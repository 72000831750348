import React, {  useEffect } from 'react';
import { CardBody,  CardHeader, CardTitle, Card, Row, Col } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { fetchProductCategoryRequest } from '../../../../store/woocommerce/actions';
import { Pie } from "react-chartjs-2"
import MetaTags from 'react-meta-tags';
import Breadcrumbs from "../../../../components/Common/Breadcrumb";
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, {
  PaginationProvider, PaginationListStandalone,
  SizePerPageDropdownStandalone
} from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import "../../../PrestaDashbaord/datatables.scss"
import { withRouter } from "react-router-dom"
import { withTranslation } from "react-i18next"
import PropTypes from "prop-types";
const WooCommercepIcon = `${process.env.PUBLIC_URL}/images/integrations_images/WooCommerce.svg`;
function WooProductCategory(props) {
  const dispatch = useDispatch();
  const { ProductCategory } = useSelector((state) => state.woocommerce);
  useEffect(() => {
    const savedProductCategory = localStorage.getItem('wooProductCategory');
    if (savedProductCategory) {

      dispatch({ type: 'FETCH_PRODUCT_CATEGORY_SUCCESS', payload: JSON.parse(savedProductCategory) });
    } else {
      dispatch(fetchProductCategoryRequest(20, 1));
    }
  }, [dispatch]);

  const dataMapProductCategoryName = ProductCategory.map((item) => { return item.ProductCategory.slug });
  const dataMapProductCategoryCount = ProductCategory.map((item) => { return item.ProductCategory.count });
  console.log("dataMapProductCategoryName", dataMapProductCategoryName);
  const data = {
    labels: dataMapProductCategoryName,
    datasets: [
      {
        data: dataMapProductCategoryCount,
        backgroundColor: ["#34c38f", "#ebeff2", "#EC1A74", "#F6B43B", "#EA2427", "#3B5B98", "	#000000", "#FF5733", "#76448A", "#0E6655 "],
        hoverBackgroundColor: ["#34c38f", "#ebeff2", "#EC1A74", "#F6B43B", "#EA2427", "#3B5B98", "	#000000", "#FF5733", "#76448A", "#0E6655"],
        hoverBorderColor: "#fff",
      },
    ],
  }

  const columns = [{
    dataField: 'ProductCategory.slug',
    text: props.t('Name'),
    sort: false,
  }
    , {
    dataField: 'ProductCategory.count',
    text: props.t('Total'),
    sort: true
  }
  ];
  const dataMapOrders = ProductCategory.map((item) => { return item });
  console.log("dataMapOrders", dataMapOrders);
  const defaultSorted = [{
    dataField: 'id',
    order: 'asc'
  }];
  const pageOptions = {
    sizePerPage: 5,
    totalSize: dataMapOrders.length,
    custom: true,
  }
  const selectRow = {
    mode: 'checkbox'
  }
  const { SearchBar } = Search;
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>{props.t("woo product category")}| Live-Metrics - E-com SaaS</title>
        </MetaTags>
        <div className="container-fluid">
          <Breadcrumbs title={props.t("WooCommerce")} breadcrumbItem={props.t("Product Category")} pageHeading={props.t("Product Category")} />
          <Row >
          <Col xl={6}>
          <Card>
            <CardHeader>
              <CardTitle className="d-flex">
                <h4 className="card-title mb-0 flex-grow-1">{props.t("All Products Categories")}</h4>
                <img className="img-intgration-icon" src={WooCommercepIcon} />
              </CardTitle>
            </CardHeader>
            <CardBody>
              <Pie width={474} height={260} data={data} />
            </CardBody>
          </Card>
          </Col>
          <Col>
          <Card>
          <CardHeader>
            <CardTitle className="d-flex">
              <h4 className="card-title mb-0 flex-grow-1"> {props.t("Orders Status Table")}</h4>
              <img className="img-intgration-icon" src={WooCommercepIcon} />
            </CardTitle>
          </CardHeader>
          <CardBody>
            <PaginationProvider
              pagination={paginationFactory(pageOptions)}
              keyField='id'
              columns={columns}
              data={dataMapOrders}
            >
              {({ paginationProps, paginationTableProps }) => (
                <ToolkitProvider
                  keyField='id'
                  columns={columns}
                  data={dataMapOrders}
                  search
                >
                  {toolkitProps => (
                    <React.Fragment>
                      <Row className="mb-2">
                        <Col md="4">
                          <div className="search-box me-2 mb-2 d-inline-block">
                            <div className="position-relative">
                              <SearchBar
                                {...toolkitProps.searchProps}
                              />
                              <i className="bx bx-search-alt search-icon" />
                            </div>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col xl="12">
                          <div className="table-responsive">
                            <BootstrapTable
                              keyField={"id"}
                              responsive
                              bordered={true}
                              striped={true}
                              defaultSorted={defaultSorted}
                              classes={
                                "table align-middle table-nowrap"
                              }
                              headerWrapperClasses={"thead-light"}
                              {...toolkitProps.baseProps}
                              {...paginationTableProps}
                            />
                          </div>
                        </Col>
                      </Row>
                      <Row className="align-items-md-center mt-30">
                        <Col className="inner-custom-pagination d-flex">
                          <div className="d-inline">
                            <SizePerPageDropdownStandalone
                              {...paginationProps}
                            />
                          </div>
                          <div className="text-md-right ms-auto">
                            <PaginationListStandalone
                              {...paginationProps}
                            />
                          </div>
                        </Col>
                      </Row>
                    </React.Fragment>
                  )
                  }
                </ToolkitProvider>
              )
              }</PaginationProvider>
          </CardBody>
        </Card>
          </Col>
            </Row>
        </div>
      </div>
    </React.Fragment>
  );
}
WooProductCategory.propTypes = {
  t: PropTypes.any
};
export default withRouter(withTranslation()(WooProductCategory));
//export default WooProductCategory;