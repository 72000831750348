import React, { useState, useEffect, useRef } from 'react';
import { Card } from "semantic-ui-react";
import { getInformations } from "../../queries/Reports/PnLQuery";
import urls from "../../routes/apiUrls";
import CountUp from "react-countup";
import { withTranslation } from "react-i18next"
import PropTypes from 'prop-types';
import { withRouter } from "react-router-dom"
import { Row, Col, CardBody } from 'reactstrap';
import ReactApexChart from "react-apexcharts";
// import '../../assets/scss/shepherd-tour/theme.scss';
const GoogleAdsIcon = `${process.env.PUBLIC_URL}/images/integrations_images/GoogleADS.svg`;
const FacebookAdsIcon = `${process.env.PUBLIC_URL}/images/integrations_images/AD8.png`;
const WooCommercepIcon = `${process.env.PUBLIC_URL}/images/integrations_images/WooCommerce.svg`;

function GenralOverviewCard(props) {

  const formatNumberWithSpaces = (value) => {
    if (value !== undefined && value !== null) {
      return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    }
    return ""; // or any default value you want to return for undefined/null cases
  };

  const googleCost = props.data.GoogleMetrics ? props.data.GoogleMetrics.totalCost : 0;
  const facebookCost = props.data.facebookMetrics ? props.data.facebookMetrics.totalCost : 0;
  const Globalspend = googleCost + facebookCost;
  const totalCosts = [googleCost, facebookCost];
  const totalPercentage = Globalspend !== 0 ? totalCosts.map(cost => (cost / Globalspend) * 100) : [0, 0];

  //calculate revenue
  const googleRevenue = props.data.GoogleMetrics ? props.data.GoogleMetrics.totalconversionValue : 0;
  const facebookRevenue = props.data.facebookMetrics ? props.data.facebookMetrics.totalconversionValue : 0;
  const globalRevenue = googleRevenue + facebookRevenue;
  const totalRevenues = [googleRevenue, facebookRevenue];
  const totalRevenuePercentage = globalRevenue !== 0 ? totalRevenues.map(revenue => (revenue / globalRevenue) * 100) : [0, 0];

  //calculate ROAS
  let globalROAS;

  if (props.data.GoogleMetrics && facebookRevenue) {
    // Case: Both Google and Facebook data are available
    const googleROAS = props.data.GoogleMetrics.totalROAS;
     const facebookROAS = facebookRevenue/facebookCost  ;
    globalROAS = (googleROAS + facebookROAS) / 2;
  } else if (props.data.GoogleMetrics) {
    // Case: Only Google data is available
    globalROAS = props.data.GoogleMetrics.totalROAS;
  } else if (facebookRevenue) {
    // Case: Only Facebook data is available
    globalROAS = facebookRevenue /facebookCost  ;
  } else {
    // Case: No data available, set globalROAS to a default value or handle as needed
    globalROAS = 0;
  }
  //claculate conversion rate
  let globalConversionRate;

if (props.data.GoogleMetrics && props.data.facebookMetrics) {
  // Case: Both Google and Facebook data are available
  const googleConversionRate = props.data.GoogleMetrics.totalCVR;
  const facebookConversionRate = (props.data.facebookMetrics.totalConversions / props.data.facebookMetrics.totalLandingPageViews)*100;
  globalConversionRate = (googleConversionRate + facebookConversionRate) / 2;
} else if (props.data.GoogleMetrics) {
  // Case: Only Google data is available
  globalConversionRate = props.data.GoogleMetrics.totalCVR;
} else if (props.data.facebookMetrics) {
  // Case: Only Facebook data is available
  globalConversionRate = (props.data.facebookMetrics.totalConversions / props.data.facebookMetrics.totalLandingPageViews)*100;
 
} else {
  // Case: No data available, set globalConversionRate to a default value or handle as needed
  globalConversionRate = 0;
}
  //calcul AOV
  let globalAOV;

if (props.data.GoogleMetrics && props.data.facebookMetrics) {
  // Case: Both Google and Facebook data are available
  const googleAOV = props.data.GoogleMetrics.totalAOV;
  const facebookAOV = props.data.facebookMetrics.totalconversionValue / props.data.facebookMetrics.totalConversions;
  globalAOV = (googleAOV + facebookAOV) / 2;
} else if (props.data.GoogleMetrics) {
  // Case: Only Google data is available
  globalAOV = props.data.GoogleMetrics.totalAOV;
} else if (props.data.facebookMetrics) {
  // Case: Only Facebook data is available
  globalAOV = props.data.facebookMetrics.totalconversionValue / props.data.facebookMetrics.totalConversions;
} else {
  // Case: No data available, set globalAOV to a default value or handle as needed
  globalAOV = 0;
}
// calcul conversion
const googleConversion = props.data.GoogleMetrics ? props.data.GoogleMetrics.totalConversions : 0;
  const facebookConversion = props.data.facebookMetrics ? props.data.facebookMetrics.totalConversions : 0;
  const globalConversion = googleConversion + facebookConversion;
  // console.log("globalConversion",globalConversion)

  const piechartColors = ['#F6B43B', '#0044BD'];
  const labels = ['Google', 'Facebook'];

  const options = {
    chart: {
      width: 227,
      height: 227,
      type: 'pie',
    },
    labels: labels,
    colors: piechartColors,
    stroke: {
      width: 0,
    },
    legend: {
      show: false,
    },
    responsive: [{
      breakpoint: 480,
      options: {
        chart: {
          width: 200,
        },
      },
    }],
  };

  const series = totalPercentage;
  const seriesrevenue = totalRevenuePercentage;

  return (
    <div className="mt-5">

      <div>
        <Col  >
          <Row className='align-items-center'>
            <Col>
              <Card className="card-h-100">
                <CardBody className="facebook-ads-card">
                  <div className="d-flex flex-wrap align-items-center mb-4">
                    <h4  className="">{props.t('Ads Spend  Distribution')}  </h4>
                    <div className="ms-auto">
                      <div>
                        <img className="img-intgration-icon" src={GoogleAdsIcon} />
                        <img className="img-intgration-icon" src={FacebookAdsIcon} />

                      </div>
                    </div>
                  </div>

                  <Row className="align-items-center">
                    <div className="col-sm">
                      <div id="wallet-balance" className="apex-charts">
                        <ReactApexChart options={options} series={series} type="pie" height="227" />
                      </div>
                    </div>
                    <div className="col-sm align-self-center">
                      <div className="mt-4 mt-sm-0">
                        <div>
                          <p className="mb-2"><i className="mdi mdi-circle align-middle font-size-10 me-2 text-warning"></i> Google Ads</p>
                          <h4>{props.data.GoogleMetrics ? `${formatNumberWithSpaces(props.data.GoogleMetrics.totalCost.toFixed(2))}$` : props.t('No data available')}</h4>
                        </div>

                        <div className="mt-4 pt-2">
                          <p className="mb-2"><i className="mdi mdi-circle align-middle font-size-10 me-2 text-primary"></i> Facebook Ads</p>
                          <h4>{props.data.facebookMetrics ? `${formatNumberWithSpaces(props.data.facebookMetrics.totalCost.toFixed(2))}$` : props.t('No data available')}</h4>
                        </div>
                      </div>
                    </div>
                  </Row>
                </CardBody>
              </Card>
            </Col>
            <Col>
              <Card className="card-h-100">
                <CardBody className="facebook-ads-card">
                  <div className="d-flex flex-wrap align-items-center mb-4">
                    <h4 className="">{props.t('Ads Revenue Distribution')} </h4>
                    <div className="ms-auto">
                      <div>
                        <img className="img-intgration-icon" src={GoogleAdsIcon} />
                        <img className="img-intgration-icon" src={FacebookAdsIcon} />
                      </div>
                    </div>
                  </div>

                  <Row className="align-items-center">
                    <div className="col-sm">
                      <div id="wallet-balance" className="apex-charts">
                        <ReactApexChart options={options} series={seriesrevenue} type="pie" height="227" />
                      </div>
                    </div>
                    <div className="col-sm align-self-center">
                      <div className="col-sm align-self-center">
                        <div className="mt-4 mt-sm-0">
                          <div>
                            <p className="mb-2"><i className="mdi mdi-circle align-middle font-size-10 me-2 text-warning"></i> Google Ads</p>
                            <h4>{props.data.GoogleMetrics ? `${formatNumberWithSpaces(props.data.GoogleMetrics.totalconversionValue.toFixed(2))}$` : props.t('No data available')}</h4>
                          </div>

                          <div className="mt-4 pt-2">
                            <p className="mb-2"><i className="mdi mdi-circle align-middle font-size-10 me-2 text-primary"></i> Facebook Ads</p>
                            <h4>{props.data.facebookMetrics ? `${formatNumberWithSpaces(props.data.facebookMetrics.totalconversionValue.toFixed(2))}$` : props.t('No data available')}</h4>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Col>

        <Col xl={12} md={4}>
          <Row>
            <Col>
              <Card className="card-h-100">
                <CardBody className="facebook-ads-card">
                  <div className="my-0 text-primary d-flex justify-content-between align-items-center">
                    <span className="my-0 text-primary">
                      <i className="fa fa-info-circle text-primary" aria-hidden="true" data-tooltip="Meta & Google "></i>{" "}
                      {props.t("Average Order Value")}
                    </span>
                    <div>
                      <img className="img-intgration-icon" src={GoogleAdsIcon} />
                      <img className="img-intgration-icon" src={FacebookAdsIcon} />
                    </div>

                  </div>
                  <Row className="align-items-center">
                    <Col xs={6}>
                      <div className="  mb-3">
                        <h4>
                        {globalAOV ? `$${globalAOV.toFixed(2)}` : '-'}
                        </h4>
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
            <Col>
              <Card className="card-h-100">
                <CardBody className="facebook-ads-card">
                  <div className="my-0 text-primary d-flex justify-content-between align-items-center">
                    <span className="my-0 text-primary">
                      <i className="fa fa-info-circle text-primary" aria-hidden="true" data-tooltip="Meta & Google"></i>{" "}
                      {props.t("Conversion Rate")}
                    </span>
                    <div>
                      <img className="img-intgration-icon" src={GoogleAdsIcon} />
                      <img className="img-intgration-icon" src={FacebookAdsIcon} />
                    </div>
                  </div>
                  <Row className="align-items-center">
                    <Col xs={6}>
                      <div className="mb-3">
                        <h4>
                          {globalConversionRate?`%${globalConversionRate.toFixed(2)}` : '-'}
                        </h4>
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
            <Col>
              <Card className="card-h-100">
                <CardBody className="facebook-ads-card">
                  <div className="my-0 text-primary d-flex justify-content-between align-items-center">
                    <span className="my-0 text-primary">
                      <i className="fa fa-info-circle text-primary" aria-hidden="true" data-tooltip="Meta & Google"></i>{" "}
                      {props.t("ROAS")}
                    </span>
                    <div>
                      <img className="img-intgration-icon" src={GoogleAdsIcon} />
                      <img className="img-intgration-icon" src={FacebookAdsIcon} />
                    </div>
                  </div>
                  <Row className="align-items-center">
                    <Col xs={6}>
                      <div className="mb-3">
                        <h4>
                        {isFinite(globalROAS) ? `x${globalROAS.toFixed(2)}` : '-'}
                        </h4>

                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
            <Col>
              <Card className="card-h-100">
                <CardBody className="facebook-ads-card">
                  <div className="my-0 text-primary d-flex justify-content-between align-items-center">
                    <span className="my-0 text-primary">
                      <i className="fa fa-info-circle text-primary" aria-hidden="true" data-tooltip="Meta & Google"></i>{" "}
                      {props.t("Total Conversions")}
                    </span>
                    <div>
                      <img className="img-intgration-icon" src={GoogleAdsIcon} />
                      <img className="img-intgration-icon" src={FacebookAdsIcon} />
                    </div>
                  </div>
                  <Row className="align-items-center">
                    <Col xs={6}>
                      <div className="mb-3">
                        <h4>
                          {globalConversion.toFixed(2)}
                        </h4>

                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Col>

      </div>
    </div>
  )
}
GenralOverviewCard.propTypes = {
  t: PropTypes.any
};
export default withRouter(withTranslation()(GenralOverviewCard));

