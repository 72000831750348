import React, { useRef,useState, useEffect } from 'react';
import { TabContent, TabPane, Nav, NavItem, NavLink, CardBody, Progress, Alert } from 'reactstrap';
import { SearchOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import { Button, Space, Table, Menu, Dropdown,Checkbox,Input } from 'antd';
import {  Thead, Tbody, Tr, Th } from "react-super-responsive-table";
import classnames from 'classnames';
import {
  getOders,
} from '../../queries/OrdersQueries/queries';
import urls from '../../routes/apiUrls';
import ApolloClient from "apollo-boost";

const ApploCLI = new ApolloClient({
  uri: urls.orders,
});
const datePattern = /(\d{1,2})\/(\d{1,2})\/(\d{4})/;
function PrestaShopOrders(props) {
  const [activeTab, setActiveTab] = useState('1');
  const [orders, setOrders] = useState([]);
  const [loadingState, setLoadingState] = useState(true);
  useEffect(() => {
    var newStartDate = datePattern.exec(props.chosedDate[0].startDate && props.chosedDate[0].startDate
      .toLocaleString("fr-FR"));
    var newEndDate = datePattern.exec(props.chosedDate[0].endDate && props.chosedDate[0].endDate
      .toLocaleString("fr-FR"));
  
      let isSubscribed = true
      setLoadingState(false);
      
        if(newStartDate && newEndDate){
          ApploCLI.query({
            query: getOders,
            variables: {
              startDate: newStartDate[3] + "-" + newStartDate[2] + "-" + newStartDate[1],
              endDate: newEndDate[3] + "-" + newEndDate[2] + "-" + newEndDate[1],
            },
            context: {
              headers: {
                Authorization: window.localStorage.getItem("token")
                  ? `Bearer ${window.localStorage.getItem("token")}`
                  : "",
              },
            },
            errorPolicy: "all",
            fetchPolicy: "no-cache",
          }).then((data) => {
            if (isSubscribed) {
              if (data && data.data && data.data.all && data.data.all.length > 0) {
             
                setOrders(data.data.all);
             
              }
              setLoadingState(true);
            }
          });
          return () => (isSubscribed = false)
        }
     
   

  }, [props.chosedDate]);
  const [filteredInfo, setFilteredInfo] = useState({});
  const [sortedInfo, setSortedInfo] = useState({});
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const [paginationOptions, setPaginationOptions] = useState({
    pageSize:10, // Default page size
    pageSizeOptions: ['5', '10', '20'], // Available page size options
  });
  const searchInput = useRef(null);
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: 'block',
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1677ff' : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: '#ffc069',
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });
  const handleChange = (pagination, filters, sorter) => {
    setFilteredInfo(filters);
    setSortedInfo(sorter);
    if (pagination.pageSize !== paginationOptions.pageSize) {
      setPaginationOptions({
        ...paginationOptions,
        pageSize: pagination.pageSize,
      });
    }
  };
  const clearFilters = () => {
    setFilteredInfo({});
  };
  const clearAll = () => {
    setFilteredInfo({});
    setSortedInfo({});
    // make the columns  selected
    setSelectedColumns(columns.map((column) => column.key));
  };
  const setAgeSort = () => {
    setSortedInfo({
      order: 'descend',
      columnKey: 'total',
    });
  };

  const uniqueStatusValues = Array.from(
    new Set(orders.map((order) => order.order.status))
  );
  
  const statusFilters = uniqueStatusValues.map((statusValue) => ({
    text: statusValue,
    value: statusValue,
  }));
  
  const columns = [
    {
      title: 'Order ID',
      dataIndex: 'id',
      align: 'center',
      key: 'orderId',
      filteredValue: filteredInfo.id || null,
      // onFilter: (value, record) => record.order.id).includes(value),
      // ...getColumnSearchProps('id'),
      ellipsis: true,
      render: (text, record) => (
        <strong>{record.order.id}</strong>
      ),
    },
   
    {
      title: 'Subtotal',
      dataIndex: 'subtotal',
      align: 'center',
      key: 'subtotal',
      sorter: (a, b) => a.order.subtotal - b.order.subtotal,
      sortOrder: sortedInfo.columnKey === 'subtotal' ? sortedInfo.order : null,
      ellipsis: true,
      render: (text, record) => (
        <strong>{record.order.subtotal}</strong>
      ),
    },
    {
      title: 'Shipping Cost',
      dataIndex: 'shipping_cost',
      align: 'center',
      key: 'shipping_cost',
      sorter: (a, b) => a.order.shipping_cost - b.order.shipping_cost,
      sortOrder: sortedInfo.columnKey === 'shipping_cost' ? sortedInfo.order : null,
      ellipsis: true,
      render: (text, record) => (
        <strong>{record.order.shipping_cost}</strong>
      ),
    },
    {
      title: 'Total',
      dataIndex: 'total',
      align: 'center',
      key: 'total',
      sorter: (a, b) => a.order.total - b.order.total,
      sortOrder: sortedInfo.columnKey === 'total' ? sortedInfo.order : null,
      ellipsis: true,
      render: (text, record) => (
        <strong>{record.order.total}</strong>
      ),
    },
   
     {
    title: 'Status',
    dataIndex: 'status',
    align: 'center',
    key: 'status',
    render: (text, record) => (
      <strong>{record.order.status}</strong>
    ),
    width: 500,
    ellipsis: true,
    filters: statusFilters,
    filteredValue: filteredInfo.status || null,
    onFilter: (value, record) => {
      return record.order.status === value;
    },
  },

  ];
  
  const [selectedColumns, setSelectedColumns] = useState(
    columns.map((column) => column.key)
  );

  const handleMenuClick = (clickedKey) => {
    const index = selectedColumns.indexOf(clickedKey);

    let updatedSelectedColumns = [];

    if (index === -1) {
      // Column was not selected before, add it to the selected columns
      updatedSelectedColumns = [...selectedColumns, clickedKey];
    } else {
      // Column was selected before, remove it from the selected columns
      updatedSelectedColumns = selectedColumns.filter(
        (key) => key !== clickedKey
      );
    }

    setSelectedColumns(updatedSelectedColumns);
  };

  const filteredColumns = columns.filter((column) =>
    selectedColumns.includes(column.key)
  );

  const menu = (
    <Menu>
    {columns.map((column) => (
      <Menu.Item
        key={column.key}
        onClick={() => handleMenuClick(column.key)}
      >
        <Checkbox
          checked={selectedColumns.includes(column.key)}
          style={{ marginRight: '8px' }}
        />
        <span
          style={
            selectedColumns.includes(column.key)
              ? { fontWeight: 'bold' }
              : {}
          }
        >
          {column.title}
        </span>
      </Menu.Item>
    ))}
  </Menu>
  );

const transformedOrders = orders.map((element, index) => ({
  order: element.order,
}));
  console.log("transformedOrders",transformedOrders)
  return (
    <React.Fragment>
      <div className="col-xl-12">
        <div className="card">
          <div className="card-header align-items-center d-flex">
            <h4 className="card-title mb-0 flex-grow-1">Orders</h4>
        
          </div>
         
                {orders && orders.length > 0 ? (
                
                        // {orders.map((element, index) => (
                        //   <SingleOrder element={element} key={index} />
                        // ))}
                        <>
                          <Space
                  style={{
                    marginBottom: 16,
                    marginTop: 16,
                  }}
                >
                   <Button onClick={setAgeSort}>Sort buy Total</Button>
                <Button onClick={clearFilters}>Clear filters</Button>
                <Button onClick={clearAll}>Clear filters and sorters</Button>
                <Dropdown overlay={menu} trigger={['click']}>
                  <Button>Select Columns</Button>
                </Dropdown>
                </Space>
                <Table
                  columns={filteredColumns}
                 dataSource={transformedOrders}
                  onChange={handleChange}
                  pagination={{
                    ...paginationOptions,
                    onChange: handleChange,
                    showSizeChanger: true, // Enable the page size selector
                    // showTotal: (total) => `Total ${total} items`, // Optionally show total items
                    // Other pagination settings
                  }}
                  scroll={{ y: 700 }}
                  
                />
                        
                        </>
                     
                ) : (
                  <div className='text-center'>
                    <div className="text-center">
                      <CardBody>
                        <Progress
                          value={80}
                          color="success"
                          style={{ width: '75%' }}
                          animated
                        ></Progress>
                        <Alert color="success" className="mt-3">
                          This might take a few minutes!
                        </Alert>
                      </CardBody>
                    </div>
                  </div>
                )}
              
        </div>
      </div>
    </React.Fragment>
  );
}
export default PrestaShopOrders;