import React, { useEffect } from "react";
import MetaTags from 'react-meta-tags';
import { useDispatch, useSelector } from 'react-redux';
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { Row, Col, Card, CardBody, CardTitle, CardHeader } from "reactstrap"
import { fetchProductsRequest } from '../../../store/prestaShop/actions';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, {
  PaginationProvider, PaginationListStandalone,
  SizePerPageDropdownStandalone
} from 'react-bootstrap-table2-paginator';
import secureSection from '../../../MangeSecurity/MemberSecurity';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';

function AllProducts() {
  secureSection();
  const dispatch = useDispatch();
  const { products } = useSelector((state) => state.prestashop);
  useEffect(() => {
    const savedProducts = localStorage.getItem('products');
    if (savedProducts) {

      dispatch({ type: 'FETCH_PRODUCTS_SUCCESS', payload: JSON.parse(savedProducts) });
    } else {
      dispatch(fetchProductsRequest(50, 1));
    }
  }, [dispatch]);
  let mappedProducts = [];

  if (products && Array.isArray(products)) {
    // Ensure products is not null or undefined and is an array
    mappedProducts = products.map((item) => {
      return item.product;
    });
  }
  const defaultSorted = [{
    dataField: 'id',
    order: 'asc'
  }];
  const columns = [{
    dataField: 'product_id',
    text: 'product_id',
    sort: true,
  }
    , {
    dataField: 'name',
    text: 'name',
    sort: true
  }, {
    dataField: 'price',
    text: 'price',
    sort: true
  }, {
    dataField: 'quantity',
    text: 'quantity',
    sort: true
  },
  ];
  const pageOptions = {
    sizePerPage: 5,
    totalSize: mappedProducts.length, 
    custom: true,
  }
  const selectRow = {
    mode: 'checkbox'
  }
  const { SearchBar } = Search;
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>All PrestaShop Products | Live-Metrics - E-com SaaS</title>
        </MetaTags>
        <div className="container-fluid">
          <Breadcrumbs title="PrestaShop" breadcrumbItem="All Products" />
          <Row>
            <Col className="col-12">
              <Card>
                <CardHeader>
                  <CardTitle className="h4">All PrestaShop Products </CardTitle>
                </CardHeader>
                <CardBody>
                  <PaginationProvider
                    pagination={paginationFactory(pageOptions)}
                    keyField='id'
                    columns={columns}
                    data={mappedProducts}
                  >
                    {({ paginationProps, paginationTableProps }) => (
                      <ToolkitProvider
                        keyField='id'
                        columns={columns}
                        data={mappedProducts}
                        search
                      >
                        {toolkitProps => (
                          <React.Fragment>
                            <Row className="mb-2">
                              <Col md="4">
                                <div className="search-box me-2 mb-2 d-inline-block">
                                  <div className="position-relative">
                                    <SearchBar
                                      {...toolkitProps.searchProps}
                                    />
                                    <i className="bx bx-search-alt search-icon" />
                                  </div>
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col xl="12">
                                <div className="table-responsive">
                                  <BootstrapTable
                                    keyField={"id"}
                                    responsive
                                    bordered={false}
                                    striped={false}
                                    defaultSorted={defaultSorted}
                                    selectRow={selectRow}
                                    classes={
                                      "table align-middle table-nowrap"
                                    }
                                    headerWrapperClasses={"thead-light"}
                                    {...toolkitProps.baseProps}
                                    {...paginationTableProps}
                                  />
                                </div>
                              </Col>
                            </Row>
                            <Row className="align-items-md-center mt-30">
                              <Col className="inner-custom-pagination d-flex">
                                <div className="d-inline">
                                  <SizePerPageDropdownStandalone
                                    {...paginationProps}
                                  />
                                </div>
                                <div className="text-md-right ms-auto">
                                  <PaginationListStandalone
                                    {...paginationProps}
                                  />
                                </div>
                              </Col>
                            </Row>
                          </React.Fragment>
                        )
                        }
                      </ToolkitProvider>
                    )
                    }</PaginationProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
}
export default AllProducts;