import gql from 'graphql-tag';

const getWooTopSellers = gql`query($limit:Int!,$page:Int!){
    all(limit:$limit,page:$page){
        pages,
        type,
        TopSeller{
            name,
            quantity,
            product_id
        },
}}
`


export { getWooTopSellers};