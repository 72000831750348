import React, { useEffect, useState } from 'react';
import { Card, CardBody, Col, Row } from 'reactstrap';
import '../Integrations/styles.css';
import urls from '../../routes/apiUrls';
import ApolloClient from 'apollo-boost';
import { Link } from 'react-router-dom';
import { getGAEcommerceAffiliation, getGAUsers } from "../../queries/GoogleAnalytics/Queries";
import AnalyticsOverviewDataCard from './AnalyticsOverviewDataCard';
import PerfectScrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";
import GoogleAnalyticsUsersDataCard from './GoogleAnalyticsUsersDataCard';

const ApploCLI = new ApolloClient({
    uri: urls.analytics,
});
const GoogleAnalyticsOverview = () => {
    const [EcommerceAffiliationDashboard, setEcommerceAffiliationDashboard] = useState([]);
    const [loadingState, setLoadingState] = useState(false);
    const [transactions, setTransactions] = useState([]);
    const [ecommerceConversionRate, setEcommerceConversionRate] = useState([]);
    const [revenue, setRevenue] = useState([]);
    const [revenuePerItem, setRevenuePerItem] = useState([]);
    const [avgOrderValue, setAvgOrderValue] = useState([]);
    const [usersDataDashboard, setUsersDataDashboard] = useState([]);
    const [sessionsPerUser, setSessionsPerUser] = useState([]);
    const [percentNewSessions, setPercentNewSessions] = useState([]);
    const [users, setUsers] = useState([]);
    const [newUsers, setNewUsers] = useState([]);
    useEffect(() => {
        let isSubscribed = true;
        ApploCLI.query({
            query: getGAEcommerceAffiliation,
            context: {
                headers: {
                    Authorization: window.localStorage.getItem("token")
                        ? `Bearer ${window.localStorage.getItem("token")}`
                        : "",
                },
            },
            errorPolicy: "all",
            fetchPolicy: "network-only",
        }).then((data) => {
            if (isSubscribed) {
                if (data.data.gaecommerceaffiliations) {
                    setEcommerceAffiliationDashboard(data.data.gaecommerceaffiliations);
                    setLoadingState(true);
                } else {
                    setLoadingState(false);
                }
            }
        });
        return () => (isSubscribed = false);
    }, []);
    useEffect(() => {
        let isSubscribed = true;
        var Ttransactions = [];
        var TecommerceConversionRate = [];
        var Trevenue = [];
        var TrevenuePerItem = [];
        var TavgOrderValue = [];
        if (EcommerceAffiliationDashboard.length !== 0) {
            EcommerceAffiliationDashboard.map((elem) => {
                Ttransactions.push(elem.transactions ? (elem.transactions) : 0)
                TecommerceConversionRate.push(elem.ecommerceConversionRate ? (elem.ecommerceConversionRate) : 0)
                Trevenue.push(elem.revenue ? (elem.revenue) : 0)
                TrevenuePerItem.push(elem.revenuePerItem ? (elem.revenuePerItem) : 0)
                TavgOrderValue.push(elem.avgOrderValue ? (elem.avgOrderValue) : 0)
            })
        }
        setTransactions(Ttransactions);
        setEcommerceConversionRate(TecommerceConversionRate);
        setRevenue(Trevenue);
        setRevenuePerItem(TrevenuePerItem);
        setAvgOrderValue(TavgOrderValue);
        return () => (isSubscribed = false);
    }, [EcommerceAffiliationDashboard]);
    useEffect(() => {
        let isSubscribed = true;
        ApploCLI.query({
          query: getGAUsers,
          context: {
            headers: {
              Authorization: window.localStorage.getItem("token")
                ? `Bearer ${window.localStorage.getItem("token")}`
                : "",
            },
          },
          errorPolicy: "all",
          fetchPolicy: "network-only", 
        }).then((data) => {
          if (isSubscribed) {
            if (data.data.gausers) {
                setUsersDataDashboard(data.data.gausers);
              setLoadingState(true);
            } else {
              setLoadingState(false);
            }
          }
        });
        return () => (isSubscribed = false);
      }, []);
    useEffect(() => {
        let isSubscribed = true;
        var TsessionsPerUser = [];
        var TpercentNewSessions = [];
        var Tusers = [];
        var TnewUsers = [];
        if (usersDataDashboard.length !== 0) {
            usersDataDashboard.map((elem) => {
            TsessionsPerUser.push(elem.sessionsPerUser ? (elem.sessionsPerUser) : 0)
            TpercentNewSessions.push(elem.percentNewSessions ? (elem.percentNewSessions) : 0)
            Tusers.push(elem.users ? (elem.users) : 0)
            TnewUsers.push(elem.newUsers ? (elem.newUsers) : 0)
          })
        }
        setSessionsPerUser(TsessionsPerUser);
        setPercentNewSessions(TpercentNewSessions);
        setUsers(Tusers);
        setNewUsers(TnewUsers);
        return () => (isSubscribed = false);
      }, [usersDataDashboard]);
    var op1 = {
        chart: {
            toolbar: {
                followCursor: true,
                show: false,
                tools: {
                    download: true,
                    selection: true,
                    zoom: true,
                    zoomin: true,
                    zoomout: true,
                    pan: true,
                    reset: true,
                },
                tooltip: {
                    shared: true,
                    intersect: true,
                    followCursor: true,
                    x: {
                        show: false,
                    },
                },
                autoSelected: "zoom",
            },
            type: "area",
            height: 120,
            sparkline: {
                enabled: true,
            },
        },
        markers: {
            size: 0,
        },
        stroke: {
            curve: "straight",
            width: 2.5,
        },
        fill: {
            opacity: 1,
        },
        series: [{ name: "Revenue", data: revenue }],
        xaxis: {
            crosshairs: {
                width: 1,
            },
        },
        yaxis: {
            show: false,
        },
        colors: ["#F6B43B", "#EC1A74"],
    };
    var op2 = {
        chart: {
            toolbar: {
                followCursor: true,
                show: false,
                tools: {
                    download: true,
                    selection: true,
                    zoom: true,
                    zoomin: true,
                    zoomout: true,
                    pan: true,
                    reset: true,
                },
                tooltip: {
                    shared: true,
                    intersect: true,
                    followCursor: true,
                    x: {
                        show: false,
                    },
                },
                autoSelected: "zoom",
            },
            type: "area",
            height: 120,
            sparkline: {
                enabled: true,
            },
        },
        markers: {
            size: 0,
        },
        stroke: {
            curve: "straight",
            width: 2.5,
        },
        fill: {
            opacity: 1,
        },
        series: [{ name: "Revenue Per Transaction", data: avgOrderValue }],
        xaxis: {
            crosshairs: {
                width: 1,
            },
        },
        yaxis: {
            show: false,
        },
        colors: ["#F6B43B", "#EC1A74"],
    };
    var op3 = {
        chart: {
            toolbar: {
                followCursor: true,
                show: false,
                tools: {
                    download: true,
                    selection: true,
                    zoom: true,
                    zoomin: true,
                    zoomout: true,
                    pan: true,
                    reset: true,
                },
                tooltip: {
                    shared: true,
                    intersect: true,
                    followCursor: true,
                    x: {
                        show: false,
                    },
                },
                autoSelected: "zoom",
            },
            type: "area",
            height: 120,
            sparkline: {
                enabled: true,
            },
        },
        markers: {
            size: 0,
        },
        stroke: {
            curve: "straight",
            width: 2.5,
        },
        fill: {
            opacity: 1,
        },
        series: [{ name: "Conversion Rate", data: ecommerceConversionRate }],
        xaxis: {
            crosshairs: {
                width: 1,
            },
        },
        yaxis: {
            show: false,
        },
        colors: ["#F6B43B", "#EC1A74"],
    };
    var op4 = {
        chart: {
            toolbar: {
                followCursor: true,
                show: false,
                tools: {
                    download: true,
                    selection: true,
                    zoom: true,
                    zoomin: true,
                    zoomout: true,
                    pan: true,
                    reset: true,
                },
                tooltip: {
                    shared: true,
                    intersect: true,
                    followCursor: true,
                    x: {
                        show: false,
                    },
                },
                autoSelected: "zoom",
            },
            type: "area",
            height: 120,
            sparkline: {
                enabled: true,
            },
        },
        markers: {
            size: 0,
        },
        stroke: {
            curve: "straight",
            width: 2.5,
        },
        fill: {
            opacity: 1,
        },
        series: [{ name: "Transactions", data: transactions }],
        xaxis: {
            crosshairs: {
                width: 1,
            },
        },
        yaxis: {
            show: false,
        },

        colors: ["#F6B43B", "#EC1A74"],
    };
    const [myCards, setMyCards] = useState([
        { id: 1, name: "Revenue", options: op1, series: op1.series },
        { id: 2, name: "Revenue Per Transaction", options: op2, series: op2.series },
        { id: 3, name: "Conversion Rate", options: op3, series: op3.series },
        { id: 4, name: "Transactions", options: op4, series: op4.series },
    ]);
    useEffect(() => {
        let isSubscribed = true;
        setMyCards([
            { id: 1, name: "Revenue", options: op1, series: op1.series },
            { id: 2, name: "Revenue Per Transaction", options: op2, series: op2.series },
            { id: 3, name: "Conversion Rate", options: op3, series: op3.series },
            { id: 4, name: "Transactions", options: op4, series: op4.series },
        ]);
        return () => (isSubscribed = false);
    }, [transactions, ecommerceConversionRate, revenue, avgOrderValue]);
 var op1 = {
    chart: {
      toolbar: {
        followCursor: true,
        show: false,
        tools: {
          download: true,
          selection: true,
          zoom: true,
          zoomin: true,
          zoomout: true,
          pan: true,
          reset: true,
        },
        tooltip: {
          shared: true,
          intersect: true,
          followCursor: true,
          x: {
            show: false,
          },
        },
        autoSelected: "zoom",
      },
      type: "area",
      height: 120,
      sparkline: {
        enabled: true,
      },
    },
    markers: {
      size: 0,
    },
    stroke: {
      curve: "straight",
      width: 2.5,
    },
    fill: {
      opacity: 1,
    },
    series: [{ name: "Users", data: users }],
    xaxis: {
      crosshairs: {
        width: 1,
      },
    },
    yaxis: {
      show: false,
    },
    colors: ["#F6B43B", "#EC1A74"],
  };
  var op2 = {
    chart: {
      toolbar: {
        followCursor: true,
        show: false,
        tools: {
          download: true,
          selection: true,
          zoom: true,
          zoomin: true,
          zoomout: true,
          pan: true,
          reset: true,
        },
        tooltip: {
          shared: true,
          intersect: true,
          followCursor: true,
          x: {
            show: false,
          },
        },
        autoSelected: "zoom",
      },
      type: "area",
      height: 120,
      sparkline: {
        enabled: true,
      },
    },
    markers: {
      size: 0,
    },
    stroke: {
      curve: "straight",
      width: 2.5,
    },
    fill: {
      opacity: 1,
    },
    series: [{ name: "New Users", data: newUsers }],
    xaxis: {
      crosshairs: {
        width: 1,
      },
    },
    yaxis: {
      show: false,
    },
    colors: ["#F6B43B", "#EC1A74"],
  };
  var op3 = {
    chart: {
      toolbar: {
        followCursor: true,
        show: false,
        tools: {
          download: true,
          selection: true,
          zoom: true,
          zoomin: true,
          zoomout: true,
          pan: true,
          reset: true,
        },
        tooltip: {
          shared: true,
          intersect: true,
          followCursor: true,
          x: {
            show: false,
          },
        },
        autoSelected: "zoom",
      },
      type: "area",
      height: 120,
      sparkline: {
        enabled: true,
      },
    },
    markers: {
      size: 0,
    },
    stroke: {
      curve: "straight",
      width: 2.5,
    },
    fill: {
      opacity: 1,
    },
    series: [{ name: "Sessions Per User", data: sessionsPerUser }],
    xaxis: {
      crosshairs: {
        width: 1,
      },
    },
    yaxis: {
      show: false,
    },

    colors: ["#F6B43B", "#EC1A74"],
  };
  var op4 = {
    chart: {
      toolbar: {
        followCursor: true,
        show: false,
        tools: {
          download: true,
          selection: true,
          zoom: true,
          zoomin: true,
          zoomout: true,
          pan: true,
          reset: true,
        },
        tooltip: {
          shared: true,
          intersect: true,
          followCursor: true,
          x: {
            show: false,
          },
        },
        autoSelected: "zoom",
      },
      type: "area",
      height: 120,
      sparkline: {
        enabled: true,
      },
    },
    markers: {
      size: 0,
    },
    stroke: {
      curve: "straight",
      width: 2.5,
    },
    fill: {
      opacity: 1,
    },
    series: [{ name: "Percent New Sessions", data: percentNewSessions }],
    xaxis: {
      crosshairs: {
        width: 1,
      },
    },
    yaxis: {
      show: false,
    },

    colors: ["#F6B43B", "#EC1A74"],
  };
  const [myUsersCards, setUsersMyCards] = useState([
    { id: 1, name: "Users", options: op1, series: op1.series },
    { id: 2, name: "New Users", options: op2, series: op2.series },
    { id: 3, name: "Sessions Per User", options: op3, series: op3.series },
    { id: 4, name: "Percent New Sessions", options: op4, series: op4.series },
   
  ]);
  useEffect(() => {
    let isSubscribed = true;
    setUsersMyCards([
        { id: 1, name: "Users", options: op1, series: op1.series },
        { id: 2, name: "New Users", options: op2, series: op2.series },
        { id: 3, name: "Sessions Per User", options: op3, series: op3.series },
        { id: 4, name: "Percent New Sessions", options: op4, series: op4.series },
     
    ]);
    return () => (isSubscribed = false);
  }, [users,newUsers,sessionsPerUser,percentNewSessions]);
    return (
        <React.Fragment>
            <Col xl={12}>
                <Card className="card-h-100">
                    <CardBody>
                        <div className="d-flex flex-wrap align-items-center mb-4">
                            <h5 className="card-title me-2">Google Analytics KPIs</h5>   
                        </div>
                        <Row>
                            {
                                myCards.map(item => (
                                    <AnalyticsOverviewDataCard loading={loadingState} item={item} />
                                ))}
                        </Row>
                        <PerfectScrollbar className="chat-conversation p-3 px-2" style={{ height: "200px" }} >
                        <div className="chat-day-title">
                          <span className="title">Users</span>
                        </div>
                        <Row>
                            {
                                myUsersCards.map(item => (
                                    <GoogleAnalyticsUsersDataCard loading={loadingState} item={item} />
                                ))}
                        </Row>
                        </PerfectScrollbar>
                        <div className="mt-2-overview-dashboard">
                            <Link to="/google-analytics-ecommerce-affiliation" className="btn btn-primary btn-sm">View more <i className="overview-dashboard-icon mdi mdi-arrow-right ms-1"></i></Link>
                        </div>
                       
                    </CardBody>
                </Card>
            </Col>
        </React.Fragment>
    );
}
export default GoogleAnalyticsOverview;