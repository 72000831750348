import gql from 'graphql-tag';

const getOders = gql`query($startDate:String!,$endDate:String!){
    all(startDate:$startDate,endDate:$endDate){
        pages,
        type,
        order{
            id,
            total,
            subtotal,
            shipping_cost,
            status
        },
        order_rows{
            item_id, 
            product_attribute_id,
            product_id,
            quantity,
            product_name,
            product_price
        }
     
}}
`

const getOneOrder = gql`query($id:Int!){
    one(id:$id){
        id,
        order{
            id,
            total,
            subtotal,
            shipping_cost
        }
     
}}
`
export { getOders,getOneOrder };