import React from 'react';
//import { Row } from 'reactstrap';
import { Card, CardBody, Col, Container, Row, FormGroup, Input, Modal, Label, Alert, Table, Button, InputGroupText, InputGroup, ModalHeader, ModalBody } from "reactstrap";
import WooCommerceCard from './Woocommerce/WoocommerceCard';
import PrestaShopCard from './PrestaShop/PrestaShopCard';
import ShopifyCard from './Shopify/ShopifyCard';

const SalesPlatformsCatalog = () => (

  

    <Row xl={3} sm={6}>
      <WooCommerceCard />
      <PrestaShopCard />
      <ShopifyCard />
    </Row>
  

);
export default SalesPlatformsCatalog;
