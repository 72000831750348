import React, { useState, useEffect, useRef } from "react"
import MetaTags from 'react-meta-tags';
import {
  Row,
  Col,
  Card,
  CardBody,
  FormGroup,
  InputGroup,
  Container
} from "reactstrap"
import {
  Icon,
  Table,
  Placeholder
} from "semantic-ui-react";
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { DateRange } from "react-date-range";
import { withRouter } from "react-router-dom"
import { withTranslation } from "react-i18next"
import { getSalesPlatform } from '../../queries/Shopify/queries'
import ApolloClient from "apollo-boost";
import "flatpickr/dist/themes/material_blue.css"
import urls from '../../routes/apiUrls'
import { getInformations } from "../../queries/Advisor/AdvisorQuery";
import { alert } from '../Alerts/NotLinkedAccountAlert'
import Recommendation from "./Recommendation";
import secureSection from "../../MangeSecurity/MemberSecurity";

const ApploCLI = new ApolloClient({
  uri: urls.report,
});
const ApploShopifyCLI = new ApolloClient({
  uri: urls.shopify,
});

function useOutsideAlerter(ref, toggleDate) {
  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        toggleDate(false)
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);
}
function Advisor(props) {
  secureSection();
  const wrapperRef = useRef(null);
  const [toggleDate, setToggleDate] = useState(false);
  useOutsideAlerter(wrapperRef, setToggleDate);
  const [info, setInfo] = useState({});
  const [timeZone, setTimeZone] = useState("GMT");
  const [loadingState, setLoadingState] = useState(true);
  const [currentFocus, setCurrentFocus] = useState([]);
  const [activeBtn, setActiveBtn] = useState(window.localStorage.getItem("activeBtn") ? JSON.parse(window.localStorage.getItem("activeBtn")) : {
    today: 1, last7days: 0, last30day: 0, last60day: 0, month: 0, other: 0
  });
  const [channel, setChannel] = useState("all");
  const [dateRage, setDateRange] = useState({
    date: [
      window.localStorage.getItem("start") ? new Date(window.localStorage.getItem("start")) : new Date(new Date(chosedDate[0].startDate.toLocaleDateString('en-US', { timeZone: window.localStorage.getItem("timezone") ? window.localStorage.getItem("timezone") : timeZone }))),
      window.localStorage.getItem("end") ? new Date(window.localStorage.getItem("end")) : new Date(new Date(chosedDate[0].endDate.toLocaleDateString('en-US', { timeZone: window.localStorage.getItem("timezone") ? window.localStorage.getItem("timezone") : timeZone })))
    ],
  });
  const [chosedDate, setChosedDate] = useState([{
    startDate: window.localStorage.getItem("start") ? new Date(window.localStorage.getItem("start")) : new Date(new Date().toLocaleDateString('en-US', { timeZone: window.localStorage.getItem("timezone") ? window.localStorage.getItem("timezone") : timeZone })),
    endDate: window.localStorage.getItem("end") ? new Date(window.localStorage.getItem("end")) : null,
    key: 'selection'
  }]);
  const buttonHandler = (startDate, endDate) => {
    setDateRange({ date: [startDate, endDate] });
    setChosedDate([{
      startDate: startDate,
      endDate: endDate,
      key: 'selection'
    }]);
    setToggleDate(false)
    window.localStorage.setItem("start", startDate);
    window.localStorage.setItem("end", endDate);
  };
  useEffect(() => {
    let isSubscribed = true;
    setLoadingState(false);
    const datePattern = /(\d{1,2})\/(\d{1,2})\/(\d{4})/;
    var newStartDate = datePattern.exec(chosedDate[0].startDate && chosedDate[0].startDate
      .toLocaleString("fr-FR"));
    var newEndDate = datePattern.exec(chosedDate[0].endDate && chosedDate[0].endDate
      .toLocaleString("fr-FR"));
    ApploCLI.query({
      query: getInformations,
      variables: {
        startDate: newStartDate[3] + "-" + newStartDate[2] + "-" + newStartDate[1],
        endDate: newEndDate[3] + "-" + newEndDate[2] + "-" + newEndDate[1],
        channel: channel
      },
      context: {
        headers: {
          Authorization: window.localStorage.getItem("token")
            ? `Bearer ${window.localStorage.getItem("token")}`
            : "",
        },
      },
      errorPolicy: "all",
      fetchPolicy: "network-only", //allow me to refecth data
    }).then((data) => {
      if (isSubscribed) {
        setInfo(data.data.advisor);
        setLoadingState(true);

      }
    });
    return () => (isSubscribed = false);
  }, [dateRage, channel]);
  useEffect(() => {
    let isSubscribed = true;
    ApploShopifyCLI.query({
      query: getSalesPlatform,
      context: {
        headers: {
          Authorization: window.localStorage.getItem("token")
            ? `Bearer ${window.localStorage.getItem("token")}`
            : "",
        },
      },
      errorPolicy: "all",
      fetchPolicy: "network-only", //allow me to refecth data
    }).then((data) => {
      if (isSubscribed) {

        if (!data.data.get)
          alert().then((data) => {
            window.location.replace("/integrations");
          });
        else {
          setTimeZone(data.data.get.storeIanaTimezone);
          window.localStorage.setItem(
            "timezone",
            data.data.get.storeIanaTimezone
          );
        }
      }
    });
    return () => (isSubscribed = false);
  }, []);
  useEffect(() => {
    if (currentFocus[1] === 0) {
      setDateRange({
        date: [
          new Date(new Date(chosedDate[0].startDate.toLocaleDateString('en-US', { timeZone: props.timeZone ? props.timeZone : "UTC" }))),
          new Date(new Date(chosedDate[0].endDate.toLocaleDateString('en-US', { timeZone: props.timeZone ? props.timeZone : "UTC" })))
        ]
      });
      setToggleDate(false)
    }

  }, [currentFocus])

  useEffect(() => {
    window.localStorage.setItem("start", chosedDate[0].startDate);
    window.localStorage.setItem("end", chosedDate[0].endDate);
  }, [chosedDate]);

  const mnths = [{ num: 0, add: 2 },
  { num: 1, add: 0 },
  { num: 2, add: 2 },
  { num: 3, add: 1 },
  { num: 4, add: 2 },
  { num: 5, add: 1 },
  { num: 6, add: 2 },
  { num: 7, add: 2 },
  { num: 8, add: 1 },
  { num: 9, add: 2 },
  { num: 10, add: 1 },
  { num: 11, add: 2 }
  ]
  const getToAddOnMonths = (int) => {
    let res = 0;
    let month = 0;
    if (int === 0) {
      month = int
    }
    else {
      month = int - 1
    }
    mnths.map(x => {
      if (x.num === month)
        res = x.add;
    })
    return res
  }
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>{props.t("Advisor")} | Convergen Metrics - E-com SaaS</title>
        </MetaTags>
        <Container fluid>
          {<Breadcrumbs title={props.t("Advisor")} breadcrumbItem={props.t("Advisor")} />}
          <div className="container-fluid">
            <Row>
              <Col md={3}>
                <FormGroup className="mb-4">
                  <InputGroup>
                    <div className="DateContainer">
                      <div>

                        <div className="divDateContainer" >

                          <div ref={wrapperRef}>
                            <button
                              className="btn btn-light"
                              onClick={() => setToggleDate(!toggleDate)}
                            >
                              {chosedDate[0] !== null
                                ? [
                                  chosedDate[0].startDate.toLocaleString("en", {
                                    month: "short",
                                  }),
                                  " ",
                                  chosedDate[0].startDate.getDate(),
                                  " ",
                                  chosedDate[0].startDate.getFullYear(),
                                  "  -  ",
                                  chosedDate[0].endDate && chosedDate[0].endDate.toLocaleString("en", {
                                    month: "short",
                                  }),
                                  " ",
                                  chosedDate[0].endDate && chosedDate[0].endDate.getDate(),
                                  " ",
                                  chosedDate[0].endDate && chosedDate[0].endDate.getFullYear(),
                                ]
                                : null}
                            </button>
                            {toggleDate && (
                              <div style={{ position: "relative", display: "flex" }}>
                                <div className="calenderDivStyle">
                                  <div className="btnsCalendar">
                                    <DateRange
                                      editableDateInputs={true}
                                      moveRangeOnFirstSelection={false}
                                      onRangeFocusChange={item => { setCurrentFocus(item) }}
                                      onChange={item => { setChosedDate([item.selection]); setActiveBtn({ today: 0, yesterday: 0, last7days: 0, last30day: 0, last60day: 0, month: 0, other: 1 }); window.localStorage.setItem("activeBtn", JSON.stringify({ today: 0, yesterday: 0, last7days: 0, last30day: 0, last60day: 0, month: 0, other: 1 })) }}
                                      ranges={chosedDate}
                                      dragSelectionEnabled={true}
                                      rangeColors={["#3B5B98"]}
                                      months={2}
                                      direction="horizontal"
                                    />
                                  </div>
                                  <div className="calendarDiv">
                                    <div className="btnCalendarContainer">
                                      <button
                                        className={activeBtn.today ? "dateBtns activeBtnDate" : "dateBtns"}
                                        onClick={() => {
                                          buttonHandler(new Date(new Date().toLocaleDateString('en-US', { timeZone: window.localStorage.getItem('timezone') })),

                                            new Date(new Date().toLocaleDateString('en-US', { timeZone: window.localStorage.getItem('timezone') })));
                                          ;
                                          setActiveBtn({ today: 1, yesterday: 0, last7days: 0, last30day: 0, last60day: 0, month: 0, other: 0 })
                                          window.localStorage.setItem("activeBtn", JSON.stringify({ today: 1, yesterday: 0, last7days: 0, last30day: 0, last60day: 0, month: 0, other: 0 }));
                                        }}
                                      >
                                        {props.t("compareto")}
                                      </button>
                                      <button
                                        className={activeBtn.yesterday ? "dateBtns activeBtnDate" : "dateBtns"}
                                        onClick={() => {
                                          buttonHandler(
                                            new Date(new Date(
                                              new Date().getTime() - 24 * 60 * 60 * 1000
                                            ).toLocaleDateString('en-US', { timeZone: window.localStorage.getItem('timezone') })),

                                            new Date(new Date(
                                              new Date().getTime() - 24 * 60 * 60 * 1000
                                            ).toLocaleDateString('en-US', { timeZone: window.localStorage.getItem('timezone') })));
                                          ;
                                          setActiveBtn({ today: 0, yesterday: 1, last7days: 0, last30day: 0, last60day: 0, month: 0, other: 0 })
                                          window.localStorage.setItem("activeBtn", JSON.stringify({ today: 0, yesterday: 1, last7days: 0, last30day: 0, last60day: 0, month: 0, other: 0 }));
                                        }}
                                      >
                                        {props.t("yesterday")}
                                      </button>
                                      <button
                                        className={activeBtn.last7days ? "dateBtns activeBtnDate" : "dateBtns"}
                                        onClick={() => {
                                          buttonHandler(
                                            new Date(new Date(
                                              new Date().getTime() - 6 * 24 * 60 * 60 * 1000
                                            ).toLocaleDateString('en-US', { timeZone: window.localStorage.getItem('timezone') })),
                                            new Date(new Date().toLocaleDateString('en-US', { timeZone: window.localStorage.getItem('timezone') }))
                                          );
                                          setActiveBtn({ today: 0, yesterday: 0, last7days: 1, last30day: 0, last60day: 0, month: 0, other: 0 })
                                          window.localStorage.setItem("activeBtn", JSON.stringify({ today: 0, yesterday: 0, last7days: 1, last30day: 0, last60day: 0, month: 0, other: 0 }));
                                        }}
                                      >
                                        {props.t("last7days")}
                                      </button>
                                      <button
                                        className={activeBtn.last30day ? "dateBtns activeBtnDate" : "dateBtns"}
                                        onClick={() => {
                                          buttonHandler(
                                            new Date(new Date(
                                              new Date().getTime() - 29 * 24 * 60 * 60 * 1000
                                            ).toLocaleDateString('en-US', { timeZone: window.localStorage.getItem('timezone') })),
                                            new Date(new Date().toLocaleDateString('en-US', { timeZone: window.localStorage.getItem('timezone') }))
                                          );
                                          setActiveBtn({ today: 0, yesterday: 0, last7days: 0, last30day: 1, last60day: 0, month: 0, other: 0 })
                                          window.localStorage.setItem("activeBtn", JSON.stringify({ today: 0, yesterday: 0, last7days: 0, last30day: 1, last60day: 0, month: 0, other: 0 }));
                                        }}
                                      >
                                        {props.t("last30days")}
                                      </button>
                                      <button
                                        className={activeBtn.last60day ? "dateBtns activeBtnDate" : "dateBtns"}
                                        onClick={() => {
                                          buttonHandler(
                                            new Date(new Date(
                                              new Date().getTime() - 59 * 24 * 60 * 60 * 1000
                                            ).toLocaleDateString('en-US', { timeZone: window.localStorage.getItem('timezone') })),
                                            new Date(new Date().toLocaleDateString('en-US', { timeZone: window.localStorage.getItem('timezone') }))
                                          );
                                          setActiveBtn({ today: 0, yesterday: 0, last7days: 0, last30day: 0, last60day: 1, month: 0, other: 0 })
                                          window.localStorage.setItem("activeBtn", JSON.stringify({ today: 0, yesterday: 0, last7days: 0, last30day: 0, last60day: 1, month: 0, other: 0 }));
                                        }}
                                      >
                                        {props.t("last60days")}
                                      </button>
                                      <button
                                        className={activeBtn.month ? "dateBtns activeBtnDate" : "dateBtns"}
                                        onClick={() => {
                                          buttonHandler(
                                            new Date(new Date(
                                              new Date().getFullYear(),
                                              new Date().getMonth(),

                                              getToAddOnMonths(new Date().getMonth())
                                            ).toLocaleDateString('en-US', { timeZone: window.localStorage.getItem('timezone') })),
                                            new Date(new Date().toLocaleDateString('en-US', { timeZone: window.localStorage.getItem('timezone') }))
                                          );
                                          setActiveBtn({ today: 0, yesterday: 0, last7days: 0, last30day: 0, last60day: 0, month: 1, other: 0 })
                                          window.localStorage.setItem("activeBtn", JSON.stringify({ today: 0, yesterday: 0, last7days: 0, last30day: 0, last60day: 0, month: 1, other: 0 }));
                                        }}
                                      >
                                        {props.t("thismonth")}
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}
                            <br />
                          </div>
                        </div>
                      </div>
                    </div>
                  </InputGroup>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col col={12} lg={12} >
                <Card>
                  <CardBody>
                    <h4 className="card-title mb-4">{props.t("Simulations")}</h4>
                    <div className="table-responsive">
                      {
                        loadingState ?
                          info !== null ? (
                            <Table className="table table-striped table-bordered">
                              <Table.Body>
                                <Table.Row>
                                  <Table.Cell>{props.t("Average Order Value")}</Table.Cell>
                                  <Table.Cell style={{ color: "#373D3F" }}>
                                    {window.localStorage.getItem("currency") === "CAD"
                                      ? "C"
                                      : window.localStorage.getItem("currency") === "AUD"
                                        ? "A"
                                        : ""}
                                    <b style={{ color: '#373D3F' }}>{info && info.aov && info.total_orders ? (info.aov / (info.total_orders !== 0 ? info.total_orders : 1)).toFixed(2) : 0}</b>
                                  </Table.Cell>
                                </Table.Row>
                                <Table.Row>
                                  <Table.Cell>{props.t("Total Orders")}</Table.Cell>
                                  <Table.Cell>
                                    <b>
                                      {info && info.total_orders ? info.total_orders : 0}
                                    </b>
                                  </Table.Cell>
                                </Table.Row>
                                <Table.Row>
                                  <Table.Cell>{props.t("Average Cost per Order")}</Table.Cell>
                                  <Table.Cell style={{ color: "#373D3F" }}>
                                    {window.localStorage.getItem("currency") === "CAD"
                                      ? "C"
                                      : window.localStorage.getItem("currency") === "AUD"
                                        ? "A"
                                        : ""}
                                    <b style={{ color: '#373D3F' }}>
                                      {(info && info.costGoods) ||
                                        info.merchantFees ||
                                        info.shippingFees
                                        ? (
                                          info.costGoods +
                                          info.shippingFees +
                                          (info.refunds / (info.total_orders !== 0 ? info.total_orders : 1))
                                        ).toFixed(2)
                                        : 0}
                                    </b>
                                  </Table.Cell>
                                </Table.Row>
                                <Table.Row>
                                  <Table.Cell>{props.t("Cost of Goods")}</Table.Cell>
                                  <Table.Cell style={{ color: "rgb(143, 143, 140)" }}>
                                    {window.localStorage.getItem("currency") === "CAD"
                                      ? "C"
                                      : window.localStorage.getItem("currency") === "AUD"
                                        ? "A"
                                        : ""}

                                    <b> {info && info.costGoods ? info.costGoods.toFixed(2) : 0}
                                    </b>
                                  </Table.Cell>
                                </Table.Row>
                                <Table.Row>
                                  <Table.Cell>{props.t("Shipping Fees")}</Table.Cell>
                                  <Table.Cell style={{ color: "rgb(143, 143, 140)" }}>
                                    {window.localStorage.getItem("currency") === "CAD"
                                      ? "C"
                                      : window.localStorage.getItem("currency") === "AUD"
                                        ? "A"
                                        : ""}
                                    {" "}
                                    <b>{info && info.shippingFees
                                      ? info.shippingFees.toFixed(2)
                                      : 0}</b>
                                  </Table.Cell>
                                </Table.Row>
                                <Table.Row>
                                  <Table.Cell>{props.t("Refunds")}</Table.Cell>
                                  <Table.Cell style={{ color: "rgb(143, 143, 140)" }}>
                                    {window.localStorage.getItem("currency") === "CAD"
                                      ? "C"
                                      : window.localStorage.getItem("currency") === "AUD"
                                        ? "A"
                                        : ""}
                                    {" "}
                                    <b>{info && info.refunds
                                      ? (info.refunds / (info.total_orders !== 0 ? info.total_orders : 1)).toFixed(2)
                                      : 0}</b>
                                  </Table.Cell>
                                </Table.Row>
                                <Table.Row>
                                  <Table.Cell>{props.t("Blended CPP")}</Table.Cell>
                                  <Table.Cell style={{ color: "#373D3F" }}>
                                    {window.localStorage.getItem("currency") === "CAD"
                                      ? "C"
                                      : window.localStorage.getItem("currency") === "AUD"
                                        ? "A"
                                        : ""}
                                    <b style={{ color: '#373D3F' }}>{info && info.cps && info.total_orders ? (info.cps / (info.total_orders !== 0 ? info.total_orders : 1)).toFixed(2) : 0}</b>
                                  </Table.Cell>
                                </Table.Row>
                                <Table.Row>
                                  <Table.Cell>ROAS</Table.Cell>
                                  <Table.Cell style={{ color: "#373D3F" }}>
                                    <Icon name='percent' />
                                    <b style={{ color: '#373D3F' }}>{info && info.cps && info.aov ? ((info.aov / (info.cps !== 0 ? info.cps : 1)) * 0.1).toFixed(2) : 0}</b>
                                  </Table.Cell>
                                </Table.Row>
                              </Table.Body>
                            </Table>
                          ) : (
                            <React.Fragment>
                              <Placeholder>
                                <Placeholder.Paragraph>
                                  <Placeholder.Line />
                                  <Placeholder.Line />
                                  <Placeholder.Line />
                                  <Placeholder.Line />
                                  <Placeholder.Line />
                                </Placeholder.Paragraph>
                              </Placeholder>
                              <h4>{props.t("Youdonthaveanyrecords")}</h4>
                            </React.Fragment>
                          )
                          :
                          <div className='text-center'>
                            <div className="text-center">
                              <CardBody>
                                <button type="button" className="btn btn-dark waves-effect waves-light">
                                  <i className="bx bx-loader bx-spin font-size-32 align-middle me-2"></i> {props.t("Loading")}
                                </button>
                              </CardBody>
                            </div>
                          </div>
                      }
                    </div>
                  </CardBody>
                </Card>
              </Col>
              <Card>
                <CardBody>
                  <h4 className="card-title mb-4">{props.t("Recommendations")}</h4>
                  {
                    loadingState ?
                      info !== null ? (
                        <Recommendation info={info} />
                      ) : (
                        <React.Fragment>
                          <Placeholder>
                            <Placeholder.Paragraph>
                              <Placeholder.Line />
                              <Placeholder.Line />
                              <Placeholder.Line />
                              <Placeholder.Line />
                              <Placeholder.Line />
                            </Placeholder.Paragraph>
                          </Placeholder>
                          <button type="button" className="btn btn-warning waves-effect waves-light">
                            <i className="bx bx-error font-size-16 align-middle me-2"></i> {props.t("Warning")}
                          </button>
                        </React.Fragment>
                      )
                      :
                      <div className="text-center">
                        <CardBody>
                          <button type="button" className="btn btn-dark waves-effect waves-light">
                            <i className="bx bx-loader bx-spin font-size-32 align-middle me-2"></i> {props.t("Loading")}
                          </button>
                        </CardBody>
                      </div>
                  }
                </CardBody>
              </Card>
            </Row>
          </div>
        </Container>
      </div>
    </React.Fragment>
  )
}
export default withRouter(withTranslation()(Advisor))
