import gql from 'graphql-tag';


const getInformations = gql`query($startDate:String!, $endDate:String!, $channel:String){
    strat(startDate:$startDate, endDate:$endDate, channel:$channel){
        created,
        sales,
        netProfit,
        aov,
        cps,
        roa,
        avgCPO,
        magicMetrics,
        facebook,
        google,
        profitMargin,
        bRoas
    }
}
`


export {getInformations};