import React from "react"
import ReactApexChart from "react-apexcharts"
import { useSelector, useDispatch } from 'react-redux';
import { withRouter } from "react-router-dom"
import { withTranslation } from "react-i18next"
import PropTypes from "prop-types";
const Spinearea = (props) => {
    const { DataDashboard } = useSelector((state) => state.GoogleAds);
    const spent = DataDashboard.DataDashboard.reports.map((item) => item.cps.toFixed(2));
    const Revenue = DataDashboard.DataDashboard.reports.map((item) => item.conversions_value.toFixed(2));
    const date = DataDashboard.DataDashboard.reports.map((item) => item.date);
    console.log(spent)
  const series = [
    {
      name: props.t('Spent'),
      data: spent,
    },
    {
      name:props.t('Revenue'),
      data: Revenue,
    }
   
  ]

  const options = {
    chart: {
      toolbar: {
        show: false
      }
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
      width: 2,
    },

    colors: ['#0044BD', '#F6B43B'],
    xaxis: {
      type: "category",
      categories: DataDashboard.DataDashboard?.reports.map((item) => {
        const date = new Date(item.date);
        const month = date.toLocaleDateString('fr-FR', { month: 'short' }); // Use 'short' for abbreviated month name
        return `${date.getDate()}${month}`;
      }),
      labels: {
        trim: false, // Display all labels without trimming
      },
    },
    grid: {
      borderColor: "#f1f1f1",
    },
    tooltip: {
      x: {
        format: "dd/MM",
      },
    },
  }

  return (
    <ReactApexChart
      options={options}
      series={series}
      type="bar"
      height="350"
    />
  )
}
Spinearea.propTypes = {
  t: PropTypes.any
};
export default withRouter(withTranslation()(Spinearea));

//export default Spinearea