import React, { useEffect, useState, useRef } from 'react';
import { Card, CardBody, Col, Row } from 'reactstrap';
import { Link } from 'react-router-dom';
import { withTranslation } from "react-i18next"
import Highlighter from 'react-highlight-words';
import PropTypes from 'prop-types';
import { withRouter } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { getMetricsByCampaignsPerDate } from '../../store/googleAds/actions';
import { Space, Button, Dropdown, Menu, Progress, Alert, Input, Checkbox, Select, Tooltip } from 'antd';
import { Table } from 'antd';
import { DownOutlined, SearchOutlined } from '@ant-design/icons';
import { Offcanvas } from 'react-bootstrap';
import FeatherIcon from 'feather-icons-react';
import { CloseOutlined } from '@ant-design/icons';
const GoogleAdsIcon = `${process.env.PUBLIC_URL}/images/integrations_images/GoogleADS.svg`;
const Plus = `${process.env.PUBLIC_URL}/images/integrations_images/pluus.png`;
const datePattern = /(\d{1,2})\/(\d{1,2})\/(\d{4})/;

const GoogleAdsOverview = (props) => {
  // format number with spaces
  const formatNumberWithSpaces = (value) => {
    if (value !== undefined && value !== null) {
      return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    }
    return ""; // or any default value you want to return for undefined/null cases
  };
  const dispatch = useDispatch();
  const { metrcisbyCompaign } = useSelector((state) => state.GoogleAds)
  useEffect(() => {
    var newStartDate = datePattern.exec(props.chosedDate[0].startDate && props.chosedDate[0].startDate
      .toLocaleString("fr-FR"));
    var newEndDate = datePattern.exec(props.chosedDate[0].endDate && props.chosedDate[0].endDate
      .toLocaleString("fr-FR"));
    if (newStartDate && newEndDate) {
      dispatch(getMetricsByCampaignsPerDate(newStartDate[3] + "-" + newStartDate[2] + "-" + newStartDate[1], newEndDate[3] + "-" + newEndDate[2] + "-" + newEndDate[1]));
    }
  }, [props.dateRage, props.chosedDate]);

  // Update state to store the selected option objects
  const [selectedGroups, setSelectedGroups] = useState([null]);
  const [hiddenCards, setHiddenCards] = useState([]);

  const handleHideCard = (cardIndex) => {
    setHiddenCards((prevHidden) => [...prevHidden, cardIndex]);
  };

  const handleSelectGroup = (selectedKey, index) => {
    const updatedGroups = [...selectedGroups];
    const selectedOption = optionGroup.find((option) => option.key === selectedKey);
    updatedGroups[index] = selectedOption;
    setSelectedGroups(updatedGroups);
  };
  const handlePlusClick = () => {
    setSelectedGroups((prevGroups) => [...prevGroups, null]);
  };

  const [checkboxStates, setCheckboxStates] = useState({
    spendisChecked: true,
    RevenueisChecked: true,
    ConversionsisChecked: true,
    CPAisChecked: true,
    ImpressionsChecked: false,
    InteractionsChecked: false,
    ClicksChecked: false,
    CTRChecked: false,
    AOVChecked: false,
    CRChecked: false,
    ROASChecked: false,
    CPCChecked: false,


  });
  const optionGroup = [
    {
      key: 'spend',
      label: 'Spend',
      value: props.google
        ? `$${formatNumberWithSpaces(props.google.totalCost.toFixed(2) || 0)}`
        : '$0',
    },
    {
      key: 'Revenue',
      label: 'Revenue',
      value: props.google ? `$${formatNumberWithSpaces(props.google.totalconversionValue.toFixed(2) || 0)}` : '$0',
    },
    {
      key: 'Conversions',
      label: 'Conversions',
      value: props.google ? (props.google.totalConversions ? props.google.totalConversions.toFixed(2) : 0) : 0
    },
    {
      key: 'CPA',
      label: 'CPA',
      value: props.google ? `$${formatNumberWithSpaces(props.google.totalCPA.toFixed(2) || 0)}` : '$0'

    },
    {
      key: 'Impressions',
      label: 'Impressions',
      value: props.google ? (props.google.totalImpressions ? formatNumberWithSpaces(props.google.totalImpressions) : 0) : 0

    },
    {
      key: 'Interactions',
      label: 'Interactions',
      value: props.google ? (props.google.totalinteractions ? formatNumberWithSpaces(props.google.totalinteractions) : 0) : 0

    },
    {
      key: 'Clicks',
      label: 'Clicks',
      value: props.google ? (props.google.totalClicks ? formatNumberWithSpaces(props.google.totalClicks) : 0) : 0
    },
    {
      key: 'CTR',
      label: 'CTR',
      value: props.google && props.google.totalctr ? ` ${props.google.totalctr.toFixed(2) || 0}%` : '0%'
    },
    {
      key: 'CPC',
      label: 'CPC',
      value: props.google && props.google.totalCPC
        ? `$${formatNumberWithSpaces(props.google.totalCPC.toFixed(2))}`
        : '$0',
    },
    {
      key: 'AOV',
      label: 'AOV',
      value: props.google ? `$${formatNumberWithSpaces(props.google.totalAOV.toFixed(2) || 0)}` : '$0'

    },
    {
      key: 'CR',
      label: 'CR',
      value: props.google ? ` ${props.google.totalCVR.toFixed(2)}%` : '0%'

    },
    {  key: 'ROAS',
      label: 'ROAS',
      value: props.google ? `x ${props.google.totalROAS.toFixed(2)}` : '0%'
     

    }

  ];
  const MetricCard = ({ selectedGroup, handleSelectGroup, checkboxState, cardIndex, handleHideCard }) => {
    const isCardHidden = hiddenCards.includes(cardIndex);
    return (
      <Col xl={2} md={4}>
        <Card className="text-center">
          <CardBody className="facebook-ads-card">
            <div className="my-0 text-primary d-flex justify-content-between align-items-center">
              <Select
                placeholder={props.t("select metrics")}
                value={selectedGroups[cardIndex] ? selectedGroups[cardIndex].label : undefined}
                onChange={(selectedKey) => handleSelectGroup(selectedKey, cardIndex)}
                style={{
                  width: '70%',
                  borderColor: 'initial',
                  fontWeight: 'bold',
                  margin: 'auto',
                }}
                dropdownStyle={{ zIndex: 9999 }}
              >
                {optionGroup.map((option, index) => (
                  <Select.Option key={index} value={option.key}>
                    {option.label}
                  </Select.Option>
                ))}
              </Select>
              <div style={{ position: 'absolute', top: 5, right: 5, }}>
                <Tooltip title="Delete Metric">
                  <CloseOutlined onClick={() => handleHideCard(cardIndex)} style={{ cursor: 'pointer' }} />
                </Tooltip>
              </div>
            </div>
            {/* <h4>{selectedGroup ? selectedGroup.label : ''}</h4> */}
            <Row className="justify-content-center">
              <Col xs={12} className="d-flex justify-content-center">
                <div className="mt-3">
                  <h4> {selectedGroup ? selectedGroup.value : ''} </h4>
                </div>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Col>
    );
  };

  const PlusCard = ({ onClick }) => {
    return (
      <Col xl={2} md={2}>
        <form>
          <Card className="dropzone custom-hover cursor-pointer" onClick={onClick} style={{ height: '97px' }}>
            <CardBody className="text-center d-flex align-items-center justify-content-center">
              <img className="img-overview-dashboard ms-2" src={Plus} />
            </CardBody>
          </Card>
        </form>
      </Col>
    );
  };
  // const handleToggle = (key) => {
  //   setCheckboxStates((prevStates) => ({
  //     ...prevStates,
  //     [key]: !prevStates[key],
  //   }));
  // };
  // const [isOffcanvasVisible, setOffcanvasVisible] = useState(false);

  // const showOffcanvas = () => {
  //   setOffcanvasVisible(true);
  // };

  // const hideOffcanvas = () => {
  //   setOffcanvasVisible(false);
  // };

  const [filteredInfo, setFilteredInfo] = useState({});
  const [sortedInfo, setSortedInfo] = useState({});
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const [paginationOptions, setPaginationOptions] = useState({
    pageSize: 5, // Default page size
    pageSizeOptions: ['5', '10', '20'], // Available page size options
  });
  const searchInput = useRef(null);
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: 'block',
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1677ff' : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: '#ffc069',
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });
  const handleChange = (pagination, filters, sorter) => {
    setFilteredInfo(filters);
    setSortedInfo(sorter);
    if (pagination.pageSize !== paginationOptions.pageSize) {
      setPaginationOptions({
        ...paginationOptions,
        pageSize: pagination.pageSize,
      });
    }
  };
  const clearFilters = () => {
    setFilteredInfo({});
  };
  const clearAll = () => {
    setFilteredInfo({});
    setSortedInfo({});
    // make the columns  selected
    setSelectedColumns(columns.map((column) => column.key));
  };
  const setAgeSort = () => {
    setSortedInfo({
      order: 'descend',
      columnKey: 'impressions',
    });
  };

  const columns = [
    {
      title: '#',
      dataIndex: 'id',
      key: 'id',
      render: (text, record, index) => <strong> {index + 1}</strong>,
      width: 50,
      align: 'center',
      filters: [
        {
          text: 'ENABLED',
          value: 2,
        },
        {
          text: 'PAUSED',
          value: 3,
        },

      ],
      filteredValue: filteredInfo.id || null,
      onFilter: (value, record) => {
        if (value === 2) {
          return record.status === 2;
        } else if (value === 3) {
          return record.status === 3;
        }
        return true; // Return true if no filter is applied
      },
    },
    {
      title: props.t('Campaign Name'),
      dataIndex: 'campaign_name',
      key: 'campaign_name',
      align: 'center',
      width: 300,
      filteredValue: filteredInfo.campaign_name || null,
      onFilter: (value, record) => record.campaign_name.includes(value),
      ...getColumnSearchProps('campaign_name'),
      //  sortOrder: sortedInfo.columnKey === 'name' ? sortedInfo.order : null,
      ellipsis: true,
    },
    {
      title: props.t('Spend'),
      dataIndex: 'metrics.totalCost',
      align: 'center',
      key: 'spend',
      sorter: (a, b) => a.metrics.totalCost - b.metrics.totalCost,
      sortOrder: sortedInfo && sortedInfo.columnKey === 'spend' ? sortedInfo.order : null,
      ellipsis: true,
      render: (text, record) => <strong>{record.metrics.totalCost.toFixed(2)}$</strong>,
    },
    {
      title: props.t('Revenue'),
      dataIndex: 'totalConversionsValue',
      key: 'totalConversionsValue',
      sorter: (a, b) => a.metrics.totalConversionsValue - b.metrics.totalConversionsValue,
      sortOrder: sortedInfo.columnKey === 'totalConversionsValue' ? sortedInfo.order : null,
      ellipsis: true,
      align: 'center',
      render: (text, record) => <strong>{record.metrics.totalConversionsValue.toFixed(2)}$</strong>,
    },
    {
      title: props.t('Conversions'),
      dataIndex: 'totalConversions',
      key: 'totalConversions',
      align: 'center',
      sorter: (a, b) => a.metrics.totalConversions - b.metrics.totalConversions,
      sortOrder: sortedInfo.columnKey === 'totalConversions' ? sortedInfo.order : null,
      ellipsis: true,
      render: (text, record) => <strong>{record.metrics.totalConversions.toFixed(2)}</strong>,

    },
    {
      title: props.t('Impressions'),
      dataIndex: 'metrics.totalImpressions',
      key: 'impressions',
      sorter: (a, b) => a.metrics.totalImpressions - b.metrics.totalImpressions,
      sortOrder:
        sortedInfo.columnKey === 'impressions' ? sortedInfo.order : null,
      ellipsis: true,
      align: 'center',
      render: (text, record) => <strong>{formatNumberWithSpaces(record.metrics.totalImpressions)}</strong>,
    },

    {
      title: props.t('Interactions'),
      dataIndex: 'metrics.totalInteractions',
      key: 'totalInteractions',
      align: 'center',
      sorter: (a, b) => a.metrics.totalInteractions - b.metrics.totalInteractions,
      sortOrder:
        sortedInfo.columnKey === 'totalInteractions' ? sortedInfo.order : null,
      ellipsis: true,
      render: (text, record) => <strong>{formatNumberWithSpaces(record.metrics.totalInteractions)}</strong>,
    },
    {
      title: props.t('Clicks'),
      dataIndex: 'metrics.totalClicks',
      align: 'center',
      key: 'totalClicks',
      sorter: (a, b) => a.metrics.totalClicks - b.metrics.totalClicks,
      sortOrder: sortedInfo.columnKey === 'totalClicks' ? sortedInfo.order : null,
      ellipsis: true,
      render: (text, record) => <strong>{formatNumberWithSpaces(record.metrics.totalClicks)}</strong>,
    },


    // {
    //   title: 'New sessions',
    //   dataIndex: 'totalNewSessions',
    //   align: 'center',
    //   key: 'totalNewSessions',
    //   sorter: (a, b) => a.metrics.totalNewSessions - b.metrics.totalNewSessions,
    //   sortOrder: sortedInfo.columnKey === 'totalNewSessions' ? sortedInfo.order : null,
    //   ellipsis: true,
    //   render: (text,record) => <strong>{record.metrics.totalNewSessions}</strong>,
    // },
    {
      title: 'CTR',
      dataIndex: 'metrics.ctr',
      align: 'center',
      key: 'ctr',
      sorter: (a, b) => a.metrics.ctr - b.metrics.ctr,
      sortOrder: sortedInfo.columnKey === 'ctr' ? sortedInfo.order : null,
      ellipsis: true,
      render: (text, record) => <strong>{record.metrics?.ctr?.toFixed(2)}%</strong>,
    },
    {
      title: 'CPC',
      dataIndex: 'metrics.cpc',
      align: 'center',
      key: 'cpc',
      sorter: (a, b) => a.metrics.cpc - b.metrics.cpc,
      sortOrder: sortedInfo.columnKey === 'cpc' ? sortedInfo.order : null,
      ellipsis: true,
      render: (text, record) => <strong>{record.metrics?.cpc?.toFixed(2)}$</strong>,
    },
    {
      title: 'CPA',
      dataIndex: 'metrics.cpa',
      align: 'center',
      key: 'CPA',
      sorter: (a, b) => a.metrics.cpa - b.metrics.cpa,
      sortOrder: sortedInfo.columnKey === 'CPA' ? sortedInfo.order : null,
      ellipsis: true,
      render: (text, record) => <strong>{record.metrics?.cpa?.toFixed(2)}$</strong>,
    },
    // {
    //   title: 'CR',
    //   dataIndex: 'metrics.conversionRate',
    //   align: 'center',
    //   key: 'conversionRate',
    //   sorter: (a, b) => a.metrics.conversionRate - b.metrics.conversionRate,
    //   sortOrder: sortedInfo.columnKey === 'conversionRate' ? sortedInfo.order : null,
    //   ellipsis: true,
    //   render: (text,record) => <strong>%{record.metrics?.conversionRate?.toFixed(2)}</strong>,
    // },
    // {
    //   title: 'AverageCost',
    //   dataIndex: 'metrics.totalAverageCost',
    //   align: 'center',
    //   key: 'totalAverageCost',
    //   sorter: (a, b) => a.metrics.totalAverageCost - b.metrics.totalAverageCost,
    //   sortOrder: sortedInfo.columnKey === 'totalAverageCost' ? sortedInfo.order : null,
    //   ellipsis: true,
    //   render: (text,record) => <strong>{record.metrics?.totalAverageCost?.toFixed(2)}</strong>,
    // },
    // {
    //   title: 'AverageCpc',
    //   dataIndex: 'metrics.totalAverageCpc',
    //   align: 'center',
    //   key: 'totalAverageCpc',
    //   sorter: (a, b) => a.metrics.totalAverageCpc - b.metrics.totalAverageCpc,
    //   sortOrder: sortedInfo.columnKey === 'totalAverageCpc' ? sortedInfo.order : null,
    //   ellipsis: true,
    //   render: (text,record) => <strong>{record.metrics?.totalAverageCpc?.toFixed(2)}</strong>,
    // },
    // {
    //   title: 'AOV',
    //   dataIndex: 'metrics.AOV',
    //   align: 'center',
    //   key: 'AOV',
    //   sorter: (a, b) => a.metrics.AOV - b.metrics.AOV,
    //   sortOrder: sortedInfo.columnKey === 'AOV' ? sortedInfo.order : null,
    //   ellipsis: true,
    //   render: (text,record) => <strong>{record.metrics?.AOV?.toFixed(2)}$</strong>,
    // }, 
    // {
    //   title: 'SearchImpressionShare',
    //   dataIndex: 'metrics.totalSearchImpressionShare',
    //   align: 'center',
    //   key: 'totalSearchImpressionShare',
    //   sorter: (a, b) => a.metrics.totalSearchImpressionShare - b.metrics.totalSearchImpressionShare,
    //   sortOrder: sortedInfo.columnKey === 'totalSearchImpressionShare' ? sortedInfo.order : null,
    //   ellipsis: true,
    //   render: (text,record) => <strong>{record.metrics?.totalSearchImpressionShare?.toFixed(2)}</strong>,
    // },
    // {
    //   title: 'ROAs',
    //   dataIndex: 'metrics.ROAs',
    //   align: 'center',
    //   key: 'ROAs',
    //   fixed: 'right',
    //   width: 100,
    //   sorter: (a, b) => a.metrics.ROAs - b.metrics.ROAs,
    //   sortOrder: sortedInfo.columnKey === 'ROAs' ? sortedInfo.order : null,
    //   ellipsis: true,
    //   render: (text,record) => <strong>{record.metrics?.ROAs?.toFixed(2)}</strong>,
    // },
  ];
  const [selectedColumns, setSelectedColumns] = useState(
    columns.map((column) => column.key)
  );

  const handleMenuClick = (clickedKey) => {
    const index = selectedColumns.indexOf(clickedKey);

    let updatedSelectedColumns = [];

    if (index === -1) {
      // Column was not selected before, add it to the selected columns
      updatedSelectedColumns = [...selectedColumns, clickedKey];
    } else {
      // Column was selected before, remove it from the selected columns
      updatedSelectedColumns = selectedColumns.filter(
        (key) => key !== clickedKey
      );
    }

    setSelectedColumns(updatedSelectedColumns);
  };

  const filteredColumns = columns.filter((column) =>
    selectedColumns.includes(column.key)
  );
  // const [selectedGroup, setselectedGroup] = useState(null);
  // const [selectedGroup2, setselectedGroup2] = useState(null);
  // const [selectedGroup3, setselectedGroup3] = useState(null);
  // const [selectedGroup4, setselectedGroup4] = useState(null);


  // function handleSelectGroup(selectedKey) {
  //   // Find the full option object based on the selected key
  //   const selectedOption = optionGroup.find(option => option.key === selectedKey);
  //   // console.log(selectedOption.value); // This will log the full object of the selected option
  //   // If you have a state setter function to update the selected group, you can call it here
  //   setselectedGroup(selectedOption.value);
  // }
  // function handleSelectGroup2(selectedKey) {
  //   // Find the full option object based on the selected key
  //   const selectedOption = optionGroup.find(option => option.key === selectedKey);
  //   //  console.log(selectedOption.value); // This will log the full object of the selected option
  //   // If you have a state setter function to update the selected group, you can call it here
  //   setselectedGroup2(selectedOption.value);
  // }

  // function handleSelectGroup3(selectedKey) {
  //   // Find the full option object based on the selected key
  //   const selectedOption = optionGroup.find(option => option.key === selectedKey);
  //   console.log(selectedOption.value); // This will log the full object of the selected option
  //   // If you have a state setter function to update the selected group, you can call it here
  //   setselectedGroup3(selectedOption.value);
  // }
  // function handleSelectGroup4(selectedKey) {
  //   // Find the full option object based on the selected key
  //   const selectedOption = optionGroup.find(option => option.key === selectedKey);
  //   //  console.log(selectedOption.value); // This will log the full object of the selected option
  //   // If you have a state setter function to update the selected group, you can call it here
  //   setselectedGroup4(selectedOption.value);
  // }

  const menu = (
    <Menu>
      {columns.map((column) => (
        <Menu.Item
          key={column.key}
          onClick={() => handleMenuClick(column.key)}
        >
          <Checkbox
            checked={selectedColumns.includes(column.key)}
            style={{ marginRight: '8px' }}
          />
          <span
            style={
              selectedColumns.includes(column.key)
                ? { fontWeight: 'bold' }
                : {}
            }
          >
            {column.title}
          </span>
        </Menu.Item>
      ))}
    </Menu>
  );
  return (
    <React.Fragment>
      <Col xl={12}>
        <div className="row mb-4">
          <div className="col">
            <h5 className="card-title d-flex align-items-center">
              <img className="img-overview-dashboard ms-2" src={GoogleAdsIcon} style={{ marginTop: '-40px', height: '40px' }} />
              <h5 className="card-title ms-2" style={{ marginTop: '-33px' }}>{props.t("Google Ads performance")}</h5>
              {/* <i
                className="bx bx-edit ms-auto"
                style={{
                  cursor: 'pointer',
                  position: 'relative',
                  top: '-30px',
                  left: '10px',
                  fontSize: '1.5em', // Change the font-size value to increase the icon size
                }}
                onClick={showOffcanvas}
              ></i> */}
            </h5>
          </div>
        </div>

        <Row>
          <Row className="justify-content-center">
            {selectedGroups.map((selectedGroup, index) => (
              !hiddenCards.includes(index) && (

                <MetricCard
                  key={index}
                  selectedGroup={selectedGroup}
                  handleSelectGroup={(selectedKey) => handleSelectGroup(selectedKey, index)}
                  //checkboxState={checkboxStates}
                  cardIndex={index}
                  handleHideCard={handleHideCard}
                />
              )
            ))}
            <PlusCard onClick={handlePlusClick} />
          </Row>
        </Row>
        <hr />
        <Row>
          <Card style={{ border: "none" }}>
            <CardBody >
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
              >
                <h4 className="card-title mb-4">
                  {props.t('Google Ads Campaigns')}
                </h4>
                <img className="img-intgration-icon" src={GoogleAdsIcon} />
              </div>
              <Space
                style={{
                  marginBottom: 16,
                }}
              >
                <Button onClick={setAgeSort}>{props.t("Sort Impressions")}</Button>
                <Button onClick={clearFilters}>{props.t("Clear filters")}</Button>
                <Button onClick={clearAll}>{props.t("Clear filters and sorters")}</Button>
                <Dropdown overlay={menu} trigger={['click']}>
                  <Button>{props.t("Select Columns")}</Button>
                </Dropdown>
              </Space>

              <Table className="" style={{ border: "1px solid #e8e8e8" }}
                columns={filteredColumns}
                bordered
                dataSource={metrcisbyCompaign}
                onChange={handleChange}
                pagination={{
                  ...paginationOptions,
                  onChange: handleChange,
                  showSizeChanger: true, // Enable the page size selector
                  // showTotal: (total) => `Total ${total} items`, // Optionally show total items
                  // Other pagination settings
                }}
                scroll={{
                  x: 1500,
                }}
              />
            </CardBody>
          </Card>
        </Row>


        <div className="mt-2-overview-dashboard">
          <Link to="/google-dashboard" className="btn btn-primary btn-sm">{props.t("View more")} <i className="overview-dashboard-icon mdi mdi-arrow-right ms-1"></i></Link>
        </div>

      </Col>
    </React.Fragment>
  );
}
GoogleAdsOverview.propTypes = {
  t: PropTypes.any
};
export default withRouter(withTranslation()(GoogleAdsOverview));
