import React ,{useState, useEffect}from "react"
import { Row, Col, Card, CardBody, CardTitle, CardHeader } from "reactstrap"
import MetaTags from 'react-meta-tags'
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, {
  PaginationProvider, PaginationListStandalone,
  SizePerPageDropdownStandalone
} from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import {
    getOders , getOneOrder
  } from '../../../queries/OrdersQueries/queries';
import ApolloClient from "apollo-boost";
import urls from '../../../routes/apiUrls';
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import "../datatables.scss"
import { withTranslation } from "react-i18next"
import { withRouter } from "react-router-dom"
import PropTypes from 'prop-types';

const ApploCLI = new ApolloClient({
    uri: urls.orders,
  });
function AllOrders(props){
 const [edit, setEdit] = useState(false);
 const [orders, setOrders] = useState([]);
 const [orderdetail, setOrderDetails] = useState([]);
 const [productsClone, setProductsClone] = useState([]);
 const [orderdetailsClone, setOrderDetailsClone] = useState([]);
 const [loadingState, setLoadingState] = useState(true);
 const [isSearch, setIsSearch] = useState(false)
 const [page, setPage] = useState(1)
 const [pages, setPages] = useState(-1);
 const [Order_id, setOrderID] = useState(1)
const columns = [{
    dataField: 'id',
    text: 'id',
    sort: true,
  }
  , {
    dataField: 'total',
    text: props.t("Total"),
    sort: true
  }, {
    dataField: 'subtotal',
    text: props.t("Subtotal"),
    sort: true
  }, {
    dataField: 'shipping_cost',
    text: props.t("Shipping_cost"),
    sort: true
  },
  {
    text: props.t("View Details"),
    dataField: "",
    sort: false,
    formatter: () => (
      <>
       <div className="col-xl-4">
        <button 
        type="button" 
        className="btn btn-success waves-effect btn-label waves-light"
        //onClick={async () => GetSingleOrder()}
        >
          <i className="mdi mdi-pencil d-block font-size-16"></i> 
          {props.t("Success")}
          </button>
        </div>
      </>
    ),
  },
];
  const dataMapOrders = orders.map((item,index) => { return item});
  const defaultSorted = [{
    dataField: 'id',
    order: 'asc'
  }];
  const pageOptions = {
    sizePerPage: 5,
    totalSize: dataMapOrders.length, 
    custom: true,
  }
  const selectRow = {
    mode: 'checkbox'
  }
// const  GetSingleOrder =id => {
//   if(!isSearch ){
//   let isSubscribed = true
//   setLoadingState(false);
//   ApploCLI.query({
//     query: getOneOrder,
//     variables: {
//       id : 20921,
//     },
//     context: {
//       headers: {
//         Authorization: window.localStorage.getItem("token")
//           ? `Bearer ${window.localStorage.getItem("token")}`
//           : "",
//       },
//     },
//     errorPolicy: "all",
//     fetchPolicy: "no-cache",
//   }).then((data) => {
//     if (isSubscribed) {
//       if (data && data.data && data.data.one && data.data.one.length > 0){
//             setOrderDetails(data.data.one);
//             setOrderDetailsClone(data.data.one);
//       }
//       setLoadingState(true);
//     }
//   });
//   return () => (isSubscribed = false)
// }
// }
  const { SearchBar } = Search;
useEffect(() => {

    if(!isSearch ){
    let isSubscribed = true
    setLoadingState(false);
    ApploCLI.query({
      query: getOders,
      variables: {
        limit: 5,
        page:page
      },
      context: {
        headers: {
          Authorization: window.localStorage.getItem("token")
            ? `Bearer ${window.localStorage.getItem("token")}`
            : "",
        },
      },
      errorPolicy: "all",
      fetchPolicy: "no-cache",
    }).then((data) => {
      if (isSubscribed) {
        if (data && data.data && data.data.all && data.data.all.length > 0){
          setPages(data.data.all[0].pages)
              setOrders(data.data.all);
              setProductsClone(data.data.all);
        }
        setLoadingState(true);
      }
    });
    return () => (isSubscribed = false)
  }
  }, [edit, page]);
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>{props.t("All PrestaShop Orders")}| Live-Metrics - E-com SaaS</title>
        </MetaTags>
        <div className="container-fluid">
          <Breadcrumbs title="PrestaShop" breadcrumbItem={props.t("All Orders")} />
          <Row>
            <Col className="col-12">
              <Card>
                <CardHeader>
                  <CardTitle className="h4">{props.t("All PrestaShop Orders")} </CardTitle>
                </CardHeader>
                <CardBody>
                  <PaginationProvider
                    pagination={paginationFactory(pageOptions)}
                    keyField='id'
                    columns={columns}
                    data={dataMapOrders}
                  >
                    {({ paginationProps, paginationTableProps }) => (
                      <ToolkitProvider
                        keyField='id'
                        columns={columns}
                        data={dataMapOrders}
                        search
                      >
                        {toolkitProps => (
                          <React.Fragment>
                            <Row className="mb-2">
                              <Col md="4">
                                <div className="search-box me-2 mb-2 d-inline-block">
                                  <div className="position-relative">
                                    <SearchBar
                                      {...toolkitProps.searchProps}
                                    />
                                    <i className="bx bx-search-alt search-icon" />
                                  </div>
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col xl="12">
                                <div className="table-responsive">
                                  <BootstrapTable
                                    keyField={"id"}
                                    responsive
                                    bordered={false}
                                    striped={false}
                                    defaultSorted={defaultSorted}
                                    selectRow={selectRow}
                                    classes={
                                      "table align-middle table-nowrap"
                                    }
                                    headerWrapperClasses={"thead-light"}
                                    {...toolkitProps.baseProps}
                                    {...paginationTableProps}
                                  />
                                </div>
                              </Col>
                            </Row>
                            <Row className="align-items-md-center mt-30">
                              <Col className="inner-custom-pagination d-flex">
                                <div className="d-inline">
                                  <SizePerPageDropdownStandalone
                                    {...paginationProps}
                                  />
                                </div>
                                <div className="text-md-right ms-auto">
                                  <PaginationListStandalone
                                    {...paginationProps}
                                  />
                                </div>
                              </Col>
                            </Row>
                          </React.Fragment>
                        )
                        }
                      </ToolkitProvider>
                    )
                    }</PaginationProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  )
}
AllOrders.propTypes = {
  t: PropTypes.any
};
export default withRouter(withTranslation() (AllOrders));
//export default AllOrders
