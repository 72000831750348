import React, { useState } from 'react';
import MetaTags from 'react-meta-tags';
import { Row, Col, Container } from 'reactstrap';
import { Form } from 'react-final-form';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import logo from '../../assets/images/Live_Metrics_Logo.png';
import CarouselPage from '../AuthenticationInner/CarouselPage';
import { useDispatch } from 'react-redux';
import {
  registerRequest,
  registerWithGoogleRequest,
} from '../../store/auth/actions';
import { useGoogleLogin } from '@react-oauth/google';
import Gooogleicon from '../../assets/images/google.png';

function Register() {
  const dispatch = useDispatch();
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [errors, setErrors] = useState({ name: '', email: '' });

  const onSuccess = (response) => {
    console.log('front de');
    if (response && response.access_token) {
      const tokenString = response.access_token;
      console.log('Google login response:', tokenString);
      dispatch(registerWithGoogleRequest({ token: tokenString }));
    } else {
      console.error(
        'Google login response is missing necessary data:',
        response
      );
    }
  };
  const onError = (error) => {
    if (error.error === 'popup_closed_by_user') {
      console.log('User closed the Google login popup.');
    } else {
      console.error('Google login failed:', error);
    }
  };
  const signUpWithGoogle = useGoogleLogin({
    onSuccess,
    onError,
    flow: 'implicit', // Adjust flow based on your OAuth setup
  });

  const verif_inputs = () => {
    let err = { name: '', email: '' };
    if (name.length < 3) {
      err.name = (
        <p style={{ color: 'red' }}>first name required at least 3 caracters</p>
      );
    } else {
      err.name = false;
    }
    if (
      !/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
        email
      )
    ) {
      err.email = <p style={{ color: 'red' }}>invalid email address</p>;
    } else {
      err.email = false;
    }
    setErrors(err);
    return err;
  };

  const onSubmit = () => {
    const errors = verif_inputs();
    if (!errors.name && !errors.email) {
      dispatch(registerRequest(email, name));
    }
  };
  const { handleSubmit } = useForm();
  return (
    <React.Fragment>
      <MetaTags>
        <title>Register | Convergen-Metrics - E-com SaaS</title>
      </MetaTags>
      <div className="auth-page">
        <Container fluid className="p-0">
          <Row className="g-0">
            <Col lg={4} md={5} className="col-xxl-3">
              <div className="auth-full-page-content d-flex p-sm-5 p-4">
                <div className="w-100">
                  <div className="d-flex flex-column h-100">
                    <div className="mb-4 mb-md-5 text-center">
                      <a
                        href="https://live-metrics.io/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img src={logo} alt="" height="50" />
                      </a>
                    </div>
                    <div className="auth-content my-auto">
                      <div className="text-center">
                        <h5 className="mb-0">Register Account</h5>
                        <p className="text-muted mt-2">
                          Get your free LiveMetrics account now.
                        </p>
                      </div>
                      <Form
                        className="needs-validation custom-form mt-4 pt-2"
                        onSubmit={handleSubmit(onSubmit)}
                      >
                        {({ handleSubmit }) => (
                          <form onSubmit={handleSubmit}>
                            <div className="mb-3">
                              <input
                                type="text"
                                id="email"
                                name="email"
                                required
                                placeholder="Enter email"
                                className="form-control"
                                value={email}
                                onChange={(event) =>
                                  setEmail(event.target.value)
                                }
                              />
                              {errors.email && errors.email}
                            </div>
                            <div className="mb-3">
                              <input
                                type="text"
                                id="name"
                                name="name"
                                required
                                placeholder="Enter username"
                                className="form-control"
                                value={name}
                                onChange={(event) =>
                                  setName(event.target.value)
                                }
                              />
                              {errors.name && errors.name}
                            </div>
                            <div className="mb-4">
                              <p className="mb-0">
                                By registering you agree to the LiveMetris{' '}
                                <Link to="#" className="text-primary">
                                  Terms of Use
                                </Link>
                              </p>
                            </div>
                            <div className="mb-3">
                              <button
                                className="btn btn-primary w-100 waves-effect waves-light mb-2"
                                type="submit"
                              >
                                Register
                              </button>
                            </div>
                          </form>
                        )}
                      </Form>

                      <button
                        onClick={signUpWithGoogle}
                        className="btn btn-light w-100 waves-effect mb-2 d-flex align-items-center justify-content-center"
                        type="submit"
                        style={{ padding: '10px' }}
                      >
                        <img
                          src={Gooogleicon}
                          alt=""
                          height="20"
                          style={{ marginRight: '15px' }}
                        />
                        <span> Sign up with Google</span>
                      </button>
                      <div className="mt-5 text-center">
                        <p className="text-muted mb-0">
                          Already have an account ?{' '}
                          <Link
                            to="/login"
                            className="text-primary fw-semibold"
                          >
                            {' '}
                            Login{' '}
                          </Link>{' '}
                        </p>
                      </div>
                    </div>
                    <div className="mt-4 mt-md-5 text-center">
                      <p className="mb-0">
                        © {new Date().getFullYear()} LiveMetrics .Crafted by
                        Convergen
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
            <CarouselPage />
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
}
export default Register;
