import React, { useState, useEffect, useRef } from 'react';
import MetaTags from 'react-meta-tags';
import Breadcrumbs from '../../components/Common/Breadcrumb';
import Select from "react-select"
import { Link } from 'react-router-dom';
import {
  getGoogleMetrics,
} from '../../store/googleAds/actions';
import {
  generateUrl,
  getGooglePlatform,
  getAccounts,
  integrate,
  deleteGoogle,
} from "../../queries/Google/googleQueries";
import axios from "axios";
import { useDispatch, useSelector } from 'react-redux';
import { DateRange } from 'react-date-range';
import 'flatpickr/dist/themes/material_blue.css';
import GoogleAdsDataCard from './GoogleAdsDataCard';
import {
  Row,
  Col,
  FormGroup,
  InputGroup,
  Card,
  Modal,
  CardHeader,
  CardTitle,
  CardBody,
} from 'reactstrap';
import ApolloClient from "apollo-boost";
import secureSection from '../../MangeSecurity/MemberSecurity';
import ReactEcharts from 'echarts-for-react';
import FeatherIcon from "feather-icons-react";
import Button from 'react-bootstrap/Button';
import urls from "../../routes/apiUrls";
import Offcanvas from 'react-bootstrap/Offcanvas';
import OffcanvasHeader from 'react-bootstrap/OffcanvasHeader';
import OffcanvasBody from 'react-bootstrap/OffcanvasBody';
import { withRouter } from "react-router-dom"
import { withTranslation } from "react-i18next"
import PropTypes from "prop-types";
const ApploCLI = new ApolloClient({
  uri: urls.google,
});
const daysBetween = function (date1, date2) {
  var one_day = 1000 * 60 * 60 * 24;
  var date1_ms = date1.getTime();
  var date2_ms = date2.getTime();
  var difference_ms = date2_ms - date1_ms;
  return Math.round(difference_ms / one_day);
};
function useOutsideAlerter(ref, toggleDate) {
  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        toggleDate(false);
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref]);
}
const datePattern = /(\d{1,2})\/(\d{1,2})\/(\d{4})/;

function GoogleDashboard(props) {
  secureSection();
  const dispatch = useDispatch();
  const { DataDashboard } = useSelector(
    (state) => state.GoogleAds
  );
  const [isOffcanvasOpen, setIsOffcanvasOpen] = useState(false);

  const toggleOffcanvas = () => {
    setIsOffcanvasOpen(!isOffcanvasOpen);
  };

  const wrapperRef = useRef(null);

  const [toggleDate, setToggleDate] = useState(false);
  useOutsideAlerter(wrapperRef, setToggleDate);
  const [dateRage, setDateRange] = useState({
    date: [
      window.localStorage.getItem('start')
        ? new Date(window.localStorage.getItem('start'))
        : new Date(
          new Date().toLocaleDateString('en-US', { timeZone: props.timeZone })
        ),
      window.localStorage.getItem('end')
        ? new Date(window.localStorage.getItem('end'))
        : new Date(
          new Date().toLocaleDateString('en-US', { timeZone: props.timeZone })
        ),
    ],
  });
  const [lastPeriod, setLastPeriod] = useState('');
  const [chosedDate, setChosedDate] = useState([
    {
      startDate: window.localStorage.getItem('start')
        ? new Date(window.localStorage.getItem('start'))
        : new Date(
          new Date().toLocaleDateString('en-US', { timeZone: props.timeZone })
        ),
      endDate: window.localStorage.getItem('end')
        ? new Date(window.localStorage.getItem('end'))
        : new Date(
          new Date().toLocaleDateString('en-US', { timeZone: props.timeZone })
        ),
      key: 'selection',
    },
  ]);

  function removeBodyCss() {
    document.body.classList.add("no_padding")
  }
  const checkcurrency = localStorage.getItem("google_currency_revenue_rate")
  // const [ads, setAds] = useState([]);
  // const [metrcisbyCompaign, setMetrcisbyCompaign] = useState([]);
  const [currentFocus, setCurrentFocus] = useState([]);
  // const [dataDashboard, setDataDashboard] = useState([]);
  const [sales, setSales] = useState([]);
  const [grossProfit, setGrossProfit] = useState([]);
  const [cps, setCps] = useState([]);
  const [aov, setAov] = useState([]);
  const [clicks, setClicks] = useState([]);
  const [impressions, setImpressions] = useState([]);
  const [conversions, setConversions] = useState([]);
  const [conversions_value, setConversionValue] = useState([]);
  const [interactions, setInteractions] = useState([]);
  const [new_sessions, setNewSessions] = useState([]);
  const [nmbrOfOrders, setNmbrOfOrders] = useState([]);
  const [lastSales, setLastSales] = useState([]);
  const [lastGrossProfit, setLastGrossProfit] = useState([]);
  const [lastCps, setLastCps] = useState([]);
  const [lastAov, setLastAov] = useState([]);
  const [lastClicks, setlastClicks] = useState([]);
  const [lastImpressions, setlastImpressions] = useState([]);
  const [lastConversions, setlastConversions] = useState([]);
  const [lastConversionvalue, setlastConversionvalue] = useState([]);
  const [lastInteractions, setlastInteractions] = useState([]);
  const [lastNewSessions, setLastNewSessions] = useState([]);
  const [dates, setDates] = useState([]);
  const [lastDates, setLastDates] = useState([]);
  const [loadingState, setLoadingState] = useState(false);
  const [MessageDisplay, setMessageDisplay] = useState(true);
  const [activeBtn, setActiveBtn] = useState(
    window.localStorage.getItem('activeBtn')
      ? JSON.parse(window.localStorage.getItem('activeBtn'))
      : {
        today: 1,
        yesterday: 0,
        last7days: 0,
        last30day: 0,
        last60day: 0,
        month: 0,
        other: 0,
      }
  );
  const [comparedTo, setComparedTo] = useState('');
  const mnths = [
    { num: 0, add: 2 },
    { num: 1, add: 0 },
    { num: 2, add: 2 },
    { num: 3, add: 1 },
    { num: 4, add: 2 },
    { num: 5, add: 1 },
    { num: 6, add: 2 },
    { num: 7, add: 2 },
    { num: 8, add: 1 },
    { num: 9, add: 2 },
    { num: 10, add: 1 },
    { num: 11, add: 2 },
  ];
  const getToAddOnMonths = (int) => {
    let res = 0;
    let month = 0;
    if (int === 0) {
      month = int;
    } else {
      month = int - 1;
    }
    mnths.map((x) => {
      if (x.num === month) res = x.add;
    });
    return res;
  };
  useEffect(() => {
    ApploCLI.query({
      query: getAccounts,
      context: {
        headers: {
          Authorization: window.localStorage.getItem("token")
            ? `Bearer ${window.localStorage.getItem("token")}`
            : "",
        },
      },
      errorPolicy: "all",
      fetchPolicy: "network-only",
    }).then((data) => {
      if (data.data.accounts) {
        let active_account = data.data.accounts.filter((x) => { if (x.active === true) return console.log("aaaaaaazzzzz", x) })
        if (active_account.length > 0) {
          localStorage.setItem("googleads_customer_currency_code",active_account[0].customer_currency_code);
        } 
      }
    });
  }, []);




  let formattedDates;
  useEffect(() => {
    if (chosedDate[0] && chosedDate[0].startDate && chosedDate[0].endDate) {
      const startDate = chosedDate[0].startDate;
      const endDate = chosedDate[0].endDate;
      const newStartDate = new Date(startDate).setDate(
        new Date(startDate).getDate() - daysBetween(startDate, endDate) - 1
      );
      const newEndDate = new Date(endDate).setDate(
        new Date(endDate).getDate() - daysBetween(startDate, endDate) - 1
      );
      comparedTo !== 'year'
        ? setLastPeriod(
          `${'Comparedto'} : ${new Date(newStartDate).toLocaleString('en', {
            day: '2-digit',
            month: 'short',
          })}-${new Date(newEndDate).toLocaleString('en', {
            day: '2-digit',
            month: 'short',
          })} ${new Date(newEndDate).getFullYear()}`
        )
        : setLastPeriod(
          `${'Comparedto'}: ${new Date(startDate).toLocaleString('en', {
            day: '2-digit',
            month: 'short',
          })}-${new Date(endDate).toLocaleString('en', {
            day: '2-digit',
            month: 'short',
          })} ${new Date(endDate).getFullYear() - 1}`
        );
    }
  }, [chosedDate, comparedTo]);
  const buttonHandler = (startDate, endDate) => {
    setDateRange({ date: [startDate, endDate] });
    setChosedDate([
      {
        startDate: startDate,
        endDate: endDate,
        key: 'selection',
      },
    ]);
    setToggleDate(false);
    window.localStorage.setItem('start', startDate);
    window.localStorage.setItem('end', endDate);
  };
  useEffect(() => {
    let isSubscribed = true;
    setLoadingState(false)
    var newStartDate = datePattern.exec(
      chosedDate[0].startDate && chosedDate[0].startDate.toLocaleString('fr-FR')
    );

    var newEndDate = datePattern.exec(
      chosedDate[0].endDate && chosedDate[0].endDate.toLocaleString('fr-FR')
    );

    var startDate =
      newStartDate[3] + '-' + newStartDate[2] + '-' + newStartDate[1];
    var endDate = newEndDate[3] + '-' + newEndDate[2] + '-' + newEndDate[1];
    console.log("startDate", startDate);
    console.log("comparedTo", comparedTo)
    if (newStartDate && newEndDate) {
      dispatch(getGoogleMetrics(startDate, endDate, comparedTo));

      if (DataDashboard.DataDashboard && isSubscribed) {
        DataDashboard.DataDashboard.reports.sort((a, b) => {
          let dateA = new Date(a.date);
          let dateB = new Date(b.date);
          return dateA - dateB;
        });

        console.log('formattedDates', formattedDates);
        setLoadingState(true);
      } else {
        setLoadingState(true);
      }
    }
    return () => (isSubscribed = false);
  }, [dateRage, comparedTo]);

  // useEffect(() => {
  //   let isSubscribed = true;
  //   setLoadingState(false);
  //   var newStartDate = datePattern.exec(chosedDate[0].startDate && chosedDate[0].startDate
  //     .toLocaleString("fr-FR"));
  //   var newEndDate = datePattern.exec(chosedDate[0].endDate && chosedDate[0].endDate
  //     .toLocaleString("fr-FR"));
  //   if (newStartDate && newEndDate) {
  //     ApploCLI.query({
  //       query: getData,
  //       variables: {
  //         startDate: newStartDate[3] + "-" + newStartDate[2] + "-" + newStartDate[1],
  //         endDate: newEndDate[3] + "-" + newEndDate[2] + "-" + newEndDate[1],
  //         compare_to: comparedTo
  //       },
  //       context: {
  //         headers: {
  //           Authorization: window.localStorage.getItem("token")
  //             ? `Bearer ${window.localStorage.getItem("token")}`
  //             : "",
  //         },
  //       },
  //       errorPolicy: "all",
  //       fetchPolicy: "no-cache", //allow me to refecth data
  //     }).then((data) => {
  //       if (isSubscribed) {
  //         if (data.data.get) {
  //           data.data.get.reports.sort((a, b) => {
  //             let dateA = new Date(a.date);
  //             let dateB = new Date(b.date);
  //             return dateA - dateB;
  //           });
  //           setDataDashboard(data.data.get);
  //           setConvertedDate(data.data.get.reports.map((item) => {
  //             const date = new Date(item.date);
  //             const formattedDate = date.toLocaleDateString('fr-FR', {
  //               day: '2-digit',
  //               month: 'short',
  //             });
  //             // console.log("formattedDate",formattedDate)
  //             return formattedDate;
  //           }));
  //           setLoadingState(true);
  //         }
  //         else
  //           setLoadingState(true)
  //       }
  //     });
  //   }
  //   return () => (isSubscribed = false);
  // }, [dateRage, comparedTo]);
  useEffect(() => {
    let isSubscribed = true;
    var Tdates = [];
    var Tsales = [];
    var TgrossProfit = [];
    var Tcps = [];
    var Taov = [];
    var Tclicks = [];
    var Tconversions = [];
    var Timpressions = [];
    var TconversionValue = [];
    var Tinteractions = [];
    var Tnewsessions = [];
    var TnbrOforders = [];
    // Last period data
    var lastTdates = [];
    var lastTsales = [];
    var lastTgrossProfit = [];
    var lastTcps = [];
    var lastTaov = [];
    var lastTclicks = [];
    var lastTimpressions = [];
    var lastTConversions = [];
    var lastTconversionValue = [];
    var lastTinteractions = [];
    var lastTnewsessions = [];
    var lastTnbrOforders = [];
    var lastMainS = [];
    var lastMainGP = [];

    // Last period data end
    var mainS = [];
    var mainGP = [];
    if (DataDashboard.DataDashboard) {
      DataDashboard.DataDashboard.reports.map((elem) => {
        Tsales.push(elem.sum_subtotal ? elem.sum_subtotal.toFixed(2) : 0);
        TgrossProfit.push(elem.gross_rev ? elem.gross_rev.toFixed(2) : 0);
        Tcps.push(elem.cps ? elem.cps.toFixed(2) : 0);
        Taov.push(elem.aov ? elem.aov.toFixed(2) : 0);
        Tclicks.push(elem.clicks ? elem.clicks.toFixed(2) : 0);
        Timpressions.push(elem.impressions ? elem.impressions.toFixed(2) : 0);
        Tconversions.push(elem.conversions ? elem.conversions.toFixed(2) : 0);
        TconversionValue.push(
          elem.conversions_value ? elem.conversions_value.toFixed(2) : 0
        );
        Tinteractions.push(elem.interactions ? elem.interactions : 0);
        Tnewsessions.push(elem.new_sessions ? elem.new_sessions : 0);
        TnbrOforders.push(elem.total_num_orders ? elem.total_num_orders : 0);
        Tdates.push(elem.date ? elem.date : null);
      });
      DataDashboard.DataDashboard.lastPeriodReport.map((elem) => {
        lastTsales.push(elem.sum_subtotal ? elem.sum_subtotal.toFixed(2) : 0);
        lastTgrossProfit.push(elem.gross_rev ? elem.gross_rev.toFixed(2) : 0);
        lastTcps.push(elem.cps ? elem.cps.toFixed(2) : 0);
        lastTaov.push(elem.aov ? elem.aov.toFixed(2) : 0);
        lastTclicks.push(elem.clicks ? elem.clicks.toFixed(2) : 0);
        lastTimpressions.push(
          elem.impressions ? elem.impressions.toFixed(2) : 0
        );
        lastTConversions.push(
          elem.conversions ? elem.conversions.toFixed(2) : 0
        );
        lastTconversionValue.push(
          elem.conversions_value ? elem.conversions_value.toFixed(2) : 0
        );
        lastTinteractions.push(
          elem.interactions ? elem.interactions.toFixed(2) : 0
        );
        lastTnewsessions.push(elem.new_sessions ? elem.new_sessions : 0);
        lastTnbrOforders.push(
          elem.total_num_orders ? elem.total_num_orders : 0
        );
        lastTdates.push(elem.date ? elem.date : null);
      });
      DataDashboard.DataDashboard.lastPeriodReport.map((val, i) => {
        var difference = daysBetween(
          new Date(dateRage.date[0]),
          new Date(dateRage.date[1])
        );
        var x_date = new Date(
          DataDashboard.DataDashboard.lastPeriodReport[i].date
        );
        comparedTo !== 'year'
          ? x_date.setDate(x_date.getDate() + difference + 1)
          : x_date.setDate(x_date.getDate() + difference);
        if (comparedTo !== 'year') {
          lastMainS.push(
            new Date(dateRage.date[0]).toLocaleString('fr-FR').split(' ')[0] !==
              new Date(dateRage.date[1]).toLocaleString('fr-FR').split(' ')[0]
              ? DataDashboard &&
                DataDashboard.DataDashboard.lastPeriodReport.length > 0
                ? {
                  x:
                    x_date.toISOString().split('-')[0] +
                    '-' +
                    x_date.toISOString().split('-')[1] +
                    '-' +
                    x_date.toISOString().split('-')[2].split('T')[0] +
                    '  GMT',
                  y: val.sum_subtotal ? val.sum_subtotal : 0,
                }
                : { x: '', y: 0 }
              : DataDashboard &&
                DataDashboard.DataDashboard.lastPeriodReport.length > 0
                ? {
                  x: new Date(
                    DataDashboard.DataDashboard?.reports[i]?.date
                  ).getTime(),
                  y: val.sum_subtotal ? val.sum_subtotal : 0,
                }
                : { x: '', y: 0 }
          );
        } else {
          let y_date = dateRage.date[0].getFullYear();
          lastMainS.push(
            new Date(dateRage.date[0]).toLocaleString('fr-FR').split(' ')[0] !==
              new Date(dateRage.date[1]).toLocaleString('fr-FR').split(' ')[0]
              ? DataDashboard &&
                DataDashboard.DataDashboard.lastPeriodReport.length > 0
                ? {
                  x:
                    y_date +
                    '-' +
                    x_date.toISOString().split('-')[1] +
                    '-' +
                    x_date.toISOString().split('-')[2].split('T')[0] +
                    '  GMT',
                  y: val.sum_subtotal ? val.sum_subtotal : 0,
                }
                : { x: '', y: 0 }
              : DataDashboard &&
                DataDashboard.DataDashboard.lastPeriodReport.length > 0
                ? {
                  x: new Date(
                    DataDashboard.DataDashboard?.reports[i]?.date
                  ).getTime(),
                  y: val.sum_subtotal ? val.sum_subtotal : 0,
                }
                : { x: '', y: 0 }
          );
        }
      });
      DataDashboard.DataDashboard.lastPeriodReport.map((val, i) => {
        var difference = daysBetween(
          new Date(dateRage.date[0]),
          new Date(dateRage.date[1])
        );
        var x_date = new Date(
          DataDashboard.DataDashboard.lastPeriodReport[i].date
        );
        comparedTo !== 'year'
          ? x_date.setDate(x_date.getDate() + difference + 1)
          : x_date.setDate(x_date.getDate() + difference);
        if (comparedTo !== 'year') {
          lastMainGP.push(
            new Date(dateRage.date[0]).toLocaleString('fr-FR').split(' ')[0] !==
              new Date(dateRage.date[1]).toLocaleString('fr-FR').split(' ')[0]
              ? DataDashboard &&
                DataDashboard.DataDashboard.lastPeriodReport.length > 0
                ? {
                  x:
                    x_date.toISOString().split('-')[0] +
                    '-' +
                    x_date.toISOString().split('-')[1] +
                    '-' +
                    x_date.toISOString().split('-')[2].split('T')[0] +
                    '  GMT',
                  y: val.gross_rev ? val.gross_rev : 0,
                }
                : { x: '', y: 0 }
              : DataDashboard &&
                DataDashboard.DataDashboard.lastPeriodReport.length > 0
                ? {
                  x: new Date(
                    DataDashboard.DataDashboard?.reports[i]?.date
                  ).getTime(),
                  y: val.gross_rev ? val.gross_rev : 0,
                }
                : { x: '', y: 0 }
          );
        } else {
          let y_date = dateRage.date[0].getFullYear();
          lastMainGP.push(
            new Date(dateRage.date[0]).toLocaleString('fr-FR').split(' ')[0] !==
              new Date(dateRage.date[1]).toLocaleString('fr-FR').split(' ')[0]
              ? DataDashboard &&
                DataDashboard.DataDashboard.lastPeriodReport.length > 0
                ? {
                  x:
                    y_date +
                    '-' +
                    x_date.toISOString().split('-')[1] +
                    '-' +
                    x_date.toISOString().split('-')[2].split('T')[0] +
                    '  GMT',
                  y: val.gross_rev ? val.gross_rev : 0,
                }
                : { x: '', y: 0 }
              : DataDashboard &&
                DataDashboard.DataDashboard.lastPeriodReport.length > 0
                ? {
                  x: new Date(
                    DataDashboard.DataDashboard?.reports[i]?.date
                  ).getTime(),
                  y: val.gross_rev ? val.gross_rev : 0,
                }
                : { x: '', y: 0 }
          );
        }
      });
      DataDashboard.DataDashboard.reports.map((val, i) => {
        var x = new Date(
          DataDashboard.DataDashboard.reports[i].date
        ).toUTCString();
        mainS.push(
          new Date(dateRage.date[0]).toLocaleString('fr-FR').split(' ')[0] !==
            new Date(dateRage.date[1]).toLocaleString('fr-FR').split(' ')[0]
            ? DataDashboard && DataDashboard.DataDashboard.reports.length > 0
              ? {
                x:
                  DataDashboard.DataDashboard.reports[i].date.split('-')[0] +
                  '-' +
                  DataDashboard.DataDashboard.reports[i].date.split('-')[1] +
                  '-' +
                  parseInt(
                    DataDashboard.DataDashboard.reports[i].date
                      .split('-')[2]
                      .split('T')[0]
                  ) +
                  '  GMT',
                y: val.sum_subtotal ? val.sum_subtotal : 0,
              }
              : { x: '', y: 0 }
            : DataDashboard && DataDashboard.DataDashboard.reports.length > 0
              ? {
                x: new Date(
                  DataDashboard.DataDashboard.reports[i].date
                ).getTime(),
                y: val.sum_subtotal ? val.sum_subtotal : 0,
              }
              : { x: '', y: 0 }
        );
      });
      DataDashboard.DataDashboard.reports.map((val, i) => {
        mainGP.push(
          new Date(dateRage.date[0]).toLocaleString('fr-FR').split(' ')[0] !==
            new Date(dateRage.date[1]).toLocaleString('fr-FR').split(' ')[0]
            ? DataDashboard && DataDashboard.DataDashboard.reports.length > 0
              ? {
                x:
                  DataDashboard.DataDashboard.reports[i].date.split('-')[0] +
                  '-' +
                  DataDashboard.DataDashboard.reports[i].date.split('-')[1] +
                  '-' +
                  parseInt(
                    DataDashboard.DataDashboard.reports[i].date
                      .split('-')[2]
                      .split('T')[0]
                  ) +
                  '  GMT',
                y: val.gross_rev ? val.gross_rev : 0,
              }
              : { x: '', y: 0 }
            : DataDashboard && DataDashboard.DataDashboard.reports.length > 0
              ? {
                x: new Date(
                  DataDashboard.DataDashboard.reports[i].date
                ).getTime(),
                y: val.gross_rev ? val.gross_rev : 0,
              }
              : { x: '', y: 0 }
        );
      });
    }

    setSales(Tsales);
    setGrossProfit(TgrossProfit);
    setCps(Tcps);
    setAov(Taov);
    setNmbrOfOrders(TnbrOforders);
    setClicks(Tclicks);
    setImpressions(Timpressions);
    setConversions(Tconversions);
    setConversionValue(TconversionValue);
    setInteractions(Tinteractions);
    setNewSessions(Tnewsessions);
    // Last period data
    setLastSales(lastTsales);
    setLastGrossProfit(lastTgrossProfit);
    setLastCps(lastTcps);
    setLastAov(lastTaov);
    setlastClicks(lastTclicks);
    setlastImpressions(lastTimpressions);
    setlastConversions(lastTConversions);
    setlastConversionvalue(lastTconversionValue);
    setlastInteractions(lastTinteractions);
    setLastNewSessions(lastTnewsessions);
    // Last period data end
    setDates(Tdates);
    setLastDates(lastTdates);
    return () => (isSubscribed = false);
  }, [DataDashboard]);

  const [selectedGroup, setselectedGroup] = useState(null);
  const optionGroup = [
    {
      label: "USD - United States Dollar",
      value: "USD",
    },
    {
      label: "EUR - Euro",
      value: "EUR",
    },

    {
      label: "LYD - Libyan Dinar",
      value: "LYD",
    },
    {
      label: "MAD - Moroccan Dirham",
      value: "MAD",
    },
    {
      label: "TND - Tunisian Dinar",
      value: "TND",
    },

  ]

  const currencyspend = localStorage.getItem("googleads_customer_currency_code")
  function handleSelectGroup(selectedOption) {

    setselectedGroup(selectedOption)
    axios.get(`https://v6.exchangerate-api.com/v6/c0fd644ef51cdd9e2aed5b22/latest/${currencyspend}`)
      .then(response => {
        localStorage.setItem("google_revenue_currency", selectedOption.value);
        const revenue_currency = response.data.conversion_rates[selectedOption.value]
        localStorage.setItem("google_currency_revenue_rate", revenue_currency);
        // Handle the response data here
      })
  }
  const currencyrevenuerate = localStorage.getItem("google_currency_revenue_rate")


  var op1 = {
    chart: {
      toolbar: {
        followCursor: true,
        show: false,
        tools: {
          download: true,
          selection: true,
          zoom: true,
          zoomin: true,
          zoomout: true,
          pan: true,
          reset: true,
        },
        tooltip: {
          shared: true,
          intersect: true,
          followCursor: true,
          x: {
            show: false,
          },
        },
        autoSelected: 'zoom',
      },
      type: 'area',
      height: 120,
      sparkline: {
        enabled: true,
      },
    },
    markers: {
      size: 0,
    },
    stroke: {
      curve: 'straight',
      width: 2.5,
    },
    fill: {
      opacity: 1,
    },
    series: [
      { name: 'TotalAdCost', data: cps },
      { name: 'Compared To', data: lastCps },
    ],
    xaxis: {
      crosshairs: {
        width: 1,
      },
    },
    yaxis: {
      show: false,
    },

    colors: ['#0044BD', '#F6B43B'],
  };
  var op2 = {
    chart: {
      toolbar: {
        followCursor: true,
        show: false,
        tools: {
          download: true,
          selection: true,
          zoom: true,
          zoomin: true,
          zoomout: true,
          pan: true,
          reset: true,
        },
        tooltip: {
          shared: true,
          intersect: true,
          followCursor: true,
          x: {
            show: false,
          },
        },
        autoSelected: 'zoom',
      },
      type: 'area',
      height: 120,
      sparkline: {
        enabled: true,
      },
    },
    markers: {
      size: 0,
    },
    stroke: {
      curve: 'straight',
      width: 2.5,
    },
    fill: {
      opacity: 1,
    },
    series: [
      { name: 'conversion_Value', data: conversions_value },
      { name: 'Compared To', data: lastConversionvalue },
    ],
    xaxis: {
      crosshairs: {
        width: 1,
      },
    },
    yaxis: {
      show: false,
    },

    colors: ['#0044BD', '#F6B43B'],
  };
  var op3 = {
    chart: {
      toolbar: {
        followCursor: true,
        show: false,
        tools: {
          download: true,
          selection: true,
          zoom: true,
          zoomin: true,
          zoomout: true,
          pan: true,
          reset: true,
        },
        tooltip: {
          shared: true,
          intersect: true,
          followCursor: true,
          x: {
            show: false,
          },
        },
        autoSelected: 'zoom',
      },
      type: 'area',
      height: 120,
      sparkline: {
        enabled: true,
      },
    },
    markers: {
      size: 0,
    },
    stroke: {
      curve: 'straight',
      width: 2.5,
    },
    fill: {
      opacity: 1,
    },
    series: [
      { name: 'Conversions', data: conversions },
      { name: 'Compared To', data: lastConversions },
    ],
    xaxis: {
      crosshairs: {
        width: 1,
      },
    },
    yaxis: {
      show: false,
    },

    colors: ['#0044BD', '#F6B43B'],
  };
  var op4 = {
    chart: {
      toolbar: {
        followCursor: true,
        show: false,
        tools: {
          download: true,
          selection: true,
          zoom: true,
          zoomin: true,
          zoomout: true,
          pan: true,
          reset: true,
        },
        tooltip: {
          shared: true,
          intersect: true,
          followCursor: true,
          x: {
            show: false,
          },
        },
        autoSelected: 'zoom',
      },
      type: 'area',
      height: 120,
      sparkline: {
        enabled: true,
      },
    },
    markers: {
      size: 0,
    },
    stroke: {
      curve: 'straight',
      width: 2.5,
    },
    fill: {
      opacity: 1,
    },
    series: [
      { name: 'ROAs', data: [conversions_value, cps] },
      { name: 'Compared To', data: [lastConversionvalue, lastCps] },
    ],
    xaxis: {
      crosshairs: {
        width: 1,
      },
    },
    yaxis: {
      show: false,
    },

    colors: ['#0044BD', '#F6B43B'],
  };
  var op5 = {
    chart: {
      toolbar: {
        followCursor: true,
        show: false,
        tools: {
          download: true,
          selection: true,
          zoom: true,
          zoomin: true,
          zoomout: true,
          pan: true,
          reset: true,
        },
        tooltip: {
          shared: true,
          intersect: true,
          followCursor: true,
          x: {
            show: false,
          },
        },
        autoSelected: 'zoom',
      },
      type: 'area',
      height: 120,
      sparkline: {
        enabled: true,
      },
    },
    markers: {
      size: 0,
    },
    stroke: {
      curve: 'straight',
      width: 2.5,
    },
    fill: {
      opacity: 1,
    },
    series: [
      { name: 'CPA', data: [cps, conversions] },
      { name: 'Compared To', data: [lastCps, lastConversions] },
    ],
    xaxis: {
      crosshairs: {
        width: 1,
      },
    },
    yaxis: {
      show: false,
    },

    colors: ['#0044BD', '#F6B43B'],
  };
  var op6 = {
    chart: {
      toolbar: {
        followCursor: true,
        show: false,
        tools: {
          download: true,
          selection: true,
          zoom: true,
          zoomin: true,
          zoomout: true,
          pan: true,
          reset: true,
        },
        tooltip: {
          shared: true,
          intersect: true,
          followCursor: true,
          x: {
            show: false,
          },
        },
        autoSelected: 'zoom',
      },
      type: 'area',
      height: 120,
      sparkline: {
        enabled: true,
      },
    },
    markers: {
      size: 0,
    },
    stroke: {
      curve: 'straight',
      width: 2.5,
    },
    fill: {
      opacity: 1,
    },
    series: [
      { name: 'CPC', data: [cps, clicks] },
      { name: 'Compared To', data: [lastCps, lastClicks] },
    ],
    xaxis: {
      crosshairs: {
        width: 1,
      },
    },
    yaxis: {
      show: false,
    },

    colors: ['#0044BD', '#F6B43B'],
  };
  var op7 = {
    chart: {
      toolbar: {
        followCursor: true,
        show: false,
        tools: {
          download: true,
          selection: true,
          zoom: true,
          zoomin: true,
          zoomout: true,
          pan: true,
          reset: true,
        },
        tooltip: {
          shared: true,
          intersect: true,
          followCursor: true,
          x: {
            show: false,
          },
        },
        autoSelected: 'zoom',
      },
      type: 'area',
      height: 120,
      sparkline: {
        enabled: true,
      },
    },
    markers: {
      size: 0,
    },
    stroke: {
      curve: 'straight',
      width: 2.5,
    },
    fill: {
      opacity: 1,
    },
    series: [
      { name: 'CTR', data: [clicks, impressions] },
      { name: 'Compared To', data: [lastClicks, lastImpressions] },
    ],
    xaxis: {
      crosshairs: {
        width: 1,
      },
    },
    yaxis: {
      show: false,
    },

    colors: ['#0044BD', '#F6B43B'],
  };
  var op8 = {
    chart: {
      toolbar: {
        followCursor: true,
        show: false,
        tools: {
          download: true,
          selection: true,
          zoom: true,
          zoomin: true,
          zoomout: true,
          pan: true,
          reset: true,
        },
        tooltip: {
          shared: true,
          intersect: true,
          followCursor: true,
          x: {
            show: false,
          },
        },
        autoSelected: 'zoom',
      },
      type: 'area',
      height: 120,
      sparkline: {
        enabled: true,
      },
    },
    markers: {
      size: 0,
    },
    stroke: {
      curve: 'straight',
      width: 2.5,
    },
    fill: {
      opacity: 1,
    },
    series: [
      { name: 'Conversion Rate', data: [conversions, clicks] },
      { name: 'Compared To', data: [lastConversions, lastClicks] },
    ],
    xaxis: {
      crosshairs: {
        width: 1,
      },
    },
    yaxis: {
      show: false,
    },

    colors: ['#0044BD', '#F6B43B'],
  };
  var op9 = {
    chart: {
      toolbar: {
        followCursor: true,
        show: false,
        tools: {
          download: true,
          selection: true,
          zoom: true,
          zoomin: true,
          zoomout: true,
          pan: true,
          reset: true,
        },
        tooltip: {
          shared: true,
          intersect: true,
          followCursor: true,
          x: {
            show: false,
          },
        },
        autoSelected: 'zoom',
      },
      type: 'area',
      height: 120,
      sparkline: {
        enabled: true,
      },
    },
    markers: {
      size: 0,
    },
    stroke: {
      curve: 'straight',
      width: 2.5,
    },
    fill: {
      opacity: 1,
    },
    series: [
      { name: 'Impressions', data: impressions },
      { name: 'Compared To', data: lastImpressions },
    ],
    xaxis: {
      crosshairs: {
        width: 1,
      },
    },
    yaxis: {
      show: false,
    },

    colors: ['#0044BD', '#F6B43B'],
  };
  var op10 = {
    chart: {
      toolbar: {
        followCursor: true,
        show: false,
        tools: {
          download: true,
          selection: true,
          zoom: true,
          zoomin: true,
          zoomout: true,
          pan: true,
          reset: true,
        },
        tooltip: {
          shared: true,
          intersect: true,
          followCursor: true,
          x: {
            show: false,
          },
        },
        autoSelected: 'zoom',
      },
      type: 'area',
      height: 120,
      sparkline: {
        enabled: true,
      },
    },
    markers: {
      size: 0,
    },
    stroke: {
      curve: 'straight',
      width: 2.5,
    },
    fill: {
      opacity: 1,
    },
    series: [
      { name: 'CLICKS', data: clicks },
      { name: 'Compared To', data: lastClicks },
    ],
    xaxis: {
      crosshairs: {
        width: 1,
      },
    },
    yaxis: {
      show: false,
    },

    colors: ['#0044BD', '#F6B43B'],
  };
  var op11 = {
    chart: {
      toolbar: {
        followCursor: true,
        show: false,
        tools: {
          download: true,
          selection: true,
          zoom: true,
          zoomin: true,
          zoomout: true,
          pan: true,
          reset: true,
        },
        tooltip: {
          shared: true,
          intersect: true,
          followCursor: true,
          x: {
            show: false,
          },
        },
        autoSelected: 'zoom',
      },
      type: 'area',
      height: 120,
      sparkline: {
        enabled: true,
      },
    },
    markers: {
      size: 0,
    },
    stroke: {
      curve: 'straight',
      width: 2.5,
    },
    fill: {
      opacity: 1,
    },
    series: [
      { name: 'AOV', data: [conversions_value, conversions] },
      { name: 'Compared To', data: [lastConversionvalue, lastConversions] },
    ],
    xaxis: {
      crosshairs: {
        width: 1,
      },
    },
    yaxis: {
      show: false,
    },

    colors: ['#0044BD', '#F6B43B'],
  };
  const [myCards, setMyCards] = useState([]);

  useEffect(() => {
    let isSubscribed = true;
    setMyCards([
      {
        id: 1,
        name: 'TotalAdCost',
        options: op1,
        series: op1.series,
        lastseries: lastCps,
        dates: dates,
        lastDates: lastDates,
      },
      {
        id: 2,
        name: 'conversion_Value',
        options: op2,
        series: op2.series,
        lastseries: lastConversionvalue,
        dates: dates,
        lastDates: lastDates,
      },
      {
        id: 3,
        name: 'Conversions',
        options: op3,
        series: op3.series,
        lastseries: lastConversions,
        dates: dates,
        lastDates: lastDates,
      },
      {
        id: 4,
        name: 'ROAs',
        options: op4,
        series: op4.series,
        dates: dates,
        lastDates: lastDates,
      },
      {
        id: 5,
        name: 'CPA',
        options: op5,
        series: op5.series,
        dates: dates,
        lastDates: lastDates,
      },
      {
        id: 6,
        name: 'CPC',
        options: op6,
        series: op6.series,
        dates: dates,
        lastDates: lastDates,
      },
      {
        id: 7,
        name: 'CTR',
        options: op7,
        series: op7.series,
        dates: dates,
        lastDates: lastDates,
      },
      {
        id: 8,
        name: 'Conversion Rate',
        options: op8,
        series: op8.series,
        dates: dates,
        lastDates: lastDates,
      },
      {
        id: 9,
        name: 'Impressions',
        options: op9,
        series: op9.series,
        lastseries: lastClicks,
        dates: dates,
        lastDates: lastDates,
      },
      {
        id: 10,
        name: 'CLICKS',
        options: op10,
        series: op10.series,
        lastseries: lastClicks,
        dates: dates,
        lastDates: lastDates,
      },
      {
        id: 11,
        name: 'AOV',
        options: op11,
        series: op11.series,
        lastseries: lastNewSessions,
        dates: dates,
        lastDates: lastDates,
      },
    ]);
    return () => (isSubscribed = false);
  }, [
    clicks,
    lastClicks,
    cps,
    lastCps,
    impressions,
    conversions,
    new_sessions,
    lastNewSessions,
    lastConversions,
    lastImpressions,
    conversions_value,
    lastConversionvalue,
    interactions,
    lastInteractions,
    dates,
    lastDates,
  ]);


  const getConversionRate = (t1, t2, diff) => {
    if (
      t1 instanceof Array &&
      t2 instanceof Array &&
      t1.length > 0 &&
      t2.length > 0
    ) {
      var totalConversion = t2.reduce((x, y) => {
        return parseFloat(x) + parseFloat(y);
      });
      var totalClicks = t1.reduce((x, y) => {
        return parseFloat(x) + parseFloat(y);
      });
      console.log('totalConversion', totalConversion);
      console.log(' totalClicks', totalClicks);
      var ConversionRate = (totalConversion / totalClicks) * 100;
      return ConversionRate;
    }
    return 0;
  };
  const getLastConversionRate = (t1, t2, diff) => {
    if (
      t1 instanceof Array &&
      t2 instanceof Array &&
      t1.length > 0 &&
      t2.length > 0
    ) {
      var lastTotalConversion = t2.reduce((x, y) => {
        return parseFloat(x) + parseFloat(y);
      });
      var lastTotalClicks = t1.reduce((x, y) => {
        return parseFloat(x) + parseFloat(y);
      });
      var lastConversionRate = (lastTotalConversion / lastTotalClicks) * 100;
      return lastConversionRate;
    }
    return 0;
  };
  const getCTR = (t1, t2, diff) => {
    if (diff === 0) {
      var totalImpressions = t1.map(Number).reduce((x, y) => {
        return x + y;
      });
      return totalImpressions / parseFloat(t2);
    } else {
      if (
        t1 instanceof Array &&
        t2 instanceof Array &&
        t1.length > 0 &&
        t2.length > 0
      ) {
        var totalClicks = t2.reduce((x, y) => {
          return parseFloat(x) + parseFloat(y);
        });
        var totalImpressions = t1.reduce((x, y) => {
          return parseFloat(x) + parseFloat(y);
        });
        var ctr = (totalClicks / totalImpressions) * 100;
        return ctr;
      } else if (t2 > 0) {
        return (parseFloat(t2) / parseFloat(t1)) * 100;
      }
    }
    return 0;
  };
  const getLastCTR = (t1, t2, diff) => {
    if (diff === 0) {
      var lastTotalCps = t1.map(Number).reduce((x, y) => {
        return x + y;
      });
      return lastTotalCps / parseFloat(t2);
    } else {
      if (
        t1 instanceof Array &&
        t2 instanceof Array &&
        t1.length > 0 &&
        t2.length > 0
      ) {
        var lasttotalClicks = t2.reduce((x, y) => {
          return parseFloat(x) + parseFloat(y);
        });
        var lasttotalImpressions = t1.reduce((x, y) => {
          return parseFloat(x) + parseFloat(y);
        });
        var lastctr = (lasttotalClicks / lasttotalImpressions) * 100;
        return lastctr;
      } else if (t2 > 0) {
        return (parseFloat(t2) / parseFloat(t1)) * 100;
      } else if (t2 > 0) {
        return parseFloat(t2) / parseFloat(t1);
      }
      return 0;
    }
  };
  const getCPC = (t1, t2, diff) => {
    if (diff === 0) {
      var totalCps = t1.map(Number).reduce((x, y) => {
        return x + y;
      });
      return totalCps / parseFloat(t2);
    } else {
      if (
        t1 instanceof Array &&
        t2 instanceof Array &&
        t1.length > 0 &&
        t2.length > 0
      ) {
        var totalCps = t2.reduce((x, y) => {
          return parseFloat(x) + parseFloat(y);
        });
        var totalClicks = t1.reduce((x, y) => {
          return parseFloat(x) + parseFloat(y);
        });
        var cpc = totalCps / totalClicks;
        return cpc;
      } else if (t2 > 0) {
        return parseFloat(t2) / parseFloat(t1);
      }
      return 0;
    }
  };
  const getLastCPC = (t1, t2, diff) => {
    if (diff === 0) {
      var lasttotalCps = t2.map(Number).reduce((x, y) => {
        return x + y;
      });
      return lasttotalCps / parseFloat(t1);
    } else {
      if (
        t1 instanceof Array &&
        t2 instanceof Array &&
        t1.length > 0 &&
        t2.length > 0
      ) {
        var lasttotalCps = t2.reduce((x, y) => {
          return parseFloat(x) + parseFloat(y);
        });
        var lasttotalClicks = t1.reduce((x, y) => {
          return parseFloat(x) + parseFloat(y);
        });
        var lastcpc = lasttotalCps / lasttotalClicks;
        return lastcpc;
      } else if (t2 > 0) {
        return parseFloat(t2) / parseFloat(t1);
      }
      return 0;
    }
  };
  const getAOV = (t1, t2, diff) => {
    if (diff === 0) {
      var totalConversion = t1.map(Number).reduce((x, y) => {
        return x + y;
      });
      return totalConversion / parseFloat(t2);
    } else {
      if (
        t1 instanceof Array &&
        t2 instanceof Array &&
        t1.length > 0 &&
        t2.length > 0
      ) {
        var totalconversionvalue = t2.reduce((x, y) => {
          return parseFloat(x) + parseFloat(y);
        });
        var totalConversion = t1.reduce((x, y) => {
          return parseFloat(x) + parseFloat(y);
        });
        return totalconversionvalue / totalConversion;
      } else if (t2 > 0) {
        return parseFloat(t2) / parseFloat(t1);
      } else if (t2 > 0) {
        return parseFloat(t2) / parseFloat(t1);
      }
      return 0;
    }
  };
  const getLastAOV = (t1, t2, diff) => {
    if (diff === 0) {
      var lastTotalConversions = t1.map(Number).reduce((x, y) => {
        return x + y;
      });
      return lastTotalConversions / parseFloat(t2);
    } else {
      if (
        t1 instanceof Array &&
        t2 instanceof Array &&
        t1.length > 0 &&
        t2.length > 0
      ) {
        var lastTotalconversionvalue = t2.reduce((x, y) => {
          return parseFloat(x) + parseFloat(y);
        });
        var lastTotalConversions = t1.reduce((x, y) => {
          return parseFloat(x) + parseFloat(y);
        });
        var lastAOVCalculated = lastTotalconversionvalue / lastTotalConversions;
        return lastAOVCalculated;
      } else if (t2 > 0) {
        return parseFloat(t2) / parseFloat(t1);
      }
      return 0;
    }
  };
  const getROAS = (t1, t2, diff) => {
    if (diff === 0) {
      var totalCps = t1.map(Number).reduce((x, y) => {
        return x + y;
      });
      console.log('totalSales', totalCps);
      return totalCps / parseFloat(t2);
    } else {
      if (
        t1 instanceof Array &&
        t2 instanceof Array &&
        t1.length > 0 &&
        t2.length > 0
      ) {
        var totalconversionvalue = t2.reduce((x, y) => {
          return parseFloat(x) + parseFloat(y);
        });
        var totalCps = t1.reduce((x, y) => {
          return parseFloat(x) + parseFloat(y);
        });

        return ((totalconversionvalue / currencyrevenuerate) / totalCps);
      } else if (t2 > 0) {
        return parseFloat(t2) / parseFloat(t1);
      }
      return 0;
    }
  };
  const getLastROAS = (t1, t2, diff) => {
    if (diff === 0) {
      var lastTotalCps = t1.map(Number).reduce((x, y) => {
        return x + y;
      });
      return lastTotalCps / parseFloat(t2);
    } else {
      if (
        t1 instanceof Array &&
        t2 instanceof Array &&
        t1.length > 0 &&
        t2.length > 0
      ) {
        var lastTotalconversionvalue = t2.reduce((x, y) => {
          return parseFloat(x) + parseFloat(y);
        });
        var lastTotalCps = t1.reduce((x, y) => {
          return parseFloat(x) + parseFloat(y);
        });
        return ((lastTotalconversionvalue /currencyrevenuerate) /lastTotalCps);
      } else if (t2 > 0) {
        return parseFloat(t2) / parseFloat(t1);
      }
      return 0;
    }
  };
  const getCPA = (t1, t2, diff) => {
    if (diff === 0) {
      var totalCps = t1.map(Number).reduce((x, y) => {
        return x + y;
      });
      return totalCps / parseFloat(t2);
    } else {
      if (
        t1 instanceof Array &&
        t2 instanceof Array &&
        t1.length > 0 &&
        t2.length > 0
      ) {
        var totalCps = t2.reduce((x, y) => {
          return parseFloat(x) + parseFloat(y);
        });
        var totalConversion = t1.reduce((x, y) => {
          return parseFloat(x) + parseFloat(y);
        });
        var cpa = totalCps / totalConversion;
        return cpa;
      } else if (t2 > 0) {
        return parseFloat(t2) / parseFloat(t1);
      }
      return 0;
    }
  };
  const getLastCPA = (t1, t2, diff) => {
    if (diff === 0) {
      var lastTotalCps = t1.map(Number).reduce((x, y) => {
        return x + y;
      });
      return lastTotalCps / parseFloat(t2);
    } else {
      if (
        t1 instanceof Array &&
        t2 instanceof Array &&
        t1.length > 0 &&
        t2.length > 0
      ) {
        var lastTotalCps = t2.reduce((x, y) => {
          return parseFloat(x) + parseFloat(y);
        });
        var lastTotalConversions = t1.reduce((x, y) => {
          return parseFloat(x) + parseFloat(y);
        });
        var lastCPACalculated = lastTotalCps / lastTotalConversions;
        return lastCPACalculated;
      } else if (t2 > 0) {
        return parseFloat(t2) / parseFloat(t1);
      }
      return 0;
    }
  };
  const handleCompareToggle = (compare_to) => {
    setComparedTo(compare_to);
    if (comparedTo === compare_to) {
      setComparedTo('');
    }
  };
  useEffect(() => {
    if (currentFocus[1] === 0) {
      setDateRange({
        date: [
          new Date(
            new Date(
              chosedDate[0].startDate.toLocaleDateString('en-US', {
                timeZone: props.timeZone ? props.timeZone : 'UTC',
              })
            )
          ),
          new Date(
            new Date(
              chosedDate[0].endDate.toLocaleDateString('en-US', {
                timeZone: props.timeZone ? props.timeZone : 'UTC',
              })
            )
          ),
        ],
      });
      setToggleDate(false);
    }
  }, [currentFocus]);
  useEffect(() => {
    window.localStorage.setItem('start', chosedDate[0].startDate);
    window.localStorage.setItem('end', chosedDate[0].endDate);
  }, [chosedDate]);

  // Google ads chart
  const getOption = () => {
    return {
      tooltip: {
        trigger: 'axis',
      },
      grid: {
        zlevel: 0,
        x: 50,
        x2: 50,
        y: 30,
        y2: 30,
        borderWidth: 0,
      },
      xAxis: {
        type: 'category',
        data: DataDashboard.DataDashboard?.reports.map((item) => {
          const date = new Date(item.date);
          return date.toLocaleDateString('fr-FR', {
            day: '2-digit',
            month: 'long', // Use 'long' to display the month as "aout" instead of "août"
          });
        }) || [],
        axisLable: {
          color: '#ffffff',
        },
        axisLine: {
          lineStyle: {
            color: '#74788d',
          },
        },
      },
      yAxis: {
        type: 'value',
        axisLable: {
          color: '#ffffff',
        },
        axisLine: {
          lineStyle: {
            color: '#74788d',
          },
        },
      },
      series: [
        {
          name: selectedSeries,
          data: getSelectedSeriesData(selectedSeries), // Pass the selected series name to the function
          type: 'line',
        },
      ],
      color: ['#0044BD'],
      textStyle: {
        color: ['#0044BD'],
      },
    };
  };
  // options
  const [selectedSeries, setSelectedSeries] = useState('spend');

  const handleDropdownChange = (event) => {
    setSelectedSeries(event.target.value);
  };
  // Function to get the data array for the selected series
  const getSelectedSeriesData = (selectedSeries) => {
    switch (selectedSeries) {
      case 'spend':
        return cps;
      case 'conversionsValue':
        return conversions_value;
      case 'conversions':
        return conversions;
      case 'impressions':
        return impressions;
      case 'clicks':
        return clicks;
    }
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>{props.t("GoogleAds Dashbaord")} | Convergen-Metrics - E-com SaaS</title>
        </MetaTags>
        <div className="container-fluid">
          <Breadcrumbs
            title={props.t("Google Ads")}
            breadcrumbItem={props.t("Google Dashboard")}
            pageHeading={props.t("Google Ads")}
          />

          <Row>
            <div className="d-flex align-items-start">
              <div className="flex-grow-1">
                <Col md={4}>
                  <FormGroup>
                    <InputGroup>
                      <div className="DateContainer">
                        <div>
                          <div className="divDateContainer">
                            <div ref={wrapperRef}>
                              <button
                                className="btn btn-light"
                                style={{ marginTop: '-10px' }}
                                onClick={() => setToggleDate(!toggleDate)}
                              >
                                {chosedDate[0] !== null
                                  ? [
                                    chosedDate[0].startDate.toLocaleString(
                                      'en',
                                      {
                                        month: 'short',
                                      }
                                    ),
                                    ' ',
                                    chosedDate[0].startDate.getDate(),
                                    ' ',
                                    chosedDate[0].startDate.getFullYear(),
                                    '  -  ',
                                    chosedDate[0].endDate &&
                                    chosedDate[0].endDate.toLocaleString(
                                      'en',
                                      {
                                        month: 'short',
                                      }
                                    ),
                                    ' ',
                                    chosedDate[0].endDate &&
                                    chosedDate[0].endDate.getDate(),
                                    ' ',
                                    chosedDate[0].endDate &&
                                    chosedDate[0].endDate.getFullYear(),
                                  ]
                                  : null}
                              </button>
                              {toggleDate && (
                                <div 
                                style={{
                                  position: 'fixed',
                                  display: 'flex',
                                  left: '265px',
                                  zIndex: 9999,
                                }}
                              >
                                  <div className="calenderDivStyle">

                                    <div className="btnsCalendar">
                                      <DateRange
                                        editableDateInputs={true}
                                        moveRangeOnFirstSelection={false}
                                        onRangeFocusChange={(item) => {
                                          setCurrentFocus(item);
                                        }}
                                        onChange={(item) => {
                                          setChosedDate([item.selection]);
                                          setActiveBtn({
                                            today: 0,
                                            yesterday: 0,
                                            last7days: 0,
                                            last30day: 0,
                                            last60day: 0,
                                            month: 0,
                                            other: 1,
                                          });
                                          window.localStorage.setItem(
                                            'activeBtn',
                                            JSON.stringify({
                                              today: 0,
                                              yesterday: 0,
                                              last7days: 0,
                                              last30day: 0,
                                              last60day: 0,
                                              month: 0,
                                              other: 1,
                                            })
                                          );
                                        }}
                                        ranges={chosedDate}
                                        dragSelectionEnabled={true}
                                        rangeColors={['#6689F2']}
                                        months={2}
                                        direction="horizontal"
                                        maxDate={new Date(new Date().getTime() - 24 * 60 * 60 * 1000)}
                                        disabledDates={[
                                          {
                                            after: new Date(), // Disable dates after today
                                          },
                                        ]}
                                      />
                                    </div>
                                    <div className="calendarDiv">
                                      <div className="btnCalendarContainer">
                                        <button
                                          className={
                                            activeBtn.today
                                              ? 'dateBtns activeBtnDate'
                                              : 'dateBtns'
                                          }
                                          onClick={() => {
                                            buttonHandler(
                                              new Date(
                                                new Date().toLocaleDateString(
                                                  'en-US',
                                                  {
                                                    timeZone:
                                                      window.localStorage.getItem(
                                                        'timezone'
                                                      ),
                                                  }
                                                )
                                              ),
                                              new Date(
                                                new Date().toLocaleDateString(
                                                  'en-US',
                                                  {
                                                    timeZone:
                                                      window.localStorage.getItem(
                                                        'timezone'
                                                      ),
                                                  }
                                                )
                                              )
                                            );
                                            setActiveBtn({
                                              today: 1,
                                              yesterday: 0,
                                              last7days: 0,
                                              last30day: 0,
                                              last60day: 0,
                                              month: 0,
                                              other: 0,
                                            });
                                            window.localStorage.setItem(
                                              'activeBtn',
                                              JSON.stringify({
                                                today: 1,
                                                yesterday: 0,
                                                last7days: 0,
                                                last30day: 0,
                                                last60day: 0,
                                                month: 0,
                                                other: 0,
                                              })
                                            );
                                          }}
                                        >
                                          {props.t("compareto")}
                                        </button>
                                        <button
                                          className={
                                            activeBtn.yesterday
                                              ? 'dateBtns activeBtnDate'
                                              : 'dateBtns'
                                          }
                                          onClick={() => {
                                            const timeZone =
                                              window.localStorage.getItem(
                                                'timezone'
                                              ) || 'UTC'; // set default time zone to UTC
                                            buttonHandler(
                                              new Date(
                                                new Date(
                                                  new Date().getTime() -
                                                  24 * 60 * 60 * 1000
                                                ).toLocaleDateString('en-US', {
                                                  timeZone: timeZone,
                                                })
                                              ),

                                              new Date(
                                                new Date(
                                                  new Date().getTime() -
                                                  24 * 60 * 60 * 1000
                                                ).toLocaleDateString('en-US', {
                                                  timeZone: timeZone,
                                                })
                                              )
                                            );
                                            setActiveBtn({
                                              today: 0,
                                              yesterday: 1,
                                              last7days: 0,
                                              last30day: 0,
                                              last60day: 0,
                                              month: 0,
                                              other: 0,
                                            });
                                            window.localStorage.setItem(
                                              'activeBtn',
                                              JSON.stringify({
                                                today: 0,
                                                yesterday: 1,
                                                last7days: 0,
                                                last30day: 0,
                                                last60day: 0,
                                                month: 0,
                                                other: 0,
                                              })
                                            );
                                          }}
                                        >
                                          {props.t("yesterday")}
                                        </button>
                                        <button
                                          className={
                                            activeBtn.last7days
                                              ? 'dateBtns activeBtnDate'
                                              : 'dateBtns'
                                          }
                                          onClick={() => {
                                            const timeZone =
                                              window.localStorage.getItem(
                                                'timezone'
                                              ) || 'UTC'; // set default time zone to UTC
                                            buttonHandler(
                                              new Date(
                                                new Date(
                                                  new Date().getTime() -
                                                  6 * 24 * 60 * 60 * 1000
                                                ).toLocaleDateString('en-US', {
                                                  timeZone: timeZone,
                                                })
                                              ),
                                              new Date(
                                                new Date().toLocaleDateString(
                                                  'en-US',
                                                  { timeZone: timeZone }
                                                )
                                              )
                                            );
                                            setActiveBtn({
                                              today: 0,
                                              yesterday: 0,
                                              last7days: 1,
                                              last30day: 0,
                                              last60day: 0,
                                              month: 0,
                                              other: 0,
                                            });
                                            window.localStorage.setItem(
                                              'activeBtn',
                                              JSON.stringify({
                                                today: 0,
                                                yesterday: 0,
                                                last7days: 1,
                                                last30day: 0,
                                                last60day: 0,
                                                month: 0,
                                                other: 0,
                                              })
                                            );
                                          }}
                                        >
                                          {props.t("last7days")}
                                        </button>
                                        <button
                                          className={
                                            activeBtn.last30day
                                              ? 'dateBtns activeBtnDate'
                                              : 'dateBtns'
                                          }
                                          onClick={() => {
                                            const timeZone =
                                              window.localStorage.getItem(
                                                'timezone'
                                              ) || 'UTC'; // set default time zone to UTC
                                            buttonHandler(
                                              new Date(
                                                new Date(
                                                  new Date().getTime() -
                                                  29 * 24 * 60 * 60 * 1000
                                                ).toLocaleDateString('en-US', {
                                                  timeZone: timeZone,
                                                })
                                              ),
                                              new Date(
                                                new Date().toLocaleDateString(
                                                  'en-US',
                                                  { timeZone: timeZone }
                                                )
                                              )
                                            );
                                            setActiveBtn({
                                              today: 0,
                                              yesterday: 0,
                                              last7days: 0,
                                              last30day: 1,
                                              last60day: 0,
                                              month: 0,
                                              other: 0,
                                            });
                                            window.localStorage.setItem(
                                              'activeBtn',
                                              JSON.stringify({
                                                today: 0,
                                                yesterday: 0,
                                                last7days: 0,
                                                last30day: 1,
                                                last60day: 0,
                                                month: 0,
                                                other: 0,
                                              })
                                            );
                                          }}
                                        >
                                          {props.t("last30days")}
                                        </button>
                                        <button
                                          className={
                                            activeBtn.month
                                              ? 'dateBtns activeBtnDate'
                                              : 'dateBtns'
                                          }
                                          onClick={() => {
                                            const timeZone =
                                              window.localStorage.getItem(
                                                'timezone'
                                              ) || 'UTC'; // set default time zone to UTC
                                            buttonHandler(
                                              new Date(
                                                new Date(
                                                  new Date().getFullYear(),
                                                  new Date().getMonth(),

                                                  getToAddOnMonths(
                                                    new Date().getMonth()
                                                  )
                                                ).toLocaleDateString('en-US', {
                                                  timeZone: timeZone,
                                                })
                                              ),

                                              new Date(
                                                new Date().toLocaleDateString(
                                                  'en-US',
                                                  { timeZone: timeZone }
                                                )
                                              )
                                            );
                                            setActiveBtn({
                                              today: 0,
                                              yesterday: 0,
                                              last7days: 0,
                                              last30day: 0,
                                              last60day: 0,
                                              month: 1,
                                              other: 0,
                                            });
                                            window.localStorage.setItem(
                                              'activeBtn',
                                              JSON.stringify({
                                                today: 0,
                                                yesterday: 0,
                                                last7days: 0,
                                                last30day: 0,
                                                last60day: 0,
                                                month: 1,
                                                other: 0,
                                              })
                                            );
                                          }}
                                        >
                                          {props.t("thismonth")}
                                        </button>
                                        <span className="compareSpan">
                                          <b>{props.t("compareto")}</b>
                                        </span>
                                        <div className="form-check mb-3">
                                          <input
                                            style={{
                                              width: '1em',
                                              height: '1em',
                                              marginTop: '0.25em',
                                              verticalAlign: 'top',
                                              backgroundColor: '#fff',
                                              backgroundRepeat: 'no-repeat',
                                              backgroundPosition: 'center',
                                              border: '#000000',
                                            }}
                                            type="checkbox"
                                            id="formCheck1"
                                            className={
                                              comparedTo === 'period'
                                                ? 'compareTogglebtnStyleActive'
                                                : null
                                            }
                                            checked={comparedTo === 'period'}
                                            onClick={() =>
                                              handleCompareToggle('period')
                                            }
                                          />
                                          <label
                                            className="form-check-label"
                                            htmlFor="formCheck1"
                                          >
                                            {props.t("previousperiod")}
                                          </label>
                                        </div>
                                        <div className="form-check mb-3">
                                          <input
                                            style={{
                                              width: '1em',
                                              height: '1em',
                                              marginTop: '0.25em',
                                              verticalAlign: 'top',
                                              backgroundColor: '#fff',
                                              backgroundRepeat: 'no-repeat',
                                              backgroundPosition: 'center',
                                              border: '#000000',
                                            }}
                                            type="checkbox"
                                            id="formCheck1"
                                            className={
                                              comparedTo === 'year'
                                                ? 'compareTogglebtnStyleActive'
                                                : null
                                            }
                                            checked={comparedTo === 'year'}
                                            onClick={() =>
                                              handleCompareToggle('year')
                                            }
                                          />
                                          <label
                                            className="form-check-label"
                                            htmlFor="formCheck1"
                                          >
                                            {props.t("Previousyear")}
                                          </label>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )}
                              <br />
                              {comparedTo !== "" && (
                            <small style={{
                              display: 'flex',
                              marginTop: '10px',
                              backgroundColor: 'rgb(59 91 152 / 7%)',
                              borderRadius: '5px',
                              width: 'max-content',
                              height: '30px',
                              padding: "8px",
                              fontWeight: 'bold',
                            }}>
                              {lastPeriod}
                            </small>
                          )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </InputGroup>
                  </FormGroup>
                </Col>
              </div>

              <div className="flex-shrink-0">
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <Link to="/GoogleReportDetail" className="btn btn-light" style={{ marginRight: '10px' }}><i className="bx bx-plus me-1"></i>  {props.t("Generate a Google Ads report")}</Link>
                  <Button color="primary" onClick={toggleOffcanvas}>
                    <FeatherIcon
                      icon="settings"
                      className="icon-lg"
                    />
                  </Button>
                </div>
                <Offcanvas show={isOffcanvasOpen} onHide={toggleOffcanvas} placement="end">
                  <OffcanvasHeader closeButton> {props.t("settings")}</OffcanvasHeader>
                  <OffcanvasBody> <div className="modal-header">
                    <h5 className="modal-title" id="staticBackdropLabel"> {props.t("Please choose your revenue currency for more accurate metrics.")}</h5>

                  </div>

                    <div className="mb-3">
                      <label htmlFor="choices-single-default" className="form-label font-size-13 text-muted"> {props.t("Select Your  Revenue currency")} </label>
                      <Select
                        value={selectedGroup}
                        onChange={handleSelectGroup}
                        options={optionGroup}
                        classNamePrefix="select2-selection"
                      />
                    </div></OffcanvasBody>
                </Offcanvas>
              </div>
            </div>

            <Col md={3}></Col>
          </Row>
          <div>

            <Row>
              <Col xl={12}>
                <Card>
                  <CardHeader>
                    <CardTitle>{props.t("Google Ads Charts")} </CardTitle>
                  </CardHeader>
                  <CardBody>
                    <div dir="ltr">
                      <select
                        className="btn btn-primary"
                        value={selectedSeries}
                        onChange={handleDropdownChange}
                      >
                        <option value="spend">{props.t("Spend")}</option>
                        <option value="conversionsValue">{props.t("Conversions Value")}</option>
                        <option value="conversions">{props.t("Conversions")}</option>
                        <option value="impressions">{props.t("Impressions")}</option>
                        <option value="clicks">{props.t("Clicks")}</option>
                      </select>
                      <div className="charts">
                        <ReactEcharts
                          option={getOption()}
                          style={{ height: '300px', width: '90%' }}
                        />
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
              <Row>
                <div>
                  {' '}
                  {comparedTo !== '' && (
                    <div
                      style={{
                        display: 'flex',
                        marginTop: '50px',
                        backgroundColor: 'rgb(59 91 152 / 7%)',
                        borderRadius: '5px',
                        width: 'max-content',
                        height: '30px',
                        padding: '15px',
                      }}
                    >
                      <section
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                      >
                        <div
                          style={{
                            width: '13px',
                            height: '13px',
                            background: '#0044BD',
                            borderRadius: '2px',
                            marginRight: '5px',
                            marginTop: '-2px',
                          }}
                        ></div>
                        <span
                          style={{
                            color: '#0C1426',
                            fontSize: '14px',
                            fontWeight: 'bold',
                          }}
                        >
                          {`${chosedDate[0]?.startDate?.toLocaleString('en', {
                            month: 'short',
                            day: '2-digit',
                          })}-${chosedDate[0]?.endDate?.toLocaleString('en', {
                            month: 'short',
                            day: '2-digit',
                          })} ${chosedDate[0]?.endDate?.getFullYear()}`}
                        </span>
                      </section>
                      <section
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          marginLeft: '15px',
                        }}
                      >
                        <div
                          style={{
                            width: '13px',
                            height: '13px',
                            background: '#F6B43B',
                            borderRadius: '2px',
                            marginRight: '5px',
                            marginTop: '-2px',
                          }}
                        ></div>
                        <span
                          style={{
                            color: '#0C1426',
                            fontSize: '14px',
                            fontWeight: 'bold',
                          }}
                        >
                          {lastPeriod.split(':')[1]}
                        </span>
                      </section>
                    </div>
                  )}
                  <br></br>
                </div>
                <Col lg={6}>
                </Col>
              </Row>
            </Row>

            <Row>
              {myCards.map((item) => (
                <GoogleAdsDataCard
                  loading={loadingState}
                  item={item}
                  date={chosedDate}
                  getROAS={getROAS}
                  getAOV={getAOV}
                  getLastAOV={getLastAOV}
                  getCTR={getCTR}
                  getCPC={getCPC}
                  getLastCTR={getLastCTR}
                  getLastROAS={getLastROAS}
                  getLastCPC={getLastCPC}
                  getCPA={getCPA}
                  getLastCPA={getLastCPA}
                  getConversionRate={getConversionRate}
                  getLastConversionRate={getLastConversionRate}
                />
              ))}
            </Row>
          </div>
        </div>
        {/* <Campaignstable data={metrcisbyCompaign} /> */}
        {/* <Adstable data={ads} /> */}
      </div>
    </React.Fragment>
  );
}
GoogleDashboard.propTypes = {
  t: PropTypes.any
};
export default withRouter(withTranslation()(GoogleDashboard));
//export default GoogleDashboard;
