import PropTypes from "prop-types"
import React, { useEffect, useRef, useCallback, useContext, useState } from "react"
import FeatherIcon from "feather-icons-react";
import SimpleBar from "simplebar-react"
import MetisMenu from "metismenujs"
import { withRouter } from "react-router-dom"
import { Link } from "react-router-dom"
import { withTranslation } from "react-i18next"
import { Col, Input } from "reactstrap";
import { ShepherdTour, ShepherdTourContext, TourProvider } from 'react-shepherd'
import { isNewUser, getaccount } from '../../queries/Users/userQuery';
import ApolloClient from 'apollo-boost';
import urls from '../../routes/apiUrls';

//*styles
import 'shepherd.js/dist/css/shepherd.css'

const ApploCLI = new ApolloClient({
  uri: urls.user
});

const backBtnClass = 'btn btn-sm btn-outline-primary',
  nextBtnClass = 'btn btn-sm btn-primary btn-next'

let instance = null;

const Content = () => {
  const tour = useContext(ShepherdTourContext);
  const [isNewUser, setIsNewUser] = useState(null);

  instance = tour;

  useEffect(() => {
    const storedTourStatus = localStorage.getItem('tourStatus'); // Retrieve tour status from localStorage or state
    setIsNewUser(storedTourStatus === 'completed' ? false : true);

    ApploCLI.query({
      query: getaccount,
      context: {
        headers: {
          Authorization: window.localStorage.getItem("token")
            ? `Bearer ${window.localStorage.getItem("token")}`
            : "",
        },
      },
    }).then((data) => {
      console.log("data", data);
      if (data.data) {
        // console.log("data.get.new_account", data.data.get.new_account);
        setIsNewUser(data.data.get.new_account);
        if (data.data.get.new_account === true) {
          //tour.start();
        } else {
          tour.complete();

        }
      }
    });
  }, [tour]);

  useEffect(() => {
    if (isNewUser) {
      tour.start();

    }
  }, [isNewUser]);

  return (
    <Col className='text-center mt-2 pt-50'>
      {/* You can remove the button if you want, as the tour will start automatically */}
      {/* <Input
          type='checkbox'
          onChange={() => tour.start()}
          className='guide-tour-checkbox'
          style={{ marginRight: '5px' }}
        />
        <span style={{ fontSize: '16px' }}></span>*/}
    </Col>
  );
};




const SidebarContent = props => {

  const ref = useRef()
  const activateParentDropdown = useCallback((item) => {
    item.classList.add("active")
    const parent = item.parentElement
    const parent2El = parent.childNodes[1]
    scrollElement(item);
    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show")
    }

    if (parent) {
      parent.classList.add("mm-active")
      const parent2 = parent.parentElement

      if (parent2) {
        parent2.classList.add("mm-show") // ul tag

        const parent3 = parent2.parentElement // li tag

        if (parent3) {
          parent3.classList.add("mm-active") // li
          parent3.childNodes[0].classList.add("mm-active") //a
          const parent4 = parent3.parentElement // ul
          if (parent4) {
            parent4.classList.add("mm-show") // ul
            const parent5 = parent4.parentElement
            if (parent5) {
              parent5.classList.add("mm-show") // li
              parent5.childNodes[0].classList.add("mm-active") // a tag
            }
          }
        }
      }
      scrollElement(item);
      return false
    }
    scrollElement(item);
    return false
  }, []);

  // Use ComponentDidMount and ComponentDidUpdate method symultaniously
  useEffect(() => {
    const pathName = props.location.pathname

    const initMenu = () => {
      new MetisMenu("#side-menu")
      let matchingMenuItem = null
      const ul = document.getElementById("side-menu")
      const items = ul.getElementsByTagName("a")
      for (let i = 0; i < items.length; ++i) {
        if (pathName === items[i].pathname) {
          matchingMenuItem = items[i]
          break
        }
      }
      if (matchingMenuItem) {
        activateParentDropdown(matchingMenuItem)
      }
    }
    initMenu()
  }, [props.location.pathname, activateParentDropdown])

  useEffect(() => {
    ref.current.recalculate()
  })

  function scrollElement(item) {
    if (item) {
      const currentPosition = item.offsetTop
      if (currentPosition > window.innerHeight) {
        ref.current.getScrollElement().scrollTop = currentPosition - 300
      }
    }
  }
  const FromNewUserToOlduser = () => {
    ApploCLI.mutate({
      mutation: isNewUser,
      context: {
        headers: {
          Authorization: window.localStorage.getItem("token")
            ? `Bearer ${window.localStorage.getItem("token")}`
            : "",
        },
      },
    }).then((data) => {
    });
  }
  //const [activeTab, toggleTab] = useState("1");
  const steps = [
    {
      id: 'Hello',
      title: props.t('Welcome to the Guided Tour!'),
      text: props.t('Would you like a quick tour to explore the features of our platform?'),
      attachTo: { element: '.guide-tours', on: 'bottom' },
      cancelIcon: {
        enabled: true
      },
      buttons: [
        {
          action: () => {
            instance.cancel()
            FromNewUserToOlduser();
            window.location.reload();
          },

          classes: backBtnClass,
          text: props.t('Skip Guide Tour'),
        },
        {
          text: props.t('Yes, start tour'),
          classes: nextBtnClass,
          action: () => {
            instance.next();
            // window.location.replace("/overview-dashboard");
            //toggleTab("2"); // Update the active tab here
          }
        }
      ]
    },
    {
      id: props.t('Overview Dashboard'),
      title: props.t('Overview Dashboard'),
      text: props.t('This is your central hub for all your data. Customize it to display the metrics that matter most to you. 📊 '),
      attachTo: { element: '.guide-Overview', on: 'bottom' },
      cancelIcon: {
        enabled: true
      },
      buttons: [
        {
          action: () => {
            instance.cancel()
            FromNewUserToOlduser();
            window.location.reload();
          },
          classes: backBtnClass,
          text: props.t('Skip')
        },
        {
          text: props.t('Next'),
          classes: nextBtnClass,
          action: () => {
            instance.next();
            // Update the active tab here
          }
        }
      ]
    },
    {
      id: props.t('Reports'),
      title: props.t('Reports'),
      text: props.t('🗃️ Check your Reports in this step. Access important data and insights in an organized format. 🗃️ '),
      attachTo: { element: '.guide-Reports', on: 'bottom' },
      cancelIcon: {
        enabled: true
      },
      buttons: [
        {
          action: () => {
            instance.cancel()
            FromNewUserToOlduser();
            window.location.reload();
          },
          classes: backBtnClass,
          text: props.t('Skip')
        },
        {
          text: props.t('Back'),
          classes: backBtnClass,
          action: () => instance.back()
        },
        {
          text: props.t('Next'),
          classes: nextBtnClass,
          action: () => {
            instance.next();
            //toggleTab("3"); // Update the active tab here
          }
        }
      ]
    },
    /*{
      id: 'Emails Platforms',
      title: 'Emails Platforms',
      text: 'This is our third step (3)',
      attachTo: { element: '.guide-Miscell', on: 'bottom' },
      cancelIcon: {
        enabled: true
      },
      buttons: [
        {
          action: () => instance.cancel(),
          classes: backBtnClass,
          text: 'Skip'
        },
        {
          text: 'Back',
          classes: backBtnClass,
          action: () => instance.back()
        },
        {
          text: 'Next',
          classes: nextBtnClass,
          action: () => {
            instance.next();
            //toggleTab("4"); // Update the active tab here
          }
        }
      ]
    },*/
    {
      id: props.t('Presta Dashboard'),
      title: props.t('Presta Dashboard'),
      text: props.t('Dive into the Presta Dashboard, your dedicated space for PrestaShop-related insights and analytics. 🖥️'),
      attachTo: { element: '.guide-presta', on: 'bottom' },
      cancelIcon: {
        enabled: true
      },
      buttons: [
        {
          action: () => {
            instance.cancel()
            FromNewUserToOlduser();
            window.location.reload();
          },
          classes: backBtnClass,
          text: props.t('Skip')
        },
        {
          text: props.t('Back'),
          classes: backBtnClass,
          action: () => instance.back()
        },
        {
          text: props.t('Next'),
          classes: nextBtnClass,
          action: () => {
            instance.next();
            //toggleTab("5"); // Update the active tab here
          }
        }
      ]
    },
    {
      id: props.t('Woo Dashboard'),
      title: props.t('Woo Dashboard'),
      text: props.t('Explore the Woo Dashboard, tailored for WooCommerce analytics. Get a comprehensive view of your WooCommerce store. 👁'),
      attachTo: { element: '.guide-woo', on: 'bottom' },
      cancelIcon: {
        enabled: true
      },
      buttons: [
        {
          action: () => {
            instance.cancel()
            FromNewUserToOlduser();
            window.location.reload();
          },
          classes: backBtnClass,
          text: props.t('Skip')
        },
        {
          text: props.t('Back'),
          classes: backBtnClass,
          action: () => instance.back()
        },
        {
          text: props.t('Next'),
          classes: nextBtnClass,
          action: () => {
            instance.next();
            //toggleTab("5"); // Update the active tab here
          }
        }
      ]
    },
    {
      id: props.t('Google Ads Dashboard'),
      title: props.t('Google Ads Dashboard'),
      text: props.t('Check the Google Ads Dashboard to monitor and optimize your ad campaigns. Get a closer look at your advertising performance. 👁'),
      attachTo: { element: '.guide-GADS', on: 'bottom' },
      cancelIcon: {
        enabled: true
      },
      buttons: [
        {
          action: () => {
            instance.cancel()
            FromNewUserToOlduser();
            window.location.reload();
          },
          classes: backBtnClass,
          text: props.t('Skip')
        },
        {
          text: props.t('Back'),
          classes: backBtnClass,
          action: () => instance.back()
        },
        {
          text: props.t('Next'),
          classes: nextBtnClass,
          action: () => {
            instance.next();
            //toggleTab("5"); // Update the active tab here
          }
        }
      ]
    },
    {
      id: props.t('Meta Ads Dashboard'),
      title: props.t('Meta Ads Dashboard'),
      text: props.t('Explore the Meta Ads Dashboard for insights into your Meta advertising efforts. 👁'),
      attachTo: { element: '.guide-MetaAds', on: 'bottom' },
      cancelIcon: {
        enabled: true
      },
      buttons: [
        {
          action: () => {
            instance.cancel()
            FromNewUserToOlduser();
            window.location.reload();
          },
          classes: backBtnClass,
          text: props.t('Skip')
        },
        {
          text: props.t('Back'),
          classes: backBtnClass,
          action: () => instance.back()
        },
        {
          text: props.t('Next'),
          classes: nextBtnClass,
          action: () => {
            instance.next();
            //toggleTab("5"); // Update the active tab here
          }
        }
      ]
    },
    {
      id: props.t('Social Media Dashboard'),
      title: props.t('Social Media Dashboard'),
      text: props.t('This is your Social Media Dashboard. Monitor and analyze your social media performance in one centralized space. 👁'),
      attachTo: { element: '.guide-SocM', on: 'bottom' },
      cancelIcon: {
        enabled: true
      },
      buttons: [
        {
          action: () => {
            instance.cancel()
            FromNewUserToOlduser();
            window.location.reload();
          },
          classes: backBtnClass,
          text: props.t('Skip')
        },
        {
          text: props.t('Back'),
          classes: backBtnClass,
          action: () => instance.back()
        },
        {
          text: props.t('Next'),
          classes: nextBtnClass,
          action: () => {
            instance.next();
            //toggleTab("5"); // Update the active tab here
          }
        }
      ]
    },
    {
      id: props.t('Klaviyo Dashboard'),
      title: props.t('Klaviyo Dashboard'),
      text: props.t('Dive into the Klaviyo Dashboard for in-depth insights into your email marketing campaigns and strategies. 👁'),
      attachTo: { element: '.guide-klaviyo', on: 'bottom' },
      cancelIcon: {
        enabled: true
      },
      buttons: [
        {
          action: () => {
            instance.cancel()
            FromNewUserToOlduser();
            window.location.reload();
          },
          classes: backBtnClass,
          text: props.t('Skip')
        },
        {
          text: props.t('Back'),
          classes: backBtnClass,
          action: () => instance.back()
        },
        {
          text: props.t('Next'),
          classes: nextBtnClass,
          action: () => {
            instance.next();
            //toggleTab("5"); // Update the active tab here
          }
        }
      ]
    },
    {
      id: props.t('Aramex Dashboard'),
      title: props.t('Aramex Dashboard'),
      text: props.t('Check the Aramex Dashboard for insights into your shipping and logistics with Aramex. 🚚'),
      attachTo: { element: '.guide-Aramex', on: 'bottom' },
      cancelIcon: {
        enabled: true
      },
      buttons: [
        {
          action: () => {
            instance.cancel()
            FromNewUserToOlduser();
            window.location.reload();
          },
          classes: backBtnClass,
          text: props.t('Skip')
        },
        {
          text: props.t('Back'),
          classes: backBtnClass,
          action: () => instance.back()
        },
        {
          text: props.t('Next'),
          classes: nextBtnClass,
          action: () => {
            instance.next();
            //toggleTab("5"); // Update the active tab here
          }
        }
      ]
    },
    {
      id: props.t('Email Builder'),
      title: props.t('Email Builder'),
      text: props.t('Explore our Email Builder, your tool for crafting engaging and effective email campaigns. 📧'),
      attachTo: { element: '.guide-EmailBe', on: 'bottom' },
      cancelIcon: {
        enabled: true
      },
      buttons: [
        {
          action: () => {
            instance.cancel()
            FromNewUserToOlduser();
            window.location.reload();
          },
          classes: backBtnClass,
          text: props.t('Skip')
        },
        {
          text: props.t('Back'),
          classes: backBtnClass,
          action: () => instance.back()
        },
        {
          text: props.t('Next'),
          classes: nextBtnClass,
          action: () => {
            instance.next();
            //toggleTab("5"); // Update the active tab here
          }
        }
      ]
    },
    {
      id: props.t('Integrations'),
      title: props.t('Integrations'),
      text: props.t('Here, you will find all your Integrations in one place. Manage and optimize your connections seamlessly. 🖇️ '),
      attachTo: { element: '.guide-Integrations', on: 'bottom' },
      cancelIcon: {
        enabled: true
      },
      buttons: [
        {
          text: props.t('Back'),
          classes: backBtnClass,
          action: () => instance.back()
        },
        {
          text: props.t('Finish'),
          classes: nextBtnClass,
          action: () => {
            instance.cancel()
            FromNewUserToOlduser();
            window.location.reload(); // Reload the page
          },
        }
      ]
    },

    /*{
      id: 'Advisor',
      title: 'Advisor',
      text: ' 🧭 This is our Advisor, providing guidance and insights for optimizing your dashboard usage. 🧭 ',
      attachTo: { element: '.guide-Advisor', on: 'bottom' },
      cancelIcon: {
        enabled: true
      },
      buttons: [
        {
          action: () => instance.cancel(),
          classes: backBtnClass,
          text: 'Skip'
        },
        {
          text: 'Back',
          classes: backBtnClass,
          action: () => instance.back()
        },
        {
          text: 'Next',
          classes: nextBtnClass,
          action: () => {
            instance.next();
            //toggleTab("5"); // Update the active tab here
          }
        }
      ]
    },
    {
      id: 'Settings',
      title: 'Settings',
      text: ' ⚙️ This is your Settings area. Configure and personalize your dashboard according to your preferences. ⚙️',
      attachTo: { element: '.guide-Settings', on: 'bottom' },
      cancelIcon: {
        enabled: true
      },
      buttons: [
        {
          text: 'Back',
          classes: backBtnClass,
          action: () => instance.back()
        },
        {
          text: 'Finish',
          classes: nextBtnClass,
          action: () => instance.cancel()
        }
      ]
    },
*/
  ]

  return (
    <React.Fragment className="bg-white" >
      <ShepherdTour
        steps={steps}
        tourOptions={{
          useModalOverlay: true,
          scrollParent: window, // Set the scrollParent to the window
        }}
      >
        {/* Content component */}
        <Content />
      </ShepherdTour>

      <SimpleBar style={{ maxHeight: "100%" }} ref={ref}>

        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">

            <li className="guide-Overview">
              <Link to={"/overview-dashboard"}>
                <FeatherIcon icon="home" />
                <span>
                  {props.t("Overview Dashboard")}
                </span>
                </Link>
            </li>
          

          <li>
            <Link to="/#" className="guide-Reports has-arrow">
              <FeatherIcon
                icon="archive"
              />
              <span className="guide-Reports">{props.t("Reports")}</span>
            </Link>
            <ul className="sub-menu">
              <li>
                <Link to="/costs" className="">
                  <span className="guide-Miscell">{props.t("Miscellaneous Costs")}</span>
                </Link>
              </li>
              <li>
                <Link to="/reports-opertations">{props.t("Operations")}</Link>
              </li>
              <li>
                <Link to="/reports-strategic">{props.t("Strategic")}</Link>
              </li>
              {/* <li>
                <Link to="/reports-pnl">{props.t("Profit & Loss")}</Link>
              </li> */}
              <li>
                <Link to="/reports-Npnl">{props.t("Profit & Loss")}</Link>
              </li>

            </ul>
          </li>

          {/* <li className="menu-title">{props.t("Presta Dashboard")}</li>
              <Link to="/#" className="has-arrow">
                <FeatherIcon
                  icon="monitor"
                />
                <span>PrestaShop Dashboard</span>
              </Link> */}
          <li>
            <Link to="/#" className="guide-presta has-arrow">
              <FeatherIcon
                icon="shopping-cart"
              />
              <span className="guide-presta">{props.t("Presta Dashboard")}</span>
            </Link>
            <ul className="sub-menu">
              <li>
                <Link to="/prestashop-dashboard">{props.t("Presta Overview")}</Link>
              </li>
              {/* <li>
                <Link to="/prestashop-all-orders">{props.t("Orders")}</Link>
              </li> */}
              <li>
                <Link to="/prestashop-all-products">{props.t("Products")}</Link>
              </li>
            </ul>
          </li>
          <li>
            <Link to="/#" className=" has-arrow">
              <i className='bx bxl-shopify'></i>
              <span className="">{props.t("Shopify Dashboard")}</span>
            </Link>
            <ul className="sub-menu">
              <li>
                <Link to="/prestashop-dashboard">{props.t("Shopify Overview")}</Link>
              </li>
              {/* <li>
                <Link to="/prestashop-all-orders">{props.t("Orders")}</Link>
              </li> */}
              {/* <li>
                <Link to="/prestashop-all-products">{props.t("Products")}</Link>
              </li> */}
            </ul>
          </li>
          <li>

            <Link to="/#" className="guide-woo has-arrow">
              <i className='bx bxl-wordpress'></i>
              <span className="guide-woo">{props.t("Woo Dashboard")}</span>
            </Link>
            <ul className="sub-menu">
              <li>
                <Link to="/woo_dashboard"> {props.t("Woo Overview")}</Link>
              </li>
              <li>
                <Link to="/woo_coupons">{props.t("Coupons Codes")}</Link>
              </li>
              <li>
                <Link to="/woo_top_sellers">{props.t("Top Sellers")}</Link>
              </li>
              <li>
                <Link to="/woo_orders_status">{props.t("Orders Status")}</Link>
              </li>
              {/* <li>
                <Link to="/woo_product_category">{props.t("Product Category")}</Link>
              </li> */}
            </ul>
          </li>
          <li>

            <Link to="/#" className="guide-GADS has-arrow">
              <i className='bx bxl-google'></i>
              <span className="guide-GADS">{props.t("Google Ads Dashboard")}</span>
            </Link>
            <ul className="sub-menu">
              <li>
                <Link to="/google-dashboard">{props.t("Google Overview")}</Link>
              </li>
              <li>
                <Link to="/google-campaigns-details">{props.t("Campaigns")}</Link>
              </li>
              <li>
                <Link to="/google-ads-details">{props.t("Ads")}</Link>
              </li>
              <li>
                <Link to="/google-keywords-details">{props.t("Keywords Performance")}</Link>
              </li>
            </ul>
          </li>
          <li>
            <Link to="/#" className="guide-MetaAds has-arrow">
              <i className='bx bxl-facebook'></i>
              <span className="guide-MetaAds">{props.t("Meta Ads Dashboard")}</span>
            </Link>
            <ul className="sub-menu">
              <li>
                <Link to="/ads-dashboard">{props.t("Meta Overview")}</Link>
              </li>
              <li>
                <Link to="/fb-campaigns">{props.t("Campaigns")}</Link>
              </li>
              <li>
                <Link to="/MetaAds">{props.t("Ads")}</Link>
              </li>
              

            </ul>
          </li>
          <li>

            <Link to="/#" className="guide-SocM has-arrow">
              <i className='bx bx-share-alt'></i>
              <span className="guide-SocM"> {props.t("SocialMedia Dashboard")}</span>
            </Link>
            <ul className="sub-menu">
              <li>
                <Link to="/fb-social-media-dashboard">{props.t(" Facebook Social Media Overview")}</Link>
              </li>
              <li>
                <Link to="/InstagramDashboard">{props.t("Instagram  Social Media Overview")}</Link>
              </li>
            </ul>
          </li>


          {/* <li className="menu-title">{props.t("Ads Dashboards")}</li>
            <li>
              <Link to="/ads-dashboard" className="">
                <FeatherIcon
                  icon="facebook"
                />
                <span>{props.t("Facebook Dashboard")}</span>
              </Link>
            </li> */}
          <li>
            {/* <Link to="/google-dashboard" className="">
                <FeatherIcon
                  icon="bar-chart-2"
                />
                <span>{props.t("Google Dashboard")}</span>
              </Link> */}
          </li>
          {/* <li>
              <li className="menu-title">{props.t("Analytics Dashboard")}</li>
              <Link to="/#" className="has-arrow">
                <FeatherIcon
                  icon="archive"
                />
                <span>Google Analytics</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/google-analytics-ad-words">AdWords</Link>
                </li>
                <li>
                  <Link className="has-arrow" to="#">Platforms & Devices</Link>
                  <ul className="sub-menu">
                    <li>
                      <Link to="/google-analytics">Browsers</Link>
                    </li>
                    <li>
                      <Link to="/google-analytics-operating-system">Operating System</Link>
                    </li>
                    <li>
                      <Link to="/google-analytics-mobile-device-branding">Mobile Device Branding</Link>
                    </li>
                    <li>
                      <Link to="/google-analytics-device-category-data-source">Device Category & Data Source</Link>
                    </li>
                  </ul>
                </li>
                <li>
                  <Link to="/google-analytics-tracking-page">Tracking Page</Link>
                </li>
                <li>
                  <Link to="/google-analytics-users">Users</Link>
                </li>
                <li>
                  <Link to="/google-analytics-audience">Audience</Link>
                </li>
                <li>
                  <Link to="/google-analytics-ecommerce-affiliation">E Commerce</Link>
                </li>
                <li>
                  <Link className="has-arrow" to="/google-analytics-event-tracking">Event Tracking</Link>
                  <ul className="sub-menu">
                    <li>
                      <Link to="/google-analytics-event-tracking">Event Category Tracking</Link>
                    </li>
                    <li>
                      <Link to="/google-analytics-event-action-tracking">Event Action Tracking</Link>
                    </li>
                  </ul>
                </li>
              </ul> 
            </li>*/}

          <li>
            <Link to="/Klaviyo-Dashboard" className="guide-klaviyo">
              <i className='bx bx-envelope-open'></i>
              <span className="guide-klaviyo">{props.t("Klaviyo Dashboard")}</span>
            </Link>
          </li>


          <li>
            <Link to="/Aramex-Dashboard" className="guide-Aramex">
              <FeatherIcon
                icon="truck"
              />
              <span className="guide-Aramex">{props.t("Aramex Dashboard")}</span>

            </Link>
          </li>
          <li>
            <Link to="/email-builder" className="guide-EmailBe" >
              <FeatherIcon
                icon="send"
              />
              <span className="guide-EmailBe" >{props.t("Email Builder")}</span>
            </Link>

          </li>
          <li>
            <Link to="/integrations" className="guide-Integrations">
              <FeatherIcon
                icon="paperclip"
              />
              <span className="guide-Integrations">{props.t("Integrations")}</span>
            </Link>
          </li>
          <li>
            <Link to="/advisor" className="">
              <FeatherIcon
                icon="compass"
              />
              <span className="guide-Advisor">{props.t("Advisor")}</span>
            </Link>
          </li>
          <li>
            <Link to="/#" className="guide-Settings has-arrow">
              <FeatherIcon
                icon="settings"
              />
              <span className="guide-Settings">{props.t("Settings")}</span>
            </Link>
            <ul className="sub-menu">
              <li>
                <Link to="/settings-profile">{props.t("Profile")}</Link>
              </li>
              {/* <li>
                  <Link to="/settings-stores">{props.t("Stores")}</Link>
                </li> */}
              <li>
                <Link to="/billing">{props.t("Billing")}</Link>
              </li>
              <li>
                <Link to="/settings-members">{props.t("Members")}</Link>
              </li>
            </ul>
          </li>
        </ul>


      </div>
    </SimpleBar>

    </React.Fragment >
  )
}
SidebarContent.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
}
export default withRouter(withTranslation()(SidebarContent))
