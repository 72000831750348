import{
    GET_STRATEGIC_REPORTS_REQUEST,
    GET_STRATEGIC_REPORTS_SUCCESS,
    GET_STRATEGIC_REPORTS_FAILURE,
} from './action';
 const initialState = {
    strat:[],
    series:[],
avg:0,
daily:0,
avgNetProfit:0,
avgCPS:0,
avgROA:0,
avgACPO:0,
avgMM:0,
avgAdCost:0,
totalFacebook:0,
totalGoogle:0,
avgFacebook:0,
avgGoogle:0,
totalNetProfit:0,
totalAdCost:0,
totalAov:0,
totalCps:0,
totalRoas:0,
totalAcpo:0,
totalMm:0,
tableaov:[],
error: null,
 };

 export default function STRATEGIC(state = initialState, action) {  
        switch (action.type) {
            case GET_STRATEGIC_REPORTS_REQUEST:
                return {
                    ...state,
                    error: null,
                };
            case GET_STRATEGIC_REPORTS_SUCCESS:
                return {
                    ...state,
                    strat: action.payload.strat,
    series: action.payload.series,
avg: action.payload.avg,
daily: action.payload.daily,
avgNetProfit: action.payload.avgNetProfit,
avgCPS: action.payload.avgCPS,
avgROA: action.payload.avgROA,
avgACPO:    action.payload.avgACPO,
avgMM:  action.payload.avgMM,
avgAdCost:  action.payload.avgAdCost,
totalFacebook:  action.payload.totalFacebook,
totalGoogle:    action.payload.totalGoogle,
avgFacebook:    action.payload.avgFacebook,
avgGoogle:  action.payload.avgGoogle,
totalNetProfit: action.payload.totalNetProfit,
totalAdCost:    action.payload.totalAdCost,
totalAov:   action.payload.totalAov,
totalCps:   action.payload.totalCps,
totalRoas:  action.payload.totalRoas,
totalAcpo:  action.payload.totalAcpo,
totalMm:    action.payload.totalMm,
tableaov:       action.payload.tableaov,
                    error: null,
                };
            case GET_STRATEGIC_REPORTS_FAILURE:
                return {
                    ...state,
                    error: action.payload.error,
                };
            default:
                return state;
            

        }
     

 }