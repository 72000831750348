import React, { useEffect, useState, useRef } from 'react';
import MetaTags from 'react-meta-tags';
import Select from "react-select"
import axios from "axios";
import { Row, Col, FormGroup, InputGroup, Container, Input, Label, CardBody, Card, Spinner } from 'reactstrap';
//i18n
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Breadcrumbs from '../../components/Common/Breadcrumb';
import { withRouter } from 'react-router-dom';
import { DateRange } from 'react-date-range';
import { useDispatch, useSelector } from 'react-redux';
import FacebookAdsOverview from './FacebookAdsOverview';
import GoogleAdsOverview from './GoogleAdsOverview';
import FacebookpageOverview from './FacebookpageOverview';
import StoreOverview from './StoreOverview';
import GoogleAnalyticsOverview from './GoogleAnalyticsOverview';
import {
  getOverviewDataRequest,
} from '../../store/reports/overview/action';
import GenralOverviewCard from './genralOverviewCard';
import MetaAdsFacebook from './Ads-creative-meta';
import Offcanvas from 'react-bootstrap/Offcanvas';
import OffcanvasHeader from 'react-bootstrap/OffcanvasHeader';
import OffcanvasBody from 'react-bootstrap/OffcanvasBody';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import FeatherIcon from 'feather-icons-react';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import logo from '../../assets/images/Live_Metrics_Logo.png';
import { motion } from 'framer-motion';
import InstagrampageOverview from './InstagrampageOverview';

const daysBetween = function (date1, date2) {
  var one_day = 1000 * 60 * 60 * 24;
  var date1_ms = date1.getTime();
  var date2_ms = date2.getTime();
  var difference_ms = date2_ms - date1_ms;
  return Math.round(difference_ms / one_day);
};
function useOutsideAlerter(ref, toggleDate) {
  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        toggleDate(false);
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref]);
}
const datePattern = /(\d{1,2})\/(\d{1,2})\/(\d{4})/;

function OverviewDashboard(props) {

  const dispatch = useDispatch();
  const { data, loading } = useSelector(
    (state) => state.overview
  );


  const wrapperRef = useRef(null);
  const [toggleDate, setToggleDate] = useState(false);
  useOutsideAlerter(wrapperRef, setToggleDate);
  const [currentFocus, setCurrentFocus] = useState([]);
  const [dateRage, setDateRange] = useState({
    date: [
      window.localStorage.getItem('start')
        ? new Date(window.localStorage.getItem('start'))
        : new Date(
          new Date().toLocaleDateString('en-US', { timeZone: props.timeZone })
        ),
      window.localStorage.getItem('end')
        ? new Date(window.localStorage.getItem('end'))
        : new Date(
          new Date().toLocaleDateString('en-US', { timeZone: props.timeZone })
        ),
    ],
  });
  const [lastPeriod, setLastPeriod] = useState('');
  const [chosedDate, setChosedDate] = useState([
    {
      startDate: window.localStorage.getItem('start')
        ? new Date(window.localStorage.getItem('start'))
        : new Date(
          new Date().toLocaleDateString('en-US', { timeZone: props.timeZone })
        ),
      endDate: window.localStorage.getItem('end')
        ? new Date(window.localStorage.getItem('end'))
        : new Date(
          new Date().toLocaleDateString('en-US', { timeZone: props.timeZone })
        ),
      key: 'selection',
    },
  ]);
  const [activeBtn, setActiveBtn] = useState(
    window.localStorage.getItem('activeBtn')
      ? JSON.parse(window.localStorage.getItem('activeBtn'))
      : {
        today: 1,
        yesterday: 0,
        last7days: 0,
        last30day: 0,
        last60day: 0,
        month: 0,
        other: 0,
      }
  );
  const [comparedTo, setComparedTo] = useState('');
  const mnths = [
    { num: 0, add: 2 },
    { num: 1, add: 0 },
    { num: 2, add: 2 },
    { num: 3, add: 1 },
    { num: 4, add: 2 },
    { num: 5, add: 1 },
    { num: 6, add: 2 },
    { num: 7, add: 2 },
    { num: 8, add: 1 },
    { num: 9, add: 2 },
    { num: 10, add: 1 },
    { num: 11, add: 2 },
  ];
  const getToAddOnMonths = (int) => {
    let res = 0;
    let month = 0;
    if (int === 0) {
      month = int;
    } else {
      month = int - 1;
    }
    mnths.map((x) => {
      if (x.num === month) res = x.add;
    });
    return res;
  };
  useEffect(() => {
    if (chosedDate[0] && chosedDate[0].startDate && chosedDate[0].endDate) {
      const startDate = chosedDate[0].startDate;
      const endDate = chosedDate[0].endDate;
      const newStartDate = new Date(startDate).setDate(
        new Date(startDate).getDate() - daysBetween(startDate, endDate) - 1
      );
      const newEndDate = new Date(endDate).setDate(
        new Date(endDate).getDate() - daysBetween(startDate, endDate) - 1
      );
      comparedTo !== 'year'
        ? setLastPeriod(
          `${'Comparedto'} : ${new Date(newStartDate).toLocaleString('en', {
            day: '2-digit',
            month: 'short',
          })}-${new Date(newEndDate).toLocaleString('en', {
            day: '2-digit',
            month: 'short',
          })} ${new Date(newEndDate).getFullYear()}`
        )
        : setLastPeriod(
          `${'Comparedto'}: ${new Date(startDate).toLocaleString('en', {
            day: '2-digit',
            month: 'short',
          })}-${new Date(endDate).toLocaleString('en', {
            day: '2-digit',
            month: 'short',
          })} ${new Date(endDate).getFullYear() - 1}`
        );
    }
  }, [chosedDate, comparedTo]);
  const buttonHandler = (startDate, endDate) => {
    setDateRange({ date: [startDate, endDate] });
    setChosedDate([
      {
        startDate: startDate,
        endDate: endDate,
        key: 'selection',
      },
    ]);
    setToggleDate(false);
    window.localStorage.setItem('start', startDate);
    window.localStorage.setItem('end', endDate);
  };
  // end of  date range picker
  const [isOffcanvasOpen, setIsOffcanvasOpen] = useState(false);
  const [isChecked, setChecked] = useState(true);
  const [fbchecked, setfbChecked] = useState(true);
  const [fbpagechecked, setfbpageChecked] = useState(true);
  const [instagrampagechecked, setinstagrampageChecked] = useState(true);
  const [isGoogleAdsChecked, setGoogleAdsChecked] = useState(true);
  const [isPeriodChecked, setPeriodChecked] = useState(false);
  const [isYearChecked, setYearChecked] = useState(false);
  const [storechecked, setStoreChecked] = useState(true);
  // const[loading,setloading]=useState(false)
  const toggleOffcanvas = () => {
    setIsOffcanvasOpen(!isOffcanvasOpen);
  };
  const handleGoogleAdsToggle = () => {
    setGoogleAdsChecked(!isGoogleAdsChecked);
  };
  const handleToggle = () => {
    setChecked(!isChecked);
  };
  const handlefbToggle = () => {
    setfbChecked(!fbchecked);
  };
  const handlefbpageToggle = () => {
    setfbpageChecked(!fbpagechecked);
  };
  const handleinstagrampageToggle = () => {
    setinstagrampageChecked(!instagrampagechecked);
  };
  const handelStoreToggle = () => {
    setStoreChecked(!storechecked);
  };
  const [selectedImage, setSelectedImage] = useState(null);

  useEffect(() => {
    // Check localStorage for a previously selected image
    const storedImage = localStorage.getItem('selectedImage');
    if (storedImage) {
      setSelectedImage(storedImage);
    }
  }, []);

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (event) => {
        const imageDataURL = event.target.result;

        // Save the selected image URL to localStorage
        localStorage.setItem('selectedImage', imageDataURL);

        setSelectedImage(imageDataURL);
      };
      reader.readAsDataURL(file);
    }
  };

  const note =
 props.t('here you can write a note for your report, this note will be displayed on the bottom  of the report');

  const [selectedGroup, setselectedGroup] = useState(null);
  const [storecurrency, setstorecurrency] = useState({
    selectedOptionValue: null,
    conversion_rate: null,
  });
  const optionGroup = [
    {
      label: "USD - United States Dollar",
      value: "USD",
    },
    {
      label: "EUR - Euro",
      value: "EUR",
    },

    {
      label: "LYD - Libyan Dinar",
      value: "LYD",
    },
    {
      label: "MAD - Moroccan Dirham",
      value: "MAD",
    },
    {
      label: "TND - Tunisian Dinar",
      value: "TND",
    },

  ]
  function handleSelectGroup(selectedOption) {

    setselectedGroup(selectedOption)
    axios.get(`https://v6.exchangerate-api.com/v6/c0fd644ef51cdd9e2aed5b22/latest/usd`)
      .then(response => {
        console.log(response.data.conversion_rates[selectedOption.value])
        setstorecurrency({ selectedOptionValue: selectedOption.value, conversion_rate: response.data.conversion_rates[selectedOption.value], })
        // Handle the response data here
      })
  }

  const handleChildClick = (valueFromChild) => {
    // This function receives the value from the child component
    setIsOffcanvasOpen(valueFromChild)
    // Process the received value or update the state as needed in the parent component
  };

  const [cards, setCards] = useState([
    { id: 1, component: storechecked ? <StoreOverview data={data} storecurrency={storecurrency} handleClick={handleChildClick} /> : null },
    { id: 2, component: isChecked ? <GenralOverviewCard data={data} /> : null },
    { id: 3, component: fbchecked ? <FacebookAdsOverview facebook={data.facebookMetrics} chosedDate={chosedDate} dateRage={dateRage} /> : null },
    {id :4, component:MetaAdsFacebook ? <MetaAdsFacebook chosedDate={chosedDate} dateRage={dateRage} /> : null},
    { id: 5, component: fbpagechecked ? <FacebookpageOverview facebookpage={data.facebookpage} /> : null },
    { id: 6, component: isGoogleAdsChecked ? <GoogleAdsOverview google={data.GoogleMetrics} chosedDate={chosedDate} dateRage={dateRage} /> : null },
    { id: 7, component: instagrampagechecked ? <InstagrampageOverview chosedDate={chosedDate} dateRage={dateRage} /> : null },

    // Add other components similarly
  ]);
  useEffect(() => {
    // Update cards based on changes in data variables
    setCards([
      { id: 1, component: storechecked ? <StoreOverview data={data} storecurrency={storecurrency} handleClick={handleChildClick} /> : null },
      { id: 2, component: isChecked ? <GenralOverviewCard data={data} /> : null },
      { id: 3, component: fbchecked ? <FacebookAdsOverview facebook={data.facebookMetrics} chosedDate={chosedDate} dateRage={dateRage} /> : null },
      {id :4, component:MetaAdsFacebook ? <MetaAdsFacebook chosedDate={chosedDate} dateRage={dateRage} /> : null},
      { id: 5, component: fbpagechecked ? <FacebookpageOverview facebookpage={data.facebookpage} /> : null },
      { id: 6, component: isGoogleAdsChecked ? <GoogleAdsOverview google={data.GoogleMetrics} chosedDate={chosedDate} dateRage={dateRage} /> : null },
      { id: 7, component: instagrampagechecked ? <InstagrampageOverview chosedDate={chosedDate} dateRage={dateRage} /> : null },

      // Add other components similarly
    ]);
  }, [isChecked, fbchecked, fbpagechecked, isGoogleAdsChecked, data, chosedDate, dateRage, storechecked, storecurrency, instagrampagechecked]);

  const handleCompareToggle = (compare_to) => {
    setComparedTo(compare_to);
    if (comparedTo === compare_to) {
      setComparedTo('');
    }
  };
  useEffect(() => {
    // setloading(true)
    var newStartDate = datePattern.exec(
      chosedDate[0].startDate && chosedDate[0].startDate.toLocaleString('fr-FR')
    );
    var newEndDate = datePattern.exec(
      chosedDate[0].endDate && chosedDate[0].endDate.toLocaleString('fr-FR')
    );
    var startDate =
      newStartDate[3] + '-' + newStartDate[2] + '-' + newStartDate[1];
    var endDate = newEndDate[3] + '-' + newEndDate[2] + '-' + newEndDate[1];
    if (newStartDate && newEndDate) {
      dispatch(getOverviewDataRequest(startDate, endDate))
    }
  }, [dateRage, chosedDate]);
  useEffect(() => {
    if (currentFocus[1] === 0) {
      setDateRange({
        date: [
          new Date(
            new Date(
              chosedDate[0].startDate.toLocaleDateString('en-US', {
                timeZone: props.timeZone ? props.timeZone : 'UTC',
              })
            )
          ),
          new Date(
            new Date(
              chosedDate[0].endDate.toLocaleDateString('en-US', {
                timeZone: props.timeZone ? props.timeZone : 'UTC',
              })
            )
          ),
        ],
      });
      setToggleDate(false);
    }
  }, [currentFocus]);
  useEffect(() => {
    window.localStorage.setItem('start', chosedDate[0].startDate);
    window.localStorage.setItem('end', chosedDate[0].endDate);
  }, [chosedDate]);


  const handleDrag = (startIndex, endIndex) => {
    const result = Array.from(cards);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    setCards(result);
  };
  const [downloadloading, setdownloadloading] = useState(false);
  const exportToPDF = async () => {
    try {
      setdownloadloading(true)
      const capture = document.querySelector('.print');
      const canvas = await html2canvas(capture, { useCORS: true, scale: 1 }); // Adjust scale for higher resolution
      const imgData = canvas.toDataURL('image/png', 9.0); // Adjust quality
      const doc = new jsPDF({
        orientation: 'portrait', // 'portrait' or 'landscape'
        unit: 'mm',
        format: [canvas.width, canvas.height], // A4 size in millimeters (default)
      });


      const componentWidth = doc.internal.pageSize.getWidth();
      const componentHeight = doc.internal.pageSize.getHeight();
      const padding = 20; // Adjust this value to set the padding in millimeters
      // Add padding to X and Y coordinates
      doc.addImage(
        imgData,
        'PNG',
        1 + padding,
        padding,
        componentWidth - 2 * padding,
        componentHeight - 2 * padding
      );
      // // Customize the appearance of the PDF
      // doc.setLineWidth(0.5); // Set line width for borders
      // doc.setDrawColor(103, 137, 242); // Set border color to black
      // doc.rect(1, 1, componentWidth - 1, componentHeight - 1, 'S'); // Add a border around the content
      doc.save('Rapport-Instagram-Ads.pdf');
    } catch (error) {
      console.error('Error exporting to PDF:', error);
    } finally {
      setdownloadloading(false)
    }
  };

  return (
    <React.Fragment>
      <div className="page-content print">
        <MetaTags>
          <title>
            {props.t('Overview Dashboard')} | Live-Metrics - E-com SaaS
          </title>
        </MetaTags>
        <Container fluid >

          <Card className="tamour">
            <CardBody className="">
              <div>
                <div className="invoice-title">
                  <div className="d-flex align-items-start">
                    <div className="flex-grow-1">
                      <div className="">
                        <img src={logo} alt="" height="27" />
                      </div>
                    </div>
                    <div className="flex-shrink-0">
                      <div className="" style={{ display: 'flex', alignItems: 'center' }}>
                        <h4 className="font-size-16">
                          {props.t('Overview Dashboard')}
                        </h4>
                        {/* <FeatherIcon
                          icon="edit-2"
                          className="icon-md"
                          onClick={toggleOffcanvas}
                          style={{ cursor: 'pointer', position: 'relative', top: '-5px' }}
                        /> */}
                        <i className='bx bx-edit ' style={{ cursor: 'pointer', position: 'relative', top: '-5px', left: '10px', fontSize: '1.5em' }} onClick={toggleOffcanvas}> </i>
                      </div>
                    </div>

                  </div>
                  <div className="d-flex align-items-center justify-content-between">
                    <div className="flex-grow-1">
                      {/* <div className="">
                        <address>
                          <br />
                          <span>
                            {props.t(
                              'Industrial Zone Khéreddine Zone North West,'
                            )}
                          </span>
                          <br />
                          <span>Tunis, Tunisia</span>
                        </address>
                      </div> */}
                    </div>
                    <div style={{ marginRight: '-30px' }} className="flex-grow-1">
                      {/* Input element for uploading an image */}
                      <input
                        type="file"
                        id="imageUpload"
                        accept="image/*"
                        style={{ display: 'none' }}
                        onChange={handleImageChange}
                      />
                      {selectedImage ? (
                        <img
                          src={selectedImage}
                          alt="Selected Image"
                          style={{ maxWidth: '200px', maxHeight: '200px', cursor: 'pointer' }}
                          onClick={() => document.getElementById('imageUpload').click()}
                        />
                      ) : (
                        <label htmlFor="imageUpload" className="btn btn-primary waves-effect waves-light w-sm">
                          <i className="mdi mdi-upload d-block font-size-16 text-white"></i>
                          {props.t("Upload Brand Image")}
                        </label>
                      )}
                    </div>
                    <div className="flex-shrink-0">
                      <FormGroup className="mt-3">
                        <InputGroup>
                          <div>
                            <div className="divDateContainer">
                              <div ref={wrapperRef}>
                                <button
                                  type="button"
                                  className="btn btn-light"
                                  onClick={() => setToggleDate(!toggleDate)}
                                  id="page-header-search-dropdown"
                                >
                                  {chosedDate[0] !== null
                                    ? [
                                      chosedDate[0].startDate.toLocaleString(
                                        'en',
                                        {
                                          month: 'short',
                                        }
                                      ),
                                      ' ',
                                      chosedDate[0].startDate.getDate(),
                                      ' ',
                                      chosedDate[0].startDate.getFullYear(),
                                      '  -  ',
                                      chosedDate[0].endDate &&
                                      chosedDate[0].endDate.toLocaleString(
                                        'en',
                                        {
                                          month: 'short',
                                        }
                                      ),
                                      ' ',
                                      chosedDate[0].endDate &&
                                      chosedDate[0].endDate.getDate(),
                                      ' ',
                                      chosedDate[0].endDate &&
                                      chosedDate[0].endDate.getFullYear(),
                                    ]
                                    : null}
                                </button>

                                {toggleDate && (
                                  <div
                                    style={{
                                      position: 'fixed',
                                      display: 'flex',
                                      right: '35px',
                                      zIndex: 9999,
                                    }}
                                  >
                                    <div
                                      className="calenderDivStyle"
                                      style={{ right: 0 }}
                                    >
                                      <div className="btnsCalendar">
                                        <DateRange
                                          editableDateInputs={true}
                                          moveRangeOnFirstSelection={false}
                                          onRangeFocusChange={(item) => {
                                            setCurrentFocus(item);
                                          }}
                                          onChange={(item) => {
                                            setChosedDate([item.selection]);
                                            setActiveBtn({
                                              today: 0,
                                              yesterday: 0,
                                              last7days: 0,
                                              last30day: 0,
                                              last60day: 0,
                                              month: 0,
                                              other: 1,
                                            });
                                            window.localStorage.setItem(
                                              'activeBtn',
                                              JSON.stringify({
                                                today: 0,
                                                yesterday: 0,
                                                last7days: 0,
                                                last30day: 0,
                                                last60day: 0,
                                                month: 0,
                                                other: 1,
                                              })
                                            );
                                          }}
                                          maxDate={new Date(new Date().getTime() - 24 * 60 * 60 * 1000)}
                                          disabledDates={[
                                            {
                                              after: new Date(), // Disable dates after today
                                            },
                                          ]}
                                          ranges={chosedDate}
                                          dragSelectionEnabled={true}
                                          rangeColors={['#6689F2']}
                                          months={2}
                                          direction="horizontal"
                                        />
                                      </div>
                                      <div className="calendarDiv">
                                        <div className="btnCalendarContainer">
                                          <button
                                            className={
                                              activeBtn.today
                                                ? 'dateBtns activeBtnDate'
                                                : 'dateBtns'
                                            }
                                            onClick={() => {
                                              buttonHandler(
                                                new Date(
                                                  new Date().toLocaleDateString(
                                                    'en-US',
                                                    {
                                                      timeZone:
                                                        window.localStorage.getItem(
                                                          'timezone'
                                                        ),
                                                    }
                                                  )
                                                ),
                                                new Date(
                                                  new Date().toLocaleDateString(
                                                    'en-US',
                                                    {
                                                      timeZone:
                                                        window.localStorage.getItem(
                                                          'timezone'
                                                        ),
                                                    }
                                                  )
                                                )
                                              );
                                              setActiveBtn({
                                                today: 1,
                                                yesterday: 0,
                                                last7days: 0,
                                                last30day: 0,
                                                last60day: 0,
                                                month: 0,
                                                other: 0,
                                              });
                                              window.localStorage.setItem(
                                                'activeBtn',
                                                JSON.stringify({
                                                  today: 1,
                                                  yesterday: 0,
                                                  last7days: 0,
                                                  last30day: 0,
                                                  last60day: 0,
                                                  month: 0,
                                                  other: 0,
                                                })
                                              );
                                            }}
                                          >
                                            {props.t('compareto')}
                                          </button>
                                          <button
                                            className={
                                              activeBtn.yesterday
                                                ? 'dateBtns activeBtnDate'
                                                : 'dateBtns'
                                            }
                                            onClick={() => {
                                              const timeZone =
                                                window.localStorage.getItem(
                                                  'timezone'
                                                ) || 'UTC'; // set default time zone to UTC
                                              buttonHandler(
                                                new Date(
                                                  new Date(
                                                    new Date().getTime() -
                                                    24 * 60 * 60 * 1000
                                                  ).toLocaleDateString(
                                                    'en-US',
                                                    {
                                                      timeZone: timeZone,
                                                    }
                                                  )
                                                ),

                                                new Date(
                                                  new Date(
                                                    new Date().getTime() -
                                                    24 * 60 * 60 * 1000
                                                  ).toLocaleDateString(
                                                    'en-US',
                                                    {
                                                      timeZone: timeZone,
                                                    }
                                                  )
                                                )
                                              );
                                              setActiveBtn({
                                                today: 0,
                                                yesterday: 1,
                                                last7days: 0,
                                                last30day: 0,
                                                last60day: 0,
                                                month: 0,
                                                other: 0,
                                              });
                                              window.localStorage.setItem(
                                                'activeBtn',
                                                JSON.stringify({
                                                  today: 0,
                                                  yesterday: 1,
                                                  last7days: 0,
                                                  last30day: 0,
                                                  last60day: 0,
                                                  month: 0,
                                                  other: 0,
                                                })
                                              );
                                            }}
                                          >
                                            {props.t('yesterday')}
                                          </button>
                                          <button
                                            className={
                                              activeBtn.last7days
                                                ? 'dateBtns activeBtnDate'
                                                : 'dateBtns'
                                            }
                                            onClick={() => {
                                              const timeZone =
                                                window.localStorage.getItem(
                                                  'timezone'
                                                ) || 'UTC'; // set default time zone to UTC
                                              buttonHandler(
                                                new Date(
                                                  new Date(
                                                    new Date().getTime() -
                                                    6 * 24 * 60 * 60 * 1000
                                                  ).toLocaleDateString(
                                                    'en-US',
                                                    {
                                                      timeZone: timeZone,
                                                    }
                                                  )
                                                ),
                                                new Date(
                                                  new Date().toLocaleDateString(
                                                    'en-US',
                                                    { timeZone: timeZone }
                                                  )
                                                )
                                              );
                                              setActiveBtn({
                                                today: 0,
                                                yesterday: 0,
                                                last7days: 1,
                                                last30day: 0,
                                                last60day: 0,
                                                month: 0,
                                                other: 0,
                                              });
                                              window.localStorage.setItem(
                                                'activeBtn',
                                                JSON.stringify({
                                                  today: 0,
                                                  yesterday: 0,
                                                  last7days: 1,
                                                  last30day: 0,
                                                  last60day: 0,
                                                  month: 0,
                                                  other: 0,
                                                })
                                              );
                                            }}
                                          >
                                            {props.t('last7days')}
                                          </button>
                                          <button
                                            className={
                                              activeBtn.last30day
                                                ? 'dateBtns activeBtnDate'
                                                : 'dateBtns'
                                            }
                                            onClick={() => {
                                              const timeZone =
                                                window.localStorage.getItem(
                                                  'timezone'
                                                ) || 'UTC'; // set default time zone to UTC
                                              buttonHandler(
                                                new Date(
                                                  new Date(
                                                    new Date().getTime() -
                                                    29 * 24 * 60 * 60 * 1000
                                                  ).toLocaleDateString(
                                                    'en-US',
                                                    {
                                                      timeZone: timeZone,
                                                    }
                                                  )
                                                ),
                                                new Date(
                                                  new Date().toLocaleDateString(
                                                    'en-US',
                                                    { timeZone: timeZone }
                                                  )
                                                )
                                              );
                                              setActiveBtn({
                                                today: 0,
                                                yesterday: 0,
                                                last7days: 0,
                                                last30day: 1,
                                                last60day: 0,
                                                month: 0,
                                                other: 0,
                                              });
                                              window.localStorage.setItem(
                                                'activeBtn',
                                                JSON.stringify({
                                                  today: 0,
                                                  yesterday: 0,
                                                  last7days: 0,
                                                  last30day: 1,
                                                  last60day: 0,
                                                  month: 0,
                                                  other: 0,
                                                })
                                              );
                                            }}
                                          >
                                            {props.t('last30days')}
                                          </button>
                                          <button
                                            className={
                                              activeBtn.month
                                                ? 'dateBtns activeBtnDate'
                                                : 'dateBtns'
                                            }
                                            onClick={() => {
                                              const timeZone =
                                                window.localStorage.getItem(
                                                  'timezone'
                                                ) || 'UTC'; // set default time zone to UTC
                                              buttonHandler(
                                                new Date(
                                                  new Date(
                                                    new Date().getFullYear(),
                                                    new Date().getMonth(),

                                                    getToAddOnMonths(
                                                      new Date().getMonth()
                                                    )
                                                  ).toLocaleDateString(
                                                    'en-US',
                                                    {
                                                      timeZone: timeZone,
                                                    }
                                                  )
                                                ),

                                                new Date(
                                                  new Date().toLocaleDateString(
                                                    'en-US',
                                                    { timeZone: timeZone }
                                                  )
                                                )
                                              );
                                              setActiveBtn({
                                                today: 0,
                                                yesterday: 0,
                                                last7days: 0,
                                                last30day: 0,
                                                last60day: 0,
                                                month: 1,
                                                other: 0,
                                              });
                                              window.localStorage.setItem(
                                                'activeBtn',
                                                JSON.stringify({
                                                  today: 0,
                                                  yesterday: 0,
                                                  last7days: 0,
                                                  last30day: 0,
                                                  last60day: 0,
                                                  month: 1,
                                                  other: 0,
                                                })
                                              );
                                            }}
                                          >
                                            {props.t('thismonth')}
                                          </button>
                                          <span className="compareSpan">
                                            <b>{props.t('compareto')}</b>
                                          </span>
                                          <div className="form-check mb-3">
                                            <input
                                              style={{
                                                width: '1em',
                                                height: '1em',
                                                marginTop: '0.25em',
                                                verticalAlign: 'top',
                                                backgroundColor: '#fff',
                                                backgroundRepeat: 'no-repeat',
                                                backgroundPosition: 'center',
                                                border: '#000000',
                                              }}
                                              type="checkbox"
                                              id="formCheck1"
                                              className={
                                                comparedTo === 'period'
                                                  ? 'compareTogglebtnStyleActive'
                                                  : null
                                              }
                                              checked={comparedTo === 'period'}
                                              onClick={() => {
                                                handleCompareToggle('period');
                                                setPeriodChecked(
                                                  !isPeriodChecked
                                                );
                                              }}
                                            />
                                            <label
                                              className="form-check-label"
                                              htmlFor="formCheck1"
                                            >
                                              {props.t('previousperiod')}
                                            </label>
                                          </div>
                                          <div className="form-check mb-3">
                                            <input
                                              style={{
                                                width: '1em',
                                                height: '1em',
                                                marginTop: '0.25em',
                                                verticalAlign: 'top',
                                                backgroundColor: '#fff',
                                                backgroundRepeat: 'no-repeat',
                                                backgroundPosition: 'center',
                                                border: '#000000',
                                              }}
                                              type="checkbox"
                                              id="formCheck1"
                                              className={
                                                comparedTo === 'year'
                                                  ? 'compareTogglebtnStyleActive'
                                                  : null
                                              }
                                              checked={comparedTo === 'year'}
                                              onChange={() => {
                                                handleCompareToggle('year');
                                                setYearChecked(!isYearChecked);
                                              }}
                                            />
                                            <label
                                              className="form-check-label"
                                              htmlFor="formCheck1"
                                            >
                                              {props.t('Previousyear')}
                                            </label>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                )}
                                <br />
                                <small>
                                  {comparedTo !== '' ? lastPeriod : null}
                                </small>
                              </div>
                            </div>
                          </div>
                        </InputGroup>
                      </FormGroup>
                    </div>
                  </div>
                </div>
              </div>
            </CardBody>
          </Card>
          {loading ? (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100vh', // Set to 100% of the viewport height
              }}
            >
              <Spinner color="primary" style={{ height: '3rem', width: '3rem' }} />
            </div>
          ) : (
            <Row>
              <Col lg={12}>
                <Card >
                  <CardBody>
                    <div className=''>
                      <div className="d-flex align-items-start ">


                        <div className="flex-shrink-0">

                          <Offcanvas
                            show={isOffcanvasOpen}
                            onHide={toggleOffcanvas}
                            placement="end"
                          >
                            <OffcanvasHeader closeButton>
                              {' '}
                              {props.t('Custom Settings')}

                            </OffcanvasHeader>
                            <hr className="" />
                            <OffcanvasBody>
                              <div className="modal-header">

                              </div>

                              <div className="mb-3">
                                <label htmlFor="choices-single-default" className="form-label font-size-13 text-muted">{props.t("Select Your  Webtsite currency")} :</label>
                                <Select
                                  value={selectedGroup}
                                  onChange={handleSelectGroup}
                                  options={optionGroup}
                                  classNamePrefix="select2-selection"
                                />
                              </div>
                              {' '}
                              <div className="modal-header">
                                {/* <h5 className="modal-title" id="staticBackdropLabel"> {props.t("")}</h5> */}
                              </div>
                              <div className="form-check form-switch mb-3" dir="ltr">
                                <input
                                  type="checkbox"
                                  className="form-check-input"
                                  id="customSwitch1"
                                  defaultChecked={isChecked}
                                  onChange={handleToggle}
                                />
                                <label className="form-check-label" htmlFor="customSwitch1">
                                  show Genral overview
                                </label>
                              </div>
                              <div className="form-check form-switch mb-3" dir="ltr">
                                <input
                                  type="checkbox"
                                  className="form-check-input"
                                  id="customSwitch1"
                                  defaultChecked={storechecked}
                                  onChange={handelStoreToggle}
                                />
                                <label className="form-check-label" htmlFor="customSwitch1">
                                  show Store overview
                                </label>
                              </div>

                              <div className="form-check form-switch mb-3" dir="ltr">
                                <input
                                  type="checkbox"
                                  className="form-check-input"
                                  id="customSwitch1"
                                  defaultChecked={fbchecked}
                                  onChange={handlefbToggle}
                                />
                                <label className="form-check-label" htmlFor="customSwitch1">
                                  show facebook ads overview
                                </label>
                              </div>
                              <div className="form-check form-switch mb-3" dir="ltr">
                                <input
                                  type="checkbox"
                                  className="form-check-input"
                                  id="customSwitch1"
                                  defaultChecked={fbpagechecked}
                                  onChange={handlefbpageToggle}
                                />
                                <label className="form-check-label" htmlFor="customSwitch1">
                                  show facebook Page overview
                                </label>
                              </div>
                              <div className="form-check form-switch mb-3" dir="ltr">
                                <input
                                  type="checkbox"
                                  className="form-check-input"
                                  id="customSwitch1"
                                  defaultChecked={isGoogleAdsChecked}
                                  onChange={handleGoogleAdsToggle}
                                />
                                <label className="form-check-label" htmlFor="customSwitch1">
                                  show Google overview
                                </label>
                              </div>
                              <div className="form-check form-switch mb-3" dir="ltr">
                                <input
                                  type="checkbox"
                                  className="form-check-input"
                                  id="customSwitch1"
                                  defaultChecked={instagrampagechecked}
                                  onChange={handleinstagrampageToggle}
                                />
                                <label className="form-check-label" htmlFor="customSwitch1">
                                  show Instagram overview
                                </label>
                              </div>
                            </OffcanvasBody>
                          </Offcanvas>
                        </div>
                      </div>

                      {/* <Row>{isChecked ? <GenralOverviewCard data={data} /> : null}</Row>
<Row>{fbchecked ? <FacebookAdsOverview facebook={data.facebookMetrics} /> : null}</Row>
<Row>{fbpagechecked ? <FacebookpageOverview facebookpage={data.facebookpage} /> : null}</Row>
<Row>{isGoogleAdsChecked ? <GoogleAdsOverview  google={data.GoogleMetrics}/> : null}</Row> */}
                      <Row>{/* <GoogleAnalyticsOverview /> */}</Row>
                      
                      <div className="drag-container">
                        {cards.map((card, index) => (
                          card.component && ( // Check if the component exists before rendering
                            <React.Fragment key={card.id}>
                              <div className="drag-item" style={{ border: "none" }}>
                                <div className="drag-handle">
                                  {/* <FeatherIcon icon="menu" className="icon-md" /> */}
                                </div>
                                {card.component}
                              </div>
                              {index !== cards.length - 1 && <hr />} {/* Add <hr> between cards except for the last one */}
                            </React.Fragment>
                          )
                        ))}
                      </div>




                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}

<hr className='invoice-spacing mt-0' />

{/* Invoice Note */}
<CardBody className='invoice-padding py-0'>
  <Row>
    <Col>
      <div className='mb-2'>
        <Label for='note' className='form-label fw-bold'>
          {props.t("Recommandation")}:
        </Label>
        <Input type='textarea' rows='2' id='note' defaultValue={note} />
      </div>
    </Col>
  </Row>
</CardBody>
        </Container>
        <button
          onClick={exportToPDF}
          className={`btn btn-primary w-md ${loading ? 'disabled' : ''}`}
          disabled={loading}
        >
          {downloadloading ? (
            <>
              <i className="bx bx-loader bx-spin font-size-16 align-middle me-2 text-white" /> Loading
            </>
          ) : (
            props.t('Download')
          )}
        </button>
      </div>
    </React.Fragment>
  );
}
OverviewDashboard.propTypes = {
  t: PropTypes.any,
};
export default withRouter(withTranslation()(OverviewDashboard));
