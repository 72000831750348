export const GET_METRICS_BY_CAMPAIGNS = 'GET_METRICS_BY_CAMPAIGNS';
export const GET_METRICS_BY_CAMPAIGNS_SUCCESS = 'GET_METRICS_BY_CAMPAIGNS_SUCCESS';
export const GET_METRICS_BY_CAMPAIGNS_FAILURE = 'GET_METRICS_BY_CAMPAIGNS_FAILURE';

export const GET_ADS= 'GET_ADS';
export const GET_ADS_SUCCESS = 'GET_ADS_SUCCESS';
export const GET_ADS_FAILURE = 'GET_ADS_FAILURE';

export const GET_METRICS_BY_CAMPAIGNS_PER_DATE = 'GET_METRICS_BY_CAMPAIGNS_PER_DATE';
export const GET_METRICS_BY_CAMPAIGNS_PER_DATE_SUCCESS = 'GET_METRICS_BY_CAMPAIGNS_PER_DATE_SUCCESS';
export const GET_METRICS_BY_CAMPAIGNS_PER_DATE_FAILURE = 'GET_METRICS_BY_CAMPAIGNS_PER_DATE_FAILURE';

// GET GOOGLE ADS KEYWORDS
export const GET_KEYWORDS_GOOGLE_ADS_REQUEST = 'GET_KEYWORDS_GOOGLE_ADS_REQUEST';
export const GET_KEYWORDS_GOOGLE_ADS_SUCCESS = 'GET_KEYWORDS_GOOGLE_ADS_SUCCESS';
export const GET_KEYWORDS_GOOGLE_ADS_FAILURE = 'GET_KEYWORDS_GOOGLE_ADS_FAILURE';

// GET GOOGLE ADS METRICS 
export const GET_GOOGLE_METRICS = 'GET_GOOGLE_METRICS';
export const GET_GOOGLE_METRICS_SUCCESS = 'GET_GOOGLE_METRICS_SUCCESS';
export const GET_GOOGLE_METRICS_FAILURE = 'GET_GOOGLE_METRICS_FAILURE';


export const getMetricsByCampaigns = () => ({
    type: GET_METRICS_BY_CAMPAIGNS,
})

export const getMetricsByCampaignsSuccess = (metrcisbyCompaign) => ({
    type: GET_METRICS_BY_CAMPAIGNS_SUCCESS,
    payload: {metrcisbyCompaign},
})

export const getMetricsByCampaignsFailure = (error) => ({
    type: GET_METRICS_BY_CAMPAIGNS_FAILURE,
    payload: {error},
})


export const getAdsRequest = () => ({
    type: GET_ADS,

})
export const getAdsSuccess = (ads) => ({
    type: GET_ADS_SUCCESS,
    payload: {ads},
})

export const getAdsFailure = (error) => ({
    type: GET_ADS_FAILURE,
    payload: {error},
})

export const getMetricsByCampaignsPerDate = (startDate,endDate) => ({
    type: GET_METRICS_BY_CAMPAIGNS_PER_DATE,
    payload: {startDate,endDate},
})
export const getMetricsByCampaignsPerDateSuccess = (MetrcisbyCompaignSelectedDate) => ({
    type: GET_METRICS_BY_CAMPAIGNS_PER_DATE_SUCCESS,
    payload: {MetrcisbyCompaignSelectedDate},

})
export const getMetricsByCampaignsPerDateFailure = (error) => ({
    type: GET_METRICS_BY_CAMPAIGNS_PER_DATE_FAILURE,
    payload: {error},
})
export const getGoogleMetrics = (startDate,endDate,comparedTo) => ({
    type: GET_GOOGLE_METRICS,
    payload: {startDate,endDate,comparedTo},
})
export const getGoogleMetricsSuccess = (DataDashboard) => ({
    type: GET_GOOGLE_METRICS_SUCCESS,
    payload: {DataDashboard},
})

export const getGoogleMetricsFailure = (error) => ({
    type: GET_GOOGLE_METRICS_FAILURE,
    payload: {error},
})
// Get keywords 
export const getKeywordsRequest = () =>({
    type : GET_KEYWORDS_GOOGLE_ADS_REQUEST
})
export const getKeywordsSuccess = (gaKeywords) => ({
    type : GET_KEYWORDS_GOOGLE_ADS_SUCCESS,
    payload : {gaKeywords}
})
export const getKeywordsFailure = (error) =>({
    type : GET_KEYWORDS_GOOGLE_ADS_FAILURE,
    payload:{ error }
})