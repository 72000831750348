import React, { useEffect, useState } from 'react';
import MetaTags from 'react-meta-tags';
import { Card, CardBody, CardHeader, CardTitle, Col, Container, Nav, NavItem, NavLink, Row, TabContent, TabPane } from "reactstrap";
import { Link,useLocation } from 'react-router-dom';
import classnames from "classnames";
import urls from '../../routes/apiUrls';
//Import Breadcrumb
import ApolloClient from 'apollo-boost';
import { Paiment,verifPaiment } from '../../queries/Users/userQuery';
import PlanDetaills from "./paiementComponents/PlanDetaills"

import Swal from 'sweetalert2';
const ApploCLI = new ApolloClient({
    uri: urls.user
  });




const useQuery = () => {
    return new URLSearchParams(useLocation().search);
};

const PagePricing = ({ PlanType }) => {


    const yearData = [
        {
            id: 1,
            title: "Essential",
            price: 299000,
            desc: "Designed for small brands and growing businesses, ",
            integration: "All Platforms",
            users: 1,
            reporting: "Basic",
            Multicurrency:"Yes",
            CogsZones:"Products Only",
            HitoricalData:"yes",
           
        },
        {
            id: 2,
            title: "Meduim",
            price: 499000,
            desc: "For larger businesses or those with onal administration needs",
            integration: "All Platforms",
            users: 2,
            reporting: "Profit Reports",
            Multicurrency:"Yes",
            CogsZones:"Only One",
          
            HitoricalData:"yes",
        },
        {
            id: 3,
            title: "Full-Metrics",
            price: 999000,
            desc: "For extra large businesses or those in regulated industries",
            integration: "All Platforms",
            users: "Unlimited",
            reporting: "Full Metrics Reports",
            Multicurrency:"Yes",
            CogsZones:"Unlimited",
           
            HitoricalData:"yes",

        },
    ];
     // Set isFeatured based on PlanType
     const updatedYearData = yearData.map((year) => {
        if (year.title === PlanType) {
            return { ...year, isFeatured: true };
        } else {
            return { ...year, isFeatured: false };
        }
    });
    const [activeTab2, toggleTab2] = useState("");

    const verifpaiement = async (payment_id,Type,Amount) => {
        try {
            await ApploCLI.query({
                query: verifPaiment,
                variables:{
                    id_paiement: payment_id,
                    Type: Type,
                    Amount: Amount
    
                },
                context: {
                    headers: {
                        Authorization: window.localStorage.getItem('token') ?
                            `Bearer ${window.localStorage.getItem('token')}` :
                            '',
                    }
                },
                errorPolicy: "all",
            }).then(res => {
                console.log("verif",res);
                if( res.data.verif_paiement.success=="SUCCESS"){
                    Swal.fire({
                        title: 'Success!',
                        text: "Paiement effectué avec succès!",
                        icon: 'success',
                        confirmButtonText: "Done"
                    }).then((result) => {
                        if (result.isConfirmed) {
                            window.location.href = "/settings-profile";
                        }
                    })
                }else{
                    Swal.fire({
                        title: 'Error!',
                        text: "Erreur lors du paiement! please check  your card informations",
                        icon: 'error',
                        confirmButtonText: "Done"
                    }).then((result) => {
                        if (result.isConfirmed) {
                            window.location.href = "/settings-profile";
                        }
                    })
                }
            }).catch(err => {
                console.log("veriiif error",err);
            }) 
           
        } catch (error) {
            console.log(error);
        }
    }  

    const functionpaiement = async (amount,type) => {
        try {
            const { data } = await ApploCLI.mutate({
                mutation: Paiment,
                variables:{
                    amount: amount,
                    Type: type
                },
                context: {
                    headers: {
                        Authorization: window.localStorage.getItem('token') ?
                            `Bearer ${window.localStorage.getItem('token')}` :
                            '',
                    }
                },
                errorPolicy: "all",
            }).then(res => {
const queryParams = new URLSearchParams({ amount, type }).toString();

const redirectUrl = `${res.data.paiement.link}?${queryParams}`;
window.location.href = redirectUrl;
           
              
            }).catch(err => {
                console.log(err);
            }) 
           
        } catch (error) {
            console.log(error);
        }
    }
    const query = useQuery();
    const paymentId = query.get('payment_id');
    const Type = query.get('Type');
    const Amount = query.get('amount');

    const getButtonTextsecond = (PlanType) => {
        if (PlanType === "Premium") {
            return "current plan";
        } else if (PlanType === "Standard") {
            return "Upgrade";
        } else {
            return "Choose Plan";
        }
    };
    const getButtonTextfirst = (PlanType) => {
        if (PlanType === "Standard") {
            return "This is your plan";
        } else if (PlanType === "Premium") {
            return "Downgrade your plan";
        } else {
            return "Choose Plan";
        }
    };
    useEffect(() => {
        if (paymentId){
            verifpaiement(paymentId,Type,Amount);
        }

        
    }, [paymentId]);  
    return (
         <React.Fragment>
            <Container fluid>
                <Row>
                    <Col xl={12}>
                        <Card>
                            <CardHeader>
                                <h4 className="card-title">Subscription</h4>
                                <p className="card-title-desc">Choose the subscription you want to sign up for and turn your data into opportunities!</p>
                            </CardHeader>   
                            <CardBody>
                            <Row>
                                                <PlanDetaills yearData={updatedYearData} onChoosePlan={functionpaiement} />
                                            </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
               
            </Container>
        </React.Fragment>
    );
};

export default PagePricing;