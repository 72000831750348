import React, { useEffect } from "react"
import { Row, Col, Card, CardBody, CardTitle, CardHeader } from "reactstrap"
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, {
  PaginationProvider, PaginationListStandalone,
  SizePerPageDropdownStandalone
} from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import { useDispatch, useSelector } from 'react-redux';
import { fetchOrderStatusRequest } from '../../../store/woocommerce/actions';
import "../../PrestaDashbaord/datatables.scss"
import { withRouter } from "react-router-dom"
import { withTranslation } from "react-i18next"
import PropTypes from "prop-types";
const WooCommercepIcon = `${process.env.PUBLIC_URL}/images/integrations_images/WooCommerce.svg`;

function WooOrdersStatusTable(props) {

  const dispatch = useDispatch();
  const { orderStatus } = useSelector((state) => state.woocommerce);
  useEffect(() => {
    const savedOrderStatus = localStorage.getItem('wooorderstatus');
    if (savedOrderStatus) {

      dispatch({ type: 'FETCH_ORDER_STATUS_SUCCESS', payload: JSON.parse(savedOrderStatus) });
    } else {
      dispatch(fetchOrderStatusRequest(15, 1));
    }
  }, [dispatch]);

  const columns = [{
    dataField: 'orderStatus.name',
    text: props.t('Name'),
    sort: false,
  }
    , {
    dataField: 'orderStatus.total',
    text: props.t('Total'),
    sort: true
  }
  ];
  const dataMapOrders = orderStatus ? orderStatus.map((item) => item) : [];

  console.log("dataMapOrders", dataMapOrders);
  const defaultSorted = [{
    dataField: 'id',
    order: 'asc'
  }];
  const pageOptions = {
    sizePerPage: 5,
    totalSize: dataMapOrders.length,
    custom: true,
  }
  const selectRow = {
    mode: 'checkbox'
  }
  const { SearchBar } = Search;

  return (
    <React.Fragment>
      <div className="container-fluid">
        <Card>
          <CardHeader>
            <CardTitle className="d-flex">
              <h4 className="card-title mb-0 flex-grow-1">{props.t("Orders Status Table")} </h4>
              <img className="img-intgration-icon" src={WooCommercepIcon} />
            </CardTitle>
          </CardHeader>
          <CardBody>
            <PaginationProvider
              pagination={paginationFactory(pageOptions)}
              keyField='id'
              columns={columns}
              data={dataMapOrders}
            >
              {({ paginationProps, paginationTableProps }) => (
                <ToolkitProvider
                  keyField='id'
                  columns={columns}
                  data={dataMapOrders}
                  search
                >
                  {toolkitProps => (
                    <React.Fragment>
                      <Row className="mb-2">
                        <Col md="4">
                          <div className="search-box me-2 mb-2 d-inline-block">
                            <div className="position-relative">
                              <SearchBar
                                {...toolkitProps.searchProps}
                              />
                              <i className="bx bx-search-alt search-icon" />
                            </div>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col xl="12">
                          <div className="table-responsive">
                            <BootstrapTable
                              keyField={"id"}
                              responsive
                              bordered={true}
                              striped={true}
                              defaultSorted={defaultSorted}
                              //selectRow={selectRow}
                              classes={
                                "table align-middle table-nowrap"
                              }
                              headerWrapperClasses={"thead-light"}
                              {...toolkitProps.baseProps}
                              {...paginationTableProps}
                            />
                          </div>
                        </Col>
                      </Row>
                      <Row className="align-items-md-center mt-30">
                        <Col className="inner-custom-pagination d-flex">
                          <div className="d-inline">
                            <SizePerPageDropdownStandalone
                              {...paginationProps}
                            />
                          </div>
                          <div className="text-md-right ms-auto">
                            <PaginationListStandalone
                              {...paginationProps}
                            />
                          </div>
                        </Col>
                      </Row>
                    </React.Fragment>
                  )
                  }
                </ToolkitProvider>
              )
              }</PaginationProvider>
          </CardBody>
        </Card>
      </div>
    </React.Fragment>
  )
}
WooOrdersStatusTable.propTypes = {
  t: PropTypes.any
};
export default withRouter(withTranslation()(WooOrdersStatusTable));
//export default WooOrdersStatusTable
