import React, { useEffect, useState } from 'react';
import { Card, CardBody, Col, Row } from 'reactstrap';
import '../Integrations/styles.css';
import { withTranslation } from "react-i18next"
import PropTypes from 'prop-types';
import { withRouter } from "react-router-dom"
import { Link } from 'react-router-dom';
import { Button, Space, Table, Menu, Dropdown,Checkbox  } from 'antd';
const FacebookAdsIcon = `${process.env.PUBLIC_URL}/images/integrations_images/fb1.svg`;



const FacebookPageOverview = (props) => {
  const posts = (props.facebookpage && props.facebookpage.posts) || [];
  // console.log("posts", posts);
  // test ant table
  const [filteredInfo, setFilteredInfo] = useState({});
  const [sortedInfo, setSortedInfo] = useState({});

  const [paginationOptions, setPaginationOptions] = useState({
    pageSize: 5, // Default page size
    pageSizeOptions: ['5', '10', '20'], // Available page size options
  });
  
  const formatNumberWithSpaces = (value) => {
    if (value !== undefined && value !== null) {
      return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    }
    return ""; // or any default value you want to return for undefined/null cases
  };
  const handleChange = (pagination, filters, sorter) => {
    setFilteredInfo(filters);
    setSortedInfo(sorter);
    if (pagination.pageSize !== paginationOptions.pageSize) {
      setPaginationOptions({
        ...paginationOptions,
        pageSize: pagination.pageSize,
      });
    }
  };
  // const clearFilters = () => {
  //   setFilteredInfo({});
  // };
  const clearAll = () => {
    //setFilteredInfo({});
    setSortedInfo({});
    // make the columns  selected
    setSelectedColumns(columns.map((column) => column.key));
  };
  const setAgeSort = () => {
    setSortedInfo({
      order: 'descend',
      columnKey: 'post_impressions',
    });
  };
  const columns = [
    
    {
      title: props.t('Full Picture'),
      dataIndex: 'full_picture',
      key: 'full_picture',
      align: 'center',

      // sortOrder: sortedInfo.columnKey === 'name' ? sortedInfo.order : null,
      ellipsis: true,
      render: (text, record) => {
        if (!text) {
          return (
            <a href={record.permalink_url} target="_blank" rel="noopener noreferrer">
            <span>{' There is Non-image '}</span>
            </a>
          );
        } else {
          return (
            <a href={record.permalink_url} target="_blank" rel="noopener noreferrer">
              <img
                src={text}
                alt={`Full Picture ${record.full_picture}`}
                style={{ maxWidth: '100px', maxHeight: '90px' }}
              />
            </a>
          );
        }
      }
    },
    // {
    //   title: 'Captions',
    //   dataIndex: 'message',
    //   key: 'message',
    //   ellipsis: true,
    //   width: 150,
    //   align: 'center',
    //   render: (text) => <strong>{text}</strong>,
    // },
    {
      title: props.t('Post clicks'),
      dataIndex: 'post_clicks',
      align: 'center',
      key: 'post_clicks',
      sorter: (a, b) => a.post_clicks - b.post_clicks,
      sortOrder: sortedInfo.columnKey === 'post_clicks' ? sortedInfo.order : null,
      ellipsis: true,
      render: (text) => <strong>{formatNumberWithSpaces(text)}</strong>,
    },
    {
      title: props.t('Post likes'),
      dataIndex: 'post_likes',
      key: 'post_likes',
      align: 'center',
      sorter: (a, b) => a.post_likes - b.post_likes,
      sortOrder:
        sortedInfo.columnKey === 'post_likes' ? sortedInfo.order : null,
      ellipsis: true,
      render: (text) => <strong>{formatNumberWithSpaces(text)}</strong>,
    },
    {
      title: props.t('Post Total Reactions'),
      dataIndex: 'postReactionsTotal',
      key: 'postReactionsTotal',
      align: 'center',
      sorter: (a, b) => a.postReactionsTotal - b.postReactionsTotal,
      sortOrder:
        sortedInfo.columnKey === 'postReactionsTotal' ? sortedInfo.order : null,
      ellipsis: true,
      render: (text) => <strong>{formatNumberWithSpaces(text)}</strong>,
    },
    {
      title: props.t('Post impressions'),
      dataIndex: 'post_impressions',
      align: 'center',
      key: 'post_impressions',
      onFilter: (value, record) => record.address.includes(value),
      sorter: (a, b) => a.post_impressions - b.post_impressions,
      sortOrder: sortedInfo.columnKey === 'post_impressions' ? sortedInfo.order : null,
      ellipsis: true,
      render: (text) => <strong>{formatNumberWithSpaces(text)}</strong>,
    },
    {
      title: props.t('Shares'),
      dataIndex: 'shares',
      key: 'shares',
      align: 'center',
      sorter: (a, b) => a.shares - b.shares,
      sortOrder: sortedInfo.columnKey === 'shares' ? sortedInfo.order : null,
      ellipsis: true,
      render: (text) => <strong>{formatNumberWithSpaces(text)}</strong>,

    },
    {
      title: props.t('Created At'),
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (text, record, index) => {
        const formattedDate = text ? new Date(text).toLocaleDateString() : '';
        return <strong>{formattedDate}</strong>;
      },
      // width: 50,
      align: 'center',
    },
  ];
  const [selectedColumns, setSelectedColumns] = useState(
    columns.map((column) => column.key)
  );

  const handleMenuClick = (clickedKey) => {
    const index = selectedColumns.indexOf(clickedKey);

    let updatedSelectedColumns = [];

    if (index === -1) {
      // Column was not selected before, add it to the selected columns
      updatedSelectedColumns = [...selectedColumns, clickedKey];
    } else {
      // Column was selected before, remove it from the selected columns
      updatedSelectedColumns = selectedColumns.filter(
        (key) => key !== clickedKey
      );
    }

    setSelectedColumns(updatedSelectedColumns);
  };

  const filteredColumns = columns.filter((column) =>
    selectedColumns.includes(column.key)
  );

  const menu = (
    <Menu>
      {columns.map((column) => (
        <Menu.Item
          key={column.key}
          onClick={() => handleMenuClick(column.key)}
        >
          <Checkbox
            checked={selectedColumns.includes(column.key)}
            style={{ marginRight: '8px' }}
          />
          <span
            style={
              selectedColumns.includes(column.key)
                ? { fontWeight: 'bold' }
                : {}
            }
          >
            {column.title}
          </span>
        </Menu.Item>
      ))}
    </Menu>
  );
  // let dataMaparamexTrackingNumbers = [];
  // const { SearchBar } = Search;
  // if (posts) { // Add optional chaining here
  //   dataMaparamexTrackingNumbers = posts?.map((item) => {
  //       return item;
  //     }).flat();
  // }
  // console.log("dataMaparamexTrackingNumbers",dataMaparamexTrackingNumbers);
  // const defaultSorted = [{
  //   dataField: 'post_impressions',
  //   order: 'asc'
  // }];
  // const pageOptions = {
  //   sizePerPage: 5,
  //   totalSize: posts.length,  // replace later with size(customers),
  //   custom: true,
  // }
  // const columns = [
  //   {
  //     dataField: 'full_picture',
  //     text: props.t("Full Picture"),
  //     formatter: (cell, row) => (
  //       <img src={cell} alt={`Full Picture ${row.full_picture}`} style={{ maxWidth: '100px' }} />
  //     ),
  //     sort: false,
  //   },
  //   {
  //     dataField: 'message',
  //     text: props.t("Message"),
  //     style: {
  //       maxWidth: '300px', // Set a maximum width for the cell
  //       overflow: 'hidden', // Hide overflow content
  //       whiteSpace: 'nowrap', // Prevent text from wrapping
  //       textOverflow: 'ellipsis', // Show ellipsis for truncated text
  //     },

  //   },
  //   {
  //     dataField: 'post_likes',
  //     text: props.t("Likes"),
  //     sort: true,
  //     sortFunc: (a, b, order, dataField) => {
  //       if (order === 'asc') {
  //         return a - b; // Ascending order
  //       } else {
  //         return b - a; // Descending order
  //       }
  //     },
  //   },
  //   {
  //     dataField: 'post_impressions',
  //     text: props.t("Impressions"),
  //     sort: true,
  //     sortFunc: (a, b, order, dataField) => {
  //       if (order === 'asc') {
  //         return a - b; // Ascending order
  //       } else {
  //         return b - a; // Descending order
  //       }
  //     },
  //   },
  // ];



  return (
    <React.Fragment>
      <Col xl={12}>

        <div className="d-flex flex-wrap align-items-center mb-4">
          <h5 className="card-title me-2" style={{ marginTop: '-24px', marginLeft: '25px' }}>Facebook Social Page: {props.facebookpage ? props.facebookpage.fb_page_name : ''}</h5>
          <img className="img-overview-dashboard ms-auto" src={FacebookAdsIcon} style={{ marginTop: '-30px' }} />
        </div>
        <Row>
          <Col xl={3} md={4}>
            <Card className="card-h-100">
              <CardBody className='facebook-ads-card'>
                <div className="my-0 text-primary d-flex justify-content-between align-items-center">
                  <span className="my-0 text-primary">
                    <i className="fa fa-info-circle text-primary" aria-hidden="true" data-tooltip="Facebook Like"></i>{" "}
                    {props.t("Likes")}
                  </span>
                  <div>
                    <img className="img-intgration-icon" src={FacebookAdsIcon} />
                  </div>

                </div>
                <Row className="align-items-center">
                  <Col xs={6}>
                    <div className="  mb-3">
                      <h4>
                        {props.facebookpage ? formatNumberWithSpaces(props.facebookpage.likes_numbre) : 0}
                      </h4>
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
          <Col xl={3} md={4}>
            <Card className="card-h-100">
              <CardBody className='facebook-ads-card'>
                <div className="my-0 text-primary d-flex justify-content-between align-items-center">
                  <span className="my-0 text-primary">
                    <i className="fa fa-info-circle text-primary" aria-hidden="true" data-tooltip="Facebook Followers"></i>{" "}
                    {props.t("Followers")}
                  </span>
                  <div>
                    <img className="img-intgration-icon" src={FacebookAdsIcon} />
                  </div>

                </div>
                <Row className="align-items-center">
                  <Col xs={6}>
                    <div className="  mb-3">
                      <h4>
                        {props.facebookpage ?formatNumberWithSpaces( props.facebookpage.followers_numbre ): 0}
                      </h4>
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
          <Col xl={3} md={4}>
            <Card className="card-h-100">
              <CardBody className='facebook-ads-card'>
                <div className="my-0 text-primary d-flex justify-content-between align-items-center">
                  <span className="my-0 text-primary">
                    <i className="fa fa-info-circle text-primary" aria-hidden="true" data-tooltip="Facebook New Likes  "></i>{" "}
                    {props.t("New Likes")}
                  </span>
                  <div>
                    <img className="img-intgration-icon" src={FacebookAdsIcon} />
                  </div>

                </div>
                <Row className="align-items-center">
                  <Col xs={6}>
                    <div className="  mb-3">
                      <h4>
                        {props.facebookpage ? formatNumberWithSpaces(props.facebookpage.new_likes) : 0}
                      </h4>
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
          <Col xl={3} md={4}>
            <Card className="card-h-100">
              <CardBody className='facebook-ads-card'>
                <div className="my-0 text-primary d-flex justify-content-between align-items-center">
                  <span className="my-0 text-primary">
                    <i className="fa fa-info-circle text-primary" aria-hidden="true" data-tooltip="Facebook EngagedUsers  "></i>{" "}
                    {props.t("Engaged Users")}
                  </span>
                  <div>
                    <img className="img-intgration-icon" src={FacebookAdsIcon} />
                  </div>

                </div>
                <Row className="align-items-center">
                  <Col xs={6}>
                    <div className="  mb-3">
                      <h4>
                        {props.facebookpage ? formatNumberWithSpaces(props.facebookpage.engageduser) : 0}
                      </h4>
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>

        </Row>
        <hr />
        <Row>
                    <Card style={{ border: "none" }}>
                      <CardBody >
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                          }}
                        >
                          <h4 className="card-title mb-4">
                            {props.t('Facebook Page posts')}
                          </h4>
                          <img className="img-intgration-icon" src={FacebookAdsIcon} />
                        </div>
                        <Space
                          style={{
                            marginBottom: 16,
                          }}
                        >
                          {/* Add any additional buttons or controls specific to Facebook here */}
                          <Button onClick={setAgeSort}>{props.t("Sort Impressions")}</Button>
                          
                          <Button onClick={clearAll}>{props.t("Clear sorters")}</Button>
                          <Dropdown overlay={menu} trigger={['click']}>
                            <Button>{props.t("Select Columns")}</Button>
                          </Dropdown>
                        </Space>
                        <Table
                          // style={{ border: "1px solid #e8e8e8" }}
                          columns={filteredColumns}
                          dataSource={posts || []}
                          onChange={handleChange}
                          pagination={{
                            ...paginationOptions,
                            onChange: handleChange,
                            showSizeChanger: true, // Enable the page size selector
                            // showTotal: (total) => `Total ${total} items`, // Optionally show total items
                            // Other pagination settings
                          }}
                         
                        />
                      </CardBody>
                    </Card>
                  </Row>
     
        <div className="mt-2-overview-dashboard">
          <Link to="/fb-social-media-dashboard" className="btn btn-primary btn-sm">{props.t("View more")}<i className="overview-dashboard-icon mdi mdi-arrow-right ms-1"></i></Link>
        </div>


      </Col>
    </React.Fragment>
  );
}
FacebookPageOverview.propTypes = {
  t: PropTypes.any
};
export default withRouter(withTranslation()(FacebookPageOverview));