import React, { useState, useEffect, useContext } from 'react';
import {
    Card, CardBody, Col, Container, Row, Modal, Label, Input, FormGroup, Alert, Button, ModalBody,Badge,
} from "reactstrap";
import { Table, Thead, Tbody, Tr, Th,Td } from "react-super-responsive-table"
import { integrationswoRequest, deleteWoocommerceRequest, getSalesPlateformeRequest, checkThirdStepWoocommerceRequest } from '../../../store/woocommerce/actions';
import { useDispatch, useSelector } from 'react-redux';
import { deleteAlert } from '../../Alerts/NotLinkedAccountAlert';
import Swal from 'sweetalert2';
//import WooLogo from "../Woocommerce/woologo.png";
import WooLogo from "../Woocommerce/woo2.png";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { ShepherdTour, ShepherdTourContext } from 'react-shepherd'
//*styles
import 'shepherd.js/dist/css/shepherd.css'
// import '@styles/react/libs/shepherd-tour/shepherd-tour.scss'

import '../../../assets/scss/shepherd-tour/shepherd-tour.scss'
// ** Icons Imports
import { Check, Link2, Key } from 'react-feather'
const WoocommerceIcon = `${process.env.PUBLIC_URL}/images/integrations_images/WooCommerce.svg`;



function WooCommerceCard(props) {
    const dispatch = useDispatch();
    const { step, reachedFinalStep, websiteName, info } = useSelector((state) => state.woocommerce);
    const [modal_standard, setmodal_standard] = useState(false);
    const [validation, setValidation] = useState({
        unm: null,
        fnm: null,
        lnm: null,
    })
    //guide tour 
const backBtnClass = 'btn btn-sm btn-outline-primary',
nextBtnClass = 'btn btn-sm btn-primary btn-next'

let instance = null


// // this tour guide 
// const Content = () => {
// const tour = useContext(ShepherdTourContext)

// instance = tour


// return (
//     <Col className='text-center mt-2 pt-50'>
//         <Button color='primary' className='me-1' onClick={() => tour.start()} outline>
//             Guide Tour
//         </Button>
//     </Col>
// )
// }
    // const data = [
    //     {
    //         icon: <Link2 className='icon' />,
    //         title: props.t('Add Your website Link 🤟🏻'),
    //         subtitle: props.t('Dont forget to add http or https protocol')
    //     },
    //     {
    //         icon: <Key className='icon' />,
    //         title: props.t('Add Your  api key 👩🏻‍💻'),
    //         subtitle: props.t('You can generate keys from your Prestashop backend')
    //     },
    //     {
    //         icon: <Check className='icon' />,
    //         title: props.t('Your Account is ready for use 🎉'),
    //         subtitle: props.t('You  will get 7 days free trial')
    //     }
    // ]
    // const steps = [
    //     {
    //         id: 'url',
    //         title: 'url',
    //         text: props.t('copy paste your website link'),
    //         attachTo: { element: '.form-control-UrlG', on: 'bottom' },
    //         cancelIcon: {
    //             enabled: true
    //         },
    //         buttons: [
    //             {
    //                 action: () => instance.cancel(),
    //                 classes: backBtnClass,
    //                 text: props.t('Skip')
    //             },
    //             {
    //                 text: props.t('Next'),
    //                 classes: nextBtnClass,
    //                 action: () => instance.next()
    //             }
    //         ]
    //     },
    //     {
    //         id: 'Consumer key',
    //         title: props.t('Consumer key'),
    //         text: props.t('Insert your consumer key'),
    //         attachTo: { element: '.form-control-ConKeyG', on: 'top' },
    //         cancelIcon: {
    //             enabled: true
    //         },
    //         buttons: [
    //             {
    //                 text: props.t('Skip'),
    //                 classes: backBtnClass,
    //                 action: () => instance.cancel()
    //             },
    //             {
    //                 text: props.t('Back'),
    //                 classes: backBtnClass,
    //                 action: () => instance.back()
    //             },
    //             {
    //                 text: props.t('Next'),
    //                 classes: nextBtnClass,
    //                 action: () => instance.next()
    //             }
    //         ]
    //     },
    //     {
    //         id: 'Secret key',
    //         title: props.t('Secret key'),
    //         text: props.t('Insert your secret key'),
    //         attachTo: { element: '.form-control-SecKeyG', on: 'top' },
    //         cancelIcon: {
    //             enabled: true
    //         },
    //         buttons: [
    //             {
    //                 text: props.t('Skip'),
    //                 classes: backBtnClass,
    //                 action: () => instance.cancel()
    //             },
    //             {
    //                 text: props.t('Back'),
    //                 classes: backBtnClass,
    //                 action: () => instance.back()
    //             },
    //             {
    //                 text: props.t('Next'),
    //                 classes: nextBtnClass,
    //                 action: () => instance.next()
    //             }
    //         ]
    //     },
    
    //     {
    //         id: 'connect',
    //         title: props.t('connect'),
    //         text: props.t('connect your Woocommerce store'),
    //         attachTo: { element: '.modal-footer-GC', on: 'top' },
    //         cancelIcon: {
    //             enabled: true
    //         },
    //         buttons: [
    //             {
    //                 text: props.t('Back'),
    //                 classes: backBtnClass,
    //                 action: () => instance.back()
    //             },
    //             {
    //                 text: props.t('Finish'),
    //                 classes: nextBtnClass,
    //                 action: () => instance.cancel()
    //             }
    //         ]
    //     }
    // ]
    
    const [deleteLoading, setDeleteLoading] = useState(false)
    const [error, setError] = useState("");

    const [key, setKey] = useState("")
    const [secret, setSecret] = useState("")
    const [url, setUrl] = useState("")
    const [stores, setStores] = useState([])
    useEffect(() => {
        dispatch(checkThirdStepWoocommerceRequest())
        dispatch(getSalesPlateformeRequest())
        if (info.connectionstate === false) {
            Swal.fire({
                title: 'oops',
                text: props.t("please check your check your woocommerce credentials or contact   our support team "),
                icon: 'warning',
                showCancelButton: false,
            }).then(function (e) {

            })
        }
    }, [info.connectionstate])

    const newdomain = info && info.url ? info.url.match(/^https?:\/\/([^/?#]+)(?:[/?#]|$)/i)[1] : '';
    let integrate = async () => {
        if (key === "" || secret === "" || url === "") {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Please fill all the fields!',
            } )
        }else{
            dispatch(integrationswoRequest(key, secret, url,));
        }
       

    }
    let deleteConnection = async () => {
        deleteAlert(info.url).then(function (e) {
            if (e.isConfirmed) {
                dispatch(deleteWoocommerceRequest());

            }
        })
    }
    function tog_standard() {
        setmodal_standard(!modal_standard)
        removeBodyCss()
    }
    function removeBodyCss() {
        document.body.classList.add("no_padding")
    }


    const firstStep = () => {
        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>
                        <Row>
                            <Col>
                                <Card className="border text-secondary">
                                    <CardBody>
                                        <div className="my-0 text-primary d-flex justify-content-between align-items-center">
                                            <div className='text-left d-flex align-items-center'>
                                                <img className="img-intgration-icon" src={WoocommerceIcon} />
                                                <span className="text-primary h4 mb-0 ml-2">WooCommerce</span>
                                            </div>
                                            <div>
                                                <button
                                                    type="button"
                                                    onClick={() => {
                                                        tog_standard();
                                                    }}
                                                    className="btn btn-primary w-100"
                                                    data-toggle="modal"
                                                    data-target="#myModal"
                                                >
                                                    {props.t("Connect")}
                                                </button>
                                            </div>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>

                <Modal isOpen={modal_standard} toggle={() => tog_standard()} className='modal-dialog-centered modal-refer-earn modal-lg'>
                    <ModalBody className='pb-5 px-sm-0'>
                        <div className='text-center'>
                            <img style={{ height: '80px' }} src={WooLogo} />
                            <p className='text-center mb-5'>
                                {props.t('Connect your WooCommerce Store within 30 seconds')}
                            </p>

                            <button
                                type="button"
                                onClick={() => {
                                    setmodal_standard(false);
                                }}
                                className="close"
                                data-dismiss="modal"
                                aria-label="Close"
                            >
                                <span aria-hidden="true">&times;</span>
                            </button>

                            <Row className='mb-4'>
                                {data.map((item, index) => {
                                    return (
                                        <Col xs={12} lg={4} key={index}>
                                            <div className='d-flex justify-content-center mb-1'>
                                                <div className='circle'>
                                                    <div className='icon'>
                                                        {item.icon}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='text-center'>
                                                <h6 className='fw-bolder mb-1'>{item.title}</h6>
                                                <p>{item.subtitle}</p>
                                            </div>
                                        </Col>
                                    );
                                })}
                            </Row>

                        </div>
                        <Row className='px-3'>
                            <Col md="12">
                                <div className="mb-3 position-relative">
                                    <Label htmlFor="validationTooltipUsername">
                                        webiste Url
                                    </Label>
                                    <div className="input-group">
                                        <div className="input-group-prepend">
                                            <span
                                                className="input-group-text"
                                                id="validationTooltipUsernamePrepend"
                                            >
                                                https
                                            </span>
                                        </div>
                                        <Input
                                            type="text"
                                            className="form-control-UrlG"
                                            id="validationTooltipUsername"
                                            placeholder="Website url"
                                            onChange={e => setUrl(e.target.value)}
                                            valid={validation["unm"] === true}
                                            invalid={
                                                validation["unm"] !== true &&
                                                validation["unm"] !== null
                                            }
                                        />
                                        <div
                                            className={
                                                validation["unm"] === true
                                                    ? "valid-tooltip"
                                                    : "invalid-tooltip"
                                            }
                                            name="validate"
                                            id="validate3"
                                        >
                                            {validation["unm"] === true
                                                ? props.t("Looks good!")
                                                : props.t("Please choose a unique and valid website")}
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            <Col md="12">
                                <div className="mb-3 position-relative">
                                    <Label htmlFor="validationTooltip01">
                                        {props.t("Consumer key")}
                                    </Label>
                                    <Input
                                        type="text"
                                        className="form-control-ConKeyG"
                                        id="validationTooltip01"
                                        placeholder={props.t("Consumer key")}
                                        onChange={e => setKey(e.target.value)}
                                        valid={validation["fnm"] === true}
                                        invalid={
                                            validation["fnm"] !== true &&
                                            validation["fnm"] !== null
                                        }
                                    />
                                    <div
                                        className={
                                            validation["fnm"] === true
                                                ? "valid-tooltip"
                                                : "invalid-tooltip"
                                        }
                                        name="validate"
                                        id="validate1"
                                    >
                                        {validation["fnm"] === true
                                            ? props.t("Looks good!")
                                            : props.t("Please Enter Valid consumer key")}
                                    </div>
                                </div>
                            </Col>
                            <Col md="12">
                                <div className="mb-3 position-relative">
                                    <Label htmlFor="validationTooltip01">
                                        {props.t("Secret key")}
                                    </Label>
                                    <Input
                                        type="text"
                                        className="form-control-SecKeyG"
                                        id="validationTooltip01"
                                        placeholder={props.t("Secret key")}
                                        onChange={e => setSecret(e.target.value)}
                                        valid={validation["lnm"] === true}
                                        invalid={
                                            validation["lnm"] !== true &&
                                            validation["lnm"] !== null
                                        }
                                    />
                                    <div
                                        className={
                                            validation["lnm"] === true
                                                ? "valid-tooltip"
                                                : "invalid-tooltip"
                                        }
                                        name="validate"
                                        id="validate1"
                                    >
                                        {validation["lnm"] === true
                                            ? props.t("Looks good!")
                                            : props.t("Please Enter Valid Secret Key")}
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <Col xs={12} className='text-center mt-2 pt-50'>
                            <div className="modal-footer-GC">
                                <button
                                    type="button"
                                    onClick={() => {
                                        tog_standard();
                                    }}
                                    className="btn btn-secondary "
                                    data-dismiss="modal"
                                >
                                    {props.t("Close")}
                                </button>
                                <button
                                    type="button"
                                    className="btn btn-primary"
                                    onClick={() => { integrate() }}
                                >
                                    {props.t("Confirm")}
                                </button>
                            </div>
                            <ShepherdTour
                                steps={steps}
                                tourOptions={{
                                    useModalOverlay: true
                                }}
                            >
                                <Content />
                            </ShepherdTour>
                        </Col>
                    </ModalBody>
                </Modal>
            </React.Fragment>
        );
    };
    // Shepherd Tour content component
    const Content = () => {
        const tour = useContext(ShepherdTourContext);
    
        instance = tour;
    
        return (
            <Col className='text-center mt-2 pt-50'>
                <Button color='primary' className='me-1' onClick={() => tour.start()} outline>
                    Guide Tour
                </Button>
            </Col>
        );
    };
    const data = [
        {
            icon: <Link2 className='icon' />,
            title: props.t('Add Your website Link 🤟🏻'),
            subtitle: props.t('Dont forget to add http or https protocol')
        },
        {
            icon: <Key className='icon' />,
            title: props.t('Add Your  api key 👩🏻‍💻'),
            subtitle: props.t('You can generate keys from your Prestashop backend')
        },
        {
            icon: <Check className='icon' />,
            title: props.t('Your Account is ready for use 🎉'),
            subtitle: props.t('You  will get 7 days free trial')
        }
    ];

    const steps = [
        {
            id: 'url',
            title: 'url',
            text: props.t('copy paste your website link'),
            attachTo: { element: '.form-control-UrlG', on: 'bottom' },
            cancelIcon: {
                enabled: true
            },
            buttons: [
                {
                    action: () => instance.cancel(),
                    classes: backBtnClass,
                    text: props.t('Skip')
                },
                {
                    text: props.t('Next'),
                    classes: nextBtnClass,
                    action: () => instance.next()
                }
            ]
        },
        {
            id: 'Consumer key',
            title: props.t('Consumer key'),
            text: props.t('Insert your consumer key'),
            attachTo: { element: '.form-control-ConKeyG', on: 'top' },
            cancelIcon: {
                enabled: true
            },
            buttons: [
                {
                    text: props.t('Skip'),
                    classes: backBtnClass,
                    action: () => instance.cancel()
                },
                {
                    text: props.t('Back'),
                    classes: backBtnClass,
                    action: () => instance.back()
                },
                {
                    text: props.t('Next'),
                    classes: nextBtnClass,
                    action: () => instance.next()
                }
            ]
        },
        {
            id: 'Secret key',
            title: props.t('Secret key'),
            text: props.t('Insert your secret key'),
            attachTo: { element: '.form-control-SecKeyG', on: 'top' },
            cancelIcon: {
                enabled: true
            },
            buttons: [
                {
                    text: props.t('Skip'),
                    classes: backBtnClass,
                    action: () => instance.cancel()
                },
                {
                    text: props.t('Back'),
                    classes: backBtnClass,
                    action: () => instance.back()
                },
                {
                    text: props.t('Next'),
                    classes: nextBtnClass,
                    action: () => instance.next()
                }
            ]
        },
        {
            id: 'connect',
            title: props.t('connect'),
            text: props.t('connect your Woocommerce store'),
            attachTo: { element: '.modal-footer-GC', on: 'top' },
            cancelIcon: {
                enabled: true
            },
            buttons: [
                {
                    text: props.t('Back'),
                    classes: backBtnClass,
                    action: () => instance.back()
                },
                {
                    text: props.t('Finish'),
                    classes: nextBtnClass,
                    action: () => instance.cancel()
                }
            ]
        }
    ];

   
    const secondStep = () => {
        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>
                        <Row>
                            <Col>
                                <Card className="border text-secondary">
                                    <div className="card-header bg-transparent border-secondary">
                                        <div className="my-0 text-primary d-flex justify-content-between align-items-center"><h5 className="my-0 text-primary">WooCommerce </h5><img className="img-intgration-icon" src={WoocommerceIcon} /></div>
                                    </div>
                                    <CardBody>
                                        <FormGroup className="mb-3">
                                            <div style={{ textAlign: "center", padding: "20px" }}>
                                                {(info && info.length > 0) || true ? (
                                                    <div>

                                                        <div className="mb-3 position-relative">

                                                        <Table
                                                            id="tech-companies-1"
                                                            className="table table-striped table-bordered"
                                                        >
                                                            <Thead>
                                                                <Tr>
                                                                    <Th data-priority="3">{props.t("Domaine Name")}</Th>
                                                                    <Th data-priority="1">{props.t("status")}</Th>
                                                                </Tr>
                                                            </Thead>
                                                            <Tbody>
                                                                {/* {firststore.map((element, index) => (
                                                                    <PrestaStore element={element} key={index} />
                                                                ))} */}
                                                                
                                                                        <Tr>
                                                                            <Td> {newdomain}</Td>
                                                                            <Td>
                        {info.connectionstate ? (
                            <Badge className="me-2 bg-success">Online</Badge>
                        ) : (
                            <Badge className="me-2 bg-danger">Offline</Badge>
                        )}
                    </Td>
                                                                        </Tr>
                                                                   
                                                                
                                                            </Tbody>
                                                        </Table>
                                                        </div>
                                                    </div>
                                                ) : (
                                                    <div style={{ display: "grid" }}>
                                                        <div>
                                                            <h3>{props.t("There is no Store integrated")}</h3>
                                                            <small>{props.t("Tryagain")}</small>
                                                        </div>
                                                    </div>
                                                )}
                                                {deleteLoading ? (
                                                    <img style={{ float: 'left', margin: 10 }} name="notched circle loading icon" />
                                                ) : (
                                                    <div className='text-center'>
                                                        <button type="button" className="btn btn-danger waves-effect waves-light" onClick={() => deleteConnection()}>
                                                            <i className="bx bx-block font-size-16 align-middle"></i> {props.t("Delete Connection")}
                                                        </button>
                                                    </div>
                                                )}
                                            </div>
                                        </FormGroup>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment >
        );
    };
    const thirdStep = () => {
        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>
                        <Row>
                            <Col>
                                <Card className="border text-secondary">
                                    <div className="card-header bg-transparent border-secondary">
                                        <div className="my-0 text-primary d-flex justify-content-between align-items-center"><h5 className="my-0 text-primary">Woocommerce </h5><img className="img-intgration-icon" src={WoocommerceIcon} /></div>
                                    </div>
                                    <CardBody>
                                        <FormGroup>
                                            <div style={{ display: 'flex', flexDirection: 'center', alignItems: 'center', justifyContent: 'center' }} >
                                                <div className="mt-4 mt-md-0 text-center">
                                                    <Alert color="warnning">

                                                        <p style={{ fontWeight: 'bold' }} >{props.t("You have already a store integrated")}</p>
                                                    </Alert>
                                                </div>
                                            </div>
                                        </FormGroup>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        )
    }
    const loadingSection = () => {
        return (
            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    marginTop: "20px",
                }}
            >
                <Input style={{ height: 10, width: "100%" }} />
                <Input style={{ height: 10, width: 300 }} />
                <Input style={{ height: 10, width: "100%" }} />
            </div>
        );
    };
    const renderSections = () => {
        switch (step) {
            case 3:
                return thirdStep();
            case 0:
                return loadingSection();
            case 1:
                return firstStep();
            case 2:
                return secondStep();
            default:
                return loadingSection();
        }
    };
    return <div>{renderSections()}</div>;
}
WooCommerceCard.propTypes = {
    t: PropTypes.any
};

export default withRouter(withTranslation()(WooCommerceCard));
//export default WooCommerceCard;