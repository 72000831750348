import React from 'react'
import { Tr, Td } from "react-super-responsive-table"
function SingleTopSeller(props){ 
    return (
        <Tr className="">
            <Td className="">{props.element.TopSeller.product_id}</Td>
            <Td className="">{props.element.TopSeller.name}</Td>
            <Td className="">{props.element.TopSeller.quantity}</Td>
        </Tr>
    )
}
export default SingleTopSeller;