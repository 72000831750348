import React from "react"
import MetaTags from 'react-meta-tags';
import {
  Row,
  Col,
  Card,
  CardHeader,
  CardTitle,
  CardBody
} from "reactstrap"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import InterestAffinityCategory from "./InterestAffinityCategory";
import UserAgeBarChart from "./UserAgeBarChart";
import UserGenderBarChart from "./UserGenderBarChart";
import { withTranslation } from "react-i18next"
import { withRouter } from "react-router-dom"

function GAAudience(props) {
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Google Analytics | Live-Metrics - E-com SaaS</title>
        </MetaTags>
        <div className="container-fluid">
          <Breadcrumbs title="Google Analytics" breadcrumbItem="Audience" pageHeading="Audience" />
          <Row>
          <Col xl={6}>
              <Card>
                <CardHeader>
                  <CardTitle>{props.t("Audience Gender")}</CardTitle>
                </CardHeader>
                <CardBody>
                  <UserGenderBarChart />
                </CardBody>
              </Card>
            </Col>
          <Col xl={6}>
              <Card>
                <CardHeader>
                  <CardTitle>{props.t("Audience Age Bracket")}</CardTitle>
                </CardHeader>
                <CardBody>
                <UserAgeBarChart/>
                </CardBody>
              </Card>
            </Col>
            <Col xl={12}>
            <InterestAffinityCategory/>
            </Col>
          </Row>       
        </div>
      </div>
    </React.Fragment>
  )
}

export default withRouter(withTranslation() (GAAudience));
