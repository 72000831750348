import React, { useState, useEffect, useRef } from 'react';
import { Space, Button, Dropdown, Menu, Progress,Table, Alert, Input, Checkbox } from 'antd';
import {
  Row,
  Col,
  CardBody,
  Card,
  Container,
  FormGroup,
  InputGroup,
} from 'reactstrap';

import { Link } from 'react-router-dom';
import Highlighter from 'react-highlight-words';

import { withTranslation } from "react-i18next"
import { withRouter } from "react-router-dom"
import PropTypes from 'prop-types';
import { DownOutlined, SearchOutlined } from '@ant-design/icons';
import MetaTags from 'react-meta-tags';
import { useDispatch, useSelector } from 'react-redux';
import { getFacebookCampaigns ,getFacebookCampaignsPerDate,getAdsFacebook} from '../../store/facebookAds/actions';
import secureSection from '../../MangeSecurity/MemberSecurity';
import Breadcrumbs from '../../components/Common/Breadcrumb';
import SimpleBar from 'simplebar-react';
import { DateRange } from 'react-date-range';
import logo from '../../assets/images/Live_Metrics_Logo.png';

const FacebookAdsIcon = `${process.env.PUBLIC_URL}/images/integrations_images/AD8.png`;
const datePattern = /(\d{1,2})\/(\d{1,2})\/(\d{4})/;
function AdsFacebook(props) {
  secureSection();
  const dispatch = useDispatch();
  const { ads } = useSelector((state) => state.facebookAds);
  const daysBetween = function (date1, date2) {
    var one_day = 1000 * 60 * 60 * 24;
    var date1_ms = date1.getTime();
    var date2_ms = date2.getTime();
    var difference_ms = date2_ms - date1_ms;
    return Math.round(difference_ms / one_day);
  };
  function useOutsideAlerter(ref, toggleDate) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          toggleDate(false);
        }
      }
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [ref]);
  }
 
  //start date picker
  const wrapperRef = useRef(null);
  const [toggleDate, setToggleDate] = useState(false);
  useOutsideAlerter(wrapperRef, setToggleDate);
  const [currentFocus, setCurrentFocus] = useState([]);
  const [dateRage, setDateRange] = useState({
    date: [
      window.localStorage.getItem('start')
        ? new Date(window.localStorage.getItem('start'))
        : new Date(
          new Date().toLocaleDateString('en-US', { timeZone: props.timeZone })
        ),
      window.localStorage.getItem('end')
        ? new Date(window.localStorage.getItem('end'))
        : new Date(
          new Date().toLocaleDateString('en-US', { timeZone: props.timeZone })
        ),
    ],
  });
  const [lastPeriod, setLastPeriod] = useState('');
  const [chosedDate, setChosedDate] = useState([
    {
      startDate: window.localStorage.getItem('start')
        ? new Date(window.localStorage.getItem('start'))
        : new Date(
          new Date().toLocaleDateString('en-US', { timeZone: props.timeZone })
        ),
      endDate: window.localStorage.getItem('end')
        ? new Date(window.localStorage.getItem('end'))
        : new Date(
          new Date().toLocaleDateString('en-US', { timeZone: props.timeZone })
        ),
      key: 'selection',
    },
  ]);
  const [activeBtn, setActiveBtn] = useState(
    window.localStorage.getItem('activeBtn')
      ? JSON.parse(window.localStorage.getItem('activeBtn'))
      : {
        today: 1,
        yesterday: 0,
        last7days: 0,
        last30day: 0,
        last60day: 0,
        month: 0,
        other: 0,
      }
  );
  const [comparedTo, setComparedTo] = useState('');
  const mnths = [
    { num: 0, add: 2 },
    { num: 1, add: 0 },
    { num: 2, add: 2 },
    { num: 3, add: 1 },
    { num: 4, add: 2 },
    { num: 5, add: 1 },
    { num: 6, add: 2 },
    { num: 7, add: 2 },
    { num: 8, add: 1 },
    { num: 9, add: 2 },
    { num: 10, add: 1 },
    { num: 11, add: 2 },
  ];
  const getToAddOnMonths = (int) => {
    let res = 0;
    let month = 0;
    if (int === 0) {
      month = int;
    } else {
      month = int - 1;
    }
    mnths.map((x) => {
      if (x.num === month) res = x.add;
    });
    return res;
  };
  useEffect(() => {
    if (chosedDate[0] && chosedDate[0].startDate && chosedDate[0].endDate) {
      const startDate = chosedDate[0].startDate;
      const endDate = chosedDate[0].endDate;
      const newStartDate = new Date(startDate).setDate(
        new Date(startDate).getDate() - daysBetween(startDate, endDate) - 1
      );
      const newEndDate = new Date(endDate).setDate(
        new Date(endDate).getDate() - daysBetween(startDate, endDate) - 1
      );
      comparedTo !== 'year'
        ? setLastPeriod(
          `${'Comparedto'} : ${new Date(newStartDate).toLocaleString('en', {
            day: '2-digit',
            month: 'short',
          })}-${new Date(newEndDate).toLocaleString('en', {
            day: '2-digit',
            month: 'short',
          })} ${new Date(newEndDate).getFullYear()}`
        )
        : setLastPeriod(
          `${'Comparedto'}: ${new Date(startDate).toLocaleString('en', {
            day: '2-digit',
            month: 'short',
          })}-${new Date(endDate).toLocaleString('en', {
            day: '2-digit',
            month: 'short',
          })} ${new Date(endDate).getFullYear() - 1}`
        );
    }
  }, [chosedDate, comparedTo]);
  const buttonHandler = (startDate, endDate) => {
    setDateRange({ date: [startDate, endDate] });
    setChosedDate([
      {
        startDate: startDate,
        endDate: endDate,
        key: 'selection',
      },
    ]);
    setToggleDate(false);
    window.localStorage.setItem('start', startDate);
    window.localStorage.setItem('end', endDate);
  };
  const handleCompareToggle = (compare_to) => {
    setComparedTo(compare_to);
    if (comparedTo === compare_to) {
      setComparedTo('');
    }
  };
  useEffect(() => {
    if (currentFocus[1] === 0) {
      setDateRange({
        date: [
          new Date(
            new Date(
              chosedDate[0].startDate.toLocaleDateString('en-US', {
                timeZone: props.timeZone ? props.timeZone : 'UTC',
              })
            )
          ),
          new Date(
            new Date(
              chosedDate[0].endDate.toLocaleDateString('en-US', {
                timeZone: props.timeZone ? props.timeZone : 'UTC',
              })
            )
          ),
        ],
      });
      setToggleDate(false);
    }
  }, [currentFocus]);
  useEffect(() => {
    window.localStorage.setItem('start', chosedDate[0].startDate);
    window.localStorage.setItem('end', chosedDate[0].endDate);
  }, [chosedDate]);
  const [isPeriodChecked, setPeriodChecked] = useState(false);
  const [isYearChecked, setYearChecked] = useState(false);
 

  useEffect(() => {
    var newStartDate = datePattern.exec(
      chosedDate[0].startDate &&
      chosedDate[0].startDate.toLocaleString('fr-FR')
    );
    var newEndDate = datePattern.exec(
      chosedDate[0].endDate &&
    chosedDate[0].endDate.toLocaleString('fr-FR')
    );
    if (newStartDate && newEndDate) {
      dispatch(getAdsFacebook(   newStartDate[3] + '-' + newStartDate[2] + '-' + newStartDate[1],
      newEndDate[3] + '-' + newEndDate[2] + '-' + newEndDate[1]))
    }
  }, [ dateRage,chosedDate]);

  const [filteredInfo, setFilteredInfo] = useState({});
  const [sortedInfo, setSortedInfo] = useState({});
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const [paginationOptions, setPaginationOptions] = useState({
    pageSize: 5, // Default page size
    pageSizeOptions: ['5', '10', '20'], // Available page size options
  });
  const searchInput = useRef(null);
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: 'block',
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1677ff' : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: '#ffc069',
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });
  const handleChange = (pagination, filters, sorter) => {
    setFilteredInfo(filters);
    setSortedInfo(sorter);
    if (pagination.pageSize !== paginationOptions.pageSize) {
      setPaginationOptions({
        ...paginationOptions,
        pageSize: pagination.pageSize,
      });
    }
  };
  const clearFilters = () => {
    setFilteredInfo({});
  };
  const clearAll = () => {
    setFilteredInfo({});
    setSortedInfo({});
    // make the columns  selected
    setSelectedColumns(columns.map((column) => column.key));
  };
  const setAgeSort = () => {
    setSortedInfo({
      order: 'descend',
      columnKey: 'impressions',
    });
  };
  // Utility function to format numbers with spaces
  const formatNumberWithSpaces = (value) => {
    if (value !== undefined && value !== null) {
      return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
    }
    return ''; // or any default value you want to return for undefined/null cases
  };
  const columns = [
    {
        title: 'Ad Image',
        dataIndex: 'image_url',
        key: 'image_url',
        align: 'center',
        width: 200,
        filteredValue: filteredInfo.image_url || null,
        // sortOrder: sortedInfo.columnKey === 'name' ? sortedInfo.order : null,
        fixed: 'left',
        render: (text, record) => {
          return (
            <div className="d-flex align-items-center">
              <img
                src={text}
                alt="Ad Image"
                style={{ width: '130', height: '150px' }}
              />
            </div>
          );
        },

    },
   
    {
        title: 'Ad Name',
        dataIndex: 'ad_name',
        key: 'ad_name',
        align: 'center',
        width: 200,
        fixed: 'left',
        filteredValue: filteredInfo.ad_name || null,
        onFilter: (value, record) => record.ad_name.includes(value),
        ...getColumnSearchProps('ad_name'),
        // sortOrder: sortedInfo.columnKey === 'name' ? sortedInfo.order : null,
       

        
    },
    {
      title: 'Campaign Name',
      dataIndex: 'campaign_name',
      key: 'campaign_name',
      align: 'center',
      width: 200,
      fixed: 'left',
      filteredValue: filteredInfo.campaign_name || null,
      onFilter: (value, record) => record.campaign_name.includes(value),
      ...getColumnSearchProps('campaign_name'),
      // sortOrder: sortedInfo.columnKey === 'name' ? sortedInfo.order : null,
     
    },
    {
      title: 'Spend',
      dataIndex: 'spend',
      align: 'center',
      key: 'spend',
      width: 100,
      sorter: (a, b) => a.spend - b.spend,
      sortOrder: sortedInfo.columnKey === 'spend' ? sortedInfo.order : null,
     
      render: (text) => <strong>{text.toFixed(2)}$</strong>,
    },
    {
      title: 'Revenue',
      dataIndex: 'action_values',
      key: 'action_values',
      sorter: (a, b) => a.action_values - b.action_values,
      sortOrder:
        sortedInfo.columnKey === 'action_values' ? sortedInfo.order : null,
        width: 100,
      align: 'center',
      render: (text) => <strong>{text.toFixed(2)}$</strong>,
    },
    {
      title: 'Conversions',
      dataIndex: 'actions',
      align: 'center',
      key: 'actions',
      sorter: (a, b) => a.actions - b.actions,
      sortOrder: sortedInfo.columnKey === 'actions' ? sortedInfo.order : null,
      width: 120,
      render: (text) => <strong>{text}</strong>,
    },
    {
      title: 'Impressions',
      dataIndex: 'impressions',
      key: 'impressions',
      sorter: (a, b) => a.impressions - b.impressions,
      sortOrder:
        sortedInfo.columnKey === 'impressions' ? sortedInfo.order : null,
        width: 120,
      align: 'center',
      render: (text) => <strong>{formatNumberWithSpaces(text)}</strong>,
    },
    {
      title: 'Clicks',
      dataIndex: 'clicks',
      key: 'Clicks',
      sorter: (a, b) => a.clicks - b.clicks,
      sortOrder: sortedInfo.columnKey === 'clicks' ? sortedInfo.order : null,
      width: 100,
      align: 'center',
      render: (text) => <strong>{formatNumberWithSpaces(text)}</strong>,
    },
    {
      title: ' Reach',
      dataIndex: 'reach',
      key: 'reach',
      align: 'center',
      sorter: (a, b) => a.reach - b.reach,
      sortOrder: sortedInfo.columnKey === 'reach' ? sortedInfo.order : null,
      width: 100,
      render: (text) => <strong>{formatNumberWithSpaces(text)}</strong>,
    },
    {
      title: 'CTR',
      dataIndex: 'ctr',
      align: 'center',
      key: 'ctr',
      sorter: (a, b) => a.clicks / a.impressions - b.clicks / b.impressions,
      sortOrder: sortedInfo.columnKey === 'ctr' ? sortedInfo.order : null,
      width: 100,
      render: (text, record) => {
        const floatValue = parseFloat(text);
        return <strong>{isNaN(floatValue) ? '-' : `$${floatValue.toFixed(2)}`}</strong>;
      },
     
    },
  
    {
      title: 'CPC',
      dataIndex: 'cpc',
      key: 'cpc',
      align: 'center',
      sorter: (a, b) => a.cpc - b.cpc,
      sortOrder: sortedInfo.columnKey === 'cpc' ? sortedInfo.order : null,
      width: 100,
      render: (text, record) => {
        const floatValue = parseFloat(text);
        return <strong>{isNaN(floatValue) ? '-' : `$${floatValue.toFixed(2)}`}</strong>;
      },
    },
    {
      title: 'landing PageView',
      dataIndex: 'landing_page_views',
      align: 'center',
      key: 'landing_page_views',
      sorter: (a, b) => a.landing_page_views - b.landing_page_views,
      sortOrder:sortedInfo.columnKey === 'landing_page_views' ? sortedInfo.order : null,
          width: 100,
      render: (text) => <strong>{text}</strong>,
    },
    {
        title:'post engagement',
        dataIndex:'post_engagement',
        align:'center',
        key:'post_engagement',
        sorter:(a,b)=>a.post_engagement-b.post_engagement,
        sortOrder:sortedInfo.columnKey==='post_engagement'?sortedInfo.order:null,
        width: 100,
        render:(text)=><strong>{text}</strong>

    },
    {
        title:'Post reaction',
        dataIndex:'post_reaction',
        align:'center',
        key:'post_reaction',
        sorter:(a,b)=>a.post_reaction-b.post_reaction,
        sortOrder:sortedInfo.columnKey==='post_reaction'?sortedInfo.order:null,
        width: 100,
        render:(text)=><strong>{text}</strong>
    },
    { 
        title:"Page engagement",
        dataIndex:'page_engagement',
        align:'center',
        key:'page_engagement',
        sorter:(a,b)=>a.page_engagement-b.page_engagement,
        sortOrder:sortedInfo.columnKey==='page_engagement'?sortedInfo.order:null,
        width: 100,
        render:(text)=><strong>{text}</strong>
    },
    {
      title: 'Engagement Rate',
      dataIndex: 'engagement_rate',
      align: 'center',
      key: 'engagement_rate',
      sorter: (a, b) => ((a.post_engagement / a.impressions) * 100) - ((b.post_engagement / b.impressions) * 100),
      sortOrder: sortedInfo.columnKey === 'engagement_rate' ? sortedInfo.order : null,
      width: 120,
      render: (_, record) => {
        const engagementRate = (record.post_engagement / record.impressions) * 100;
        return <strong>{engagementRate ? engagementRate.toFixed(2) : '-'}</strong>;
      },
    },
    {
      title: 'Roas',
      dataIndex: 'roas',
      align: 'center',
      key: 'roas',
      sorter: (a, b) => (a.action_values / a.spend) - (b.action_values / b.spend),
      sortOrder: sortedInfo.columnKey === 'roas' ? sortedInfo.order : null,
      width: 100,
      render: (_, record) => {
        const roas = record.action_values / record.spend;
        return <strong>{roas !== null ? roas.toFixed(2) : '-'}</strong>;
      },
    },
  ];
  const [selectedColumns, setSelectedColumns] = useState(
    columns.map((column) => column.key)
  );

  const handleMenuClick = (clickedKey) => {
    const index = selectedColumns.indexOf(clickedKey);

    let updatedSelectedColumns = [];

    if (index === -1) {
      // Column was not selected before, add it to the selected columns
      updatedSelectedColumns = [...selectedColumns, clickedKey];
    } else {
      // Column was selected before, remove it from the selected columns
      updatedSelectedColumns = selectedColumns.filter(
        (key) => key !== clickedKey
      );
    }

    setSelectedColumns(updatedSelectedColumns);
  };

  const filteredColumns = columns.filter((column) =>
    selectedColumns.includes(column.key)
  );

  const menu = (
    <Menu>
      {columns.map((column) => (
        <Menu.Item key={column.key} onClick={() => handleMenuClick(column.key)}>
          <Checkbox
            checked={selectedColumns.includes(column.key)}
            style={{ marginRight: '8px' }}
          />
          <span
            style={
              selectedColumns.includes(column.key) ? { fontWeight: 'bold' } : {}
            }
          >
            {column.title}
          </span>
        </Menu.Item>
      ))}
    </Menu>
  );

  return (
    <div className="page-content">
      <MetaTags>
        <title>{props.t(" Meta Campaigns Data")} | Convergen-Metrics - E-com SaaS</title>
      </MetaTags>
      <div className="container-fluid">
        <Breadcrumbs
          title={props.t("Meta Ads")}
          breadcrumbItem={props.t(" Meta Campaigns Data")}
          pageHeading={props.t("Meta Campaigns Data")}
        />
        <Card>
          {/* <div className="card-header align-items-center d-flex">
            <h4 className="card-title mb-0 flex-grow-1">
              {props.t("Campaigns")}
            </h4>
            <div className="flex-shrink-0">
              <img className="img-intgration-icon" src={FacebookAdsIcon} alt="Facebook Ads Icon" />
            </div>
          </div> */}
          <CardBody className="">
            <div>
              <div className="invoice-title">
                <div className="d-flex align-items-start">
                  <div className="flex-grow-1">
                    {/* <div className="col">
                      <h5 className="card-title d-flex align-items-center">
                        <img className="img-intgration-icon" src={FacebookAdsIcon} alt="Facebook Ads Icon" style={{ marginTop: '-10px', height: '40px' }} />
                        <h5 className="card-title ms-2" style={{ marginTop: '-5px' }}>Campaigns</h5>
                      </h5>
                    </div> */}
                  </div>
                  <div className="d-flex align-items-center justify-content-between">
                    <div className="flex-grow-1">
                      {/* <div className="">
                        <address>
                          <br />
                          <span>
                            {props.t(
                              'Industrial Zone Khéreddine Zone North West,'
                            )}
                          </span>
                          <br />
                          <span>Tunis, Tunisia</span>
                        </address>
                      </div>*/}
                    </div>
                    <div className="flex-shrink-0">
                      <FormGroup className="mt-1">
                        <InputGroup>
                          <div>
                            <div className="divDateContainer">
                              <div ref={wrapperRef}>
                                <button
                                  type="button"
                                  className="btn btn-light"
                                  onClick={() => setToggleDate(!toggleDate)}
                                  id="page-header-search-dropdown"
                                >
                                  {chosedDate[0] !== null
                                    ? [
                                      chosedDate[0].startDate.toLocaleString(
                                        'en',
                                        {
                                          month: 'short',
                                        }
                                      ),
                                      ' ',
                                      chosedDate[0].startDate.getDate(),
                                      ' ',
                                      chosedDate[0].startDate.getFullYear(),
                                      '  -  ',
                                      chosedDate[0].endDate &&
                                      chosedDate[0].endDate.toLocaleString(
                                        'en',
                                        {
                                          month: 'short',
                                        }
                                      ),
                                      ' ',
                                      chosedDate[0].endDate &&
                                      chosedDate[0].endDate.getDate(),
                                      ' ',
                                      chosedDate[0].endDate &&
                                      chosedDate[0].endDate.getFullYear(),
                                    ]
                                    : null}
                                </button>

                                {toggleDate && (
                                  <div 
                                  style={{
                                    position: 'fixed',
                                    display: 'flex',
                                    right: '35px',
                                    zIndex: 9999,
                                  }}
                                >
                                
                                    <div
                                      className="calenderDivStyle"
                                      style={{ right: 0 }}
                                    >
                                      <div className="btnsCalendar">
                                        <DateRange
                                          editableDateInputs={true}
                                          moveRangeOnFirstSelection={false}
                                          onRangeFocusChange={(item) => {
                                            setCurrentFocus(item);
                                          }}
                                          onChange={(item) => {
                                            setChosedDate([item.selection]);
                                            setActiveBtn({
                                              today: 0,
                                              yesterday: 0,
                                              last7days: 0,
                                              last30day: 0,
                                              last60day: 0,
                                              month: 0,
                                              other: 1,
                                            });
                                            window.localStorage.setItem(
                                              'activeBtn',
                                              JSON.stringify({
                                                today: 0,
                                                yesterday: 0,
                                                last7days: 0,
                                                last30day: 0,
                                                last60day: 0,
                                                month: 0,
                                                other: 1,
                                              })
                                            );
                                          }}
                                          maxDate={new Date(new Date().getTime() - 24 * 60 * 60 * 1000)}
                                          disabledDates={[
                                            {
                                              after: new Date(), // Disable dates after today
                                            },
                                          ]}
                                          ranges={chosedDate}
                                          dragSelectionEnabled={true}
                                          rangeColors={['#6689F2']}
                                          months={2}
                                          direction="horizontal"
                                        />
                                      </div>
                                      <div className="calendarDiv">
                                        <div className="btnCalendarContainer">
                                          <button
                                            className={
                                              activeBtn.today
                                                ? 'dateBtns activeBtnDate'
                                                : 'dateBtns'
                                            }
                                            onClick={() => {
                                              buttonHandler(
                                                new Date(
                                                  new Date().toLocaleDateString(
                                                    'en-US',
                                                    {
                                                      timeZone:
                                                        window.localStorage.getItem(
                                                          'timezone'
                                                        ),
                                                    }
                                                  )
                                                ),
                                                new Date(
                                                  new Date().toLocaleDateString(
                                                    'en-US',
                                                    {
                                                      timeZone:
                                                        window.localStorage.getItem(
                                                          'timezone'
                                                        ),
                                                    }
                                                  )
                                                )
                                              );
                                              setActiveBtn({
                                                today: 1,
                                                yesterday: 0,
                                                last7days: 0,
                                                last30day: 0,
                                                last60day: 0,
                                                month: 0,
                                                other: 0,
                                              });
                                              window.localStorage.setItem(
                                                'activeBtn',
                                                JSON.stringify({
                                                  today: 1,
                                                  yesterday: 0,
                                                  last7days: 0,
                                                  last30day: 0,
                                                  last60day: 0,
                                                  month: 0,
                                                  other: 0,
                                                })
                                              );
                                            }}
                                          >
                                            {props.t('compareto')}
                                          </button>
                                          <button
                                            className={
                                              activeBtn.yesterday
                                                ? 'dateBtns activeBtnDate'
                                                : 'dateBtns'
                                            }
                                            onClick={() => {
                                              const timeZone =
                                                window.localStorage.getItem(
                                                  'timezone'
                                                ) || 'UTC'; // set default time zone to UTC
                                              buttonHandler(
                                                new Date(
                                                  new Date(
                                                    new Date().getTime() -
                                                    24 * 60 * 60 * 1000
                                                  ).toLocaleDateString(
                                                    'en-US',
                                                    {
                                                      timeZone: timeZone,
                                                    }
                                                  )
                                                ),

                                                new Date(
                                                  new Date(
                                                    new Date().getTime() -
                                                    24 * 60 * 60 * 1000
                                                  ).toLocaleDateString(
                                                    'en-US',
                                                    {
                                                      timeZone: timeZone,
                                                    }
                                                  )
                                                )
                                              );
                                              setActiveBtn({
                                                today: 0,
                                                yesterday: 1,
                                                last7days: 0,
                                                last30day: 0,
                                                last60day: 0,
                                                month: 0,
                                                other: 0,
                                              });
                                              window.localStorage.setItem(
                                                'activeBtn',
                                                JSON.stringify({
                                                  today: 0,
                                                  yesterday: 1,
                                                  last7days: 0,
                                                  last30day: 0,
                                                  last60day: 0,
                                                  month: 0,
                                                  other: 0,
                                                })
                                              );
                                            }}
                                          >
                                            {props.t('yesterday')}
                                          </button>
                                          <button
                                            className={
                                              activeBtn.last7days
                                                ? 'dateBtns activeBtnDate'
                                                : 'dateBtns'
                                            }
                                            onClick={() => {
                                              const timeZone =
                                                window.localStorage.getItem(
                                                  'timezone'
                                                ) || 'UTC'; // set default time zone to UTC
                                              buttonHandler(
                                                new Date(
                                                  new Date(
                                                    new Date().getTime() -
                                                    6 * 24 * 60 * 60 * 1000
                                                  ).toLocaleDateString(
                                                    'en-US',
                                                    {
                                                      timeZone: timeZone,
                                                    }
                                                  )
                                                ),
                                                new Date(
                                                  new Date().toLocaleDateString(
                                                    'en-US',
                                                    { timeZone: timeZone }
                                                  )
                                                )
                                              );
                                              setActiveBtn({
                                                today: 0,
                                                yesterday: 0,
                                                last7days: 1,
                                                last30day: 0,
                                                last60day: 0,
                                                month: 0,
                                                other: 0,
                                              });
                                              window.localStorage.setItem(
                                                'activeBtn',
                                                JSON.stringify({
                                                  today: 0,
                                                  yesterday: 0,
                                                  last7days: 1,
                                                  last30day: 0,
                                                  last60day: 0,
                                                  month: 0,
                                                  other: 0,
                                                })
                                              );
                                            }}
                                          >
                                            {props.t('last7days')}
                                          </button>
                                          <button
                                            className={
                                              activeBtn.last30day
                                                ? 'dateBtns activeBtnDate'
                                                : 'dateBtns'
                                            }
                                            onClick={() => {
                                              const timeZone =
                                                window.localStorage.getItem(
                                                  'timezone'
                                                ) || 'UTC'; // set default time zone to UTC
                                              buttonHandler(
                                                new Date(
                                                  new Date(
                                                    new Date().getTime() -
                                                    29 * 24 * 60 * 60 * 1000
                                                  ).toLocaleDateString(
                                                    'en-US',
                                                    {
                                                      timeZone: timeZone,
                                                    }
                                                  )
                                                ),
                                                new Date(
                                                  new Date().toLocaleDateString(
                                                    'en-US',
                                                    { timeZone: timeZone }
                                                  )
                                                )
                                              );
                                              setActiveBtn({
                                                today: 0,
                                                yesterday: 0,
                                                last7days: 0,
                                                last30day: 1,
                                                last60day: 0,
                                                month: 0,
                                                other: 0,
                                              });
                                              window.localStorage.setItem(
                                                'activeBtn',
                                                JSON.stringify({
                                                  today: 0,
                                                  yesterday: 0,
                                                  last7days: 0,
                                                  last30day: 1,
                                                  last60day: 0,
                                                  month: 0,
                                                  other: 0,
                                                })
                                              );
                                            }}
                                          >
                                            {props.t('last30days')}
                                          </button>
                                          <button
                                            className={
                                              activeBtn.month
                                                ? 'dateBtns activeBtnDate'
                                                : 'dateBtns'
                                            }
                                            onClick={() => {
                                              const timeZone =
                                                window.localStorage.getItem(
                                                  'timezone'
                                                ) || 'UTC'; // set default time zone to UTC
                                              buttonHandler(
                                                new Date(
                                                  new Date(
                                                    new Date().getFullYear(),
                                                    new Date().getMonth(),

                                                    getToAddOnMonths(
                                                      new Date().getMonth()
                                                    )
                                                  ).toLocaleDateString(
                                                    'en-US',
                                                    {
                                                      timeZone: timeZone,
                                                    }
                                                  )
                                                ),

                                                new Date(
                                                  new Date().toLocaleDateString(
                                                    'en-US',
                                                    { timeZone: timeZone }
                                                  )
                                                )
                                              );
                                              setActiveBtn({
                                                today: 0,
                                                yesterday: 0,
                                                last7days: 0,
                                                last30day: 0,
                                                last60day: 0,
                                                month: 1,
                                                other: 0,
                                              });
                                              window.localStorage.setItem(
                                                'activeBtn',
                                                JSON.stringify({
                                                  today: 0,
                                                  yesterday: 0,
                                                  last7days: 0,
                                                  last30day: 0,
                                                  last60day: 0,
                                                  month: 1,
                                                  other: 0,
                                                })
                                              );
                                            }}
                                          >
                                            {props.t('thismonth')}
                                          </button>
                                          <span className="compareSpan">
                                            <b>{props.t('compareto')}</b>
                                          </span>
                                          <div className="form-check mb-3">
                                            <input
                                              style={{
                                                width: '1em',
                                                height: '1em',
                                                marginTop: '0.25em',
                                                verticalAlign: 'top',
                                                backgroundColor: '#fff',
                                                backgroundRepeat: 'no-repeat',
                                                backgroundPosition: 'center',
                                                border: '#000000',
                                              }}
                                              type="checkbox"
                                              id="formCheck1"
                                              className={
                                                comparedTo === 'period'
                                                  ? 'compareTogglebtnStyleActive'
                                                  : null
                                              }
                                              checked={comparedTo === 'period'}
                                              onClick={() => {
                                                handleCompareToggle('period');
                                                setPeriodChecked(
                                                  !isPeriodChecked
                                                );
                                              }}
                                            />
                                            <label
                                              className="form-check-label"
                                              htmlFor="formCheck1"
                                            >
                                              {props.t('previousperiod')}
                                            </label>
                                          </div>
                                          <div className="form-check mb-3">
                                            <input
                                              style={{
                                                width: '1em',
                                                height: '1em',
                                                marginTop: '0.25em',
                                                verticalAlign: 'top',
                                                backgroundColor: '#fff',
                                                backgroundRepeat: 'no-repeat',
                                                backgroundPosition: 'center',
                                                border: '#000000',
                                              }}
                                              type="checkbox"
                                              id="formCheck1"
                                              className={
                                                comparedTo === 'year'
                                                  ? 'compareTogglebtnStyleActive'
                                                  : null
                                              }
                                              checked={comparedTo === 'year'}
                                              onChange={() => {
                                                handleCompareToggle('year');
                                                setYearChecked(!isYearChecked);
                                              }}
                                            />
                                            <label
                                              className="form-check-label"
                                              htmlFor="formCheck1"
                                            >
                                              {props.t('Previousyear')}
                                            </label>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                )}
                                <br />
                                {comparedTo !== "" && (
                            <small style={{
                              display: 'flex',
                              marginTop: '10px',
                              backgroundColor: 'rgb(59 91 152 / 7%)',
                              borderRadius: '5px',
                              width: 'max-content',
                              height: '30px',
                              padding: "8px",
                              fontWeight: 'bold',
                            }}>
                              {lastPeriod}
                            </small>
                          )}
                              </div>
                            </div>
                          </div>
                        </InputGroup>
                      </FormGroup>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </CardBody>
          <Card style={{ border: 'none' }}>
            <CardBody>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
              >
                <h4 className="card-title mb-4">
                  {props.t('Ads creatives  ')}
                </h4>
               
              </div>
              <Space
                style={{
                  marginBottom: 16,
                }}
              >
                <Button onClick={setAgeSort}>Sort Impressions</Button>
                <Button onClick={clearFilters}>Clear filters</Button>
                <Button onClick={clearAll}>Clear filters and sorters</Button>
                <Dropdown overlay={menu} trigger={['click']}>
                  <Button>Select Columns</Button>
                </Dropdown>
              </Space>
              <Table
                columns={filteredColumns}
                bordered
                dataSource={ads}
                onChange={handleChange}
                pagination={{
                  ...paginationOptions,
                  onChange: handleChange,
                  showSizeChanger: true, // Enable the page size selector
                  // showTotal: (total) => `Total ${total} items`, // Optionally show total items
                  // Other pagination settings
                }}
                scroll={{ x: 900, y: 600}}
              />
            </CardBody>
          </Card>
        </Card>
      </div>
      <div className="mt-2-overview-dashboard">
        {/* <Link to="/fb-campaigns-details" className="btn btn-primary btn-sm">
          {props.t("View more")} <i className="overview-dashboard-icon mdi mdi-arrow-right ms-1"></i>
        </Link> */}
      </div>
    </div>
  );
}

AdsFacebook.propTypes = {
  t: PropTypes.any,
};

export default withRouter(withTranslation()(AdsFacebook));
