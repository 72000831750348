import PropTypes from 'prop-types';
import MetaTags from 'react-meta-tags';
import React, { useEffect, useState } from 'react';
import { Row, Col, Container } from 'reactstrap';
import { Link } from 'react-router-dom';
import { Form } from 'react-final-form';
import { useForm } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
import logo from '../../assets/images/Live_Metrics_Logo.png';
import CarouselPage from '../AuthenticationInner/CarouselPage';
import { loginRequest, isConnectedRequest, loginWithGoogleRequest } from '../../store/auth/actions';
import { GoogleLogin, useGoogleLogin } from '@react-oauth/google';
import Gooogleicon from "../../assets/images/google.png"

const Login = () => {
  const dispatch = useDispatch();
  const { isConnected } = useSelector((state) => state.editProfile);
  const { register, handleSubmit } = useForm();

  const onSuccess = (response) => {
    if (response && response.access_token) {
      const tokenString = response.access_token;
      console.log('Google login response:', tokenString);
      dispatch(loginWithGoogleRequest({ token: tokenString }));
    } else {
      console.error(
        'Google login response is missing necessary data:',
        response
      );
    }
  };
  const onError = (error) => {
    if (error.error === 'popup_closed_by_user') {
      console.log('User closed the Google login popup.');
    } else {
      console.error('Google login failed:', error);
    }
  };
  const loginWithGoogle = useGoogleLogin({
    onSuccess,
    onError,
  });

  const onSubmit = (formData) => {
    if (formData) {
      dispatch(loginRequest(formData.email, formData.password));
    }
  };

  useEffect(() => {
    if (isConnected) window.location.replace('/integrations');
  }, [isConnected]);

  useEffect(() => {
    dispatch(isConnectedRequest());
  }, []);
  return (
    <React.Fragment>
      <MetaTags>
        <title>Login | LiveMetrics - E-com SaaS</title>
      </MetaTags>
      <div className="auth-page">
        <Container fluid className="p-0">
          <Row className="g-0">
            <Col lg={4} md={5} className="col-xxl-3">
              <div className="auth-full-page-content d-flex p-sm-5 p-4">
                <div className="w-100">
                  <div className="d-flex flex-column h-100">
                    <div className="mb-4 mb-md-5 text-center">
                      <a
                        href="https://live-metrics.io/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img src={logo} alt="" height="50" />
                      </a>
                    </div>
                    <div className="auth-content my-auto">
                      <div className="text-center">
                        <h5 className="mb-0">Welcome Back !</h5>
                        <p className="text-muted mt-2">
                          Sign in to continue to LiveMetrics.
                        </p>
                      </div>
                      <Form onSubmit={handleSubmit(onSubmit)}>
                        {({ handleSubmit }) => (
                          <form onSubmit={handleSubmit}>
                             <label className="form-label">Email</label>
                            <div className="mb-3">
                              <input
                                type="text"
                                name="email"
                                required="true"
                                id="email"
                                className="form-control"
                                placeholder="Enter email"
                                {...register('email', {
                                  required: true,
                                })}
                              />
                            </div>
                            <div className="mb-3">
                              <div className="d-flex align-items-start">
                                <div className="flex-grow-1">
                                  <label className="form-label">Password</label>
                                </div>
                                <div className="flex-shrink-0">
                                  <div className="">
                                    <Link
                                      to="/forgot-password"
                                      className="text-muted"
                                    >
                                      Forgot password?
                                    </Link>
                                  </div>
                                </div>
                              </div>
                              <div className="mb-3">
                                <input
                                  name="password"
                                  type="password"
                                  id="password"
                                  className="form-control"
                                  {...register('password', { required: true })}
                                  required
                                  placeholder="Enter Password"
                                />
                              </div>
                            </div>
                            <div className="row mb-4">
                              <div className="col">
                                <div className="form-check">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    id="remember-check"
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="remember-check"
                                  >
                                    Remember me
                                  </label>
                                </div>
                              </div>
                            </div>
                            <div className="mb-3 ">
                              <button
                                className="btn btn-primary w-100 waves-effect waves-light mb-2"
                                type="submit"
                              >
                                Log In
                              </button>
                            
                            
                           
                             
                              
                            </div>
                          </form>
                        )}
                      </Form>
                      
                      <button
      onClick={loginWithGoogle}
      className="btn btn-light w-100 waves-effect mb-2 d-flex align-items-center justify-content-center"
      type="submit"
      style={{ padding: '10px' }}
    >
      <img src={Gooogleicon} alt="" height="20" style={{ marginRight: '15px' }} />
      <span>Sign in with Google</span>
    </button>
                      <div className="mt-5 text-center">
                        <p className="text-muted mb-0">
                          Don't have an account ?{' '}
                          <Link
                            to="/register"
                            className="text-primary fw-semibold"
                          >
                            {' '}
                            Signup now{' '}
                          </Link>{' '}
                        </p>
                      </div>
                    </div>
                    <div className="mt-4 mt-md-5 text-center">
                      <p className="mb-0">
                        © {new Date().getFullYear()} LiveMetrics. Crafted by
                        Convergen
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
            <CarouselPage />
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};
export default Login;
Login.propTypes = {
  history: PropTypes.object,
};
