import React, { useState, useEffect, useRef } from 'react';
import MetaTags from 'react-meta-tags';
import "../../assets/scss/custom/pages/_daterange.scss";
import WooDataCard from './WooDataCard';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import {
  Col,
  Container,
  Row,
  FormGroup,
  InputGroup,
  Card,
  CardHeader,
  CardTitle,
  CardBody
} from "reactstrap";
import "flatpickr/dist/themes/material_blue.css";
import urls from '../../routes/apiUrls';
import { getData } from '../../queries/Dashboard/WooDashboardQ';
import { getSalesPlatform } from '../../queries/Shopify/queries';
import { alert } from '../Alerts/NotLinkedAccountAlert';
import ApolloClient from 'apollo-boost';
import { DateRange } from "react-date-range";
import { useHistory } from "react-router-dom";
import secureSection from '../../MangeSecurity/MemberSecurity';
import ReactEcharts from "echarts-for-react"
import { withRouter } from "react-router-dom"
import { withTranslation } from "react-i18next"
import PropTypes from "prop-types";
import "../../assets/scss/theme.scss"
const daysBetween = function (date1, date2) {
  var one_day = 1000 * 60 * 60 * 24;
  var date1_ms = date1.getTime();
  var date2_ms = date2.getTime();
  var difference_ms = date2_ms - date1_ms;
  return Math.round(difference_ms / one_day);
}
function useOutsideAlerter(ref, toggleDate) {
  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        toggleDate(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);
}
const ApploCLI = new ApolloClient({
  uri: urls.woodashboard,
});
const ApploShopifyCLI = new ApolloClient({
  uri: urls.shopify,
});
const datePattern = /(\d{1,2})\/(\d{1,2})\/(\d{4})/;
function WooDashboard(props) {
  secureSection();
  const wrapperRef = useRef(null);
  const [toggleDate, setToggleDate] = useState(false);
  useOutsideAlerter(wrapperRef, setToggleDate);
  const history = useHistory();
  const [dateRage, setDateRange] = useState({
    date:
      [
        window.localStorage.getItem("start") ? new Date(window.localStorage.getItem("start")) : new Date(new Date().toLocaleDateString('en-US', { timeZone: props.timeZone })),
        window.localStorage.getItem("end") ? new Date(window.localStorage.getItem("end")) : new Date(new Date().toLocaleDateString('en-US', { timeZone: props.timeZone }))

      ]
  });
  const [lastPeriod, setLastPeriod] = useState("");
  const [chosedDate, setChosedDate] = useState([{
    startDate: window.localStorage.getItem("start") ? new Date(window.localStorage.getItem("start")) : new Date(new Date().toLocaleDateString('en-US', { timeZone: props.timeZone })),
    endDate: window.localStorage.getItem("end") ? new Date(window.localStorage.getItem("end")) : new Date(new Date().toLocaleDateString('en-US', { timeZone: props.timeZone })),
    key: 'selection'
  }]);
  const [convertedDate, setConvertedDate] = useState([]);
  const [currentFocus, setCurrentFocus] = useState([]);
  const [dataDashboard, setDataDashboard] = useState([]);
  const [sales, setSales] = useState([]);
  const [grossProfit, setGrossProfit] = useState([]);
  const [cps, setCps] = useState([]);
  const [aov, setAov] = useState([]);
  const [nmbrOfOrders, setNmbrOfOrders] = useState([]);
  const [lastSales, setLastSales] = useState([]);
  const [lastGrossProfit, setLastGrossProfit] = useState([]);
  const [lastCps, setLastCps] = useState([]);
  const [lastAov, setLastAov] = useState([]);
  const [lastNmbrOfOrders, setLastNmbrOfOrders] = useState([]);
  const [lastMainSales, setLastMainSales] = useState([]);
  const [lastMainGrossProfit, setLastMainGrossProfit] = useState([]);
  const [mainSales, setMainSales] = useState([]);
  const [mainGrossProfit, setMainGrossProfit] = useState([]);
  const [dates, setDates] = useState([]);
  const [lastDates, setLastDates] = useState([]);
  const [loadingState, setLoadingState] = useState(false);
  const [activeBtn, setActiveBtn] = useState(window.localStorage.getItem("activeBtn") ? JSON.parse(window.localStorage.getItem("activeBtn")) : {
    today: 1,
    yesterday: 0,
    last7days: 0,
    last30day: 0,
    last60day: 0,
    month: 0,
    other: 0,
  });
  const [comparedTo, setComparedTo] = useState("period");
  const mnths = [{ num: 0, add: 2 },
  { num: 1, add: 0 },
  { num: 2, add: 2 },
  { num: 3, add: 1 },
  { num: 4, add: 2 },
  { num: 5, add: 1 },
  { num: 6, add: 2 },
  { num: 7, add: 2 },
  { num: 8, add: 1 },
  { num: 9, add: 2 },
  { num: 10, add: 1 },
  { num: 11, add: 2 }
  ]
  const getToAddOnMonths = (int) => {
    let res = 0;
    let month = 0;
    if (int === 0) {
      month = int
    }
    else {
      month = int - 1
    }
    mnths.map(x => {
      if (x.num === month)
        res = x.add;
    })
    return res
  }
  useEffect(() => {
    if (chosedDate[0] && chosedDate[0].startDate && chosedDate[0].endDate) {
      const startDate = chosedDate[0].startDate;
      const endDate = chosedDate[0].endDate;
      const newStartDate = new Date(startDate).setDate(new Date(startDate).getDate() - daysBetween(startDate, endDate) - 1);
      const newEndDate = new Date(endDate).setDate(new Date(endDate).getDate() - daysBetween(startDate, endDate) - 1);
      (comparedTo !== 'year' ?
        setLastPeriod(`${"Comparedto"} : ${new Date(newStartDate).toLocaleString("en", { day: "2-digit", month: "short" })}-${new Date(newEndDate).toLocaleString("en", { day: "2-digit", month: "short" })} ${new Date(newEndDate).getFullYear()}`)
        :
        setLastPeriod(`${"Comparedto"}: ${new Date(startDate).toLocaleString("en", { day: "2-digit", month: "short" })}-${new Date(endDate).toLocaleString("en", { day: "2-digit", month: "short" })} ${new Date(endDate).getFullYear() - 1}`)
      )
    }
  }, [chosedDate, comparedTo])

  const buttonHandler = (startDate, endDate) => {
    const timeZone = window.localStorage.getItem('timezone') || 'UTC'; // set default time zone to UTC
    setDateRange({ date: [startDate, endDate] });
    setChosedDate([{
      startDate: startDate,
      endDate: endDate,
      key: 'selection'
    }]);
    setToggleDate(false)
    window.localStorage.setItem("start", startDate.toLocaleDateString('en-US', { timeZone: timeZone })); // convert start date to local time
    window.localStorage.setItem("end", endDate.toLocaleDateString('en-US', { timeZone: timeZone })); // convert end date to local time
  };

  useEffect(() => {
    let isSubscribed = true;
    setLoadingState(false);
    var newStartDate = datePattern.exec(chosedDate[0].startDate && chosedDate[0].startDate
      .toLocaleString("fr-FR"));
    var newEndDate = datePattern.exec(chosedDate[0].endDate && chosedDate[0].endDate
      .toLocaleString("fr-FR"));
    if (newStartDate && newEndDate) {
      ApploCLI.query({
        query: getData,
        variables: {
          startDate: newStartDate[3] + "-" + newStartDate[2] + "-" + newStartDate[1],
          endDate: newEndDate[3] + "-" + newEndDate[2] + "-" + newEndDate[1],
          compare_to: comparedTo
        },
        context: {
          headers: {
            Authorization: window.localStorage.getItem("token")
              ? `Bearer ${window.localStorage.getItem("token")}`
              : "",
          },
        },
        errorPolicy: "all",
        fetchPolicy: "no-cache",
      }).then((data) => {
        if (isSubscribed) {
          if (data.data.get) {
            data.data.get.reports.sort((a, b) => {
              let dateA = new Date(a.date);
              let dateB = new Date(b.date);
              return dateA - dateB;
            });
            setDataDashboard(data.data.get);
            setConvertedDate(data.data.get.reports.map((item) => {
              const date = new Date(item.date);
              const formattedDate = date.toLocaleDateString('fr-FR', {
                day: '2-digit',
                month: 'short',
              });
              // console.log("formattedDate",formattedDate)
              return formattedDate;
            }));
            setLoadingState(true);
          }
          else
            setLoadingState(true)
        }
      });
    }
    return () => (isSubscribed = false);
  }, [dateRage, comparedTo, chosedDate]);
  useEffect(() => {
    let isSubscribed = true;
    var Tdates = [];
    var Tsales = [];
    var TgrossProfit = [];
    var Tcps = [];
    var Taov = [];
    var TnbrOforders = [];
    var lastTdates = [];
    var lastTsales = [];
    var lastTgrossProfit = [];
    var lastTcps = [];
    var lastTaov = [];
    var lastTnbrOforders = [];
    var lastMainS = [];
    var lastMainGP = [];
    var mainS = [];
    var mainGP = [];
    if (dataDashboard.length !== 0) {
      dataDashboard.reports.map((elem) => (
        Tsales.push(elem.sum_subtotal ? elem.sum_subtotal.toFixed(2) : 0),
        TgrossProfit.push(elem.gross_rev ? elem.gross_rev.toFixed(2) : 0),
        Tcps.push(elem.cps ? (elem.cps).toFixed(2) : 0),
        Taov.push(elem.aov ? elem.aov.toFixed(2) : 0),
        TnbrOforders.push(elem.total_num_orders ? elem.total_num_orders : 0),
        Tdates.push(elem.date ? elem.date : null)
      )
      );
      dataDashboard.lastPeriodReport.map((elem) => (
        lastTsales.push(elem.sum_subtotal ? elem.sum_subtotal.toFixed(2) : 0),
        lastTgrossProfit.push(elem.gross_rev ? elem.gross_rev.toFixed(2) : 0),
        lastTcps.push(elem.cps ? elem.cps.toFixed(2) : 0),
        lastTaov.push(elem.aov ? elem.aov.toFixed(2) : 0),
        lastTnbrOforders.push(elem.total_num_orders ? elem.total_num_orders : 0),
        lastTdates.push(elem.date ? elem.date : null)
      )
      );
      dataDashboard.lastPeriodReport.map((val, i) => {
        var difference = daysBetween(new Date(dateRage.date[0]), new Date(dateRage.date[1]));
        var x_date = new Date(dataDashboard.lastPeriodReport[i].date);
        comparedTo !== 'year' ?
          x_date.setDate(x_date.getDate() + difference + 1)
          :
          x_date.setDate(x_date.getDate() + difference)
        if (comparedTo !== 'year') {
          lastMainS.push(
            new Date(dateRage.date[0]).toLocaleString("fr-FR").split(" ")[0] !==
              new Date(dateRage.date[1]).toLocaleString("fr-FR").split(" ")[0]
              ? dataDashboard && dataDashboard.lastPeriodReport.length > 0
                ? {
                  x:
                    x_date.toISOString().split("-")[0] +
                    "-" +
                    x_date.toISOString().split("-")[1] +
                    "-" +
                    x_date.toISOString().split("-")[2].split("T")[0] +
                    "  GMT",
                  y: val.sum_subtotal ? val.sum_subtotal : 0,
                }
                : { x: "", y: 0 }
              : dataDashboard && dataDashboard.lastPeriodReport.length > 0
                ? {
                  x: new Date(dataDashboard?.reports[i]?.date).getTime(),
                  y: val.sum_subtotal ? val.sum_subtotal : 0,
                }
                : { x: "", y: 0 }
          );
        } else {
          let y_date = dateRage.date[0].getFullYear()
          lastMainS.push(
            new Date(dateRage.date[0]).toLocaleString("fr-FR").split(" ")[0] !==
              new Date(dateRage.date[1]).toLocaleString("fr-FR").split(" ")[0]
              ? dataDashboard && dataDashboard.lastPeriodReport.length > 0
                ? {
                  x:
                    y_date +
                    "-" +
                    x_date.toISOString().split("-")[1] +
                    "-" +
                    x_date.toISOString().split("-")[2].split("T")[0] +
                    "  GMT",
                  y: val.sum_subtotal ? val.sum_subtotal : 0,
                }
                : { x: "", y: 0 }
              : dataDashboard && dataDashboard.lastPeriodReport.length > 0
                ? {
                  x: new Date(dataDashboard?.reports[i]?.date).getTime(),
                  y: val.sum_subtotal ? val.sum_subtotal : 0,
                }
                : { x: "", y: 0 }
          );
        }
      });
      dataDashboard.lastPeriodReport.map((val, i) => {
        var difference = daysBetween(new Date(dateRage.date[0]), new Date(dateRage.date[1]));
        var x_date = new Date(dataDashboard.lastPeriodReport[i].date);
        comparedTo !== 'year' ?
          x_date.setDate(x_date.getDate() + difference + 1)
          :
          x_date.setDate(x_date.getDate() + difference)
        if (comparedTo !== 'year') {
          lastMainGP.push(
            new Date(dateRage.date[0]).toLocaleString("fr-FR").split(" ")[0] !==
              new Date(dateRage.date[1]).toLocaleString("fr-FR").split(" ")[0]
              ? dataDashboard && dataDashboard.lastPeriodReport.length > 0
                ? {
                  x:
                    x_date.toISOString().split("-")[0] +
                    "-" +
                    x_date.toISOString().split("-")[1] +
                    "-" +
                    x_date.toISOString().split("-")[2].split("T")[0] +
                    "  GMT",
                  y: val.gross_rev ? val.gross_rev : 0,
                }
                : { x: "", y: 0 }
              : dataDashboard && dataDashboard.lastPeriodReport.length > 0
                ? {
                  x: new Date(dataDashboard?.reports[i]?.date).getTime(),
                  y: val.gross_rev ? val.gross_rev : 0,
                }
                : { x: "", y: 0 }
          );
        }
        else {
          let y_date = dateRage.date[0].getFullYear()
          lastMainGP.push(
            new Date(dateRage.date[0]).toLocaleString("fr-FR").split(" ")[0] !==
              new Date(dateRage.date[1]).toLocaleString("fr-FR").split(" ")[0]
              ? dataDashboard && dataDashboard.lastPeriodReport.length > 0
                ? {
                  x:
                    y_date +
                    "-" +
                    x_date.toISOString().split("-")[1] +
                    "-" +
                    x_date.toISOString().split("-")[2].split("T")[0] +
                    "  GMT",
                  y: val.gross_rev ? val.gross_rev : 0,
                }
                : { x: "", y: 0 }
              : dataDashboard && dataDashboard.lastPeriodReport.length > 0
                ? {
                  x: new Date(dataDashboard?.reports[i]?.date).getTime(),
                  y: val.gross_rev ? val.gross_rev : 0,
                }
                : { x: "", y: 0 }
          );
        }
      });
      dataDashboard.reports.map((val, i) => {
        var x = new Date(dataDashboard.reports[i].date).toUTCString();
        mainS.push(
          new Date(dateRage.date[0]).toLocaleString("fr-FR").split(" ")[0] !==
            new Date(dateRage.date[1]).toLocaleString("fr-FR").split(" ")[0]
            ? dataDashboard && dataDashboard.reports.length > 0
              ? {
                x:
                  dataDashboard.reports[i].date.split("-")[0] +
                  "-" +
                  dataDashboard.reports[i].date.split("-")[1] +
                  "-" +
                  (parseInt(dataDashboard.reports[i].date.split("-")[2].split("T")[0])) +
                  "  GMT",
                y: val.sum_subtotal ? val.sum_subtotal : 0,
              }
              : { x: "", y: 0 }
            : dataDashboard && dataDashboard.reports.length > 0
              ? {
                x: new Date(dataDashboard.reports[i].date).getTime(),
                y: val.sum_subtotal ? val.sum_subtotal : 0,
              }
              : { x: "", y: 0 }
        );
      });
      dataDashboard.reports.map((val, i) => {
        mainGP.push(
          new Date(dateRage.date[0]).toLocaleString("fr-FR").split(" ")[0] !==
            new Date(dateRage.date[1]).toLocaleString("fr-FR").split(" ")[0]
            ? dataDashboard && dataDashboard.reports.length > 0
              ? {
                x:
                  dataDashboard.reports[i].date.split("-")[0] +
                  "-" +
                  dataDashboard.reports[i].date.split("-")[1] +
                  "-" +
                  (parseInt(dataDashboard.reports[i].date.split("-")[2].split("T")[0])) +
                  "  GMT",
                y: val.gross_rev ? val.gross_rev : 0,
              }
              : { x: "", y: 0 }
            : dataDashboard && dataDashboard.reports.length > 0
              ? {
                x: new Date(dataDashboard.reports[i].date).getTime(),
                y: val.gross_rev ? val.gross_rev : 0,
              }
              : { x: "", y: 0 }
        );
      });
    }
    setSales(Tsales);
    setGrossProfit(TgrossProfit);
    setCps(Tcps);
    setAov(Taov);
    setNmbrOfOrders(TnbrOforders);
    setLastSales(lastTsales);
    setLastGrossProfit(lastTgrossProfit);
    setLastCps(lastTcps);
    setLastAov(lastTaov);
    setLastNmbrOfOrders(lastTnbrOforders);
    setLastMainGrossProfit(lastMainGP);
    setLastMainSales(lastMainS);
    setMainSales(mainS);
    setMainGrossProfit(mainGP);
    setDates(Tdates);
    setLastDates(lastTdates);
    return () => (isSubscribed = false);
  }, [dataDashboard]);
  var op1 = {
    chart: {
      toolbar: {
        followCursor: true,
        show: false,
        tools: {
          download: true,
          selection: true,
          zoom: true,
          zoomin: true,
          zoomout: true,
          pan: true,
          reset: true,
        },
        autoSelected: "zoom",
      },
      tooltip: {
        shared: true,
        intersect: true,
        followCursor: true,
        x: {
          show: false,
        },
      },
      type: "area",
      height: 120,
      sparkline: {
        enabled: true,
      },
    },
    markers: {
      size: 0,
    },
    stroke: {
      curve: "straight",
      width: 2.5,
    },
    fill: {
      opacity: 1,
    },
    series: [{ name: "Sales", data: sales }, { name: "Compared To", data: lastSales }],
    xaxis: {
      labels: {
        format: daysBetween(chosedDate[0].startDate, chosedDate[0].endDate) !== 0 ? 'dd MMM' : 'HH:mm',
      },
      crosshairs: {
        width: 1,
      },
      type: 'numeric'
    },

    yaxis: {
      show: false,
    },
    colors: ["#F6B43B", "#EC1A74"],
  };
  var op2 = {
    chart: {
      toolbar: {
        followCursor: true,
        show: false,
        tools: {
          download: true,
          selection: true,
          zoom: true,
          zoomin: true,
          zoomout: true,
          pan: true,
          reset: true,
        },
        autoSelected: "zoom",
      },
      tooltip: {
        shared: true,
        intersect: true,
        followCursor: true,
        x: {
          show: false,
        },
      },
      type: "area",
      height: 120,
      sparkline: {
        enabled: true,
      },
    },
    markers: {
      size: 0,
    },
    stroke: {
      curve: "straight",
      width: 2.5,
    },
    fill: {
      opacity: 1,
    },
    series: [{ name: "Gross Profit", data: grossProfit }, { name: "Compared To", data: lastGrossProfit }],
    xaxis: {
      crosshairs: {
        width: 1,
      },
    },
    yaxis: {
      show: false,
    },
    colors: ["#F6B43B", "#EC1A74"],
  };
  var op3 = {
    chart: {
      toolbar: {
        followCursor: true,
        show: false,
        tools: {
          download: true,
          selection: true,
          zoom: true,
          zoomin: true,
          zoomout: true,
          pan: true,
          reset: true,
        },
        autoSelected: "zoom",
      },
      tooltip: {
        shared: true,
        intersect: true,
        followCursor: true,
        x: {
          show: false,
        },
      },
      type: "area",
      height: 120,
      sparkline: {
        enabled: true,
      },
    },
    markers: {
      size: 0,
    },
    fill: {
      opacity: 1,
    },
    series: [{ name: "AOV", data: [sales, nmbrOfOrders] }, { name: "Compared To", data: [lastSales, lastNmbrOfOrders] }],
    xaxis: {
      crosshairs: {
        width: 1,
      },
    },
    yaxis: {
      show: false,
    },

    colors: ["#F6B43B", "#EC1A74"],
  };
  var op4 = {
    chart: {
      toolbar: {
        followCursor: true,
        show: false,
        tools: {
          download: true,
          selection: true,
          zoom: true,
          zoomin: true,
          zoomout: true,
          pan: true,
          reset: true,
        },
        autoSelected: "zoom",
      },
      tooltip: {
        shared: true,
        intersect: true,
        followCursor: true,
        x: {
          show: false,
        },
      },
      type: "area",
      height: 120,
      sparkline: {
        enabled: true,
      },
    },
    markers: {
      size: 0,
    },
    stroke: {
      curve: "straight",
      width: 2.5,
    },
    fill: {
      opacity: 1,
    },
    series: [{ name: "# orders", data: nmbrOfOrders }, { name: "Compared To", data: lastNmbrOfOrders }],
    xaxis: {
      crosshairs: {
        width: 1,
      }
    },
    yaxis: {
      show: false
    },
    tooltip: {
      y: {
        formatter: (val, i) => {
          return Math.trunc(val);
        }
      }
    },
    colors: ["#F6B43B", "#EC1A74"],
  };
  var op5 = {
    chart: {
      toolbar: {
        followCursor: true,
        show: false,
        tools: {
          download: true,
          selection: true,
          zoom: true,
          zoomin: true,
          zoomout: true,
          pan: true,
          reset: true,
        },
        tooltip: {
          shared: true,
          intersect: true,
          followCursor: true,
          x: {
            show: false,
          },
        },
        autoSelected: "zoom",
      },
      type: "area",
      height: 120,
      sparkline: {
        enabled: true,
      },
    },
    markers: {
      size: 0,
    },
    stroke: {
      curve: "straight",
      width: 2.5,
    },
    fill: {
      opacity: 1,
    },
    series: [{ name: "CPS", data: cps }, { name: "Compared To", data: lastCps }],
    xaxis: {
      crosshairs: {
        width: 1,
      },
    },
    yaxis: {
      show: false,
    },

    colors: ["#F6B43B", "#EC1A74"],
  };
  const [myCards, setMyCards] = useState([
    { id: "2", name: "sales", options: op1, series: op1.series, lastseries: lastSales, dates: dates, lastDates: lastDates },
    { id: "3", name: "GrossProfit", options: op2, series: op2.series, lastseries: lastGrossProfit, adCost: op5.series, dates: dates, lastDates: lastDates },
    {
      id: "4",
      name: "AOV",
      options: op3,
      series: op3.series,
      orders: op3.series,
      dates: dates, lastDates: lastDates
    },
    { id: "5", name: "NumberofOrders", options: op4, series: op4.series, lastseries: lastNmbrOfOrders, dates: dates, lastDates: lastDates },
    {
      id: "6", name: "CPS",
      options: op5,
      series: op5.series,
      orders: op5.series,
      lastseries: lastCps,
      lastOrders: lastNmbrOfOrders,
      dates: dates, lastDates: lastDates
    },
  ]);
  const onDragEnd = (result) => {
    const newItems = Array.from(myCards);
    const [removed] = newItems.splice(result.source.index, 1);
    newItems.splice(result.destination.index, 0, removed);
    setMyCards(newItems);
  };
  useEffect(() => {
    let isSubscribed = true;
    setMyCards([
      { id: "2", name: "sales", options: op1, series: op1.series, lastseries: lastSales, dates: dates, lastDates: lastDates },
      { id: "3", name: "GrossProfit", options: op2, series: op2.series, lastseries: lastGrossProfit, adCost: op5.series, dates: dates, lastDates: lastDates },
      {
        id: "4",
        name: "AOV",
        options: op3,
        series: op3.series,
        orders: op4.series,
        lastseries: lastAov,
        lastOrders: lastNmbrOfOrders, dates: dates, lastDates: lastDates
      },
      { id: "5", name: "NumberofOrders", options: op4, series: op4.series, lastseries: lastNmbrOfOrders, dates: dates, lastDates: lastDates },
    ]);
    return () => (isSubscribed = false);
  }, [sales, grossProfit, cps, aov, nmbrOfOrders, lastSales, lastGrossProfit, lastCps, lastAov, lastNmbrOfOrders, dates, lastDates]);
  const getAOV = (t1, t2, diff) => {
    if (diff === 0) {
      var totalConversion = t1.length > 0 ? t1.map(Number).reduce((x, y) => {
        return x + y;
      }) : 0;
      return (totalConversion / parseFloat(t2))
    } else {
      if (t1 instanceof Array && t2 instanceof Array && t1.length > 0 && t2.length > 0) {
        var totalconversionvalue = t2.reduce((x, y) => { return parseFloat(x) + parseFloat(y) })
        var totalConversion = t1.reduce((x, y) => { return parseFloat(x) + parseFloat(y) })
        return (totalconversionvalue / totalConversion);
      } else if (t2 > 0) {
        return ((parseFloat(t2) / parseFloat(t1)));
      } else if (t2 > 0) {
        return ((parseFloat(t2) / parseFloat(t1)));
      }
      return 0;
    }
  }
  const getLastAOV = (t1, t2, diff) => {
    if (diff === 0) {
      var lastTotalConversions = t1.map(Number).reduce((x, y) => {
        return x + y;
      })
      return ((lastTotalConversions / parseFloat(t2)))
    } else {
      if (t1 instanceof Array && t2 instanceof Array && t1.length > 0 && t2.length > 0) {
        var lastTotalconversionvalue = t2.reduce((x, y) => { return parseFloat(x) + parseFloat(y) })
        var lastTotalConversions = t1.reduce((x, y) => { return parseFloat(x) + parseFloat(y) })
        var lastAOVCalculated = (lastTotalconversionvalue / lastTotalConversions)
        return lastAOVCalculated;
      } else if (t2 > 0) {
        return ((parseFloat(t2) / parseFloat(t1)));
      }
      return 0
    }
  }
  const handleCompareToggle = (compare_to) => {
    setComparedTo(compare_to)
    if (comparedTo === compare_to) {
      setComparedTo("")
    }
  }
  useEffect(() => {
    let isSubscribed = true;
    ApploShopifyCLI.query({
      query: getSalesPlatform,
      context: {
        headers: {
          Authorization: window.localStorage.getItem("token")
            ? `Bearer ${window.localStorage.getItem("token")}`
            : "",
        },
      },
      errorPolicy: "all",
      fetchPolicy: "network-only",
    }).then((data) => {
      if (data.data && data.data.get && data.data.get.url === null) {
        console.log("data.data.get.url", data.data.get.url);
        alert().then(() => {
          history.push("/integrations");
        });
      }
    });
  
    return () => (isSubscribed = false);
  }, []);
  useEffect(() => {
    if (currentFocus[1] === 0) {
      setDateRange({
        date: [
          new Date(new Date(chosedDate[0].startDate.toLocaleDateString('en-US', { timeZone: props.timeZone ? props.timeZone : "UTC" }))),
          new Date(new Date(chosedDate[0].endDate.toLocaleDateString('en-US', { timeZone: props.timeZone ? props.timeZone : "UTC" })))
        ]
      });
      setToggleDate(false)
    }
  }, [currentFocus])

  useEffect(() => {
    window.localStorage.setItem("start", chosedDate[0].startDate);
    window.localStorage.setItem("end", chosedDate[0].endDate);
  }, [chosedDate]);

  const getListStyle = (isDraggingOver, itemsLength) => ({
    background: "#fffff",
    display: "flex",
    padding: "10px",
    width: itemsLength * 68.44 + 16
  });




  // REACT E-CHARTS
  const getOption = () => {
    return {
      tooltip: {
        trigger: "axis",
      },
      grid: {
        zlevel: 0,
        x: 50,
        x2: 50,
        y: 30,
        y2: 30,
        borderWidth: 0,
      },
      xAxis: {
        type: "category",
        data: convertedDate,
        axisLable: {
          color: "#ffffff",
        },
        axisLine: {
          lineStyle: {
            color: "#74788d",
          },
        },
      },
      yAxis: {
        type: "value",
        axisLable: {
          color: "#ffffff",
        },
        axisLine: {
          lineStyle: {
            color: "#74788d",
          },
        },
      },
      series: [
        {
          name: selectedSeries,
          data: getSelectedSeriesData(selectedSeries), // Pass the selected series name to the function
          type: "line"
        },
      ],
      color: ["#556ee6"],
      textStyle: {
        color: ["#74788d"],
      },
    };
  };

  // options 
  const [selectedSeries, setSelectedSeries] = useState("sales_tab");

  const handleDropdownChange = (event) => {
    setSelectedSeries(event.target.value);
  };
  //Function to get the data array for the selected series
  const getSelectedSeriesData = (selectedSeries) => {
    switch (selectedSeries) {
      case "sales_tab":
        return sales;
      case "grossProfit":
        return grossProfit;
      case "nmbrOfOrders":
        return nmbrOfOrders;
    }
  };
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title> {props.t("WooCommerce Dashboard")} | Convergen Metrics - E-COM SASS</title>
        </MetaTags>
        <Container fluid>
          {<Breadcrumbs title={props.t("Dashboards")} breadcrumbItem={props.t("WooCommerce Dashboard")} />}
          <Row>
            <Col md={3}>
              <FormGroup className="mb-4">
                <InputGroup>
                  <div className="DateContainer">
                    <div>
                      <div className="divDateContainer" >
                        <div ref={wrapperRef}>
                          <button
                            className="btn btn-light"
                            onClick={() => setToggleDate(!toggleDate)}
                            id="page-wooOverview-search-dropdown"
                          >
                            {chosedDate[0] !== null
                              ? [
                                chosedDate[0].startDate.toLocaleString("en", {
                                  month: "short",
                                }),
                                " ",
                                chosedDate[0].startDate.getDate(),
                                " ",
                                chosedDate[0].startDate.getFullYear(),
                                "  -  ",
                                chosedDate[0].endDate && chosedDate[0].endDate.toLocaleString("en", {
                                  month: "short",
                                }),
                                " ",
                                chosedDate[0].endDate && chosedDate[0].endDate.getDate(),
                                " ",
                                chosedDate[0].endDate && chosedDate[0].endDate.getFullYear(),
                              ]
                              : null}
                          </button>
                          {toggleDate && (
                            <div 
                            style={{
                              position: 'fixed',
                              display: 'flex',
                              left: '280px',
                              zIndex: 9999,
                            }}
                          >
                              <div className="calenderDivStyle">
                                <div className="btnsCalendar">
                                  <DateRange
                                    editableDateInputs={true}
                                    moveRangeOnFirstSelection={false}
                                    onRangeFocusChange={item => { setCurrentFocus(item) }}
                                    onChange={item => { setChosedDate([item.selection]); setActiveBtn({ today: 0, yesterday: 0, last7days: 0, last30day: 0, last60day: 0, month: 0, other: 1 }); window.localStorage.setItem("activeBtn", JSON.stringify({ today: 0, yesterday: 0, last7days: 0, last30day: 0, last60day: 0, month: 0, other: 1 })) }}
                                    ranges={chosedDate}
                                    dragSelectionEnabled={true}
                                    rangeColors={["#3B5B98"]}
                                    months={2}
                                    direction="horizontal"
                                    maxDate={new Date(new Date().getTime() - 24 * 60 * 60 * 1000)}
                                    disabledDates={[
                                      {
                                        after: new Date(), // Disable dates after today

                                      },
                                    ]}
                                  />
                                </div>
                                <div className="calendarDiv">
                                  <div className="btnCalendarContainer">
                                    <button
                                      className={activeBtn.today ? "dateBtns activeBtnDate" : "dateBtns"}
                                    >
                                      {props.t("compareto")}
                                    </button>
                                    <button
                                      className={activeBtn.yesterday ? "dateBtns activeBtnDate" : "dateBtns"}
                                      onClick={() => {
                                        const timeZone = window.localStorage.getItem('timezone') || 'UTC'; // set default time zone to UTC
                                        buttonHandler(
                                          new Date(new Date(
                                            new Date().getTime() - 24 * 60 * 60 * 1000
                                          ).toLocaleDateString('en-US', { timeZone: timeZone })),

                                          new Date(new Date(
                                            new Date().getTime() - 24 * 60 * 60 * 1000
                                          ).toLocaleDateString('en-US', { timeZone: timeZone })));
                                        setActiveBtn({ today: 0, yesterday: 1, last7days: 0, last30day: 0, last60day: 0, month: 0, other: 0 })
                                        window.localStorage.setItem("activeBtn", JSON.stringify({ today: 0, yesterday: 1, last7days: 0, last30day: 0, last60day: 0, month: 0, other: 0 }));
                                      }}
                                    >
                                      {props.t("yesterday")}
                                    </button>
                                    <button
                                      className={activeBtn.last7days ? "dateBtns activeBtnDate" : "dateBtns"}
                                      onClick={() => {
                                        const timeZone = window.localStorage.getItem('timezone') || 'UTC'; // set default time zone to UTC
                                        buttonHandler(
                                          new Date(new Date(
                                            new Date().getTime() - 6 * 24 * 60 * 60 * 1000
                                          ).toLocaleDateString('en-US', { timeZone: timeZone })),
                                          new Date(new Date().toLocaleDateString('en-US', { timeZone: timeZone }))
                                        );
                                        setActiveBtn({ today: 0, yesterday: 0, last7days: 1, last30day: 0, last60day: 0, month: 0, other: 0 })
                                        window.localStorage.setItem("activeBtn", JSON.stringify({ today: 0, yesterday: 0, last7days: 1, last30day: 0, last60day: 0, month: 0, other: 0 }));
                                      }}
                                    >
                                      {props.t("last7days")}
                                    </button>
                                    <button
                                      className={activeBtn.last30day ? "dateBtns activeBtnDate" : "dateBtns"}
                                      onClick={() => {
                                        const timeZone = window.localStorage.getItem('timezone') || 'UTC'; // set default time zone to UTC
                                        buttonHandler(
                                          new Date(new Date(
                                            new Date().getTime() - 29 * 24 * 60 * 60 * 1000
                                          ).toLocaleDateString('en-US', { timeZone: timeZone })),
                                          new Date(new Date().toLocaleDateString('en-US', { timeZone: timeZone }))
                                        );
                                        setActiveBtn({ today: 0, yesterday: 0, last7days: 0, last30day: 1, last60day: 0, month: 0, other: 0 })
                                        window.localStorage.setItem("activeBtn", JSON.stringify({ today: 0, yesterday: 0, last7days: 0, last30day: 1, last60day: 0, month: 0, other: 0 }));
                                      }}
                                    >
                                      {props.t("last30days")}
                                    </button>

                                    <button
                                      className={activeBtn.month ? "dateBtns activeBtnDate" : "dateBtns"}
                                      onClick={() => {
                                        const timeZone = window.localStorage.getItem('timezone') || 'UTC'; // set default time zone to UTC
                                        buttonHandler(
                                          new Date(new Date(
                                            new Date().getFullYear(),
                                            new Date().getMonth(),

                                            getToAddOnMonths(new Date().getMonth())
                                          ).toLocaleDateString('en-US', { timeZone: timeZone })),

                                          new Date(new Date().toLocaleDateString('en-US', { timeZone: timeZone }))
                                        );
                                        setActiveBtn({ today: 0, yesterday: 0, last7days: 0, last30day: 0, last60day: 0, month: 1, other: 0 })
                                        window.localStorage.setItem("activeBtn", JSON.stringify({ today: 0, yesterday: 0, last7days: 0, last30day: 0, last60day: 0, month: 1, other: 0 }));
                                      }}
                                    >
                                      {props.t("thismonth")}
                                    </button>
                                    <span className="compareSpan" ><b>{props.t("Compare To")}</b></span>
                                    <div className="form-check mb-3">
                                      <input
                                        style={{
                                          width: "1em",
                                          height: "1em",
                                          marginTop: "0.25em",
                                          verticalAlign: "top",
                                          backgroundColor: "#fff",
                                          backgroundRepeat: "no-repeat",
                                          backgroundPosition: "center",
                                          border: "#000000",
                                        }}
                                        type="checkbox"
                                        id="formCheck1"
                                        className={comparedTo === "period" ? "compareTogglebtnStyleActive" : null}
                                        checked={comparedTo === "period"}
                                        onClick={() => handleCompareToggle("period")}
                                      />
                                      <label className="form-check-label" htmlFor="formCheck1" style={{
                                        marginLeft: "0.5em", // Adjust the spacing between the checkbox and label
                                        cursor: "pointer",// Show pointer cursor when hovering over label
                                        marginTop: 'auto' 
                                      }}>
                                        {props.t("previousperiod")}
                                      </label>
                                    </div>
                                    <div className="form-check mb-3">
                                      <input
                                        style={{
                                          width: "1em",
                                          height: "1em",
                                          marginTop: "0.25em",
                                          verticalAlign: "top",
                                          backgroundColor: "#fff",
                                          backgroundRepeat: "no-repeat",
                                          backgroundPosition: "center",
                                          border: "#000000",
                                        }}
                                        type="checkbox"
                                        id="formCheck1"
                                        className={comparedTo === "year" ? "compareTogglebtnStyleActive" : null}
                                        checked={comparedTo === "year"}
                                        onClick={() => handleCompareToggle("year")}
                                      />
                                      <label className="form-check-label" htmlFor="formCheck1" style={{
                                        marginLeft: "0.5em", // Adjust the spacing between the checkbox and label
                                        cursor: "pointer",// Show pointer cursor when hovering over label
                                        marginTop: 'auto' 
                                      }}>
                                        {props.t("Previousyear")}
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                          <br />

                          {comparedTo !== "" && (
                            <small style={{
                              display: 'flex',
                              marginTop: '10px',
                              backgroundColor: 'rgb(59 91 152 / 7%)',
                              borderRadius: '5px',
                              width: 'max-content',
                              height: '30px',
                              padding: "8px",
                              fontWeight: 'bold',
                            }}>
                              {lastPeriod}
                            </small>
                          )}



                        </div>
                      </div>
                    </div>
                  </div>
                </InputGroup>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col xl={12}>
              <Card>
                <CardHeader>
                  <CardTitle> {props.t("Woocommerce  Charts")} </CardTitle>
                </CardHeader>
                <CardBody>
                  <div dir="ltr">
                    <select className="btn btn-primary" value={selectedSeries} onChange={handleDropdownChange}>
                      <option value="sales_tab">{props.t("Sales")}</option>
                      <option value="grossProfit">{props.t("Gross Profit")}</option>
                      <option value="nmbrOfOrders">{props.t("Number Of Orders")}</option>
                    </select>
                    <div className="charts">
                      <ReactEcharts
                        option={getOption()}
                        style={{ height: "300px", width: "90%" }}
                      />
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row >
            <div>  {
              comparedTo !== '' &&
              <div style={{ display: 'flex', marginTop: '50px', backgroundColor: 'rgb(59 91 152 / 7%)', borderRadius: '5px', width: 'max-content', height: '30px', padding: "15px" }}>
                <section style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <div style={{ width: '13px', height: '13px', background: '#F6B43B', borderRadius: '2px', marginRight: '5px', marginTop: '-2px' }}></div>
                  <span style={{ color: '#0C1426', fontSize: '14px', fontWeight: 'bold' }}>
                    {`${chosedDate[0]?.startDate?.toLocaleString("en", {
                      month: "short", day: "2-digit"
                    })}-${chosedDate[0]?.endDate?.toLocaleString("en", {
                      month: "short", day: "2-digit"
                    })} ${chosedDate[0]?.endDate?.getFullYear()}`}
                  </span>
                </section>
                <section style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginLeft: '15px' }}>
                  <div style={{ width: '13px', height: '13px', background: '#EC1A74', borderRadius: '2px', marginRight: '5px', marginTop: '-2px' }}></div>
                  <span style={{ color: '#0C1426', fontSize: '14px', fontWeight: 'bold' }}>
                    {lastPeriod.split(':')[1]}
                  </span>
                </section>
              </div>
            }
              <br></br>
            </div>
          </Row>
          <Row>
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId="droppable" direction="horizontal">
                {(provided, snapshot) => (
                  <div
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    style={getListStyle(snapshot.isDraggingOver)}
                  >
                    {myCards.map((item, index) => (
                      <Draggable key={item.id} draggableId={item.id} index={index}>
                        {(provided, snapshot) => (
                          <WooDataCard
                            provided={provided}
                            snapshot={snapshot}
                            item={item}
                            loading={loadingState}
                            date={chosedDate}
                            lastPeriod={lastPeriod}
                            getAOV={getAOV}
                            getLastAOV={getLastAOV}
                          />
                        )}
                      </Draggable>
                    ))}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
}
WooDashboard.propTypes = {
  children: PropTypes.element.isRequired,
};
export default withRouter(withTranslation()(React.memo(WooDashboard)));
//export default React.memo(WooDashboard);