import { call, put, takeEvery } from 'redux-saga/effects';
import {
    integrateGoogleAnalyticsFailure,
    integrateGoogleAnalyticsSuccess
} from './actions'
import {
    INTEGRATE_GOOGLE_ANALYTICS_REQUEST
} from './actionsTypes';
import { integrate } from '../../queries/GoogleAnalytics/Queries';
import urls from '../../routes/apiUrls';
import ApolloClient from "apollo-boost";

const googleAnalyticsApolloCli = new ApolloClient({
    uri: urls.analytics
})
//INTEGRATE GOOGLE ANALYTICS 
function* integrateGoogleAnalytics(action){
    try{
      const { data } = yield call(googleAnalyticsApolloCli.mutate, {
        mutation: integrate,
        variables: {
          code: action.payload.code,
        },
        context: {
          headers: {
            Authorization: window.localStorage.getItem("token")
              ? `Bearer ${window.localStorage.getItem("token")}`
              : "",
          },
        },
        errorPolicy: "all",
        fetchPolicy: "no-cache",
      });
      console.log("integrate googele analytics code",data);
      if (data.integration) {
      yield put(integrateGoogleAnalyticsSuccess(data.integration));
      window.location.reload('/integrations');
      }
  
    }catch(error){
      yield put(integrateGoogleAnalyticsFailure(error.message));
   }
   }
export default function* rootSaga() {
    yield takeEvery(INTEGRATE_GOOGLE_ANALYTICS_REQUEST, integrateGoogleAnalytics);
}