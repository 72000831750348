import gql from 'graphql-tag';

const getData = gql`query($startDate:String!,$endDate:String!,$compare_to:String){
    get(startDate:$startDate,endDate:$endDate,compare_to:$compare_to){
        pixels{
            _id,
            page_views,
            content_views,
            add_to_cart,
            purchase,
            checkouts,
            payment_infos,
            applications,
            subscribes,
            searches,
            leads,
            wishlist,
            registration_complete,
            contacts,
            customize_product,
            donation,
            find_location,
            schedules,
            start_trial,
        },
        reports{
            date,
            sum_subtotal,
            total_num_orders,
            gross_rev,
            aov,
            cps,
            ctr,
            clicks,
            impressions,
            average_cpc,
            conversions,
            conversions_value,
            interactions
        },
        lastPeriodReport{
            date,
            sum_subtotal,
            total_num_orders,
            gross_rev,
            aov,
            cps,
            ctr,
            clicks,
            impressions,
            average_cpc,
            conversions,
            conversions_value,
            interactions
        }
       
    }
}
`

export { getData }