import React, { useEffect, useState } from "react"
import MetaTags from 'react-meta-tags';
import {
  Row
} from "reactstrap"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import ApolloClient from 'apollo-boost';
import { getGAUsers } from "../../../queries/GoogleAnalytics/Queries";
import urls from "../../../routes/apiUrls";
import GAUsersDataCard from "./GAUsersDataCard";
const ApploCLI = new ApolloClient({
  uri: urls.analytics,
});
function GaUsers() {
  const [usersDataDashboard, setUsersDataDashboard] = useState([]);
  const [loadingState, setLoadingState] = useState(false);
  const [sessionsPerUser, setSessionsPerUser] = useState([]);
  const [percentNewSessions, setPercentNewSessions] = useState([]);
  const [users, setUsers] = useState([]);
  const [newUsers, setNewUsers] = useState([]);
  useEffect(() => {
    let isSubscribed = true;
    ApploCLI.query({
      query: getGAUsers,
      context: {
        headers: {
          Authorization: window.localStorage.getItem("token")
            ? `Bearer ${window.localStorage.getItem("token")}`
            : "",
        },
      },
      errorPolicy: "all",
      fetchPolicy: "network-only",
    }).then((data) => {
      if (isSubscribed) {
        if (data.data.gausers) {
            setUsersDataDashboard(data.data.gausers);
          setLoadingState(true);
        } else {
          setLoadingState(false);
        }
      }
    });
    return () => (isSubscribed = false);
  }, []);
  useEffect(() => {
    let isSubscribed = true;
    var TsessionsPerUser = [];
    var TpercentNewSessions = [];
    var Tusers = [];
    var TnewUsers = [];
    if (usersDataDashboard.length !== 0) {
        usersDataDashboard.map((elem) => {
        TsessionsPerUser.push(elem.sessionsPerUser ? (elem.sessionsPerUser) : 0)
        TpercentNewSessions.push(elem.percentNewSessions ? (elem.percentNewSessions) : 0)
        Tusers.push(elem.users ? (elem.users) : 0)
        TnewUsers.push(elem.newUsers ? (elem.newUsers) : 0)
      })
    }
    setSessionsPerUser(TsessionsPerUser);
    setPercentNewSessions(TpercentNewSessions);
    setUsers(Tusers);
    setNewUsers(TnewUsers);
    return () => (isSubscribed = false);
  }, [usersDataDashboard]);
  var op1 = {
    chart: {
      toolbar: {
        followCursor: true,
        show: false,
        tools: {
          download: true,
          selection: true,
          zoom: true,
          zoomin: true,
          zoomout: true,
          pan: true,
          reset: true,
        },
        tooltip: {
          shared: true,
          intersect: true,
          followCursor: true,
          x: {
            show: false,
          },
        },
        autoSelected: "zoom",
      },
      type: "area",
      height: 120,
      sparkline: {
        enabled: true,
      },
    },
    markers: {
      size: 0,
    },
    stroke: {
      curve: "straight",
      width: 2.5,
    },
    fill: {
      opacity: 1,
    },
    series: [{ name: "Users", data: users }],
    xaxis: {
      crosshairs: {
        width: 1,
      },
    },
    yaxis: {
      show: false,
    },

    colors: ["#F6B43B", "#EC1A74"],
  };
  var op2 = {
    chart: {
      toolbar: {
        followCursor: true,
        show: false,
        tools: {
          download: true,
          selection: true,
          zoom: true,
          zoomin: true,
          zoomout: true,
          pan: true,
          reset: true,
        },
        tooltip: {
          shared: true,
          intersect: true,
          followCursor: true,
          x: {
            show: false,
          },
        },
        autoSelected: "zoom",
      },
      type: "area",
      height: 120,
      sparkline: {
        enabled: true,
      },
    },
    markers: {
      size: 0,
    },
    stroke: {
      curve: "straight",
      width: 2.5,
    },
    fill: {
      opacity: 1,
    },
    series: [{ name: "New Users", data: newUsers }],
    xaxis: {
      crosshairs: {
        width: 1,
      },
    },
    yaxis: {
      show: false,
    },

    colors: ["#F6B43B", "#EC1A74"],
  };
  var op3 = {
    chart: {
      toolbar: {
        followCursor: true,
        show: false,
        tools: {
          download: true,
          selection: true,
          zoom: true,
          zoomin: true,
          zoomout: true,
          pan: true,
          reset: true,
        },
        tooltip: {
          shared: true,
          intersect: true,
          followCursor: true,
          x: {
            show: false,
          },
        },
        autoSelected: "zoom",
      },
      type: "area",
      height: 120,
      sparkline: {
        enabled: true,
      },
    },
    markers: {
      size: 0,
    },
    stroke: {
      curve: "straight",
      width: 2.5,
    },
    fill: {
      opacity: 1,
    },
    series: [{ name: "Sessions Per User", data: sessionsPerUser }],
    xaxis: {
      crosshairs: {
        width: 1,
      },
    },
    yaxis: {
      show: false,
    },

    colors: ["#F6B43B", "#EC1A74"],
  };
  var op4 = {
    chart: {
      toolbar: {
        followCursor: true,
        show: false,
        tools: {
          download: true,
          selection: true,
          zoom: true,
          zoomin: true,
          zoomout: true,
          pan: true,
          reset: true,
        },
        tooltip: {
          shared: true,
          intersect: true,
          followCursor: true,
          x: {
            show: false,
          },
        },
        autoSelected: "zoom",
      },
      type: "area",
      height: 120,
      sparkline: {
        enabled: true,
      },
    },
    markers: {
      size: 0,
    },
    stroke: {
      curve: "straight",
      width: 2.5,
    },
    fill: {
      opacity: 1,
    },
    series: [{ name: "Percent New Sessions", data: percentNewSessions }],
    xaxis: {
      crosshairs: {
        width: 1,
      },
    },
    yaxis: {
      show: false,
    },

    colors: ["#F6B43B", "#EC1A74"],
  };
  const [myCards, setMyCards] = useState([
    { id: 1, name: "Users", options: op1, series: op1.series },
    { id: 2, name: "New Users", options: op2, series: op2.series },
    { id: 3, name: "Sessions Per User", options: op3, series: op3.series },
    { id: 4, name: "Percent New Sessions", options: op4, series: op4.series },
  ]);
  useEffect(() => {
    let isSubscribed = true;
    setMyCards([
        { id: 1, name: "Users", options: op1, series: op1.series },
        { id: 2, name: "New Users", options: op2, series: op2.series },
        { id: 3, name: "Sessions Per User", options: op3, series: op3.series },
        { id: 4, name: "Percent New Sessions", options: op4, series: op4.series },
    ]);
    return () => (isSubscribed = false);
  }, [users,newUsers,sessionsPerUser,percentNewSessions]);
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>ga Users | Live-Metrics - E-com SaaS</title>
        </MetaTags>
        <div className="container-fluid">
          <Breadcrumbs title="Google Analytics" breadcrumbItem="Users" pageHeading="Users" />
          <Row>
          {
              myCards.map(item => (
                <GAUsersDataCard loading={loadingState} item={item} />
              ))}
          </Row>
        </div>
      </div>
    </React.Fragment>
  )
}
export default GaUsers;
