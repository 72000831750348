import React from "react"
import MetaTags from 'react-meta-tags';
import {
  Row
} from "reactstrap"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import TotalEvents from "./TotalEvents";
import EventValue from "./EventValue"
import AvgValue from "./AvgValue";
import SessionsWithEvents from "./SessionsWithEvents";
import EventPerSessionWithEvent from "./EventPerSessionWithEvent";
function GAEventActionTracking() {
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Google Analytics | Live-Metrics - E-com SaaS</title>
        </MetaTags>
        <div className="container-fluid">
          <Breadcrumbs title="Google Analytics" breadcrumbItem="Event Action Tracking" pageHeading="Event Action Tracking" />
          <Row>
         <TotalEvents />
         <EventValue />
          </Row>
         <Row>
          <AvgValue/>
          <SessionsWithEvents />
          <EventPerSessionWithEvent />
         </Row>
        </div>
      </div>
    </React.Fragment>
  )
}
export default GAEventActionTracking;
