import gql from 'graphql-tag';


const getInformations = gql`query($startDate:String!, $endDate:String!){
    advisor(startDate:$startDate, endDate:$endDate){
        aov,
        total_orders,
        costGoods,
        merchantFees,
        shippingFees,
        cps,
        refunds,
        currency
    }
}
`


export {getInformations};