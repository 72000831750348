import React, { useEffect, useState } from 'react';
import { Link, useHistory } from "react-router-dom";
import MetaTags from "react-meta-tags";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
} from "reactstrap";

import urls from '../../routes/apiUrls';
import ApolloClient from 'apollo-boost';
import { getInvoice } from '../../queries/Users/userQuery';
import Breadcrumbs from "../../components/Common/Breadcrumb";

import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";

//redux
import { useSelector, useDispatch } from "react-redux";

const ApploCLI = new ApolloClient({
  uri: urls.user
});
const getInvoices = async () => {
  try {
      const response = await ApploCLI.query({
          query: getInvoice, // Make sure 'getInvoice' is defined and contains the correct GraphQL query
          variables: {},
          context: {
              headers: {
                  Authorization: window.localStorage.getItem('token') ?
                      `Bearer ${window.localStorage.getItem('token')}` :
                      '',
              }
          },
          errorPolicy: "all",
      });

      console.log("billing", response.data.getInvoice);
      return response.data.getInvoice.map(invoice => ({
          invoiceId: invoice.payment_id, // Using 'payment_id' as 'invoiceId' for demonstration
          date: invoice.DatePaiement.split('T')[0],
          billing_name: `${invoice.User.firstname} ${invoice.User.lastname}`,
          Plan_Type: invoice.Plan, // Example, as this wasn't provided in your response
          Amount: `TND${(parseInt(invoice.Amount) / 1000).toFixed(2)}`, // Assuming 'Amount' is in cents
          status: invoice.status,
          method: invoice.method,
          color: invoice.status === "SUCCESS" ? "success" : "danger", // Conditional color based on status
      }));
      
  } catch (error) {
      console.error("verification error", error);
      return []; // Return an empty array in case of error
  }
};
const Billing = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { SearchBar } = Search;

  const [invoices, setInvoices] = useState([]);
  
  useEffect(() => {
    getInvoices().then(setInvoices);
  }, []);

  const pageOptions = {
    sizePerPage: 7,
    totalSize: invoices.length, // replace later with size(users),
    custom: true,
  };

  const defaultSorted = [
    {
      dataField: "invoiceId", // if dataField is not match to any column you defined, it will be ignored.
      order: "asc", // desc or asc
    },
  ];

  const selectRow = {
    mode: "checkbox",
  };

  const invoicesListColumns = [
    {
      text: "Invoice ID",
      dataField: "invoiceId",
      sort: true,
      formatter: (cellContent, invoices) => <>{invoices.invoiceId}</>,
    },
    {
      text: "Date",
      dataField: "date",
      sort: true,
      formatter: (cellContent, invoices) => <>{invoices.date}</>,
    },
    {
      text: "Billing Name",
      dataField: "billing_name",
      sort: true,
      formatter: (cellContent, invoices) => <>{invoices.billing_name}</>,
    },
    {
      text: "Plan Type",
      dataField: "Plan_Type",
      sort: true,
      formatter: (cellContent, invoices) => <>{invoices.Plan_Type}</>,
    },
    {
      text: "Method",
      dataField: "method",
      sort: true,
      formatter: (cellContent, invoices) => <>{invoices.method}</>,

    },
    {
      text: "Amount",
      dataField: "amount",
      sort: true,
      formatter: (cellContent, invoices) => <>{invoices.Amount}</>,
    },
    {
      text: "Status",
      dataField: "status",
      sort: true,
      formatter: (cellContent, invoices) => (
        <>
          <div className={"badge badge-soft-" + invoices.color + " font-size-12"}>
            {invoices.status}
          </div>
        </>
      ),
    },
    {
      text: "Download Pdf",
      dataField: "pdf",
      sort: true,
      formatter: (cellContent, row) => (
        <>
          <div>
            <button
              type="button"
              className="btn btn-soft-light btn-sm w-xs waves-effect btn-label waves-light"
              onClick={() => history.push('/billing-details', { invoice: row })}
            >
              <i className="bx bx-download label-icon"></i> Pdf
            </button>
          </div>
        </>
      ),
    },
  ];

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Invoice List <span className="text-muted fw-normal ms-2">({invoices.length})</span></title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Invoices" breadcrumbItem="Invoice List" />
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <PaginationProvider pagination={paginationFactory(pageOptions)}>
                    {({ paginationProps, paginationTableProps }) => (
                      <ToolkitProvider keyField="invoiceId" data={invoices} columns={invoicesListColumns} bootstrap4 search>
                        {toolkitProps => (
                          <React.Fragment>
                            <Row className="mb-2">
                              <Col sm="4">
                                <div className="search-box ms-2 mb-2 d-inline-block">
                                  <div className="position-relative">
                                    <SearchBar {...toolkitProps.searchProps} />
                                    <i className="bx bx-search-alt search-icon-search" />
                                  </div>
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col xl="12">
                                <div className="table-responsive">
                                  <BootstrapTable
                                    {...toolkitProps.baseProps}
                                    {...paginationTableProps}
                                    selectRow={selectRow}
                                    defaultSorted={defaultSorted}
                                    classes="table align-middle table-nowrap table-hover"
                                    responsive
                                    bordered={false}
                                    striped={false}
                                  />
                                </div>
                              </Col>
                            </Row>
                            <Row className="align-items-md-center mt-30">
                              <Col className="pagination pagination-rounded justify-content-end mb-2">
                                <PaginationListStandalone {...paginationProps} />
                              </Col>
                            </Row>
                          </React.Fragment>
                        )}
                      </ToolkitProvider>
                    )}
                  </PaginationProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Billing;
