import React, { useEffect, useState } from "react"
import MetaTags from 'react-meta-tags';
import {
  Table,
  Row,
  Col,
} from "reactstrap"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import ApolloClient from 'apollo-boost';
import { getGAPlatform } from "../../../queries/GoogleAnalytics/Queries";
import urls from "../../../routes/apiUrls";
import { withTranslation } from "react-i18next"
import { withRouter } from "react-router-dom"
const ApploCLI = new ApolloClient({
  uri: urls.analytics,
});
function GADeviceCategoryAndDataSource(props) {
  const [refetch, setRefetch] = useState(false);
  const [dataDeviceCategory,setDataDeviceCategory]= useState([]);
  const [dataSource, setDataSource] = useState([]);
  useEffect(() => {
    ApploCLI.query({
      query: getGAPlatform,
      context: {
        headers: {
          Authorization: window.localStorage.getItem("token")
            ? `Bearer ${window.localStorage.getItem("token")}`
            : "",
        },
      },
      errorPolicy: "all",
      fetchPolicy: "network-only",
    }).then((data) => {
      if (data.data.gaPlatform) {
        setDataDeviceCategory(data.data.gaPlatform[0].deviceCategory);
      } else {
        setRefetch(!refetch);
      }
    });
  }, []);

  const filteredDeviceCategory = dataDeviceCategory.filter((e) =>
    e
  )
useEffect(() => {
    ApploCLI.query({
      query: getGAPlatform,
      context: {
        headers: {
          Authorization: window.localStorage.getItem("token")
            ? `Bearer ${window.localStorage.getItem("token")}`
            : "",
        },
      },
      errorPolicy: "all",
      fetchPolicy: "network-only",
    }).then((data) => {
      if (data.data.gaPlatform) {
        setDataSource(data.data.gaPlatform[0].dataSource);
      } else {
        setRefetch(!refetch);
      }
    });
  }, []);
  const filteredDataSource = dataSource.filter((e) =>
  e
)
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Google Analytics | Live-Metrics - E-com SaaS</title>
        </MetaTags>
        <div className="container-fluid">
          <Breadcrumbs title="Google Analytics" breadcrumbItem="Device Category & Data Source" pageHeading="Device Category & Data Source" />
          <Row>
            <Col col={6}>
                  <div className="table-responsive">
                    <Table className="table table-striped table-bordered">
                      <thead className="table-light">
                        <tr>
                          <th>{props.t("Device Category Name")}</th>
                          <th>{props.t("Device Category count")}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {filteredDeviceCategory.map((element, index) => (
                          <tr key={index}>
                          <td>{element[0]}</td>
                          <td>{element[1]}</td>
                          </tr>
                          ))
                        }
                      </tbody>
                    </Table>
                  </div>
            </Col>
          </Row>
          <Row>
            <Col col={6}>
                  <div className="table-responsive">
                    <Table className="table table-striped table-bordered">
                      <thead className="table-light">
                        <tr>
                          <th>{props.t("Data Source Name")}</th>
                          <th>{props.t("Data Source count")}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {filteredDataSource.map((element, index) => (
                          <tr key={index}>
                          <td>{element[0]}</td>
                          <td>{element[1]}</td>
                          </tr>
                          ))
                        }
                      </tbody>
                    </Table>
                  </div>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  )
}
export default withRouter(withTranslation() (GADeviceCategoryAndDataSource));
