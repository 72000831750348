import React from 'react';
import { CardBody,  CardHeader, CardTitle,Card  } from 'reactstrap';
import { Pie } from "react-chartjs-2"
import AramexIcon from "./aramex.png"
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";

 function AramexChart({ShipmentChargesPaid,Delivered,ReturnedtoShipper,SupportingDocumentReturnedtoShipper, t }){
    const data = {
        labels: [t("ShipmentChargesPaid"),t("SupportingDocumentReturnedtoShipper"),t("ReturnedtoShipper"),t("Delivered")],
        datasets: [
          {
            data: [ShipmentChargesPaid,SupportingDocumentReturnedtoShipper,ReturnedtoShipper,Delivered],
            backgroundColor: ["#34c38f", "#ebeff2", "#EC1A74", "#F6B43B", "#EA2427", "#3B5B98", "	#000000", "#FF5733", "#76448A", "#0E6655 "],
            hoverBackgroundColor: ["#34c38f", "#ebeff2", "#EC1A74", "#F6B43B", "#EA2427", "#3B5B98", "	#000000", "#FF5733", "#76448A", "#0E6655"],
            hoverBorderColor: "#fff",
          },
        ],
      }
    return (
        <React.Fragment>
              <Card  >
                <CardHeader>
                  <CardTitle className="d-flex">
                    <h4 className="card-title mb-0 flex-grow-1">{t("Aramex Status")}</h4>
                    <img className="img-intgration-icon" src={AramexIcon} />
                    </CardTitle>
                </CardHeader>
                <CardBody>
                  <Pie width={474} height={260} data={data} />
                </CardBody>
              </Card>
        </React.Fragment>
    );
}
AramexChart.propTypes = {
  t: PropTypes.any
};

export default withRouter(withTranslation()(AramexChart));
//export default AramexChart;