import PropTypes from 'prop-types';
import MetaTags from 'react-meta-tags';
import React from 'react';
import { Row, Col, Alert, Container } from 'reactstrap';
import { useSelector, useDispatch } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import logo from '../../assets/images/Live_Metrics_Logo.png';
import CarouselPage from '../AuthenticationInner/CarouselPage';
import { forgetPasswordRequest } from '../../store/auth/actions';

const ForgetPasswordPage = (props) => {
  // const dispatch = useDispatch()

  // const { forgetError, forgetSuccessMsg } = useSelector(state => ({
  //   forgetError: state.ForgetPassword.forgetError,
  //   forgetSuccessMsg: state.ForgetPassword.forgetSuccessMsg,
  // }))
const dispatch = useDispatch();
  function handleSubmit(event) {
    event.preventDefault()
    const email=event.currentTarget.elements.email.value;
    dispatch(forgetPasswordRequest(email));
  }
 
  return (
    <React.Fragment>
      <MetaTags>
        <title>Forget Password | Convergen-Metrics - E-com SaaS</title>
      </MetaTags>
      <div className="auth-page">
        <Container fluid className="p-0">
          <Row className="g-0">
            <Col lg={4} md={5} className="col-xxl-3">
              <div className="auth-full-page-content d-flex p-sm-5 p-4">
                <div className="w-100">
                  <div className="d-flex flex-column h-100">
                    <div className="mb-4 mb-md-5 text-center">
                      <Link to="/dashboard" className="d-block auth-logo">
                        <img src={logo} alt="" height="28" />
                      </Link>
                    </div>
                    <div className="auth-content my-auto">
                      <div className="text-center">
                        <h5 className="mb-0">Reset Password</h5>
                        <p className="text-muted mt-2">
                          Reset Password with Live Metrics.
                        </p>
                      </div>

                      {/* {forgetError && forgetError ? (
                        <Alert color="danger" style={{ marginTop: "13px" }}>
                          {forgetError}
                        </Alert>
                      ) : null}
                      {forgetSuccessMsg ? (
                        <Alert color="success" style={{ marginTop: "13px" }}>
                          {forgetSuccessMsg}
                        </Alert>
                      ) : null} */}

                      {/* <AvForm className="custom-form mt-4">
                        <div className="mb-3">
                          <AvField
                            name="email"
                            label="Email"
                            className="form-control"
                            placeholder="Enter email"
                            type="email"
                            required
                          />
                        </div> */}
                        <form onSubmit={handleSubmit}>
                            <div className="mb-3">
                              <input
                                type="text"
                                name="email"
                                id="email"
                                className="form-control"
                                placeholder="Enter email"
                              />
                            </div>
                        <div className="mb-3 mt-4">
                          <button
                            className="btn btn-primary w-100 waves-effect waves-light"
                            type="submit"
                            onClick={onsubmit}
                          >
                            Reset
                          </button>
                        </div>
                      </form>

                      <div className="mt-5 text-center">
                        <p className="text-muted mb-0">
                          Remember It ?
                          <Link
                            to="/login"
                            className="text-primary fw-semibold"
                          >
                            Sign In
                          </Link>
                        </p>
                      </div>
                    </div>
                    <div className="mt-4 mt-md-5 text-center">
                      <p className="mb-0">
                        © {new Date().getFullYear()} Minia . Crafted with{' '}
                        <i className="mdi mdi-heart text-danger"></i> by
                        Themesbrand
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
            <CarouselPage />
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

ForgetPasswordPage.propTypes = {
  history: PropTypes.object,
};
export default withRouter(ForgetPasswordPage);
