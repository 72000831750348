import React , {useState, useEffect} from "react"
import ReactApexChart from "react-apexcharts"
import ApolloClient from 'apollo-boost';
import { getGAEventCategoryTracking } from "../../../../../queries/GoogleAnalytics/Queries";
import urls from "../../../../../routes/apiUrls";
const ApploCLI = new ApolloClient({
    uri: urls.analytics,
});

const GAEventValueChart = () => {
    const [refetch, setRefetch] = useState(false);
    const [eventValue, setEventValue] = useState([]);
    useEffect(() => {
        ApploCLI.query({
            query: getGAEventCategoryTracking,
            context: {
                headers: {
                    Authorization: window.localStorage.getItem("token")
                        ? `Bearer ${window.localStorage.getItem("token")}`
                        : "",
                },
            },
            errorPolicy: "all",
            fetchPolicy: "network-only", //allow me to refecth data
        }).then((data) => {
            if (data.data.gaeventcategorytracking) {
                setEventValue(data.data.gaeventcategorytracking[0].eventValue);
            } else {
                setRefetch(!refetch);
            }
        });
    }, []);
    const dataMapGATotalEventsName= eventValue.map((item) => { return item[0]});
    const dataMapGATotalEventsCount = eventValue.map((item) => { return parseInt(item[1])});
    const piechartColors = ["#F6B43B", "#EC1A74", "#3B5B98","EA2427"]
    const series = dataMapGATotalEventsCount
    const options = {
        chart: {
            width: 227,
            height: 227,
            type: 'pie',
        },
        labels: dataMapGATotalEventsName,
        colors: piechartColors,
        stroke: {
            width: 0,
        },
        legend: {
            show: false
        },
        responsive: [{
            breakpoint: 480,
            options: {
                chart: {
                    width: 200
                },
            }
        }],
    }
    return (
        <ReactApexChart options={options} series={series} type="pie" height="227" />
    )
}
export default GAEventValueChart;
