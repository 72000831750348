import gql from 'graphql-tag';


const generateUrl = gql`query{
    url{
        url
    }
}
`
const getGooglePlatform = gql`query{
    get{
        id,
        name,
        google_id,
        type,
        active
    }
}
`
const getAccounts = gql`query{
    accounts{
        id,
        account_id,
        selfLink,
        name,
        active
    }
}`

const getProfileAccounts = gql`query{
    profileaccounts{
        id,
        account_id,
        web_prop_id,
        profile_id,
        name,
        active
    }
}`

const integrate = gql`mutation($code:String!){
    integration(code:$code){
        name,
        createdAt
    }
}
`

const toggleGoogle = gql`mutation($adAccount:String!){
    toggle(adAccount:$adAccount){
        active
    }
}
`
const toggleGoogleProfile = gql`mutation($adAccount:String!){
    toggleprofiles(adAccount:$adAccount){
        active
    }
}
`

const deleteGoogle = gql`mutation{
    delete{
        isDeleted
    }
}
`
const getGABrowsers = gql`query{
    browsers{
        salesPlatform,
        profileId,
        data
    }
}`

const getGATrackingPage = gql`query{
    trackingpage{
        salesPlatform,
        profileId,
        entranceRate,
        pageviews,
        pageValue,
        pageviewsPerSession,
        timeOnPage,
        exits,
        exitRate
    }
}`
const getGAUsers =  gql`query{
    gausers{
        salesPlatform,
        profileId,
        sessionsPerUser,
        percentNewSessions,
        users,
        newUsers
    }
}`
const getGAAudience =  gql`query{
    gaaudiences{
        salesPlatform,
        profileId,
        userAgeBracket,
        userGender,
        interestOtherCategory,
        interestAffinityCategory
    }
}`

const getGAEventCategoryTracking =  gql`query{
    gaeventcategorytracking{
        salesPlatform,
        profileId,
        totalEvents,
        eventValue,
        avgValue,
        sessionsWithEvent,
        eventsPerSessionWithEvent
    }
}`

const getGAEventActionTracking =  gql`query{
    gaeventactiontracking{
        salesPlatform,
        profileId,
        totalEvents,
        eventValue,
        avgValue,
        sessionsWithEvent,
        eventsPerSessionWithEvent
    }
}`
const getGAAdWordAdGroup =  gql`query{
    gaadgroupwords{
        salesPlatform,
        profileId,
        impressions,
        clicks,
        cost,
        cpm,
        cpc,
        ctr,
        costPerTransaction,
        costPerGoalConversion,
        costPerConversion,
        rpc,
        roas
    }
}`
const getGAEcommerceAffiliation =  gql`query{
    gaecommerceaffiliations{
        salesPlatform,
        profileId,
        transactions,
        ecommerceConversionRate,
        revenue,
        avgOrderValue,
        perSessionValue,
        shipping,
        tax,
        totalValue,
        itemQuantity,
        revenuePerItem,
        uniquePurchases,
        itemRevenue,
        itemsPerPurchase
    }
}`
const getGAPlatform =  gql`query{
    gaPlatform{
        salesPlatform,
        profileId,
        operatingSystem,
        mobileDeviceBranding,
        deviceCategory,
        dataSource
    }
}`


export { generateUrl, getGooglePlatform, integrate, getAccounts, toggleGoogle, deleteGoogle, getProfileAccounts, toggleGoogleProfile, getGABrowsers, getGATrackingPage, getGAUsers, getGAAudience, getGAEventCategoryTracking, getGAEventActionTracking, getGAAdWordAdGroup, getGAEcommerceAffiliation, getGAPlatform};