import React from "react"
import {
  Row,
  Col,
  Card,
  CardBody,
} from "reactstrap"
import ReactApexChart from "react-apexcharts";
import { default as NumberFormat } from 'react-number-format';
const PrestaShopIcon = `${process.env.PUBLIC_URL}/images/integrations_images/PrestaShop.svg`;

function DataCard({ item, date, loading, getAOV, getLastAOV  }) {
   const start = new Date(date[0].startDate);
   const end = new Date(date[0].endDate);
   const today = new Date(new Date().setHours(0, 0, 0, 0));
   const diff_today_start = Math.abs(today - start) / 1000;
   const diff_today_end = Math.abs(today - end) / 1000;
   const diff = Math.abs(end - start) / 1000;
   let getTotal = (array) => {
     return array.map(Number).reduce((x, y) => { return x + y });
   }
   let getTotalFixed = (array) => {
     return array.map(Number).reduce((x, y) => { return x + y }).toFixed(2);
   }
   let generateSalesChart = () => {
     let processedChart = []
     let lastProcessedChart = []
     if (diff === 0) {
       for (let i = 0; i < item.dates.length; i++) {
         processedChart.push({ x: `${new Date(item.dates[i]).toLocaleString("en", { month: "short", day: "2-digit", hour: "2-digit", minute: "2-digit" })}&nbsp;<span style="font-weight:bold">compared to<span>&nbsp;${new Date(item.lastDates[i]).toLocaleString("en", { month: "short", day: "2-digit", hour: "2-digit", minute: "2-digit" })}`, y: parseFloat(item.series[0].data[0]).toFixed(2) });
       }
       for (let i = 0; i < ((diff_today_start === 0 && diff_today_end === 0) ? item.series[0].data.length : 24); i++) {
         lastProcessedChart.push({ x: `${new Date(item.dates[i]).toLocaleString("en", { month: "short", day: "2-digit", hour: "2-digit", minute: "2-digit" })}&nbsp;<span style="font-weight:bold">compared to<span>&nbsp;${new Date(item.lastDates[i]).toLocaleString("en", { month: "short", day: "2-digit", hour: "2-digit", minute: "2-digit" })}`, y: parseFloat(item.series[1].data[0]).toFixed(2) });
       }
     } else {
       item.series[0].data.map((sales, idx) => {
         processedChart.push({ x: `${new Date(item.dates[idx]).toLocaleString("en", { month: "short", day: "2-digit" })}&nbsp;<span style="font-weight:bold">compared to<span>&nbsp;${new Date(item.lastDates[idx]).toLocaleString("en", { month: "short", day: "2-digit" })}`, y: parseFloat(sales).toFixed(2) });
       })
       item.series[1].data.map((sales, idx) => {
         lastProcessedChart.push({ x: `${new Date(item.dates[idx]).toLocaleString("en", { month: "short", day: "2-digit" })}&nbsp;<span style="font-weight:bold">compared to<span>&nbsp;${new Date(item.lastDates[idx]).toLocaleString("en", { month: "short", day: "2-digit" })}`, y: parseFloat(sales).toFixed(2) })
       })
     }
     return [processedChart, lastProcessedChart];
   }
   let generateGrossProfitChart = () => {
     let processedChart = []
     let lastProcessedChart = []
     if (diff === 0) {
       for (let i = 0; i < item.dates.length; i++) {
         processedChart.push({ x: `${new Date(item.dates[i]).toLocaleString("en", { month: "short", day: "2-digit", hour: "2-digit", minute: "2-digit" })}&nbsp;<span style="font-weight:bold">compared to<span>&nbsp;${new Date(item.lastDates[i]).toLocaleString("en", { month: "short", day: "2-digit", hour: "2-digit", minute: "2-digit" })}`, y: parseFloat(item.series[0].data[0]).toFixed(2) });
       }
       for (let i = 0; i < ((diff_today_start === 0 && diff_today_end === 0) ? item.series[0].data.length : 24); i++) {
         lastProcessedChart.push({ x: `${new Date(item.dates[i]).toLocaleString("en", { month: "short", day: "2-digit", hour: "2-digit", minute: "2-digit" })}&nbsp;<span style="font-weight:bold">compared to<span>&nbsp;${new Date(item.lastDates[i]).toLocaleString("en", { month: "short", day: "2-digit", hour: "2-digit", minute: "2-digit" })}`, y: parseFloat(item.series[1].data[0]).toFixed(2) });
       }
     } else {
       item.series[0].data.map((grossProfit, idx) => {
         processedChart.push({ x: `${new Date(item.dates[idx]).toLocaleString("en", { month: "short", day: "2-digit" })}&nbsp;<span style="font-weight:bold">compared to<span>&nbsp;${new Date(item.lastDates[idx]).toLocaleString("en", { month: "short", day: "2-digit" })}`, y: parseFloat(grossProfit).toFixed(2) });
       })
       // Last period
       item.series[1].data.map((grossProfit, idx) => {
         lastProcessedChart.push({ x: `${new Date(item.dates[idx]).toLocaleString("en", { month: "short", day: "2-digit" })}&nbsp;<span style="font-weight:bold">compared to<span>&nbsp;${new Date(item.lastDates[idx]).toLocaleString("en", { month: "short", day: "2-digit" })}`, y: parseFloat(grossProfit).toFixed(2) })
       })
     }
     return [processedChart, lastProcessedChart];
   }
   let generateAOVChart = () => {
     let processedChart = []
     let lastProcessedChart = []
     if (diff === 0) {
       for (let i = 0; i < item.series[0].data[1].length; i++) {
         processedChart.push({ x: `${new Date(item.dates[i]).toLocaleString("en", { month: "short", day: "2-digit", hour: "2-digit", minute: "2-digit" })}&nbsp;<span style="font-weight:bold">compared to<span>&nbsp;${new Date(item.lastDates[i]).toLocaleString("en", { month: "short", day: "2-digit", hour: "2-digit", minute: "2-digit" })}`, y: getAOV(item.series[0].data[1], item.series[0].data[0][0], diff).toFixed(2) });
       }
       if (item.series[1].data[0].length > 0 && item.series[1].data[1].length > 0) {
         for (let i = 0; i < ((diff_today_start === 0 && diff_today_end === 0) ? item.series[0].data[1].length : 24); i++) {
           lastProcessedChart.push({ x: `${new Date(item.dates[i]).toLocaleString("en", { month: "short", day: "2-digit", hour: "2-digit", minute: "2-digit" })}&nbsp;<span style="font-weight:bold">compared to<span>&nbsp;${new Date(item.lastDates[i]).toLocaleString("en", { month: "short", day: "2-digit", hour: "2-digit", minute: "2-digit" })}`, y: getAOV(item.series[1].data[1], item.series[1].data[0][0], diff).toFixed(2) });
         }
       }
     } else {
       item.series[0].data[1].map((aovValue, idx) => {
         processedChart.push({ x: `${new Date(item.dates[idx]).toLocaleString("en", { month: "short", day: "2-digit" })}&nbsp;<span style="font-weight:bold">compared to<span>&nbsp;${new Date(item.lastDates[idx]).toLocaleString("en", { month: "short", day: "2-digit" })}`, y: getAOV(aovValue, item.series[0].data[0][idx], diff).toFixed(2) });
       })
       item.series[1].data[1].map((aovValue, idx) => {
         lastProcessedChart.push({ x: `${new Date(item.dates[idx]).toLocaleString("en", { month: "short", day: "2-digit" })}&nbsp;<span style="font-weight:bold">compared to<span>&nbsp;${new Date(item.lastDates[idx]).toLocaleString("en", { month: "short", day: "2-digit" })}`, y: getAOV(aovValue, item.series[1].data[0][idx], diff).toFixed(2) })
       })
     }
     return [processedChart, lastProcessedChart];
   }
   let generateNumberOfOrdersChart = () => {
     let processedChart = []
     let lastProcessedChart = []
     if (diff === 0) {
       for (let i = 0; i < item.dates.length; i++) {
         processedChart.push({ x: `${new Date(item.dates[i]).toLocaleString("en", { month: "short", day: "2-digit", hour: "2-digit", minute: "2-digit" })}&nbsp;<span style="font-weight:bold">compared to<span>&nbsp;${new Date(item.lastDates[i]).toLocaleString("en", { month: "short", day: "2-digit", hour: "2-digit", minute: "2-digit" })}`, y: parseFloat(item.series[0].data[0]).toFixed(2) });
       }
       for (let i = 0; i < ((diff_today_start === 0 && diff_today_end === 0) ? item.series[0].data.length : 24); i++) {
         lastProcessedChart.push({ x: `${new Date(item.dates[i]).toLocaleString("en", { month: "short", day: "2-digit", hour: "2-digit", minute: "2-digit" })}&nbsp;<span style="font-weight:bold">compared to<span>&nbsp;${new Date(item.lastDates[i]).toLocaleString("en", { month: "short", day: "2-digit", hour: "2-digit", minute: "2-digit" })}`, y: parseFloat(item.series[1].data[0]).toFixed(2) });
       }
     } else {
       item.series[0].data.map((nmbrOfOrders, idx) => {
         processedChart.push({ x: `${new Date(item.dates[idx]).toLocaleString("en", { month: "short", day: "2-digit" })}&nbsp;<span style="font-weight:bold">compared to<span>&nbsp;${new Date(item.lastDates[idx]).toLocaleString("en", { month: "short", day: "2-digit" })}`, y: parseFloat(nmbrOfOrders).toFixed(2) });
       })
       item.series[1].data.map((nmbrOfOrders, idx) => {
         lastProcessedChart.push({ x: `${new Date(item.dates[idx]).toLocaleString("en", { month: "short", day: "2-digit" })}&nbsp;<span style="font-weight:bold">compared to<span>&nbsp;${new Date(item.lastDates[idx]).toLocaleString("en", { month: "short", day: "2-digit" })}`, y: parseFloat(nmbrOfOrders).toFixed(2) })
       })
     }
     return [processedChart, lastProcessedChart];
   }
  return (
    <div className="dashboardMinorCardItem">
    {
      (item.name === 'sales' || item.name === 'sales') &&
      <Card className="card-h-100">
        <CardBody>
          <div className="my-0 text-primary d-flex justify-content-between align-items-center">
            <span className="my-0 text-primary">
              {item.name}
            </span>
            <img className="img-intgration-icon" src={PrestaShopIcon} />
          </div>
          <Row className="align-items-center">
            <Col xs={6}>
              <div className="mb-3">
                {
                  loading ?
                    <NumberFormat value={item.series[0].data.length > 0 && getTotalFixed(item.series[0].data)} decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={'TND'} renderText={value => <h4>{value}</h4>} />
                    : ""
                }
              </div>
            </Col>
            <Col xs={6}>
              <ReactApexChart
                options={item.options}
                series={item.series[1].data.length > 0 ? [{ name: item.name, data: generateSalesChart()[0] }, { name: "Compared To", data: generateSalesChart()[1] }] : [{ name: item.name, data: generateSalesChart()[0] }]}
                type="line"
                width={"100%"}
                height={60}
              />
            </Col>
            <div className="text-nowrap h4">
              {
                loading ?
                  item.series[1].data.length > 0 ?
                    getTotal(item.series[0].data) > getTotal(item.series[1].data) ?
                      <span className="badge badge-soft-success text-success">{item.series[0].data.length > 0 && ((getTotal(item.series[0].data) - getTotal(item.series[1].data)) / Math.abs(getTotal(item.series[0].data)) * 100).toFixed(2)}%</span>
                      :
                      <span className="badge badge-soft-danger text-danger ">{item.series[0].data.length > 0 && ((getTotal(item.series[0].data) - getTotal(item.series[1].data)) / Math.abs(getTotal(item.series[0].data)) * 100).toFixed(2)}%</span>
                    : "-"
                  : ""
              }
            </div>
          </Row>
        </CardBody>
      </Card>
    }
    {
      (item.name === 'GrossProfit' || item.name === 'GrossProfit') &&
      <Card className="card-h-100">
        <CardBody>
          <div className="my-0 text-primary d-flex justify-content-between align-items-center">
            <span className="my-0 text-primary">
              {item.name}
            </span>
            <img className="img-intgration-icon" src={PrestaShopIcon} />
          </div>
          <Row className="align-items-center">
            <Col xs={6}>
              <div className="mb-3">
                {
                  loading ?
                    <NumberFormat value={item.series[0].data.length > 0 && getTotalFixed(item.series[0].data)} decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={'TND'} renderText={value => <h4>{value}</h4>} />
                    : ""
                }
              </div>
            </Col>
            <Col xs={6}>
              <ReactApexChart
                options={item.options}
                series={item.series[1].data.length > 0 ? [{ name: item.name, data: generateGrossProfitChart()[0] }, { name: "Compared To", data: generateGrossProfitChart()[1] }] : [{ name: item.name, data: generateGrossProfitChart()[0] }]}
                type="line"
                width={"100%"}
                height={60}
              />
            </Col>
            <div className="text-nowrap h4">
              {
                loading ?
                  item.series[1].data.length > 0 ?
                    getTotal(item.series[0].data) > getTotal(item.series[1].data) ?
                      <span className="badge badge-soft-success text-success">{item.series[0].data.length > 0 && ((getTotal(item.series[0].data) - getTotal(item.series[1].data)) / Math.abs(getTotal(item.series[0].data)) * 100).toFixed(2)}%</span>
                      :
                      <span className="badge badge-soft-danger text-danger ">{item.series[0].data.length > 0 && ((getTotal(item.series[0].data) - getTotal(item.series[1].data)) / Math.abs(getTotal(item.series[0].data)) * 100).toFixed(2)}%</span>
                    : "-"
                  : ""
              }
            </div>
          </Row>
        </CardBody>
      </Card>
    }
    {
      (item.name === 'AOV' || item.name === 'AOV') &&
      <Card className="card-h-100">
        <CardBody>
          <div className="my-0 text-primary d-flex justify-content-between align-items-center">
            <span className="my-0 text-primary">
              <i class="fa fa-info-circle text-primary" aria-hidden="true" data-tooltip="Average Order Value"></i>{" "}
              {item.name}
            </span>
            <img className="img-intgration-icon" src={PrestaShopIcon} />
          </div>
          <Row className="align-items-center">
            <Col xs={6}>
              <div className="mb-3">
                {
                  loading ?
                    (isFinite(getAOV(item.series[0].data[1], item.series[0].data[0])) ? <NumberFormat value={getAOV(item.series[0].data[1], item.series[0].data[0])} decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={'TND'} renderText={value => <h4>{value}</h4>} /> : "No AOV")
                    : ""
                }
              </div>
            </Col>
            <Col xs={6}>
              <ReactApexChart
                options={item.options}
                series={item.series[1].data.length > 0 ? [{ name: item.name, data: generateAOVChart()[0] }, { name: "Compared To", data: generateAOVChart()[1] }] : [{ name: item.name, data: generateAOVChart()[0] }]}
                type="line"
                 width={"100%"}
                height={60}
              />
            </Col>
            <div className="text-nowrap h4">
              {
                loading ?
                  item.series[1].data[0].length > 0 ?
                    getLastAOV(item.series[1].data[1], item.series[1].data[0], diff) > getAOV(item.series[0].data[1], item.series[0].data[0], diff) ?
                      <span className="badge badge-soft-danger text-danger ">{(((getAOV(item.series[0].data[1], item.series[0].data[0], diff) - getLastAOV(item.series[1].data[1], item.series[1].data[0], diff)) / Math.abs(getAOV(item.series[0].data[1], item.series[0].data[0], diff))) * 100).toFixed(1)}%</span>
                      :
                      <span className="badge badge-soft-success text-success">{(((getAOV(item.series[0].data[1], item.series[0].data[0], diff) - getLastAOV(item.series[1].data[1], item.series[1].data[0], diff)) / Math.abs(getAOV(item.series[0].data[1], item.series[0].data[0], diff))) * 100).toFixed(1)}%</span>
                    : "-"
                  : ''
              }
            </div>
          </Row>
        </CardBody>
      </Card>
    }
    {
      (item.name === 'NumberofOrders' || item.name === 'NumberofOrders') &&
      <Card className="card-h-100">
        <CardBody>
          <div className="my-0 text-primary d-flex justify-content-between align-items-center">
            <span className="my-0 text-primary">
              {item.name}
            </span>
            <img className="img-intgration-icon" src={PrestaShopIcon} />
          </div>
          <Row className="align-items-center">
            <Col xs={6}>
              <div className="mb-3">
                {
                  loading ?
                    <h4>{item.series[0].data.length > 0 && getTotal(item.series[0].data)}</h4>
                    : ""
                }
              </div>
            </Col>
            <Col xs={6}>
              <ReactApexChart
                options={item.options}
                series={item.series[1].data.length > 0 ? [{ name: item.name, data: generateNumberOfOrdersChart()[0] }, { name: "Compared To", data: generateNumberOfOrdersChart()[1] }] : [{ name: item.name, data: generateNumberOfOrdersChart()[0] }]}
                type="line"
                width={"100%"}
                height={60}
              />
            </Col>
            <div className="text-nowrap h4">
              {
                loading ?
                  item.series[1].data.length > 0 ?
                    getTotal(item.series[0].data) > getTotal(item.series[1].data) ?
                      <span className="badge badge-soft-success text-success">{item.series[0].data.length > 0 && ((getTotal(item.series[0].data) - getTotal(item.series[1].data)) / Math.abs(getTotal(item.series[0].data)) * 100).toFixed(2)}%</span>
                      :
                      <span className="badge badge-soft-danger text-danger ">{item.series[0].data.length > 0 && ((getTotal(item.series[0].data) - getTotal(item.series[1].data)) / Math.abs(getTotal(item.series[0].data)) * 100).toFixed(2)}%</span>
                    : "-"
                  : ""
              }
            </div>
          </Row>
        </CardBody>
      </Card>
    }
  </div >
  )
}
export default DataCard; 
