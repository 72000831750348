import React from "react"
import ReactApexChart from "react-apexcharts"
import { useSelector, useDispatch } from 'react-redux';
import { withRouter } from "react-router-dom"
import { withTranslation } from "react-i18next"
import PropTypes from "prop-types";
const Conversionchart = (props) => {
    const { DataDashboard } = useSelector((state) => state.GoogleAds);
    const conversion = DataDashboard.DataDashboard.reports.map((item) => item.conversions.toFixed(2));
    const date = DataDashboard.DataDashboard.reports.map((item) => item.date);
  const series = [
    {
      name: props.t("Conversion"),
      data: conversion,
    },
  
   
  ]

  const options = {
    chart: {
      toolbar: {
        show: false
      }
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
      width: 2,
    },

    colors: ['#0044BD', '#F6B43B'],
    xaxis: {
      type: "category",
      categories: DataDashboard.DataDashboard?.reports.map((item) => {
        const date = new Date(item.date);
        const month = date.toLocaleDateString('fr-FR', { month: 'short' }); // Use 'short' for abbreviated month name
        return `${date.getDate()}${month}`;
      }),
      labels: {
        trim: false, // Display all labels without trimming
      },
    },
    grid: {
      borderColor: "#f1f1f1",
    },
    tooltip: {
      x: {
        format: "dd/MM",
      },
    },
  }

  return (
    <ReactApexChart
      options={options}
      series={series}
      type="line"
      height="350"
    />
  )
}
Conversionchart.propTypes = {
  t: PropTypes.any
};
export default withRouter(withTranslation()(Conversionchart));

//export default Conversionchart