import {
    INTEGRATE_GOOGLE_ANALYTICS_REQUEST,
    INTEGRATE_GOOGLE_ANALYTICS_SUCCESS,
    INTEGRATE_GOOGLE_ANALYTICS_FAILURE
} from './actionsTypes';

//INTEGRATE GOOGLE ANALYTICS
export const integrateGoogleAnalyticsRequest = (code) => ({
    type: INTEGRATE_GOOGLE_ANALYTICS_REQUEST,
    payload: { code }
});

export const integrateGoogleAnalyticsSuccess = (data) => ({
    type: INTEGRATE_GOOGLE_ANALYTICS_SUCCESS,
    payload:  data 
});

export const integrateGoogleAnalyticsFailure = (error) => ({
    type: INTEGRATE_GOOGLE_ANALYTICS_FAILURE,
    payload:  error 
});