import React, { useState, useEffect, useRef } from 'react';
import { Card } from "semantic-ui-react";
import { getInformations } from "../../queries/Reports/PnLQuery";
import urls from "../../routes/apiUrls";
import CountUp from "react-countup";
import { withTranslation } from "react-i18next"
import PropTypes from 'prop-types';
import { withRouter } from "react-router-dom"
import { Row, Col, CardBody, Alert, Badge } from 'reactstrap';
import classnames from 'classnames'
import { Star, ShoppingCart, DollarSign, Heart, Share2, Facebook, Twitter, Youtube, Instagram, Circle } from 'react-feather'
import ReactApexChart from "react-apexcharts";
import dolla from '../../assets/images/dol3.png';
const GoogleAdsIcon = `${process.env.PUBLIC_URL}/images/integrations_images/GoogleADS.svg`;
const FacebookAdsIcon = `${process.env.PUBLIC_URL}/images/integrations_images/AD8.svg`;
const WooCommercepIcon = `${process.env.PUBLIC_URL}/images/integrations_images/WooCommerce.svg`;

const shoppingIcon = `${process.env.PUBLIC_URL}/images/integrations_images/shop1.png`;
const shoppincardgIcon = `${process.env.PUBLIC_URL}/images/integrations_images/Average Order.png`;
const orderIcon = `${process.env.PUBLIC_URL}/images/integrations_images/Total Orders.png`;
const MerIcon = `${process.env.PUBLIC_URL}/images/integrations_images/merr.png`;
const TotaladSpendIcon = `${process.env.PUBLIC_URL}/images/integrations_images/Totadsspend.png`;
const totaladsRevenueIcon = `${process.env.PUBLIC_URL}/images/integrations_images/totadsrev.png`;




function StoreOverview({ data, handleClick, storecurrency }) {
  const formatNumberWithSpaces = (value) => {
    if (value !== undefined && value !== null) {
      return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    }
    return ""; // or any default value you want to return for undefined/null cases
  };

  const handleBadgeClick = () => {
    // This function gets triggered when the badge is clicked
    handleClick(true); // Pass the value to the parent component
  };
  //spend
  const googleCost = data.GoogleMetrics ? data.GoogleMetrics.totalCost : 0;
  const facebookCost = data.facebookMetrics ? data.facebookMetrics.totalCost : 0;
  const Globalspend = googleCost + facebookCost;
  // // Revenue
  const googleRevenue = data.GoogleMetrics ? data.GoogleMetrics.totalconversionValue : 0;
  const facebookRevenue = data.facebookMetrics ? data.facebookMetrics.totalconversionValue : 0;
  const globalRevenue = googleRevenue + facebookRevenue;

  const currency = storecurrency ? storecurrency.selectedOptionValue : "TND";
  const MER = data?.store?.total_sales / (Globalspend * storecurrency.conversion_rate);
  return (
    <div className="mt-5">
      <div className="d-flex flex-wrap align-items-center mb-4">
        <h5 className="card-title me-2" style={{ marginTop: '-24px', marginLeft: '25px' }}>Overall Overview </h5>

      </div>

      <div>
        <Col  >
          <Row>
            {data.store ? (
              <>
                <Col xl={3}>
                  <Card className="card-h-100">
                    <CardBody className="facebook-ads-card">
                      <div className="d-flex flex-column align-items-center">
                        <span className="my-0 text-primary mb-2">
                          <i className="fa fa-info-circle text-primary" aria-hidden="true" data-tooltip="Website organic"></i>{" "}
                          <strong>Total Sales</strong>
                        </span>
                        <div className="mb-3">
                          <img className="img-integration-icon" src={shoppingIcon} alt="Shopping Icon" />
                        </div>
                        <Row className="align-items-center">
                          <Col xs={12}>
                            <div className="text-center">
                              <h4 className="mb-0">
                                {formatNumberWithSpaces(data?.store?.total_sales?.toFixed(2))}{currency}
                              </h4>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
                <Col xl={3}>
                  <Card className="card-h-100">
                    <CardBody className="facebook-ads-card">
                      <div className="d-flex flex-column align-items-center">
                        <span className="my-0 text-primary mb-2">
                          <i className="fa fa-info-circle text-primary" aria-hidden="true" data-tooltip="Website organic"></i>{" "}
                          <strong>Average Order Value</strong>
                        </span>
                        <div className="mb-3">
                          <img className="img-integration-icon" src={shoppincardgIcon} alt="Shopping Card Icon" />
                        </div>
                        <Row className="align-items-center">
                          <Col xs={12}>
                            <div className="text-center">
                              <h4 className="mb-0">
                                {data?.store?.aov?.toFixed(2)}{currency}
                              </h4>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </CardBody>
                  </Card>

                </Col>
                <Col xl={3}>
                  <Card className="card-h-100">
                    <CardBody className="facebook-ads-card">
                      <div className="d-flex flex-column align-items-center">
                        <span className="my-0 text-primary mb-2">
                          <i className="fa fa-info-circle text-primary" aria-hidden="true" data-tooltip="Website organic"></i>{" "}
                          <strong>Total Orders</strong>
                        </span>
                        <div className="mb-3">
                        <img className="img-integration-icon" src={orderIcon} alt="Order Icon" />
                        </div>
                        <Row className="align-items-center">
                          <Col xs={12}>
                            <div className="text-center">
                              <h4 className="mb-0">
                                {data?.store?.total_orders}
                              </h4>
                            </div>
                          </Col>
                        </Row>
                        <Badge className="bg-success mt-3">
                          {data?.store?.delivred_orders} Delivered
                        </Badge>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </>
            ) : (
              null
            )}
            {data.store ? (
              <Col xl={3}>
                <Card className="card-h-100 bg-yellow">
                  <CardBody className="facebook-ads-card">
                    <div className="d-flex flex-column align-items-center">
                      <span className="my-0 text-primary mb-2">
                        <i className="fa fa-info-circle text-primary" aria-hidden="true" data-tooltip="Website organic"></i>{" "}
                        <strong>MER</strong>
                      </span>
                      <div className="mb-3">
                        <img className="img-integration-icon" src={MerIcon} alt="WooCommerce Icon" />
                      </div>
                      <Row className="align-items-center">
                        <Col xs={12}>
                          <div className="text-center">
                            {isNaN(MER) ? "-" : MER === Infinity ? (

                              <button
                                type="button"
                                className="btn btn-warning waves-effect waves-light"
                                onClick={handleBadgeClick}
                              >
                                <i className="bx bx-error text-white font-size-16 align-middle me-2"></i> select currency
                              </button>


                            ) : (
                              <span className="h4" style={{ fontSize: '1.5em' }}>
                                {MER?.toFixed(2)}
                              </span>
                            )}
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            ) : (
              null
            )
            } 
            <Row className='justify-content-center'>
            <Col xl={4}>
              <Card className="card-h-100 bg-yellow">
                <CardBody className="facebook-ads-card">
                  <div className="d-flex flex-column align-items-center">
                    <span className="my-0 text-primary mb-2">
                      <i className="fa fa-info-circle text-primary" aria-hidden="true" data-tooltip="Website organic"></i>{" "}
                      <strong>Total Ads Spend</strong>
                    </span>
                    <div className="mb-3">
                      <img className="img-integration-icon" src={TotaladSpendIcon} alt="WooCommerce Icon" />
                    </div>
                    <Row className="align-items-center">
                      <Col xs={12}>
                        <div className="text-center">
                          <h4 className="mb-0">
                            {formatNumberWithSpaces(Globalspend.toFixed(2))}$
                          </h4>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </CardBody>
              </Card>

            </Col>
            <Col xl={4}>
              <Card className="card-h-100 bg-yellow">
                <CardBody className="facebook-ads-card">
                  <div className="d-flex flex-column align-items-center">
                    <span className="my-0 text-primary mb-2">
                      <i className="fa fa-info-circle text-primary" aria-hidden="true" data-tooltip="Website organic"></i>{" "}
                      <strong>Total Ads Revenue</strong>
                    </span>
                    <div className="mb-3">
                      <img className="img-integration-icon" src={totaladsRevenueIcon} alt="WooCommerce Icon" />
                    </div>
                    <Row className="align-items-center">
                      <Col xs={12}>
                        <div className="text-center">
                          <h4 className="mb-0">
                            {formatNumberWithSpaces(globalRevenue.toFixed(2))}$
                          </h4>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </CardBody>
              </Card>

            </Col>
            </Row>
            
          </Row>

        </Col>


      </div>
    </div>
  )
}
StoreOverview.propTypes = {
  t: PropTypes.any
};
export default withRouter(withTranslation()(StoreOverview));

