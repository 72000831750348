import React, { useState, useEffect, useContext } from 'react';
import { checkSales } from '../../../queries/Aramex/Queries';
import { deleteIntegrationAramexRequest, integrationAramexRequest } from '../../../store/aramexState/action'
import ApolloClient from "apollo-boost";
import urls from "../../../routes/apiUrls";
import { CardTitle, Card, CardBody, Col, Container, Row, Input, Modal, Label, Button, ModalBody } from "reactstrap";
import { useDispatch, useSelector } from 'react-redux';
import amrex from '../Aramex/aramex.png';
import amrexx from '../Aramex/aramexx.png';
import { deleteAlert } from '../../Alerts/NotLinkedAccountAlert';
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { ShepherdTour, ShepherdTourContext } from 'react-shepherd'
import Select from 'react-select'
//*styles
import 'shepherd.js/dist/css/shepherd.css'
// import '@styles/react/libs/shepherd-tour/shepherd-tour.scss'

import '../../../assets/scss/shepherd-tour/shepherd-tour.scss'


const ApploCLI = new ApolloClient({
  uri: urls.Aramex,
});

const backBtnClass = 'btn btn-sm btn-outline-primary',
  nextBtnClass = 'btn btn-sm btn-primary btn-next'

let instance = null



const AramexCard = (props) => {
  const steps = [
    {
      id: 'username',
      title: props.t('Username'),
      text: props.t('Insert your username'),
      attachTo: { element: '.form-control-username-tour', on: 'bottom' },
      cancelIcon: {
        enabled: true
      },
      buttons: [
        {
          action: () => instance.cancel(),
          classes: backBtnClass,
          text: props.t('Skip')
        },
        {
          text: props.t('Next'),
          classes: nextBtnClass,
          action: () => instance.next()
        }
      ]
    },
    {
      id: 'password',
      title: props.t('Password'),
      text: props.t('Insert your password'),
      attachTo: { element: '.pwd-tour', on: 'top' },
      cancelIcon: {
        enabled: true
      },
      buttons: [
        {
          text: props.t('Skip'),
          classes: backBtnClass,
          action: () => instance.cancel()
        },
        {
          text: props.t('Back'),
          classes: backBtnClass,
          action: () => instance.back()
        },
        {
          text: props.t('Next'),
          classes: nextBtnClass,
          action: () => instance.next()
        }
      ]
    },
    {
      id: 'Account Number',
      title: props.t('Account Number'),
      text: props.t('Insert your Account Number'),
      attachTo: { element: '.Anumber-tour', on: 'top' },
      cancelIcon: {
        enabled: true
      },
      buttons: [
        {
          text: props.t('Skip'),
          classes: backBtnClass,
          action: () => instance.cancel()
        },
        {
          text: props.t('Back'),
          classes: backBtnClass,
          action: () => instance.back()
        },
        {
          text: props.t('Next'),
          classes: nextBtnClass,
          action: () => instance.next()
        }
      ]
    },
    {
      id: 'Account PIN',
      title: props.t('Account PIN'),
      text: props.t('Insert your Account PIN'),
      attachTo: { element: '.acpin-tour', on: 'top' },
      cancelIcon: {
        enabled: true
      },
      buttons: [
        {
          text: props.t('Skip'),
          classes: backBtnClass,
          action: () => instance.cancel()
        },
        {
          text: props.t('Back'),
          classes: backBtnClass,
          action: () => instance.back()
        },
        {
          text: props.t('Next'),
          classes: nextBtnClass,
          action: () => instance.next()
        }
      ]
    },

    {
      id: 'Account Entity',
      title: props.t('Account Entity'),
      text: props.t('Choose your Account Entity'),
      attachTo: { element: '.AEnti-tour', on: 'top' },
      cancelIcon: {
        enabled: true
      },
      buttons: [
        {
          text: props.t('Skip'),
          classes: backBtnClass,
          action: () => instance.cancel()
        },
        {
          text: props.t('Back'),
          classes: backBtnClass,
          action: () => instance.back()
        },
        {
          text: props.t('Next'),
          classes: nextBtnClass,
          action: () => instance.next()
        }
      ]
    },
    {
      id: 'Account Country Code',
      title: props.t('Account Country Code'),
      text: props.t('Choose your Account Country Code'),
      attachTo: { element: '.countryC-tour', on: 'top' },
      cancelIcon: {
        enabled: true
      },
      buttons: [
        {
          text: props.t('Skip'),
          classes: backBtnClass,
          action: () => instance.cancel()
        },
        {
          text: props.t('Back'),
          classes: backBtnClass,
          action: () => instance.back()
        },
        {
          text: props.t('Next'),
          classes: nextBtnClass,
          action: () => instance.next()
        }
      ]
    },
    {
      id: 'connect',
      title: props.t('connect'),
      text: props.t('connect your Aramex store'),
      attachTo: { element: '.modal-footer-GC', on: 'top' },
      cancelIcon: {
        enabled: true
      },
      buttons: [
        {
          text: props.t('Back'),
          classes: backBtnClass,
          action: () => instance.back()
        },
        {
          text: props.t('Finish'),
          classes: nextBtnClass,
          action: () => instance.cancel()
        }
      ]
    }

  ]
  const dispatch = useDispatch();

  const { data, checkIntegrationdata } = useSelector((state) => state.aramex);
  console.log('aramex integration  card', checkIntegrationdata, data)
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [modal_standard, setmodal_standard] = useState(false);
  const [UserName, setUserName] = useState("");
  const [Password, setPassword] = useState("");
  const [AccountNumber, setAccountNumber] = useState("");
  const [AccountPin, setAccountPin] = useState("");
  const [AccountEntity, setAccountEntity] = useState("");
  const [AccountCountryCode, setAccountCountryCode] = useState("");
  const [error, setError] = useState("");
  const [info, setInfo] = useState("");
  const [deleted, setDeleted] = useState(false);

  const accountCountryCodeOptions = [
    { value: 'TN', label: 'TN' },
    { value: 'MAR', label: 'MAR' },
    { value: 'DZ', label: 'DZ' }
  ]
  function tog_standard() {
    setmodal_standard(!modal_standard)
  }
  //not chaning this  useffect to redux cause i already have checksales  from page  dashboard
  useEffect(() => {
    let isSubscribed = true
    ApploCLI.query({
      query: checkSales,
      context: {
        headers: {
          Authorization: window.localStorage.getItem("token") ? `Bearer ${window.localStorage.getItem("token")}` : ""
        }
      },
      errorPolicy: "all",
      fetchPolicy: "network-only"
    }).then((data) => {
      if (isSubscribed && data.data.get != null) {
        if (!data.data.get.isDeleted && data.data.get.active) {
          setInfo(data.data.get.active);
          console.log("data.data.get", data.data.get)
        } else {
          setInfo(data.data.get.isDeleted);
        }
        setDeleted(true);
        setUserName(data.data.get.UserName)
        setAccountCountryCode(data.data.get.AccountCountryCode)
        setAccountEntity(data.data.get.AccountEntity)
        setAccountNumber(data.data.get.AccountNumber)
        setAccountPin(data.data.get.AccountPin)
        setPassword(data.data.get.Password)

      }
    }).catch(err => console.log("Aramex  checkSales", err));
    return () => (isSubscribed = false)
  }, [])
  function handleSelectGroup(accountCountryCodeOptions) {
    setAccountCountryCode(accountCountryCodeOptions.value)
  }
  const onSubmitForm = async (e) => {
  }
  let integration = () => {
    if (UserName === "") {
      setError("UserName  is required!")
      return;
    }
    setError("");
    console.log("Consumer Key is", UserName)
    dispatch(integrationAramexRequest(UserName, Password, AccountNumber, AccountPin, AccountEntity, AccountCountryCode))
  }

  const deleteConnection = () => {
    deleteAlert('Aramex').then(function (e) {
      if (e.isConfirmed) {
        dispatch(deleteIntegrationAramexRequest())
      }
    })
  }
  // this tour guide 
  const Content = () => {
    const tour = useContext(ShepherdTourContext)

    instance = tour


    return (
      <Col className='text-center mt-2 pt-50'>
        <Button color='primary' className='me-1' onClick={() => tour.start()} outline>
          Guide Tour
        </Button>
      </Col>
    )
  }
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row className='gy-1 pt-75'>
            <Col>
              <Card className="border text-secondary">
                <CardBody>
                  <div className="my-0 text-primary d-flex justify-content-between align-items-center">
                    <div className='text-left d-flex align-items-center'>
                      <img className="img-intgration-icon" src={amrex} />
                      <CardTitle className="text-primary h4 mb-0 ml-2">Aramex</CardTitle>
                    </div>

                    <div className="d-flex">
                      <button
                        className={info ? "btn btn-primary mr-2" : "btn btn-primary"}
                        onClick={() => { tog_standard() }}
                        data-toggle="modal"
                        data-target="#myModal"
                      >
                        {info ? (
                          <i className="bx bx-pencil font-size-16 align-middle text-white"></i>
                        ) : (
                          props.t("Connect")
                        )}
                      </button>

                      {deleted ? (

                        <button style={{ marginLeft: '10px' }}
                          type="button"
                          className="btn btn-danger waves-effect waves-light "
                          onClick={() => deleteConnection()}
                        >
                          <i className="bx bx-block font-size-16 align-middle text-white"></i>
                        </button>

                      ) : null}

                    </div>


                    <Modal isOpen={modal_standard} toggle={() => tog_standard()} className='modal-dialog-centered modal-refer-earn modal-lg'>
                      <ModalBody className='px-sm-5 mx-50 pb-5'>
                        <div className='text-center'>
                          <img style={{ height: '80px' }} src={amrexx} />
                          <h1 className='text-center mb-1'>Aramex</h1>
                          <div className="modal-header">
                            <h5 className="modal-title mt-0" id="myModalLabel">
                              {props.t("Aramex Credentials")}
                            </h5>
                            <button
                              type="button"
                              onClick={() => {
                                setmodal_standard(false)
                              }}
                              className="close"
                              data-dismiss="modal"
                              aria-label="Close"
                            >
                              <span aria-hidden="true">&times;</span>
                            </button>
                          </div>
                        </div>
                        <div className="modal-body">

                          <Label htmlFor="validationTooltip01">{props.t("UserName")}</Label>
                          <Col xs={12}>
                            <Input
                              type="text"
                              className="form-control-username-tour"
                              id="validationTooltip01"
                              placeholder={props.t("UserName")}
                              onChange={e => setUserName(e.target.value)}
                              value={UserName}
                            />
                          </Col>
                          <Col xs={12} className="pwd-tour">
                            <Label htmlFor="validationTooltip01">{props.t("Password")}</Label>
                            <div className='password-aramex-tour'>
                              <Input
                                type="password"
                                className="form-control-pwd"
                                id="validationTooltip01"
                                placeholder={props.t("Password")}
                                onChange={e => setPassword(e.target.value)}
                                value={Password}
                              />
                            </div>
                          </Col>
                          <Row>
                            <Col md={6} xs={12} className="Anumber-tour">
                              <Label htmlFor="validationTooltip01">{props.t("Account Number")}</Label>
                              <Input
                                type="text"
                                className="form-control-Anumber"
                                id="validationTooltip01"
                                placeholder={props.t("Account Number")}
                                onChange={e => setAccountNumber(e.target.value)}
                                value={AccountNumber}
                              />
                            </Col>
                            <Col md={6} xs={12} className="acpin-tour">
                              <Label htmlFor="validationTooltip01">{props.t("AccountPin")}</Label>
                              <Input
                                type="text"
                                className="form-control-acpin"
                                id="validationTooltip01"
                                placeholder="Account Pin"
                                onChange={e => setAccountPin(e.target.value)}
                                value={AccountPin}
                              />
                            </Col>

                            <Col md={6} xs={12} className="AEnti-tour">
                              <Label htmlFor="validationTooltip01">{props.t("AccountEntitiy")}</Label>
                              <Input
                                type="text"
                                className="form-control-AEnti"
                                id="validationTooltip01"
                                placeholder={props.t("AccountEntitiy")}
                                onChange={e => setAccountEntity(e.target.value)}
                                value={AccountEntity}
                              />
                            </Col>
                            <Col md={6} xs={12} className="countryC-tour">
                              <Label htmlFor="validationTooltip01">{props.t("AccountCountryCode")}</Label>
                              <Select
                                id='account-country-code'
                                isClearable={false}
                                className='form-control-countryC'
                                classNamePrefix='select'
                                options={accountCountryCodeOptions}
                                onChange={handleSelectGroup}
                                //onChange={e => setAccountCountryCode(e.target.value)}
                                //theme={selectThemeColors}
                                defaultValue={props.t("AccountCountryCode")}
                              />

                            </Col>
                          </Row>
                        </div>

                        <Col xs={12} className='text-center mt-2 pt-50'>

                          <div className="modal-footer-GC">
                            <button
                              type="button"
                              onClick={() => {
                                tog_standard()
                              }}
                              className="btn btn-secondary "
                              data-dismiss="modal"
                            >
                              {props.t("Close")}
                            </button>
                            <button
                              type="button"
                              className="btn btn-primary"
                              onClick={() => { integration() }}
                            >
                              {props.t("Confirm")}
                            </button>
                          </div>
                        </Col>
                        <ShepherdTour
                          steps={steps}
                          tourOptions={{
                            useModalOverlay: true
                          }}
                        >
                          <Content />
                        </ShepherdTour>
                      </ModalBody>
                    </Modal>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment >
  )
}
AramexCard.propTypes = {
  t: PropTypes.any
};

export default withRouter(withTranslation()(AramexCard));
//export default AramexCard;
