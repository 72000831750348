// FETCH ALL PRESTASHOP PRODUCTS
export const FETCH_PRODUCTS_REQUEST = 'FETCH_PRODUCTS_REQUEST';
export const FETCH_PRODUCTS_SUCCESS = 'FETCH_PRODUCTS_SUCCESS';
export const FETCH_PRODUCTS_FAILURE = 'FETCH_PRODUCTS_FAILURE';

// FETCH ALL PRESTASHOP REFUNDS
export const FETCH_REFUNDS_REQUEST = 'FETCH_REFUNDS_REQUEST';
export const FETCH_REFUNDS_SUCCESS = 'FETCH_REFUNDS_SUCCESS';
export const FETCH_REFUNDS_FAILURE = 'FETCH_REFUNDS_FAILURE';



//delete integration prestaShop
export const DELETE_INTEGRATION_PRESTASHOP_REQUEST = 'DELETE_INTEGRATION_PRESTASHOP_REQUEST';
export const DELETE_INTEGRATION_PRESTASHOP_SUCCESS = 'DELETE_INTEGRATION_PRESTASHOP_SUCCESS';
export const DELETE_INTEGRATION_PRESTASHOP_FAILURE = 'DELETE_INTEGRATION_PRESTASHOP_FAILURE';

// integration prestaShop
export const INTEGRATION_PRESTASHOP_REQUEST = 'INTEGRATION_PRESTASHOP_REQUEST';
export const INTEGRATION_PRESTASHOP_SUCCESS = 'INTEGRATION_PRESTASHOP_SUCCESS';
export const INTEGRATION_PRESTASHOP_FAILURE = 'INTEGRATION_PRESTASHOP_FAILURE';


//get all prestashop stores 
export const FETCH_STORES_REQUEST = 'FETCH_STORES_REQUEST';
export const FETCH_STORES_SUCCESS = 'FETCH_STORES_SUCCESS';
export const FETCH_STORES_FAILURE = 'FETCH_STORES_FAILURE';


//get single prestashop store
export const FETCH_STORE_REQUEST = 'FETCH_STORE_REQUEST';
export const FETCH_STORE_SUCCESS = 'FETCH_STORE_SUCCESS';
export const FETCH_STORE_FAILURE = 'FETCH_STORE_FAILURE';

// check  third step prestashop
export const CHECK_THIRD_STEP_PRESTASHOP_REQUEST = 'CHECK_THIRD_STEP_PRESTASHOP_REQUEST';
export const CHECK_THIRD_STEP_PRESTASHOP_SUCCESS = 'CHECK_THIRD_STEP_PRESTASHOP_SUCCESS';
export const CHECK_THIRD_STEP_PRESTASHOP_FAILURE = 'CHECK_THIRD_STEP_PRESTASHOP_FAILURE';
