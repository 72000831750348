import React from 'react';
import { Card } from "semantic-ui-react";
import { Row, Col, CardBody } from "reactstrap"
const GoogleAnalyticsIcon = `${process.env.PUBLIC_URL}/images/integrations_images/GoogleAnalytics.svg`;
function GAEcommerceAffiliationCard({ loading, item }) {
    let getTotal = (array) => {
        return array.map(Number).reduce((x, y) => { return x + y });
    }
    let getTotalFixed = (array) => {
        return array.map(Number).reduce((x, y) => { return x + y }).toFixed(3);
    }
    return (
        <div className="dashboardMinorCardItem">
            {
                (item.name === 'Transactions' || item.name === 'Transactions') &&
                <Card className="card-h-100">
                    <CardBody>
                        <div className="my-0 text-primary d-flex justify-content-between align-items-center">{item.name}<img className="img-intgration-icon" src={GoogleAnalyticsIcon} /></div>
                        <Row className="align-items-center">
                            <Col xs={6}>
                                <div className="mb-3">
                                    {
                                        loading ?
                                            <h4>{item.series[0].data.length > 0 && getTotal(item.series[0].data)}</h4>
                                            : ""
                                    }
                                </div>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            }
            {
                (item.name === 'Conversion Rate' || item.name === 'Conversion Rate') &&
                <Card className="card-h-100">
                    <CardBody>
                        <div className="my-0 text-primary d-flex justify-content-between align-items-center">{item.name}<img className="img-intgration-icon" src={GoogleAnalyticsIcon} /></div>
                        <Row className="align-items-center">
                            <Col xs={6}>
                                <div className="mb-3">
                                    {
                                        loading ?
                                            <h4>{item.series[0].data.length > 0 && getTotal(item.series[0].data)}</h4>
                                            : ""
                                    }
                                </div>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            }
            {
                (item.name === 'Revenue' || item.name === 'Revenue') &&
                <Card className="card-h-100">
                    <CardBody>
                        <div className="my-0 text-primary d-flex justify-content-between align-items-center">{item.name}<img className="img-intgration-icon" src={GoogleAnalyticsIcon} /></div>
                        <Row className="align-items-center">
                            <Col xs={6}>
                                <div className="mb-3">
                                    {
                                        loading ?
                                            <h4>{item.series[0].data.length > 0 && getTotalFixed(item.series[0].data)}</h4>
                                            : ""
                                    }
                                </div>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            }
            {
                (item.name === 'Revenue Per Transaction' || item.name === 'Revenue Per Transaction') &&
                <Card className="card-h-100">
                    <CardBody>
                        <div className="my-0 text-primary d-flex justify-content-between align-items-center">{item.name}<img className="img-intgration-icon" src={GoogleAnalyticsIcon} /></div>
                        <Row className="align-items-center">
                            <Col xs={6}>
                                <div className="mb-3">
                                    {
                                        loading ?
                                            <h4>{item.series[0].data.length > 0 && getTotalFixed(item.series[0].data)}</h4>
                                            : ""
                                    }
                                </div>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            }
            {
                (item.name === 'Transaction Revenue Per Session' || item.name === 'Transaction Revenue Per Session') &&
                <Card className="card-h-100">
                    <CardBody>
                        <div className="my-0 text-primary d-flex justify-content-between align-items-center">{item.name}<img className="img-intgration-icon" src={GoogleAnalyticsIcon} /></div>
                        <Row className="align-items-center">
                            <Col xs={6}>
                                <div className="mb-3">
                                    {
                                        loading ?
                                            <h4>{item.series[0].data.length > 0 && getTotalFixed(item.series[0].data)}</h4>
                                            : ""
                                    }
                                </div>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            }
            {
                (item.name === 'Transaction Shipping' || item.name === 'Transaction Shipping') &&
                <Card className="card-h-100">
                    <CardBody>
                        <div className="my-0 text-primary d-flex justify-content-between align-items-center">{item.name}<img className="img-intgration-icon" src={GoogleAnalyticsIcon} /></div>
                        <Row className="align-items-center">
                            <Col xs={6}>
                                <div className="mb-3">
                                    {
                                        loading ?
                                            <h4>{item.series[0].data.length > 0 && getTotalFixed(item.series[0].data)}</h4>
                                            : ""
                                    }
                                </div>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            }
            {
                (item.name === 'Transaction Tax' || item.name === 'Transaction Tax') &&
                <Card className="card-h-100">
                    <CardBody>
                        <div className="my-0 text-primary d-flex justify-content-between align-items-center">{item.name}<img className="img-intgration-icon" src={GoogleAnalyticsIcon} /></div>
                        <Row className="align-items-center">
                            <Col xs={6}>
                                <div className="mb-3">
                                    {
                                        loading ?
                                            <h4>{item.series[0].data.length > 0 && getTotalFixed(item.series[0].data)}</h4>
                                            : ""
                                    }
                                </div>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            }
            {
                (item.name === 'Total Value' || item.name === 'Total Value') &&
                <Card className="card-h-100">
                    <CardBody>
                        <div className="my-0 text-primary d-flex justify-content-between align-items-center">{item.name}<img className="img-intgration-icon" src={GoogleAnalyticsIcon} /></div>
                        <Row className="align-items-center">
                            <Col xs={6}>
                                <div className="mb-3">
                                    {
                                        loading ?
                                            <h4>{item.series[0].data.length > 0 && getTotalFixed(item.series[0].data)}</h4>
                                            : ""
                                    }
                                </div>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            }
            {
                (item.name === 'Item Quantity' || item.name === 'Item Quantity') &&
                <Card className="card-h-100">
                    <CardBody>
                        <div className="my-0 text-primary d-flex justify-content-between align-items-center">{item.name}<img className="img-intgration-icon" src={GoogleAnalyticsIcon} /></div>
                        <Row className="align-items-center">
                            <Col xs={6}>
                                <div className="mb-3">
                                    {
                                        loading ?
                                            <h4>{item.series[0].data.length > 0 && getTotal(item.series[0].data)}</h4>
                                            : ""
                                    }
                                </div>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            }
            {
                (item.name === 'Average Price' || item.name === 'Average Price') &&
                <Card className="card-h-100">
                    <CardBody>
                        <div className="my-0 text-primary d-flex justify-content-between align-items-center">{item.name}<img className="img-intgration-icon" src={GoogleAnalyticsIcon} /></div>
                        <Row className="align-items-center">
                            <Col xs={6}>
                                <div className="mb-3">
                                    {
                                        loading ?
                                            <h4>{item.series[0].data.length > 0 && getTotalFixed(item.series[0].data)}</h4>
                                            : ""
                                    }
                                </div>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            }
            {
                (item.name === 'Unique Purchases' || item.name === 'Unique Purchases') &&
                <Card className="card-h-100">
                    <CardBody>
                        <div className="my-0 text-primary d-flex justify-content-between align-items-center">{item.name}<img className="img-intgration-icon" src={GoogleAnalyticsIcon} /></div>
                        <Row className="align-items-center">
                            <Col xs={6}>
                                <div className="mb-3">
                                    {
                                        loading ?
                                            <h4>{item.series[0].data.length > 0 && getTotal(item.series[0].data)}</h4>
                                            : ""
                                    }
                                </div>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            }
            {
                (item.name === 'Product Revenue' || item.name === 'Product Revenue') &&
                <Card className="card-h-100">
                    <CardBody>
                        <div className="my-0 text-primary d-flex justify-content-between align-items-center">{item.name}<img className="img-intgration-icon" src={GoogleAnalyticsIcon} /></div>
                        <Row className="align-items-center">
                            <Col xs={6}>
                                <div className="mb-3">
                                    {
                                        loading ?
                                            <h4>{item.series[0].data.length > 0 && getTotalFixed(item.series[0].data)}</h4>
                                            : ""
                                    }
                                </div>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            }
            {
                (item.name === 'Average Quantity' || item.name === 'Average Quantity') &&
                <Card className="card-h-100">
                    <CardBody>
                        <div className="my-0 text-primary d-flex justify-content-between align-items-center">{item.name}<img className="img-intgration-icon" src={GoogleAnalyticsIcon} /></div>
                        <Row className="align-items-center">
                            <Col xs={6}>
                                <div className="mb-3">
                                    {
                                        loading ?
                                            <h4>{item.series[0].data.length > 0 && getTotalFixed(item.series[0].data)}</h4>
                                            : ""
                                    }
                                </div>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            }
        </div >
    )
}
export default GAEcommerceAffiliationCard;
