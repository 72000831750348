import React from "react"
import ReactApexChart from "react-apexcharts"
import { useSelector, useDispatch } from 'react-redux';
const Spinearea = () => {
    const { dataDashboard } = useSelector((state) => state.facebookAds);
    const spent = dataDashboard.reports.map((item) => item.spend.toFixed(2));
    const Revenue = dataDashboard.reports.map((item) => item.conversions_value.toFixed(2));
    const date = dataDashboard.reports.map((item) => item.date);
    console.log(spent)
  const series = [
    {
      name: "Spent",
      data: spent,
    },
    {
      name:"Revenue",
      data: Revenue,
    }
   
  ]

  const options = {
    chart: {
      toolbar: {
        show: false
      }
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
      width: 2,
    },

    colors: ["#0044BD", "#F6B43B"],
    xaxis: {
      type: "category",
      categories: dataDashboard?.reports.map((item) => {
        const date = new Date(item.date);
        const month = date.toLocaleDateString('fr-FR', { month: 'short' }); // Use 'short' for abbreviated month name
        return `${date.getDate()}${month}`;
      }),
      labels: {
        trim: false, // Display all labels without trimming
      },
    },
    grid: {
      borderColor: "#f1f1f1",
    },
    tooltip: {
      x: {
        format: "dd/MM",
      },
    },
  }

  return (
    <ReactApexChart
      options={options}
      series={series}
      type="bar"
      height="350"
    />
  )
}

export default Spinearea