import React from 'react';
import { Row } from 'reactstrap';
import GoogleAdsCard from './GoogleAds/GoogleAdsCard';
import FacebookAdsCard from './FacebookAds/FacebookAdsCard';
import FacebookSocialMediaCard from './FacebookSocialMedia/FacebookSocialMediaCard';
import InstagramCard from './Instagram/InstagramCard';

const AdsPlatformsCatalog = () => (
  <Row xl={3} sm={6}> 
   <GoogleAdsCard/>
   <FacebookAdsCard/>
   <FacebookSocialMediaCard />
   <InstagramCard />
  </Row>
);
export default AdsPlatformsCatalog;
