import React, { useEffect } from "react";
import MetaTags from "react-meta-tags";
import { Link, withRouter,useLocation } from "react-router-dom";
import { Card, CardBody, Col, Container, Row, Table } from "reactstrap";
import { isEmpty, map } from "lodash";
import iconlivemetrics from "../../assets/images/Live_Metrics_favicon.svg"
import livemetrics from "../../assets/images/Live_Metrics_Logo.png"


// Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

// Import Image
import logo from "../../assets/images/logo-sm.svg";

// Redux
import { useSelector, useDispatch } from "react-redux";

const InvoiceDetail = (props) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const invoice = location.state?.invoice || {};
  console.log("allo",invoice);
  // Mock data for invoiceDetail
  const invoiceDetail = {
    
    email: "customer@example.com",
    orderSummary: {
      items: [
        {
          id: 1,
          item: "Product 1",
          adminName: "Admin 1",
          price: "TND350.00",
        },
      ],
      subTotal: "TND350.00",
      total: "TND350.00",
    },
  };

  // Print the Invoice
  const printInvoice = () => {
    window.print();
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>
            Billing Detail | LiveMetrics
          </title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Invoices" breadcrumbItem="Invoice Detail" />
          {!isEmpty(invoiceDetail) && (
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    <div className="invoice-title">
                      <div className="d-flex align-items-start">
                        <div className="flex-grow-1">
                          <div className="mb-4">
                          <span className="logo-lg">
                             <img src={livemetrics} alt="" height="27" />
                          </span>
                          </div>
                        </div>
                        <div className="flex-shrink-0">
                          <div className="mb-4">
                            <h4 className="float-end font-size-16">
                              Invoice # {invoice.invoiceId}
                            </h4>
                          </div>
                        </div>
                      </div>
                      <address>
                      Immeuble Napoleon, Porte à gauche, <br></br>3eme ETAGE, ZI Khéreddine 2015, Lac3
                        <br></br>
                      </address>
                    </div>
                    <hr className="my-4" />
                    <Row>
                      <Col sm="6">
                        <div>
                          <h5 className="font-size-15 mb-3">Billed To:</h5>
                          <p>{invoice.billing_name}</p>
                        </div>
                      </Col>
                      <Col sm="6">
                        <div>
                          <div>
                            <h5 className="font-size-15">Order Date:</h5>
                            <p>{invoice.date}</p>
                          </div>
                        </div>
                        <div className="mt-4">
                          <h5 className="font-size-15">Payment Method:</h5>
                          <p className="mb-1">{invoice.method}</p>
                          <p>{invoiceDetail.email}</p>
                        </div>
                      </Col>
                    </Row>
                    <div className="py-2 mt-3">
                      <h3 className="font-size-15 fw-bold">Order summary</h3>
                    </div>
                    <div className="p-4 border rounded">
                      <div className="table-responsive">
                        <Table className="table-nowrap align-middle mb-0">
                          <thead>
                            <tr>
                              <th style={{ width: "70px" }}>No.</th>
                              <th>Item</th>
                              <th className="text-end" style={{ width: "120px" }}>Price</th>
                            </tr>
                          </thead>
                          <tbody>
                            {map(
                              invoiceDetail.orderSummary.items,
                              (item, key) => (
                                <tr key={key}>
                                  <td>{item.id}</td>
                                  <td>
                                    <h5 className="font-size-15 mb-1">Monthly Subscription</h5>
                                    <p className="font-size-13 text-muted mb-0">{invoice.Plan_Type} </p>
                                  </td>
                                  <td className="text-end">{invoice.Amount}</td>
                                </tr>
                              )
                            )}
                            <tr>
                              <td colSpan="2" className="text-end">
                                Sub Total
                              </td>
                              <td className="text-end">
                              0.00
                              </td>
                            </tr>
                            <tr>
                              <td colSpan="2" className="border-0 text-end">
                                <strong>Total</strong>
                              </td>
                              <td className="border-0 text-end">
                                <h4 className="m-0">
                                {invoice.Amount}
                                </h4>
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </div>
                    </div>
                    <div className="d-print-none mt-4">
                      <div className="float-end">
                        <Link
                          to="#"
                          onClick={printInvoice}
                          className="btn btn-success me-2"
                        >
                          <i className="fa fa-print" />
                        </Link>
                        <Link to="#" className="btn btn-primary w-md">
                          Send
                        </Link>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(InvoiceDetail);