/* eslint-disable no-lone-blocks */
import {
  CHECK_INTEGRATION_REQUEST,
  CHECK_INTEGRATION_SUCCESS,
  CHECK_INTEGRATION_FAILURE,
  GET_ALL_TRACKING_STATUS_REQUEST,
  GET_ALL_TRACKING_STATUS_SUCCESS,
  GET_ALL_TRACKING_STATUS_FAILURE,
  FETCH_DATA_REQUEST,
  FETCH_DATA_SUCCESS,
  FETCH_DATA_FAILURE,
  INTEGRATION_ARAMEX_REQUEST,
  INTEGRATION_ARAMEX_SUCCESS,
  INTEGRATION_ARAMEX_FAILURE,
  DELETE_INTEGRATION_ARAMEX_REQUEST,
  DELETE_INTEGRATION_ARAMEX_SUCCESS,
  DELETE_INTEGRATION_ARAMEX_FAILURE,
  
} from './action';

const initialState = {
  data: [],
  isLoading: false,
  isDisabled: false,
  error: null,
  trackingNStatus: [],
  ShipmentChargesPaid: 0,
  SupportingDocumentReturnedtoShipper: 0,
  ReturnedtoShipper: 0,
  Delivered: 0,
  checkIntegrationdata: [],
  emailofreturnedshipper: [],
};

export default function aramex(state = initialState, action) {
  switch (action.type) {
    case CHECK_INTEGRATION_REQUEST:
      return {
        ...state,
        error: null,
      };
    case CHECK_INTEGRATION_SUCCESS:
      return {
        ...state,
        data: action.payload,
        checkIntegrationdata: action.payload.checkIntegrationdata,
        error: null,
      };
    case CHECK_INTEGRATION_FAILURE:
      return {
        ...state,
        error: action.payload,
      };

    case GET_ALL_TRACKING_STATUS_REQUEST: {
      return {
        ...state,
        isLoading: true,
        isDisabled: true,
        error: null,
      };
    };
    case GET_ALL_TRACKING_STATUS_SUCCESS:
      return {
        ...state,
        checkIntegration: action.payload,
        isLoading: false,
        isDisabled: false,
        error: null,
      };
    case GET_ALL_TRACKING_STATUS_FAILURE:
      return {
        ...state,
        isLoading: false,
        isDisabled: false,
        error: action.payload,
      };
    case FETCH_DATA_REQUEST:
      return {
        ...state,
        error: null,
      };
    case FETCH_DATA_SUCCESS:
      return {
        ...state,
        trackingNStatus: action.payload.trackingNStatus,
        ShipmentChargesPaid: action.payload.ShipmentChargesPaid,
        SupportingDocumentReturnedtoShipper: action.payload.SupportingDocumentReturnedtoShipper,
        ReturnedtoShipper: action.payload.ReturnedtoShipper,
        Delivered: action.payload.Delivered,
        emailofreturnedshipper: action.payload.emailofreturnedshipper,
      };
    case FETCH_DATA_FAILURE:
      return {
        ...state,
        error: action.payload
      };
    case INTEGRATION_ARAMEX_REQUEST:
      return {
        ...state,
        error: null,
      };
    case INTEGRATION_ARAMEX_SUCCESS:
      return {
        ...state,
        data: action.payload,

      };
    case INTEGRATION_ARAMEX_FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    case DELETE_INTEGRATION_ARAMEX_REQUEST:
      return {
        ...state,
      };
    case DELETE_INTEGRATION_ARAMEX_SUCCESS:
      return {
        ...state,
        data: action.payload,
      };
    case DELETE_INTEGRATION_ARAMEX_FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    default:
      return state;
  }
}