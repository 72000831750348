import React from 'react'
import {Tr, Td } from "react-super-responsive-table"
function SingleProduct(props){
    return (
        <Tr className="">
            <Td className="">{props.element.product.product_id}</Td>
            <Td className="">{props.element.product.name}</Td>
            <Td className="">{props.element.product.price}</Td>
            <Td className="">{props.element.product.quantity}</Td>
        </Tr>
    )
}
export default SingleProduct;