import gql from 'graphql-tag';


const getInformations = gql`query($startDate:String!, $endDate:String!, $channel:String){
    ops(startDate:$startDate, endDate:$endDate, channel:$channel){
        created,
        sumTotal,
        grossRev,
        adCost,
        costGoods,
        shippingCost,
        variantHandling,
        merchantFee,
        refunds,
        discount,
        tax,
        netProfit,
        numOrders,
        sumQte,
        facebook,
        google,
        gift
    }
}
`


export {getInformations};