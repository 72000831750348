import gql from 'graphql-tag';

const getBrowsers = gql`query($limit:Int!,$page:Int!){
    all(limit:$limit,page:$page){
        pages,
        browser{,
            salesPlatform,
            profileId,
        },
}}
`


export { getBrowsers};