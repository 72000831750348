export const    GET_OVERVIEW_REPORT = 'GET_OVERVIEW_REPORT';
export const   GET_OVERVIEW_REPORT_SUCCESS = 'GET_OVERVIEW_REPORT_SUCCESS';
export const   GET_OVERVIEW_REPORT_FAILURE = 'GET_OVERVIEW_REPORT_FAILURE';


export const getOverviewDataRequest = (startDate,endDate) => ({
    type: GET_OVERVIEW_REPORT,
    payload: {startDate,endDate},
    
})

export const getOverviewDataSuccess = (data) => ({
    type: GET_OVERVIEW_REPORT_SUCCESS,
    payload: {data}
})

export const getOverviewDataFailure = (error) => ({
    type: GET_OVERVIEW_REPORT_FAILURE,
    payload: error,
})

