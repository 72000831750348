import React, { useState, useEffect } from "react"
import { Row, Col, Card, CardBody, CardTitle, CardHeader, Table } from "reactstrap"
import "./datatables.scss";
import ApolloClient from 'apollo-boost';
import { getGAAudience } from "../../../queries/GoogleAnalytics/Queries";
import urls from "../../../routes/apiUrls";
import { withTranslation } from "react-i18next"
import { withRouter } from "react-router-dom"

const ApploCLI = new ApolloClient({
  uri: urls.analytics,
});
const InterestAffinityCategory = (props) => {
  const [refetch, setRefetch] = useState(false);
  const [interestAffinityCategory, setInterestAffinityCategory] = useState([]);
  useEffect(() => {
    ApploCLI.query({
      query: getGAAudience,
      context: {
        headers: {
          Authorization: window.localStorage.getItem("token")
            ? `Bearer ${window.localStorage.getItem("token")}`
            : "",
        },
      },
      errorPolicy: "all",
      fetchPolicy: "network-only", //allow me to refecth data
    }).then((data) => {
      if (data.data.gaaudiences) {
        setInterestAffinityCategory(data.data.gaaudiences[0].interestAffinityCategory);
      } else {
        setRefetch(!refetch);
      }
    });
  }, []);
  const filteredInterestAffinityCategory = interestAffinityCategory.filter((e) =>
    e
  );
  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Row>
            <Col className="col-12">
              <Card>
                <CardHeader>
                  <CardTitle className="h4">{props.t("Audience Interest Affinity Category")}</CardTitle>
                  <p className="card-title-desc">
                    {props.t("Audience Interest Description")}
                    :{" "}
                    <code>{props.t("likely be interested in")}.</code>
                  </p>
                </CardHeader>
                <CardBody>
                  <div className="table-responsive">
                    <Table className="table table-bordered mb-0">
                      <thead className="table-light">
                        <tr>
                          <th>{props.t("Interest Affinity Name")}</th>
                          <th>{props.t("Interest Affinity count")}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {filteredInterestAffinityCategory.map((element, index) => (
                          <tr key={index}>
                            <td>{element[0]}</td>
                            <td>{element[1]}</td>
                          </tr>
                        ))
                        }
                      </tbody>
                    </Table>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  )
}
export default withRouter(withTranslation()(InterestAffinityCategory));
