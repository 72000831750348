import { takeLatest, put, call,takeEvery} from 'redux-saga/effects';
import { getcmetrcisbyCompaign,getAds,getcmetrcisbyCompaignperspecifcdate,getkeywords } from '../../queries/Google/googleQueries';
import { getData } from '../../queries/Dashboard/googleAdsDashboard';
import urls from '../../routes/apiUrls';
import ApolloClient from "apollo-boost";
import {
    GET_METRICS_BY_CAMPAIGNS,
    getMetricsByCampaignsSuccess,
    getMetricsByCampaignsFailure,
    GET_ADS,
    getAdsSuccess,
    getAdsFailure,
    GET_METRICS_BY_CAMPAIGNS_PER_DATE,
    getMetricsByCampaignsPerDateSuccess,
    getMetricsByCampaignsPerDateFailure,
    GET_GOOGLE_METRICS,
    getGoogleMetricsSuccess,
    getGoogleMetricsFailure,
    GET_KEYWORDS_GOOGLE_ADS_REQUEST,
    getKeywordsSuccess,
    getKeywordsFailure,



} from './actions';

const ApploCLI2 = new ApolloClient({
    uri: urls.google,
  });

  const ApploCLI = new ApolloClient({
    uri: urls.googleAdsDashboard,
  });
  
  function* getMetricsByCampaigns() {
    try {
        const {data}= yield call (ApploCLI2.query,{
        query: getcmetrcisbyCompaign,
        context: {
          headers: {
            Authorization: window.localStorage.getItem("token")
              ? `Bearer ${window.localStorage.getItem("token")}`
              : "",
          },
        },
        errorPolicy: "all",
        fetchPolicy: "no-cache", //allow me to refetch data
      })
      if(data){
        yield put(getMetricsByCampaignsSuccess(data.getmetrcisbycampaign));
      }else{
        yield put(getMetricsByCampaignsFailure("err"));
      }
   
      
    } catch (error) {
      yield put(getMetricsByCampaignsFailure(error));
    }
  }
   function* getAdss() {
    try{

  const {data}= yield call (ApploCLI2.query,{
        query: getAds,
        context: {
          headers: {
            Authorization: window.localStorage.getItem("token")
              ? `Bearer ${window.localStorage.getItem("token")}`
              : "",
          },
        },
        errorPolicy: "all",
        fetchPolicy: "no-cache", //allow me to refetch data
      })
      if(data){
        yield put(getAdsSuccess(data.getads));
      }else{
        yield put(getAdsFailure("err"));
      }
    }catch(error){
        yield put(getAdsFailure(error));
    }
   }
   function* getMetricsByCampaignsPerDate(action) {
    try{
      const {data}= yield call (ApploCLI2.query,{
        query: getcmetrcisbyCompaignperspecifcdate,
        variables: {
          startDate: action.payload.startDate,
          endDate: action.payload.endDate,
        },
        context: {
          headers: {
            Authorization: window.localStorage.getItem("token")
              ? `Bearer ${window.localStorage.getItem("token")}`
              : "",
          },
        },
        errorPolicy: "all",
        fetchPolicy: "no-cache", //allow me to refetch data
      })
      if(data){
        yield put(getMetricsByCampaignsPerDateSuccess(data.getmetrcisofcampaignPerspecifdate));
      }else{
        yield put(getMetricsByCampaignsPerDateFailure("err"));
      }

    }catch(error){
        yield put(getMetricsByCampaignsPerDateFailure(error));
    }
   }

   function* requestgoogleads(action) {
    const checkstartDate = action.payload.startDate;
    const checkendDate = action.payload.endDate;
    try{
        const {data}= yield call (ApploCLI.query,{
            query: getData,
            variables: {
              startDate: action.payload.startDate,
              endDate: action.payload.endDate,
              compare_to: action.payload.comparedTo
            },
            context: {
              headers: {
                Authorization: window.localStorage.getItem("token")
                  ? `Bearer ${window.localStorage.getItem("token")}`
                  : "",
              },
            },
            errorPolicy: "all",
            fetchPolicy: "no-cache", //allow me to refetch data
          })
          if(data){
            // console.log("helooo",data.get);
            yield put(getGoogleMetricsSuccess(data.get));
           
            
          }else{
            yield put(getGoogleMetricsFailure("err"));
          }

    }catch(error){
       yield put(getGoogleMetricsFailure(error));
    }

   }
function* getKeywords() {
  try{
    const {data}= yield call (ApploCLI2.query,{
      query: getkeywords,
      context: {
        headers: {
          Authorization: window.localStorage.getItem("token")
            ? `Bearer ${window.localStorage.getItem("token")}`
            : "",
        },
      },
      errorPolicy: "all",
      fetchPolicy: "no-cache", //allow me to refetch data
    })
    if(data){
      yield put(getKeywordsSuccess(data.getkeywords)); 
    }else{
      yield put(getKeywordsFailure("err"));
    }

  }catch(error){
    yield put(getKeywordsFailure(error));
 
  }
}


    export default function* GoogleAdsSaga() {
        yield takeLatest(GET_KEYWORDS_GOOGLE_ADS_REQUEST, getKeywords);
        yield takeLatest(GET_METRICS_BY_CAMPAIGNS, getMetricsByCampaigns);
        yield takeLatest(GET_ADS, getAdss);
        yield takeLatest(GET_METRICS_BY_CAMPAIGNS_PER_DATE, getMetricsByCampaignsPerDate);
        yield takeLatest(GET_GOOGLE_METRICS, requestgoogleads);
    }