import React, { useState, useEffect, useContext } from 'react';
import {
    Card, CardBody, Col, Container, Row, Modal, Input, Label, FormGroup, Alert, Button, ModalBody,Badge
} from "reactstrap";
import { Table, Thead, Tbody, Tr, Th,Td } from "react-super-responsive-table"
import { getPrestashopStore, integratePresta, deleteIntegration, getPrestashopStores } from '../../../queries/PrestaShop/queries';
import { checkSales, getSalesPlatform } from '../../../queries/Shopify/queries';
import urls from '../../../routes/apiUrls';
import ApolloClient from "apollo-boost";
import PrestaStore from './PrestaStore';
import Swal from 'sweetalert2';
import { deleteIntegrationPrestaShopRequest, integrationPrestaShopRequest, fetchStoresRequest, fetchStoreRequest, checkThirdStepPrestaShopRequest } from '../../../store/prestaShop/actions';

import { useDispatch, connect, useSelector } from 'react-redux';
import { deleteAlert, successAlert } from '../../Alerts/NotLinkedAccountAlert';
import { AlertProgressBar } from '../../Alerts/AlertTimeServerResponse';
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { ShepherdTour, ShepherdTourContext } from 'react-shepherd';

// ** Icons Imports
import { Check, Link2, Key } from 'react-feather'
//*styles
import 'shepherd.js/dist/css/shepherd.css'
// import '@styles/react/libs/shepherd-tour/shepherd-tour.scss'

import '../../../assets/scss/shepherd-tour/shepherd-tour.scss'

const ApploCLI = new ApolloClient({
    uri: urls.prestashop
})
const AppoloCliCheckSales = new ApolloClient({
    uri: urls.shopify
})

//guide tour 
const backBtnClass = 'btn btn-sm btn-outline-primary',
    nextBtnClass = 'btn btn-sm btn-primary btn-next'

let instance = null

const Content = () => {
    const tour = useContext(ShepherdTourContext)
    instance = tour

    return (
        <Col className='text-center mt-2 pt-50'>
            <Button color='primary' className='me-1' onClick={() => tour.start()} outline>
                Guide Tour
            </Button>
        </Col>
    )
}
const PrestaShopIcon = `${process.env.PUBLIC_URL}/images/integrations_images/PrestaShop.svg`;
function PrestaShopCard(props) {
    const data = [
        {
            icon: <Link2 className='icon' />,
            title: props.t('Add Your website Link 🤟🏻'),
            subtitle: props.t('Dont forget to add http or https protocol')
        },
        {
            icon: <Key className='icon' />,
            title: props.t('Add Your  api key 👩🏻‍💻'),
            subtitle: props.t('You can generate keys from your Prestashop backend')
        },
        {
            icon: <Check className='icon' />,
            title: props.t('Your Account is ready for use 🎉'),
            subtitle: props.t('You  will get 7 days free trial')
        }
    ]
    const steps = [
        {
            id: 'url',
            title: 'url',
            text: props.t('copy paste your website link'),
            attachTo: { element: '.form-control-itps', on: 'bottom' },
            cancelIcon: {
                enabled: true
            },
            buttons: [
                {
                    action: () => instance.cancel(),
                    classes: backBtnClass,
                    text: props.t('Skip')
                },
                {
                    text: props.t('Next'),
                    classes: nextBtnClass,
                    action: () => instance.next()
                }
            ]
        },
        {
            id: 'keys',
            title: props.t('keys'),
            text: props.t('Insert your consumer key'),
            attachTo: { element: '.form-control-apketg', on: 'top' },
            cancelIcon: {
                enabled: true
            },
            buttons: [
                {
                    text: props.t('Skip'),
                    classes: backBtnClass,
                    action: () => instance.cancel()
                },
                {
                    text: props.t('Back'),
                    classes: backBtnClass,
                    action: () => instance.back()
                },
                {
                    text: props.t('Next'),
                    classes: nextBtnClass,
                    action: () => instance.next()
                }
            ]
        },
        {
            id: 'connect',
            title: props.t('connect'),
            text: props.t('connect your Prestashop store'),
            attachTo: { element: '.modal-footer-GC', on: 'top' },
            cancelIcon: {
                enabled: true
            },
            buttons: [
                {
                    text: props.t('Back'),
                    classes: backBtnClass,
                    action: () => instance.back()
                },
                {
                    text: props.t('Finish'),
                    classes: nextBtnClass,
                    action: () => instance.cancel()
                }
            ]
        }
    ]
    const dispatch = useDispatch();

    const { step, reachedFinalStep, stores,firststore } = useSelector((state) => state.prestashop);
    const options = [{ key: 1, text: 'HTTP', value: 'http' }, { key: 2, text: 'HTTPS', value: 'https' }];
    const [api, setApi] = useState("");
    const [domain, setDomain] = useState("");
    const [filterStr, setFilterStr] = useState("");
    const [deleteLoading, setDeleteLoading] = useState(false);
    const [protocol, setProtocol] = useState(options[1]);

    const [modal_standard, setmodal_standard] = useState(false);
    const [validation, setValidation] = useState({
        unm: null,
        fnm: null,
        lnm: null,
    })
    function tog_standard() {
        setmodal_standard(!modal_standard)
        removeBodyCss()
    }
    function removeBodyCss() {
        document.body.classList.add("no_padding")
    }


    useEffect(() => {
        dispatch(checkThirdStepPrestaShopRequest())
        dispatch(fetchStoresRequest())

    }, [])
    useEffect(() => {
        dispatch(fetchStoreRequest())
    }, [])


    const deleteConnection = () => {
        let name = ""
        if (stores && stores.length > 0 && stores[0] && stores[0].name) {
            name = stores[0].name
        } else {
            name = "prestashop"
        }


        deleteAlert(name).then(function (e) {
            if (e.isConfirmed) {
                dispatch(deleteIntegrationPrestaShopRequest())
            }
        })
    }


    let integrate = () => {
        dispatch(integrationPrestaShopRequest(api, domain, protocol.value))
    }
    // const filteredAccounts = stores.filter((e) =>
    //     e.name.toUpperCase().includes(filterStr.toUpperCase())
    // );
    const newdomain = firststore && firststore.url ? firststore.url.match(/^https?:\/\/([^/?#]+)(?:[/?#]|$)/i)[1] : '';
    const firstStep = () => {
        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>
                        <Row>
                            <Col>
                                <Card className="border text-secondary">
                                    <CardBody>
                                        <div className="my-0 text-primary d-flex justify-content-between align-items-center">
                                            <div className='text-left d-flex align-items-center'>
                                                <img className="img-intgration-icon" src={PrestaShopIcon} />
                                                <span className="text-primary h4 mb-0 ml-2">PrestaShop</span>
                                            </div>
                                            <div>
                                                <button
                                                    onClick={() => {
                                                        tog_standard()
                                                    }}
                                                    className="btn btn-primary">
                                                    {props.t("Connect")}
                                                </button>

                                                <Modal isOpen={modal_standard} toggle={() => tog_standard()} className='modal-dialog-centered modal-refer-earn modal-lg'>
                                                    <ModalBody className='pb-5 px-sm-0'>

                                                        <div className='text-center'>
                                                            <img style={{ height: '80px' }} src={PrestaShopIcon} />
                                                            <h1 className='text-center mb-1'>PrestaShop</h1>
                                                            <p className='text-center mb-5'>
                                                                {props.t('Connect your PrestaShop Store within 30 seconds')}
                                                            </p>
                                                            <button
                                                                type="button"
                                                                onClick={() => {
                                                                    setmodal_standard(false)
                                                                }}
                                                                className="close"
                                                                data-dismiss="modal"
                                                                aria-label="Close"
                                                            >
                                                                <span aria-hidden="true">&times;</span>
                                                            </button>
                                                            <Row className='mb-4'>
                                                                {data.map((item, index) => {
                                                                    return (
                                                                        <Col xs={12} lg={4} key={index}>
                                                                            <div className='d-flex justify-content-center mb-1'>
                                                                                <div className='circle'>
                                                                                    <div className='icon'>
                                                                                        {item.icon}
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className='text-center'>
                                                                                <h6 className='fw-bolder mb-1'>{item.title}</h6>
                                                                                <p>{item.subtitle}</p>
                                                                            </div>
                                                                        </Col>
                                                                    );
                                                                })}
                                                            </Row>
                                                        </div>
                                                        <Row className='px-3'>
                                                            <Col md="12">
                                                                <div className="mb-3 position-relative">
                                                                    <Label htmlFor="validationTooltipUsername">
                                                                        webiste Url
                                                                    </Label>
                                                                    <div className="input-group">
                                                                        <div className="input-group-prepend">
                                                                            <span
                                                                                className="input-group-text"
                                                                                id="validationTooltipUsernamePrepend"
                                                                            >
                                                                                https
                                                                            </span>
                                                                        </div>
                                                                        <Input
                                                                            type="text"
                                                                            className="form-control-itps"
                                                                            id="validationTooltipUsername"
                                                                            placeholder="Website url"
                                                                            onChange={e => setDomain(e.target.value)}
                                                                            valid={validation["unm"] === true}
                                                                            invalid={
                                                                                validation["unm"] !== true &&
                                                                                validation["unm"] !== null
                                                                            }
                                                                        />
                                                                        <div
                                                                            className={
                                                                                validation["unm"] === true
                                                                                    ? "valid-tooltip"
                                                                                    : "invalid-tooltip"
                                                                            }
                                                                            name="validate"
                                                                            id="validate3"
                                                                        >
                                                                            {validation["unm"] === true
                                                                                ? props.t("Looks good!")
                                                                                : props.t("Please choose a unique and valid website.")}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </Col>
                                                            <Col md="12">
                                                                <div className="mb-3 position-relative">
                                                                    <Label htmlFor="validationTooltip01">
                                                                        {props.t("Api key")}
                                                                    </Label>
                                                                    <Input
                                                                        type="text"
                                                                        className="form-control-apketg"
                                                                        id="validationTooltip01"
                                                                        placeholder={props.t("Api key")}
                                                                        onChange={e => setApi(e.target.value)}
                                                                        valid={validation["fnm"] === true}
                                                                        invalid={
                                                                            validation["fnm"] !== true &&
                                                                            validation["fnm"] !== null
                                                                        }
                                                                    />

                                                                    <div
                                                                        className={
                                                                            validation["fnm"] === true
                                                                                ? "valid-tooltip"
                                                                                : "invalid-tooltip"
                                                                        }
                                                                        name="validate"
                                                                        id="validate1"
                                                                    >
                                                                        {validation["fnm"] === true
                                                                            ? props.t("Looks good!")
                                                                            : props.t("Please Enter Valid Api key")}
                                                                    </div>
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                        <Col xs={12} className='text-center mt-2 pt-50'>
                                                            <div className="modal-footer-GC">
                                                                <button
                                                                    type="button"
                                                                    onClick={() => {
                                                                        tog_standard()
                                                                    }}
                                                                    className="btn btn-secondary "
                                                                    data-dismiss="modal"
                                                                >
                                                                    {props.t("Close")}
                                                                </button>
                                                                <button
                                                                    type="button"
                                                                    className="btn btn-primary"
                                                                    onClick={() => { integrate() }}
                                                                >
                                                                    {props.t("Confirm")}
                                                                </button>
                                                            </div>
                                                            <ShepherdTour
                                                                steps={steps}
                                                                tourOptions={{
                                                                    useModalOverlay: true
                                                                }}
                                                            >
                                                                <Content />
                                                            </ShepherdTour>
                                                        </Col>
                                                    </ModalBody>
                                                </Modal>
                                            </div>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        );
    }
    const secondStep = () => {
        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>
                        <Row>
                            <Col>
                                <Card className="border text-secondary">
                                    <div className="card-header bg-transparent border-secondary">
                                        <div className="my-0 text-primary d-flex justify-content-between align-items-center"><h5 className="my-0 text-primary">PrestaShop </h5><img className="img-intgration-icon" src={PrestaShopIcon} /></div>
                                    </div>
                                    <CardBody>
                                        <FormGroup>
                                            <div style={{ textAlign: "center", padding: "20px" }}>
                                                {firststore  ? (
                                                    <div>
                                                        {/* <form className="app-search d-none d-lg-block">
                                                            <div className="position-relative">
                                                                <Input

                                                                    size="small"
                                                                    onChange={(e) => setFilterStr(e.target.value)}
                                                                    icon={<img name="search" link />}
                                                                    placeholder={props.t("Search...")}
                                                                    style={{ float: "right", marginBottom: "20px" }}
                                                                />
                                                                <button className="btn btn-primary" type="button"><i
                                                                    className="bx bx-search-alt align-middle"></i></button>
                                                            </div>
                                                        </form> */}
                                                        <Table
                                                            id="tech-companies-1"
                                                            className="table table-striped table-bordered"
                                                        >
                                                            <Thead>
                                                                <Tr>
                                                                    <Th data-priority="3">{props.t("Domaine Name")}</Th>
                                                                    <Th data-priority="1">{props.t("status")}</Th>
                                                                </Tr>
                                                            </Thead>
                                                            <Tbody>
                                                                {/* {firststore.map((element, index) => (
                                                                    <PrestaStore element={element} key={index} />
                                                                ))} */}
                                                                
                                                                        <Tr>
                                                                            <Td> {newdomain}</Td>
                                                                            <Td>
                        {firststore.connectionstate ? (
                            <Badge className="me-2 bg-success">Online</Badge>
                        ) : (
                            <Badge className="me-2 bg-danger">Offline</Badge>
                        )}
                    </Td>
                                                                        </Tr>
                                                                   
                                                                
                                                            </Tbody>
                                                        </Table>
                                                    </div>
                                                ) : (
                                                    <div style={{ display: "grid" }}>
                                                        <div>
                                                            <h3>{props.t("There is no Store integrated")}</h3>
                                                            <small>{props.t("Tryagain")}</small>
                                                        </div>

                                                    </div>
                                                )}
                                                {deleteLoading ? (
                                                    <img style={{ float: 'left', margin: 10 }} name="notched circle loading icon" />
                                                ) : (

                                                    <div className='text-center'>
                                                        <button type="button" className="btn btn-danger waves-effect waves-light" onClick={() => deleteConnection()}>
                                                            <i className="bx bx-block font-size-16 align-middle"></i>{props.t("Delete Connection")}
                                                        </button>
                                                    </div>
                                                )}
                                            </div>
                                        </FormGroup>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        );
    };
    const thirdStep = () => {
        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>
                        <Row>
                            <Col>
                                <Card className="border text-secondary">
                                    <div className="card-header bg-transparent border-secondary">
                                        <div className="my-0 text-primary d-flex justify-content-between align-items-center"><h5 className="my-0 text-primary">PrestaShop </h5><img className="img-intgration-icon" src={PrestaShopIcon} /></div>
                                    </div>
                                    <CardBody>
                                        <FormGroup className="mb-3">
                                            <div style={{ display: 'flex', flexDirection: 'center', alignItems: 'center', justifyContent: 'center' }} >
                                                <div className="mt-4 mt-md-0 text-center">
                                                    <Alert color="warnning">

                                                        <p style={{ fontWeight: 'bold' }} >{props.t("You have already a store integrated")} </p>
                                                    </Alert>
                                                </div>
                                            </div>
                                        </FormGroup>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        )
    }
    const renderSections = () => {
        switch (step) {
            case 3:
                return thirdStep();
            case 1:
                return firstStep();
            case 2:
                return secondStep();
            default:
                return firstStep();
        }
    };
    return <div>{renderSections()}</div>;
}
PrestaShopCard.propTypes = {
    t: PropTypes.any
};

export default withRouter(withTranslation()(PrestaShopCard));
//export default PrestaShopCard;