import gql from 'graphql-tag';

 const getProducts = gql`query($limit:Int!,$page:Int!){
    all(limit:$limit,page:$page){
        pages,
        type,
        product{
            id,
            name,
            price,
            quantity,
            product_id
        }
}}
`



const editProduct = gql`mutation($priceSell:Float!,$priceBuy:Float!,$priceHandling:Float!,$misc1:Float!,$misc2:Float!,$variantId:String!, $productId:String!){
    edit(price_sell:$priceSell,price_buy:$priceBuy,price_handling:$priceHandling,misc_1:$misc1,misc_2:$misc2,variant_id:$variantId,product_id:$productId){
        name
    }
}
`


const searchProduct = gql`query($term:String!,$page:Int!,$limit:Int!){
    search(term:$term,page:$page,limit:$limit){
        pages,
        type,
        product{
            id,
            name
        },
        variants{
            id,
            name,
            sku,
            price_buy,
            price_sell,
            price_handling,
            misc_1,
            misc_2,
        }
    }
}
`


export { getProducts, editProduct, searchProduct };