import React from 'react';
import "../../../assets/scss/custom/pages/_operations.scss";
import { Table, Popup, Grid, Header } from 'semantic-ui-react'
import { default as NumberFormat } from 'react-number-format';
function OperationsRow(props) {
  function isValidDate(d) {
    return d instanceof Date && !isNaN(d);
  }
  const d = isValidDate(new Date(props.singleOps.created)) ? new Date(props.singleOps.created).toUTCString() : new Date(parseInt(props.singleOps.created)).toUTCString();
  const day = d.substring(11, 5);
  const final = day.split(" ").reverse().join(" ")
  return (
    <Table.Row>
      <Table.Cell textAlign="center" style={{ fontSize: '11px' }}>{final}</Table.Cell>
      <Popup size='mini'
        trigger={<Table.Cell textAlign="center" style={{ fontSize: '11px' }}><span style={props.singleOps.sumTotal && props.singleOps.sumTotal.toFixed(2) < 0 ? { color: "red" } : null}>{props.singleOps.sumTotal && props.singleOps.sumTotal === 0 ? '-' : <NumberFormat value={props.singleOps.sumTotal ? props.singleOps.sumTotal : "-"} decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={"TND"} renderText={value => <div>{value}</div>} />}</span>
        </Table.Cell>
        } flowing hoverable position={"right center"}>
        {/* <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
          <span style={{ fontSize: "14px", color: "#202020" }}>Gift Cards:</span>
          {props.singleOps.gift && props.singleOps.gift === 0 ? '-' : <NumberFormat value={props.singleOps.gift ? props.singleOps.gift : "-"} decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={"TND"} renderText={value => <div>{value}</div>} />}
        </div> */}
      </Popup>
      <Table.Cell textAlign="center" style={{ fontSize: '11px' }}><span style={props.singleOps.grossRev && props.singleOps.grossRev.toFixed(2) < 0 ? { color: "red" } : null}>{props.singleOps.grossRev && props.singleOps.grossRev === 0 ? '-' : <NumberFormat value={props.singleOps.grossRev ? props.singleOps.grossRev : "-"} decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={"TND"} renderText={value => <div>{value}</div>} />}</span>
      </Table.Cell>
      {(props.singleOps.facebook !== 0 || props.singleOps.google !== 0) ? (
        <Popup size='tiny'
          trigger={<Table.Cell textAlign="center" style={{ fontSize: '11px' }}><span style={props.singleOps.adCost && props.singleOps.adCost.toFixed(2) < 0 ? { color: "red" } : null}>{props.singleOps.adCost && props.singleOps.adCost === 0 ? '-' : <NumberFormat value={props.singleOps.adCost ? props.singleOps.adCost : "-"} decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={"TND"} renderText={value => <div>{value}</div>} />}</span>
          </Table.Cell>
          } flowing hoverable position={"right center"}
          style={{
            border: '1px solid #ccc',
            borderRadius: '4px',
            padding: '10px',
            background: '#f9f9f9',
            zIndex:'999',
            width:'110px'
          }}
          >
            
          <Grid centered divided columns={2}>
            
            <Grid.Column textAlign='center'>
              <Header as='h6' style={{color:"#556ee6" }}>Facebook</Header>
              <p><b><span style={{ marginBottom: '10px' }}>{((props.singleOps.facebook / props.singleOps.adCost) * 100).toFixed(1)}%</span></b><br></br><br></br>
                {props.singleOps.facebook === 0 ? '-' : <NumberFormat value={props.singleOps.facebook} decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={"TND"} renderText={value => <div>{value}</div>} />}</p>
            </Grid.Column>
            <hr></hr>
            <Grid.Column textAlign='center'>
              <Header as='h6' style={{color:"#556ee6" }}>Google</Header>
              <p><b><span style={{ marginBottom: '10px' }}>{((props.singleOps.google / props.singleOps.adCost) * 100).toFixed(1)}%</span></b><br></br><br></br>
                {props.singleOps.google === 0 ? '-' : <NumberFormat value={props.singleOps.google} decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={"TND"} renderText={value => <div>{value}</div>} />}
              </p>
            </Grid.Column>
          </Grid>
        </Popup>) : <Table.Cell textAlign="center" style={{ fontSize: '11px' }}><span style={props.singleOps.adCost.toFixed(2) < 0 ? { color: "red" } : null}>{props.singleOps.adCost === 0 ? '-' : <NumberFormat value={props.singleOps.adCost} decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={"TND"} renderText={value => <div>{value}</div>} />}</span>
        </Table.Cell>}
      <Table.Cell textAlign="center" style={{ fontSize: '11px' }}><span style={props.singleOps.variantHandling && props.singleOps.variantHandling.toFixed(2) < 0 ? { color: "red" } : null}>{props.singleOps.variantHandling && props.singleOps.variantHandling === 0 ? '-' : <NumberFormat value={props.singleOps.variantHandling ? props.singleOps.variantHandling : "-"} decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={"TND"} renderText={value => <div>{value}</div>} />}</span>
      </Table.Cell>
      <Table.Cell textAlign="center" style={{ fontSize: '11px' }}><span style={props.singleOps.refunds && props.singleOps.refunds.toFixed(2) < 0 ? { color: "red" } : null}>{props.singleOps.refunds && props.singleOps.refunds === 0 ? '-' : <NumberFormat value={props.singleOps.sumTotal -props.singleOps.adCost-props.singleOps.discount- props.singleOps.variantHandling} decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={"TND"} renderText={value => <div>{value}</div>} />}</span>
      </Table.Cell>
      <Table.Cell textAlign="center" style={{ fontSize: '11px' }}><span style={props.singleOps.discount && props.singleOps.discount.toFixed(2) < 0 ? { color: "red" } : null}>{props.singleOps.discount && props.singleOps.discount === 0 ? '-' : <NumberFormat value={props.singleOps.discount ? props.singleOps.discount : "-"} decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={"TND"} renderText={value => <div>{value}</div>} />}</span>
      </Table.Cell>
      <Table.Cell textAlign="center" style={{ fontSize: '11px' }}><span style={props.singleOps.numOrders && props.singleOps.numOrders < 0 ? { color: "red" } : null}>{props.singleOps.numOrders && props.singleOps.numOrders === 0 ? "-" : props.singleOps.numOrders ? props.singleOps.numOrders : "-"}</span></Table.Cell>
      <Table.Cell textAlign="center" style={{ fontSize: '11px' }}><span style={props.singleOps.sumQte && props.singleOps.sumQte.toFixed(2) < 0 ? { color: "red" } : null}>{props.singleOps.sumQte && props.singleOps.sumQte === 0 ? "-" : props.singleOps.sumQte ? props.singleOps.sumQte : "-"}</span></Table.Cell>
    </Table.Row>
  )
}
export default OperationsRow;