import React, { useState, useEffect } from 'react';
import MetaTags from 'react-meta-tags';
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { TabContent, TabPane, Nav, CardBody, Progress, Alert } from 'reactstrap';
import { Table, Thead, Tbody, Tr, Th } from "react-super-responsive-table";
import SingleCoupon from './SingleCoupon';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCouponsCodesRequest } from '../../../store/woocommerce/actions';
import SimpleBar from "simplebar-react"
import { withRouter } from "react-router-dom"
import { withTranslation } from "react-i18next"
import PropTypes from "prop-types";
import secureSection from '../../../MangeSecurity/MemberSecurity';
const WooCommercepIcon = `${process.env.PUBLIC_URL}/images/integrations_images/WooCommerce.svg`;
function WooCoupons(props) {
  secureSection();
  const [activeTab, setActiveTab] = useState('1');
  const dispatch = useDispatch();
  const { coupons } = useSelector((state) => state.woocommerce);
  useEffect(() => {
    const savedCouponsCodes = localStorage.getItem('woocoupons');
    if (savedCouponsCodes) {
      dispatch({ type: 'FETCH_COUPONS_CODES_SUCCESS', payload: JSON.parse(savedCouponsCodes) });
    } else {
      dispatch(fetchCouponsCodesRequest(50, 1));
    }
  }, [dispatch]);
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>{props.t("woo coupons codes")} | Live-Metrics - E-com SaaS</title>
        </MetaTags>
        <div className="container-fluid">
          <Breadcrumbs title={props.t("WooCommerce")} breadcrumbItem={props.t("Coupons Codes")} pageHeading={props.t("Coupons Codes")} />
          <div className="card">
            <div className="card-header align-items-center d-flex">
              <h4 className="card-title mb-0 flex-grow-1">{props.t("All Coupons Codes")}</h4>
              <img className="img-intgration-icon" src={WooCommercepIcon} />
              <div className="flex-shrink-0">
                <Nav className="justify-content-end nav-tabs-custom rounded card-header-tabs" role="tablist">
                </Nav>
              </div>
            </div>
            <CardBody className="px-0">
              <TabContent activeTab={activeTab}>
                <TabPane tabId="1">
                  {coupons && coupons.length > 0 ? (
                    <SimpleBar className="table-responsive px-3" style={{ maxHeight: "600px" }}>
                      <Table className="table table-striped table-bordered">
                        <Thead>
                          <Tr>
                            <Th data-priority="1">{props.t("Coupon ID")}</Th>
                            <Th data-priority="1">{props.t("code")}</Th>
                            <Th data-priority="1">{props.t("amount")}</Th>
                            <Th data-priority="1">{props.t("usage_count")}</Th>
                            <Th data-priority="1">{props.t("free_shipping")}</Th>
                            <Th data-priority="1">{props.t("individual_use")}</Th>
                          </Tr>
                        </Thead>
                        <Tbody>
                          {coupons.map((element, index) => (
                            <SingleCoupon element={element} key={index} />
                          ))}
                        </Tbody>
                      </Table>
                    </SimpleBar>
                  ) : (
                    <div className='text-center'>
                      <div className="text-center">
                        <CardBody>
                          <Progress
                            value={80}
                            color="success"
                            style={{ width: '75%' }}
                            animated
                          ></Progress>
                          <Alert color="success" className="mt-3">
                          {props.t("This might take a few minutes!")}
                          </Alert>
                        </CardBody>
                      </div>
                    </div>
                  )}
                </TabPane>
              </TabContent>
            </CardBody>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
WooCoupons.propTypes = {
  t: PropTypes.any
};
export default withRouter(withTranslation()(WooCoupons));
//export default WooCoupons;