import React from "react"
import ReactApexChart from "react-apexcharts"
import { useSelector, useDispatch } from 'react-redux';
const Conversionchart = () => {
    const { dataDashboard } = useSelector((state) => state.facebookAds);
    const conversion = dataDashboard.reports.map((item) => item.conversions.toFixed(2));
    const date = dataDashboard.reports.map((item) => item.date);
  const series = [
    {
      name: "Conversion",
      data: conversion,
    },
  
   
  ]

  const options = {
    chart: {
      toolbar: {
        show: false
      }
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
      width: 2,
    }, yaxis: [
      {
        title: {
          text: 'Conversions',
        },
      },
    
    ],

    colors: ["#0044BD",],
    xaxis: {
      categories: dataDashboard?.reports.map((item) => {
        const date = new Date(item.date);
        const month = date.toLocaleDateString('fr-FR', { month: 'short' }); // Use 'short' for abbreviated month name
        return `${date.getDate()}${month}`;
      }),
      labels: {
        trim: false, // Display all labels without trimming
      },
    },
    grid: {
      borderColor: "#f1f1f1",
    },
    tooltip: {
      x: {
        format: "dd/MM",
      },
    },
  }

  return (
    <ReactApexChart
      options={options}
      series={series}
      type="line"
      height="350"
    />
  )
}

export default Conversionchart