import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import Select from "react-select"
import FeatherIcon from "feather-icons-react";
import MetaTags from 'react-meta-tags';
import axios from "axios";
import "../../assets/scss/custom/pages/_daterange.scss";
import {
  Row,
  Col,
  FormGroup,
  InputGroup,
  Card,
  Modal,
  CardHeader,
  CardTitle,
  CardBody,
} from "reactstrap"
import Button from 'react-bootstrap/Button';
import Offcanvas from 'react-bootstrap/Offcanvas';
import OffcanvasHeader from 'react-bootstrap/OffcanvasHeader';
import OffcanvasBody from 'react-bootstrap/OffcanvasBody';
import {getFacebookPixels } from '../../queries/FacebookAds/queries';
import urls from '../../routes/apiUrls';
import { getData } from '../../queries/Dashboard/FacebookDashboardQ';
import Breadcrumbs from "../../components/Common/Breadcrumb"
import ApolloClient from 'apollo-boost';
import { DateRange } from "react-date-range";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { getFbCampaignMetrics } from '../../store/facebookAds/actions';
import { useDispatch, useSelector } from 'react-redux';
import "flatpickr/dist/themes/material_blue.css"
import AdsDataCard from "./AdsDataCard";
import secureSection from '../../MangeSecurity/MemberSecurity';
import ReactEcharts from "echarts-for-react"
const ApploCLI = new ApolloClient({
  uri: urls.facebook
})

const daysBetween = function (date1, date2) {
  var one_day = 1000 * 60 * 60 * 24;
  var date1_ms = date1.getTime();
  var date2_ms = date2.getTime();
  var difference_ms = date2_ms - date1_ms;
  return Math.round(difference_ms / one_day);
}
function useOutsideAlerter(ref, toggleDate) {
  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        toggleDate(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);
}
const datePattern = /(\d{1,2})\/(\d{1,2})\/(\d{4})/;


function Socials(props) {

  const [isOffcanvasOpen, setIsOffcanvasOpen] = useState(false);

  const toggleOffcanvas = () => {
    setIsOffcanvasOpen(!isOffcanvasOpen);
  };
  secureSection();
  const dispatch = useDispatch();
  const { dataDashboard } = useSelector((state) => state.facebookAds)
  const wrapperRef = useRef(null);
  const [toggleDate, setToggleDate] = useState(false);
  useOutsideAlerter(wrapperRef, setToggleDate);
  const [dateRage, setDateRange] = useState({
    date:
      [
        window.localStorage.getItem("start") ? new Date(window.localStorage.getItem("start")) : new Date(new Date().toLocaleDateString('en-US', { timeZone: props.timeZone })),
        window.localStorage.getItem("end") ? new Date(window.localStorage.getItem("end")) : new Date(new Date().toLocaleDateString('en-US', { timeZone: props.timeZone }))

      ]
  });
  const [lastPeriod, setLastPeriod] = useState("");
  const [chosedDate, setChosedDate] = useState([{
    startDate: window.localStorage.getItem("start") ? new Date(window.localStorage.getItem("start")) : new Date(new Date().toLocaleDateString('en-US', { timeZone: props.timeZone })),
    endDate: window.localStorage.getItem("end") ? new Date(window.localStorage.getItem("end")) : new Date(new Date().toLocaleDateString('en-US', { timeZone: props.timeZone })),
    key: 'selection'
  }]);
  const [convertedDate, setConvertedDate] = useState([]);
  const [currentFocus, setCurrentFocus] = useState([]);
  // const [dataDashboard, setDataDashboard] = useState([]);
  const [sales, setSales] = useState([]);
  const [grossProfit, setGrossProfit] = useState([]);
  const [cps, setCps] = useState([]);
  const [spend, setSpend] = useState([]);
  const [reach, setReach] = useState([]);
  const [impressions, setImpression] = useState([]);
  const [aov, setAov] = useState([]);
  const [clicks, setClicks] = useState([]);
  const [nmbrOfOrders, setNmbrOfOrders] = useState([]);
  const [conversions, setConversions] = useState([]);
  const [conversionsValue, setConversionsValue] = useState([]);
  const [landingPageView, setLandingPageView] = useState([]);
  const [lastSales, setLastSales] = useState([]);
  const [lastGrossProfit, setLastGrossProfit] = useState([]);
  const [lastCps, setLastCps] = useState([]);
  const [lastSpend, setLastSpend] = useState([]);
  const [lastReach, setLastReach] = useState([]);
  const [lastImpression, setLastImpression] = useState([]);
  const [lastAov, setLastAov] = useState([]);
  const [lastClicks, setLastClicks] = useState([]);
  const [lastNmbrOfOrders, setLastNmbrOfOrders] = useState([]);
  const [lastMainSales, setLastMainSales] = useState([]);
  const [lastMainGrossProfit, setLastMainGrossProfit] = useState([]);
  const [lastConversions, setLastConversions] = useState([]);
  const [lastConversionsValue, setLastConversionsValue] = useState([]);
  const [lastLandingPageView, setLastLandingPageView] = useState([]);
  const [mainSales, setMainSales] = useState([]);
  const [mainGrossProfit, setMainGrossProfit] = useState([]);
  const [dates, setDates] = useState([]);
  const [lastDates, setLastDates] = useState([]);
  const [loadingState, setLoadingState] = useState(false);
  const [activeBtn, setActiveBtn] = useState(window.localStorage.getItem("activeBtn") ? JSON.parse(window.localStorage.getItem("activeBtn")) : {
    today: 1,
    yesterday: 0,
    last7days: 0,
    last30day: 0,
    last60day: 0,
    month: 0,
    other: 0,
  });
  const [comparedTo, setComparedTo] = useState("");
  const mnths = [{ num: 0, add: 2 },
  { num: 1, add: 0 },
  { num: 2, add: 2 },
  { num: 3, add: 1 },
  { num: 4, add: 2 },
  { num: 5, add: 1 },
  { num: 6, add: 2 },
  { num: 7, add: 2 },
  { num: 8, add: 1 },
  { num: 9, add: 2 },
  { num: 10, add: 1 },
  { num: 11, add: 2 }
  ]
  const getToAddOnMonths = (int) => {
    let res = 0;
    let month = 0;
    if (int === 0) {
      month = int
    }
    else {
      month = int - 1
    }
    mnths.map(x => {
      if (x.num === month)
        res = x.add;
    })
    return res
  }

  useEffect(() => {
   ApploCLI.query({
        query: getFacebookPixels,
        context: {
          headers: {
            Authorization: window.localStorage.getItem("token") ? `Bearer ${window.localStorage.getItem("token")}` : ""
          },
        },
        errorPolicy: "all",
        fetchPolicy: "network-only",
      }).then((data) => {
console.log("data",data)
       if(data.data.adAccounts){
        let dataActiveAccount = data.data.adAccounts;
        let x = dataActiveAccount.filter((account) => {
          return (account.active === true);
        })
        if(x.length){
          localStorage.setItem("metaads_customer_currency_code", data.data.adAccounts[0].currency_code);
        }
       }
      });
    
    
  }, [])
 
  useEffect(() => {
    if (chosedDate[0] && chosedDate[0].startDate && chosedDate[0].endDate) {
      const startDate = chosedDate[0].startDate;
      const endDate = chosedDate[0].endDate;
      const newStartDate = new Date(startDate).setDate(new Date(startDate).getDate() - daysBetween(startDate, endDate) - 1);
      const newEndDate = new Date(endDate).setDate(new Date(endDate).getDate() - daysBetween(startDate, endDate) - 1);
      (comparedTo !== 'year' ?
        setLastPeriod(`${"Comparedto"} : ${new Date(newStartDate).toLocaleString("en", { day: "2-digit", month: "short" })}-${new Date(newEndDate).toLocaleString("en", { day: "2-digit", month: "short" })} ${new Date(newEndDate).getFullYear()}`)
        :
        setLastPeriod(`${"Comparedto"}: ${new Date(startDate).toLocaleString("en", { day: "2-digit", month: "short" })}-${new Date(endDate).toLocaleString("en", { day: "2-digit", month: "short" })} ${new Date(endDate).getFullYear() - 1}`)
      )
    }
  }, [chosedDate, comparedTo])
  const buttonHandler = (startDate, endDate) => {
    setDateRange({ date: [startDate, endDate] });
    setChosedDate([{
      startDate: startDate,
      endDate: endDate,
      key: 'selection'
    }]);
    setToggleDate(false)
    window.localStorage.setItem("start", startDate);
    window.localStorage.setItem("end", endDate);
  };
  useEffect(() => {
    let isSubscribed = true;
    setLoadingState(false);
    var newStartDate = datePattern.exec(
      chosedDate[0].startDate &&
      chosedDate[0].startDate.toLocaleString("fr-FR")
    );
    var newEndDate = datePattern.exec(
      chosedDate[0].endDate && chosedDate[0].endDate.toLocaleString("fr-FR")
    );
    if (newStartDate && newEndDate) {
      dispatch(getFbCampaignMetrics(newStartDate[3] + "-" + newStartDate[2] + "-" + newStartDate[1], newEndDate[3] + "-" + newEndDate[2] + "-" + newEndDate[1], comparedTo));

      if (isSubscribed) {
        // Check if dataDashboard is defined before using it
        if (dataDashboard) {
          // Ensure that dataDashboard.reports is defined before sorting
          if (dataDashboard.reports) {
            dataDashboard.reports.sort((a, b) => {
              let dateA = new Date(a.date);
              let dateB = new Date(b.date);
              return dateA - dateB;
            });


          }

          setLoadingState(true);
        } else {
          // Handle the case where dataDashboard is undefined
          // You can set an appropriate default value or handle the error here
          console.error("dataDashboard is undefined");
          setLoadingState(true);
        }
      }
    }

    return () => (isSubscribed = false);
  }, [dateRage, comparedTo]);


  const [selectedGroup, setselectedGroup] = useState(null);
  const optionGroup = [
    {
      label: "USD - United States Dollar",
      value: "USD",
    },
    {
      label: "EUR - Euro",
      value: "EUR",
    },

    {
      label: "LYD - Libyan Dinar",
      value: "LYD",
    },
    {
      label: "MAD - Moroccan Dirham",
      value: "MAD",
    },
    {
      label: "TND - Tunisian Dinar",
      value: "TND",
    },

  ]

  const checkcurrency = localStorage.getItem("metaads_currency_revenue_rate")
 


  const currencyspend = localStorage.getItem("metaads_customer_currency_code")
  function handleSelectGroup(selectedOption) {

    setselectedGroup(selectedOption)
    axios.get(`https://v6.exchangerate-api.com/v6/c0fd644ef51cdd9e2aed5b22/latest/${currencyspend}`)
      .then(response => {
        localStorage.setItem("meta_revenue_currency", selectedOption.value);
        const revenue_currency = response.data.conversion_rates[selectedOption.value]
        localStorage.setItem("metaads_currency_revenue_rate", revenue_currency);
        // Handle the response data here
      })
  }
  const currencyrevenuerate = localStorage.getItem("metaads_currency_revenue_rate")
  useEffect(() => {
    let isSubscribed = true;
    var Tdates = [];
    var Tsales = [];
    var TgrossProfit = [];
    var Tcps = [];
    var Taov = [];
    var Tspend = [];
    var Treach = [];
    var Tclicks = [];
    var Timpression = [];
    var TnbrOforders = [];
    var Tconversions = [];
    var TconversionsValue = [];
    var TLandingPageView = [];
    var lastTdates = [];
    var lastTsales = [];
    var lastTgrossProfit = [];
    var lastTcps = [];
    var lastTspend = [];
    var lastTreach = [];
    var lastTclicks = [];
    var lastTimpression = [];
    var lastTaov = [];
    var lastTnbrOforders = [];
    var lastMainS = [];
    var lastMainGP = [];
    var lastTconversions = [];
    var lastTconversionsValue = [];
    var lastTLandingPageView = [];
    var mainS = [];
    var mainGP = [];
    if (dataDashboard.length !== 0) {
      dataDashboard?.reports.map((elem) => {
        Tsales.push(elem.sum_subtotal ? elem.sum_subtotal.toFixed(2) : 0)
        TgrossProfit.push(elem.gross_rev ? elem.gross_rev.toFixed(2) : 0)
        Tcps.push(elem.cps ? (elem.cps).toFixed(2) : 0)
        Tspend.push(elem.spend ? (elem.spend).toFixed(2) : 0)
        Taov.push(elem.aov ? elem.aov.toFixed(2) : 0)
        Tclicks.push(elem.clicks ? elem.clicks.toFixed(2) : 0)
        Treach.push(elem.reach ? (elem.reach).toFixed(2) : 0)
        Timpression.push(elem.impressions ? (elem.impressions).toFixed(2) : 0)
        Tconversions.push(elem.conversions ? (elem.conversions).toFixed(2) : 0)
        TLandingPageView.push(elem.landing_page_view ? (elem.landing_page_view) : 0)
        TconversionsValue.push(elem.conversions_value ? (elem.conversions_value).toFixed(2) : 0)
        TnbrOforders.push(elem.total_num_orders ? elem.total_num_orders : 0)
        Tdates.push(elem.date ? elem.date : null);
      }
      );
      dataDashboard?.lastPeriodReport.map((elem) => {
        lastTsales.push(elem.sum_subtotal ? elem.sum_subtotal.toFixed(2) : 0)
        lastTgrossProfit.push(elem.gross_rev ? elem.gross_rev.toFixed(2) : 0)
        lastTcps.push(elem.cps ? elem.cps.toFixed(2) : 0)
        lastTspend.push(elem.spend ? (elem.spend).toFixed(2) : 0)
        lastTreach.push(elem.reach ? (elem.reach).toFixed(2) : 0)
        lastTclicks.push(elem.clicks ? elem.clicks.toFixed(2) : 0)
        lastTimpression.push(elem.impressions ? elem.impressions.toFixed(2) : 0)
        lastTconversions.push(elem.conversions ? (elem.conversions) : 0)
        lastTconversionsValue.push(elem.conversions_value ? (elem.conversions_value).toFixed(2) : 0)
        lastTLandingPageView.push(elem.landingPageView ? (elem.landingPageView) : 0)
        lastTaov.push(elem.aov ? elem.aov.toFixed(2) : 0)
        lastTnbrOforders.push(elem.total_num_orders ? elem.total_num_orders : 0)
        lastTdates.push(elem.date ? elem.date : null);
      });
      dataDashboard?.lastPeriodReport.map((val, i) => {
        var difference = daysBetween(new Date(dateRage.date[0]), new Date(dateRage.date[1]));
        var x_date = new Date(dataDashboard.lastPeriodReport[i].date);
        comparedTo !== 'year' ?
          x_date.setDate(x_date.getDate() + difference + 1)
          :
          x_date.setDate(x_date.getDate() + difference)
        if (comparedTo !== 'year') {
          lastMainS.push(
            new Date(dateRage.date[0]).toLocaleString("fr-FR").split(" ")[0] !==
              new Date(dateRage.date[1]).toLocaleString("fr-FR").split(" ")[0]
              ? dataDashboard && dataDashboard.lastPeriodReport.length > 0
                ? {
                  x:
                    x_date.toISOString().split("-")[0] +
                    "-" +
                    x_date.toISOString().split("-")[1] +
                    "-" +
                    x_date.toISOString().split("-")[2].split("T")[0] +
                    "  GMT",
                  y: val.sum_subtotal ? val.sum_subtotal : 0,
                }
                : { x: "", y: 0 }
              : dataDashboard && dataDashboard.lastPeriodReport.length > 0
                ? {
                  x: new Date(dataDashboard?.reports[i]?.date).getTime(),
                  y: val.sum_subtotal ? val.sum_subtotal : 0,
                }
                : { x: "", y: 0 }
          );
        } else {
          let y_date = dateRage.date[0].getFullYear()
          lastMainS.push(
            new Date(dateRage.date[0]).toLocaleString("fr-FR").split(" ")[0] !==
              new Date(dateRage.date[1]).toLocaleString("fr-FR").split(" ")[0]
              ? dataDashboard && dataDashboard.lastPeriodReport.length > 0
                ? {
                  x:
                    y_date +
                    "-" +
                    x_date.toISOString().split("-")[1] +
                    "-" +
                    x_date.toISOString().split("-")[2].split("T")[0] +
                    "  GMT",
                  y: val.sum_subtotal ? val.sum_subtotal : 0,
                }
                : { x: "", y: 0 }
              : dataDashboard && dataDashboard.lastPeriodReport.length > 0
                ? {
                  x: new Date(dataDashboard?.reports[i]?.date).getTime(),
                  y: val.sum_subtotal ? val.sum_subtotal : 0,
                }
                : { x: "", y: 0 }
          );
        }
      });
      dataDashboard?.lastPeriodReport.map((val, i) => {
        var difference = daysBetween(new Date(dateRage.date[0]), new Date(dateRage.date[1]));
        var x_date = new Date(dataDashboard.lastPeriodReport[i].date);
        comparedTo !== 'year' ?
          x_date.setDate(x_date.getDate() + difference + 1)
          :
          x_date.setDate(x_date.getDate() + difference)
        if (comparedTo !== 'year') {
          lastMainGP.push(
            new Date(dateRage.date[0]).toLocaleString("fr-FR").split(" ")[0] !==
              new Date(dateRage.date[1]).toLocaleString("fr-FR").split(" ")[0]
              ? dataDashboard && dataDashboard.lastPeriodReport.length > 0
                ? {
                  x:
                    x_date.toISOString().split("-")[0] +
                    "-" +
                    x_date.toISOString().split("-")[1] +
                    "-" +
                    x_date.toISOString().split("-")[2].split("T")[0] +
                    "  GMT",
                  y: val.gross_rev ? val.gross_rev : 0,
                }
                : { x: "", y: 0 }
              : dataDashboard && dataDashboard.lastPeriodReport.length > 0
                ? {
                  x: new Date(dataDashboard?.reports[i]?.date).getTime(),
                  y: val.gross_rev ? val.gross_rev : 0,
                }
                : { x: "", y: 0 }
          );
        }
        else {
          let y_date = dateRage.date[0].getFullYear()
          lastMainGP.push(
            new Date(dateRage.date[0]).toLocaleString("fr-FR").split(" ")[0] !==
              new Date(dateRage.date[1]).toLocaleString("fr-FR").split(" ")[0]
              ? dataDashboard && dataDashboard.lastPeriodReport.length > 0
                ? {
                  x:
                    y_date +
                    "-" +
                    x_date.toISOString().split("-")[1] +
                    "-" +
                    x_date.toISOString().split("-")[2].split("T")[0] +
                    "  GMT",
                  y: val.gross_rev ? val.gross_rev : 0,
                }
                : { x: "", y: 0 }
              : dataDashboard && dataDashboard.lastPeriodReport.length > 0
                ? {
                  x: new Date(dataDashboard?.reports[i]?.date).getTime(),
                  y: val.gross_rev ? val.gross_rev : 0,
                }
                : { x: "", y: 0 }
          );
          console.log("")
        }
      });
      dataDashboard?.reports.map((val, i) => {
        var x = new Date(dataDashboard.reports[i].date).toUTCString();
        mainS.push(
          new Date(dateRage.date[0]).toLocaleString("fr-FR").split(" ")[0] !==
            new Date(dateRage.date[1]).toLocaleString("fr-FR").split(" ")[0]
            ? dataDashboard && dataDashboard.reports.length > 0
              ? {
                x:
                  dataDashboard.reports[i].date.split("-")[0] +
                  "-" +
                  dataDashboard.reports[i].date.split("-")[1] +
                  "-" +
                  (parseInt(dataDashboard.reports[i].date.split("-")[2].split("T")[0])) +
                  "  GMT",
                y: val.sum_subtotal ? val.sum_subtotal : 0,
              }
              : { x: "", y: 0 }
            : dataDashboard && dataDashboard.reports.length > 0
              ? {
                x: new Date(dataDashboard.reports[i].date).getTime(),
                y: val.sum_subtotal ? val.sum_subtotal : 0,
              }
              : { x: "", y: 0 }
        );
      });
      dataDashboard?.reports.map((val, i) => {
        mainGP.push(
          new Date(dateRage.date[0]).toLocaleString("fr-FR").split(" ")[0] !==
            new Date(dateRage.date[1]).toLocaleString("fr-FR").split(" ")[0]
            ? dataDashboard && dataDashboard.reports.length > 0
              ? {
                x:
                  dataDashboard.reports[i].date.split("-")[0] +
                  "-" +
                  dataDashboard.reports[i].date.split("-")[1] +
                  "-" +
                  (parseInt(dataDashboard.reports[i].date.split("-")[2].split("T")[0])) +
                  "  GMT",
                y: val.gross_rev ? val.gross_rev : 0,
              }
              : { x: "", y: 0 }
            : dataDashboard && dataDashboard.reports.length > 0
              ? {
                x: new Date(dataDashboard.reports[i].date).getTime(),
                y: val.gross_rev ? val.gross_rev : 0,
              }
              : { x: "", y: 0 }
        );
      });
    }
    setSales(Tsales);
    setGrossProfit(TgrossProfit);
    setCps(Tcps);
    setClicks(Tclicks);
    setSpend(Tspend);
    setReach(Treach);
    setImpression(Timpression);
    setConversions(Tconversions);
    setConversionsValue(TconversionsValue);
    setLandingPageView(TLandingPageView);
    setAov(Taov);
    setNmbrOfOrders(TnbrOforders);
    setLastSales(lastTsales);
    setLastGrossProfit(lastTgrossProfit);
    setLastCps(lastTcps);
    setLastClicks(lastTclicks);
    setLastSpend(lastTspend);
    setLastImpression(lastTimpression);
    setLastConversions(lastTconversions);
    setLastConversionsValue(lastTconversionsValue);
    setLastReach(lastTreach);
    setLastAov(lastTaov);
    setLastNmbrOfOrders(lastTnbrOforders);
    setLastMainGrossProfit(lastMainGP);
    setLastMainSales(lastMainS);
    setLastLandingPageView(lastLandingPageView);
    setMainSales(mainS);
    setMainGrossProfit(mainGP);
    setDates(Tdates);
    setLastDates(lastTdates);
    return () => (isSubscribed = false);
  }, [dataDashboard]);
  var op1 = {
    chart: {
      toolbar: {
        followCursor: true,
        show: false,
        tools: {
          download: true,
          selection: true,
          zoom: true,
          zoomin: true,
          zoomout: true,
          pan: true,
          reset: true,
        },
        tooltip: {
          shared: true,
          intersect: true,
          followCursor: true,
          x: {
            show: false,
          },
        },
        autoSelected: "zoom",
      },
      type: "area",
      height: 120,
      sparkline: {
        enabled: true,
      },
    },
    markers: {
      size: 0,
    },
    stroke: {
      curve: "straight",
      width: 2.5,
    },
    fill: {
      opacity: 1,
    },
    series: [{ name: "TotalAdCost", data: spend || [] }, { name: "Compared To", data: lastSpend || [] }],
    xaxis: {
      crosshairs: {
        width: 1,
      },
    },
    yaxis: {
      show: false,
    },

    colors: ['#0044BD', '#F6B43B'],
  };
  var op2 = {
    chart: {
      toolbar: {
        followCursor: true,
        show: false,
        tools: {
          download: true,
          selection: true,
          zoom: true,
          zoomin: true,
          zoomout: true,
          pan: true,
          reset: true,
        },
        tooltip: {
          shared: true,
          intersect: true,
          followCursor: true,
          x: {
            show: false,
          },
        },
        autoSelected: "zoom",
      },
      type: "area",
      height: 120,
      sparkline: {
        enabled: true,
      },
    },
    markers: {
      size: 0,
    },
    stroke: {
      curve: "straight",
      width: 2.5,
    },
    fill: {
      opacity: 1,
    },
    series: [{ name: "conversions_value", data: conversionsValue || [] }, { name: "Compared To", data: lastConversionsValue || [] }],
    xaxis: {
      crosshairs: {
        width: 1,
      },
    },
    yaxis: {
      show: false,
    },

    colors: ['#0044BD', '#F6B43B'],
  };
  var op3 = {
    chart: {
      toolbar: {
        followCursor: true,
        show: false,
        tools: {
          download: true,
          selection: true,
          zoom: true,
          zoomin: true,
          zoomout: true,
          pan: true,
          reset: true,
        },
        tooltip: {
          shared: true,
          intersect: true,
          followCursor: true,
          x: {
            show: false,
          },
        },
        autoSelected: "zoom",
      },
      type: "area",
      height: 120,
      sparkline: {
        enabled: true,
      },
    },
    markers: {
      size: 0,
    },
    stroke: {
      curve: "straight",
      width: 2.5,
    },
    fill: {
      opacity: 1,
    },
    series: [{ name: "Conversions", data: conversions || [] }, { name: "Compared To", data: lastConversions || [] }],
    xaxis: {
      crosshairs: {
        width: 1,
      },
    },
    yaxis: {
      show: false,
    },

    colors: ['#0044BD', '#F6B43B'],
  };
  var op4 = {
    chart: {
      toolbar: {
        followCursor: true,
        show: false,
        tools: {
          download: true,
          selection: true,
          zoom: true,
          zoomin: true,
          zoomout: true,
          pan: true,
          reset: true,
        },
        tooltip: {
          shared: true,
          intersect: true,
          followCursor: true,
          x: {
            show: false,
          },
        },
        autoSelected: "zoom",
      },
      type: "area",
      height: 120,
      sparkline: {
        enabled: true,
      },
    },
    markers: {
      size: 0,
    },
    stroke: {
      curve: "straight",
      width: 2.5,
    },
    fill: {
      opacity: 1,
    },
    series: [{ name: "CPA", data: [spend, conversions] || [] }, { name: "Compared To", data: [lastSpend, lastConversions] || [] }],
    xaxis: {
      crosshairs: {
        width: 1,
      },
    },
    yaxis: {
      show: false,
    },

    colors: ['#0044BD', '#F6B43B'],
  };
  var op5 = {
    chart: {
      toolbar: {
        followCursor: true,
        show: false,
        tools: {
          download: true,
          selection: true,
          zoom: true,
          zoomin: true,
          zoomout: true,
          pan: true,
          reset: true,
        },
        tooltip: {
          shared: true,
          intersect: true,
          followCursor: true,
          x: {
            show: false,
          },
        },
        autoSelected: "zoom",
      },
      type: "area",
      height: 120,
      sparkline: {
        enabled: true,
      },
    },
    markers: {
      size: 0,
    },
    stroke: {
      curve: "straight",
      width: 2.5,
    },
    fill: {
      opacity: 1,
    },
    series: [{ name: "AOV", data: [conversionsValue, conversions] || [] }, { name: "Compared To", data: [lastConversionsValue, lastConversions] || [] }],
    xaxis: {
      crosshairs: {
        width: 1,
      },
    },
    yaxis: {
      show: false,
    },

    colors: ['#0044BD', '#F6B43B'],
  };
  var op6 = {
    chart: {
      toolbar: {
        followCursor: true,
        show: false,
        tools: {
          download: true,
          selection: true,
          zoom: true,
          zoomin: true,
          zoomout: true,
          pan: true,
          reset: true,
        },
        tooltip: {
          shared: true,
          intersect: true,
          followCursor: true,
          x: {
            show: false,
          },
        },
        autoSelected: "zoom",
      },
      type: "area",
      height: 120,
      sparkline: {
        enabled: true,
      },
    },
    markers: {
      size: 0,
    },
    stroke: {
      curve: "straight",
      width: 2.5,
    },
    fill: {
      opacity: 1,
    },
    series: [{ name: "CR%", data: [conversions, landingPageView] || [] }, { name: "Compared To", data: [lastConversions, lastLandingPageView] || [] }],
    xaxis: {
      crosshairs: {
        width: 1,
      },
    },
    yaxis: {
      show: false,
    },

    colors: ['#0044BD', '#F6B43B'],
  };
  var op7 = {
    chart: {
      toolbar: {
        followCursor: true,
        show: false,
        tools: {
          download: true,
          selection: true,
          zoom: true,
          zoomin: true,
          zoomout: true,
          pan: true,
          reset: true,
        },
        tooltip: {
          shared: true,
          intersect: true,
          followCursor: true,
          x: {
            show: false,
          },
        },
        autoSelected: "zoom",
      },
      type: "area",
      height: 120,
      sparkline: {
        enabled: true,
      },
    },
    markers: {
      size: 0,
    },
    stroke: {
      curve: "straight",
      width: 2.5,
    },
    fill: {
      opacity: 1,
    },
    series: [{ name: "ROAs", data: [conversionsValue, spend] || [] }, { name: "Compared To", data: [lastConversionsValue, lastSpend] || [] }],
    xaxis: {
      crosshairs: {
        width: 1,
      },
    },
    yaxis: {
      show: false,
    },

    colors: ['#0044BD', '#F6B43B'],
  };
  var op8 = {
    chart: {
      toolbar: {
        followCursor: true,
        show: false,
        tools: {
          download: true,
          selection: true,
          zoom: true,
          zoomin: true,
          zoomout: true,
          pan: true,
          reset: true,
        },
        tooltip: {
          shared: true,
          intersect: true,
          followCursor: true,
          x: {
            show: false,
          },
        },
        autoSelected: "zoom",
      },
      type: "area",
      height: 120,
      sparkline: {
        enabled: true,
      },
    },
    markers: {
      size: 0,
    },
    stroke: {
      curve: "straight",
      width: 2.5,
    },
    fill: {
      opacity: 1,
    },
    series: [{ name: "CPC", data: [spend, clicks] || [] }, { name: "Compared To", data: [lastSpend, lastClicks] || [] }],
    xaxis: {
      crosshairs: {
        width: 1,
      },
    },
    yaxis: {
      show: false,
    },

    colors: ['#0044BD', '#F6B43B'],
  };
  var op9 = {
    chart: {
      toolbar: {
        followCursor: true,
        show: false,
        tools: {
          download: true,
          selection: true,
          zoom: true,
          zoomin: true,
          zoomout: true,
          pan: true,
          reset: true,
        },
        tooltip: {
          shared: true,
          intersect: true,
          followCursor: true,
          x: {
            show: false,
          },
        },
        autoSelected: "zoom",
      },
      type: "area",
      height: 120,
      sparkline: {
        enabled: true,
      },
    },
    markers: {
      size: 0,
    },
    stroke: {
      curve: "straight",
      width: 2.5,
    },
    fill: {
      opacity: 1,
    },
    series: [{ name: "Impressions", data: impressions || [] }, { name: "Compared To", data: lastImpression || [] }],
    xaxis: {
      crosshairs: {
        width: 1,
      },
    },
    yaxis: {
      show: false,
    },

    colors: ['#0044BD', '#F6B43B'],
  };
  var op10 = {
    chart: {
      toolbar: {
        followCursor: true,
        show: false,
        tools: {
          download: true,
          selection: true,
          zoom: true,
          zoomin: true,
          zoomout: true,
          pan: true,
          reset: true,
        },
        tooltip: {
          shared: true,
          intersect: true,
          followCursor: true,
          x: {
            show: false,
          },
        },
        autoSelected: "zoom",
      },
      type: "area",
      height: 120,
      sparkline: {
        enabled: true,
      },
    },
    markers: {
      size: 0,
    },
    stroke: {
      curve: "straight",
      width: 2.5,
    },
    fill: {
      opacity: 1,
    },
    series: [{ name: "Reach", data: reach || [] }, { name: "Compared To", data: lastReach || [] }],
    xaxis: {
      crosshairs: {
        width: 1,
      },
    },
    yaxis: {
      show: false,
    },

    colors: ['#0044BD', '#F6B43B'],
  };
  var op11 = {
    chart: {
      toolbar: {
        followCursor: true,
        show: false,
        tools: {
          download: true,
          selection: true,
          zoom: true,
          zoomin: true,
          zoomout: true,
          pan: true,
          reset: true,
        },
        tooltip: {
          shared: true,
          intersect: true,
          followCursor: true,
          x: {
            show: false,
          },
        },
        autoSelected: "zoom",
      },
      type: "area",
      height: 120,
      sparkline: {
        enabled: true,
      },
    },
    markers: {
      size: 0,
    },
    stroke: {
      curve: "straight",
      width: 2.5,
    },
    fill: {
      opacity: 1,
    },
    series: [{ name: "Clicks", data: clicks || [] }, { name: "Compared To", data: lastClicks || [] }],
    xaxis: {
      crosshairs: {
        width: 1,
      },
    },
    yaxis: {
      show: false,
    },

    colors: ['#0044BD', '#F6B43B'],
  };
  var op12 = {
    chart: {
      toolbar: {
        followCursor: true,
        show: false,
        tools: {
          download: true,
          selection: true,
          zoom: true,
          zoomin: true,
          zoomout: true,
          pan: true,
          reset: true,
        },
        tooltip: {
          shared: true,
          intersect: true,
          followCursor: true,
          x: {
            show: false,
          },
        },
        autoSelected: "zoom",
      },
      type: "area",
      height: 120,
      sparkline: {
        enabled: true,
      },
    },
    markers: {
      size: 0,
    },
    stroke: {
      curve: "straight",
      width: 2.5,
    },
    fill: {
      opacity: 1,
    },
    series: [{ name: "CTR", data: [clicks, impressions] || [] }, { name: "Compared To", data: [lastClicks, lastImpression] || [] }],
    xaxis: {
      crosshairs: {
        width: 1,
      },
    },
    yaxis: {
      show: false,
    },

    colors: ['#0044BD', '#F6B43B'],
  };
  const [secCards, setsecCards] = useState([]);
  useEffect(() => {
    let isSubscribed = true;
    setsecCards([
      {
        id: 2, name: "TotalAdCost",
        options: op1,
        series: op1.series,
        dates: dates, lastDates: lastDates
      },
      { id: 3, name: "conversions_value", options: op2, series: op2.series, lastseries: lastConversionsValue, dates: dates, lastDates: lastDates },
      { id: 4, name: "Conversions", options: op3, series: op3.series, lastseries: lastConversions, dates: dates, lastDates: lastDates },
      { id: 5, name: "CPA", options: op4, series: op4.series, dates: dates, lastDates: lastDates },
      { id: 6, name: "AOV", options: op5, series: op5.series, dates: dates, lastDates: lastDates },
      { id: 7, name: "CR%", options: op6, series: op6.series, dates: dates, lastDates: lastDates },
      { id: 8, name: "ROAs", options: op7, series: op7.series, dates: dates, lastDates: lastDates },
      { id: 9, name: "CPC", options: op8, series: op8.series, dates: dates, lastDates: lastDates },
      { id: 10, name: "Impressions", options: op9, series: op9.series, lastseries: lastImpression, dates: dates, lastDates: lastDates },
      { id: 11, name: "Reach", options: op10, series: op10.series, lastseries: lastReach, dates: dates, lastDates: lastDates },
      { id: 12, name: "Clicks", options: op11, series: op11.series, lastseries: lastClicks, dates: dates, lastDates: lastDates },
      { id: 13, name: "CTR", options: op12, series: op12.series, dates: dates, lastDates: lastDates },
    ])
    return () => (isSubscribed = false);
  }, [sales, grossProfit, spend, aov, nmbrOfOrders, clicks, lastClicks, lastSales, lastGrossProfit, lastSpend, lastAov, lastNmbrOfOrders, reach, lastReach, impressions, lastImpression, dates, lastDates, conversions, lastConversions, conversionsValue, lastConversionsValue]);
  const getCTR = (t1, t2, diff) => {
    if (diff === 0) {
      var totalImpressions = t1.length > 0 ? t1.map(Number).reduce((x, y) => {
        return x + y;
      }) : 0;
      return (totalImpressions / parseFloat(t2))
    } else {
      if (t1 instanceof Array && t2 instanceof Array && t1.length > 0 && t2.length > 0) {
        var totalClicks = t2.reduce((x, y) => { return parseFloat(x) + parseFloat(y) })
        var totalImpressions = t1.reduce((x, y) => { return parseFloat(x) + parseFloat(y) })
        var ctr = (totalClicks / totalImpressions * 100);
        return ctr;
      } else if (t2 > 0) {
        return ((parseFloat(t2) / parseFloat(t1) * 100));
      }
    }
    return 0;
  }
  const getLastCTR = (t1, t2, diff) => {
    if (diff === 0) {
      var lastTotalCps = t1.length > 0 ? t1.map(Number).reduce((x, y) => {
        return x + y;
      }) : 0;
      return ((lastTotalCps / parseFloat(t2)))
    } else {
      if (t1 instanceof Array && t2 instanceof Array && t1.length > 0 && t2.length > 0) {
        var lasttotalClicks = t2.reduce((x, y) => { return parseFloat(x) + parseFloat(y) })
        var lasttotalImpressions = t1.reduce((x, y) => { return parseFloat(x) + parseFloat(y) })
        var lastctr = (lasttotalClicks / lasttotalImpressions * 100);
        return lastctr;
      } else if (t2 > 0) {
        return ((parseFloat(t2) / parseFloat(t1) * 100));
      } else if (t2 > 0) {
        return ((parseFloat(t2) / parseFloat(t1)));
      }
      return 0;
    }
  }
  const getCPC = (t1, t2, diff) => {
    if (diff === 0) {
      var totalSpend = t1.length > 0 ? t1.map(Number).reduce((x, y) => {
        return x + y;
      }) : 0;
      return ((totalSpend / parseFloat(t2)))
    } else {
      if (t1 instanceof Array && t2 instanceof Array && t1.length > 0 && t2.length > 0) {
        var totalSpend = t2.reduce((x, y) => { return parseFloat(x) + parseFloat(y) })
        var totalClicks = t1.reduce((x, y) => { return parseFloat(x) + parseFloat(y) })
        var cpc = (totalSpend / totalClicks);
        return cpc;
      } else if (t2 > 0) {
        return ((parseFloat(t2) / parseFloat(t1)));
      }
      return 0;
    }
  }
  const getLastCPC = (t1, t2, diff) => {
    if (diff === 0) {
      var lasttotalSpend = t1.length > 0 ? t1.map(Number).reduce((x, y) => {
        return x + y;
      }) : 0;
      return ((lasttotalSpend / parseFloat(t1)))
    } else {
      if (t1 instanceof Array && t2 instanceof Array && t1.length > 0 && t2.length > 0) {
        var lasttotalSpend = t2.reduce((x, y) => { return parseFloat(x) + parseFloat(y) })
        var lasttotalClicks = t1.reduce((x, y) => { return parseFloat(x) + parseFloat(y) })
        var lastcpc = (lasttotalSpend / lasttotalClicks);
        return lastcpc;
      } else if (t2 > 0) {
        return ((parseFloat(t2) / parseFloat(t1)));
      }
      return 0;
    }
  }
  const getCR = (t1, t2, diff) => {
    if (diff === 0) {
      var totalConversion = t1.length > 0 ? t1.map(Number).reduce((x, y) => {
        return x + y;
      }) : 0;
      return (totalConversion / parseFloat(t2) * 100)
    } else {
      if (t1 instanceof Array && t2 instanceof Array && t1.length > 0 && t2.length > 0) {
        var totalLandingPageView = t1.reduce((x, y) => { return parseFloat(x) + parseFloat(y) })
        var totalConversion = t2.reduce((x, y) => { return parseFloat(x) + parseFloat(y) })
        var CRCalculated = ((totalConversion / totalLandingPageView) * 100);
        return (CRCalculated);
      } else if (t2 > 0) {
        return (parseFloat(t2) / parseFloat(t1) * 100);
      }
      return 0;
    }
  }
  const getLastCR = (t1, t2, diff) => {
    if (diff === 0) {
      var lastTotalLandingPageView = t1.length > 0 ? t1.map(Number).reduce((x, y) => {
        return x + y;
      }) : 0;
      return ((lastTotalConversions / parseFloat(t2)))
    } else {
      if (t1 instanceof Array && t2 instanceof Array && t1.length > 0 && t2.length > 0) {
        var lastTotalLandingPageView = t1.reduce((x, y) => { return parseFloat(x) + parseFloat(y) })
        var lastTotalConversions = t2.reduce((x, y) => { return parseFloat(x) + parseFloat(y) })
        var lastCRCalculated = ((lastTotalConversions / lastTotalLandingPageView) * 100);
        // console.log("lastTotalLandingPageView", lastTotalLandingPageView);
        // console.log("lastTotalConversions", lastTotalConversions);
        return (lastCRCalculated);
      }
      else if (t2 > 0) {
        return ((parseFloat(t2) / parseFloat(t1)));
      }
      return 0
    }
  }
  const getAOV = (t1, t2, diff) => {
    if (diff === 0) {
      var totalConversion = t1.length > 0 ? t1.map(Number).reduce((x, y) => {
        return x + y;
      }) : 0;
      return (totalConversion / parseFloat(t2))
    } else {
      if (t1 instanceof Array && t2 instanceof Array && t1.length > 0 && t2.length > 0) {
        var totalconversionvalue = t2.reduce((x, y) => { return parseFloat(x) + parseFloat(y) })
        var totalConversion = t1.reduce((x, y) => { return parseFloat(x) + parseFloat(y) })
        return (totalconversionvalue / totalConversion);
      } else if (t2 > 0) {
        return ((parseFloat(t2) / parseFloat(t1)));
      } else if (t2 > 0) {
        return ((parseFloat(t2) / parseFloat(t1)));
      }
      return 0;
    }
  }
  const getLastAOV = (t1, t2, diff) => {
    if (diff === 0) {
      var lastTotalConversions = t1.length > 0 ? t1.map(Number).reduce((x, y) => {
        return x + y;
      }) : 0;
      return ((lastTotalConversions / parseFloat(t2)))
    } else {
      if (t1 instanceof Array && t2 instanceof Array && t1.length > 0 && t2.length > 0) {
        var lastTotalconversionvalue = t2.reduce((x, y) => { return parseFloat(x) + parseFloat(y) })
        var lastTotalConversions = t1.reduce((x, y) => { return parseFloat(x) + parseFloat(y) })
        var lastAOVCalculated = (lastTotalconversionvalue / lastTotalConversions)
        return lastAOVCalculated;
      } else if (t2 > 0) {
        return ((parseFloat(t2) / parseFloat(t1)));
      }
      return 0
    }
  }
  const getROAS = (t1, t2, diff) => {
    if (diff === 0) {
      var totalCps = t1.length > 0 ? t1.map(Number).reduce((x, y) => {
        return x + y;
      }) : 0;
      console.log("totalSales", totalCps);
      return ((totalCps / parseFloat(t2)))
    } else {
      if (t1 instanceof Array && t2 instanceof Array && t1.length > 0 && t2.length > 0) {
        var totalconversionvalue = t2.reduce((x, y) => { return parseFloat(x) + parseFloat(y) })
        var totalCps = t1.reduce((x, y) => { return parseFloat(x) + parseFloat(y) })
        return ((totalconversionvalue * currencyrevenuerate) / totalCps);
      } else if (t2 > 0) {
        return ((parseFloat(t2) / parseFloat(t1)));
      }
      return 0;
    }
  }
  const getLastROAS = (t1, t2, diff) => {
    if (diff === 0) {
      var lastTotalCps = t1.length > 0 ? t1.map(Number).reduce((x, y) => {
        return x + y;
      }) : 0;
      return ((lastTotalCps / parseFloat(t2)))
    } else {
      if (t1 instanceof Array && t2 instanceof Array && t1.length > 0 && t2.length > 0) {
        var lastTotalconversionvalue = t2.reduce((x, y) => { return parseFloat(x) + parseFloat(y) })
        var lastTotalCps = t1.reduce((x, y) => { return parseFloat(x) + parseFloat(y) });
        var lastTotalROAS = (lastTotalconversionvalue / lastTotalCps);
        return (((lastTotalconversionvalue / currencyrevenuerate) / lastTotalCps));
      } else if (t2 > 0) {
        return ((parseFloat(t2) / parseFloat(t1)));
      }
      return 0
    }
  }
  const getCPA = (t1, t2, diff) => {
    if (diff === 0) {
      var totalConversion = t1.length > 0 ? t1.map(Number).reduce((x, y) => {
        return x + y;
      }) : 0;
      return ((totalCps / parseFloat(t2)))
    } else {
      if (t1 instanceof Array && t2 instanceof Array && t1.length > 0 && t2.length > 0) {
        var totalCps = t2.reduce((x, y) => { return parseFloat(x) + parseFloat(y) });
        var totalConversion = t1.reduce((x, y) => { return parseFloat(x) + parseFloat(y) });
        var cpa = (totalCps / totalConversion)
        return cpa;
      } else if (t2 > 0) {
        return ((parseFloat(t2) / parseFloat(t1)));
      }
      return 0;
    }
  }
  const getLastCPA = (t1, t2, diff) => {
    if (diff === 0) {
      var lastTotalConversions = t1.length > 0 ? t1.map(Number).reduce((x, y) => {
        return x + y;
      }) : 0;
      return ((lastTotalCps / parseFloat(t2)))
    } else {
      if (t1 instanceof Array && t2 instanceof Array && t1.length > 0 && t2.length > 0) {
        var lastTotalCps = t2.reduce((x, y) => { return parseFloat(x) + parseFloat(y) })
        var lastTotalConversions = t1.reduce((x, y) => { return parseFloat(x) + parseFloat(y) })
        var lastCPACalculated = (lastTotalCps / lastTotalConversions)
        return lastCPACalculated
      } else if (t2 > 0) {
        return ((parseFloat(t2) / parseFloat(t1)));
      }
      return 0
    }
  }
  const handleCompareToggle = (compare_to) => {
    setComparedTo(compare_to)
    if (comparedTo === compare_to) {
      setComparedTo("")
    }
  }
  useEffect(() => {
    if (currentFocus[1] === 0) {
      setDateRange({
        date: [
          new Date(new Date(chosedDate[0].startDate.toLocaleDateString('en-US', { timeZone: props.timeZone ? props.timeZone : "UTC" }))),
          new Date(new Date(chosedDate[0].endDate.toLocaleDateString('en-US', { timeZone: props.timeZone ? props.timeZone : "UTC" })))
        ]
      });
      setToggleDate(false)
    }

  }, [currentFocus])
  useEffect(() => {
    window.localStorage.setItem("start", chosedDate[0].startDate);
    window.localStorage.setItem("end", chosedDate[0].endDate);
  }, [chosedDate]);

  // facebook ads charts 
  const getOption = () => {
    return {
      tooltip: {
        trigger: "axis",
      },
      grid: {
        zlevel: 0,
        x: 50,
        x2: 50,
        y: 30,
        y2: 30,
        borderWidth: 0,
      },
      xAxis: {
        type: "category",
        data: dataDashboard?.reports?.map((item) => {
          const date = new Date(item.date);
          return date.toLocaleDateString('fr-FR', {
            day: '2-digit',
            month: 'long', // Use 'long' to display the month as "aout" instead of "août"
          });
        }) || [],

        axisLable: {
          color: "#ffffff",
        },
        axisLine: {
          lineStyle: {
            color: "#74788d",
          },
        },
      },
      yAxis: {
        type: "value",
        axisLable: {
          color: "#ffffff",
        },
        axisLine: {
          lineStyle: {
            color: "#74788d",
          },
        },
      },
      series: [
        {
          name: selectedSeries,
          data: getSelectedSeriesData(selectedSeries) || [], // Pass the selected series name to the function
          type: "line"
        },
      ],
      color: ["#556ee6"],
      textStyle: {
        color: ["#74788d"],
      },
    };
  };
  // options 
  const [selectedSeries, setSelectedSeries] = useState("spend");

  const handleDropdownChange = (event) => {
    setSelectedSeries(event.target.value);
  };
  // Function to get the data array for the selected series
  const getSelectedSeriesData = (selectedSeries) => {
    switch (selectedSeries) {
      case "spend":
        return spend;
      case "conversionsValue":
        return conversionsValue;
      case "conversions":
        return conversions;
      case "impressions":
        return impressions;
      case "reach":
        return reach;
      case "clicks":
        return clicks;
    }
  };
  // get metrics by campaign 

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>{props.t("Ads Dashboard")} | Live-Metrics - E-com SaaS</title>
        </MetaTags>
        <div className="container-fluid">
          <Breadcrumbs title={props.t("Ads Dashboard")} breadcrumbItem={props.t("Facebook Ads Dashboard")} pageHeading={props.t("Ads Dashboard")} />
          <Row>
        
            <div className="d-flex align-items-start">
              <div className="flex-grow-1">
                <Col md={4}>
                  <FormGroup>
                    <InputGroup>
                      <div className="DateContainer">
                        <div>
                          <div className="divDateContainer">
                            <div ref={wrapperRef}>
                              <button
                                className="btn btn-light"
                                style={{ marginTop: '-10px' }}
                                onClick={() => setToggleDate(!toggleDate)}
                              >
                                {chosedDate[0] !== null
                                  ? [
                                    chosedDate[0].startDate.toLocaleString(
                                      'en',
                                      {
                                        month: 'short',
                                      }
                                    ),
                                    ' ',
                                    chosedDate[0].startDate.getDate(),
                                    ' ',
                                    chosedDate[0].startDate.getFullYear(),
                                    '  -  ',
                                    chosedDate[0].endDate &&
                                    chosedDate[0].endDate.toLocaleString(
                                      'en',
                                      {
                                        month: 'short',
                                      }
                                    ),
                                    ' ',
                                    chosedDate[0].endDate &&
                                    chosedDate[0].endDate.getDate(),
                                    ' ',
                                    chosedDate[0].endDate &&
                                    chosedDate[0].endDate.getFullYear(),
                                  ]
                                  : null}
                              </button>
                              {toggleDate && (
                                <div 
                                style={{
                                  position: 'fixed',
                                  display: 'flex',
                                  left: '265px', 
                                  zIndex: 9999,
                                }}
                              >
                              
                                  <div className="calenderDivStyle">

                                    <div className="btnsCalendar">
                                      <DateRange
                                        editableDateInputs={true}
                                        moveRangeOnFirstSelection={false}
                                        onRangeFocusChange={(item) => {
                                          setCurrentFocus(item);
                                        }}
                                        onChange={(item) => {
                                          setChosedDate([item.selection]);
                                          setActiveBtn({
                                            today: 0,
                                            yesterday: 0,
                                            last7days: 0,
                                            last30day: 0,
                                            last60day: 0,
                                            month: 0,
                                            other: 1,
                                          });
                                          window.localStorage.setItem(
                                            'activeBtn',
                                            JSON.stringify({
                                              today: 0,
                                              yesterday: 0,
                                              last7days: 0,
                                              last30day: 0,
                                              last60day: 0,
                                              month: 0,
                                              other: 1,
                                            })
                                          );
                                        }}
                                        maxDate={new Date(new Date().getTime() - 24 * 60 * 60 * 1000)}  
                                        disabledDates={[
                                          {
                                            after: new Date(), // Disable dates after today
                                          },
                                        ]}
                                        ranges={chosedDate}
                                        dragSelectionEnabled={true}
                                        rangeColors={['#6689F2']}
                                        months={2}
                                        direction="horizontal"
                                      />
                                    </div>
                                    <div className="calendarDiv">
                                      <div className="btnCalendarContainer">
                                        <button
                                          className={
                                            activeBtn.today
                                              ? 'dateBtns activeBtnDate'
                                              : 'dateBtns'
                                          }
                                          onClick={() => {
                                            buttonHandler(
                                              new Date(
                                                new Date().toLocaleDateString(
                                                  'en-US',
                                                  {
                                                    timeZone:
                                                      window.localStorage.getItem(
                                                        'timezone'
                                                      ),
                                                  }
                                                )
                                              ),
                                              new Date(
                                                new Date().toLocaleDateString(
                                                  'en-US',
                                                  {
                                                    timeZone:
                                                      window.localStorage.getItem(
                                                        'timezone'
                                                      ),
                                                  }
                                                )
                                              )
                                            );
                                            setActiveBtn({
                                              today: 1,
                                              yesterday: 0,
                                              last7days: 0,
                                              last30day: 0,
                                              last60day: 0,
                                              month: 0,
                                              other: 0,
                                            });
                                            window.localStorage.setItem(
                                              'activeBtn',
                                              JSON.stringify({
                                                today: 1,
                                                yesterday: 0,
                                                last7days: 0,
                                                last30day: 0,
                                                last60day: 0,
                                                month: 0,
                                                other: 0,
                                              })
                                            );
                                          }}
                                        >
                                          {props.t("compareto")}
                                        </button>
                                        <button
                                          className={
                                            activeBtn.yesterday
                                              ? 'dateBtns activeBtnDate'
                                              : 'dateBtns'
                                          }
                                          onClick={() => {
                                            const timeZone =
                                              window.localStorage.getItem(
                                                'timezone'
                                              ) || 'UTC'; // set default time zone to UTC
                                            buttonHandler(
                                              new Date(
                                                new Date(
                                                  new Date().getTime() -
                                                  24 * 60 * 60 * 1000
                                                ).toLocaleDateString('en-US', {
                                                  timeZone: timeZone,
                                                })
                                              ),

                                              new Date(
                                                new Date(
                                                  new Date().getTime() -
                                                  24 * 60 * 60 * 1000
                                                ).toLocaleDateString('en-US', {
                                                  timeZone: timeZone,
                                                })
                                              )
                                            );
                                            setActiveBtn({
                                              today: 0,
                                              yesterday: 1,
                                              last7days: 0,
                                              last30day: 0,
                                              last60day: 0,
                                              month: 0,
                                              other: 0,
                                            });
                                            window.localStorage.setItem(
                                              'activeBtn',
                                              JSON.stringify({
                                                today: 0,
                                                yesterday: 1,
                                                last7days: 0,
                                                last30day: 0,
                                                last60day: 0,
                                                month: 0,
                                                other: 0,
                                              })
                                            );
                                          }}
                                        >
                                          {props.t("yesterday")}
                                        </button>
                                        <button
                                          className={
                                            activeBtn.last7days
                                              ? 'dateBtns activeBtnDate'
                                              : 'dateBtns'
                                          }
                                          onClick={() => {
                                            const timeZone =
                                              window.localStorage.getItem(
                                                'timezone'
                                              ) || 'UTC'; // set default time zone to UTC
                                            buttonHandler(
                                              new Date(
                                                new Date(
                                                  new Date().getTime() -
                                                  6 * 24 * 60 * 60 * 1000
                                                ).toLocaleDateString('en-US', {
                                                  timeZone: timeZone,
                                                })
                                              ),
                                              new Date(
                                                new Date().toLocaleDateString(
                                                  'en-US',
                                                  { timeZone: timeZone }
                                                )
                                              )
                                            );
                                            setActiveBtn({
                                              today: 0,
                                              yesterday: 0,
                                              last7days: 1,
                                              last30day: 0,
                                              last60day: 0,
                                              month: 0,
                                              other: 0,
                                            });
                                            window.localStorage.setItem(
                                              'activeBtn',
                                              JSON.stringify({
                                                today: 0,
                                                yesterday: 0,
                                                last7days: 1,
                                                last30day: 0,
                                                last60day: 0,
                                                month: 0,
                                                other: 0,
                                              })
                                            );
                                          }}
                                        >
                                          {props.t("last7days")}
                                        </button>
                                        <button
                                          className={
                                            activeBtn.last30day
                                              ? 'dateBtns activeBtnDate'
                                              : 'dateBtns'
                                          }
                                          onClick={() => {
                                            const timeZone =
                                              window.localStorage.getItem(
                                                'timezone'
                                              ) || 'UTC'; // set default time zone to UTC
                                            buttonHandler(
                                              new Date(
                                                new Date(
                                                  new Date().getTime() -
                                                  29 * 24 * 60 * 60 * 1000
                                                ).toLocaleDateString('en-US', {
                                                  timeZone: timeZone,
                                                })
                                              ),
                                              new Date(
                                                new Date().toLocaleDateString(
                                                  'en-US',
                                                  { timeZone: timeZone }
                                                )
                                              )
                                            );
                                            setActiveBtn({
                                              today: 0,
                                              yesterday: 0,
                                              last7days: 0,
                                              last30day: 1,
                                              last60day: 0,
                                              month: 0,
                                              other: 0,
                                            });
                                            window.localStorage.setItem(
                                              'activeBtn',
                                              JSON.stringify({
                                                today: 0,
                                                yesterday: 0,
                                                last7days: 0,
                                                last30day: 1,
                                                last60day: 0,
                                                month: 0,
                                                other: 0,
                                              })
                                            );
                                          }}
                                        >
                                          {props.t("last30days")}
                                        </button>
                                        <button
                                          className={
                                            activeBtn.month
                                              ? 'dateBtns activeBtnDate'
                                              : 'dateBtns'
                                          }
                                          onClick={() => {
                                            const timeZone =
                                              window.localStorage.getItem(
                                                'timezone'
                                              ) || 'UTC'; // set default time zone to UTC
                                            buttonHandler(
                                              new Date(
                                                new Date(
                                                  new Date().getFullYear(),
                                                  new Date().getMonth(),

                                                  getToAddOnMonths(
                                                    new Date().getMonth()
                                                  )
                                                ).toLocaleDateString('en-US', {
                                                  timeZone: timeZone,
                                                })
                                              ),

                                              new Date(
                                                new Date().toLocaleDateString(
                                                  'en-US',
                                                  { timeZone: timeZone }
                                                )
                                              )
                                            );
                                            setActiveBtn({
                                              today: 0,
                                              yesterday: 0,
                                              last7days: 0,
                                              last30day: 0,
                                              last60day: 0,
                                              month: 1,
                                              other: 0,
                                            });
                                            window.localStorage.setItem(
                                              'activeBtn',
                                              JSON.stringify({
                                                today: 0,
                                                yesterday: 0,
                                                last7days: 0,
                                                last30day: 0,
                                                last60day: 0,
                                                month: 1,
                                                other: 0,
                                              })
                                            );
                                          }}
                                        >
                                          {props.t("thismonth")}
                                        </button>
                                        <span className="compareSpan">
                                          <b>{props.t("compareto")}</b>
                                        </span>
                                        <div className="form-check mb-3">
                                          <input
                                            style={{
                                              width: '1em',
                                              height: '1em',
                                              marginTop: '0.25em',
                                              verticalAlign: 'top',
                                              backgroundColor: '#fff',
                                              backgroundRepeat: 'no-repeat',
                                              backgroundPosition: 'center',
                                              border: '#000000',
                                            }}
                                            type="checkbox"
                                            id="formCheck1"
                                            className={
                                              comparedTo === 'period'
                                                ? 'compareTogglebtnStyleActive'
                                                : null
                                            }
                                            checked={comparedTo === 'period'}
                                            onClick={() =>
                                              handleCompareToggle('period')
                                            }
                                          />
                                          <label
                                            className="form-check-label"
                                            htmlFor="formCheck1"
                                          >
                                            {props.t("previousperiod")}
                                          </label>
                                        </div>
                                        <div className="form-check mb-3">
                                          <input
                                            style={{
                                              width: '1em',
                                              height: '1em',
                                              marginTop: '0.25em',
                                              verticalAlign: 'top',
                                              backgroundColor: '#fff',
                                              backgroundRepeat: 'no-repeat',
                                              backgroundPosition: 'center',
                                              border: '#000000',
                                            }}
                                            type="checkbox"
                                            id="formCheck1"
                                            className={
                                              comparedTo === 'year'
                                                ? 'compareTogglebtnStyleActive'
                                                : null
                                            }
                                            checked={comparedTo === 'year'}
                                            onClick={() =>
                                              handleCompareToggle('year')
                                            }
                                          />
                                          <label
                                            className="form-check-label"
                                            htmlFor="formCheck1"
                                          >
                                            {props.t("Previousyear")}
                                          </label>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )}
                              <br />
                              {comparedTo !== "" && (
                            <small style={{
                              display: 'flex',
                              marginTop: '10px',
                              backgroundColor: 'rgb(59 91 152 / 7%)',
                              borderRadius: '5px',
                              width: 'max-content',
                              height: '30px',
                              padding: "8px",
                              fontWeight: 'bold',
                            }}>
                              {lastPeriod}
                            </small>
                          )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </InputGroup>
                  </FormGroup>
                </Col>
              </div>
              <div className="flex-shrink-0">
  <div style={{ display: 'flex', alignItems: 'center' }}>
    <Link to="/fb-campaigns-invoice" className="btn btn-light" style={{ marginRight: '10px' }}><i className="bx bx-plus me-1"></i>  {props.t("Generate a Meta  Ads report")}</Link>
    <Button color="primary" onClick={toggleOffcanvas}>
    <FeatherIcon
                  icon="settings"
                  className="icon-lg"
                />
    </Button>
  </div>
  <Offcanvas show={isOffcanvasOpen} onHide={toggleOffcanvas} placement="end">
    <OffcanvasHeader closeButton>{props.t("settings")}</OffcanvasHeader>
    <OffcanvasBody> <div className="modal-header">
                          <h5 className="modal-title" id="staticBackdropLabel">{props.t("Please choose your revenue currency for more accurate metrics.")}</h5>
                         
                        </div>

                        <div className="mb-3">
                          <label htmlFor="choices-single-default" className="form-label font-size-13 text-muted">{props.t("Select Your  Revenue currency")} </label>
                          <Select
                            value={selectedGroup}
                            onChange={handleSelectGroup}
                            options={optionGroup}
                            classNamePrefix="select2-selection"
                          />
                        </div></OffcanvasBody>
  </Offcanvas>
</div>
            </div>
          </Row>
          <Row>
            <Col>
       
            </Col>
            <Col xl={12}>
              <Card>
                <CardHeader>
                  <CardTitle> {props.t("Facebook Ads Charts")} </CardTitle>
                </CardHeader>
                <CardBody>
                  <div dir="ltr">
                    <select className="btn btn-primary" value={selectedSeries} onChange={handleDropdownChange}>
                      <option value="spend">{props.t("Spend")}</option>
                      <option value="conversionsValue">{props.t("Conversions Value")}</option>
                      <option value="conversions">{props.t("Conversions")}</option>
                      <option value="impressions">{props.t("Impressions")}</option>
                      <option value="reach">{props.t("Reach")}</option>
                      <option value="clicks">{props.t("Clicks")}</option>
                    </select>
                    <div className="charts">
                      <ReactEcharts
                        option={getOption()}
                        style={{ height: "300px", width: "90%" }}
                      />
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row >
            <div>  {
              comparedTo !== '' &&
              <div style={{ display: 'flex', marginTop: '50px', backgroundColor: 'rgb(59 91 152 / 7%)', borderRadius: '5px', width: 'max-content', height: '30px', padding: "15px" }}>
                <section style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <div style={{ width: '13px', height: '13px', background: '#F6B43B', borderRadius: '2px', marginRight: '5px', marginTop: '-2px' }}></div>
                  <span style={{ color: '#0C1426', fontSize: '14px', fontWeight: 'bold' }}>
                    {`${chosedDate[0]?.startDate?.toLocaleString("en", {
                      month: "short", day: "2-digit"
                    })}-${chosedDate[0]?.endDate?.toLocaleString("en", {
                      month: "short", day: "2-digit"
                    })} ${chosedDate[0]?.endDate?.getFullYear()}`}
                  </span>
                </section>
                <section style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginLeft: '15px' }}>
                  <div style={{ width: '13px', height: '13px', background: '#EC1A74', borderRadius: '2px', marginRight: '5px', marginTop: '-2px' }}></div>
                  <span style={{ color: '#0C1426', fontSize: '14px', fontWeight: 'bold' }}>
                    {lastPeriod.split(':')[1]}
                  </span>
                </section>
              </div>
            }
              <br></br>
            </div>
          </Row>
          <Row>
            {
              secCards.map(item => (
                <AdsDataCard loading={loadingState} item={item} date={chosedDate} getROAS={getROAS} getCPA={getCPA} getLastCPA={getLastCPA} getLastROAS={getLastROAS} getCTR={getCTR} getLastCTR={getLastCTR} getCPC={getCPC} getLastCPC={getLastCPC} getAOV={getAOV} getLastAOV={getLastAOV} getCR={getCR} getLastCR={getLastCR} />
              ))}
          </Row>
          {/* <Campaignstable data={metrcisbyCompaign} /> */}
        </div>
      </div>
    </React.Fragment>
  )
}
Socials.propTypes = {
  t: PropTypes.any
};
export default withRouter(withTranslation()(Socials));
//export default withRouter(withTranslation()(Socials))
