import React from 'react';
import { Card } from "semantic-ui-react";
import { Row, Col, CardBody } from "reactstrap"
import { default as NumberFormat } from 'react-number-format';
import ReactApexChart from 'react-apexcharts';
const GoogleAdsIcon = `${process.env.PUBLIC_URL}/images/integrations_images/GoogleADS.svg`;

function GoogleAdsDataCard({  date, loading, item,  getROAS, getLastROAS, getCTR, getLastCTR, getCPC, getLastCPC, getCPA, getLastCPA, getConversionRate, getLastConversionRate, getAOV, getLastAOV }) {
  
 const currencyrevenue=localStorage.getItem("google_revenue_currency")||" "
 const cuurencyadcost=localStorage.getItem("googleads_customer_currency_code")||" "
  const startDate = date[0].startDate;
  const endDate = date[0].endDate;
  const diff = Math.abs(endDate - startDate) / 1000;
  const today = new Date(new Date().setHours(0, 0, 0, 0));
  const diff_today_start = Math.abs(today - startDate) / 1000;
  const diff_today_end = Math.abs(today - endDate) / 1000;
  const formatNumberWithSpaces = (value) => {
    if (value !== undefined && value !== null) {
      return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    }
  }

  let generateCRChart = () => {
    let processedChart = []
    let lastProcessedChart = []
    if (diff === 0) {
      for (let i = 0; i < item.series[0].data[1].length; i++) {
        processedChart.push({ x: `${new Date(item.dates[i]).toLocaleString("en", { month: "short", day: "2-digit", hour: "2-digit", minute: "2-digit" })}&nbsp;<span style="font-weight:bold">compared to<span>&nbsp;${new Date(item.lastDates[i]).toLocaleString("en", { month: "short", day: "2-digit", hour: "2-digit", minute: "2-digit" })}`, y: getConversionRate(item.series[0].data[1], item.series[0].data[0][0], diff).toFixed(2) });
      }
      if (item.series[1].data[0].length > 0 && item.series[1].data[1].length > 0) {
        for (let i = 0; i < ((diff_today_start === 0 && diff_today_end === 0) ? item.series[0].data[1].length : 24); i++) {
          lastProcessedChart.push({ x: `${new Date(item.dates[i]).toLocaleString("en", { month: "short", day: "2-digit", hour: "2-digit", minute: "2-digit" })}&nbsp;<span style="font-weight:bold">compared to<span>&nbsp;${new Date(item.lastDates[i]).toLocaleString("en", { month: "short", day: "2-digit", hour: "2-digit", minute: "2-digit" })}`, y: getConversionRate(item.series[1].data[1], item.series[1].data[0][0], diff).toFixed(2) });
        }
      }
    } else {
      item.series[0].data[1].map((crValue, idx) => {
        processedChart.push({ x: `${new Date(item.dates[idx]).toLocaleString("en", { month: "short", day: "2-digit" })}&nbsp;<span style="font-weight:bold">compared to<span>&nbsp;${new Date(item.lastDates[idx]).toLocaleString("en", { month: "short", day: "2-digit" })}`, y: getConversionRate(crValue, item.series[0].data[0][idx], diff).toFixed(2) });
      })
      item.series[1].data[1].map((crValue, idx) => {
        lastProcessedChart.push({ x: `${new Date(item.dates[idx]).toLocaleString("en", { month: "short", day: "2-digit" })}&nbsp;<span style="font-weight:bold">compared to<span>&nbsp;${new Date(item.lastDates[idx]).toLocaleString("en", { month: "short", day: "2-digit" })}`, y: getConversionRate(crValue, item.series[1].data[0][idx], diff).toFixed(2) })
      })
    }
    return [processedChart, lastProcessedChart];
  }
  let generateCPCChart = () => {
    let processedChart = []
    let lastProcessedChart = []
    if (diff === 0) {
      for (let i = 0; i < item.series[0].data[1].length; i++) {
        processedChart.push({ x: `${new Date(item.dates[i]).toLocaleString("en", { month: "short", day: "2-digit", hour: "2-digit", minute: "2-digit" })}&nbsp;<span style="font-weight:bold">compared to<span>&nbsp;${new Date(item.lastDates[i]).toLocaleString("en", { month: "short", day: "2-digit", hour: "2-digit", minute: "2-digit" })}`, y: getCPC(item.series[0].data[1], item.series[0].data[0][0], diff).toFixed(2) });
      }
      if (item.series[1].data[0].length > 0 && item.series[1].data[1].length > 0) {
        for (let i = 0; i < ((diff_today_start === 0 && diff_today_end === 0) ? item.series[0].data[1].length : 24); i++) {
          lastProcessedChart.push({ x: `${new Date(item.dates[i]).toLocaleString("en", { month: "short", day: "2-digit", hour: "2-digit", minute: "2-digit" })}&nbsp;<span style="font-weight:bold">compared to<span>&nbsp;${new Date(item.lastDates[i]).toLocaleString("en", { month: "short", day: "2-digit", hour: "2-digit", minute: "2-digit" })}`, y: getCPC(item.series[1].data[1], item.series[1].data[0][0], diff).toFixed(2) });
        }
      }
    } else {
      item.series[0].data[1].map((cpcValue, idx) => {
        processedChart.push({ x: `${new Date(item.dates[idx]).toLocaleString("en", { month: "short", day: "2-digit" })}&nbsp;<span style="font-weight:bold">compared to<span>&nbsp;${new Date(item.lastDates[idx]).toLocaleString("en", { month: "short", day: "2-digit" })}`, y: getCPC(cpcValue, item.series[0].data[0][idx], diff).toFixed(2) });
      })
      item.series[1].data[1].map((cpcValue, idx) => {
        lastProcessedChart.push({ x: `${new Date(item.dates[idx]).toLocaleString("en", { month: "short", day: "2-digit" })}&nbsp;<span style="font-weight:bold">compared to<span>&nbsp;${new Date(item.lastDates[idx]).toLocaleString("en", { month: "short", day: "2-digit" })}`, y: getCPC(cpcValue, item.series[1].data[0][idx], diff).toFixed(2) })
      })
    }
    return [processedChart, lastProcessedChart];
  }
  let generateAdCostChart = () => {
    let processedChart = []
    let lastProcessedChart = []
    if (diff === 0) {
      for (let i = 0; i < item.dates.length; i++) {
        processedChart.push({ x: `${new Date(item.dates[i]).toLocaleString("en", { month: "short", day: "2-digit", hour: "2-digit", minute: "2-digit" })}&nbsp;<span style="font-weight:bold">compared to<span>&nbsp;${new Date(item.lastDates[i]).toLocaleString("en", { month: "short", day: "2-digit", hour: "2-digit", minute: "2-digit" })}`, y: parseFloat(item.series[0].data[0]).toFixed(2) });
      }
      for (let i = 0; i < ((diff_today_start === 0 && diff_today_end === 0) ? item.series[0].data.length : 24); i++) {
        lastProcessedChart.push({ x: `${new Date(item.dates[i]).toLocaleString("en", { month: "short", day: "2-digit", hour: "2-digit", minute: "2-digit" })}&nbsp;<span style="font-weight:bold">compared to<span>&nbsp;${new Date(item.lastDates[i]).toLocaleString("en", { month: "short", day: "2-digit", hour: "2-digit", minute: "2-digit" })}`, y: parseFloat(item.series[1].data[0]).toFixed(2) });
      }
    } else {
      item.series[0].data.map((adCost, idx) => {
        processedChart.push({ x: `${new Date(item.dates[idx]).toLocaleString("en", { month: "short", day: "2-digit" })}&nbsp;<span style="font-weight:bold">compared to<span>&nbsp;${new Date(item.lastDates[idx]).toLocaleString("en", { month: "short", day: "2-digit" })}`, y: parseFloat(adCost).toFixed(2) });
      })
      item.series[1].data.map((adCost, idx) => {
        lastProcessedChart.push({ x: `${new Date(item.dates[idx]).toLocaleString("en", { month: "short", day: "2-digit" })}&nbsp;<span style="font-weight:bold">compared to<span>&nbsp;${new Date(item.lastDates[idx]).toLocaleString("en", { month: "short", day: "2-digit" })}`, y: parseFloat(adCost).toFixed(2) })
      })
    }
    return [processedChart, lastProcessedChart];
  }
  let generateClicksChart = () => {
    let processedChart = []
    let lastProcessedChart = []
    if (diff === 0) {
      for (let i = 0; i < item.dates.length; i++) {
        processedChart.push({ x: `${new Date(item.dates[i]).toLocaleString("en", { month: "short", day: "2-digit", hour: "2-digit", minute: "2-digit" })}&nbsp;<span style="font-weight:bold">compared to<span>&nbsp;${new Date(item.lastDates[i]).toLocaleString("en", { month: "short", day: "2-digit", hour: "2-digit", minute: "2-digit" })}`, y: parseFloat(item.series[0].data[0]).toFixed(2) });
      }
      for (let i = 0; i < ((diff_today_start === 0 && diff_today_end === 0) ? item.series[0].data.length : 24); i++) {
        lastProcessedChart.push({ x: `${new Date(item.dates[i]).toLocaleString("en", { month: "short", day: "2-digit", hour: "2-digit", minute: "2-digit" })}&nbsp;<span style="font-weight:bold">compared to<span>&nbsp;${new Date(item.lastDates[i]).toLocaleString("en", { month: "short", day: "2-digit", hour: "2-digit", minute: "2-digit" })}`, y: parseFloat(item.series[1].data[0]).toFixed(2) });
      }
    } else {
      item.series[0].data.map((clicks, idx) => {
        processedChart.push({ x: `${new Date(item.dates[idx]).toLocaleString("en", { month: "short", day: "2-digit" })}&nbsp;<span style="font-weight:bold">compared to<span>&nbsp;${new Date(item.lastDates[idx]).toLocaleString("en", { month: "short", day: "2-digit" })}`, y: parseFloat(clicks).toFixed(2) });
      })
      item.series[1].data.map((clicks, idx) => {
        lastProcessedChart.push({ x: `${new Date(item.dates[idx]).toLocaleString("en", { month: "short", day: "2-digit" })}&nbsp;<span style="font-weight:bold">compared to<span>&nbsp;${new Date(item.lastDates[idx]).toLocaleString("en", { month: "short", day: "2-digit" })}`, y: parseFloat(clicks).toFixed(2) })
      })
    }
    return [processedChart, lastProcessedChart];
  }
  let generateCTRChart = () => {
    let processedChart = []
    let lastProcessedChart = []
    if (diff === 0) {
      for (let i = 0; i < item.series[0].data[1].length; i++) {
        processedChart.push({ x: `${new Date(item.dates[i]).toLocaleString("en", { month: "short", day: "2-digit", hour: "2-digit", minute: "2-digit" })}&nbsp;<span style="font-weight:bold">compared to<span>&nbsp;${new Date(item.lastDates[i]).toLocaleString("en", { month: "short", day: "2-digit", hour: "2-digit", minute: "2-digit" })}`, y: getCTR(item.series[0].data[1], item.series[0].data[0][0], diff).toFixed(2) });
      }
      if (item.series[1].data[0].length > 0 && item.series[1].data[1].length > 0) {
        for (let i = 0; i < ((diff_today_start === 0 && diff_today_end === 0) ? item.series[0].data[1].length : 24); i++) {
          lastProcessedChart.push({ x: `${new Date(item.dates[i]).toLocaleString("en", { month: "short", day: "2-digit", hour: "2-digit", minute: "2-digit" })}&nbsp;<span style="font-weight:bold">compared to<span>&nbsp;${new Date(item.lastDates[i]).toLocaleString("en", { month: "short", day: "2-digit", hour: "2-digit", minute: "2-digit" })}`, y: getCTR(item.series[1].data[1], item.series[1].data[0][0], diff).toFixed(2) });
        }
      }
    } else {
      item.series[0].data[1].map((ctrValue, idx) => {
        processedChart.push({ x: `${new Date(item.dates[idx]).toLocaleString("en", { month: "short", day: "2-digit" })}&nbsp;<span style="font-weight:bold">compared to<span>&nbsp;${new Date(item.lastDates[idx]).toLocaleString("en", { month: "short", day: "2-digit" })}`, y: getCTR(ctrValue, item.series[0].data[0][idx], diff).toFixed(2) });
      })
      item.series[1].data[1].map((ctrValue, idx) => {
        lastProcessedChart.push({ x: `${new Date(item.dates[idx]).toLocaleString("en", { month: "short", day: "2-digit" })}&nbsp;<span style="font-weight:bold">compared to<span>&nbsp;${new Date(item.lastDates[idx]).toLocaleString("en", { month: "short", day: "2-digit" })}`, y: getCTR(ctrValue, item.series[1].data[0][idx], diff).toFixed(2) })
      })
    }
    return [processedChart, lastProcessedChart];
  }
  let generateROASChart = () => {
    let processedChart = []
    let lastProcessedChart = []
    if (diff === 0) {
      for (let i = 0; i < item.series[0].data[1].length; i++) {
        processedChart.push({ x: `${new Date(item.dates[i]).toLocaleString("en", { month: "short", day: "2-digit", hour: "2-digit", minute: "2-digit" })}&nbsp;<span style="font-weight:bold">compared to<span>&nbsp;${new Date(item.lastDates[i]).toLocaleString("en", { month: "short", day: "2-digit", hour: "2-digit", minute: "2-digit" })}`, y: getROAS(item.series[0].data[1], item.series[0].data[0][0], diff).toFixed(2) });
      }
      if (item.series[1].data[0].length > 0 && item.series[1].data[1].length > 0) {
        for (let i = 0; i < ((diff_today_start === 0 && diff_today_end === 0) ? item.series[0].data[1].length : 24); i++) {
          lastProcessedChart.push({ x: `${new Date(item.dates[i]).toLocaleString("en", { month: "short", day: "2-digit", hour: "2-digit", minute: "2-digit" })}&nbsp;<span style="font-weight:bold">compared to<span>&nbsp;${new Date(item.lastDates[i]).toLocaleString("en", { month: "short", day: "2-digit", hour: "2-digit", minute: "2-digit" })}`, y: getROAS(item.series[1].data[1], item.series[1].data[0][0], diff).toFixed(2) });
        }
      }
    } else {
      item.series[0].data[1].map((salesValue, idx) => {
        processedChart.push({ x: `${new Date(item.dates[idx]).toLocaleString("en", { month: "short", day: "2-digit" })}&nbsp;<span style="font-weight:bold">compared to<span>&nbsp;${new Date(item.lastDates[idx]).toLocaleString("en", { month: "short", day: "2-digit" })}`, y: getROAS(salesValue, item.series[0].data[0][idx], diff).toFixed(2) });
      })
      item.series[1].data[1].map((salesValue, idx) => {
        lastProcessedChart.push({ x: `${new Date(item.dates[idx]).toLocaleString("en", { month: "short", day: "2-digit" })}&nbsp;<span style="font-weight:bold">compared to<span>&nbsp;${new Date(item.lastDates[idx]).toLocaleString("en", { month: "short", day: "2-digit" })}`, y: getROAS(salesValue, item.series[1].data[0][idx], diff).toFixed(2) })
      })
    }
    return [processedChart, lastProcessedChart];
  }
  let getTotal = (array) => {
    return array.length > 0 ? array.map(Number).reduce((x, y) => { return x + y }) : 0;
  }
  let getTotalFixed = (array) => {
    return array.length > 0 ? array.map(Number).reduce((x, y) => { return x + y }).toFixed(2) : "0.00";
  }
  let generateConversionValueChart = () => {
    let processedChart = []
    let lastProcessedChart = []
    if (diff === 0) {
      for (let i = 0; i < item.dates.length; i++) {
        processedChart.push({ x: `${new Date(item.dates[i]).toLocaleString("en", { month: "short", day: "2-digit", hour: "2-digit", minute: "2-digit" })}&nbsp;<span style="font-weight:bold">compared to<span>&nbsp;${new Date(item.lastDates[i]).toLocaleString("en", { month: "short", day: "2-digit", hour: "2-digit", minute: "2-digit" })}`, y: parseFloat(item.series[0].data[0]).toFixed(2) });
      }
      for (let i = 0; i < ((diff_today_start === 0 && diff_today_end === 0) ? item.series[0].data.length : 24); i++) {
        lastProcessedChart.push({ x: `${new Date(item.dates[i]).toLocaleString("en", { month: "short", day: "2-digit", hour: "2-digit", minute: "2-digit" })}&nbsp;<span style="font-weight:bold">compared to<span>&nbsp;${new Date(item.lastDates[i]).toLocaleString("en", { month: "short", day: "2-digit", hour: "2-digit", minute: "2-digit" })}`, y: parseFloat(item.series[1].data[0]).toFixed(2) });
      }
    } else {
      item.series[0].data.map((conversions_value, idx) => {
        processedChart.push({ x: `${new Date(item.dates[idx]).toLocaleString("en", { month: "short", day: "2-digit" })}&nbsp;<span style="font-weight:bold">compared to<span>&nbsp;${new Date(item.lastDates[idx]).toLocaleString("en", { month: "short", day: "2-digit" })}`, y: parseFloat(conversions_value).toFixed(2) });
      })
      item.series[1].data.map((conversions_value, idx) => {
        lastProcessedChart.push({ x: `${new Date(item.dates[idx]).toLocaleString("en", { month: "short", day: "2-digit" })}&nbsp;<span style="font-weight:bold">compared to<span>&nbsp;${new Date(item.lastDates[idx]).toLocaleString("en", { month: "short", day: "2-digit" })}`, y: parseFloat(conversions_value).toFixed(2) })
      })
    }
    return [processedChart, lastProcessedChart];
  }
  let generateConversionsChart = () => {
    let processedChart = []
    let lastProcessedChart = []
    if (diff === 0) {
      for (let i = 0; i < item.dates.length; i++) {
        processedChart.push({ x: `${new Date(item.dates[i]).toLocaleString("en", { month: "short", day: "2-digit", hour: "2-digit", minute: "2-digit" })}&nbsp;<span style="font-weight:bold">compared to<span>&nbsp;${new Date(item.lastDates[i]).toLocaleString("en", { month: "short", day: "2-digit", hour: "2-digit", minute: "2-digit" })}`, y: parseFloat(item.series[0].data[0]).toFixed(2) });
      }
      for (let i = 0; i < ((diff_today_start === 0 && diff_today_end === 0) ? item.series[0].data.length : 24); i++) {
        lastProcessedChart.push({ x: `${new Date(item.dates[i]).toLocaleString("en", { month: "short", day: "2-digit", hour: "2-digit", minute: "2-digit" })}&nbsp;<span style="font-weight:bold">compared to<span>&nbsp;${new Date(item.lastDates[i]).toLocaleString("en", { month: "short", day: "2-digit", hour: "2-digit", minute: "2-digit" })}`, y: parseFloat(item.series[1].data[0]).toFixed(2) });
      }
    } else {
      item.series[0].data.map((conversions, idx) => {
        processedChart.push({ x: `${new Date(item.dates[idx]).toLocaleString("en", { month: "short", day: "2-digit" })}&nbsp;<span style="font-weight:bold">compared to<span>&nbsp;${new Date(item.lastDates[idx]).toLocaleString("en", { month: "short", day: "2-digit" })}`, y: parseFloat(conversions).toFixed(2) });
      })
      item.series[1].data.map((conversions, idx) => {
        lastProcessedChart.push({ x: `${new Date(item.dates[idx]).toLocaleString("en", { month: "short", day: "2-digit" })}&nbsp;<span style="font-weight:bold">compared to<span>&nbsp;${new Date(item.lastDates[idx]).toLocaleString("en", { month: "short", day: "2-digit" })}`, y: parseFloat(conversions).toFixed(2) })
      })
    }
    return [processedChart, lastProcessedChart];
  }
  let generateImpressionChart = () => {
    let processedChart = []
    let lastProcessedChart = []
    if (diff === 0) {
      for (let i = 0; i < item.dates.length; i++) {
        processedChart.push({ x: `${new Date(item.dates[i]).toLocaleString("en", { month: "short", day: "2-digit", hour: "2-digit", minute: "2-digit" })}&nbsp;<span style="font-weight:bold">compared to<span>&nbsp;${new Date(item.lastDates[i]).toLocaleString("en", { month: "short", day: "2-digit", hour: "2-digit", minute: "2-digit" })}`, y: parseFloat(item.series[0].data[0]).toFixed(2) });
      }
      for (let i = 0; i < ((diff_today_start === 0 && diff_today_end === 0) ? item.series[0].data.length : 24); i++) {
        lastProcessedChart.push({ x: `${new Date(item.dates[i]).toLocaleString("en", { month: "short", day: "2-digit", hour: "2-digit", minute: "2-digit" })}&nbsp;<span style="font-weight:bold">compared to<span>&nbsp;${new Date(item.lastDates[i]).toLocaleString("en", { month: "short", day: "2-digit", hour: "2-digit", minute: "2-digit" })}`, y: parseFloat(item.series[1].data[0]).toFixed(2) });
      }
    } else {
      item.series[0].data.map((impressions, idx) => {
        processedChart.push({ x: `${new Date(item.dates[idx]).toLocaleString("en", { month: "short", day: "2-digit" })}&nbsp;<span style="font-weight:bold">compared to<span>&nbsp;${new Date(item.lastDates[idx]).toLocaleString("en", { month: "short", day: "2-digit" })}`, y: parseFloat(impressions).toFixed(2) });
      })
      item.series[1].data.map((impressions, idx) => {
        lastProcessedChart.push({ x: `${new Date(item.dates[idx]).toLocaleString("en", { month: "short", day: "2-digit" })}&nbsp;<span style="font-weight:bold">compared to<span>&nbsp;${new Date(item.lastDates[idx]).toLocaleString("en", { month: "short", day: "2-digit" })}`, y: parseFloat(impressions).toFixed(2) })
      })
    }
    return [processedChart, lastProcessedChart];
  }
  let generateCPAChart = () => {
    let processedChart = []
    let lastProcessedChart = []
    if (diff === 0) {
      for (let i = 0; i < item.series[0].data[1].length; i++) {
        processedChart.push({ x: `${new Date(item.dates[i]).toLocaleString("en", { month: "short", day: "2-digit", hour: "2-digit", minute: "2-digit" })}&nbsp;<span style="font-weight:bold">compared to<span>&nbsp;${new Date(item.lastDates[i]).toLocaleString("en", { month: "short", day: "2-digit", hour: "2-digit", minute: "2-digit" })}`, y: getCPA(item.series[0].data[1], item.series[0].data[0][0], diff).toFixed(2) });
      }
      if (item.series[1].data[0].length > 0 && item.series[1].data[1].length > 0) {
        for (let i = 0; i < ((diff_today_start === 0 && diff_today_end === 0) ? item.series[0].data[1].length : 24); i++) {
          lastProcessedChart.push({ x: `${new Date(item.dates[i]).toLocaleString("en", { month: "short", day: "2-digit", hour: "2-digit", minute: "2-digit" })}&nbsp;<span style="font-weight:bold">compared to<span>&nbsp;${new Date(item.lastDates[i]).toLocaleString("en", { month: "short", day: "2-digit", hour: "2-digit", minute: "2-digit" })}`, y: getCPA(item.series[1].data[1], item.series[1].data[0][0], diff).toFixed(2) });
        }
      }
    } else {
      item.series[0].data[1].map((cpaValue, idx) => {
        processedChart.push({ x: `${new Date(item.dates[idx]).toLocaleString("en", { month: "short", day: "2-digit" })}&nbsp;<span style="font-weight:bold">compared to<span>&nbsp;${new Date(item.lastDates[idx]).toLocaleString("en", { month: "short", day: "2-digit" })}`, y: getCPA(cpaValue, item.series[0].data[0][idx], diff).toFixed(2) });
      })
      item.series[1].data[1].map((cpaValue, idx) => {
        lastProcessedChart.push({ x: `${new Date(item.dates[idx]).toLocaleString("en", { month: "short", day: "2-digit" })}&nbsp;<span style="font-weight:bold">compared to<span>&nbsp;${new Date(item.lastDates[idx]).toLocaleString("en", { month: "short", day: "2-digit" })}`, y: getCPA(cpaValue, item.series[1].data[0][idx], diff).toFixed(2) })
      })
    }
    return [processedChart, lastProcessedChart];
  }
  let generateAOVChart = () => {
    let processedChart = []
    let lastProcessedChart = []
    if (diff === 0) {
      for (let i = 0; i < item.series[0].data[1].length; i++) {
        processedChart.push({ x: `${new Date(item.dates[i]).toLocaleString("en", { month: "short", day: "2-digit", hour: "2-digit", minute: "2-digit" })}&nbsp;<span style="font-weight:bold">compared to<span>&nbsp;${new Date(item.lastDates[i]).toLocaleString("en", { month: "short", day: "2-digit", hour: "2-digit", minute: "2-digit" })}`, y: getAOV(item.series[0].data[1], item.series[0].data[0][0], diff).toFixed(2) });
      }
      if (item.series[1].data[0].length > 0 && item.series[1].data[1].length > 0) {
        for (let i = 0; i < ((diff_today_start === 0 && diff_today_end === 0) ? item.series[0].data[1].length : 24); i++) {
          lastProcessedChart.push({ x: `${new Date(item.dates[i]).toLocaleString("en", { month: "short", day: "2-digit", hour: "2-digit", minute: "2-digit" })}&nbsp;<span style="font-weight:bold">compared to<span>&nbsp;${new Date(item.lastDates[i]).toLocaleString("en", { month: "short", day: "2-digit", hour: "2-digit", minute: "2-digit" })}`, y: getAOV(item.series[1].data[1], item.series[1].data[0][0], diff).toFixed(2) });
        }
      }
    } else {
      item.series[0].data[1].map((aovValue, idx) => {
        processedChart.push({ x: `${new Date(item.dates[idx]).toLocaleString("en", { month: "short", day: "2-digit" })}&nbsp;<span style="font-weight:bold">compared to<span>&nbsp;${new Date(item.lastDates[idx]).toLocaleString("en", { month: "short", day: "2-digit" })}`, y: getAOV(aovValue, item.series[0].data[0][idx], diff).toFixed(2) });
      })
      item.series[1].data[1].map((aovValue, idx) => {
        lastProcessedChart.push({ x: `${new Date(item.dates[idx]).toLocaleString("en", { month: "short", day: "2-digit" })}&nbsp;<span style="font-weight:bold">compared to<span>&nbsp;${new Date(item.lastDates[idx]).toLocaleString("en", { month: "short", day: "2-digit" })}`, y: getAOV(aovValue, item.series[1].data[0][idx], diff).toFixed(2) })
      })
    }
    return [processedChart, lastProcessedChart];
  }
  return (
    <div className="dashboardMinorCardItem">
      {
        (item.name === 'CLICKS' || item.name === 'Clicks') &&
        <Card className="card-h-100">
          <CardBody>
            <div className="my-0 text-primary d-flex justify-content-between align-items-center">{item.name}<img className="img-intgration-icon" src={GoogleAdsIcon} /></div>
            <Row className="align-items-center">
              <Col xs={6}>
                <div className="mb-3">
                  {
                    loading ?
                      <h4>{item.series[0].data.length > 0 && formatNumberWithSpaces(getTotal(item.series[0].data))}</h4>
                      : ""
                  }
                </div>
              </Col>
              <Col xs={6}>
                <ReactApexChart
                  options={item.options}
                  series={item.series[1].data.length > 0 ? [{ name: item.name, data: generateClicksChart()[0] }, { name: "Compared To", data: generateClicksChart()[1] }] : [{ name: item.name, data: generateClicksChart()[0] }]}
                  type="line"
                  width={"100%"}
                  height={60}
                />
              </Col>
              <div className="text-nowrap h4">
                {
                  loading ?
                    item.series[1].data.length > 0 ?
                      getTotal(item.series[0].data) > getTotal(item.series[1].data) ?
                        <span className="badge badge-soft-success text-success">{item.series[0].data.length > 0 && ((getTotal(item.series[0].data) - getTotal(item.series[1].data)) / Math.abs(getTotal(item.series[0].data)) * 100).toFixed(2)}%</span>
                        :
                        <span className="badge badge-soft-danger text-danger ">{item.series[0].data.length > 0 && ((getTotal(item.series[0].data) - getTotal(item.series[1].data)) / Math.abs(getTotal(item.series[0].data)) * 100).toFixed(2)}%</span>
                      : "-"
                    : ""
                }
              </div>
            </Row>
          </CardBody>
        </Card>
      }
      {
        (item.name === 'Conversions' || item.name === 'conversions') &&
        <Card className="card-h-100">
          <CardBody>
            <div className="my-0 text-primary d-flex justify-content-between align-items-center">{item.name}<img className="img-intgration-icon" src={GoogleAdsIcon} /></div>
            <Row className="align-items-center">
              <Col xs={6}>
                <div className="mb-3">
                  {
                    loading ?
                      <h4>{item.series[0].data.length > 0 && formatNumberWithSpaces(getTotal(item.series[0].data).toFixed(2))}</h4>
                      : ""
                  } 
                </div>
              </Col>
              <Col xs={6}>
                <ReactApexChart
                  options={item.options}
                  series={item.series[1].data.length > 0 ? [{ name: item.name, data: generateConversionsChart()[0] }, { name: "Compared To", data: generateConversionsChart()[1] }] : [{ name: item.name, data: generateConversionsChart()[0] }]}
                  type="line"
                  width={"100%"}
                  height={60}
                />
              </Col>
            </Row>
            <div className="text-nowrap h4">
            {
                loading ?
                  item.series[1].data.length > 0 ?
                    getTotal(item.series[0].data) > getTotal(item.series[1].data) ?
                      <span className="badge badge-soft-success text-success">{item.series[0].data.length > 0 && ((getTotal(item.series[0].data) - getTotal(item.series[1].data)) / Math.abs(getTotal(item.series[0].data)) * 100).toFixed(2)}%</span>
                      :
                      <span className="badge badge-soft-danger text-danger ">{item.series[0].data.length > 0 && ((getTotal(item.series[0].data) - getTotal(item.series[1].data)) / Math.abs(getTotal(item.series[0].data)) * 100).toFixed(2)}%</span>
                    : "-"
                  : ""
              }
            </div>
          </CardBody>
        </Card>
      }
      {
        (item.name === 'Impressions' || item.name === 'impressions') &&
        <Card className="card-h-100">
          <CardBody>
            <div className="my-0 text-primary d-flex justify-content-between align-items-center">{item.name}<img className="img-intgration-icon" src={GoogleAdsIcon} /></div>
            <Row className="align-items-center">
              <Col xs={6}>
                <div className="mb-3">
                  {
                    loading ?
                      <h4>{item.series[0].data.length > 0 && formatNumberWithSpaces(getTotal(item.series[0].data))}</h4>
                      : ""
                  }
                </div>
              </Col>
              <Col xs={6}>
                <ReactApexChart
                  options={item.options}
                  series={item.series[1].data.length > 0 ? [{ name: item.name, data: generateImpressionChart()[0] }, { name: "Compared To", data: generateImpressionChart()[1] }] : [{ name: item.name, data: generateImpressionChart()[0] }]}
                  type="line"
                  width={"100%"}
                  height={60}
                />
              </Col>
            </Row>
            <div className="text-nowrap h4">
              {
                loading ?
                  item.series[1].data.length > 0 ?
                    getTotal(item.series[0].data) > getTotal(item.series[1].data) ?
                      <span className="badge badge-soft-success text-success">{item.series[0].data.length > 0 && ((getTotal(item.series[0].data) - getTotal(item.series[1].data)) / Math.abs(getTotal(item.series[0].data)) * 100).toFixed(2)}%</span>
                      :
                      <span className="badge badge-soft-danger text-danger ">{item.series[0].data.length > 0 && ((getTotal(item.series[0].data) - getTotal(item.series[1].data)) / Math.abs(getTotal(item.series[0].data)) * 100).toFixed(2)}%</span>
                    : "-"
                  : ""
              }
            </div>
          </CardBody>
        </Card>
      }
      {
        (item.name === 'interactions' || item.name === 'Interactions') &&
        <Card className="card-h-100">
          <CardBody>
            <div className="my-0 text-primary d-flex justify-content-between align-items-center">{item.name}<img className="img-intgration-icon" src={GoogleAdsIcon} /></div>
            <Row className="align-items-center">
              <Col xs={6}>
                <div className="mb-3">
                  {
                    loading ?
                      <h4>{item.series[0].data.length > 0 && formatNumberWithSpaces(getTotal(item.series[0].data))}</h4>
                      : ""
                  }
                </div>
              </Col>
            </Row>
          </CardBody>
        </Card>
      }
      {
        (item.name === 'Conversion Value' || item.name === 'conversion_Value') &&
        <Card className="card-h-100">
          <CardBody>
            <div className="my-0 text-primary d-flex justify-content-between align-items-center">
              <span className="my-0 text-primary">
                <i class="fa fa-info-circle text-primary" aria-hidden="true" data-tooltip="Please check your  buisness manager currency settings"></i>{" "}
                Revenue
              </span>
              <img className="img-intgration-icon" src={GoogleAdsIcon} />
            </div>
            <Row className="align-items-center">
              <Col xs={6}>
                <div className="mb-3">
                  {
                    loading ?
                      <h4>{currencyrevenue}{' '}{item.series[0].data.length > 0 && formatNumberWithSpaces(getTotalFixed(item.series[0].data))}</h4>
                      : ""
                  }
                </div>
              </Col>
              <Col xs={6}>
                <ReactApexChart
                  options={item.options}
                  series={item.series[1].data.length > 0 ? [{ name: item.name, data: generateConversionValueChart()[0] }, { name: "Compared To", data: generateConversionValueChart()[1] }] : [{ name: item.name, data: generateConversionValueChart()[0] }]}
                  type="line"
                  width={"100%"}
                  height={60}
                />
              </Col>
            </Row>
            <div className="text-nowrap h4">
              {
                loading ?
                  item.series[1].data.length > 0 ?
                    getTotal(item.series[0].data) > getTotal(item.series[1].data) ?
                      <span className="badge badge-soft-success text-success">{item.series[0].data.length > 0 && ((getTotal(item.series[0].data) - getTotal(item.series[1].data)) / Math.abs(getTotal(item.series[0].data)) * 100).toFixed(2)}%</span>
                      :
                      <span className="badge badge-soft-danger text-danger ">{item.series[0].data.length > 0 && ((getTotal(item.series[0].data) - getTotal(item.series[1].data)) / Math.abs(getTotal(item.series[0].data)) * 100).toFixed(2)}%</span>
                    : "-"
                  : ""
              }
            </div>
          </CardBody>
        </Card>
      }
      {
        (item.name === 'TotalAdCost' || item.name === 'Coût publicitaire total') &&
        <Card className="card-h-100">
          <CardBody>
            <div className="my-0 text-primary d-flex justify-content-between align-items-center">{item.name}<img className="img-intgration-icon" src={GoogleAdsIcon} /></div>
            <Row className="align-items-center">
              <Col xs={6}>
                <div className="mb-3">
                  {
                    loading ?
                      (isFinite(getTotal(item.series[0].data)) ? <NumberFormat value={getTotal(item.series[0].data)} decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={cuurencyadcost} renderText={value => <h4>{value}</h4>} /> : "NoAdCost")

                      : ""
                  }
                </div>
              </Col>
              <Col xs={6}>
                <ReactApexChart
                  options={item.options}
                  series={item.series[1].data.length > 0 ? [{ name: item.name, data: generateAdCostChart()[0] }, { name: "Compared To", data: generateAdCostChart()[1] }] : [{ name: item.name, data: generateAdCostChart()[0] }]}
                  type="line"
                  width={"100%"}
                  height={60}
                />
              </Col>
            </Row>
            <div className="text-nowrap h4">
              {
                loading ?
                  item.series[1].data.length > 0 ?
                    getTotal(item.series[0].data) > getTotal(item.series[1].data) ?
                      <span className="badge badge-soft-success text-success">{item.series[0].data.length > 0 && ((getTotal(item.series[0].data) - getTotal(item.series[1].data)) / Math.abs(getTotal(item.series[0].data)) * 100).toFixed(2)}%</span>
                      :
                      <span className="badge badge-soft-danger text-danger ">{item.series[0].data.length > 0 && ((getTotal(item.series[0].data) - getTotal(item.series[1].data)) / Math.abs(getTotal(item.series[0].data)) * 100).toFixed(2)}%</span>
                    : "-"
                  : ""
              }
            </div>
          </CardBody>
        </Card>
      }
      {
        (item.name === 'CTR' || item.name === 'Ctr') &&
        <Card className="card-h-100">
          <CardBody>
            <div className="my-0 text-primary d-flex justify-content-between align-items-center">
              <span className="my-0 text-primary">
                <i class="fa fa-info-circle text-primary" aria-hidden="true" data-tooltip="Click Through Rate"></i>{" "}
                {item.name}
              </span>
              <img className="img-intgration-icon" src={GoogleAdsIcon} />
            </div>
            <Row className="align-items-center">
              <Col xs={6}>
                <div className="mb-3">
                  {
                    loading ?
                      (isFinite(getCTR(item.series[0].data[1], item.series[0].data[0])) ? <NumberFormat value={getCTR(item.series[0].data[1], item.series[0].data[0])} decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={'%'} renderText={value => <h4>{value}</h4>} /> : "No CTR")
                      : ""
                  }
                </div>
              </Col>
              <Col xs={6}>
                <ReactApexChart
                  options={item.options}
                  series={item.series[1].data.length > 0 ? [{ name: item.name, data: generateCTRChart()[0] }, { name: "Compared To", data: generateCTRChart()[1] }] : [{ name: item.name, data: generateCTRChart()[0] }]}
                  type="line"
                  width={"100%"}
                  height={60}
                />
              </Col>
              <div className="text-nowrap h4">
                {
                  loading ?
                    item.series[1].data[0].length > 0 ?
                      getLastCTR(item.series[1].data[1], item.series[1].data[0], diff) > getCTR(item.series[0].data[1], item.series[0].data[0], diff) ?
                        <span className="badge badge-soft-danger text-danger ">{(((getCTR(item.series[0].data[1], item.series[0].data[0], diff) - getLastCTR(item.series[1].data[1], item.series[1].data[0], diff)) / Math.abs(getCTR(item.series[0].data[1], item.series[0].data[0], diff))) * 100).toFixed(1)}%</span>
                        :
                        <span className="badge badge-soft-success text-success">{(((getCTR(item.series[0].data[1], item.series[0].data[0], diff) - getLastCTR(item.series[1].data[1], item.series[1].data[0], diff)) / Math.abs(getCTR(item.series[0].data[1], item.series[0].data[0], diff))) * 100).toFixed(1)}%</span>
                      : "-"
                    : ''
                }
              </div>
            </Row>
          </CardBody>
        </Card>
      }
      {
        (item.name === 'ROAs' || item.name === 'ROAS') &&
        <Card className="card-h-100">
          <CardBody>
            <div className="my-0 text-primary d-flex justify-content-between align-items-center">
              <span className="my-0 text-primary">
                <i class="fa fa-info-circle text-primary" aria-hidden="true" data-tooltip="Return On Ad Spend "></i>{" "}
                {item.name}
              </span>
              <img className="img-intgration-icon" src={GoogleAdsIcon} />
            </div>
            <Row className="align-items-center">
              <Col xs={6}>
                <div className="mb-3">
                  {
                    loading ?
                      (isFinite(getROAS(item.series[0].data[1], item.series[0].data[0])) ? <NumberFormat value={getROAS(item.series[0].data[1], item.series[0].data[0])} decimalScale={2} displayType={'text'} thousandSeparator={true} renderText={value => <h4>{value}</h4>} /> : "No ROAs")
                      : ""
                  }
                </div>
              </Col>
              <Col xs={6}>
                <ReactApexChart
                  options={item.options}
                  series={item.series[1].data[1].length > 0 ? [{ name: item.name, data: generateROASChart()[0] }, { name: "Compared To", data: generateROASChart()[1] }] : [{ name: item.name, data: generateROASChart()[0] }]}
                  type="line"
                  width={"100%"}
                  height={60}
                />
              </Col>
              </Row>
              <div className="text-nowrap h4">
                {
                  loading ?
                    item.series[1].data.length > 0 ?
                      getLastROAS(item.series[0].data) > getROAS(item.series[1].data) ?
                        <span className="badge badge-soft-success text-success">{item.series[0].data.length > 0 && ((getROAS(item.series[0].data) - getLastROAS(item.series[1].data)) / Math.abs(getTotal(item.series[0].data)) * 100).toFixed(2)}%</span>
                        :
                        <span className="badge badge-soft-danger text-danger ">{item.series[0].data.length > 0 && ((getROAS(item.series[0].data) - getLastROAS(item.series[1].data)) / Math.abs(getTotal(item.series[0].data)) * 100).toFixed(2)}%</span>
                      : "-"
                    : ""
                }
              </div> 
          </CardBody>
        </Card>
      }
       {
        (item.name === 'CPC' || item.name === 'Cpc') &&
        <Card className="card-h-100">
          <CardBody>
            <div className="my-0 text-primary d-flex justify-content-between align-items-center">
              <span className="my-0 text-primary">
                <i class="fa fa-info-circle text-primary" aria-hidden="true" data-tooltip="Cost Per Click"></i>{" "}
                {item.name}
              </span>
              <img className="img-intgration-icon" src={GoogleAdsIcon} />
            </div>
            <Row className="align-items-center">
              <Col xs={6}>
                <div className="mb-3">
                  {
                    loading ?
                      (isFinite(getCPC(item.series[0].data[1], item.series[0].data[0])) ? <NumberFormat value={getCPC(item.series[0].data[1], item.series[0].data[0])} decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={'$'} renderText={value => <h4>{value}</h4>} /> : "No CTR")
                      : ""
                  }
                </div>
              </Col>
              <Col xs={6}>
                <ReactApexChart
                  options={item.options}
                  series={item.series[1].data.length > 0 ? [{ name: item.name, data: generateCPCChart()[0] }, { name: "Compared To", data: generateCPCChart()[1] }] : [{ name: item.name, data: generateCPCChart()[0] }]}
                  type="line"
                  width={"100%"}
                  height={60}
                />
              </Col>
              <div className="text-nowrap h4">
                {
                  loading ?
                    item.series[1].data[0].length > 0 ?
                      getLastCPC(item.series[1].data[1], item.series[1].data[0], diff) > getCPC(item.series[0].data[1], item.series[0].data[0], diff) ?
                        <span className="badge badge-soft-success text-success ">{(((getCPC(item.series[0].data[1], item.series[0].data[0], diff) - getLastCPC(item.series[1].data[1], item.series[1].data[0], diff)) / Math.abs(getCPC(item.series[0].data[1], item.series[0].data[0], diff))) * 100).toFixed(1)}%</span>
                        :
                        <span className="badge badge-soft-danger text-danger">{(((getCPC(item.series[0].data[1], item.series[0].data[0], diff) - getLastCPC(item.series[1].data[1], item.series[1].data[0], diff)) / Math.abs(getCPC(item.series[0].data[1], item.series[0].data[0], diff))) * 100).toFixed(1)}%</span>
                      : "-"
                    : ''
                }
              </div>
            </Row>
          </CardBody>
        </Card>
      }
        {
        (item.name === 'CPA' || item.name === 'cpa') &&
        <Card className="card-h-100">
          <CardBody>
            <div className="my-0 text-primary d-flex justify-content-between align-items-center">
              <span className="my-0 text-primary">
                <i class="fa fa-info-circle text-primary" aria-hidden="true" data-tooltip="Cost per Acquisition"></i>{" "}
                {item.name}
              </span>
              <img className="img-intgration-icon" src={GoogleAdsIcon} />
            </div>
            <Row className="align-items-center">
              <Col xs={6}>
                <div className="mb-3">
                  {
                    loading ?
                      (isFinite(getCPA(item.series[0].data[1], item.series[0].data[0])) ? <NumberFormat value={getCPA(item.series[0].data[1], item.series[0].data[0])} decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={'$'} renderText={value => <h4>{value}</h4>} /> : "No CPA")
                     : ""
                  }
                </div>
              </Col>
              <Col xs={6}>
                <ReactApexChart
                  options={item.options}
                  series={item.series[1].data.length > 0 ? [{ name: item.name, data: generateCPAChart()[0] }, { name: "Compared To", data: generateCPAChart()[1] }] : [{ name: item.name, data: generateCPAChart()[0] }]}
                  type="line"
                  width={"100%"}
                  height={60}
                />
              </Col>
              <div className="text-nowrap h4">
                {
                  loading ?
                    item.series[1].data[0].length > 0 ?
                      getLastCPA(item.series[1].data[1], item.series[1].data[0], diff) > getCPA(item.series[0].data[1], item.series[0].data[0], diff) ?
                        <span className="badge badge-soft-success text-success ">{(((getCPA(item.series[0].data[1], item.series[0].data[0], diff) - getLastCPA(item.series[1].data[1], item.series[1].data[0], diff)) / Math.abs(getCPA(item.series[0].data[1], item.series[0].data[0], diff))) * 100).toFixed(1)}%</span>
                        :
                        <span className="badge badge-soft-danger text-danger">{(((getCPA(item.series[0].data[1], item.series[0].data[0], diff) - getLastCPA(item.series[1].data[1], item.series[1].data[0], diff)) / Math.abs(getCPA(item.series[0].data[1], item.series[0].data[0], diff))) * 100).toFixed(1)}%</span>
                      : "-"
                    : ''
                }
              </div>
            </Row>
          </CardBody>
        </Card>
      }
    {
        (item.name === 'Conversion Rate' || item.name === 'Conversion Rate') &&
        <Card className="card-h-100">
          <CardBody>
            <div className="my-0 text-primary d-flex justify-content-between align-items-center">
              <span className="my-0 text-primary">
                <i class="fa fa-info-circle text-primary" aria-hidden="true" data-tooltip="Conversion Rate"></i>{" "}
                {item.name}
              </span>
              <img className="img-intgration-icon" src={GoogleAdsIcon} />
            </div>
            <Row className="align-items-center">
              <Col xs={6}>
                <div className="mb-3">
                  {
                    loading ?
                      (isFinite(getConversionRate(item.series[0].data[1], item.series[0].data[0])) ? <NumberFormat value={getConversionRate(item.series[0].data[1], item.series[0].data[0])} decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={'%'} renderText={value => <h4>{value}</h4>} /> : "No CR%")
                      : ""
                  }
                </div>
              </Col>
              <Col xs={6}>
                <ReactApexChart
                  options={item.options}
                  series={item.series[1].data.length > 0 ? [{ name: item.name, data: generateCRChart()[0] }, { name: "Compared To", data: generateCRChart()[1] }] : [{ name: item.name, data: generateCRChart()[0] }]}
                  type="line"
                  width={"100%"}
                  height={60}
                />
              </Col>
              <div className="text-nowrap h4">
                {
                  loading ?
                    item.series[1].data[0].length > 0 ?
                      getLastConversionRate(item.series[1].data[1], item.series[1].data[0], diff) > getConversionRate(item.series[0].data[1], item.series[0].data[0], diff) ?
                        <span className="badge badge-soft-danger text-danger ">{(((getConversionRate(item.series[0].data[1], item.series[0].data[0], diff) - getLastConversionRate(item.series[1].data[1], item.series[1].data[0], diff)) / Math.abs(getConversionRate(item.series[0].data[1], item.series[0].data[0], diff))) * 100).toFixed(1)}%</span>
                        :
                        <span className="badge badge-soft-success text-success">{(((getConversionRate(item.series[0].data[1], item.series[0].data[0], diff) - getLastConversionRate(item.series[1].data[1], item.series[1].data[0], diff)) / Math.abs(getConversionRate(item.series[0].data[1], item.series[0].data[0], diff))) * 100).toFixed(1)}%</span>
                      : "-"
                    : ''
                }
              </div>
            </Row>
          </CardBody>
        </Card>
      }
     {
        (item.name === 'AOV' || item.name === 'AOV') &&
        <Card className="card-h-100">
          <CardBody>
            <div className="my-0 text-primary d-flex justify-content-between align-items-center">
              <span className="my-0 text-primary">
                <i class="fa fa-info-circle text-primary" aria-hidden="true" data-tooltip="Average Order Value"></i>{" "}
                {item.name}
              </span>
              <img className="img-intgration-icon" src={GoogleAdsIcon} />
            </div>
            <Row className="align-items-center">
              <Col xs={6}>
                <div className="mb-3">
                  {
                    loading ?
                      (isFinite(getAOV(item.series[0].data[1], item.series[0].data[0])) ? <NumberFormat value={getAOV(item.series[0].data[1], item.series[0].data[0])} decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={currencyrevenue} renderText={value => <h4>{value}</h4>} /> : "No AOV")
                      : ""
                  }
                </div>
              </Col>
              <Col xs={6}>
                <ReactApexChart
                  options={item.options}
                  series={item.series[1].data.length > 0 ? [{ name: item.name, data: generateAOVChart()[0] }, { name: "Compared To", data: generateAOVChart()[1] }] : [{ name: item.name, data: generateAOVChart()[0] }]}
                  type="line"
                  width={"100%"}
                  height={60}
                />
              </Col>
              <div className="text-nowrap h4">
                {
                  loading ?
                    item.series[1].data[0].length > 0 ?
                      getLastAOV(item.series[1].data[1], item.series[1].data[0], diff) > getAOV(item.series[0].data[1], item.series[0].data[0], diff) ?
                        <span className="badge badge-soft-danger text-danger ">{(((getAOV(item.series[0].data[1], item.series[0].data[0], diff) - getLastAOV(item.series[1].data[1], item.series[1].data[0], diff)) / Math.abs(getAOV(item.series[0].data[1], item.series[0].data[0], diff))) * 100).toFixed(1)}%</span>
                        :
                        <span className="badge badge-soft-success text-success">{(((getAOV(item.series[0].data[1], item.series[0].data[0], diff) - getLastAOV(item.series[1].data[1], item.series[1].data[0], diff)) / Math.abs(getAOV(item.series[0].data[1], item.series[0].data[0], diff))) * 100).toFixed(1)}%</span>
                      : "-"
                    : ''
                }
              </div>
            </Row>
          </CardBody>
        </Card>
      }
    </div >
  )
}
export default GoogleAdsDataCard
