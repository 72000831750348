import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import MetaTags from 'react-meta-tags';
import { Col, Container, Row } from 'reactstrap';
import ReactCodeInput from 'react-code-input';
import livemetrics from '../../assets/images/Live_Metrics_Logo.png';
//Verification code package
// import AuthCode from 'react-auth-code-input';

//import images
import logo from '../../assets/images/logo-sm.svg';
import CarouselPage from '../AuthenticationInner/CarouselPage';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import {
  isConnectedRequest,
  isVerifiedRequest,
  verifCodeRequest,
} from '../../store/actions';

const TwoStepVerification = () => {
  const [result, setResult] = useState();
  const dispatch = useDispatch();
  const { isConnected, isVerified, loading } = useSelector(
    (state) => state.editProfile
  );
  const handleOnChange = (res) => {
    setResult(res);
  };
  const { id ,verifCode} = useParams();
  
  const onSubmit = (event) => {
    event.preventDefault();
    dispatch(verifCodeRequest(id, result,verifCode));
  };

  useEffect(() => {
    console.log('is connected', isConnected);

    if (isConnected) window.location.replace('/integrations');
  }, [isConnected]);

  useEffect(() => {
    dispatch(isConnectedRequest());
  }, []);

  useEffect(() => {
    const verifyUser = async () => {
      await dispatch(isVerifiedRequest(id));
    };

    verifyUser();
  }, [dispatch, id]);

  useEffect(() => {
    if (!loading && isVerified) {
      window.location.replace('/404');
    }
  }, [isVerified, loading]);


  if (isVerified || loading ) return null;

  return (
    <React.Fragment>
      <MetaTags>
        <title>Two Step Verification</title>
      </MetaTags>
      <div className="auth-page">
        <Container fluid className="p-0">
          <Row className="g-0">
            <Col lg={4} md={5} className="col-xxl-3">
              <div className="auth-full-page-content d-flex p-sm-5 p-4">
                <div className="w-100">
                  <div className="d-flex flex-column h-100">
                    <div className="mb-4 mb-md-5 text-center">
                      <Link to="/login" className="d-block auth-logo">
                        <img src={livemetrics} alt="" height="28" />
                      </Link>
                    </div>
                    <div className="auth-content my-auto">
                      <div className="text-center">
                        <div className="avatar-lg mx-auto">
                          <div className="avatar-title rounded-circle bg-light">
                            <i className="bx bxs-envelope h2 mb-0 text-primary"></i>
                          </div>
                        </div>
                        <div className="p-2 mt-4">
                          <h4>Verify your email</h4>
                          <p className="mb-5">
                            Please enter the 4 digit code sent to{' '}
                            <span className="fw-bold">example@abc.com</span>
                          </p>

                          <form onSubmit={onSubmit}>
                            <div className="row">
                              <div className="col-12">
                                <div className="mb-3 verification">
                                  <label
                                    htmlFor="digit1-input"
                                    className="visually-hidden"
                                  >
                                    Digit 1
                                  </label>

                                  <ReactCodeInput
                                    type="text"
                                    fields={4}
                                    onChange={handleOnChange}
                                    inputStyle={{
                                      width: '50px',
                                      height: '42px',
                                      padding: '8px',
                                      borderRadius: '8px',
                                      fontSize: '16px',
                                      textAlign: 'center',
                                      marginRight: '25px',
                                      border: '1px solid #ced4da',
                                      textTransform: 'uppercase',
                                    }}
                                  />
                                </div>
                                <div className="mt-4">
                                  <button
                                    className="btn btn-primary w-100"
                                    type="submit"
                                  >
                                    Confirm
                                  </button>
                                </div>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>

                      <div className="mt-5 text-center">
                        <p className="text-muted mb-0">
                          Didn't receive an email?{' '}
                          <Link to="#" className="text-primary fw-semibold">
                            Resend
                          </Link>
                        </p>
                      </div>
                    </div>
                    <div className="mt-4 mt-md-5 text-center">
                      <p className="mb-0">
                        © {new Date().getFullYear()} LiveMetrics. Crafted with
                        by Convergen
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
            <CarouselPage />
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default TwoStepVerification;
