import React, { useEffect, useState } from 'react';
import { Card, CardBody, Col, Container, Row, FormGroup, Input, Modal, Label, Alert, Table, Button, InputGroupText, InputGroup, ModalHeader, ModalBody, Badge} from "reactstrap";
import ApolloClient from "apollo-boost";
import { getFromShopify, editPlatform, deletePlatform, checkSales } from '../../../queries/Shopify/queries';
import urls from '../../../routes/apiUrls';
import SweetAlert from "react-bootstrap-sweetalert";
import { Tbody, Th, Thead, Tr,Td } from 'react-super-responsive-table';
import ShopifyStore from './ShopifyStore';
import ShopifyIcon from "../Shopify/shopify logo.png";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import ShLogo from "../Shopify/shopify2.png";
import coming_soon from "../../../assets/images/coming-soon.png";
// ** Icons Imports
import { Check, Facebook, Twitter, Linkedin, Link2, Key } from 'react-feather';



const AppShop = new ApolloClient({
  uri: urls.shopify
})
const ApploCLI = new ApolloClient({
  uri: urls.shopify
});
function ShopifyCard(props) {
  const [info, setInfo] = useState(null);
  const [step, setStep] = useState(1);
  const [url, setUrl] = useState("");
  const [loading, setLoading] = useState(false);
  const [startEdit, setStartEdit] = useState(true);
  const [reachedFinalStep, setReachedFinalStep] = useState(false);
  const [filterStr, setFilterStr] = useState("");
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [refetch, setRefetch] = useState(false);
  const [stores, setStores] = useState([]);
  const [inputData, setInputData] = useState("");
  const [modal_standard, setmodal_standard] = useState(false);
  const [isShopify, setIsShopify] = useState(false);
  const [validation, setValidation] = useState({
    unm: null,
    fnm: null,
    lnm: null,
  })
  const data = [
    {
      icon: <Link2 className='icon' />,
      title: props.t('Allow Access 🤟🏻'),
      subtitle: props.t('Allow LiveMetrics to access to your shopify partner account ')
    },
    {
      icon: <Key className='icon' />,
      title: props.t('Add Your Domain Store 👩🏻‍💻'),
      subtitle: props.t('Just add your domain store ')
    },
    {
      icon: <Check className='icon' />,
      title: props.t('Your Account is ready for use 🎉'),
      subtitle: props.t('You  will get 7 days free trial')
    }
  ]
  //ShopifyIcon = require(`@src/assets/images/integrations/${shopifyLogo}`).default
 useEffect(() => {
  AppShop.query({
    query: checkSales,
    context: {
      headers: {
        Authorization: window.localStorage.getItem("token")
          ? `Bearer ${window.localStorage.getItem("token")}`
          : "",
      },
    },
    errorPolicy: "all",
    fetchPolicy: "network-only",
  }).then((data) => {
    if (data.data.getIntegration) {
      if (data.data.getIntegration.type == 1) {
        setStep(2)
        setReachedFinalStep(true)
        setIsShopify(true)
        setStores(data.data.getIntegration)
       
      }
    }
  });
 }, [stores]);
  const integrateShopify = (raw_data) => {
    console.log(raw_data);
    try {
      console.log("HEREz")
      const data = JSON.parse(raw_data);
      console.log("DATA", data);
      ApploCLI.mutate({
        mutation: getFromShopify,
        context: {
          headers: {
            Authorization: window.localStorage.getItem("token")
              ? `Bearer ${window.localStorage.getItem("token")}`
              : "",
          },
        },
        variables: {
          code: data.code,
          host: data.host,
          hmac: data.hmac,
          shop: data.shop,
          timestamp: data.timestamp,
        },
        errorPolicy: "all",
      }).then((data) => {
        if (data.data.integration !== null) {
          if (data.data.integration.error) {
            SweetAlert.fire({
              icon: 'error',
              title: 'Integration Error',
              text: data.data.integration.error,
            })
          } else {
            setStep(2);
            setReachedFinalStep(true);
          }
        }
      });
    } catch (error) {
    }
  };
  
  const openPopup = () => {
    const apiKey = '2967d0d5ad9a22ad58535f52e77436ce';
const redirectUri = window.location.hostname === "localhost" || window.location.hostname === "127.0.0.1" ? 'http://localhost:3000/integrations': `https://${window.location.hostname}/integrations`;
const permissionUrl = `/oauth/authorize?client_id=${apiKey}&scope=read_checkouts,read_shopify_payments_payouts,read_all_orders,read_content,write_content,read_themes,write_themes,read_products,write_products,read_customers,write_customers,read_orders,write_orders,read_script_tags,write_script_tags,read_fulfillments,write_fulfillments,read_shipping,write_shipping,read_inventory,write_inventory&redirect_uri=${redirectUri}`;
    
let setLoading = true;
let windowObjectReference = null;
let previousUrl = null;
let interval = null;
const strWindowFeatures = "toolbar=no, menubar=no, width=1360, height=700, top=100, left=108";

if (windowObjectReference === null || windowObjectReference.closed) {
    windowObjectReference = window.open(`https://${inputData}.myshopify.com/admin${permissionUrl}`, "", strWindowFeatures);
    var timer = setInterval(function () {
        if (windowObjectReference.closed) {
            setLoading = false;
            clearInterval(timer);
        }
    }, 1000);
} else if (previousUrl !== url) {
    windowObjectReference = window.open(url, "", strWindowFeatures);
    windowObjectReference.focus();
    var timer = setInterval(function() {
        if (windowObjectReference.closed) {
            setLoading = false;
            clearInterval(timer);
        }
    }, 1000);
}
    interval = window.setInterval(() => {
      try {
        let temp = windowObjectReference.location;
        if
          (temp.hostname === "localhost" || temp.hostname === "app.live-metrics.io") {
          window.clearInterval(interval);
          console.log(temp.href);
          var result = '{';
          var test = temp.href.split("?")[1];
          for (let i in test.split("&")) {
            let item = test.split("&")[i];
            result = result + `"${item.split("=")[0]}":"${item.split("=")[1]}"`;
            if (i < (test.split("&").length - 1)) result = result + ","
          }
          result = result + '}'
          try {
            JSON.parse(result);
            integrateShopify(result);
            windowObjectReference.close();
          setLoading(false);
          setStep(2);
          setReachedFinalStep(true);    
          window.location.reload();
       
          } catch (e) {
            windowObjectReference.close();
            SweetAlert.fire({
              title: props.t("Shopify Integration Error"),
              icon: props.t("error"),
              text: props.t("There was a technical error when integrating your store. Please contact our support at: support@livemetrics.tn")
            }).then((val) => {
              setLoading(false);
            })
          }
        }
      } catch (error) {
      }
    }, 1000);
    previousUrl = url;
  };
  const deleteConnection = () => {
    SweetAlert.fire({
      title: props.t("Are you sure?"),
      text: props.t("You won't be able to revert this!"),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: props.t("Yes, delete it!"),
    }).then((result) => {
      if (result.value) {
        ApploCLI.mutate({
          mutation: deletePlatform,
          context: {
            headers: {
              Authorization: window.localStorage.getItem("token") ? `Bearer ${window.localStorage.getItem("token")}` : ""
            }
          },
          errorPolicy: 'all',
        }).then((data) => {
          if (data.data.delete.status) {
            setInfo(null);
            setStep(1);
            SweetAlert.fire(
              props.t("Deleted!"),
              props.t("Your Store has been deleted."),
              props.t("success")
            )
            window.location.replace('/integrations');
          }
        })
      }
    })
  };
  function tog_standard() {
    setmodal_standard(!modal_standard)
    removeBodyCss()
  }
  function removeBodyCss() {
    document.body.classList.add("no_padding")
  }
  const firstStep = () => {
    return (
      <React.Fragment>
  <div className="page-content">
    <Container fluid>
      <Row>
        <Col>
          <Card className="border text-secondary">
            <CardBody>
              <div className="my-0 text-primary d-flex justify-content-between align-items-center">
              <img  className="comingsoon-intgration" src={coming_soon} alt="Coming Soon" />
                <div className='text-left d-flex align-items-center'>
                  <img className="img-intgration-icon" src={ShopifyIcon} />
                  <span className="text-primary h4 mb-0 ml-2">Shopify</span>
                 
                </div>
                <button
                  onClick={() => {
                    tog_standard()
                  }}
                  className="btn btn-primary"
                >{props.t("Connect")}</button>
                <Modal isOpen={modal_standard} toggle={() => tog_standard()} className='modal-dialog-centered modal-refer-earn modal-lg'>
                  <ModalHeader className='bg-transparent' toggle={() => tog_standard()}></ModalHeader>
                  <ModalBody className='pb-5 px-sm-0'>
                    <div className='text-center'>
                      <img style={{ height: '80px' }} src={ShLogo} />
                      <h1 className='text-center mb-1'>Shopify</h1>
                      <p className='text-center mb-5'>
                        {props.t('Connect your Shopify Store within 30 seconds')}
                      </p>
                      <Row className='mb-4'>
                        {data.map((item, index) => {
                          return (
                            <Col xs={12} lg={4} key={index}>
                              <div className='d-flex justify-content-center mb-1'>
                                <div className='circle'>
                                  <div className='icon'>
                                    {item.icon}
                                  </div>
                                </div>
                              </div>
                              <div className='text-center'>
                                <h6 className='fw-bolder mb-1'>{item.title}</h6>
                                <p>{item.subtitle}</p>
                              </div>
                            </Col>
                          );
                        })}
                      </Row>
                    </div>
                    <hr />
                    <div className='px-sm-5 mx-50'>
                      <h4 className='fw-bolder mt-5 mb-1'>{props.t('Enter Store Domain')}</h4>
                      <Row tag='form' className='g-1' onSubmit={e => e.preventDefault()}>
                        <Col lg='10'>

                          <InputGroup>
                            <InputGroupText>https</InputGroupText>
                            <Input 
          placeholder="Enter store name" // Adding a placeholder for better UX
          value={inputData} // Binding inputData to the Input's value
          onChange={(e) => setInputData(e.target.value)} // Updating state on input change
        />
                            <InputGroupText>.myshopify.com</InputGroupText>
                          </InputGroup>
                        </Col>
                        <Col lg='2' className='d-flex align-items-end'>
                          <Button block color='primary'
                            onClick={() => openPopup()}
                          >
                            {props.t('Send')}
                          </Button>
                        </Col>
                      </Row>
                    </div>
                  </ModalBody>
                </Modal>
              </div>
             
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  </div>
</React.Fragment>
    );
  }
  // const filteredAccounts = stores.filter((e) =>
  //   e.name.toUpperCase().includes(filterStr.toUpperCase())
  // );
  const secondStep = () => {
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Row>
              <Col>
                <Card className="border text-secondary">
                  <div className="card-header bg-transparent border-secondary">
                    <div className="my-0 text-primary d-flex justify-content-between align-items-center"><h5 className="my-0 text-primary">Shopify </h5><img className="img-intgration-icon" src={ShopifyIcon} /></div>
                  </div>
                  <CardBody>
                    <FormGroup>
                    <div style={{ textAlign: "center", padding: "20px" }}>
                                                {stores  ? (
                                                    <div>
                                                        {/* <form className="app-search d-none d-lg-block">
                                                            <div className="position-relative">
                                                                <Input

                                                                    size="small"
                                                                    onChange={(e) => setFilterStr(e.target.value)}
                                                                    icon={<img name="search" link />}
                                                                    placeholder={props.t("Search...")}
                                                                    style={{ float: "right", marginBottom: "20px" }}
                                                                />
                                                                <button className="btn btn-primary" type="button"><i
                                                                    className="bx bx-search-alt align-middle"></i></button>
                                                            </div>
                                                        </form> */}
                                                        <Table
                                                            id="tech-companies-1"
                                                            className="table table-striped table-bordered"
                                                        >
                                                            <Thead>
                                                                <Tr>
                                                                    <Th data-priority="3">{props.t("Domaine Name")}</Th>
                                                                    <Th data-priority="1">{props.t("status")}</Th>
                                                                </Tr>
                                                            </Thead>
                                                            <Tbody>
                                                                        <Tr>
                                                                            <Td> {stores.url}</Td>
                                                                            <Td>
                        {stores.connectionstate ? (
                            <Badge className="me-2 bg-success">Online</Badge>
                        ) : (
                            <Badge className="me-2 bg-danger">Offline</Badge>
                        )}
                    </Td>
                                                                        </Tr>
                                                                   
                                                                
                                                            </Tbody>
                                                        </Table>
                                                    </div>
                                                ) : (
                                                    <div style={{ display: "grid" }}>
                                                        <div>
                                                            <h3>{props.t("There is no Store integrated")}</h3>
                                                            <small>{props.t("Tryagain")}</small>
                                                        </div>

                                                    </div>
                                                )}
                                                {deleteLoading ? (
                                                    <img style={{ float: 'left', margin: 10 }} name="notched circle loading icon" />
                                                ) : (

                                                    <div className='text-center'>
                                                        <button type="button" className="btn btn-danger waves-effect waves-light" onClick={() => deleteConnection()}>
                                                            <i className="bx bx-block font-size-16 align-middle"></i>{props.t("Delete Connection")}
                                                        </button>
                                                    </div>
                                                )}
                                            </div>
                    </FormGroup>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment >
    )
  };
  const thirdStep = () => {
    return (
      <React.Fragment>
  <div className="page-content">
    <Container fluid>
      <Row>
        <Col>
          <Card className="border text-secondary">
         
            <div className="card-header bg-transparent border-secondary">
            <img  className="comingsoon-intgration" src={coming_soon} alt="Coming Soon" />
              <div className="my-0 text-primary d-flex justify-content-between align-items-center">
                <h5 className="my-0 text-primary">Shopify </h5>
                <img className="img-intgration-icon" src={ShopifyIcon} />
              </div>
            </div>
            <CardBody>
              <FormGroup>
                <div style={{ display: 'flex', flexDirection: 'center', alignItems: 'center', justifyContent: 'center' }} >
                  <div className="mt-4 mt-md-0 text-center">
                    <Alert color="warnning m-0">
                      <p style={{ fontWeight: 'bold' }}>{props.t("You have already a store integrated")}</p>
                      {/* Include the coming_soon image */}
                     
                    </Alert>
                  </div>
                </div>
              </FormGroup>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  </div>
</React.Fragment>
    )
  }
  const loadingSection = () => {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          marginTop: "20px",
        }}
      >
        <Input style={{ height: 10, width: "100%" }} />
        <Input style={{ height: 10, width: 300 }} />
        <Input style={{ height: 10, width: "100%" }} />
      </div>
    );
  };
  const renderSections = () => {
    switch (step) {
      case 3:
        return thirdStep();
      case 0:
        return loadingSection();
      case 1:
        return firstStep();
      case 2:
        return secondStep();
      default:
        return loadingSection();
    }
  };
  return <div>{renderSections()}</div>;
}
ShopifyCard.propTypes = {
  t: PropTypes.any
};

export default withRouter(withTranslation()(ShopifyCard));
//export default ShopifyCard;