import React, { useState, useEffect } from 'react';
import { TabContent, TabPane, Nav, CardBody, Progress, Alert } from 'reactstrap';
import { Table, Thead, Tbody, Tr, Th } from "react-super-responsive-table";
import SingleTopSeller from './SingleTopSeller';
import MetaTags from 'react-meta-tags';
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import SimpleBar from "simplebar-react"
import {fetchTopSellersRequest} from '../../../store/woocommerce/actions';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from "react-router-dom"
import { withTranslation } from "react-i18next"
import secureSection from '../../../MangeSecurity/MemberSecurity';
import PropTypes from "prop-types";

function WooTopSellers(props) {
  secureSection();
  const [activeTab, setActiveTab] = useState('1');
  const dispatch = useDispatch();
  const { topSellers } = useSelector((state) => state.woocommerce);
  useEffect(() => {
    const savedTopSellers = localStorage.getItem('wootopsellers');
    if(savedTopSellers){
      dispatch({ type: 'FETCH_TOP_SELLERS_SUCCESS', payload: JSON.parse(savedTopSellers) });
    }else {
    dispatch(fetchTopSellersRequest(50, 1));
    }
  }, [dispatch]);
  return (
    <React.Fragment>
      <div className="page-content">
      <MetaTags>
          <title>{props.t("woo top sellers")} | Live-Metrics - E-com SaaS</title>
        </MetaTags>
        <div className="container-fluid">
          <Breadcrumbs title={props.t("WooCommerce")} breadcrumbItem={props.t("Top Sellers")} pageHeading={props.t("Top Sellers")} />
        <div className="card">
          <div className="card-header align-items-center d-flex">
            <h4 className="card-title mb-0 flex-grow-1">{props.t("Top Sellers")}</h4>
            <i class="fa fa-info-circle text-primary" aria-hidden="true" data-tooltip="Top Sellers Prodcuts of Last Month"></i>{" "}
            <div className="flex-shrink-0">
              <Nav className="justify-content-end nav-tabs-custom rounded card-header-tabs" role="tablist">
              </Nav>
            </div>
          </div>
          <CardBody className="px-0">
            <TabContent activeTab={activeTab}>
              <TabPane tabId="1">
                {topSellers && topSellers.length > 0 ? (
                  <SimpleBar className="table-responsive px-3" style={{ maxHeight: "600px" }}>
                    <Table className="table table-striped table-bordered">
                      <Thead>
                        <Tr>
                          <Th data-priority="1">{props.t("Product Id")}</Th>
                          <Th data-priority="1">{props.t("Name")}</Th>
                          <Th data-priority="1">{props.t("Quantity")}</Th>
                        </Tr>
                      </Thead>
                      <Tbody>
                        {topSellers.map((element, index) => (
                          <SingleTopSeller element={element} key={index} />
                        ))}
                      </Tbody>
                    </Table>
                  </SimpleBar>
                ) : (
                  <div className='text-center'>
                    <div className="text-center">
                      <CardBody>
                        <Progress
                          value={80}
                          color="success"
                          style={{ width: '75%' }}
                          animated
                        ></Progress>
                        <Alert color="success" className="mt-3">
                        {props.t("This might take a few minutes!")}
                        </Alert>
                      </CardBody>
                    </div>
                  </div>
                )}
              </TabPane>
            </TabContent>
          </CardBody>
        </div>
      </div>
      </div>
    </React.Fragment>
  );
}
WooTopSellers.propTypes = {
  t: PropTypes.any
};
export default withRouter(withTranslation()(WooTopSellers));
//export default WooTopSellers;