import {
    GET_METRICS_BY_CAMPAIGNS,
    GET_METRICS_BY_CAMPAIGNS_SUCCESS,
    GET_METRICS_BY_CAMPAIGNS_FAILURE,
    GET_ADS,
    GET_ADS_SUCCESS,
    GET_ADS_FAILURE,
    GET_METRICS_BY_CAMPAIGNS_PER_DATE,
    GET_METRICS_BY_CAMPAIGNS_PER_DATE_SUCCESS,
    GET_METRICS_BY_CAMPAIGNS_PER_DATE_FAILURE,
    GET_GOOGLE_METRICS,
    GET_GOOGLE_METRICS_SUCCESS,
    GET_GOOGLE_METRICS_FAILURE,
    GET_KEYWORDS_GOOGLE_ADS_REQUEST,
    GET_KEYWORDS_GOOGLE_ADS_SUCCESS,
    GET_KEYWORDS_GOOGLE_ADS_FAILURE

}
    from './actions';

const initialState = {
    DataDashboard: [],
    metrcisbyCompaign: [],
    ads: [],
    gaKeywords: [],
    error: null,

};

export default function GoogleAds(state = initialState, action) {
    switch (action.type) {
        case GET_GOOGLE_METRICS:
            return {
                ...state,
                error: null,
            };
        case GET_GOOGLE_METRICS_SUCCESS:

            return {
                ...state,
                DataDashboard: action.payload,
            }
        case GET_GOOGLE_METRICS_FAILURE:
            return {
                ...state,
                error: action.payload,
            }
        case GET_METRICS_BY_CAMPAIGNS:
            return {
                ...state,
                error: null,
            };
        case GET_METRICS_BY_CAMPAIGNS_SUCCESS:
            return {
                ...state,
                metrcisbyCompaign: action.payload.metrcisbyCompaign,
            }
        case GET_METRICS_BY_CAMPAIGNS_FAILURE:
            return {
                ...state,
                error: action.payload,

            }
        case GET_ADS:
            return {
                ...state,
                error: null,
            }
        case GET_ADS_SUCCESS:
            return {
                ...state,
                ads: action.payload.ads,
            }
        case GET_ADS_FAILURE:
            return {
                ...state,
                error: action.payload,
            }
        case GET_METRICS_BY_CAMPAIGNS_PER_DATE:
            return {
                ...state,
            }
        case GET_METRICS_BY_CAMPAIGNS_PER_DATE_SUCCESS:
            return {
                ...state,
                metrcisbyCompaign: action.payload.MetrcisbyCompaignSelectedDate,
            }
        case GET_METRICS_BY_CAMPAIGNS_PER_DATE_FAILURE:
            return {
                ...state,
                error: action.payload,
            }
        case GET_KEYWORDS_GOOGLE_ADS_REQUEST:
            return {
                ...state,
                error: null
            }
        case GET_KEYWORDS_GOOGLE_ADS_SUCCESS:
            return {
                ...state,
                gaKeywords: action.payload
            }
        case GET_KEYWORDS_GOOGLE_ADS_FAILURE:
            return {
                ...state,
                error: action.payload
            }

        default:
            return state;
    }
}