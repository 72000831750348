import React, { useState, useEffect } from "react"
import MetaTags from 'react-meta-tags';
import { Row } from "reactstrap"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import ApolloClient from 'apollo-boost';
import urls from "../../../routes/apiUrls";
import { getGAEcommerceAffiliation } from "../../../queries/GoogleAnalytics/Queries";
import GAEcommerceAffiliationCard from "./GAEcommerceAffiliationCard";

const ApploCLI = new ApolloClient({
    uri: urls.analytics,
});
function GAEcommerceAffiliation() {
    const [EcommerceAffiliationDashboard, setEcommerceAffiliationDashboard] = useState([]);
    const [loadingState, setLoadingState] = useState(false);
    const [transactions, setTransactions] = useState([]);
    const [ecommerceConversionRate, setEcommerceConversionRate] = useState([]);
    const [revenue, setRevenue] = useState([]);
    const [avgOrderValue, setAvgOrderValue] = useState([]);
    const [perSessionValue, setPerSessionValue] = useState([]);
    const [shipping, setShipping] = useState([]);
    const [tax, setTax] = useState([]);
    const [totalValue, setTotalValue] = useState([]);
    const [itemQuantity, setItemQuantity] = useState([]);
    const [revenuePerItem, setRevenuePerItem] = useState([]);
    const [uniquePurchases, setUniquePurchases] = useState([]);
    const [itemRevenue, setItemRevenue] = useState([]);
    const [itemsPerPurchase, setItemsPerPurchase] = useState([]);
    useEffect(() => {
        let isSubscribed = true;
        ApploCLI.query({
            query: getGAEcommerceAffiliation,
            context: {
                headers: {
                    Authorization: window.localStorage.getItem("token")
                        ? `Bearer ${window.localStorage.getItem("token")}`
                        : "",
                },
            },
            errorPolicy: "all",
            fetchPolicy: "network-only", //allow me to refecth data
        }).then((data) => {
            if (isSubscribed) {
                if (data.data.gaecommerceaffiliations) {
                    setEcommerceAffiliationDashboard(data.data.gaecommerceaffiliations);
                    setLoadingState(true);
                } else {
                    setLoadingState(false);
                }
            }
        });
        return () => (isSubscribed = false);
    }, []);

    useEffect(() => {
        let isSubscribed = true;
        var Ttransactions = [];
        var TecommerceConversionRate = [];
        var Trevenue = [];
        var TavgOrderValue = [];
        var TperSessionValue = [];
        var Tshipping = [];
        var Ttax = [];
        var TtotalValue = [];
        var TitemQuantity = [];
        var TrevenuePerItem = [];
        var TuniquePurchases = [];
        var TitemRevenue = [];
        var TitemsPerPurchase = [];
        if (EcommerceAffiliationDashboard.length !== 0) {
            EcommerceAffiliationDashboard.map((elem) => {
                Ttransactions.push(elem.transactions ? (elem.transactions) : 0)
                TecommerceConversionRate.push(elem.ecommerceConversionRate ? (elem.ecommerceConversionRate) : 0)
                Trevenue.push(elem.revenue ? (elem.revenue) : 0)
                TavgOrderValue.push(elem.avgOrderValue ? (elem.avgOrderValue) : 0)
                TperSessionValue.push(elem.perSessionValue ? (elem.perSessionValue) : 0)
                Tshipping.push(elem.shipping ? (elem.shipping) : 0)
                Ttax.push(elem.tax ? (elem.tax) : 0)
                TtotalValue.push(elem.totalValue ? (elem.totalValue) : 0)
                TitemQuantity.push(elem.itemQuantity ? (elem.itemQuantity) : 0)
                TrevenuePerItem.push(elem.revenuePerItem ? (elem.revenuePerItem) : 0)
                TuniquePurchases.push(elem.uniquePurchases ? (elem.uniquePurchases) : 0)
                TitemRevenue.push(elem.itemRevenue ? (elem.itemRevenue) : 0)
                TitemsPerPurchase.push(elem.itemsPerPurchase ? (elem.itemsPerPurchase) : 0)
            })
        }
        setTransactions(Ttransactions);
        setEcommerceConversionRate(TecommerceConversionRate);
        setRevenue(Trevenue);
        setAvgOrderValue(TavgOrderValue);
        setPerSessionValue(TperSessionValue);
        setShipping(Tshipping);
        setTax(Ttax);
        setTotalValue(TtotalValue);
        setItemQuantity(TitemQuantity);
        setRevenuePerItem(TrevenuePerItem);
        setUniquePurchases(TuniquePurchases);
        setItemRevenue(TitemRevenue);
        setItemsPerPurchase(TitemsPerPurchase);
        return () => (isSubscribed = false);
    }, [EcommerceAffiliationDashboard]);
    var op1 = {
        chart: {
            toolbar: {
                followCursor: true,
                show: false,
                tools: {
                    download: true,
                    selection: true,
                    zoom: true,
                    zoomin: true,
                    zoomout: true,
                    pan: true,
                    reset: true,
                },
                tooltip: {
                    shared: true,
                    intersect: true,
                    followCursor: true,
                    x: {
                        show: false,
                    },
                },
                autoSelected: "zoom",
            },
            type: "area",
            height: 120,
            sparkline: {
                enabled: true,
            },
        },
        markers: {
            size: 0,
        },
        stroke: {
            curve: "straight",
            width: 2.5,
        },
        fill: {
            opacity: 1,
        },
        series: [{ name: "Transactions", data: transactions }],
        xaxis: {
            crosshairs: {
                width: 1,
            },
        },
        yaxis: {
            show: false,
        },
        colors: ["#F6B43B", "#EC1A74"],
    };
    var op2 = {
        chart: {
            toolbar: {
                followCursor: true,
                show: false,
                tools: {
                    download: true,
                    selection: true,
                    zoom: true,
                    zoomin: true,
                    zoomout: true,
                    pan: true,
                    reset: true,
                },
                tooltip: {
                    shared: true,
                    intersect: true,
                    followCursor: true,
                    x: {
                        show: false,
                    },
                },
                autoSelected: "zoom",
            },
            type: "area",
            height: 120,
            sparkline: {
                enabled: true,
            },
        },
        markers: {
            size: 0,
        },
        stroke: {
            curve: "straight",
            width: 2.5,
        },
        fill: {
            opacity: 1,
        },
        series: [{ name: "Conversion Rate", data: ecommerceConversionRate }],
        xaxis: {
            crosshairs: {
                width: 1,
            },
        },
        yaxis: {
            show: false,
        },
        colors: ["#F6B43B", "#EC1A74"],
    };
    var op3 = {
        chart: {
            toolbar: {
                followCursor: true,
                show: false,
                tools: {
                    download: true,
                    selection: true,
                    zoom: true,
                    zoomin: true,
                    zoomout: true,
                    pan: true,
                    reset: true,
                },
                tooltip: {
                    shared: true,
                    intersect: true,
                    followCursor: true,
                    x: {
                        show: false,
                    },
                },
                autoSelected: "zoom",
            },
            type: "area",
            height: 120,
            sparkline: {
                enabled: true,
            },
        },
        markers: {
            size: 0,
        },
        stroke: {
            curve: "straight",
            width: 2.5,
        },
        fill: {
            opacity: 1,
        },
        series: [{ name: "Revenue", data: revenue }],
        xaxis: {
            crosshairs: {
                width: 1,
            },
        },
        yaxis: {
            show: false,
        },
        colors: ["#F6B43B", "#EC1A74"],
    };
    var op4 = {
        chart: {
            toolbar: {
                followCursor: true,
                show: false,
                tools: {
                    download: true,
                    selection: true,
                    zoom: true,
                    zoomin: true,
                    zoomout: true,
                    pan: true,
                    reset: true,
                },
                tooltip: {
                    shared: true,
                    intersect: true,
                    followCursor: true,
                    x: {
                        show: false,
                    },
                },
                autoSelected: "zoom",
            },
            type: "area",
            height: 120,
            sparkline: {
                enabled: true,
            },
        },
        markers: {
            size: 0,
        },
        stroke: {
            curve: "straight",
            width: 2.5,
        },
        fill: {
            opacity: 1,
        },
        series: [{ name: "Revenue Per Transaction", data: avgOrderValue }],
        xaxis: {
            crosshairs: {
                width: 1,
            },
        },
        yaxis: {
            show: false,
        },
        colors: ["#F6B43B", "#EC1A74"],
    };
    var op5 = {
        chart: {
            toolbar: {
                followCursor: true,
                show: false,
                tools: {
                    download: true,
                    selection: true,
                    zoom: true,
                    zoomin: true,
                    zoomout: true,
                    pan: true,
                    reset: true,
                },
                tooltip: {
                    shared: true,
                    intersect: true,
                    followCursor: true,
                    x: {
                        show: false,
                    },
                },
                autoSelected: "zoom",
            },
            type: "area",
            height: 120,
            sparkline: {
                enabled: true,
            },
        },
        markers: {
            size: 0,
        },
        stroke: {
            curve: "straight",
            width: 2.5,
        },
        fill: {
            opacity: 1,
        },
        series: [{ name: "Transaction Revenue Per Session", data: perSessionValue }],
        xaxis: {
            crosshairs: {
                width: 1,
            },
        },
        yaxis: {
            show: false,
        },
        colors: ["#F6B43B", "#EC1A74"],
    };
    var op6 = {
        chart: {
            toolbar: {
                followCursor: true,
                show: false,
                tools: {
                    download: true,
                    selection: true,
                    zoom: true,
                    zoomin: true,
                    zoomout: true,
                    pan: true,
                    reset: true,
                },
                tooltip: {
                    shared: true,
                    intersect: true,
                    followCursor: true,
                    x: {
                        show: false,
                    },
                },
                autoSelected: "zoom",
            },
            type: "area",
            height: 120,
            sparkline: {
                enabled: true,
            },
        },
        markers: {
            size: 0,
        },
        stroke: {
            curve: "straight",
            width: 2.5,
        },
        fill: {
            opacity: 1,
        },
        series: [{ name: "Transaction Shipping", data: shipping }],
        xaxis: {
            crosshairs: {
                width: 1,
            },
        },
        yaxis: {
            show: false,
        },
        colors: ["#F6B43B", "#EC1A74"],
    };
    var op7 = {
        chart: {
            toolbar: {
                followCursor: true,
                show: false,
                tools: {
                    download: true,
                    selection: true,
                    zoom: true,
                    zoomin: true,
                    zoomout: true,
                    pan: true,
                    reset: true,
                },
                tooltip: {
                    shared: true,
                    intersect: true,
                    followCursor: true,
                    x: {
                        show: false,
                    },
                },
                autoSelected: "zoom",
            },
            type: "area",
            height: 120,
            sparkline: {
                enabled: true,
            },
        },
        markers: {
            size: 0,
        },
        stroke: {
            curve: "straight",
            width: 2.5,
        },
        fill: {
            opacity: 1,
        },
        series: [{ name: "Transaction Tax", data: tax }],
        xaxis: {
            crosshairs: {
                width: 1,
            },
        },
        yaxis: {
            show: false,
        },
        colors: ["#F6B43B", "#EC1A74"],
    };
    var op8 = {
        chart: {
            toolbar: {
                followCursor: true,
                show: false,
                tools: {
                    download: true,
                    selection: true,
                    zoom: true,
                    zoomin: true,
                    zoomout: true,
                    pan: true,
                    reset: true,
                },
                tooltip: {
                    shared: true,
                    intersect: true,
                    followCursor: true,
                    x: {
                        show: false,
                    },
                },
                autoSelected: "zoom",
            },
            type: "area",
            height: 120,
            sparkline: {
                enabled: true,
            },
        },
        markers: {
            size: 0,
        },
        stroke: {
            curve: "straight",
            width: 2.5,
        },
        fill: {
            opacity: 1,
        },
        series: [{ name: "Total Value", data: totalValue }],
        xaxis: {
            crosshairs: {
                width: 1,
            },
        },
        yaxis: {
            show: false,
        },
        colors: ["#F6B43B", "#EC1A74"],
    };
    var op9 = {
        chart: {
            toolbar: {
                followCursor: true,
                show: false,
                tools: {
                    download: true,
                    selection: true,
                    zoom: true,
                    zoomin: true,
                    zoomout: true,
                    pan: true,
                    reset: true,
                },
                tooltip: {
                    shared: true,
                    intersect: true,
                    followCursor: true,
                    x: {
                        show: false,
                    },
                },
                autoSelected: "zoom",
            },
            type: "area",
            height: 120,
            sparkline: {
                enabled: true,
            },
        },
        markers: {
            size: 0,
        },
        stroke: {
            curve: "straight",
            width: 2.5,
        },
        fill: {
            opacity: 1,
        },
        series: [{ name: "Item Quantity", data: itemQuantity }],
        xaxis: {
            crosshairs: {
                width: 1,
            },
        },
        yaxis: {
            show: false,
        },
        colors: ["#F6B43B", "#EC1A74"],
    };
    var op10 = {
        chart: {
            toolbar: {
                followCursor: true,
                show: false,
                tools: {
                    download: true,
                    selection: true,
                    zoom: true,
                    zoomin: true,
                    zoomout: true,
                    pan: true,
                    reset: true,
                },
                tooltip: {
                    shared: true,
                    intersect: true,
                    followCursor: true,
                    x: {
                        show: false,
                    },
                },
                autoSelected: "zoom",
            },
            type: "area",
            height: 120,
            sparkline: {
                enabled: true,
            },
        },
        markers: {
            size: 0,
        },
        stroke: {
            curve: "straight",
            width: 2.5,
        },
        fill: {
            opacity: 1,
        },
        series: [{ name: "Average Price", data: revenuePerItem }],
        xaxis: {
            crosshairs: {
                width: 1,
            },
        },
        yaxis: {
            show: false,
        },
        colors: ["#F6B43B", "#EC1A74"],
    };
    var op11 = {
        chart: {
            toolbar: {
                followCursor: true,
                show: false,
                tools: {
                    download: true,
                    selection: true,
                    zoom: true,
                    zoomin: true,
                    zoomout: true,
                    pan: true,
                    reset: true,
                },
                tooltip: {
                    shared: true,
                    intersect: true,
                    followCursor: true,
                    x: {
                        show: false,
                    },
                },
                autoSelected: "zoom",
            },
            type: "area",
            height: 120,
            sparkline: {
                enabled: true,
            },
        },
        markers: {
            size: 0,
        },
        stroke: {
            curve: "straight",
            width: 2.5,
        },
        fill: {
            opacity: 1,
        },
        series: [{ name: "Unique Purchases", data: uniquePurchases }],
        xaxis: {
            crosshairs: {
                width: 1,
            },
        },
        yaxis: {
            show: false,
        },
        colors: ["#F6B43B", "#EC1A74"],
    };
    var op12 = {
        chart: {
            toolbar: {
                followCursor: true,
                show: false,
                tools: {
                    download: true,
                    selection: true,
                    zoom: true,
                    zoomin: true,
                    zoomout: true,
                    pan: true,
                    reset: true,
                },
                tooltip: {
                    shared: true,
                    intersect: true,
                    followCursor: true,
                    x: {
                        show: false,
                    },
                },
                autoSelected: "zoom",
            },
            type: "area",
            height: 120,
            sparkline: {
                enabled: true,
            },
        },
        markers: {
            size: 0,
        },
        stroke: {
            curve: "straight",
            width: 2.5,
        },
        fill: {
            opacity: 1,
        },
        series: [{ name: "Product Revenue", data: itemRevenue }],
        xaxis: {
            crosshairs: {
                width: 1,
            },
        },
        yaxis: {
            show: false,
        },
        colors: ["#F6B43B", "#EC1A74"],
    };
    var op13 = {
        chart: {
            toolbar: {
                followCursor: true,
                show: false,
                tools: {
                    download: true,
                    selection: true,
                    zoom: true,
                    zoomin: true,
                    zoomout: true,
                    pan: true,
                    reset: true,
                },
                tooltip: {
                    shared: true,
                    intersect: true,
                    followCursor: true,
                    x: {
                        show: false,
                    },
                },
                autoSelected: "zoom",
            },
            type: "area",
            height: 120,
            sparkline: {
                enabled: true,
            },
        },
        markers: {
            size: 0,
        },
        stroke: {
            curve: "straight",
            width: 2.5,
        },
        fill: {
            opacity: 1,
        },
        series: [{ name: "Average Quantity", data: itemsPerPurchase }],
        xaxis: {
            crosshairs: {
                width: 1,
            },
        },
        yaxis: {
            show: false,
        },
        colors: ["#F6B43B", "#EC1A74"],
    };
    const [myCards, setMyCards] = useState([
        { id: 1, name: "Transactions", options: op1, series: op1.series },
        { id: 2, name: "Conversion Rate", options: op2, series: op2.series },
        { id: 3, name: "Revenue", options: op3, series: op3.series },
        { id: 4, name: "Revenue Per Transaction", options: op4, series: op4.series },
        { id: 5, name: "Transaction Revenue Per Session", options: op5, series: op5.series },
        { id: 6, name: "Transaction Shipping", options: op6, series: op6.series },
        { id: 7, name: "Transaction Tax", options: op7, series: op7.series },
        { id: 8, name: "Total Value", options: op8, series: op8.series },
        { id: 9, name: "Item Quantity", options: op9, series: op9.series },
        { id: 10, name: "Average Price", options: op10, series: op10.series },
        { id: 11, name: "Unique Purchases", options: op11, series: op11.series },
        { id: 12, name: "Product Revenue", options: op12, series: op12.series },
        { id: 13, name: "Average Quantity", options: op13, series: op13.series },
    ]);
    useEffect(() => {
        let isSubscribed = true;
        setMyCards([
            { id: 1, name: "Transactions", options: op1, series: op1.series },
            { id: 2, name: "Conversion Rate", options: op2, series: op2.series },
            { id: 3, name: "Revenue", options: op3, series: op3.series },
            { id: 4, name: "Revenue Per Transaction", options: op4, series: op4.series },
            { id: 5, name: "Transaction Revenue Per Session", options: op5, series: op5.series },
            { id: 6, name: "Transaction Shipping", options: op6, series: op6.series },
            { id: 7, name: "Transaction Tax", options: op7, series: op7.series },
            { id: 8, name: "Total Value", options: op8, series: op8.series },
            { id: 9, name: "Item Quantity", options: op9, series: op9.series },
            { id: 10, name: "Average Price", options: op10, series: op10.series },
            { id: 11, name: "Unique Purchases", options: op11, series: op11.series },
            { id: 12, name: "Product Revenue", options: op12, series: op12.series },
            { id: 13, name: "Average Quantity", options: op13, series: op13.series },
        ]);
        return () => (isSubscribed = false);
    }, [transactions, ecommerceConversionRate, revenue, avgOrderValue, perSessionValue, shipping, tax, totalValue, itemQuantity, revenuePerItem, uniquePurchases, itemRevenue, itemsPerPurchase]);
    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>Google Analytics | Live-Metrics - E-com SaaS</title>
                </MetaTags>
                <div className="container-fluid">
                    <Breadcrumbs title="Google Analytics" breadcrumbItem="Ecommerce Affiliation" pageHeading="Ecommerce Affiliations" />
                    <Row>
                        {
                            myCards.map(item => (
                                <GAEcommerceAffiliationCard loading={loadingState} item={item} />
                            ))}
                    </Row>
                </div>
            </div>
        </React.Fragment>
    )
}
export default GAEcommerceAffiliation;