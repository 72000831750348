import React, { useState, useEffect, useRef } from 'react';
import { Row, Col, FormGroup, InputGroup, Container, Label, CardBody, Card, Spinner } from 'reactstrap';
import SimpleBar from "simplebar-react"
import { Link } from 'react-router-dom';
import { SearchOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import { Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import { useDispatch, useSelector } from 'react-redux';
import {
  getMetricsByCampaignsPerDate
} from '../../store/googleAds/actions';
import { DateRange } from 'react-date-range';
import MetaTags from 'react-meta-tags';
import secureSection from '../../MangeSecurity/MemberSecurity';
import Breadcrumbs from '../../components/Common/Breadcrumb';
import { withRouter } from "react-router-dom"
import { withTranslation } from "react-i18next"
import PropTypes from "prop-types";
import { Button, Space, Table, Menu, Dropdown, Input, Checkbox } from 'antd';
import {
  getOverviewDataRequest,
} from '../../store/reports/overview/action';

const datePattern = /(\d{1,2})\/(\d{1,2})\/(\d{4})/;
function Campaignstable(props) {
  secureSection();
  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = React.useState('1');
  const { metrcisbyCompaign } = useSelector(
    (state) => state.GoogleAds
  );
  const daysBetween = function (date1, date2) {
    var one_day = 1000 * 60 * 60 * 24;
    var date1_ms = date1.getTime();
    var date2_ms = date2.getTime();
    var difference_ms = date2_ms - date1_ms;
    return Math.round(difference_ms / one_day);
  };
  function useOutsideAlerter(ref, toggleDate) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          toggleDate(false);
        }
      }
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [ref]);
  }
  const clearFilters = () => {
    setFilteredInfo({});
  };
  const [paginationOptions, setPaginationOptions] = useState({
    pageSize: 5, // Default page size
    pageSizeOptions: ['5', '10', '20'], // Available page size options
  });
 

  const [filteredInfo, setFilteredInfo] = useState({});
  const [sortedInfo, setSortedInfo] = useState({});
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  //datepicker
  const [isPeriodChecked, setPeriodChecked] = useState(false);
  const [isYearChecked, setYearChecked] = useState(false);
  const wrapperRef = useRef(null);
  const [toggleDate, setToggleDate] = useState(false);
  useOutsideAlerter(wrapperRef, setToggleDate);
  const [currentFocus, setCurrentFocus] = useState([]);
  const [dateRage, setDateRange] = useState({
    date: [
      window.localStorage.getItem('start')
        ? new Date(window.localStorage.getItem('start'))
        : new Date(
          new Date().toLocaleDateString('en-US', { timeZone: props.timeZone })
        ),
      window.localStorage.getItem('end')
        ? new Date(window.localStorage.getItem('end'))
        : new Date(
          new Date().toLocaleDateString('en-US', { timeZone: props.timeZone })
        ),
    ],
  });
  const [lastPeriod, setLastPeriod] = useState('');
  const [chosedDate, setChosedDate] = useState([
    {
      startDate: window.localStorage.getItem('start')
        ? new Date(window.localStorage.getItem('start'))
        : new Date(
          new Date().toLocaleDateString('en-US', { timeZone: props.timeZone })
        ),
      endDate: window.localStorage.getItem('end')
        ? new Date(window.localStorage.getItem('end'))
        : new Date(
          new Date().toLocaleDateString('en-US', { timeZone: props.timeZone })
        ),
      key: 'selection',
    },
  ]);
  const [activeBtn, setActiveBtn] = useState(
    window.localStorage.getItem('activeBtn')
      ? JSON.parse(window.localStorage.getItem('activeBtn'))
      : {
        today: 1,
        yesterday: 0,
        last7days: 0,
        last30day: 0,
        last60day: 0,
        month: 0,
        other: 0,
      }
  );
  const [comparedTo, setComparedTo] = useState('');
  const mnths = [
    { num: 0, add: 2 },
    { num: 1, add: 0 },
    { num: 2, add: 2 },
    { num: 3, add: 1 },
    { num: 4, add: 2 },
    { num: 5, add: 1 },
    { num: 6, add: 2 },
    { num: 7, add: 2 },
    { num: 8, add: 1 },
    { num: 9, add: 2 },
    { num: 10, add: 1 },
    { num: 11, add: 2 },
  ];
  const getToAddOnMonths = (int) => {
    let res = 0;
    let month = 0;
    if (int === 0) {
      month = int;
    } else {
      month = int - 1;
    }
    mnths.map((x) => {
      if (x.num === month) res = x.add;
    });
    return res;
  };
  useEffect(() => {
    if (chosedDate[0] && chosedDate[0].startDate && chosedDate[0].endDate) {
      const startDate = chosedDate[0].startDate;
      const endDate = chosedDate[0].endDate;
      const newStartDate = new Date(startDate).setDate(
        new Date(startDate).getDate() - daysBetween(startDate, endDate) - 1
      );
      const newEndDate = new Date(endDate).setDate(
        new Date(endDate).getDate() - daysBetween(startDate, endDate) - 1
      );
      comparedTo !== 'year'
        ? setLastPeriod(
          `${'Comparedto'} : ${new Date(newStartDate).toLocaleString('en', {
            day: '2-digit',
            month: 'short',
          })}-${new Date(newEndDate).toLocaleString('en', {
            day: '2-digit',
            month: 'short',
          })} ${new Date(newEndDate).getFullYear()}`
        )
        : setLastPeriod(
          `${'Comparedto'}: ${new Date(startDate).toLocaleString('en', {
            day: '2-digit',
            month: 'short',
          })}-${new Date(endDate).toLocaleString('en', {
            day: '2-digit',
            month: 'short',
          })} ${new Date(endDate).getFullYear() - 1}`
        );
    }
  }, [chosedDate, comparedTo]);
  const buttonHandler = (startDate, endDate) => {
    setDateRange({ date: [startDate, endDate] });
    setChosedDate([
      {
        startDate: startDate,
        endDate: endDate,
        key: 'selection',
      },
    ]);
    setToggleDate(false);
    window.localStorage.setItem('start', startDate);
    window.localStorage.setItem('end', endDate);
  };
  const handleCompareToggle = (compare_to) => {
    setComparedTo(compare_to);
    if (comparedTo === compare_to) {
      setComparedTo('');
    }
  };
  
  useEffect(() => {
    if (currentFocus[1] === 0) {
      setDateRange({
        date: [
          new Date(
            new Date(
              chosedDate[0].startDate.toLocaleDateString('en-US', {
                timeZone: props.timeZone ? props.timeZone : 'UTC',
              })
            )
          ),
          new Date(
            new Date(
              chosedDate[0].endDate.toLocaleDateString('en-US', {
                timeZone: props.timeZone ? props.timeZone : 'UTC',
              })
            )
          ),
        ],
      });
      setToggleDate(false);
    }
  }, [currentFocus]);
  useEffect(() => {
    window.localStorage.setItem('start', chosedDate[0].startDate);
    window.localStorage.setItem('end', chosedDate[0].endDate);
  }, [chosedDate]);
  //date end
  useEffect(() => {
    var newStartDate = datePattern.exec(chosedDate[0].startDate && chosedDate[0].startDate
      .toLocaleString("fr-FR"));
    var newEndDate = datePattern.exec(chosedDate[0].endDate && chosedDate[0].endDate
      .toLocaleString("fr-FR"));
    if (newStartDate && newEndDate) {
      dispatch(getMetricsByCampaignsPerDate(newStartDate[3] + "-" + newStartDate[2] + "-" + newStartDate[1], newEndDate[3] + "-" + newEndDate[2] + "-" + newEndDate[1]));
    }
  }, [dateRage,chosedDate]);
  const searchInput = useRef(null);
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: 'block',
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1677ff' : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: '#ffc069',
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  const handleChange = (pagination, filters, sorter) => {
    console.log('Various parameters', pagination, filters, sorter);
    setFilteredInfo(filters);
    setSortedInfo(sorter);
  };
  const clearSorters = () => {
    setSortedInfo({});
  };
  const clearAll = () => {
    setFilteredInfo({});
    setSortedInfo({});
    // make the columns  selected
    setSelectedColumns(columns.map((column) => column.key));
  };

  const setAgeSort = () => {
    setSortedInfo({
      order: 'descend',
      columnKey: 'totalAverageCost',
    });
  };
  const formatNumberWithSpaces = (value) => {
    if (value !== undefined && value !== null) {
      return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    }
    return ""; // or any default value you want to return for undefined/null cases
  };
  const pageSizeOptions = ['10', '20', '30'];
  const columns = [
    {
      title: '#',
      dataIndex: 'id',
      key: 'id',
      render: (text, record, index) => <strong> {index + 1}</strong>,
      width: 50,
      align: 'center',
      filters: [
        {
          text: 'ENABLED',
          value: 2,
        },
        {
          text: 'PAUSED',
          value: 3,
        },

      ],
      filteredValue: filteredInfo.id || null,
      onFilter: (value, record) => {
        if (value === 2) {
          return record.status === 2;
        } else if (value === 3) {
          return record.status === 3;
        }
        return true; // Return true if no filter is applied
      },
    },
    {
      title: 'Campaign Name',
      dataIndex: 'campaign_name',
      key: 'campaign_name',
      align: 'center',
      width: 300,
      filteredValue: filteredInfo.campaign_name || null,
      onFilter: (value, record) => record.campaign_name.includes(value),
      ...getColumnSearchProps('campaign_name'),
      //  sortOrder: sortedInfo.columnKey === 'name' ? sortedInfo.order : null,
      ellipsis: true,
    },
    {
      title: 'Spend',
      dataIndex: 'metrics.totalCost',
      align: 'center',
      key: 'spend',
      sorter: (a, b) => a.metrics.totalCost - b.metrics.totalCost,
      sortOrder: sortedInfo && sortedInfo.columnKey === 'spend' ? sortedInfo.order : null,
      ellipsis: true,
      render: (text, record) => <strong>{record.metrics.totalCost.toFixed(2)}$</strong>,
    },
    {
      title: 'Revenue',
      dataIndex: 'totalConversionsValue',
      key: 'totalConversionsValue',
      sorter: (a, b) => a.metrics.totalConversionsValue - b.metrics.totalConversionsValue,
      sortOrder: sortedInfo.columnKey === 'totalConversionsValue' ? sortedInfo.order : null,
      ellipsis: true,
      align: 'center',
      render: (text, record) => <strong>{record.metrics.totalConversionsValue.toFixed(2)}$</strong>,
    },
    {
      title: 'Conversions',
      dataIndex: 'totalConversions',
      key: 'totalConversions',
      align: 'center',
      sorter: (a, b) => a.metrics.totalConversions - b.metrics.totalConversions,
      sortOrder: sortedInfo.columnKey === 'totalConversions' ? sortedInfo.order : null,
      ellipsis: true,
      render: (text, record) => <strong>{record.metrics.totalConversions.toFixed(2)}</strong>,

    },
    {
      title: 'Impressions',
      dataIndex: 'metrics.totalImpressions',
      key: 'impressions',
      sorter: (a, b) => a.metrics.totalImpressions - b.metrics.totalImpressions,
      sortOrder:
        sortedInfo.columnKey === 'impressions' ? sortedInfo.order : null,
      ellipsis: true,
      align: 'center',
      render: (text, record) => <strong>{formatNumberWithSpaces(record.metrics.totalImpressions)}</strong>,
    },

    {
      title: ' Interactions',
      dataIndex: 'metrics.totalInteractions',
      key: 'totalInteractions',
      align: 'center',
      sorter: (a, b) => a.metrics.totalInteractions - b.metrics.totalInteractions,
      sortOrder:
        sortedInfo.columnKey === 'totalInteractions' ? sortedInfo.order : null,
      ellipsis: true,
      render: (text, record) => <strong>{formatNumberWithSpaces(record.metrics.totalInteractions)}</strong>,
    },
    {
      title: 'Clicks',
      dataIndex: 'metrics.totalClicks',
      align: 'center',
      key: 'totalClicks',
      sorter: (a, b) => a.metrics.totalClicks - b.metrics.totalClicks,
      sortOrder: sortedInfo.columnKey === 'totalClicks' ? sortedInfo.order : null,
      ellipsis: true,
      render: (text, record) => <strong>{formatNumberWithSpaces(record.metrics.totalClicks)}</strong>,
    },


    // {
    //   title: 'New sessions',
    //   dataIndex: 'totalNewSessions',
    //   align: 'center',
    //   key: 'totalNewSessions',
    //   sorter: (a, b) => a.metrics.totalNewSessions - b.metrics.totalNewSessions,
    //   sortOrder: sortedInfo.columnKey === 'totalNewSessions' ? sortedInfo.order : null,
    //   ellipsis: true,
    //   render: (text,record) => <strong>{record.metrics.totalNewSessions}</strong>,
    // },
    {
      title: 'CTR',
      dataIndex: 'metrics.ctr',
      align: 'center',
      key: 'ctr',
      sorter: (a, b) => a.metrics.ctr - b.metrics.ctr,
      sortOrder: sortedInfo.columnKey === 'ctr' ? sortedInfo.order : null,
      ellipsis: true,
      render: (text, record) => <strong>{record.metrics?.ctr?.toFixed(2)}%</strong>,
    },
    {
      title: 'CPC',
      dataIndex: 'metrics.cpc',
      align: 'center',
      key: 'cpc',
      sorter: (a, b) => a.metrics.cpc - b.metrics.cpc,
      sortOrder: sortedInfo.columnKey === 'cpc' ? sortedInfo.order : null,
      ellipsis: true,
      render: (text, record) => <strong>{record.metrics?.cpc?.toFixed(2)}$</strong>,
    },
    {
      title: 'CPA',
      dataIndex: 'metrics.cpa',
      align: 'center',
      key: 'CPA',
      sorter: (a, b) => a.metrics.cpa - b.metrics.cpa,
      sortOrder: sortedInfo.columnKey === 'CPA' ? sortedInfo.order : null,
      ellipsis: true,
      render: (text, record) => <strong>{record.metrics?.cpa?.toFixed(2)}$</strong>,
    },
    // {
    //   title: 'CR',
    //   dataIndex: 'metrics.conversionRate',
    //   align: 'center',
    //   key: 'conversionRate',
    //   sorter: (a, b) => a.metrics.conversionRate - b.metrics.conversionRate,
    //   sortOrder: sortedInfo.columnKey === 'conversionRate' ? sortedInfo.order : null,
    //   ellipsis: true,
    //   render: (text,record) => <strong>%{record.metrics?.conversionRate?.toFixed(2)}</strong>,
    // },
    // {
    //   title: 'AverageCost',
    //   dataIndex: 'metrics.totalAverageCost',
    //   align: 'center',
    //   key: 'totalAverageCost',
    //   sorter: (a, b) => a.metrics.totalAverageCost - b.metrics.totalAverageCost,
    //   sortOrder: sortedInfo.columnKey === 'totalAverageCost' ? sortedInfo.order : null,
    //   ellipsis: true,
    //   render: (text,record) => <strong>{record.metrics?.totalAverageCost?.toFixed(2)}</strong>,
    // },
    // {
    //   title: 'AverageCpc',
    //   dataIndex: 'metrics.totalAverageCpc',
    //   align: 'center',
    //   key: 'totalAverageCpc',
    //   sorter: (a, b) => a.metrics.totalAverageCpc - b.metrics.totalAverageCpc,
    //   sortOrder: sortedInfo.columnKey === 'totalAverageCpc' ? sortedInfo.order : null,
    //   ellipsis: true,
    //   render: (text,record) => <strong>{record.metrics?.totalAverageCpc?.toFixed(2)}</strong>,
    // },
    // {
    //   title: 'AOV',
    //   dataIndex: 'metrics.AOV',
    //   align: 'center',
    //   key: 'AOV',
    //   sorter: (a, b) => a.metrics.AOV - b.metrics.AOV,
    //   sortOrder: sortedInfo.columnKey === 'AOV' ? sortedInfo.order : null,
    //   ellipsis: true,
    //   render: (text,record) => <strong>{record.metrics?.AOV?.toFixed(2)}$</strong>,
    // }, 
    // {
    //   title: 'SearchImpressionShare',
    //   dataIndex: 'metrics.totalSearchImpressionShare',
    //   align: 'center',
    //   key: 'totalSearchImpressionShare',
    //   sorter: (a, b) => a.metrics.totalSearchImpressionShare - b.metrics.totalSearchImpressionShare,
    //   sortOrder: sortedInfo.columnKey === 'totalSearchImpressionShare' ? sortedInfo.order : null,
    //   ellipsis: true,
    //   render: (text,record) => <strong>{record.metrics?.totalSearchImpressionShare?.toFixed(2)}</strong>,
    // },
    // {
    //   title: 'ROAs',
    //   dataIndex: 'metrics.ROAs',
    //   align: 'center',
    //   key: 'ROAs',
    //   fixed: 'right',
    //   width: 100,
    //   sorter: (a, b) => a.metrics.ROAs - b.metrics.ROAs,
    //   sortOrder: sortedInfo.columnKey === 'ROAs' ? sortedInfo.order : null,
    //   ellipsis: true,
    //   render: (text,record) => <strong>{record.metrics?.ROAs?.toFixed(2)}</strong>,
    // },
  ];
  const [selectedColumns, setSelectedColumns] = useState(
    columns.map((column) => column.key)
  );

  const handleMenuClick = (clickedKey) => {
    const index = selectedColumns.indexOf(clickedKey);

    let updatedSelectedColumns = [];

    if (index === -1) {
      // Column was not selected before, add it to the selected columns
      updatedSelectedColumns = [...selectedColumns, clickedKey];
    } else {
      // Column was selected before, remove it from the selected columns
      updatedSelectedColumns = selectedColumns.filter(
        (key) => key !== clickedKey
      );
    }

    setSelectedColumns(updatedSelectedColumns);
  };

  const filteredColumns = columns.filter((column) =>
    selectedColumns.includes(column.key)
  );

  const menu = (
    <Menu>
      {columns.map((column) => (
        <Menu.Item
          key={column.key}
          onClick={() => handleMenuClick(column.key)}
        >
          <Checkbox
            checked={selectedColumns.includes(column.key)}
            style={{ marginRight: '8px' }}
          />
          <span
            style={
              selectedColumns.includes(column.key)
                ? { fontWeight: 'bold' }
                : {}
            }
          >
            {column.title}
          </span>
        </Menu.Item>
      ))}
    </Menu>
  );


  // console.log("metrcisbyCompaign", metrcisbyCompaign);
  return (
    <div className="page-content">
      <MetaTags>
      <title>{props.t("Campaigns Data")} | Convergen-Metrics - E-com SaaS</title>
      </MetaTags>
      <div className="container-fluid">
      <Breadcrumbs
            title={props.t("Google Ads")}
            breadcrumbItem={props.t("Campaigns Data")}
            pageHeading={props.t("Campaigns Data")}
          />
        <Card>
          <CardBody className="">
            <div>
              <div className="invoice-title">
                <div className="d-flex align-items-start">
                  <div className="flex-grow-1">
                    {/* <div className="col">
                      <h5 className="card-title d-flex align-items-center">
                        <img className="img-intgration-icon" src={GoogleAdsIcon} alt="Google Ads Icon" style={{ marginTop: '-10px', height: '40px' }} />
                        <h5 className="card-title ms-2" style={{ marginTop: '-5px' }}>Campaigns</h5>
                      </h5>
                    </div> */}
                  </div>
                  <div className="d-flex align-items-center justify-content-between">
                    <div className="flex-grow-1">
                      {/* <div className="">
                        <address>
                          <br />
                          <span>
                            {props.t(
                              'Industrial Zone Khéreddine Zone North West,'
                            )}
                          </span>
                          <br />
                          <span>Tunis, Tunisia</span>
                        </address>
                      </div>*/}
                    </div>
                    <div className="flex-shrink-0">
                      <FormGroup className="mt-1">
                        <InputGroup>
                          <div>
                            <div className="divDateContainer">
                              <div ref={wrapperRef}>
                                <button
                                  type="button"
                                  className="btn btn-light"
                                  onClick={() => setToggleDate(!toggleDate)}
                                  id="page-header-search-dropdown"
                                >
                                  {chosedDate[0] !== null
                                    ? [
                                      chosedDate[0].startDate.toLocaleString(
                                        'en',
                                        {
                                          month: 'short',
                                        }
                                      ),
                                      ' ',
                                      chosedDate[0].startDate.getDate(),
                                      ' ',
                                      chosedDate[0].startDate.getFullYear(),
                                      '  -  ',
                                      chosedDate[0].endDate &&
                                      chosedDate[0].endDate.toLocaleString(
                                        'en',
                                        {
                                          month: 'short',
                                        }
                                      ),
                                      ' ',
                                      chosedDate[0].endDate &&
                                      chosedDate[0].endDate.getDate(),
                                      ' ',
                                      chosedDate[0].endDate &&
                                      chosedDate[0].endDate.getFullYear(),
                                    ]
                                    : null}
                                </button>

                                {toggleDate && (
                                  <div 
                                    style={{
                                      position: 'fixed',
                                      display: 'flex',
                                      right: '35px',
                                      zIndex: 9999,
                                    }}
                                  >
                                    <div
                                      className="calenderDivStyle"
                                      style={{ right: 0 }}
                                    >
                                      <div className="btnsCalendar">
                                        <DateRange
                                          editableDateInputs={true}
                                          moveRangeOnFirstSelection={false}
                                          onRangeFocusChange={(item) => {
                                            setCurrentFocus(item);
                                          }}
                                          onChange={(item) => {
                                            setChosedDate([item.selection]);
                                            setActiveBtn({
                                              today: 0,
                                              yesterday: 0,
                                              last7days: 0,
                                              last30day: 0,
                                              last60day: 0,
                                              month: 0,
                                              other: 1,
                                            });
                                            window.localStorage.setItem(
                                              'activeBtn',
                                              JSON.stringify({
                                                today: 0,
                                                yesterday: 0,
                                                last7days: 0,
                                                last30day: 0,
                                                last60day: 0,
                                                month: 0,
                                                other: 1,
                                              })
                                            );
                                          }}
                                          maxDate={new Date(new Date().getTime() - 24 * 60 * 60 * 1000)}
                                          disabledDates={[
                                            {
                                              after: new Date(), // Disable dates after today
                                            },
                                          ]}
                                          ranges={chosedDate}
                                          dragSelectionEnabled={true}
                                          rangeColors={['#6689F2']}
                                          months={2}
                                          direction="horizontal"
                                        />
                                      </div>
                                      <div className="calendarDiv">
                                        <div className="btnCalendarContainer">
                                          <button
                                            className={
                                              activeBtn.today
                                                ? 'dateBtns activeBtnDate'
                                                : 'dateBtns'
                                            }
                                            onClick={() => {
                                              buttonHandler(
                                                new Date(
                                                  new Date().toLocaleDateString(
                                                    'en-US',
                                                    {
                                                      timeZone:
                                                        window.localStorage.getItem(
                                                          'timezone'
                                                        ),
                                                    }
                                                  )
                                                ),
                                                new Date(
                                                  new Date().toLocaleDateString(
                                                    'en-US',
                                                    {
                                                      timeZone:
                                                        window.localStorage.getItem(
                                                          'timezone'
                                                        ),
                                                    }
                                                  )
                                                )
                                              );
                                              setActiveBtn({
                                                today: 1,
                                                yesterday: 0,
                                                last7days: 0,
                                                last30day: 0,
                                                last60day: 0,
                                                month: 0,
                                                other: 0,
                                              });
                                              window.localStorage.setItem(
                                                'activeBtn',
                                                JSON.stringify({
                                                  today: 1,
                                                  yesterday: 0,
                                                  last7days: 0,
                                                  last30day: 0,
                                                  last60day: 0,
                                                  month: 0,
                                                  other: 0,
                                                })
                                              );
                                            }}
                                          >
                                            {props.t('compareto')}
                                          </button>
                                          <button
                                            className={
                                              activeBtn.yesterday
                                                ? 'dateBtns activeBtnDate'
                                                : 'dateBtns'
                                            }
                                            onClick={() => {
                                              const timeZone =
                                                window.localStorage.getItem(
                                                  'timezone'
                                                ) || 'UTC'; // set default time zone to UTC
                                              buttonHandler(
                                                new Date(
                                                  new Date(
                                                    new Date().getTime() -
                                                    24 * 60 * 60 * 1000
                                                  ).toLocaleDateString(
                                                    'en-US',
                                                    {
                                                      timeZone: timeZone,
                                                    }
                                                  )
                                                ),

                                                new Date(
                                                  new Date(
                                                    new Date().getTime() -
                                                    24 * 60 * 60 * 1000
                                                  ).toLocaleDateString(
                                                    'en-US',
                                                    {
                                                      timeZone: timeZone,
                                                    }
                                                  )
                                                )
                                              );
                                              setActiveBtn({
                                                today: 0,
                                                yesterday: 1,
                                                last7days: 0,
                                                last30day: 0,
                                                last60day: 0,
                                                month: 0,
                                                other: 0,
                                              });
                                              window.localStorage.setItem(
                                                'activeBtn',
                                                JSON.stringify({
                                                  today: 0,
                                                  yesterday: 1,
                                                  last7days: 0,
                                                  last30day: 0,
                                                  last60day: 0,
                                                  month: 0,
                                                  other: 0,
                                                })
                                              );
                                            }}
                                          >
                                            {props.t('yesterday')}
                                          </button>
                                          <button
                                            className={
                                              activeBtn.last7days
                                                ? 'dateBtns activeBtnDate'
                                                : 'dateBtns'
                                            }
                                            onClick={() => {
                                              const timeZone =
                                                window.localStorage.getItem(
                                                  'timezone'
                                                ) || 'UTC'; // set default time zone to UTC
                                              buttonHandler(
                                                new Date(
                                                  new Date(
                                                    new Date().getTime() -
                                                    6 * 24 * 60 * 60 * 1000
                                                  ).toLocaleDateString(
                                                    'en-US',
                                                    {
                                                      timeZone: timeZone,
                                                    }
                                                  )
                                                ),
                                                new Date(
                                                  new Date().toLocaleDateString(
                                                    'en-US',
                                                    { timeZone: timeZone }
                                                  )
                                                )
                                              );
                                              setActiveBtn({
                                                today: 0,
                                                yesterday: 0,
                                                last7days: 1,
                                                last30day: 0,
                                                last60day: 0,
                                                month: 0,
                                                other: 0,
                                              });
                                              window.localStorage.setItem(
                                                'activeBtn',
                                                JSON.stringify({
                                                  today: 0,
                                                  yesterday: 0,
                                                  last7days: 1,
                                                  last30day: 0,
                                                  last60day: 0,
                                                  month: 0,
                                                  other: 0,
                                                })
                                              );
                                            }}
                                          >
                                            {props.t('last7days')}
                                          </button>
                                          <button
                                            className={
                                              activeBtn.last30day
                                                ? 'dateBtns activeBtnDate'
                                                : 'dateBtns'
                                            }
                                            onClick={() => {
                                              const timeZone =
                                                window.localStorage.getItem(
                                                  'timezone'
                                                ) || 'UTC'; // set default time zone to UTC
                                              buttonHandler(
                                                new Date(
                                                  new Date(
                                                    new Date().getTime() -
                                                    29 * 24 * 60 * 60 * 1000
                                                  ).toLocaleDateString(
                                                    'en-US',
                                                    {
                                                      timeZone: timeZone,
                                                    }
                                                  )
                                                ),
                                                new Date(
                                                  new Date().toLocaleDateString(
                                                    'en-US',
                                                    { timeZone: timeZone }
                                                  )
                                                )
                                              );
                                              setActiveBtn({
                                                today: 0,
                                                yesterday: 0,
                                                last7days: 0,
                                                last30day: 1,
                                                last60day: 0,
                                                month: 0,
                                                other: 0,
                                              });
                                              window.localStorage.setItem(
                                                'activeBtn',
                                                JSON.stringify({
                                                  today: 0,
                                                  yesterday: 0,
                                                  last7days: 0,
                                                  last30day: 1,
                                                  last60day: 0,
                                                  month: 0,
                                                  other: 0,
                                                })
                                              );
                                            }}
                                          >
                                            {props.t('last30days')}
                                          </button>
                                          <button
                                            className={
                                              activeBtn.month
                                                ? 'dateBtns activeBtnDate'
                                                : 'dateBtns'
                                            }
                                            onClick={() => {
                                              const timeZone =
                                                window.localStorage.getItem(
                                                  'timezone'
                                                ) || 'UTC'; // set default time zone to UTC
                                              buttonHandler(
                                                new Date(
                                                  new Date(
                                                    new Date().getFullYear(),
                                                    new Date().getMonth(),

                                                    getToAddOnMonths(
                                                      new Date().getMonth()
                                                    )
                                                  ).toLocaleDateString(
                                                    'en-US',
                                                    {
                                                      timeZone: timeZone,
                                                    }
                                                  )
                                                ),

                                                new Date(
                                                  new Date().toLocaleDateString(
                                                    'en-US',
                                                    { timeZone: timeZone }
                                                  )
                                                )
                                              );
                                              setActiveBtn({
                                                today: 0,
                                                yesterday: 0,
                                                last7days: 0,
                                                last30day: 0,
                                                last60day: 0,
                                                month: 1,
                                                other: 0,
                                              });
                                              window.localStorage.setItem(
                                                'activeBtn',
                                                JSON.stringify({
                                                  today: 0,
                                                  yesterday: 0,
                                                  last7days: 0,
                                                  last30day: 0,
                                                  last60day: 0,
                                                  month: 1,
                                                  other: 0,
                                                })
                                              );
                                            }}
                                          >
                                            {props.t('thismonth')}
                                          </button>
                                          <span className="compareSpan">
                                            <b>{props.t('compareto')}</b>
                                          </span>
                                          <div className="form-check mb-3">
                                            <input
                                              style={{
                                                width: '1em',
                                                height: '1em',
                                                marginTop: '0.25em',
                                                verticalAlign: 'top',
                                                backgroundColor: '#fff',
                                                backgroundRepeat: 'no-repeat',
                                                backgroundPosition: 'center',
                                                border: '#000000',
                                              }}
                                              type="checkbox"
                                              id="formCheck1"
                                              className={
                                                comparedTo === 'period'
                                                  ? 'compareTogglebtnStyleActive'
                                                  : null
                                              }
                                              checked={comparedTo === 'period'}
                                              onClick={() => {
                                                handleCompareToggle('period');
                                                setPeriodChecked(
                                                  !isPeriodChecked
                                                );
                                              }}
                                            />
                                            <label
                                              className="form-check-label"
                                              htmlFor="formCheck1"
                                            >
                                              {props.t('previousperiod')}
                                            </label>
                                          </div>
                                          <div className="form-check mb-3">
                                            <input
                                              style={{
                                                width: '1em',
                                                height: '1em',
                                                marginTop: '0.25em',
                                                verticalAlign: 'top',
                                                backgroundColor: '#fff',
                                                backgroundRepeat: 'no-repeat',
                                                backgroundPosition: 'center',
                                                border: '#000000',
                                              }}
                                              type="checkbox"
                                              id="formCheck1"
                                              className={
                                                comparedTo === 'year'
                                                  ? 'compareTogglebtnStyleActive'
                                                  : null
                                              }
                                              checked={comparedTo === 'year'}
                                              onChange={() => {
                                                handleCompareToggle('year');
                                                setYearChecked(!isYearChecked);
                                              }}
                                            />
                                            <label
                                              className="form-check-label"
                                              htmlFor="formCheck1"
                                            >
                                              {props.t('Previousyear')}
                                            </label>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                )}
                                <br />
                                {comparedTo !== "" && (
                            <small style={{
                              display: 'flex',
                              marginTop: '10px',
                              backgroundColor: 'rgb(59 91 152 / 7%)',
                              borderRadius: '5px',
                              width: 'max-content',
                              height: '30px',
                              padding: "8px",
                              fontWeight: 'bold',
                            }}>
                              {lastPeriod}
                            </small>
                          )}
                              </div>
                            </div>
                          </div>
                        </InputGroup>
                      </FormGroup>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </CardBody>
          <Card style={{ border: 'none' }}>
            <CardBody>
              {/* <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
              >
                <h4 className="card-title mb-4">
                  {props.t('Google Ads Campaigns')}
                </h4>
               
              </div> */}
              <Space
                style={{
                  marginBottom: 16,
                }}
              >
                <Button onClick={setAgeSort}>Sort Impressions</Button>
                <Button onClick={clearFilters}>Clear filters</Button>
                <Button onClick={clearAll}>Clear filters and sorters</Button>
                <Dropdown overlay={menu} trigger={['click']}>
                  <Button>Select Columns</Button>
                </Dropdown>
              </Space>
              <Table
                columns={filteredColumns}
                bordered
                dataSource={metrcisbyCompaign}
                onChange={handleChange}
                pagination={{
                  ...paginationOptions,
                  onChange: handleChange,
                  showSizeChanger: true, // Enable the page size selector
                  // showTotal: (total) => `Total ${total} items`, // Optionally show total items
                  // Other pagination settings
                }}
                scroll={{ y: 700 }}
              />
              {/* <div className="mt-2-overview-dashboard">
          <Link to="/GoogleCampaignsDetails" className="btn btn-primary btn-sm">{props.t("View more")} <i className="overview-dashboard-icon mdi mdi-arrow-right ms-1"></i></Link>
        </div> */}
            </CardBody>
          </Card>
        </Card>
      </div>
      <div className="mt-2-overview-dashboard">
        {/* <Link to="/fb-campaigns-details" className="btn btn-primary btn-sm">
          {props.t("View more")} <i className="overview-dashboard-icon mdi mdi-arrow-right ms-1"></i>
        </Link> */}
      </div>
    </div>
  );
}
Campaignstable.propTypes = {
  t: PropTypes.any
};
export default withRouter(withTranslation()(Campaignstable));

//export default Campaignstable