// get member request
export  const  GET_MEMBER_REQUEST  =   'GET_MEMBER_REQUEST' ;
export  const  GET_MEMBER_SUCCESS  =   'GET_MEMBER_SUCCESS' ;
export  const  GET_MEMBER_FAILURE  =   'GET_MEMBER_FAILURE' ;

  

// get member request
    export   const  getMemberRequest  =  ( )   =>   ( {
        type : GET_MEMBER_REQUEST
    } ) ;

    export   const  getMemberSuccess  =  ( members )   =>   ( {  
        type : GET_MEMBER_SUCCESS,
        payload : { members }

    });

    export   const  getMemberFailure  =  ( error )   =>   ( { 
        type : GET_MEMBER_FAILURE,
        payload : { error }
    } ) ;
// rest get member request

export  const  REST_GET_MEMBER_REQUEST  =   'REST_GET_MEMBER_REQUEST' ;
export  const  REST_GET_MEMBER_SUCCESS  =   'REST_GET_MEMBER_SUCCESS' ;
export  const  REST_GET_MEMBER_FAILURE  =   'REST_GET_MEMBER_FAILURE' ;
    
// rest get member request
    export   const  restGetMemberRequest  =  (name )   =>   ( {
        type : REST_GET_MEMBER_REQUEST,
        payload : { name }
    } ) ;

    export   const  restGetMemberSuccess  =  ( members )   =>   ( {
        type : REST_GET_MEMBER_SUCCESS,
        payload : { members }
    } ) ;
    
    export   const  restGetMemberFailure  =  ( error )   =>   ( {
        type : REST_GET_MEMBER_FAILURE,
        payload : { error }
    } ) ;
  //add member request
    export   const  ADD_MEMBER_REQUEST  =   'ADD_MEMBER_REQUEST' ;
    export   const  ADD_MEMBER_SUCCESS  =   'ADD_MEMBER_SUCCESS' ;
    export   const  ADD_MEMBER_FAILURE  =   'ADD_MEMBER_FAILURE' ;
   
    
    //add member request
    export   const  addMemberRequest  =  (email,storeName,sections )   =>   ( {
        type : ADD_MEMBER_REQUEST,
        payload : { email,storeName,sections }

    } ) ;

    export   const  addMemberSuccess  =  ( members )   =>   ( {
        type : ADD_MEMBER_SUCCESS,
        payload : { members }

    });
    export  const  addMemberFailure  =  ( error )   =>   ( {
        type : ADD_MEMBER_FAILURE,
        payload : { error }
    } ) ;

    // EDIT MEMBER REQUEST
    export   const  EDIT_MEMBER_REQUEST  =   ' EDIT_MEMBER_REQUEST' ;
    export   const  EDIT_MEMBER_SUCCESS  =   'EDIT_MEMBER_SUCCESS' ;
    export   const  EDIT_MEMBER_FAILURE  =   'EDIT_MEMBER_FAILURE' ;


    //edit member request
export   const  editMemberRequest  =  (email,sections )   =>   ( {
        type : EDIT_MEMBER_REQUEST,
        payload : { email,sections }
} ) ;

export  const editMemberSuccess  =  ( data )   =>   ( {
        type : EDIT_MEMBER_SUCCESS,
        payload : { data }
} ) ;
export  const editMemberFailure  =  ( error )   =>   ( {
        type : EDIT_MEMBER_FAILURE,
        payload : { error }
} ) ;


//delete member request
export  const  DELETE_MEMBER_REQUEST  =   'DELETE_MEMBER_REQUEST' ;
export  const  DELETE_MEMBER_SUCCESS  =   'DELETE_MEMBER_SUCCESS' ;
export  const  DELETE_MEMBER_FAILURE  =   'DELETE_MEMBER_FAILURE' ;


//delete member request
export   const  deleteMemberRequest  =  (email )   =>   ( {
        type : DELETE_MEMBER_REQUEST,
        payload : { email }

} ) ;

export   const  deleteMemberSuccess  =  ( data )   =>   ( {
        type : DELETE_MEMBER_SUCCESS,
        payload : { data }
} ) ;

export   const  deleteMemberFailure  =  ( error )   =>   ( {
        type : DELETE_MEMBER_FAILURE,
        payload : { error }

} ) ;