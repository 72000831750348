import React, { useState, useEffect, useContext } from 'react';
import MetaTags from 'react-meta-tags';
import {
    Card, CardHeader, Col, Container, Nav, NavItem, NavLink, Row, TabContent, TabPane, Button, ModalBody,
    ModalHeader
} from "reactstrap";
import classnames from "classnames";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { withTranslation } from "react-i18next"
import { withRouter } from "react-router-dom"
import SalesPlatformsCatalog from './SalesPlatformsCatalog';
import AdsPlatformsCatalog from './AdsPlatformsCatalog'
import './styles.css';
import secureSection from '../../MangeSecurity/MemberSecurity';
import TrackingPlatformsCatalog from './TrackingPlatormsCatalog';
import EmailsPlatformsCatalog from './EmailsPlatformsCatalog';
import TrackshipmentsCatalog from './TrackshipmentsCatalog';
import { ShepherdTour, ShepherdTourContext } from 'react-shepherd'
//*styles
import 'shepherd.js/dist/css/shepherd.css'

// import '@styles/react/libs/shepherd-tour/shepherd-tour.scss'

//import '../../../assets/scss/shepherd-tour/shepherd-tour.scss'

//guide tour 
const backBtnClass = 'btn btn-sm btn-outline-primary',
    nextBtnClass = 'btn btn-sm btn-primary btn-next'

let instance = null



const Integration = props => {
    // this tour guide 
    const [activeTab, setActiveTab] = useState(localStorage.getItem('activeTab') || '1');

    const toggleTab = (tab) => {
        if (activeTab !== tab) {
            setActiveTab(tab);
            localStorage.setItem('activeTab', tab);
        }
    };
    const tabComponents = {
        "1": <SalesPlatformsCatalog />,
        "2": <AdsPlatformsCatalog />,
        "3": <EmailsPlatformsCatalog />,
        "4": <TrackingPlatformsCatalog />,
        "5": <TrackshipmentsCatalog />,
    };
    const Content = () => {
        const tour = useContext(ShepherdTourContext)

        instance = tour


        return (
            <Col className='text-center mt-2 pt-50'>
                <Button color='primary' className='me-1' onClick={() => tour.start()} outline>
                    {props.t('Platforms Guide Tour')}
                </Button>
            </Col>
        )
    }

    const steps = [
        {
            id: 'Sales Platforms',
            title: props.t('Sales Platforms'),
            text: props.t('1️⃣first step1️⃣ Explore our Sales Platforms and integrate your platform'),
            attachTo: { element: '.Sales-Guidetour-bar', on: 'bottom' },
            cancelIcon: {
                enabled: true
            },
            buttons: [
                {
                    action: () => instance.cancel(),
                    classes: backBtnClass,
                    text: props.t('Skip')
                },
                {
                    text: props.t('Next'),
                    classes: nextBtnClass,
                    action: () => {
                        instance.next();
                        //toggleTab("2"); // Update the active tab here
                    }
                }
            ]
        },
        {
            id: 'Ads Platforms',
            title: props.t('Ads Platforms'),
            text: props.t('2️⃣second step2️⃣Discover the power of our Ads Platforms'),
            attachTo: { element: '.Adsp-Guidetour-bar', on: 'bottom' },
            cancelIcon: {
                enabled: true
            },
            buttons: [
                {
                    action: () => instance.cancel(),
                    classes: backBtnClass,
                    text: props.t('Skip')
                },
                {
                    text: props.t('Back'),
                    classes: backBtnClass,
                    action: () => instance.back()
                },
                {
                    text: props.t('Next'),
                    classes: nextBtnClass,
                    action: () => {
                        instance.next();
                        //toggleTab("3"); // Update the active tab here
                    }
                }
            ]
        },
        {
            id: 'Emails Platforms',
            title: props.t('Emails Platforms'),
            text: props.t('3️⃣third step3️⃣Unlock the potential of Emails Platforms'),
            attachTo: { element: '.emails-Guidetour-bar', on: 'bottom' },
            cancelIcon: {
                enabled: true
            },
            buttons: [
                {
                    action: () => instance.cancel(),
                    classes: backBtnClass,
                    text: props.t('Skip')
                },
                {
                    text: props.t('Back'),
                    classes: backBtnClass,
                    action: () => instance.back()
                },
                {
                    text: props.t('Next'),
                    classes: nextBtnClass,
                    action: () => {
                        instance.next();
                        //toggleTab("4"); // Update the active tab here
                    }
                }
            ]
        },
        {
            id: 'Tracking Platforms',
            title: props.t('Tracking Platforms'),
            text: props.t('4️⃣fourth step4️⃣Monitor and analyze with Tracking Platforms'),
            attachTo: { element: '.Tracking-Guidetour-bar', on: 'bottom' },
            cancelIcon: {
                enabled: true
            },
            buttons: [
                {
                    action: () => instance.cancel(),
                    classes: backBtnClass,
                    text: props.t('Skip')
                },
                {
                    text: props.t('Back'),
                    classes: backBtnClass,
                    action: () => instance.back()
                },
                {
                    text: props.t('Next'),
                    classes: nextBtnClass,
                    action: () => {
                        instance.next();
                        //toggleTab("5"); // Update the active tab here
                    }
                }
            ]
        },
        {
            id: 'Cash On Delivery',
            title: props.t('Cash On Delivery'),
            text: props.t('5️⃣Last step5️⃣explore Cash On Delivery options'),
            attachTo: { element: '.Cashon-Guidetour-bar', on: 'top' },
            cancelIcon: {
                enabled: true
            },
            buttons: [
                {
                    text: props.t('Back'),
                    classes: backBtnClass,
                    action: () => instance.back()
                },
                {
                    text: props.t('Finish'),
                    classes: nextBtnClass,
                    action: () => instance.cancel()
                }
            ]
        }
    ]
    //  secureSection();
    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>{props.t("Integrations")} |Convergen-Metrics - E-com SaaS</title>
                </MetaTags>
                <Container fluid>
                    <Breadcrumbs title={props.t("Integrations")} breadcrumbItem={props.t("Integrations")} />
                    <Row>
                        <Col lg={12}>
                            <Card>
                                <CardHeader >
                                    <Nav pills className="navtab-bg nav-justified">
                                        <NavItem className='Sales-Guidetour-bar'>
                                            <NavLink
                                                to="#"
                                                className={classnames({
                                                    active: activeTab === "1",
                                                })}

                                                onClick={() => {
                                                    toggleTab("1");
                                                }}
                                            >{props.t("Sales Platforms")}</NavLink>
                                        </NavItem>
                                        <NavItem className='Adsp-Guidetour-bar'>
                                            <NavLink
                                                to="#"
                                                className={classnames({
                                                    active: activeTab === "2",
                                                })}
                                                onClick={() => {
                                                    toggleTab("2");
                                                }}
                                            >{props.t("Ads / Social Platforms")}</NavLink>
                                        </NavItem>
                                        <NavItem className='emails-Guidetour-bar'>
                                            <NavLink
                                                to="#"
                                                className={classnames({
                                                    active: activeTab === "3",
                                                })}
                                                onClick={() => {
                                                    toggleTab("3");
                                                }}
                                            >{props.t("Emails Platforms")}</NavLink>
                                        </NavItem>
                                        <NavItem className='Tracking-Guidetour-bar'>
                                            <NavLink
                                                to="#"
                                                className={classnames({
                                                    active: activeTab === "4",
                                                })}
                                                onClick={() => {
                                                    toggleTab("4");
                                                }}
                                            >{props.t("Tracking Platforms")}</NavLink>
                                        </NavItem>
                                        <NavItem className='Cashon-Guidetour-bar'>
                                            <NavLink
                                                to="#"
                                                className={classnames({
                                                    active: activeTab === "5",
                                                })}
                                                onClick={() => {
                                                    toggleTab("5");
                                                }}
                                            >{props.t("Cash On Delivery")} {<span className="test badge rounded-pill bg-danger float-end  ">New</span>}</NavLink>

                                        </NavItem>
                                    </Nav>
                                </CardHeader>
                                <div className="card-body">
                                    <TabContent activeTab={activeTab}>
                                        {tabComponents[activeTab]}
                                    </TabContent>
                                </div>
                            </Card>
                        </Col>
                    </Row>
                    <Row>
                    </Row>
                </Container>
                <ShepherdTour
                    steps={steps}
                    tourOptions={{
                        useModalOverlay: true
                    }}
                >
                    {/* Content component */}
                    <Content />
                </ShepherdTour>
            </div>
        </React.Fragment>
    );
};
export default withRouter(withTranslation()(Integration));