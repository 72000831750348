import React from "react"
import {
  Row,
  Col,
  Card,
  CardBody,
} from "reactstrap"
import ReactApexChart from "react-apexcharts";
import { default as NumberFormat } from 'react-number-format';
import FeatherIcon from "feather-icons-react";
const WooCommercepIcon = `${process.env.PUBLIC_URL}/images/integrations_images/WooCommerce.svg`;

function WooDataCard({ item, date, loading, getAOV, getLastAOV, provided, snapshot }) {
  const start = new Date(date[0].startDate);
  const end = new Date(date[0].endDate);
  const today = new Date(new Date().setHours(0, 0, 0, 0));
  const diff_today_start = Math.abs(today - start) / 1000;
  const diff_today_end = Math.abs(today - end) / 1000;
  const diff = Math.abs(end - start) / 1000;

  let getTotal = (array) => {
    return array.map(Number).reduce((x, y) => { return x + y }, 0);
  }

  let getTotalFixed = (array) => {
    return array.map(Number).reduce((x, y) => { return x + y }, 0).toFixed(2);
  }
  const formatDate = (date) => {
    return new Date(date).toLocaleString("en", { month: "short", day: "2-digit" });
  }

  const formatChart = (dates, lastDates, data) => {
    const chart = [];
    for (let i = 0; i < dates.length; i++) {
      chart.push({
        x: `${formatDate(dates[i])}--compared to -->${formatDate(lastDates[i])}`,
        y: parseFloat(data[i]).toFixed(2)
      });
    }
    return chart;
  };

  const generateSalesChart = () => {
    let processedChart = [];
    let lastProcessedChart = [];

    if (diff === 0) {
      processedChart = formatChart(item.dates, item.lastDates, item.series[0].data);
      for (let i = 0; i < ((diff_today_start === 0 && diff_today_end === 0) ? item.series[0].data.length : 24); i++) {
        lastProcessedChart.push({
          x: `${formatDate(item.dates[i])}&nbsp;<span style="font-weight:bold">compared to<span>&nbsp;${formatDate(item.lastDates[i])}`,
          y: parseFloat(item.series[1].data[0]).toFixed(2)
        });
      }
    } else {
      processedChart = formatChart(item.dates, item.lastDates, item.series[0].data);
      lastProcessedChart = formatChart(item.dates, item.lastDates, item.series[1].data);
    }

    return [processedChart, lastProcessedChart];
  };

  let generateGrossProfitChart = () => {
    let processedChart = [];
    let lastProcessedChart = [];

    if (diff === 0) {
      processedChart = formatChart(item.dates, item.lastDates, item.series[0].data);
      for (let i = 0; i < ((diff_today_start === 0 && diff_today_end === 0) ? item.series[0].data.length : 24); i++) {
        lastProcessedChart.push({
          x: `${formatDate(item.dates[i])}&nbsp;<span style="font-weight:bold">compared to<span>&nbsp;${formatDate(item.lastDates[i])}`,
          y: parseFloat(item.series[1].data[0]).toFixed(2)
        });
      }
    } else {
      processedChart = formatChart(item.dates, item.lastDates, item.series[0].data);
      lastProcessedChart = formatChart(item.dates, item.lastDates, item.series[1].data);
    }
    return [processedChart, lastProcessedChart];
  }
  let generateAOVChart = () => {
    let processedChart = [];
    let lastProcessedChart = [];

    if (diff === 0) {
      processedChart = item.series[0].data[1].map((aovValue, idx) => ({
        x: `${new Date(item.dates[idx]).toLocaleString("en", { month: "short", day: "2-digit", hour: "2-digit", minute: "2-digit" })}&nbsp;<span style="font-weight:bold">compared to<span>&nbsp;${new Date(item.lastDates[idx]).toLocaleString("en", { month: "short", day: "2-digit", hour: "2-digit", minute: "2-digit" })}`,
        y: getAOV(aovValue, item.series[0].data[0][0], diff).toFixed(2),
      }));

      if (item.series[1].data[0].length > 0 && item.series[1].data[1].length > 0) {
        lastProcessedChart = item.series[1].data[1].slice(0, 24).map((aovValue, idx) => ({
          x: `${new Date(item.dates[idx]).toLocaleString("en", { month: "short", day: "2-digit", hour: "2-digit", minute: "2-digit" })}&nbsp;<span style="font-weight:bold">compared to<span>&nbsp;${new Date(item.lastDates[idx]).toLocaleString("en", { month: "short", day: "2-digit", hour: "2-digit", minute: "2-digit" })}`,
          y: getAOV(aovValue, item.series[1].data[0][0], diff).toFixed(2),
        }));
      }
    } else {
      processedChart = item.series[0].data[1].map((aovValue, idx) => ({
        x: `${new Date(item.dates[idx]).toLocaleString("en", { month: "short", day: "2-digit" })}&nbsp;<span style="font-weight:bold">compared to<span>&nbsp;${new Date(item.lastDates[idx]).toLocaleString("en", { month: "short", day: "2-digit" })}`,
        y: getAOV(aovValue, item.series[0].data[0][idx], diff).toFixed(2),
      }));

      lastProcessedChart = item.series[1].data[1].map((aovValue, idx) => ({
        x: `${new Date(item.dates[idx]).toLocaleString("en", { month: "short", day: "2-digit" })}&nbsp;<span style="font-weight:bold">compared to<span>&nbsp;${new Date(item.lastDates[idx]).toLocaleString("en", { month: "short", day: "2-digit" })}`,
        y: getAOV(aovValue, item.series[1].data[0][idx], diff).toFixed(2),
      }));
    }

    return [processedChart, lastProcessedChart];
  };
  let generateNumberOfOrdersChart = () => {
    let processedChart = [];
    let lastProcessedChart = [];

    if (diff === 0) {
      processedChart = formatChart(item.dates, item.lastDates, item.series[0].data);
      for (let i = 0; i < ((diff_today_start === 0 && diff_today_end === 0) ? item.series[0].data.length : 24); i++) {
        lastProcessedChart.push({
          x: `${formatDate(item.dates[i])}&nbsp;<span style="font-weight:bold">compared to<span>&nbsp;${formatDate(item.lastDates[i])}`,
          y: parseFloat(item.series[1].data[0]).toFixed(2)
        });
      }
    } else {
      processedChart = formatChart(item.dates, item.lastDates, item.series[0].data);
      lastProcessedChart = formatChart(item.dates, item.lastDates, item.series[1].data);
    }

    return [processedChart, lastProcessedChart];
  }
  return (
    <div className="dashboardMinorCardItem"
      ref={provided.innerRef}
      snapshot={snapshot}
      {...provided.draggableProps}
      {...provided.dragHandleProps}>
      {
        (item.name === 'sales' || item.name === 'sales') &&
        <Card className="card-h-100">
          <CardBody>
            <div className="my-0 text-primary d-flex justify-content-between align-items-center">
              <span className="my-0 text-primary">
                {item.name}
              </span>
              <img className="img-intgration-icon" src={WooCommercepIcon} />
            </div>
            <Row className="align-items-center">
              <Col xs={6}>
                <div className="mb-3">
                  {
                    loading ?
                      <NumberFormat value={item.series[0].data.length > 0 && getTotalFixed(item.series[0].data)} decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={'TND'} renderText={value => <h4>{value}</h4>} />
                      : ""
                  }
                </div>
              </Col>
              <Col xs={6}>
                <ReactApexChart
                  options={item.options}
                  series={[{ name: item.name, data: generateSalesChart()[0] }, { name: "Compared To", data: generateSalesChart()[1] }]}
                  type="bar"
                  width="100%"
                  height={60}
                />
              </Col>
              <div className="text-nowrap h4">
                {
                  loading ?
                    item.series[1].data.length > 0 ?
                      getTotal(item.series[0].data) > getTotal(item.series[1].data) ?
                        <span className="badge badge-soft-success text-success">{item.series[0].data.length > 0 && ((getTotal(item.series[0].data) - getTotal(item.series[1].data)) / Math.abs(getTotal(item.series[0].data)) * 100).toFixed(2)}%</span>
                        :
                        <span className="badge badge-soft-danger text-danger ">{item.series[0].data.length > 0 && ((getTotal(item.series[0].data) - getTotal(item.series[1].data)) / Math.abs(getTotal(item.series[0].data)) * 100).toFixed(2)}%</span>
                      : "-"
                    : ""
                }
              </div>
              <div className=" justify-content-between align-items-center">
                <FeatherIcon
                  icon="grid"
                />
              </div>
            </Row>
          </CardBody>
        </Card>
      }
      {
        (item.name === 'GrossProfit' || item.name === 'GrossProfit') &&
        <Card className="card-h-100">
          <CardBody>
            <div className="my-0 text-primary d-flex justify-content-between align-items-center">
              <span className="my-0 text-primary">
                {item.name}
              </span>
              <img className="img-intgration-icon" src={WooCommercepIcon} />
            </div>
            <Row className="align-items-center">
              <Col xs={6}>
                <div className="mb-3">
                  {
                    loading ?
                      <NumberFormat value={item.series[0].data.length > 0 && getTotalFixed(item.series[0].data)} decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={'TND'} renderText={value => <h4>{value}</h4>} />
                      : ""
                  }
                </div>
              </Col>
              <Col xs={6}>
                <ReactApexChart
                  options={item.options}
                  series={[{ name: item.name, data: generateGrossProfitChart()[0] }, { name: "Compared To", data: generateGrossProfitChart()[1] }]}
                  type="bar"
                  width="100%"
                  height={60}
                />
              </Col>
              <div className="text-nowrap h4">
                {
                  loading ?
                    item.series[1].data.length > 0 ?
                      getTotal(item.series[0].data) > getTotal(item.series[1].data) ?
                        <span className="badge badge-soft-success text-success">{item.series[0].data.length > 0 && ((getTotal(item.series[0].data) - getTotal(item.series[1].data)) / Math.abs(getTotal(item.series[0].data)) * 100).toFixed(2)}%</span>
                        :
                        <span className="badge badge-soft-danger text-danger ">{item.series[0].data.length > 0 && ((getTotal(item.series[0].data) - getTotal(item.series[1].data)) / Math.abs(getTotal(item.series[0].data)) * 100).toFixed(2)}%</span>
                      : "-"
                    : ""
                }
              </div>
              <div className=" justify-content-between align-items-center">
                <FeatherIcon
                  icon="grid"
                />
              </div>
            </Row>
          </CardBody>
        </Card>
      }
      {
        (item.name === 'AOV' || item.name === 'AOV') &&
        <Card className="card-h-100">
          <CardBody>
            <div className="my-0 text-primary d-flex justify-content-between align-items-center">
              <span className="my-0 text-primary">
                <i class="fa fa-info-circle text-primary" aria-hidden="true" data-tooltip="Average Order Value"></i>{" "}
                {item.name}
              </span>
              <img className="img-intgration-icon" src={WooCommercepIcon} />
            </div>
            <Row className="align-items-center">
              <Col xs={6}>
                <div className="mb-3">
                  {
                    loading ?
                      (isFinite(getAOV(item.series[0].data[1], item.series[0].data[0])) ? <NumberFormat value={getAOV(item.series[0].data[1], item.series[0].data[0])} decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={'TND'} renderText={value => <h4>{value}</h4>} /> : "No AOV")
                      : ""
                  }
                </div>
              </Col>
              <Col xs={6}>
                <ReactApexChart
                  options={item.options}
                  series={[
                    { name: item.name, data: generateAOVChart()[0] },
                    { name: "Compared To", data: generateAOVChart()[1] }
                  ]}
                  type="bar"
                  width="100%"
                  height={60}
                />
              </Col>
              <div className="text-nowrap h4">
                {
                  loading ?
                    item.series[1].data[0].length > 0 ?
                      getLastAOV(item.series[1].data[1], item.series[1].data[0], diff) > getAOV(item.series[0].data[1], item.series[0].data[0], diff) ?
                        <span className="badge badge-soft-danger text-danger ">{(((getAOV(item.series[0].data[1], item.series[0].data[0], diff) - getLastAOV(item.series[1].data[1], item.series[1].data[0], diff)) / Math.abs(getAOV(item.series[0].data[1], item.series[0].data[0], diff))) * 100).toFixed(1)}%</span>
                        :
                        <span className="badge badge-soft-success text-success">{(((getAOV(item.series[0].data[1], item.series[0].data[0], diff) - getLastAOV(item.series[1].data[1], item.series[1].data[0], diff)) / Math.abs(getAOV(item.series[0].data[1], item.series[0].data[0], diff))) * 100).toFixed(1)}%</span>
                      : "-"
                    : ''
                }
              </div>
              <div className=" justify-content-between align-items-center">
                <FeatherIcon
                  icon="grid"
                />
              </div>
            </Row>
          </CardBody>
        </Card>
      }
      {
        (item.name === 'NumberofOrders' || item.name === 'NumberofOrders') &&
        <Card className="card-h-100">
          <CardBody>
            <div className="my-0 text-primary d-flex justify-content-between align-items-center">
              <span className="my-0 text-primary">
                {item.name}
              </span>
              <img className="img-intgration-icon" src={WooCommercepIcon} />
            </div>
            <Row className="align-items-center">
              <Col xs={6}>
                <div className="mb-3">
                  {
                    loading ?
                      <h4>{item.series[0].data.length > 0 && getTotal(item.series[0].data)}</h4>
                      : ""
                  }
                </div>
              </Col>
              <Col xs={6}>
              <ReactApexChart
                  options={item.options}
                  series={[{ name: item.name, data: generateNumberOfOrdersChart()[0] }, { name: "Compared To", data: generateNumberOfOrdersChart()[1] }]}
                  type="bar"
                  width="100%"
                  height={60}
                />
              </Col>
              <div className="text-nowrap h4">
                {
                  loading ?
                    item.series[1].data.length > 0 ?
                      getTotal(item.series[0].data) > getTotal(item.series[1].data) ?
                        <span className="badge badge-soft-success text-success">{item.series[0].data.length > 0 && ((getTotal(item.series[0].data) - getTotal(item.series[1].data)) / Math.abs(getTotal(item.series[0].data)) * 100).toFixed(2)}%</span>
                        :
                        <span className="badge badge-soft-danger text-danger ">{item.series[0].data.length > 0 && ((getTotal(item.series[0].data) - getTotal(item.series[1].data)) / Math.abs(getTotal(item.series[0].data)) * 100).toFixed(2)}%</span>
                      : "-"
                    : ""
                }
              </div>
              <div className=" justify-content-between align-items-center">
                <FeatherIcon
                  icon="grid"
                />
              </div>
            </Row>
          </CardBody>
        </Card>
      }
    </div >
  )
}
export default WooDataCard; 
