import React, { useState, useEffect } from "react";
import { Bar } from "react-chartjs-2"
import ApolloClient from 'apollo-boost';
import { getGAAudience } from "../../../queries/GoogleAnalytics/Queries";
import urls from "../../../routes/apiUrls";
const ApploCLI = new ApolloClient({
  uri: urls.analytics,
});
const UserGenderBarChart = () => {
  const [refetch, setRefetch] = useState(false);
  const [userGender, setUserGender] = useState([]);
  useEffect(() => {
    ApploCLI.query({
      query: getGAAudience,
      context: {
        headers: {
          Authorization: window.localStorage.getItem("token")
            ? `Bearer ${window.localStorage.getItem("token")}`
            : "",
        },
      },
      errorPolicy: "all",
      fetchPolicy: "network-only", //allow me to refecth data
    }).then((data) => {
      if (data.data.gaaudiences) {
        setUserGender(data.data.gaaudiences[0].userGender);
      } else {
        setRefetch(!refetch);
      }
    });
  }, []);
  const dataMapGAAudienceGender = userGender.map((item) => { return item[0] });
  const dataMapGAAudienceGenderCount = userGender.map((item) => { return item[1] });
  const data = {
    labels: dataMapGAAudienceGender,
    datasets: [
      {
        label: "Gender",
        backgroundColor: "#EC1A74",
        borderColor: "#EC1A74",
        borderWidth: 1,
        data: dataMapGAAudienceGenderCount,
      },
    ],
  }
  const option = {
    scales: {
      dataset: [
        {
          barPercentage: 0.4,
        },
      ],
    },
  }
  return (
    <Bar width={474} height={300} data={data} options={option} />
  )
}
export default UserGenderBarChart
