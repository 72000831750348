import gql from 'graphql-tag';

const getOrdersStatus = gql`query($limit:Int!,$page:Int!){
    all(limit:$limit,page:$page){
        pages,
        type,
        orderStatus{
            name,
            slug,
            total
        },
}}
`


export { getOrdersStatus};