import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import ApolloClient from 'apollo-boost';
import { REFRESH_TOKEN } from '../../queries/LoginQueries';
import { checkExpiration } from '../../queries/Users/userQuery';
import urls from '../apiUrls';
import { logout } from '../../components/CommonForBoth/TopbarDropdown/ProfileMenu';
import { logoutRequest } from '../../store/actions';
import {jwtDecode} from 'jwt-decode'; // Ensure correct import

const userClient = new ApolloClient({
  uri: urls.user,
});
const loginClient = new ApolloClient({
  uri: urls.login,
});

const isTokenExpired = (token) => {
  if (!token) return false;
  try {
    const decodedToken = jwtDecode(token);
    const currentTime = Date.now() / 1000;
    return decodedToken.exp < currentTime;
  } catch (error) {
    console.error('Error decoding token:', error);
    return true;
  }
};

const AuthExpirationMiddleware = ({
  component: Component,
  layout: Layout,
  isAuthProtected,
  allowedPaths,
  path,
  restrictedPaths,
  ...rest
}) => {
  const [loading, setLoading] = useState(true);
  const [planExpired, setPlanExpired] = useState(false);
  const dispatch = useDispatch();

  const isLoggedOut = () => {
    dispatch(logoutRequest());
    logout();
  };

  useEffect(() => {
    const refreshToken = async () => {try {
      const decodedToken = isTokenExpired(window.localStorage.getItem('token'));
      if (window.localStorage.getItem('token') && decodedToken) {
        const res = await loginClient.query({
          query: REFRESH_TOKEN,
          context: {
            credentials: 'include',
          },
          variables: {
            token: window.localStorage.getItem('token'),
          },
          errorPolicy: 'all',
        });
        console.log('RESPONSE in the middleware', res);
        window.localStorage.setItem('token', res.data.refreshToken.accessToken);
      }
    } catch (error) {
      console.error('Error occurred while refreshing:', error);
      isLoggedOut();
    }
  
    };

    const fetchExpirationStatus = async () => {
      try {
        const token = window.localStorage.getItem('token');
        const res = await userClient.query({
          query: checkExpiration,
          context: {
            headers: {
              Authorization: token ? `Bearer ${token}` : '',
            },
          },
          errorPolicy: 'all',
        });
        setLoading(false);
        setPlanExpired(res.data.checkExpiration);
        return res.data.checkExpiration;
      } catch (error) {
        console.error('Error occurred while checking expiration:', error);
        setLoading(false);
      }
    };

    const init = async () => {
      await refreshToken();
      await fetchExpirationStatus();
    };

    init();
  }, []);

  if (loading) {
    return null; // or a loading spinner
  }

  return (
    <Route
      {...rest}
      render={(props) => {
        const token = localStorage.getItem('token');
        if (restrictedPaths.includes(path) && token) {
          return (
            <Redirect
              to={{
                pathname: '/integrations',
                state: { from: props.location },
              }}
            />
          );
        }
        if (isAuthProtected && !token) {
          return (
            <Redirect
              to={{ pathname: '/login', state: { from: props.location } }}
            />
          );
        }
        if (planExpired && !allowedPaths.includes(props.location.pathname)) {
          return (
            <Redirect
              to={{
                pathname: '/settings-profile',
                state: { from: props.location },
              }}
            />
          );
        }

        return (
          <Layout>
            <Component {...props} />
          </Layout>
        );
      }}
    />
  );
};

AuthExpirationMiddleware.propTypes = {
  component: PropTypes.any,
  layout: PropTypes.any,
  isAuthProtected: PropTypes.bool,
  allowedPaths: PropTypes.arrayOf(PropTypes.string),
};

export default AuthExpirationMiddleware;
