import React, { useState, useEffect, useRef } from 'react';
import { Col, FormGroup, InputGroup } from 'reactstrap';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { DateRange } from 'react-date-range';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';


//import { getOverviewDataRequest } from '../../store/reports/overview/action';
const daysBetween = function (date1, date2) {
  var one_day = 1000 * 60 * 60 * 24;
  var date1_ms = date1.getTime();
  var date2_ms = date2.getTime();
  var difference_ms = date2_ms - date1_ms;
  return Math.round(difference_ms / one_day);
};
const useOutsideAlerter = (ref, toggleDate) => {
  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        toggleDate(false);
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref]);
};
const datePattern = /(\d{1,2})\/(\d{1,2})\/(\d{4})/;
const CalendarComponent = (props) => {
  const [isPeriodChecked, setPeriodChecked] = useState(false);
  const [isYearChecked, setYearChecked] = useState(false);

  const wrapperRef = useRef(null);
  const [toggleDate, setToggleDate] = useState(false);
  useOutsideAlerter(wrapperRef, setToggleDate);
  const [currentFocus, setCurrentFocus] = useState([]);
  const [dateRage, setDateRange] = useState({
    date: [
      window.localStorage.getItem('start')
        ? new Date(window.localStorage.getItem('start'))
        : new Date(
          new Date().toLocaleDateString('en-US', { timeZone: props.timeZone })
        ),
      window.localStorage.getItem('end')
        ? new Date(window.localStorage.getItem('end'))
        : new Date(
          new Date().toLocaleDateString('en-US', { timeZone: props.timeZone })
        ),
    ],
  });
  const [lastPeriod, setLastPeriod] = useState('');
  const [chosedDate, setChosedDate] = useState([
    {
      startDate: window.localStorage.getItem('start')
        ? new Date(window.localStorage.getItem('start'))
        : new Date(
          new Date().toLocaleDateString('en-US', { timeZone: props.timeZone })
        ),
      endDate: window.localStorage.getItem('end')
        ? new Date(window.localStorage.getItem('end'))
        : new Date(
          new Date().toLocaleDateString('en-US', { timeZone: props.timeZone })
        ),
      key: 'selection',
    },
  ]);
  const handleCompareToggle = (compare_to) => {
    setComparedTo(compare_to);
    if (comparedTo === compare_to) {
      setComparedTo('');
    }
  };
  const [activeBtn, setActiveBtn] = useState(
    window.localStorage.getItem('activeBtn')
      ? JSON.parse(window.localStorage.getItem('activeBtn'))
      : {
        today: 1,
        yesterday: 0,
        last7days: 0,
        last30day: 0,
        last60day: 0,
        month: 0,
        other: 0,
      }
  );
  const [comparedTo, setComparedTo] = useState('');
  const mnths = [
    { num: 0, add: 2 },
    { num: 1, add: 0 },
    { num: 2, add: 2 },
    { num: 3, add: 1 },
    { num: 4, add: 2 },
    { num: 5, add: 1 },
    { num: 6, add: 2 },
    { num: 7, add: 2 },
    { num: 8, add: 1 },
    { num: 9, add: 2 },
    { num: 10, add: 1 },
    { num: 11, add: 2 },
  ];
  const getToAddOnMonths = (int) => {
    let res = 0;
    let month = 0;
    if (int === 0) {
      month = int;
    } else {
      month = int - 1;
    }
    mnths.map((x) => {
      if (x.num === month) res = x.add;
    });
    return res;
  };
  useEffect(() => {
    if (chosedDate[0] && chosedDate[0].startDate && chosedDate[0].endDate) {
      const startDate = chosedDate[0].startDate;
      const endDate = chosedDate[0].endDate;
      const newStartDate = new Date(startDate).setDate(
        new Date(startDate).getDate() - daysBetween(startDate, endDate) - 1
      );
      const newEndDate = new Date(endDate).setDate(
        new Date(endDate).getDate() - daysBetween(startDate, endDate) - 1
      );
      comparedTo !== 'year'
        ? setLastPeriod(
          `${'Comparedto'} : ${new Date(newStartDate).toLocaleString('en', {
            day: '2-digit',
            month: 'short',
          })}-${new Date(newEndDate).toLocaleString('en', {
            day: '2-digit',
            month: 'short',
          })} ${new Date(newEndDate).getFullYear()}`
        )
        : setLastPeriod(
          `${'Comparedto'}: ${new Date(startDate).toLocaleString('en', {
            day: '2-digit',
            month: 'short',
          })}-${new Date(endDate).toLocaleString('en', {
            day: '2-digit',
            month: 'short',
          })} ${new Date(endDate).getFullYear() - 1}`
        );
    }
  }, [chosedDate, comparedTo]);
  const buttonHandler = (startDate, endDate) => {
    setDateRange({ date: [startDate, endDate] });
    setChosedDate([
      {
        startDate: startDate,
        endDate: endDate,
        key: 'selection',
      },
    ]);
    setToggleDate(false);
    window.localStorage.setItem('start', startDate);
    window.localStorage.setItem('end', endDate);
  };


  useEffect(() => {
    if (currentFocus[1] === 0) {
      setDateRange({
        date: [
          new Date(
            new Date(
              chosedDate[0].startDate.toLocaleDateString('en-US', {
                timeZone: props.timeZone ? props.timeZone : 'UTC',
              })
            )
          ),
          new Date(
            new Date(
              chosedDate[0].endDate.toLocaleDateString('en-US', {
                timeZone: props.timeZone ? props.timeZone : 'UTC',
              })
            )
          ),
        ],
      });
      setToggleDate(false);
    }
  }, [currentFocus]);
  useEffect(() => {
    window.localStorage.setItem('start', chosedDate[0].startDate);
    window.localStorage.setItem('end', chosedDate[0].endDate);
  }, [chosedDate]);


  return (
    
      <FormGroup className='mt-3'>
        <InputGroup>
          
            <div>
              <div className="divDateContainer">
                <div ref={wrapperRef}>
                  
                  <button 
                    type="button"
                    className="btn btn-light"
                    onClick={() => setToggleDate(!toggleDate)}
                    id="page-header-search-dropdown"
                    
                    
                  >
                   {chosedDate[0] !== null
                              ? [
                                chosedDate[0].startDate.toLocaleString('en', {
                                  month: 'short',
                                }),
                                ' ',
                                chosedDate[0].startDate.getDate(),
                                ' ',
                                chosedDate[0].startDate.getFullYear(),
                                '  -  ',
                                chosedDate[0].endDate &&
                                chosedDate[0].endDate.toLocaleString('en', {
                                  month: 'short',
                                }),
                                ' ',
                                chosedDate[0].endDate &&
                                chosedDate[0].endDate.getDate(),
                                ' ',
                                chosedDate[0].endDate &&
                                chosedDate[0].endDate.getFullYear(),
                              ]
                              : null}

                  </button>

                  {toggleDate && (
                    <div
                      style={{
                        position: 'relative',
                        display: 'flex',
                      }}
                    >
                      <div className="calenderDivStyle" style={{ right: 0 }}>
                        <div className="btnsCalendar">
                          <DateRange
                            editableDateInputs={true}
                            moveRangeOnFirstSelection={false}
                            onRangeFocusChange={(item) => {
                              setCurrentFocus(item);
                            }}
                            onChange={(item) => {
                              setChosedDate([item.selection]);
                              setActiveBtn({
                                today: 0,
                                yesterday: 0,
                                last7days: 0,
                                last30day: 0,
                                last60day: 0,
                                month: 0,
                                other: 1,
                              });
                              window.localStorage.setItem(
                                'activeBtn',
                                JSON.stringify({
                                  today: 0,
                                  yesterday: 0,
                                  last7days: 0,
                                  last30day: 0,
                                  last60day: 0,
                                  month: 0,
                                  other: 1,
                                })
                              );
                            }}
                            maxDate={new Date()}
                            disabledDates={[
                              {
                                after: new Date(), // Disable dates after today
                              },
                            ]}
                            ranges={chosedDate}
                            dragSelectionEnabled={true}
                            rangeColors={['#6689F2']}
                            months={2}
                            direction="horizontal"
                          />
                        </div>
                        <div className="calendarDiv">
                          <div className="btnCalendarContainer">
                            <button
                              className={
                                activeBtn.today
                                  ? 'dateBtns activeBtnDate'
                                  : 'dateBtns'
                              }
                              onClick={() => {
                                buttonHandler(
                                  new Date(
                                    new Date().toLocaleDateString(
                                      'en-US',
                                      {
                                        timeZone:
                                          window.localStorage.getItem(
                                            'timezone'
                                          ),
                                      }
                                    )
                                  ),
                                  new Date(
                                    new Date().toLocaleDateString(
                                      'en-US',
                                      {
                                        timeZone:
                                          window.localStorage.getItem(
                                            'timezone'
                                          ),
                                      }
                                    )
                                  )
                                );
                                setActiveBtn({
                                  today: 1,
                                  yesterday: 0,
                                  last7days: 0,
                                  last30day: 0,
                                  last60day: 0,
                                  month: 0,
                                  other: 0,
                                });
                                window.localStorage.setItem(
                                  'activeBtn',
                                  JSON.stringify({
                                    today: 1,
                                    yesterday: 0,
                                    last7days: 0,
                                    last30day: 0,
                                    last60day: 0,
                                    month: 0,
                                    other: 0,
                                  })
                                );
                              }}
                            >
                              {props.t('compareto')}
                            </button>
                            <button
                              className={
                                activeBtn.yesterday
                                  ? 'dateBtns activeBtnDate'
                                  : 'dateBtns'
                              }
                              onClick={() => {
                                const timeZone =
                                  window.localStorage.getItem(
                                    'timezone'
                                  ) || 'UTC'; // set default time zone to UTC
                                buttonHandler(
                                  new Date(
                                    new Date(
                                      new Date().getTime() -
                                      24 * 60 * 60 * 1000
                                    ).toLocaleDateString('en-US', {
                                      timeZone: timeZone,
                                    })
                                  ),

                                  new Date(
                                    new Date(
                                      new Date().getTime() -
                                      24 * 60 * 60 * 1000
                                    ).toLocaleDateString('en-US', {
                                      timeZone: timeZone,
                                    })
                                  )
                                );
                                setActiveBtn({
                                  today: 0,
                                  yesterday: 1,
                                  last7days: 0,
                                  last30day: 0,
                                  last60day: 0,
                                  month: 0,
                                  other: 0,
                                });
                                window.localStorage.setItem(
                                  'activeBtn',
                                  JSON.stringify({
                                    today: 0,
                                    yesterday: 1,
                                    last7days: 0,
                                    last30day: 0,
                                    last60day: 0,
                                    month: 0,
                                    other: 0,
                                  })
                                );
                              }}
                            >
                              {props.t('yesterday')}
                            </button>
                            <button
                              className={
                                activeBtn.last7days
                                  ? 'dateBtns activeBtnDate'
                                  : 'dateBtns'
                              }
                              onClick={() => {
                                const timeZone =
                                  window.localStorage.getItem(
                                    'timezone'
                                  ) || 'UTC'; // set default time zone to UTC
                                buttonHandler(
                                  new Date(
                                    new Date(
                                      new Date().getTime() -
                                      6 * 24 * 60 * 60 * 1000
                                    ).toLocaleDateString('en-US', {
                                      timeZone: timeZone,
                                    })
                                  ),
                                  new Date(
                                    new Date().toLocaleDateString(
                                      'en-US',
                                      { timeZone: timeZone }
                                    )
                                  )
                                );
                                setActiveBtn({
                                  today: 0,
                                  yesterday: 0,
                                  last7days: 1,
                                  last30day: 0,
                                  last60day: 0,
                                  month: 0,
                                  other: 0,
                                });
                                window.localStorage.setItem(
                                  'activeBtn',
                                  JSON.stringify({
                                    today: 0,
                                    yesterday: 0,
                                    last7days: 1,
                                    last30day: 0,
                                    last60day: 0,
                                    month: 0,
                                    other: 0,
                                  })
                                );
                              }}
                            >
                              {props.t('last7days')}
                            </button>
                            <button
                              className={
                                activeBtn.last30day
                                  ? 'dateBtns activeBtnDate'
                                  : 'dateBtns'
                              }
                              onClick={() => {
                                const timeZone =
                                  window.localStorage.getItem(
                                    'timezone'
                                  ) || 'UTC'; // set default time zone to UTC
                                buttonHandler(
                                  new Date(
                                    new Date(
                                      new Date().getTime() -
                                      29 * 24 * 60 * 60 * 1000
                                    ).toLocaleDateString('en-US', {
                                      timeZone: timeZone,
                                    })
                                  ),
                                  new Date(
                                    new Date().toLocaleDateString(
                                      'en-US',
                                      { timeZone: timeZone }
                                    )
                                  )
                                );
                                setActiveBtn({
                                  today: 0,
                                  yesterday: 0,
                                  last7days: 0,
                                  last30day: 1,
                                  last60day: 0,
                                  month: 0,
                                  other: 0,
                                });
                                window.localStorage.setItem(
                                  'activeBtn',
                                  JSON.stringify({
                                    today: 0,
                                    yesterday: 0,
                                    last7days: 0,
                                    last30day: 1,
                                    last60day: 0,
                                    month: 0,
                                    other: 0,
                                  })
                                );
                              }}
                            >
                              {props.t('last30days')}
                            </button>
                            <button
                              className={
                                activeBtn.month
                                  ? 'dateBtns activeBtnDate'
                                  : 'dateBtns'
                              }
                              onClick={() => {
                                const timeZone =
                                  window.localStorage.getItem(
                                    'timezone'
                                  ) || 'UTC'; // set default time zone to UTC
                                buttonHandler(
                                  new Date(
                                    new Date(
                                      new Date().getFullYear(),
                                      new Date().getMonth(),

                                      getToAddOnMonths(
                                        new Date().getMonth()
                                      )
                                    ).toLocaleDateString('en-US', {
                                      timeZone: timeZone,
                                    })
                                  ),

                                  new Date(
                                    new Date().toLocaleDateString(
                                      'en-US',
                                      { timeZone: timeZone }
                                    )
                                  )
                                );
                                setActiveBtn({
                                  today: 0,
                                  yesterday: 0,
                                  last7days: 0,
                                  last30day: 0,
                                  last60day: 0,
                                  month: 1,
                                  other: 0,
                                });
                                window.localStorage.setItem(
                                  'activeBtn',
                                  JSON.stringify({
                                    today: 0,
                                    yesterday: 0,
                                    last7days: 0,
                                    last30day: 0,
                                    last60day: 0,
                                    month: 1,
                                    other: 0,
                                  })
                                );
                              }}
                            >
                              {props.t('thismonth')}
                            </button>
                            <span className="compareSpan">
                              <b>{props.t('compareto')}</b>
                            </span>
                            <div className="form-check mb-3">
                              <input
                                style={{
                                  width: '1em',
                                  height: '1em',
                                  marginTop: '0.25em',
                                  verticalAlign: 'top',
                                  backgroundColor: '#fff',
                                  backgroundRepeat: 'no-repeat',
                                  backgroundPosition: 'center',
                                  border: '#000000',
                                }}
                                type="checkbox"
                                id="formCheck1"
                                className={
                                  comparedTo === 'period'
                                    ? 'compareTogglebtnStyleActive'
                                    : null
                                }
                                checked={comparedTo === 'period'}
                                onClick={() => {
                                  handleCompareToggle('period');
                                  setPeriodChecked(!isPeriodChecked);
                                }
                                }
                              />
                              <label
                                className="form-check-label"
                                htmlFor="formCheck1"
                              >
                                {props.t('previousperiod')}
                              </label>
                            </div>
                            <div className="form-check mb-3">
                              <input
                                style={{
                                  width: '1em',
                                  height: '1em',
                                  marginTop: '0.25em',
                                  verticalAlign: 'top',
                                  backgroundColor: '#fff',
                                  backgroundRepeat: 'no-repeat',
                                  backgroundPosition: 'center',
                                  border: '#000000',
                                }}
                                type="checkbox"
                                id="formCheck1"
                                className={
                                  comparedTo === 'year'
                                    ? 'compareTogglebtnStyleActive'
                                    : null
                                }
                                checked={comparedTo === 'year'}
                                onChange={() => {
                                  handleCompareToggle('year');
                                  setYearChecked(!isYearChecked);
                                }}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="formCheck1"
                              >
                                {props.t('Previousyear')}
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  <br />
                  <small>{comparedTo !== '' ? lastPeriod : null}</small>
                </div>
              </div>
            </div>
         
        </InputGroup>
      </FormGroup>
   
  );
};
export default withRouter(withTranslation()(CalendarComponent));
