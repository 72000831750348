import React, { useState, useEffect } from 'react';
import { TabContent, TabPane, CardBody, Progress, Alert } from 'reactstrap';
import { Table, Thead, Tbody, Tr, Th } from "react-super-responsive-table";
import SingleRefund from './Refunds/SingleRefund';
import { useDispatch, useSelector } from 'react-redux';
import { fetchRefundsRequest } from '../../store/prestaShop/actions';
import SimpleBar from "simplebar-react"
function PrestaShopRefunds() {
    const [activeTab, setActiveTab] = useState('1');
    const dispatch = useDispatch();
    const { refunds } = useSelector((state) => state.prestashop);
    useEffect(() => {
        const savedProducts = localStorage.getItem('refunds');
        if (savedProducts) {

            dispatch({ type: 'FETCH_REFUNDS_SUCCESS', payload: JSON.parse(savedProducts) });
        } else {
            dispatch(fetchRefundsRequest(50, 1));
        }
    }, [dispatch]);
    return (
        <React.Fragment>
            <div className="col-xl-4">
                <div className="card">
                    <div className="card-header align-items-center d-flex">
                        <h4 className="card-title mb-0 flex-grow-1">Refunds</h4>
                    </div>
                    <CardBody className="px-0">
                        <TabContent activeTab={activeTab}>
                            <TabPane tabId="1">
                                {refunds && refunds.length > 0 ? (
                                    <SimpleBar className="table-responsive px-3" style={{ maxHeight: "600px" }}>
                                        <Table className="table table-striped table-bordered">
                                            <Thead>
                                                <Tr>
                                                    <Th data-priority="1">Refund Id</Th>
                                                    <Th data-priority="1">Order Id</Th>
                                                    <Th data-priority="1">Amount</Th>
                                                </Tr>
                                            </Thead>
                                            <Tbody>
                                                {refunds.map((element, index) => (
                                                    <SingleRefund element={element} key={index} />
                                                ))}
                                            </Tbody>
                                        </Table>
                                    </SimpleBar>
                                ) : (
                                    <div className='text-center'>

                                        <div className="text-center">
                                            <CardBody>
                                                <Progress
                                                    value={80}
                                                    color="success"
                                                    style={{ width: '75%' }}
                                                    animated
                                                ></Progress>
                                                <Alert color="success" className="mt-3">
                                                    This might take a few minutes!
                                                </Alert>
                                            </CardBody>
                                        </div>
                                    </div>
                                )}
                            </TabPane>
                        </TabContent>
                    </CardBody>
                </div>
            </div>
        </React.Fragment>
    );
}
export default PrestaShopRefunds;