import gql from 'graphql-tag';

const getData = gql`query($startDate:String!,$endDate:String!,$compare_to:String){
    get(startDate:$startDate,endDate:$endDate,compare_to:$compare_to){
        reports{
            date,
            sum_subtotal,
            total_num_orders,
            gross_rev,
            aov
        },
        lastPeriodReport{
            date,
            sum_subtotal,
            total_num_orders,
            gross_rev,
            aov
        }
       
    }
}
`

export { getData }