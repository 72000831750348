import React from "react"
import MetaTags from 'react-meta-tags';
import {
  Row,
} from "reactstrap"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import AvgValue from "./gaEventCategoryTracking/totalEvents.js/AvgValue";
import EventPerSessionWithEvent from "./gaEventCategoryTracking/totalEvents.js/EventPerSessionWithEvent";
import EventValue from "./gaEventCategoryTracking/totalEvents.js/EventValue";
import SessionsWithEvents from "./gaEventCategoryTracking/totalEvents.js/SessionsWithEvent";
import TotalEvents from "./gaEventCategoryTracking/totalEvents.js/TotalEvents";
function GAEventTracking() {
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Google Analytics | Live-Metrics - E-com SaaS</title>
        </MetaTags>
        <div className="container-fluid">
          <Breadcrumbs title="Google Analytics" breadcrumbItem="Event Category Tracking" pageHeading="Event Tracking" />
          <Row>
         <TotalEvents />
         <EventValue />
          </Row>
         <Row>
          <AvgValue/>
          <SessionsWithEvents />
          <EventPerSessionWithEvent />
         </Row>
        </div>
      </div>
    </React.Fragment>
  )
}
export default GAEventTracking;
