import { useState, useEffect } from "react";
import Swal from "sweetalert2";
import storeImg from "./icon-store.svg"
import logo from "../../assets/images/Asset-1.png";
import { getSalesPlatform } from "../../queries/Shopify/queries";
import urls from "../../routes/apiUrls";
import ApolloClient from "apollo-boost";
const AppoloCliCheckSales = new ApolloClient({

    uri: urls.shopify
})
const AlertProgressBar = () => {
const [progress, setProgress] = useState();
useEffect(() => {
    AppoloCliCheckSales.query({
        query: getSalesPlatform,
        context: {
            headers: {
                Authorization: window.localStorage.getItem("token")
                    ? `Bearer ${window.localStorage.getItem("token")}`
                    : "",
            },
        },
        errorPolicy: "all",
        fetchPolicy: "network-only", //allow me to refecth data 
    }).then((data) => {
        if (data.data.get) {
            setProgress(data.data.get.presta_round_number_orders_server_time_response);
        }
    }
    )
}, [])

Swal.fire({
      html: ` <img src=${logo} height="45"/>
        <br> 
        <br>
                 
                       <img src=${storeImg} height="38"/>
                       <div className="">
                       <Progress color="primary" value=${progress}%>
                       </Progress>
                   </div>
                 <br>
                 <p>You haven't connected<br/>any account yet to your store </p>
    
                 `
      ,
      imageWidth: 100,
      imageHeight: 100,
      width: 300,
      backdrop: `
           rgba(0,0,123,0.4)
         `,
      allowOutsideClick: false,
      confirmButtonText: "Connect Accounts",

});
}



export {AlertProgressBar}