import Swal from "sweetalert2";
import storeImg from "./icon-store.svg"
import logo from "../../assets/images/Asset-1.png";
import jwt from "jsonwebtoken";
// this methode , used for triger the alert in each component need it , in one condition  : if the user doenst have a linked Shopify account
const alert = () => Swal.fire({
      html: ` <img src=${logo} height="45"/>
        <br> 
        <br>
                 
                       <img src=${storeImg} height="38"/>

                 <h4>${jwt.decode(window.localStorage.getItem("token")).account && jwt.decode(window.localStorage.getItem("token")).account.name}</h4>
                 <br>
                 <p>You haven't connected<br/>any account yet to your store </p>
    
                 `
      ,
      imageWidth: 100,
      imageHeight: 100,
      width: 300,
      backdrop: `
           rgba(0,0,123,0.4)
         `,
      allowOutsideClick: false,
      confirmButtonText: "Connect Accounts",

});

const alert3 = () => Swal.fire({

      html: ` <img src=${logo} height="45"/>
         <br> 
         <br>
                  
                        <img src=${storeImg} height="38"/>
 
                  <h4>${jwt.decode(window.localStorage.getItem("token")).account && jwt.decode(window.localStorage.getItem("token")).account.name}</h4>
                  <br>
                  <p>Something  Went Wrong  !! </p>
            
                  
     
                  `
      ,
      imageWidth: 100,
      imageHeight: 100,
      width: 300,
      backdrop: `
            rgba(0,0,123,0.4)
          `,
      allowOutsideClick: false,
      

})
const alert2 = () => Swal.fire({
      html: ` <img src=${logo} height="45"/>
            <br>
                  <img src=${storeImg} height="38"/>

            <h4>  Comming Soon !</h4>
            <br>
      `,
      imageWidth: 100,
      imageHeight: 100,
      width: 300,
      backdrop: `
            rgba(0,0,123,0.4)
          `,
      allowOutsideClick: false,
      confirmButtonText: "Go to intergrations",

})

const deleteAlert2 = () => Swal.fire({

      title: 'Are you sure?',
      text: 'You want to delete this integration',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it',
      imageWidth: 100,
      imageHeight: 100,
      width: 400,
      backdrop: ` rgba(0,0,123,0.4)`,
      allowOutsideClick: true

})

const  deleteAlert = (store) => Swal.fire({
      html: ` <img src=${logo} height="45"/>
            <br>
            <p class="mt-3 mb-1">The intgration for the <span class="text-danger h5">${store}</span> it will be removed!</p>
      `,
      // title: 'Are you sure?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Confirm',
      cancelButtonText: 'Cancel',
      cancelButtonColor: '#c71f21',
      confirmButtonColor: 'rgb(76, 174, 76)',
      imageWidth: 100,
      imageHeight: 100,
      width: 400,
      backdrop: ` rgba(0,0,123,0.4)`,
      allowOutsideClick: true
    })

const  successAlert = (store) => Swal.fire(
      'Deleted!',
      `Your intgration has been deleted.`,
      'success'
    )



export { alert, alert2, deleteAlert, deleteAlert2, successAlert,alert3 }

// swal({
//       title: 'Are you sure?',
//       text: 'You will not be able to recover this imaginary file!',
//       type: 'warning',
//       showCancelButton: true,
//       confirmButtonText: 'Yes, delete it!',
//       cancelButtonText: 'No, keep it'
//     })
//     .then(function() {
//       swal(
//         'Deleted!',
//         'Your imaginary file has been deleted.',
//         'success'
//       )
//     }, function(dismiss) {
//       // dismiss can be 'overlay', 'cancel', 'close', 'esc', 'timer'
//       if (dismiss === 'cancel') {
//         swal(
//           'Cancelled',
//           'Your imaginary file is safe :)',
//           'error'
//         )
//       }
//     })