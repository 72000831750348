import React, { useEffect, useState } from 'react';
import { Card, CardBody, Col, Row } from 'reactstrap';

import { Button, Space, Table, Menu, Dropdown, Checkbox } from 'antd';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { Link } from 'react-router-dom';
import '../Integrations/styles.css';

import { getInstaPageData, getInstaPostData, getInstaAudienceData } from '../../store/instagram/actions';
import { useDispatch, useSelector } from 'react-redux';
const InstagramLogo = `${process.env.PUBLIC_URL}/images/integrations_images/Insta1.svg`;
const datePattern = /(\d{1,2})\/(\d{1,2})\/(\d{4})/;

const InstagramOverview = (props) => {
  const dispatch = useDispatch();
  const { instaPageData, instaPostData, instaAudienceData } = useSelector((state) => state.Instagram);

  const [filteredInfo, setFilteredInfo] = useState({});
  const [sortedInfo, setSortedInfo] = useState({});
  const [reach, setreach] = useState('');
  const [impressions, setimpressions] = useState('');
  const [follower_count, setfollower_count] = useState('');
  const [profile_views, setprofile_views] = useState('');
  const [website_clicks, setwebsite_clicks] = useState('');

  const [paginationOptions, setPaginationOptions] = useState({
    pageSize: 5, // Default page size
    pageSizeOptions: ['5', '10', '20'], // Available page size options
  });
  const formatNumberWithSpaces = (value) => {
    if (value !== undefined && value !== null) {
      return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
    }
    return '';
  };

  useEffect(() => {
    var newStartDate = datePattern.exec(props.chosedDate[0].startDate && props.chosedDate[0].startDate
      .toLocaleString("fr-FR"));
    var newEndDate = datePattern.exec(props.chosedDate[0].endDate && props.chosedDate[0].endDate
      .toLocaleString("fr-FR"));
    if (newStartDate !== null && newEndDate !== null) {
      dispatch(getInstaPageData(newStartDate[3] + "-" + newStartDate[2] + "-" + newStartDate[1], newEndDate[3] + "-" + newEndDate[2] + "-" + newEndDate[1]));
    }

  }, [props.dateRage, props.chosedDate]);

  useEffect(() => {
    if (instaPageData) {
      let total_reach = 0; // Initialize total_reach before the map function
      let total_impressions = 0;
      let total_follower_count = 0;
      let total_profile_views = 0;
      let total_website_clicks = 0;

      instaPageData.forEach((item) => {
        total_reach += item.reach;
        total_impressions += item.impressions;
        total_follower_count += item.follower_count;
        total_profile_views += item.profile_views;
        total_website_clicks += item.website_clicks;
      });
      setreach(total_reach);
      setimpressions(total_impressions);
      setfollower_count(total_follower_count);
      setprofile_views(total_profile_views);
      setwebsite_clicks(total_website_clicks);
    }
  }, [instaPageData]);

  useEffect(() => {
    dispatch(getInstaPostData());
  }, []);
  const [secCards, setsecCards] = useState([

    { id: 1, name: 'Impressions' },
    { id: 2, name: 'New followers' },
    { id: 3, name: 'Profile views' },
    { id: 4, name: 'Website clicks' },
  ]);
  useEffect(() => {
    let isSubscribed = true;
    setsecCards([

      { id: 1, name: 'Impressions' },
      { id: 2, name: 'New followers' },
      { id: 3, name: 'Profile views' },
      { id: 4, name: 'Website clicks' },
    ]);
    return () => (isSubscribed = false);
  }, [reach, impressions, follower_count, profile_views, website_clicks]);
  // Define sample data for testing

  const columns = [

    {
      title: props.t('Post Image'),
      dataIndex: 'media_url',
      key: 'media_url',
      align: 'center',
      filters: [
        {
          text: 'Image',
          value: 'IMAGE',
        },
        {
          text: 'Video',
          value: 'VIDEO',
        },
        {
          text: 'Carousel Album',
          value: 'CAROUSEL_ALBUM',
        },
      ],
      filteredValue: filteredInfo.media_url || null,
      onFilter: (value, record) => {
        if (value === 'IMAGE') {
          return record.media_type === 'IMAGE';
        } else if (value === 'VIDEO') {
          return record.media_type === 'VIDEO';
        } else if (value === 'CAROUSEL_ALBUM') {
          return record.media_type === 'CAROUSEL_ALBUM';
        }
        return true; // Return true if no filter is applied
      },
      // sortOrder: sortedInfo.columnKey === 'name' ? sortedInfo.order : null,
      ellipsis: true,
      render: (text, record) => {
        if (!text) {
          return (
            <a href={record.permalink} target="_blank" rel="noopener noreferrer">
              <span>{' There is Non-image '}</span>
            </a>
          );
        } else {
          return (
            <a href={record.permalink} target="_blank" rel="noopener noreferrer">
              <img
                src={text}
                alt={`Full Picture ${record.full_picture}`}
                style={{ maxWidth: '100px', maxHeight: '90px' }}
              />
            </a>
          );
        }
      }
    },
    {
      title: props.t('Post Interactions'),
      dataIndex: 'total_interactions',
      key: 'total_interactions',
      sorter: (a, b) => a.total_interactions - b.total_interactions,
      sortOrder:
        sortedInfo.columnKey === 'total_interactions' ? sortedInfo.order : null,
      ellipsis: true,
      align: 'center',
      render: (text) => <strong>{text}</strong>,
    },
    {
      title: props.t('Post Saves'),
      dataIndex: 'saved',
      align: 'center',
      key: 'saved',
      sorter: (a, b) => a.saved - b.saved,
      sortOrder: sortedInfo.columnKey === 'saved' ? sortedInfo.order : null,
      ellipsis: true,
      render: (text) => <strong>{text}</strong>,
    },
    {
      title: props.t('Post Impressions'),
      dataIndex: 'impressions',
      key: 'impressions',
      align: 'center',
      sorter: (a, b) => a.impressions - b.impressions,
      sortOrder: sortedInfo.columnKey === 'impressions' ? sortedInfo.order : null,
      ellipsis: true,
      render: (text) => <strong>{text || '-'}</strong>, // Render '-' if text is falsy
    },
    {
      title: props.t('Post Reach'),
      dataIndex: 'reach',
      align: 'center',
      key: 'reach',
      onFilter: (value, record) => record.address.includes(value),
      sorter: (a, b) => a.reach - b.reach,
      sortOrder: sortedInfo.columnKey === 'reach' ? sortedInfo.order : null,
      ellipsis: true,
      render: (text) => <strong>{text}</strong>,
    },
    {
      title: props.t('Post Comments'),
      dataIndex: 'comments',
      key: 'comments',
      align: 'center',
      sorter: (a, b) => a.comments - b.comments,
      sortOrder: sortedInfo.columnKey === 'comments' ? sortedInfo.order : null,
      ellipsis: true,
      render: (text) => <strong>{text}</strong>,

    },
    {
      title: props.t('Post Likes'),
      dataIndex: 'likes',
      key: 'likes',
      sorter: (a, b) => a.likes - b.likes,
      sortOrder: sortedInfo.columnKey === 'likes' ? sortedInfo.order : null,
      ellipsis: true,
      align: 'center',
      render: (text) => <strong>{text}</strong>,
    },
    {
      title: props.t('Post Plays'),
      dataIndex: 'plays',
      align: 'center',
      key: 'plays',
      sorter: (a, b) => a.plays - b.plays,
      sortOrder: sortedInfo.columnKey === 'plays' ? sortedInfo.order : null,
      ellipsis: true,
      render: (text) => <strong>{text || '-'}</strong>,
    },
    {
      title: props.t('Post Shares'),
      dataIndex: 'shares',
      align: 'center',
      key: 'shares',
      sorter: (a, b) => a.shares - b.shares,
      sortOrder: sortedInfo.columnKey === 'shares' ? sortedInfo.order : null,
      ellipsis: true,
      render: (text) => <strong>{text}</strong>,
    },
    {
      title: props.t('Created At'),
      dataIndex: 'timestamp',
      align: 'center',
      key: 'timestamp',
      sorter: (a, b) => new Date(a.timestamp) - new Date(b.timestamp),
      ellipsis: true,
      render: (text) => {
        const date = new Date(text);
        const formattedDate = date.toISOString().split('T')[0]; // Extracts date portion
        return <strong>{formattedDate}</strong>;
      },
    },

  ];
  const [selectedColumns, setSelectedColumns] = useState(
    columns.map((column) => column.key)
  );
  const handleChange = (pagination, filters, sorter) => {
    setFilteredInfo(filters);
    setSortedInfo(sorter);
    if (pagination.pageSize !== paginationOptions.pageSize) {
      setPaginationOptions({
        ...paginationOptions,
        pageSize: pagination.pageSize,
      });
    }
  };

  const clearAll = () => {
    setFilteredInfo({});
    setSortedInfo({});
    // make the columns  selected
    setSelectedColumns(columns.map((column) => column.key));
  };

  const setimpressionsSort = () => {
    setSortedInfo({
      order: 'descend',
      columnKey: 'impressions',
    });
  };
  // const clearFilters = () => {
  //     setFilteredInfo({});
  //   };
  const handleMenuClick = (clickedKey) => {
    const index = selectedColumns.indexOf(clickedKey);

    let updatedSelectedColumns = [];

    if (index === -1) {
      // Column was not selected before, add it to the selected columns
      updatedSelectedColumns = [...selectedColumns, clickedKey];
    } else {
      // Column was selected before, remove it from the selected columns
      updatedSelectedColumns = selectedColumns.filter(
        (key) => key !== clickedKey
      );
    }

    setSelectedColumns(updatedSelectedColumns);
  };
  const menu = (
    <Menu>
      {columns.map((column) => (
        <Menu.Item
          key={column.key}
          onClick={() => handleMenuClick(column.key)}
        >
          <Checkbox
            checked={selectedColumns.includes(column.key)}
            style={{ marginRight: '8px' }}
          />
          <span
            style={
              selectedColumns.includes(column.key)
                ? { fontWeight: 'bold' }
                : {}
            }
          >
            {column.title}
          </span>
        </Menu.Item>
      ))}
    </Menu>
  );


  const filteredColumns = columns.filter((column) =>
    selectedColumns.includes(column.key)
  );




  return (
    <React.Fragment>
      <Col xl={12}>
        <div className="row mb-4">
          <div className="col">
            <h5 className="card-title d-flex align-items-center">
              <img className="img-overview-dashboard ms-2" src={InstagramLogo} />
              <span className="ms-2">{props.t("Instagram Social Page")}</span>
            </h5>
          </div>
        </div>


        <Row>
          <Col xl={3} md={4}>
            <Card className="card-h-100">
              <CardBody className='facebook-ads-card'>
                <div className="my-0 text-primary d-flex justify-content-between align-items-center">
                  <span className="my-0 text-primary">
                    <i className="fa fa-info-circle text-primary" aria-hidden="true" data-tooltip="Instagram Impressions"></i>{" "}
                    {props.t("Impressions")}
                  </span>
                  {/* <div>
                    <img className="img-intgration-icon" src={InstagramLogo} />
                  </div> */}

                </div>
                <Row className="align-items-center">
                  <Col xs={6}>
                    <div className="  mb-3">
                      <h4>
                        {formatNumberWithSpaces(impressions)}
                      </h4>
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
          <Col xl={3} md={4}>
            <Card className="card-h-100">
              <CardBody className='facebook-ads-card'>
                <div className="my-0 text-primary d-flex justify-content-between align-items-center">
                  <span className="my-0 text-primary">
                    <i className="fa fa-info-circle text-primary" aria-hidden="true" data-tooltip="Instagram New followers"></i>{" "}
                    {props.t("New followers")}
                  </span>
                  {/* <div>
                    <img className="img-intgration-icon" src={InstagramLogo} />
                  </div> */}

                </div>
                <Row className="align-items-center">
                  <Col xs={6}>
                    <div className="  mb-3">
                      <h4>
                        {formatNumberWithSpaces(follower_count)}
                      </h4>
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
          <Col xl={3} md={4}>
            <Card className="card-h-100">
              <CardBody className='facebook-ads-card'>
                <div className="my-0 text-primary d-flex justify-content-between align-items-center">
                  <span className="my-0 text-primary">
                    <i className="fa fa-info-circle text-primary" aria-hidden="true" data-tooltip="Instagram Reach"></i>{" "}
                    {props.t("Reach")}
                  </span>
                  

                </div>
                <Row className="align-items-center">
                  <Col xs={6}>
                    <div className="  mb-3">
                      <h4>
                        {formatNumberWithSpaces(reach)}
                      </h4>
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
          <Col xl={3} md={4}>
            <Card className="card-h-100">
              <CardBody className='facebook-ads-card'>
                <div className="my-0 text-primary d-flex justify-content-between align-items-center">
                  <span className="my-0 text-primary">
                    <i className="fa fa-info-circle text-primary" aria-hidden="true" data-tooltip="Instagram Profile View"></i>{" "}
                    {props.t("Profile views")}
                  </span>
                </div>
                <Row className="align-items-center">
                  <Col xs={6}>
                    <div className="  mb-3">
                      <h4>
                        {formatNumberWithSpaces(profile_views)}
                      </h4>
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>

          <Row className="">
            {secCards.map((item) => (
              <div className="dashboardMinorCardItem" key={item.id}>



              </div>
            ))}
          </Row>




        </Row>
        <hr />
        <Row>
          <Card style={{ border: "none" }}>
            <CardBody >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <h4 className="card-title mb-4">
                  {props.t('Instagram Posts')}
                </h4>
                <img className="img-intgration-icon" src={InstagramLogo} />
              </div>
              <Space
                style={{
                  marginBottom: 16,
                }}
              >
                {/* Add any additional buttons or controls specific to Instagram here */}
                <Button onClick={setimpressionsSort}>{props.t("Sort Impressions")}</Button>
                {/* <Button onClick={clearFilters}>Clear filters</Button> */}
                <Button onClick={clearAll}>{props.t("Clear filters and sorters")}</Button>
                <Dropdown overlay={menu} trigger={['click']}>
                <Button>{props.t("Select Columns")}</Button>
                </Dropdown>

              </Space>
              <Table
                // style={{ border: "1px solid #e8e8e8" }}
                columns={filteredColumns}
                dataSource={instaPostData}
                onChange={handleChange}
                pagination={{
                  ...paginationOptions,
                  onChange: handleChange,
                  showSizeChanger: true, // Enable the page size selector
                  // showTotal: (total) => `Total ${total} items`, // Optionally show total items
                  // Other pagination settings
                }}
              />
            </CardBody>
          </Card>
        </Row>
        <div className="mt-2-overview-dashboard">
          <Link to="/InstagramDashboard" className="btn btn-primary btn-sm">{props.t("View more")}<i className="overview-dashboard-icon mdi mdi-arrow-right ms-1"></i></Link>
        </div>
      </Col>
    </React.Fragment>
  );

};

InstagramOverview.propTypes = {
  t: PropTypes.any,
};

export default withRouter(withTranslation()(InstagramOverview));
