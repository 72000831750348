import gql from 'graphql-tag';


const getMembers = gql`query{
    all{
        email,
        last_login,
        stores{
            name,
            sections
            account_id
        }
}}
`
const addMember = gql`mutation($email:String!,$storeName:String!,$sections:[String]){
    add(email:$email,storeName:$storeName,sections:$sections){
        email
    }
}
`

const editMember = gql`mutation($email:String!,$sections:[String]){
    edit(email:$email,sections:$sections){
        email
    }
}
`


const deleteSingleMember = gql`mutation($email:String){
    delete(email:$email){
    email
}}
`


export { getMembers, editMember, deleteSingleMember, addMember };