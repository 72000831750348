// FETCH TOP SELLERS WOOCOMMERCE
export const FETCH_TOP_SELLERS_REQUEST = 'FETCH_TOP_SELLERS_REQUEST';
export const FETCH_TOP_SELLERS_SUCCESS = 'FETCH_TOP_SELLERS_SUCCESS';
export const FETCH_TOP_SELLERS_FAILURE = 'FETCH_TOP_SELLERS_FAILURE';

// FETCH CODES COUPONS WOOCOMMERCE 
export const FETCH_COUPONS_CODES_REQUEST = 'FETCH_COUPONS_CODES_REQUEST';
export const FETCH_COUPONS_CODES_SUCCESS = 'FETCH_COUPONS_CODES_SUCCESS';
export const FETCH_COUPONS_CODES_FAILURE = 'FETCH_COUPONS_CODES_FAILURE';

// FETCH ORDERS STATUS WOOCOMMERCE 
export const FETCH_ORDER_STATUS_REQUEST = 'FETCH_ORDER_STATUS_REQUEST';
export const FETCH_ORDER_STATUS_SUCCESS = 'FETCH_ORDER_STATUS_SUCCESS';
export const FETCH_ORDER_STATUS_FAILURE = 'FETCH_ORDER_STATUS_FAILURE';

//FETCH PRODUCT CATEGORIES 
export const FETCH_PRODUCT_CATEGORY_REQUEST = 'FETCH_PRODUCT_CATEGORY_REQUEST';
export const FETCH_PRODUCT_CATEGORY_SUCCESS = 'FETCH_PRODUCT_CATEGORY_SUCCESS';
export const FETCH_PRODUCT_CATEGORY_FAILURE = 'FETCH_PRODUCT_CATEGORY_FAILURE';
// integration woocommerce
export const INTEGRATIONS_WO_REQUEST = 'INTEGRATIONS_WO_REQUEST';
export const INTEGRATIONS_WO_SUCCESS = 'INTEGRATIONS_WO_SUCCESS';
export const INTEGRATIONS_WO_FAILURE = 'INTEGRATIONS_WO_FAILURE';


// delete woocommerce

export const DELETE_WOOCOMMERCE_REQUEST = 'DELETE_WOOCOMMERCE_REQUEST';
export const DELETE_WOOCOMMERCE_SUCCESS = 'DELETE_WOOCOMMERCE_SUCCESS';
export const DELETE_WOOCOMMERCE_FAILURE = 'DELETE_WOOCOMMERCE_FAILURE';


//get sales platforme
export const GET_SALES_PLATEFORME_REQUEST = 'GET_SALES_PLATEFORME_REQUEST';
export const GET_SALES_PLATEFORME_SUCCESS = 'GET_SALES_PLATEFORME_SUCCESS';
export const GET_SALES_PLATEFORME_FAILURE = 'GET_SALES_PLATEFORME_FAILURE';

// check  third step woocommerce
export const CHECK_THIRD_STEP_WOOCOMMERCE_REQUEST = 'CHECK_THIRD_STEP_WOOCOMMERCE_REQUEST';
export const CHECK_THIRD_STEP_WOOCOMMERCE_SUCCESS = 'CHECK_THIRD_STEP_WOOCOMMERCE_SUCCESS';
export const CHECK_THIRD_STEP_WOOCOMMERCE_FAILURE = 'CHECK_THIRD_STEP_WOOCOMMERCE_FAILURE';
