import{
    GET_OPERATION_REPORTS_REQUEST,
    GET_OPERATION_REPORTS_SUCCESS,
    GET_OPERATION_REPORTS_FAILURE,
 } from './action';

  const initialState = {
    data: [],
    ops:[],
    series: [],
    sales:[],
    error: null,
    avg:0,
    daily:0,
    avgGR:0,
    avgAdCost:0,
    avgFacebook:0,
    avgGoogle:0,
    avgCOG:0,
    avgSnh:0,
    avgRef:0,
    avgDis:0,
    avgTax:0,
    avgOrd:0,
    avgUni:0,
    avgShip:0,
    avgMF:0,
    avgNetIn:0,
    avgtotalreturn:0,
  };

export default function OPERATION(state = initialState, action) {

    switch (action.type) {
        case GET_OPERATION_REPORTS_REQUEST:
            return {
                ...state,
                error: null,
            };
        case GET_OPERATION_REPORTS_SUCCESS:
            return {
                ...state,
                ops: action.payload.ops,
                series: action.payload.series,
                sales: action.payload.sales,
                avg: action.payload.avg,
                daily: action.payload.daily,
                avgGR: action.payload.avgGR,
                avgAdCost: action.payload.avgAdCost,
                avgFacebook: action.payload.avgFacebook,
                avgGoogle: action.payload.avgGoogle,
                avgCOG: action.payload.avgCOG,
                avgSnh: action.payload.avgSnh,
                avgRef: action.payload.avgRef,
                avgDis: action.payload.avgDis,
                avgTax: action.payload.avgTax,
                avgOrd: action.payload.avgOrd,
                avgUni: action.payload.avgUni,
                avgShip: action.payload.avgShip,
                avgMF: action.payload.avgMF,
                avgNetIn: action.payload.avgNetIn,
                avgtotalreturn: action.payload.avgtotalreturn,
                error: null,
            };
        case GET_OPERATION_REPORTS_FAILURE:
            return {
                ...state,
                error: action.payload,

            };
            default:
                return state;
    }
   
    

}