import React from 'react'
import { Row, Col, CardBody, Nav } from "reactstrap";
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, {
  PaginationProvider, PaginationListStandalone,
  SizePerPageDropdownStandalone
} from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
const KlaviyoIcon = `${process.env.PUBLIC_URL}/images/integrations_images/Klaviyo.svg`;


function FlowsTable( props) {
  const { SearchBar } = Search;
  const columns = [
    {
      dataField: 'name',
      text: props.t("Flow Name"),
    },
    {
      dataField: 'archived',
      text: props.t("Archived"),
      sort: false
    },
    
    
    
    {
      dataField: 'creationDate',
      text: props.t("CreationDate"),
      sort: false
    },
    {
      dataField: 'updateDate',
      text: props.t("UpdateDate"),
      sort: true
    },
    {
      dataField: 'status',
      text: props.t("Status"),
      sort: true
    },
   
  
  ];
  const defaultSorted = [{
    dataField: 'id',
    order: 'asc'
  }];
  const pageOptions = {
    sizePerPage: 10, 
    totalSize: props.flows ? props.flows.length : 0,
    custom: true,
  }
  const selectRow = {
    mode: 'checkbox'
  }
  return (
    <Row>
            <div className="card">
              <div className="card-header align-items-center d-flex">
                <h4 className="card-title mb-0 flex-grow-1">{props.t("Flows")}{" "}
                  <i class="fa fa-info-circle text-primary" aria-hidden="true" data-tooltip="This is last 30 days' data"></i>
                </h4>
                <div className="flex-shrink-0">
                  <Nav className="justify-content-end nav-tabs-custom rounded card-header-tabs" role="tablist">
                    <img className="img-intgration-icon" src={KlaviyoIcon} />
                  </Nav>
                </div>
              </div>
              <CardBody>
                <PaginationProvider
                  pagination={paginationFactory(pageOptions)}
                  keyField='id'
                  columns={columns}
                  data={props.flows ? props.flows : []}
                >
                  {({ paginationProps, paginationTableProps }) => (
                    <ToolkitProvider
                      keyField='id'
                      columns={columns}
                      data={props.flows ? props.flows : []}
                      search
                    >
                      {toolkitProps => (
                        <React.Fragment>
                          <Row className="mb-2">
                            <Col md="4">
                              <div className="search-box me-2 mb-2 d-inline-block">
                                <div className="position-relative">
                                  <SearchBar
                                    {...toolkitProps.searchProps}
                                  />
                                  <i className="bx bx-search-alt search-icon" />
                                </div>
                              </div>
                            </Col>
                          </Row>
                          <Row>
                            <Col xl="12">
                              <div className="table-responsive">
                                <BootstrapTable
                                  keyField={"id"}
                                  key={"id"}
                                  responsive
                                  bordered={false}
                                  striped={false}
                                  defaultSorted={defaultSorted}
                                  selectRow={selectRow}
                                  classes={
                                    "table align-middle table-nowrap"
                                  }
                                  headerWrapperClasses={"thead-light"}
                                  {...toolkitProps.baseProps}
                                  {...paginationTableProps}
                                />
                              </div>
                            </Col>
                          </Row>
                          <Row className="align-items-md-center mt-30">
                            <Col className="inner-custom-pagination d-flex">
                              <div className="d-inline">
                                <SizePerPageDropdownStandalone
                                  {...paginationProps}
                                />
                              </div>
                              <div className="text-md-right ms-auto">
                                <PaginationListStandalone
                                  {...paginationProps}
                                />
                              </div>
                            </Col>
                          </Row>
                        </React.Fragment>
                      )
                      }
                    </ToolkitProvider>
                  )
                  }</PaginationProvider>
              </CardBody>
            </div>
          </Row>
  )
}
FlowsTable.propTypes = {
  t: PropTypes.any
};
export default withRouter(withTranslation()(FlowsTable));
//export default FlowsTable