import { takeLatest, put, call} from 'redux-saga/effects';
import { getAllCompaigns, checkSales, getAllMetrics,integrate,deleteIntegration,getAllFlows} from '../../queries/Klaviyo/Queries';
import { alert ,alert3,successAlert} from '../../pages/Alerts/NotLinkedAccountAlert';
import urls from '../../routes/apiUrls';
import ApolloClient from "apollo-boost";
import {
    CHECK_INTEGRATION_REQUEST_KLAVIYO,
    checkIntegrationSuccessKlaviyo,
    checkIntegrationFailureKlaviyo,
    FETCH_DATA_KLAVIYO_REQUEST,
    fetchDataklaviyoSuccess,
    fetchDataklaviyoFailure,
    FETCH_METRICS_KLAVIYO_REQUEST,
    fetchMetricsklaviyoSuccess,
    fetchMetricsklaviyoFailure,
    INTEGRATION_KLAVIYO_REQUEST,
    integrationklaviyoSuccess,
    integrationklaviyoFailure,
    DELETE_INTEGRATION_KLAVIYO_REQUEST,
    deleteIntegrationklaviyoSuccess,
    deleteIntegrationklaviyoFailure,
    FETCH_DATA_FLOW_REQUEST,
    fetchDataFlowSuccess,
    fetchDataFlowFailure,
  } from './action';


const ApploCLI = new ApolloClient({
    uri: urls.klaviyo,
  });

function* checkIntegrationKlaviyo() {
    try {
      const {data}= yield call(ApploCLI.query, {
        query: checkSales,
        context: {
          headers: {
            Authorization: window.localStorage.getItem('token')
              ? `Bearer ${window.localStorage.getItem('token')}`
              : '',
          }
        },
        errorPolicy: "all",
            fetchPolicy: "no-cache",
      })
      if(data.get == null){
        alert().then((data) => {
          window.location.replace('/integrations');
         });
          yield put (checkIntegrationFailureKlaviyo())
      }
          else{
            
            yield put(checkIntegrationSuccessKlaviyo(data.get,'active',data.get.apiKey));
           
          }
    } catch (error) {
    
      yield put(checkIntegrationFailureKlaviyo(error));
    
    } 
    }
function* getAllmetrics(action){
  try {
    let key = action.payload.Apikey
    const {data}= yield call(ApploCLI.query, {
      query: getAllMetrics,
      variables:
      {
        key: key
      },
      context: {
        headers: {
          Authorization: window.localStorage.getItem('token')
            ? `Bearer ${window.localStorage.getItem('token')}`
            : '',
        }
      },
    });
    if(data.getmetrics == null){
        alert3().then((data) => {
        
         });
          yield put (checkIntegrationFailureKlaviyo())
         
      
      }else{
        yield put(fetchMetricsklaviyoSuccess(data.getmetrics));
      }
     
    
    }catch (error) {
        yield put(fetchMetricsklaviyoFailure(error)); 
    }
}
function* FetchDataKlaviyo (action) {
  
try {
let key = action.payload.Apikey
const {data}= yield call(ApploCLI.query, {
  query: getAllCompaigns,
  variables:
  {
    key: key
  },
  context: {
    headers: {
      Authorization: window.localStorage.getItem('token')
        ? `Bearer ${window.localStorage.getItem('token')}`
        : '',
    }
  },
});
if(data.getcompaigns == null){
    alert3().then((data) => {
    
     });
      yield put (checkIntegrationFailureKlaviyo())
}else{
  yield put(fetchDataklaviyoSuccess(data.getcompaigns));
}
}catch (error) {
    yield put(fetchDataklaviyoFailure(error)); 
}


}
 function* integrateKlaviyo(action){
  try{
    const {data}= yield call(ApploCLI.mutate, {
      mutation: integrate,
      variables:
      {
        key: action.payload.apiKey,
      },
      context: {
        headers: {
          Authorization: window.localStorage.getItem('token')
            ? `Bearer ${window.localStorage.getItem('token')}`
            : '',
        }
      },
    });
    if (data.integration){
      yield put(integrationklaviyoSuccess(data));
      window.location.replace('/integrations')
    }else{
      yield put(integrationklaviyoFailure('error'));
      alert3().then((data) => {
        window.location.replace('/integrations')
      });
     

    }
   

  }catch (error) {
    yield put(integrationklaviyoFailure(error)); 
  }
 }

 function* getklaviyoflows (action) {
  let key = action.payload.Apikey
  try{
    const {data}= yield call(ApploCLI.query, {
      query: getAllFlows,
      variables:
      {
        key: key,
      },
      context: {
        headers: {
          Authorization: window.localStorage.getItem('token')
            ? `Bearer ${window.localStorage.getItem('token')}`
            : '',
        }
      },
    });
    console.log("dataaaaaaaaaaaaaaaaaa",data.getflows)
    if (data){
      
      yield put(fetchDataFlowSuccess(data.getflows));
    }else{
      yield put(fetchDataFlowFailure('error'));
    }


  }catch(error){
    yield put(fetchDataFlowFailure(error));
  }

 }
function* deleteIntegrationklaviyo(action){
  try{
    const {data}= yield call(ApploCLI.mutate, {
      mutation: deleteIntegration,
      context: {
        headers: {
          Authorization: window.localStorage.getItem('token')
            ? `Bearer ${window.localStorage.getItem('token')}`
            : '',
        }
      },
    });
    if (data){
      yield put(deleteIntegrationklaviyoSuccess(data));
      successAlert().then(() => {
        window.location.replace("/integrations");
      });
    
    }else{
      yield put(deleteIntegrationklaviyoFailure('error'));
      alert3().then((data) => {
        window.location.replace('/integrations')
      });
    }
  }catch(error){
    yield put(deleteIntegrationklaviyoFailure(error));
  }
}


    export default function* getKlaviyoSaga() {
        yield takeLatest(FETCH_METRICS_KLAVIYO_REQUEST, getAllmetrics);
        yield takeLatest(CHECK_INTEGRATION_REQUEST_KLAVIYO, checkIntegrationKlaviyo);
        yield takeLatest(FETCH_DATA_KLAVIYO_REQUEST, FetchDataKlaviyo);
        yield takeLatest(INTEGRATION_KLAVIYO_REQUEST, integrateKlaviyo);
        yield takeLatest(DELETE_INTEGRATION_KLAVIYO_REQUEST, deleteIntegrationklaviyo);
        yield takeLatest(FETCH_DATA_FLOW_REQUEST, getklaviyoflows);

      }