import React, { useEffect, useState, useRef } from 'react';
import { SearchOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import { Card, CardBody, Col, Row } from 'reactstrap';
import '../Integrations/styles.css';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { getFacebookCampaignsPerDate } from '../../store/facebookAds/actions';
import {
  Button,
  Space,
  Table,
  Menu,
  Dropdown,
  Input,
  Checkbox,
  Select,
  Tooltip,
} from 'antd';
import { Drawer } from 'antd';
import { Offcanvas } from 'react-bootstrap';
import FeatherIcon from 'feather-icons-react';
import { CloseOutlined } from '@ant-design/icons';
const FacebbokAdsIcon = `${process.env.PUBLIC_URL}/images/integrations_images/AD8.png`;
const Plus = `${process.env.PUBLIC_URL}/images/integrations_images/pluus.png`;

const datePattern = /(\d{1,2})\/(\d{1,2})\/(\d{4})/;

const FacebookAdsOverview = (props) => {
  const dispatch = useDispatch();
  const { metrcisbyCompaign } = useSelector((state) => state.facebookAds);
  let CPA, AOV, CR, ROAS, CPC, CTR;
  if (props.facebook) {
    CPA = props.facebook.totalCost / props.facebook.totalConversions;
    AOV = props.facebook.totalconversionValue / props.facebook.totalConversions;
    CR =
      (props.facebook.totalConversions / props.facebook.totalLandingPageViews) *
      100;
    ROAS = props.facebook.totalconversionValue / props.facebook.totalCost;
    CPC = props.facebook.totalCost / props.facebook.totalClicks;
    CTR = (props.facebook.totalClicks / props.facebook.totalImpressions) * 100;
  }
  // get  facebook campaign data
  useEffect(() => {
    var newStartDate = datePattern.exec(
      props.chosedDate[0].startDate &&
      props.chosedDate[0].startDate.toLocaleString('fr-FR')
    );
    var newEndDate = datePattern.exec(
      props.chosedDate[0].endDate &&
      props.chosedDate[0].endDate.toLocaleString('fr-FR')
    );
    if (newStartDate && newEndDate) {
      dispatch(
        getFacebookCampaignsPerDate(
          newStartDate[3] + '-' + newStartDate[2] + '-' + newStartDate[1],
          newEndDate[3] + '-' + newEndDate[2] + '-' + newEndDate[1]
        )
      );
    }
  }, [props.dateRage, props.chosedDate]);

  const [filteredInfo, setFilteredInfo] = useState({});
  const [sortedInfo, setSortedInfo] = useState({});
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const [paginationOptions, setPaginationOptions] = useState({
    pageSize: 5, // Default page size
    pageSizeOptions: ['5', '10', '20'], // Available page size options
  });
  const searchInput = useRef(null);
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: 'block',
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1677ff' : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: '#ffc069',
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });
  const handleChange = (pagination, filters, sorter) => {
    setFilteredInfo(filters);
    setSortedInfo(sorter);
    if (pagination.pageSize !== paginationOptions.pageSize) {
      setPaginationOptions({
        ...paginationOptions,
        pageSize: pagination.pageSize,
      });
    }
  };
  const clearFilters = () => {
    setFilteredInfo({});
  };
  const clearAll = () => {
    setFilteredInfo({});
    setSortedInfo({});
    // make the columns  selected
    setSelectedColumns(columns.map((column) => column.key));
  };
  const setAgeSort = () => {
    setSortedInfo({
      order: 'descend',
      columnKey: 'impressions',
    });
  };
  // Utility function to format numbers with spaces
  const formatNumberWithSpaces = (value) => {
    if (value !== undefined && value !== null) {
      return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
    }
    return ''; // or any default value you want to return for undefined/null cases
  };
  const columns = [
    {
      title: '#',
      dataIndex: 'id',
      key: 'id',
      render: (text, record, index) => <strong> {index + 1}</strong>,
      width: 50,
      align: 'center',
      filters: [
        {
          text: 'ENABLED',
          value: 'ACTIVE',
        },
        {
          text: 'PAUSED',
          value: 'PAUSED',
        },
      ],
      filteredValue: filteredInfo.id || null,
      onFilter: (value, record) => {
        if (value === 'ACTIVE') {
          return record.status === 'ACTIVE';
        } else if (value === 'PAUSED') {
          return record.status === 'PAUSED';
        }
        return true; // Return true if no filter is applied
      },
    },
    {
      title: props.t("Campaign Name"),
      dataIndex: 'campaign_name',
      key: 'campaign_name',
      align: 'center',
      width: 300,
      filteredValue: filteredInfo.campaign_name || null,
      onFilter: (value, record) => record.campaign_name.includes(value),
      ...getColumnSearchProps('campaign_name'),
      // sortOrder: sortedInfo.columnKey === 'name' ? sortedInfo.order : null,
      ellipsis: true,
    },
    {
      title: props.t("Spend"),
      dataIndex: 'spend',
      align: 'center',
      key: 'spend',
      sorter: (a, b) => a.spend - b.spend,
      sortOrder: sortedInfo.columnKey === 'spend' ? sortedInfo.order : null,
      ellipsis: true,
      render: (text) => <strong>{text.toFixed(2)}$</strong>,
    },
    {
      title: props.t("Revenue"),
      dataIndex: 'action_values',
      key: 'action_values',
      sorter: (a, b) => a.action_values - b.action_values,
      sortOrder:
        sortedInfo.columnKey === 'action_values' ? sortedInfo.order : null,
      ellipsis: true,
      align: 'center',
      render: (text) => <strong>{text.toFixed(2)}$</strong>,
    },
    {
      title: props.t("Conversions"),
      dataIndex: 'actions',
      align: 'center',
      key: 'actions',
      sorter: (a, b) => a.actions - b.actions,
      sortOrder: sortedInfo.columnKey === 'actions' ? sortedInfo.order : null,
      ellipsis: true,
      render: (text) => <strong>{text}</strong>,
    },
    {
      title: props.t("Impressions"),
      dataIndex: 'impressions',
      key: 'impressions',
      sorter: (a, b) => a.impressions - b.impressions,
      sortOrder:
        sortedInfo.columnKey === 'impressions' ? sortedInfo.order : null,
      ellipsis: true,
      align: 'center',
      render: (text) => <strong>{formatNumberWithSpaces(text)}</strong>,
    },
    {
      title: props.t("Clicks"),
      dataIndex: 'clicks',
      key: 'Clicks',
      sorter: (a, b) => a.clicks - b.clicks,
      sortOrder: sortedInfo.columnKey === 'clicks' ? sortedInfo.order : null,
      ellipsis: true,
      align: 'center',
      render: (text) => <strong>{formatNumberWithSpaces(text)}</strong>,
    },
    {
      title: props.t("Reach"),
      dataIndex: 'reach',
      key: 'reach',
      align: 'center',
      sorter: (a, b) => a.reach - b.reach,
      sortOrder: sortedInfo.columnKey === 'reach' ? sortedInfo.order : null,
      ellipsis: true,
      render: (text) => <strong>{formatNumberWithSpaces(text)}</strong>,
    },
    {
      title: 'CTR',
      dataIndex: 'ctr',
      align: 'center',
      key: 'ctr',
      sorter: (a, b) => a.clicks / a.impressions - b.clicks / b.impressions,
      sortOrder: sortedInfo.columnKey === 'ctr' ? sortedInfo.order : null,
      ellipsis: true,
      render: (text, record) => {
        const ctrValue = (record.clicks / record.impressions) * 100;
        return <strong>{isNaN(ctrValue) ? '0' : ctrValue.toFixed(2)}%</strong>;
      },
    },
    {
      title: 'CPA',
      dataIndex: 'cpa',
      align: 'center',
      key: 'cpa',
      sorter: (a, b) => a.cpa - b.cpa,
      sortOrder: sortedInfo.columnKey === 'cpa' ? sortedInfo.order : null,
      ellipsis: true,
      render: (text) => <strong>{text.toFixed(2)}$</strong>,
    },
    {
      title: 'CPC',
      dataIndex: 'cpc',
      key: 'cpc',
      align: 'center',
      sorter: (a, b) => a.cpc - b.cpc,
      sortOrder: sortedInfo.columnKey === 'cpc' ? sortedInfo.order : null,
      ellipsis: true,
      render: (text, record) => {
        const floatValue = parseFloat(text);
        return <strong>{isNaN(floatValue) ? '-' : `$${floatValue.toFixed(2)}`}</strong>;
      },
    },
    {
      title: props.t("PageView"),
      dataIndex: 'landingPageViewAction',
      align: 'center',
      key: 'landingPageViewAction',
      sorter: (a, b) => a.landingPageViewAction - b.landingPageViewAction,
      sortOrder:
        sortedInfo.columnKey === 'landingPageViewAction'
          ? sortedInfo.order
          : null,
      ellipsis: true,
      render: (text) => <strong>{text}</strong>,
    },
  ];
  const [selectedColumns, setSelectedColumns] = useState(
    columns.map((column) => column.key)
  );

  const handleMenuClick = (clickedKey) => {
    const index = selectedColumns.indexOf(clickedKey);

    let updatedSelectedColumns = [];

    if (index === -1) {
      // Column was not selected before, add it to the selected columns
      updatedSelectedColumns = [...selectedColumns, clickedKey];
    } else {
      // Column was selected before, remove it from the selected columns
      updatedSelectedColumns = selectedColumns.filter(
        (key) => key !== clickedKey
      );
    }

    setSelectedColumns(updatedSelectedColumns);
  };

  const filteredColumns = columns.filter((column) =>
    selectedColumns.includes(column.key)
  );

  const menu = (
    <Menu>
      {columns.map((column) => (
        <Menu.Item key={column.key} onClick={() => handleMenuClick(column.key)}>
          <Checkbox
            checked={selectedColumns.includes(column.key)}
            style={{ marginRight: '8px' }}
          />
          <span
            style={
              selectedColumns.includes(column.key) ? { fontWeight: 'bold' } : {}
            }
          >
            {column.title}
          </span>
        </Menu.Item>
      ))}
    </Menu>
  );

  //Cards
  const [selectedGroups, setSelectedGroups] = useState([null]);
const [hiddenCards, setHiddenCards] = useState([]);

const handleHideCard = (cardIndex) => {
  setHiddenCards((prevHidden) => [...prevHidden, cardIndex]);
};

const handleSelectGroup = (selectedKey, index) => {
  const updatedGroups = [...selectedGroups];
  const selectedOption = optionGroup.find((option) => option.key === selectedKey);
  updatedGroups[index] = selectedOption;
  setSelectedGroups(updatedGroups);
};
  const handlePlusClick = () => {
    setSelectedGroups((prevGroups) => [...prevGroups, null]);
  };

  const MetricCard = ({ selectedGroup, handleSelectGroup, checkboxState, cardIndex ,handleHideCard}) => {
    const isCardHidden = hiddenCards.includes(cardIndex);
    return (
      <Col xl={2} md={4}>
        <Card className="text-center">
          <CardBody className="facebook-ads-card">
            <div className="my-0 text-primary d-flex justify-content-between align-items-center">
              <Select
                placeholder={props.t("select metrics")}
                value={selectedGroups[cardIndex] ? selectedGroups[cardIndex].label : undefined}
                onChange={(selectedKey) => handleSelectGroup(selectedKey, cardIndex)}
                style={{
                  width: '70%',
                  borderColor: 'initial',
                  fontWeight: 'bold',
                  margin: 'auto',
                }}
                dropdownStyle={{ zIndex: 9999 }}
              >
                {optionGroup.map((option, index) => (
                  <Select.Option key={index} value={option.key}>
                    {option.label}
                  </Select.Option>
                ))}
              </Select>
              <div style={{ position: 'absolute', top: 5, right: 5, }}>
                <Tooltip title="Delete Metric">
                  <CloseOutlined onClick={() => handleHideCard(cardIndex)} style={{ cursor: 'pointer' }} />
                </Tooltip>
              </div>
            </div>
            {/* <h4>{selectedGroup ? selectedGroup.label : ''}</h4> */}
            <Row className="justify-content-center">
              <Col xs={12} className="d-flex justify-content-center">
                <div className="mt-3">
                <h4> {selectedGroup ? selectedGroup.value : ''} </h4>
                </div>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Col>
    );
  };

  // const [checkboxStates, setCheckboxStates] = useState({
  //   spendisChecked: false,
  //   RevenueisChecked: false,
  //   ConversionsisChecked: false,
  //   CPAisChecked: false,
  //   ImpressionsChecked: false,
  //   ReachChecked: false,
  //   ClicksChecked: false,
  //   CTRChecked: false,
  //   AOVChecked: false,
  //   CRChecked: false,
  //   ROASChecked: false,
  //   CPCChecked: false,
  // });




  const optionGroup = [
    {
      key: 'spend',
      label: 'Spend',
      value: props.facebook
        ? `$${formatNumberWithSpaces(props.facebook.totalCost.toFixed(2) || 0)}`
        : '$0',
    },
    {
      key: 'Revenue',
      label: 'Revenue',
      value: props.facebook
        ? `$${formatNumberWithSpaces(
          props.facebook.totalconversionValue.toFixed(2) || 0
        )}`
        : '0',
    },

    {
      key: 'Clicks',
      label: 'Clicks',
      value: props.facebook
        ? formatNumberWithSpaces(props.facebook.totalClicks || 0)
        : '0',
    },
    {
      key: 'Conversions',
      label: 'Conversions',
      value: props.facebook
        ? formatNumberWithSpaces(props.facebook.totalConversions || 0)
        : '0',
    },
    {
      key: 'Impressions',
      label: 'Impressions',
      value: props.facebook
        ? formatNumberWithSpaces(props.facebook.totalImpressions || 0)
        : '0',
    },
    {

      key: 'Reach',
      label: 'Reach',
      value: props.facebook
        ? formatNumberWithSpaces(props.facebook.totalReach || 0)
        : '0',
    },
    {
      key: 'CPA',
      label: 'CPA',
      value: props.facebook && isFinite(CPA)
        ? `$${CPA.toFixed(2)}`
        : '$0',
    },
    {
      key: 'CPC',
      label: 'CPC',
      value: props.facebook && isNaN(CPC) == false
        ? `$${CPC.toFixed(2)}`
        : '$0',
    },
    {
      key: 'AOV',
      label: 'AOV',
      value: props.facebook && isNaN(AOV) == false
        ? `$${AOV.toFixed(2)}`
        : '$0',
    },
    {
      key: 'CR',
      label: 'CR%',
      value: props.facebook && isNaN(CR) == false
        ? `${CR.toFixed(2)}%`
        : '0',
    },
    {
      key: 'ROAS',
      label: 'ROAS',
      value: props.facebook
        ? `${ROAS.toFixed(2)}`
        : '0',
    },
  ];

  const PlusCard = ({ onClick }) => {
    return (
      <Col xl={2} md={2}>
        <form>
          <Card className="dropzone custom-hover cursor-pointer" onClick={onClick} style={{ height: '97px'}}>
            <CardBody className="text-center d-flex align-items-center justify-content-center">
              <img className="img-overview-dashboard ms-2" src={Plus} />
            </CardBody>
          </Card>
        </form>
      </Col>
    );
  };
  // function handleSelectGroup(selectedKey) {
  //   // Find the full option object based on the selected key
  //   const selectedOption = optionGroup.find(option => option.key === selectedKey);
  //   // console.log(selectedOption.value); // This will log the full object of the selected option
  //   // If you have a state setter function to update the selected group, you can call it here
  //   setselectedGroup(selectedOption.value);
  // }
  // function handleSelectGroup2(selectedKey) {
  //   // Find the full option object based on the selected key
  //   const selectedOption = optionGroup.find(option => option.key === selectedKey);
  //   //  console.log(selectedOption.value); // This will log the full object of the selected option
  //   // If you have a state setter function to update the selected group, you can call it here
  //   setselectedGroup2(selectedOption.value);
  // }

  // function handleSelectGroup3(selectedKey) {
  //   // Find the full option object based on the selected key
  //   const selectedOption = optionGroup.find(option => option.key === selectedKey);
  //   console.log(selectedOption.value); // This will log the full object of the selected option
  //   // If you have a state setter function to update the selected group, you can call it here
  //   setselectedGroup3(selectedOption.value);
  // }
  // function handleSelectGroup4(selectedKey) {
  //   // Find the full option object based on the selected key
  //   const selectedOption = optionGroup.find(option => option.key === selectedKey);
  //   //  console.log(selectedOption.value); // This will log the full object of the selected option
  //   // If you have a state setter function to update the selected group, you can call it here
  //   setselectedGroup4(selectedOption.value);
  // }





  return (
    <React.Fragment>
      <Col xl={12}>
        <div className="d-flex   mb-1">



        </div>
        <div className="row mb-4">
          <div className="col">
            <h5 className="card-title d-flex align-items-center">
              <img className="img-overview-dashboard ms-2" src={FacebbokAdsIcon} style={{ marginTop: '-40px', height: '40px' }} />
              <h5 className="card-title ms-2" style={{ marginTop: '-33px' }}>{props.t("Facebook Ads performance")}</h5>

            </h5>
          </div>
        </div>
        <Row>
          <Row className="justify-content-center">
          {selectedGroups.map((selectedGroup, index) => (
              !hiddenCards.includes(index) && (
              
              <MetricCard
                key={index}
                selectedGroup={selectedGroup}
                handleSelectGroup={(selectedKey) => handleSelectGroup(selectedKey, index)}
                //checkboxState={checkboxStates}
                cardIndex={index}
                handleHideCard={handleHideCard}
              />
              )
            ))}
             <PlusCard onClick={handlePlusClick} />
          </Row>
        </Row>
        <hr />
        <Row>
          <Card style={{ border: 'none' }}>
            <CardBody>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
              >
                <h4 className="card-title mb-4">
                  {props.t('Facebook Ads Campaigns')}
                </h4>
                <img className="img-intgration-icon" src={FacebbokAdsIcon} />
              </div>
              <Space
                style={{
                  marginBottom: 16,
                }}
              >
                <Button onClick={setAgeSort}>{props.t("Sort Impressions")}</Button>
                <Button onClick={clearFilters}>{props.t("Clear filters")}</Button>
                <Button onClick={clearAll}>{props.t("Clear filters and sorters")}</Button>
                <Dropdown overlay={menu} trigger={['click']}>
                  <Button>{props.t("Select Columns")}</Button>
                </Dropdown>
              </Space>
              <Table
                columns={filteredColumns}
                bordered
                dataSource={metrcisbyCompaign}
                onChange={handleChange}
                pagination={{
                  ...paginationOptions,
                  onChange: handleChange,
                  showSizeChanger: true, // Enable the page size selector
                  // showTotal: (total) => `Total ${total} items`, // Optionally show total items
                  // Other pagination settings
                }}
                scroll={{ y: 700 }}
              />
            </CardBody>
          </Card>
        </Row>
        <div className="mt-2-overview-dashboard">
          <Link to="/ads-dashboard" className="btn btn-primary btn-sm">
            {props.t('View more')}
            <i className="overview-dashboard-icon mdi mdi-arrow-right ms-1"></i>
          </Link>
        </div>
      </Col>
    </React.Fragment>
  );
};
FacebookAdsOverview.propTypes = {
  t: PropTypes.any,
};
export default withRouter(withTranslation()(FacebookAdsOverview));
