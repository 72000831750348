import React, { useState, useEffect, useRef } from 'react';
import MetaTags from 'react-meta-tags';
import './costs.css'
import ApolloClient from 'apollo-boost';
import { withTranslation } from "react-i18next"
import { withRouter } from "react-router-dom"
import { Container, Row, Col, FormGroup, InputGroup, Table } from 'reactstrap'
import AddNewCost from './AddNewCost';
import { getCosts } from '../../queries/Costs/Costs';
import ListCost from './ListCosts';
import urls from '../../routes/apiUrls';
import Breadcrumbs from "../../components/Common/Breadcrumb";


import PropTypes from 'prop-types';

const ApploCLI = new ApolloClient({
    uri: urls.cost
});
function Costs(props) {
    const [costs, setCosts] = useState([]);
    const [Canceledit, setCanceEdit] = useState(false);
    const [verifAddCost, setVerifAddCost] = useState(false);
    const [loadingState, setLoadingState] = useState(true);
    const wrapperRef = useRef(null);
    useEffect(() => {
        let isSubscribed = true
        setLoadingState(false);
        ApploCLI.query({
            query: getCosts,
            context: {
                headers: {
                    Authorization: window.localStorage.getItem("token") ? `Bearer ${window.localStorage.getItem("token")}` : ""
                }
            },
            errorPolicy: "all",
            fetchPolicy: "no-cache" //allow me to refecth data 
        }).then((data) => {
            if (isSubscribed) {
                if (data.data.all.length > 0)
                    setCosts(data.data.all);
                setLoadingState(true);
            }
        });
        return () => (isSubscribed = false)
    }, [verifAddCost, Canceledit])
    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title> PrestaShop Dashboard | Convergen-Metrics - E-com SaaS</title>
                </MetaTags>
                <Container fluid>
                    {<Breadcrumbs title={props.t("Costs")} breadcrumbItem={props.t("Miscellaneous Costs")} />}
                    <Row>
                        <Col md={3}>
                            <FormGroup className="mb-4">
                                <InputGroup>
                                    <div className="DateContainer">
                                        <div>
                                            <div className="divDateContainer" >
                                                <div ref={wrapperRef}>
                                                   <button disabled={!loadingState} className="btn btn-primary w-50 waves-effect waves-light" size='medium' onClick={() => { setVerifAddCost(true)}}>{props.t("+ Add Cost")}</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </InputGroup>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        {
                            loadingState ?
                                (
                                    <Table className="table table-striped table-bordered" >
                                        <thead>
                                            <tr>
                                                <th width={1}>{props.t("title")}</th>
                                                <th width={1}>{props.t("Category")}</th>
                                                <th width={1}>{props.t("Frequency")}</th>
                                                <th width={1}>{props.t("Date")}</th>
                                                <th width={1}>{props.t("Ended Date")}</th>
                                                <th width={1}>{props.t("Price")}</th>
                                                <th width={1}>{props.t("Action")}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {verifAddCost ? (
                                                <AddNewCost addCost={setVerifAddCost} />
                                            ) : null}
                                            {
                                                costs.map((element, index) => (
                                                    <ListCost element={element} index={index} key={index} />
                                                ))
                                            }
                                        </tbody>
                                    </Table>)
                                :
                                <div style={{ backgroundColor: 'white', borderRadius: "10px", padding: "10px" }}>
                                </div>
                        }
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}
Costs.propTypes = {
    t: PropTypes.any
  };
export default withRouter(withTranslation() (Costs));
