import React, { useState } from 'react'
import ApolloClient from 'apollo-boost';
import { toggleFbSocialMediaAccount } from '../../../queries/FacebookSocialMedia/queries';
import { Spinner } from 'reactstrap';
import urls from '../../../routes/apiUrls';

const ApploCLI = new ApolloClient({
  uri: urls.facebookSocialMedia
});
function FacebookSocialMediaAccount(props) {
    const [isActive,setIsActive] = useState(props.element.active?true:false);
    const [loading, setLoading] = useState(false);
    const ActiveAdAccount = (fbAccountId) => {
        setLoading(true)
        ApploCLI.mutate({mutation:toggleFbSocialMediaAccount,
          variables: {
            platform_id:props.info.id,
            fb_page_id:fbAccountId
            },
            context:{
                headers:{
                    Authorization:window.localStorage.getItem("token")?`Bearer ${window.localStorage.getItem("token")}`:""
                }
            },
            errorPolicy:'all',
        }).then((data,err)=>{
          setLoading(false)
          if (data.data.toggle) {
            window.location.replace('/integrations')
          }
        })
    };
    return (
        <tr className="">
            <td className="">{props.element.name}</td>
            <td className='text-center'>
                {
                    loading?
                    <Spinner className="ms-2" color="secondary" />
                    :
                <div className="form-check form-switch mb-2" dir="ltr">
                    <input type="checkbox" className="form-check-input" checked={isActive} id="customSwitch1" onChange={(e)=>{ActiveAdAccount(props.element.fb_page_id); setIsActive(e.target.checked);}} />
                    <label className="form-check-label" htmlFor="customSwitch1" checked={isActive}></label>
                </div>
                }
            </td>
        </tr>
    )
}
export default FacebookSocialMediaAccount;