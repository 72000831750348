import {
  EDIT_PROFILE_REQUEST,
  EDIT_PROFILE_SUCCESS,
  EDIT_PROFILE_FAILURE,
  RESET_PASSWORD_REQUEST,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAILURE,
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  IS_CONNECTED_REQUEST,
  IS_CONNECTED_SUCCESS,
  IS_CONNECTED_FAILURE,
  REGISTER_REQUEST,
  REGISTER_SUCCESS,
  REGISTER_FAILURE,
  GET_USER_INFO_REQUEST,
  GET_USER_INFO_SUCCESS,
  GET_USER_INFO_FAILURE,
  FORGET_PASSWORD_REQUEST,
  FORGET_PASSWORD_SUCCESS,
  FORGET_PASSWORD_FAILURE,
  LOGIN_WITH_GOOGLE_FAILURE,
  LOGIN_WITH_GOOGLE_REQUEST,
  LOGIN_WITH_GOOGLE_SUCCESS,
  REGISTER_WITH_GOOGLE_FAILURE,
  REGISTER_WITH_GOOGLE_SUCCESS,
  REGISTER_WITH_GOOGLE_REQUEST,
  VERIF_CODE_REQUEST,
  VERIF_CODE_SUCCESS,
  VERIF_CODE_FAILURE,
  IS_VERIFIED_SUCCESS,
  IS_VERIFIED_REQUEST,
  IS_VERIFIED_FAILURE,
  REFRESH_TOKEN_REQUEST,
  REFRESH_TOKEN_SUCCESS,
  REFRESH_TOKEN_FAILURE,
  LOGOUT_REQUEST,
  LOGOUT_SUCCESS,
  LOGOUT_FAILURE,
} from './actions';

const initialState = {
  success: false,
  error: false,
  Userupdated: [],
  data: [],
  isConnected: false,
  isVerified: false,
  loading: false,
  user: [],
};

export default function editProfile(state = initialState, action) {
  switch (action.type) {
    case GET_USER_INFO_REQUEST:
      return {
        ...state,
        error: null,
      };
    case GET_USER_INFO_SUCCESS:
      return {
        ...state,
        user: action.payload,
      };
    case GET_USER_INFO_FAILURE:
      return {
        ...state,
        error: true,
      };
    case EDIT_PROFILE_REQUEST:
      return {
        ...state,
        error: null,
      };
    case EDIT_PROFILE_SUCCESS:
      return {
        ...state,
        Userupdated: action.payload,
        success: true,
        error: false,
      };
    case EDIT_PROFILE_FAILURE:
      return {
        ...state,
        error: true,
        success: false,
      };
    case RESET_PASSWORD_REQUEST:
      return {
        ...state,
        error: null,
      };
    case RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        success: true,
        error: false,
      };
    case RESET_PASSWORD_FAILURE:
      return {
        ...state,
        error: true,
        success: false,
      };
    case FORGET_PASSWORD_REQUEST:
      return {
        ...state,
        error: null,
      };
    case FORGET_PASSWORD_SUCCESS:
      return {
        ...state,
        success: true,
        error: false,
      };
    case FORGET_PASSWORD_FAILURE:
      return {
        ...state,
        error: true,
        success: false,
      };
    case LOGIN_REQUEST:
      return {
        ...state,
        error: null,
      };
    case LOGIN_SUCCESS:
      return {
        ...state,
        data: action.payload,
      };
    case LOGIN_FAILURE:
      return {
        ...state,
        error: true,
      };
    case LOGIN_WITH_GOOGLE_REQUEST:
      return {
        ...state,
        error: null,
      };
    case LOGIN_WITH_GOOGLE_SUCCESS:
      return {
        ...state,
        data: action.payload,
      };
    case LOGIN_WITH_GOOGLE_FAILURE:
      return {
        ...state,
        error: true,
      };
    case IS_CONNECTED_REQUEST:
      return {
        ...state,
        error: null,
      };
    case IS_CONNECTED_SUCCESS:
      return {
        ...state,
        isConnected: true,
        data: action.payload,
      };
    case IS_CONNECTED_FAILURE:
      return {
        ...state,
        isConnected: false,
      };
    case LOGOUT_REQUEST:
      return {
        ...state,
        error: null,
      };
    case LOGOUT_SUCCESS:
      return {
        ...state,
        isConnected: true,
        data: action.payload,
      };
    case LOGOUT_FAILURE:
      return {
        ...state,
        isConnected: false,
      };
    case IS_VERIFIED_REQUEST:
      return {
        ...state,
        error: null,
        loading: true,
      };
    case IS_VERIFIED_SUCCESS:
      return {
        ...state,
        isVerified: true,
        data: action.payload,
        error: false,
        loading: false,
      };
    case IS_VERIFIED_FAILURE:
      return {
        ...state,
        isVerified: false,
        error: true,
        loading: false,
      };
    case REGISTER_REQUEST:
      return {
        ...state,
        error: null,
      };
    case REGISTER_SUCCESS:
      return {
        ...state,
        data: action.payload,
      };
    case REGISTER_FAILURE:
      return {
        ...state,
        error: true,
      };

    case REGISTER_WITH_GOOGLE_REQUEST:
      return {
        ...state,
        error: null,
      };
    case REGISTER_WITH_GOOGLE_SUCCESS:
      return {
        ...state,
        data: action.payload,
      };
    case REGISTER_WITH_GOOGLE_FAILURE:
      return {
        ...state,
        error: true,
      };

    case VERIF_CODE_REQUEST:
      return {
        ...state,
        error: null,
      };
    case VERIF_CODE_SUCCESS:
      return {
        ...state,
        data: action.payload,
      };
    case VERIF_CODE_FAILURE:
      return {
        ...state,
        error: true,
      };
    case REFRESH_TOKEN_REQUEST:
      return {
        ...state,
        error: null,
      };
    case REFRESH_TOKEN_SUCCESS:
      return {
        ...state,
        data: action.payload,
      };
    case REFRESH_TOKEN_FAILURE:
      return {
        ...state,
        error: true,
      };

    default:
      return state;
  }
}
