import React, {  useState } from 'react';
import { Table, Icon, Input } from 'semantic-ui-react'
import FeatherIcon from "feather-icons-react";
import { withRouter} from "react-router-dom"
import { withTranslation } from "react-i18next"

function Recommendation(props) {
  const [changeRate, setChangeRate] = 
    useState({
      Rating: parseFloat
        (((props.info.aov / props.info.total_orders) - (props.info.costGoods + props.info.merchantFees + props.info.shippingFees + (props.info.refunds / props.info.total_orders) + (props.info.cps / props.info.total_orders))) / (props.info.aov / props.info.total_orders) * 100)
    })
  var roas = props.info.cps !== 0 ? ((props.info.aov / props.info.cps) * 0.1).toFixed(3) : 0;
  const [isInput, setisInput] = useState(false)
  var finalAov = 0
  var finalCpp = 0
  var finalAcpo = 0
  var finalROAS = 0
  var finalROASBasedOnCpp = 0
  var p = props.info.aov / props.info.total_orders;
  var c1 = (props.info.cps / props.info.total_orders);
  var refunds = props.info.refunds / props.info.total_orders;
  var c2 = (props.info.costGoods + props.info.merchantFees + props.info.shippingFees + refunds)
  var originalMargin = ((p - c1 - c2) / p) * 100;
  if (changeRate && !Number.isNaN(changeRate.Rating)) {
    var margin = changeRate.Rating;
    if (originalMargin == margin) {
      finalAov = p
      finalCpp = c1
      finalAcpo = c2
      finalROAS = roas
      finalROASBasedOnCpp = roas
    } else {
      finalAov = ((100 * (c1 + c2)) / (100 - changeRate.Rating));
      finalCpp = ((p * (1 - (changeRate.Rating / 100))) - c2);
      finalAcpo = ((p * (1 - (changeRate.Rating / 100))) - c1);
      finalROAS = c1 !== 0 ? (100 / c1) * ((100 * (c1 + c2)) / (100 - (changeRate.Rating))) : 0
      finalROASBasedOnCpp = (100 * p) / (((p / 100) * (100 - changeRate.Rating)) - c2)
    }
  }
  const handleChangeSlider = (e) => {
    setChangeRate({ Rating: e.target.value })
    setisInput(false);
  }
  const handleChangeInput = (e) => setChangeRate({ Rating: e.target.value })
  return (
    <div>
      <div style={{ marginTop: "20px", textAlign: "center" }}>
        <div style={{ textAlign: "center", marginBottom: "10px" }}>
          <div style={{ display: 'flex', justifyContent: "center", alignItems: "center" }}>
            {
              !isInput ?
                <span className="spanNum" style={{ cursor: 'pointer' }} onClick={() => { setisInput(true) }}>
                  {` ${parseFloat(changeRate.Rating).toFixed(3)}%`}
                </span>
                :
                <Input icon='percent' placeholder='Margin...' value={changeRate.Rating} size='small' onChange={(e) => { handleChangeInput(e) }} />
            }
          </div>
        </div>
        <input
          type='range'
          min={0}
          max={100}
          step={0.01}
          value={changeRate.Rating}
          onChange={handleChangeSlider}
          style={{ width: "80%" , margin:"20px",position:"relative",background:"#e6e6e6", color:"#3B5B98" }}

        />
        <h3>{props.t("Adjust Your Profit Margin")}</h3>
        <p>{props.t("Your Current Margin")} {(((props.info.aov / props.info.total_orders) - ((props.info.refunds / props.info.total_orders) + props.info.costGoods + props.info.merchantFees + props.info.shippingFees + (props.info.cps / props.info.total_orders))) / (props.info.aov / props.info.total_orders) * 100).toFixed(3)}%</p>
      </div>
      <div className="either">
        <h5>{props.t("You can either")}</h5>
        <Table className="table table-striped table-bordered">
          <Table.Body>
            <Table.Row>
              <Table.Cell></Table.Cell>
              <Table.Cell></Table.Cell>
              <Table.Cell></Table.Cell>
              <Table.Cell textAlign='center'><b>ROAS</b></Table.Cell>
            </Table.Row>
            <Table.Row >
              <Table.Cell style={{ display: 'flex', alignItems: 'center' }}>{finalAov > p ?
                <React.Fragment><FeatherIcon icon="arrow-up-circle" color="green" style={{ fontSize: '20px'}} /><span>{props.t("Increase")}</span></React.Fragment>
                :
                finalAov.toFixed(3) == p.toFixed(3) ?
                  <React.Fragment><FeatherIcon icon="minus" color="grey"  /><span></span></React.Fragment>
                  :
                  <React.Fragment><FeatherIcon icon="arrow-down-circle" color="red" style={{ fontSize: '20px' }} /><span>{props.t("Decrease")}</span></React.Fragment>
              }
              </Table.Cell>
              <Table.Cell>{props.t("Your")} <b>AOV</b> {props.t("to")} :</Table.Cell>
              <Table.Cell>{window.localStorage.getItem('currency') === "CAD" ? "C" : window.localStorage.getItem('currency') === "AUD" ? "A" : ""}{finalAov ?
                finalAov.toFixed(3)
                : 0}
              </Table.Cell>
              <Table.Cell textAlign='center'><Icon name='percent' />{parseFloat(finalROAS).toFixed(3)}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell width='3'></Table.Cell>
              <Table.Cell width='5'></Table.Cell>
              <Table.Cell textAlign='left'><span style={{ color: '#202020', fontWeight: 'bold', opacity: '80%', marginLeft: '-10%' }}>{props.t("OR")}</span></Table.Cell>
              <Table.Cell></Table.Cell>
            </Table.Row>
            <Table.Row >
              <Table.Cell style={{ display: 'flex', alignItems: 'center' }}>{
                finalAcpo.toFixed(3) == c2.toFixed(3) ?
                  <React.Fragment><Icon name="minus" color="grey" size="small" /><span></span></React.Fragment>
                  :
                  finalAcpo > c2 ?
                    <React.Fragment><FeatherIcon icon="arrow-up-circle" color="green" style={{ fontSize: '20px' }} /><span>{props.t("Increase")}</span></React.Fragment>
                    :
                    <React.Fragment><FeatherIcon icon="arrow-down-circle" color="red" style={{ fontSize: '20px' }} /><span>{props.t("Decrease")}</span></React.Fragment>
              }</Table.Cell>
              <Table.Cell>{props.t("Your")}<b>ACPO</b> {props.t("to")} :</Table.Cell>
              <Table.Cell>{window.localStorage.getItem('currency') === "CAD" ? "C" : window.localStorage.getItem('currency') === "AUD" ? "A" : ""}{finalAcpo ? finalAcpo.toFixed(3) : 0}</Table.Cell>
              <Table.Cell textAlign='center'><FeatherIcon icon="minus" color="grey" style={{ fontSize: '20px' }} /></Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell width='3'></Table.Cell>
              <Table.Cell width='5'></Table.Cell>
              <Table.Cell textAlign='left'><span style={{ color: '#202020', fontWeight: 'bold', opacity: '80%', marginLeft: '-10%' }}>{props.t("OR")}</span></Table.Cell>
              <Table.Cell></Table.Cell>
            </Table.Row>
            <Table.Row >
              <Table.Cell style={{ display: 'flex', alignItems: 'center' }}>{finalCpp > c1 ?
                <React.Fragment><FeatherIcon icon="arrow-up-circle" color="green" style={{ fontSize: '20px' }} /><span>{props.t("Increase")}</span></React.Fragment>
                :
                finalCpp.toFixed(3) == c1.toFixed(3) ?
                  <React.Fragment><FeatherIcon icon="minus" color="grey" style={{ fontSize: '20px' }}/><span></span></React.Fragment>
                  :
                  <React.Fragment><FeatherIcon icon="arrow-down-circle" color="red" style={{ fontSize: '20px' }} /><span>{props.t("Decrease")}</span></React.Fragment>
              }</Table.Cell>
              <Table.Cell >{props.t("Your")} <b>Blended CPP</b> {props.t("to")} :</Table.Cell>
              <Table.Cell>{window.localStorage.getItem('currency') === "CAD" ? "C" : window.localStorage.getItem('currency') === "AUD" ? "A" : ""}{finalCpp.toFixed(3)}</Table.Cell>
              <Table.Cell textAlign='center'><FeatherIcon icon='percent'style={{ fontSize: '5px' }} />{parseFloat(finalROASBasedOnCpp).toFixed(3)}</Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>
      </div>
    </div>
  )
}
export default withRouter(withTranslation()(Recommendation));
