import React from 'react'
import { Tr, Td } from "react-super-responsive-table"

function SingleCoupon(props){
    let classes = props.element.coupon.free_shipping ? "badge-soft-success text-success" : "badge-soft-danger text-danger";
    let classes_individual_use = props.element.coupon.individual_use ? "badge-soft-success text-success" : "badge-soft-danger text-danger";
    return (
        <Tr className="">
            <Td className="">{props.element.coupon.id}</Td>
            <Td className="">{props.element.coupon.code}</Td>
            <Td className="">{props.element.coupon.amount}</Td>
            <Td className="">{props.element.coupon.usage_count}</Td>
            <Td className=""> <span style={{fontSize:"14px"}} className={`badge ${classes}`}>{ props.element.coupon.free_shipping ? "True" : "False"}</span></Td>
            <Td className=""> <span style={{fontSize:"14px"}} className={`badge ${classes_individual_use}`}>{ props.element.coupon.individual_use ? "True" : "False"}</span></Td>   
        </Tr>
    )
}
export default SingleCoupon;