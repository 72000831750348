import React, { useState, useEffect } from 'react';
import { Card, CardBody, Col, Container, FormGroup, Row, Table, Input } from "reactstrap";
import {
  generateUrl,
  getGooglePlatform,
  getAccounts,
  integrate,
  deleteGoogle
} from '../../../queries/GoogleAnalytics/Queries';
import ApolloClient from "apollo-boost";
import GoogleAnalyticsAccountsList from "./GoogleAnalyticsAccountsList";
import urls from "../../../routes/apiUrls";
import { Tbody, Th, Thead, Tr } from 'react-super-responsive-table';
import GoogleAnalyticsProfileAccount from './GoogleAnalyticsProfileAccount';
import { integrateGoogleAnalyticsRequest } from '../../../store/googleAnalytics/actions';
import { useDispatch, useSelector } from 'react-redux';
import  GoogleAnalyticsIcon from  "../GoogleAnalytics/GG Analytics.png";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";

const ApploCLI = new ApolloClient({
  uri: urls.analytics,
});

function GoogleAnalyticsCard(props) {

  const dispatch = useDispatch();

  const [step, setStep] = useState(0);
  const [url, setUrl] = useState("");
  const [loading, setLoading] = useState(false);
  const [account, setAccount] = useState(null);
  const [accounts, setAccounts] = useState([]);
  const [reachedFinalStep, setReachedFinalStep] = useState(false);
  const [filterStr, setFilterStr] = useState("");
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [refetch, setRefetch] = useState(false);
  const [activeAccount, setActiveAccount] = useState({});

  ApploCLI.query({
    query: getGooglePlatform,
    context: {
      headers: {
        Authorization: window.localStorage.getItem("token")
          ? `Bearer ${window.localStorage.getItem("token")}`
          : "",
      },
    },
    errorPolicy: "all",
    fetchPolicy: "network-only",
  }).then((data) => {
    //console.log('test', data)
    if (data.data.get === null) {
      generateUrlFx();
      setStep(1);
    } else {
      if (data.data.get && data.data.get.type != 4) {
        setStep(2);
        setReachedFinalStep(false);
      }
    }
  });
  const generateUrlFx = () => {
    ApploCLI.query({
      query: generateUrl,
      context: {
        headers: {
          Authorization: window.localStorage.getItem("token")
            ? `Bearer ${window.localStorage.getItem("token")}`
            : "",
        },
      },
      errorPolicy: "all",
      fetchPolicy: "network-only",
    }).then((data) => {
      if (data.data.url) {
        setUrl(data.data.url.url);
      }
    });
  };
  useEffect(() => {
    ApploCLI.query({
      query: getAccounts,
      context: {
        headers: {
          Authorization: window.localStorage.getItem("token")
            ? `Bearer ${window.localStorage.getItem("token")}`
            : "",
        },
      },
      errorPolicy: "all",
      fetchPolicy: "network-only",
    }).then((data) => {
      //console.log(data.data.accounts);
      if (data.data.accounts) {
        let active_account = data.data.accounts.filter((x) => { if (x.active === true) return x })
        if (active_account.length > 0) {
          setActiveAccount(active_account);
          setStep(3)
        } else {
          setAccounts(data.data.accounts)
          setRefetch(!refetch);
          setStep(2)
        }
      }
    });
  }, [reachedFinalStep]);
  const integrateGoogle = async (google_code) => {
  dispatch(integrateGoogleAnalyticsRequest(google_code))
  window.location.reload('/integrations');
  };

  const openPopup = () => {
    setLoading(true);
    let windowObjectReference = null;
    let previousUrl = null;
    let interval = null;
    const strWindowFeatures =
      "toolbar=no, menubar=no, width=600, height=700, top=100, left=100";
    if (windowObjectReference === null || windowObjectReference.closed) {
      windowObjectReference = window.open(url, "", strWindowFeatures);
      var timer = setInterval(function () {
        if (windowObjectReference.closed) {
          setLoading(false);
          clearInterval(timer);
        }
      }, 1000);
    } else if (previousUrl !== url) {
      windowObjectReference = window.open(url, "", strWindowFeatures);
      windowObjectReference.focus();
      var timer = setInterval(function () {
        if (windowObjectReference.closed) {
          setLoading(false);
          clearInterval(timer);
        }
      }, 1000);
    } else {
      windowObjectReference.focus();
      var timer = setInterval(function () {
        if (windowObjectReference.closed) {
          setLoading(false);
          clearInterval(timer);
        }
      }, 1000);
    }
    interval = window.setInterval(() => {
      try {
        let temp = windowObjectReference.location;
        //console.log(temp)
        if (
          temp.hostname === 'localhost' || temp.hostname === "app.live-metrics.io"
        ) {
          window.clearInterval(interval);
          let code = temp.href.split("?")[1].split("&")[0].split("=")[1];
          integrateGoogle(code);
          windowObjectReference.close();
          setLoading(false);
          setStep(2);
          setReachedFinalStep(true);
        }
      } catch (error) {
        console.log(error);
      }
    }, 1000);
    previousUrl = url;
  };
  const filteredAccounts = accounts.filter((e) =>
    e.name.toUpperCase().includes(filterStr.toUpperCase())
  );
  const deleteConnection = () => {
    setDeleteLoading(true);
    ApploCLI.mutate({
      mutation: deleteGoogle,
      context: {
        headers: {
          Authorization: window.localStorage.getItem("token")
            ? `Bearer ${window.localStorage.getItem("token")}`
            : "",
        },
      },
      errorPolicy: "all",
    }).then((data) => {
      if (data && data.data && data.data.delete && data.data.delete.isDeleted) {
        setDeleteLoading(false);
        setAccount(null);
        setStep(1);
      }
    });
  };
  const firstStep = () => {
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Row>
              <Col>
                <Card className="border text-secondary">
                  <CardBody>
                    <div className="my-0 text-primary d-flex justify-content-between align-items-center">
                      <div className='text-left d-flex align-items-center'>
                        <img className="img-intgration-icon" src={GoogleAnalyticsIcon} />
                        <span className="text-primary h4 mb-0 ml-2">Google Analytics</span>
                      </div>
                      <button
                        onClick={() => { openPopup() }}
                        className="btn btn-primary"
                      >{props.t("Connect")}</button>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  };
  const secondStep = () => {
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Row>
              <Col>
                <Card className="border text-secondary">
                  <div className="card-header bg-transparent border-secondary">
                    <div className="my-0 text-primary d-flex justify-content-between align-items-center"><h5 className="my-0 text-primary">Google Analytics </h5><img className="img-intgration-icon" src={GoogleAnalyticsIcon} /></div>
                  </div>
                  <FormGroup className="mb-3">
                    <div style={{ textAlign: "center", padding: "20px" }}>
                      {accounts && accounts.length > 0 ? (
                        <div>
                          <form className="app-search d-none d-lg-block">
                            <div className="position-relative">
                              <Input
                                size="small"
                                onChange={(e) => setFilterStr(e.target.value)}
                                icon={<img name="search" link />}
                                placeholder={props.t("Search...")}
                                style={{ float: "right", marginBottom: "20px" }}
                              />
                              <button className="btn btn-primary" type="button"><i
                                className="bx bx-search-alt align-middle"></i></button>
                            </div>
                          </form>
                          <Table
                            id="tech-companies-1"
                            className="table table-striped table-bordered"
                          >
                            <Thead>
                              <Tr>
                                <Th data-priority="1">{props.t("Account Id")}</Th>
                                <Th data-priority="2">{props.t("Account Name")}</Th>
                                <Th data-priority="3">{props.t("Active")}</Th>
                              </Tr>
                            </Thead>
                            <Tbody>
                              {filteredAccounts.map((element, index) => (
                                <GoogleAnalyticsAccountsList element={element} key={index} />
                              ))}
                            </Tbody>
                          </Table>
                        </div>
                      ) : (
                        <div style={{ display: "grid" }}>
                          <div>
                            <h3>{props.t("No Google Data")}</h3>
                            <small>{props.t("Tryagain")}</small>
                          </div>
                        </div>
                      )}
                      {deleteLoading ? (
                        <img style={{ float: 'left', margin: 10 }} name="notched circle loading icon" />
                      ) : (
                        <div className='text-center'>
                          <button type="button" className="btn btn-danger waves-effect waves-light" onClick={() => deleteConnection()}>
                            <i className="bx bx-block font-size-16 align-middle"></i> {props.t("Delete Connection")}
                          </button>
                        </div>
                      )}
                    </div>
                  </FormGroup>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  };
  const thirdStep = () => {
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Row>
              <Col>
                <Card className="border text-secondary">
                  <div className="card-header bg-transparent text-secondary">
                    <div className="my-0 text-primary d-flex justify-content-between align-items-center"><h5 className="my-0 text-primary">Google Analytics </h5><img className="img-intgration-icon" src={GoogleAnalyticsIcon} /></div>
                  </div>
                  <CardBody>
                    <Table id="tech-companies-1" className="table table-striped table-bordered" >
                      <Thead>
                        <Tr>
                          <Th data-priority="1">{props.t("Account Id")}</Th>
                          <Th data-priority="2">{props.t("Account Name")}</Th>
                          <Th data-priority="3">{props.t("Profile Account")}</Th>
                          <Th data-priority="4">{props.t("Active")}</Th>
                        </Tr>
                      </Thead>
                      <Tbody>
                        {activeAccount.map((element, index) => (
                          <GoogleAnalyticsProfileAccount element={element} key={index} />
                        ))}
                      </Tbody>
                    </Table>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  };
  const renderSections = () => {
    switch (step) {
      case 3:
        return thirdStep();
      case 1:
        return firstStep();
      case 2:
        return secondStep();
      default:
        return firstStep();
    }
  };
  return <div>{renderSections()}</div>;
}
GoogleAnalyticsCard.propTypes = {
  t: PropTypes.any
};

export default withRouter(withTranslation()(GoogleAnalyticsCard));
//export default GoogleAnalyticsCard;