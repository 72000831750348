import React, { useState } from 'react'
import ApolloClient from 'apollo-boost';
import { toggleGoogle } from '../../../queries/Google/googleQueries';
import urls from '../../../routes/apiUrls';

const ApploCLI = new ApolloClient({
    uri: urls.google
});
function GoogleAdAccountsList(props) {
    console.log("active ? ")
    console.log(props.element.active)
    const [isActive, setIsActive] = useState(props.element.active ? true : false);
    
    const toggleCampaign = (adAccountId) => {
     
        ApploCLI.mutate({
            mutation: toggleGoogle,
            variables: {
                adAccount: adAccountId
            },
            context:{
                headers:{
                    Authorization:window.localStorage.getItem("token")?`Bearer ${window.localStorage.getItem("token")}`:""
                }
            },
        }).then((data, err) => {
            if (data.data.toggle) {
                
                setIsActive(data.data.toggle.active);
                window.location.reload();
                localStorage.setItem("googleads_customer_currency_code", data.data.toggle.customer_currency_code);
                if(data.data.toggle.active===false){
                   localStorage.removeItem("google_currency_revenue_rate")
                }
            }
        }).catch((err) => {
            console.error("Error", err);
        })
    }
    return (
        <tr className="">
            <td className="">{props.element.customer_name}</td>
            <td className='text-center'>
                {
                    <div className="form-check form-switch mb-2" dir="ltr">
                        <input type="checkbox" className="form-check-input" checked={isActive} id="customSwitch1" onChange={(e, data) => { toggleCampaign(props.element.id); setIsActive(!isActive) }} />
                        <label className="form-check-label" htmlFor="customSwitch1" checked={isActive}></label>
                    </div>
                }
            </td>
        </tr>
    )
}
export default GoogleAdAccountsList