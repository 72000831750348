import React from 'react';
import { Card } from "semantic-ui-react";
import { Row, Col, CardBody} from "reactstrap"
const GoogleAnalyticsIcon = `${process.env.PUBLIC_URL}/images/integrations_images/GoogleAnalytics.svg`;

function GoogleAnalyticsUsersDataCard({ loading, item }) {
    let getTotal = (array) => {
        return array.map(Number).reduce((x, y) => { return x + y });
    }
    let getTotalFixed = (array) =>{
        return array.map(Number).reduce((x, y) => { return x + y }).toFixed(3);
    }
    return (
        <div className="dashboardMinorCardItem">
            {
                (item.name === 'Users' || item.name === 'users') &&
                <Card className="card-h-100">
                    <CardBody>
                        <div className="my-0 text-primary d-flex justify-content-between align-items-center">{item.name}<img className="img-intgration-icon" src={GoogleAnalyticsIcon} /></div>
                        <Row className="align-items-center">
                            <Col xs={6}>
                                <div className="mb-3">
                                    {
                                        loading ?
                                            <h4>{item.series[0].data.length > 0 && getTotal(item.series[0].data)}</h4>
                                            : ""
                                    }
                                </div>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            }
            {
                (item.name === 'New Users' || item.name === 'newUsers') &&
                <Card className="card-h-100">
                    <CardBody>
                        <div className="my-0 text-primary d-flex justify-content-between align-items-center">{item.name}<img className="img-intgration-icon" src={GoogleAnalyticsIcon} /></div>
                        <Row className="align-items-center">
                            <Col xs={6}>
                                <div className="mb-3">
                                    {
                                        loading ?
                                            <h4>{item.series[0].data.length > 0 && getTotal(item.series[0].data)}</h4>
                                            : ""
                                    }
                                </div>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            }
            {
                (item.name === 'Sessions Per User' || item.name === 'sessionsPerUser') &&
                <Card className="card-h-100">
                    <CardBody>
                        <div className="my-0 text-primary d-flex justify-content-between align-items-center">{item.name}<img className="img-intgration-icon" src={GoogleAnalyticsIcon} /></div>
                        <Row className="align-items-center">
                            <Col xs={6}>
                                <div className="mb-3">
                                    {
                                        loading ?
                                            <h4>{item.series[0].data.length > 0 && getTotalFixed(item.series[0].data)}</h4>
                                            : ""
                                    }    
                                </div>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            }
            {
                (item.name === 'Percent New Sessions' || item.name === 'percentNewSessions') &&
                <Card className="card-h-100">
                    <CardBody>
                        <div className="my-0 text-primary d-flex justify-content-between align-items-center">{item.name}<img className="img-intgration-icon" src={GoogleAnalyticsIcon} /></div>
                        <Row className="align-items-center">
                            <Col xs={6}>
                                <div className="mb-3">
                                    {
                                        loading ?
                                            <h4>{item.series[0].data.length > 0 && getTotal(item.series[0].data)}</h4>
                                            : ""
                                    }
                                </div>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            }
        </div >
    )
}
export default GoogleAnalyticsUsersDataCard;
