import PropTypes from 'prop-types';
import React, { useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
//import drawer
import ReactDrawer from 'react-drawer';
import 'react-drawer/lib/react-drawer.css';
//Import Icons
import FeatherIcon from "feather-icons-react";
// Import menuDropdown
import LanguageDropdown from "../CommonForBoth/TopbarDropdown/LanguageDropdown";
import ProfileMenu from "../CommonForBoth/TopbarDropdown/ProfileMenu";
import whitelogo from "../../assets/images/Live_Metrics_White_Logo.png"

// import images
import livemetrics from "../../assets/images/Live_Metrics_Logo.png"
import iconlivemetrics from "../../assets/images/Live_Metrics_favicon.svg"
import whiteicon from "../../assets/images/white_Live_Metrics_favicon.svg"
import LightDark from "../CommonForBoth/Menus/LightDark"
//i18n
import { withTranslation } from "react-i18next";
// Toggle full screen 
import HeaderFullScreenToggle from './FullScreenToggle';

// Redux Store
import {
  showRightSidebarAction,
  toggleLeftmenu,
  changeSidebarType,
  changelayoutMode
} from "../../store/actions";
import CalendarComponentt from './CalendarComponent';
const Header = props => {
  const { onChangeLayoutMode } = props;
  const [search, setsearch] = useState(false);
  const [isClick, setClick] = useState(true);
  const [position, setPosition] = useState();
  const [open, setOpen] = useState(false);

  const onDrawerClose = () => {
    setOpen(false);
  };


  /*** Sidebar menu icon and default menu set */
  function tToggle() {
    var body = document.body;
    setClick(!isClick);
    if (isClick === true) {
      body.classList.add("sidebar-enable");
      document.body.setAttribute('data-sidebar-size', 'sm');
    } else {
      body.classList.remove("sidebar-enable");
      document.body.setAttribute('data-sidebar-size', 'lg');
    }
  }
  const [showCalendar, setShowCalendar] = useState(false);

  const toggleCalendar = () => {
    setShowCalendar(!showCalendar);
  };
  return (
    <React.Fragment>
      <header id="page-topbar">
        <div className="navbar-header">
          <div className="d-flex">
            <div className="navbar-brand-box">
              <Link to="/overview-dashboard" className="logo logo-dark">
                <span className="logo-sm">
                  <img src={iconlivemetrics} alt="" height="25" />
                </span>
                <span className="logo-lg">
                  <img src={livemetrics} alt="" height="27" />
                </span>
              </Link>

              <Link to="/prestashop-dashboard" className="logo logo-light">
                <span className="logo-sm">
                  <img src={whiteicon} alt="" height="25" />
                </span>
                <span className="logo-lg">
                  <img src={whitelogo} alt="" height="27" />
                </span>
              </Link>
            </div>

            <button
              onClick={() => {
                tToggle();
              }}
              type="button"
              className="btn btn-sm px-3 font-size-16 header-item"
              id="vertical-menu-btn"
            >
              <i className="fa fa-fw fa-bars"></i>
            </button>

            {/* <form className="app-search d-none d-lg-block px-5">
              <div className="position-relative">
                <input
                  type="text"
                  className="form-control"
                  placeholder={props.t("Search") + "..."}
                />
                <button
                  id="btn1"
                  className="btn btn-primary"
                  type="button"
                >
                  <i style={{ color: 'white' }} className="bx bx-search-alt align-middle"></i>
                </button>
              </div>
            </form> */}
          </div>


          <div className="d-flex">
            <div className="dropdown d-inline-block d-lg-none ms-2">
              <button
                type="button"
                className="btn header-item"
                id="page-header-search-dropdown"
                data-bs-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <FeatherIcon icon="search" className="icon-lg" />
              </button>

              <div
                className="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0"
                aria-labelledby="page-header-search-dropdown"
              >
                <form className="p-3">
                  <div className="form-group m-0">
                    <div className="input-group">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Search ..."
                        aria-label="Search Result"
                      />
                      <button
                        className="btn btn-primary"
                        type="submit"
                      >
                        <i className="mdi mdi-magnify"></i>
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>

          <div className="d-flex">
            
          

            {/* <CalendarComponentt  /> */}
            
          

            {/* light / dark mode */}
            <LightDark
              layoutMode={props['layoutMode']}
              onChangeLayoutMode={onChangeLayoutMode}
            />

            <HeaderFullScreenToggle />
            <LanguageDropdown />
            <ProfileMenu />
          </div>
        </div>
      </header>
      <ReactDrawer open={open} position={position} onClose={onDrawerClose}>
      </ReactDrawer>
    </React.Fragment>
  );
};

Header.propTypes = {
  changeSidebarType: PropTypes.func,
  leftMenu: PropTypes.any,
  showRightSidebar: PropTypes.any,
  showRightSidebarAction: PropTypes.func,
  t: PropTypes.any,
  toggleLeftmenu: PropTypes.func,
  changelayoutMode: PropTypes.func,
  layoutMode: PropTypes.any,
};

const mapStatetoProps = state => {
  const {
    layoutType,
    showRightSidebar,
    leftMenu,
    layoutMode
  } = state.Layout;
  return { layoutType, showRightSidebar, leftMenu, layoutMode };
};

export default connect(mapStatetoProps, {
  showRightSidebarAction,
  changelayoutMode,
  toggleLeftmenu,
  changeSidebarType,
})(withTranslation()(Header));