 import{
    GET_MEMBER_REQUEST,
    GET_MEMBER_SUCCESS,
    GET_MEMBER_FAILURE,
    REST_GET_MEMBER_REQUEST,
    REST_GET_MEMBER_SUCCESS,
    REST_GET_MEMBER_FAILURE,
    ADD_MEMBER_REQUEST,
    ADD_MEMBER_SUCCESS,
    ADD_MEMBER_FAILURE,
    EDIT_MEMBER_REQUEST,
    EDIT_MEMBER_SUCCESS,
    EDIT_MEMBER_FAILURE,
    DELETE_MEMBER_REQUEST,
    DELETE_MEMBER_SUCCESS,
    DELETE_MEMBER_FAILURE,
 } from './action'

  const initialState = {
    members: [],
    error: false,
    loadingState: true,
    successState: false,
    failuresate: false,
    isDeleted: false,
  };


   export default function member(state = initialState, action) {

    switch (action.type) {
        //get member
      case GET_MEMBER_REQUEST:
        return {
          ...state,
          loadingState: false,
        };
      case GET_MEMBER_SUCCESS:
        return {
          ...state,
          members: action.payload.members,
          loadingState: true,
        };
      case GET_MEMBER_FAILURE:
        return {
          ...state,
          error: action.payload.error,

        };
        //rest get member
        case REST_GET_MEMBER_REQUEST:
        return {
            ...state,

        };
        case REST_GET_MEMBER_SUCCESS:
        return {
            ...state,
            members: action.payload.members,
        };
        case REST_GET_MEMBER_FAILURE:
        return {
            ...state,
            error: action.payload.error,
        };
        //add member
        case ADD_MEMBER_REQUEST:
        return {
            ...state,
        };
        case ADD_MEMBER_SUCCESS:
        return {
            ...state,
            members: action.payload,
            successState: true,
        };
        case ADD_MEMBER_FAILURE:
        return {
            ...state,
            error: action.payload.error.graphQLErrors[0].message,
            failuresate : true,
        };
        //edit member
        case EDIT_MEMBER_REQUEST:
        return {
            ...state,
            loadingState: false,
        };
        case EDIT_MEMBER_SUCCESS:
        return {
            ...state,
            members: action.payload,
            loadingState: false,
            successState: true,
        };
        case EDIT_MEMBER_FAILURE:
        return {
            ...state,
            error: action.payload.error,
            failuresate : true,
        };
        //delete member
        case DELETE_MEMBER_REQUEST:
        return {
            ...state,
            isDeleted: true,
          }
        case DELETE_MEMBER_SUCCESS:
        return {
            ...state,
            members: action.payload,
            successState: true,
          }
        case DELETE_MEMBER_FAILURE:
        return {
            ...state,
            error: action.payload.error,
            failuresate : true,
          };
      default:
        return state;
    }

   }