import React, { useState, useEffect, useRef } from 'react';
import { Row, Col, CardBody, Card, Container } from "reactstrap";
import ApolloClient from 'apollo-boost';
import urls from '../../routes/apiUrls';
import MetaTags from 'react-meta-tags';
import Breadcrumbs from '../../components/Common/Breadcrumb';
import FeatherIcon from "feather-icons-react";
import { getfbPagePosts, getfbPageData, getfbPageAudience } from '../../queries/FacebookSocialMedia/queries';
import secureSection from '../../MangeSecurity/MemberSecurity';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import BootstrapTable from 'react-bootstrap-table-next';
import { Pie, Bar } from "react-chartjs-2"
import logo from '../../assets/images/Live_Metrics_Logo.png';
import paginationFactory, {
  PaginationProvider, PaginationListStandalone,
  SizePerPageDropdownStandalone
} from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { Button, Space, Table, Menu, Dropdown,Checkbox } from 'antd';
//const FacebookSocialMediaIcon = `${process.env.PUBLIC_URL}/images/integrations_images/facebook-social-media.png`;
const FacebookSocialMediaIcon = `${process.env.PUBLIC_URL}/images/integrations_images/fb1.svg`;
const ApolloCliGetfbSocialMedia = new ApolloClient({
  uri: urls.facebookSocialMedia,
});


function FbSocialMediaDashboard(props) {
secureSection();

  const exportToPDF = async () => {
    try {
      const capture = document.querySelector('.print');
      const canvas = await html2canvas(capture, { useCORS: true, scale: 3 }); // Adjust scale for higher resolution
      const imgData = canvas.toDataURL('image/png', 5.0); // Adjust quality
      const doc = new jsPDF('p', 'mm', 'a4');
      const componentWidth = doc.internal.pageSize.getWidth();
      const componentHeight = doc.internal.pageSize.getHeight();
      const padding = 10; // Adjust this value to set the padding in millimeters
      // Add padding to X and Y coordinates
      doc.addImage(
        imgData,
        'PNG',
        1 + padding,
        padding,
        componentWidth - 2 * padding,
        componentHeight - 2 * padding
      );
      // // Customize the appearance of the PDF
      // doc.setLineWidth(0.5); // Set line width for borders
      // doc.setDrawColor(103, 137, 242); // Set border color to black
      // doc.rect(1, 1, componentWidth - 1, componentHeight - 1, 'S'); // Add a border around the content
      doc.save('Rapport-Instagram-Ads.pdf');
    } catch (error) {
      console.error('Error exporting to PDF:', error);
    }
  };
  const { SearchBar } = Search;
  const [loadingState, setLoadingState] = useState(false);
  
  const [facebookAudience, setFacebookAudience] = useState([]);
  const [facebookPagePost, setFacebookPagePost] = useState([]);
  const [facebookPageData, setFacebookPageData] = useState([]);
  const [engageduser, setEngageduser] = useState("");
  const [likes_numbre, setLikes_numbre] = useState("");
const [post_impressions, setpost_impressions] = useState("");
  const [post_engagements, setpost_engagements] = useState("");
  const [followers, setFollowers] = useState("");
  const [monthly_impressions, setMonthly_impressions] = useState("");
  const [page_fans_country, setPage_fans_country] = useState([]);
  const [page_fans_city, setPage_fans_city] = useState([]);
  const [fans_gender_age, setFans_gender_age] = useState([]);
const [page_impressions_paid,setpage_impressions_paid]=useState([]);
const [page_posts_impressions_paid,setpage_posts_impressions_paid]=useState([]);
  const [selectedImage, setSelectedImage] = useState(null);
  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      // You can use FileReader to display the selected image
      const reader = new FileReader();
      reader.onload = (event) => {
        setSelectedImage(event.target.result);
      };
      reader.readAsDataURL(file);
    }
  };
  // GET FACEBOOK SOCIAL MEDIA AUDIENCE 
  useEffect(() => {
    ApolloCliGetfbSocialMedia.query({
      query: getfbPageAudience,

      context: {
        headers: {
          Authorization: window.localStorage.getItem('token')
            ? `Bearer ${window.localStorage.getItem('token')}`
            : '',
        },
      },
      errorPolicy: 'all',
      fetchPolicy: 'no-cache', //allow me to refecth data
    }).then((res) => {
      if (res.data.getfbPageAudience && res.data.getfbPageAudience.length > 0) {
        setFacebookAudience(res.data.getfbPageAudience);
        const firstAudience = res.data.getfbPageAudience[0];

        if (firstAudience.page_fans_country) {
          setPage_fans_country(firstAudience.page_fans_country);
        }
        if (firstAudience.page_fans_city) {
          setPage_fans_city(firstAudience.page_fans_city);
        }
        if (firstAudience.fans_gender_age) {
          setFans_gender_age(firstAudience.fans_gender_age);
        }
      }
    });
  }, []);
  // Calculate sums by gender
  const sums = {
    Female: 0,
    Male: 0,
    Under: 0,
  };
  let data = null;
  // console.log("fans_gender_age", fans_gender_age);
  if (fans_gender_age && Array.isArray(fans_gender_age) && fans_gender_age.length > 0) {
    data = fans_gender_age[0];

    for (const key in data) {
      if (key.startsWith("F.")) {
        sums.Female += data[key];
      } else if (key.startsWith("M.")) {
        sums.Male += data[key];
      } else if (key.startsWith("U.")) {
        sums.Under += data[key];
      }
    }
  } else {
    // Handle the case when fans_gender_age is null, not an array, or empty
    console.log('The fans_gender_age array is empty, null, or not an array.');
  }

  const totalSum = sums.Female + sums.Male + sums.Under;
  const femalePercentage = ((sums.Female / totalSum) * 100).toFixed(2);
  const malePercentage = ((sums.Male / totalSum) * 100).toFixed(2);
  const underPercentage = ((sums.Under / totalSum) * 100).toFixed(2);

  // CREATE THE CHART DATA 
  const dataChart = {
    labels: ["Female", "Male", "Unkown"],
    datasets: [
      {
        data: [sums.Female, sums.Male, sums.Under],
        backgroundColor: ["rgb(42, 181, 125)", "rgb(59, 91, 152)", "rgb(75, 166, 239)"],
        hoverBorderColor: "#fff",
      },
    ],
  }

  // Extract unique age groups from the keys
  let ageGroups = [];

  // Checking if fans_gender_age is an array and has elements
  if (Array.isArray(fans_gender_age) && fans_gender_age.length > 0 && fans_gender_age[0]) {
    ageGroups = Array.from(
      new Set(
        Object.keys(fans_gender_age[0])
          .filter((key) => key.startsWith("F.") || key.startsWith("M.") || key.startsWith("U."))
          .map((key) => key.split(".")[1].trim())
      )
    );
  } else {
    console.log('fans_gender_age is null, an empty array, or does not contain valid elements.');
  }

  // Calculate sums by age and gender
  const sumsByAgeAndGender = ageGroups.map((ageGroup) => {
    const ageKey = `F.${ageGroup}`;
    return {
      ageGroup,
      Female: fans_gender_age[0][ageKey] || 0,
      Male: fans_gender_age[0][`M.${ageGroup}`] || 0,
      Under: fans_gender_age[0][`U.${ageGroup}`] || 0,
    };
  });
  for (const key in data) {
    const gender = key[0]; // First character represents gender (F, M, U)
    const ageGroup = key.replace(/[A-Z]\./, "").trim();

    const entry = sumsByAgeAndGender.find((item) => item.ageGroup === ageGroup);
    if (entry) {
      entry[gender] += data[key];
    }
  }
  
  // Create the chart data
  const chartData = {
    labels: ageGroups,
    datasets: [
      {
        label: props.t("Female"),
        data: sumsByAgeAndGender.map((entry) => entry.Female),
        backgroundColor: "rgb(42, 181, 125)", // Female
      },
      {
        label: props.t("Male"),
        data: sumsByAgeAndGender.map((entry) => entry.Male),
        backgroundColor: "rgb(59, 91, 152)", // Male
      },
      {
        label: props.t("Unkown"),
        data: sumsByAgeAndGender.map((entry) => entry.Under),
        backgroundColor: "rgb(75, 166, 239)", // Under
      },
    ],
  };

  // GET ALL PAGE POST DATA 
  useEffect(() => {
    ApolloCliGetfbSocialMedia.query({
      query: getfbPagePosts,

      context: {
        headers: {
          Authorization: window.localStorage.getItem('token')
            ? `Bearer ${window.localStorage.getItem('token')}`
            : '',
        },
      },
      errorPolicy: 'all',
      fetchPolicy: 'no-cache', //allow me to refecth data
    }).then((res) => {
      setFacebookPagePost(res.data.getfbPagePosts);

    });
  }, []);
  // GET PAGE DATA 
  useEffect(() => {
    ApolloCliGetfbSocialMedia.query({
      query: getfbPageData,

      context: {
        headers: {
          Authorization: window.localStorage.getItem('token')
            ? `Bearer ${window.localStorage.getItem('token')}`
            : '',
        },
      },
      errorPolicy: 'all',
      fetchPolicy: 'no-cache', //allow me to refecth data
    }).then((res) => {
      if (res.data.getfbPageData.length > 0) {
        setFacebookPageData(res.data.getfbPageData);
        setEngageduser(res.data.getfbPageData[0].engageduser);
        setLikes_numbre(res.data.getfbPageData[0].likes_numbre);
        setFollowers(res.data.getfbPageData[0].followers);
        setpost_engagements(res.data.getfbPageData[0].post_engagements);
        setpost_impressions(res.data.getfbPageData[0].post_impressions);
        //setShares(res.data.getfbPageData[0].Shares);
        setMonthly_impressions(res.data.getfbPageData[0].monthly_impressions);
        setpage_impressions_paid(res.data.getfbPageData[0].page_impressions_paid);
        setpage_posts_impressions_paid(res.data.getfbPageData[0].page_posts_impressions_paid); 

      }
    });
  }, []);
  // test ant table
  const [filteredInfo, setFilteredInfo] = useState({});
  const [sortedInfo, setSortedInfo] = useState({});

  const [paginationOptions, setPaginationOptions] = useState({
    pageSize: 5, // Default page size
    pageSizeOptions: ['5', '10', '20'], // Available page size options
  });
  const handleChange = (pagination, filters, sorter) => {
    setFilteredInfo(filters);
    setSortedInfo(sorter);
    if (pagination.pageSize !== paginationOptions.pageSize) {
      setPaginationOptions({
        ...paginationOptions,
        pageSize: pagination.pageSize,
      });
    }
  };
  // const clearFilters = () => {
  //   setFilteredInfo({});
  // };
  const clearAll = () => {
    //setFilteredInfo({});
    setSortedInfo({});
    // make the columns  selected
    setSelectedColumns(columns.map((column) => column.key));
  };
  const setAgeSort = () => {
    setSortedInfo({
      order: 'descend',
      columnKey: 'post_impressions',
    });
  };
  const pageSizeOptions = ['10', '20', '30'];
  const columns = [
    
    {
      title: 'full picture',
      dataIndex: 'full_picture',
      key: 'full_picture',
      align: 'center',

      // sortOrder: sortedInfo.columnKey === 'name' ? sortedInfo.order : null,
      ellipsis: true,
      render: (text, record) => {
        if (!text) {
          return (
            <a href={record.permalink_url} target="_blank" rel="noopener noreferrer">
            <span>{' There is Non-image '}</span>
            </a>
          );
        } else {
          return (
            <a href={record.permalink_url} target="_blank" rel="noopener noreferrer">
              <img
                src={text}
                alt={`Full Picture ${record.full_picture}`}
                style={{ maxWidth: '100px', maxHeight: '90px' }}
              />
            </a>
          );
        }
      }
    },
    // {
    //   title: 'Captions',
    //   dataIndex: 'message',
    //   key: 'message',
    //   ellipsis: true,
    //   width: 150,
    //   align: 'center',
    //   render: (text) => <strong>{text}</strong>,
    // },
    {
      title: 'Post clicks',
      dataIndex: 'post_clicks',
      align: 'center',
      key: 'post_clicks',
      sorter: (a, b) => a.post_clicks - b.post_clicks,
      sortOrder: sortedInfo.columnKey === 'post_clicks' ? sortedInfo.order : null,
      ellipsis: true,
      render: (text) => <strong>{formatNumberWithSpaces(text)}</strong>,
    },
    {
      title: ' Post likes',
      dataIndex: 'post_likes',
      key: 'post_likes',
      align: 'center',
      sorter: (a, b) => a.post_likes - b.post_likes,
      sortOrder:
        sortedInfo.columnKey === 'post_likes' ? sortedInfo.order : null,
      ellipsis: true,
      render: (text) => <strong>{formatNumberWithSpaces(text)}</strong>,
    },
    {
      title: 'Post Total Reactions ',
      dataIndex: 'postReactionsTotal',
      key: 'postReactionsTotal',
      align: 'center',
      sorter: (a, b) => a.postReactionsTotal - b.postReactionsTotal,
      sortOrder:
        sortedInfo.columnKey === 'postReactionsTotal' ? sortedInfo.order : null,
      ellipsis: true,
      render: (text) => <strong>{formatNumberWithSpaces(text)}</strong>,
    },
    {
      title: 'Post impressions',
      dataIndex: 'post_impressions',
      align: 'center',
      key: 'post_impressions',
      onFilter: (value, record) => record.address.includes(value),
      sorter: (a, b) => a.post_impressions - b.post_impressions,
      sortOrder: sortedInfo.columnKey === 'post_impressions' ? sortedInfo.order : null,
      ellipsis: true,
      render: (text) => <strong>{formatNumberWithSpaces(text)}</strong>,
    },
    { 
      title: 'Post Engagements',
      dataIndex: 'post_engagements',
      key: 'post_engagements',
      align: 'center',
      sorter: (a, b) => a.post_engagements - b.post_engagements,
      sortOrder: sortedInfo.columnKey === 'post_engagements' ? sortedInfo.order : null,
      ellipsis: true,
      render: (text) => <strong>{formatNumberWithSpaces(text)}</strong>,

    },
    { 
      title: 'Impressions Paid',
      dataIndex: 'post_impressions_paid',
      key: 'post_impressions_paid',
      align: 'center',
      sorter: (a, b) => a.post_impressions_paid - b.post_impressions_paid,
      sortOrder: sortedInfo.columnKey === 'post_impressions_paid' ? sortedInfo.order : null,
      ellipsis: true,
      render: (text) => <strong>{formatNumberWithSpaces(text)}</strong>,

    },
    {
      title: 'Negative Feedback',
      dataIndex: 'post_negative_feedback',
      key: 'post_negative_feedback',
      align: 'center',
      sorter: (a, b) => a.post_negative_feedback - b.post_negative_feedback,
      sortOrder: sortedInfo.columnKey === 'post_negative_feedback' ? sortedInfo.order : null,
      ellipsis: true,
      render: (text) => <strong>{formatNumberWithSpaces(text)}</strong>,
    },
    {
      title: 'Shares',
      dataIndex: 'shares',
      key: 'shares',
      align: 'center',
      sorter: (a, b) => a.shares - b.shares,
      sortOrder: sortedInfo.columnKey === 'shares' ? sortedInfo.order : null,
      ellipsis: true,
      render: (text) => <strong>{formatNumberWithSpaces(text)}</strong>,

    },
    {
      title: 'Created At',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (text, record, index) => {
        const formattedDate = text ? new Date(text).toLocaleDateString() : '';
        return <strong>{formattedDate}</strong>;
      },
      // width: 50,
      align: 'center',
    },
  ];
  const [selectedColumns, setSelectedColumns] = useState(
    columns.map((column) => column.key)
  );

  const handleMenuClick = (clickedKey) => {
    const index = selectedColumns.indexOf(clickedKey);

    let updatedSelectedColumns = [];

    if (index === -1) {
      // Column was not selected before, add it to the selected columns
      updatedSelectedColumns = [...selectedColumns, clickedKey];
    } else {
      // Column was selected before, remove it from the selected columns
      updatedSelectedColumns = selectedColumns.filter(
        (key) => key !== clickedKey
      );
    }

    setSelectedColumns(updatedSelectedColumns);
  };

  const filteredColumns = columns.filter((column) =>
    selectedColumns.includes(column.key)
  );

  const menu = (
    <Menu>
    {columns.map((column) => (
      <Menu.Item
        key={column.key}
        onClick={() => handleMenuClick(column.key)}
      >
        <Checkbox
          checked={selectedColumns.includes(column.key)}
          style={{ marginRight: '8px' }}
        />
        <span
          style={
            selectedColumns.includes(column.key)
              ? { fontWeight: 'bold' }
              : {}
          }
        >
          {column.title}
        </span>
      </Menu.Item>
    ))}
  </Menu>
  );

  const formatNumberWithSpaces = (value) => {
    if (value !== undefined && value !== null) {
      return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    }
    return ""; // or any default value you want to return for undefined/null cases
  };
  const [secCards, setsecCards] = useState([
    {id: 1, name: "Engaged User",},
    { id: 2, name: "Likes", },
    { id: 3, name: "Followers", },
    { id: 8, name: "Page Posts Impressions Paid",},
    { id: 4, name: "Monthly Impressions", },
    { id: 5, name: "Post Engagements", },
    { id: 6, name: "Post Impressions", },
    { id: 7, name: "Page Impressions Paid",},
   
  ]);
  useEffect(() => {
    let isSubscribed = true;
    setsecCards([
      {
        id: 1, name: "Engaged User",


      },
      { id: 8, name: "Page Posts Impressions Paid", },
      { id: 2, name: "Likes", },
      { id: 3, name: "Followers", },
      { id: 4, name: "Monthly Impressions", },
      { id: 5, name: "Post Engagements", },
      { id: 6, name: "Post Impressions", },
      { id: 7, name: "Page Impressions Paid", },
     
    ])
    return () => (isSubscribed = false);
  }, [engageduser, likes_numbre, followers, monthly_impressions, post_impressions,post_engagements,page_impressions_paid,page_posts_impressions_paid]);
  return (
    <Row>
      <div className="page-content" >
        <MetaTags>
          <title>{props.t("Facebook Social Media Dashboard")} | Convergen-Metrics - E-com SaaS</title>
        </MetaTags>
        

          <Breadcrumbs
            title={props.t("Social Media Dashboard")}
            breadcrumbItem={props.t("Facebook Social Media Dashboard")}
            // pageHeading={props.t("Facebook Social Media Dashboard")}
          />
          <Container fluid className="print">
          <Card className='tamour'>
            <CardBody className="print">
              <div>
                <div className="invoice-title">
                  <div className="d-flex align-items-start">
                    <div className="flex-grow-1">
                      <div className="">
                        <img src={logo} alt="" height="27" />
                      </div>
                    </div>

                    <div className="flex-shrink-0">
                      <div className="">
                        <h4 className="float-end font-size-16">
                          {props.t("Facebook Social Media Report")}
                        </h4>
                      </div>
                    </div>
                  </div>
                  <div className="d-flex align-items-center justify-content-between">
                    <div className="flex-grow-1">
                      <div className="">
                    
                     
                      </div>
                    </div>
                    <div className="flex-grow-1">
                      <img
                        src={facebookPageData[0]?.picture}
                        alt=""
                        height="90"
                        width="90"
                        style={{ borderRadius: '50%'}}
                      />
                    </div>
                    <div className="flex-shrink-0">
                      <div>
                        <address>
                          <br />
                          <FeatherIcon icon="calendar" />
                          <button type="button" className="btn btn-soft-secondary btn-sm">
                            {props.t("Last 30 days")}
                          </button>
                          <button type="button" className="btn btn-soft-secondary btn-sm">
                            {props.t("Last 7 days")}
                          </button>
                          <button type="button" className="btn btn-soft-secondary btn-sm">
                            {props.t("All time")}
                          </button>
                        </address>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              </CardBody>
          </Card>



              <Row >
                <Col lg="12">
                <Row className='justify-content-center no-gutters'>
                    {/* First row: Engaged User, Likes, Followers */}
                    {secCards.slice(0,4).map(item => (
                      <div className="dashboardMinorCardItem" key={item.name}>
                        {item.name === 'Engaged User' &&
                          <Card className="card-h-100">
                            <CardBody>
                              <div className="my-0 text-primary d-flex justify-content-between align-items-center">{props.t(item.name)}
                                {item.name === "Engaged User" && (
                                  <img className="img-intgration-icon" src={FacebookSocialMediaIcon} />
                                )}
                              </div>
                              <Row className="align-items-center">
                                <Col xs={6}>
                                  <div className="mb-3">
                                    <h4>{formatNumberWithSpaces(engageduser)}</h4>
                                  </div>
                                </Col>
                              </Row>
                            </CardBody>
                          </Card>
                        }
                        {(item.name === 'Likes' || item.name === 'Likes') &&
                          <Card className="card-h-100">
                            <CardBody>
                              <div className="my-0 text-primary d-flex justify-content-between align-items-center">{item.name}<img className="img-intgration-icon" src={FacebookSocialMediaIcon} /></div>
                              <Row className="align-items-center">
                                <Col xs={6}>
                                  <div className="mb-3">
                                    <h4>{formatNumberWithSpaces(likes_numbre)}</h4>
                                  </div>
                                </Col>
                              </Row>
                            </CardBody>
                          </Card>
                        }
                        {(item.name === 'Followers' || item.name === 'Followers') &&
                          <Card className="card-h-100">
                            <CardBody>
                              <div className="my-0 text-primary d-flex justify-content-between align-items-center">
                                {props.t(item.name)}
                                {item.name === "Followers" && (
                                  <img className="img-intgration-icon" src={FacebookSocialMediaIcon} />
                                )}
                              </div>
                              <Row className="align-items-center">
                                <Col xs={6}>
                                  <div className="mb-3">
                                    <h4>{formatNumberWithSpaces(followers)}</h4>
                                  </div>
                                </Col>
                              </Row>
                            </CardBody>
                          </Card>
                        }
                         {(item.name === 'Page Posts Impressions Paid' || item.name === 'Page Posts Impressions Paid') &&
                          <Card className="card-h-100">
                            <CardBody>
                              <div className="my-0 text-primary d-flex justify-content-between align-items-center">
                                {props.t(item.name)}
                                {item.name === "Page Posts Impressions Paid" && (
                                  <img className="img-intgration-icon" src={FacebookSocialMediaIcon} />
                                )}
                              </div>
                              <Row className="align-items-center">
                                <Col xs={6}>
                                  <div className="mb-3">
                                    <h4>{formatNumberWithSpaces(page_posts_impressions_paid)}</h4>
                                  </div>
                                </Col>
                              </Row>
                            </CardBody>
                          </Card>
                        }
                      </div>
                    ))}
                  </Row>

                  <Row className='justify-content-center no-gutters'>
                    {/* Second row: Monthly Impressions, Shares */}
                    {secCards.slice(4).map(item => (
                      <div className="dashboardMinorCardItem" key={item.name}>
                        {(item.name === 'Monthly Impressions' || item.name === 'Monthly Impressions') &&
                          <Card className="card-h-100">
                            <CardBody>
                              <div className="my-0 text-primary d-flex justify-content-between align-items-center">
                                {props.t(item.name)}
                                {item.name === "Monthly Impressions" && (
                                  <img className="img-intgration-icon" src={FacebookSocialMediaIcon} />
                                )}
                              </div>
                              <Row className="align-items-center">
                                <Col xs={6}>
                                  <div className="mb-3">
                                    <h4>{formatNumberWithSpaces(monthly_impressions)}</h4>
                                  </div>
                                </Col>
                              </Row>
                            </CardBody>
                          </Card>
                        }
                        {(item.name === 'Page Impressions Paid' || item.name === 'Page Impressions Paid') &&
                          <Card className="card-h-100">
                            <CardBody>
                              <div className="my-0 text-primary d-flex justify-content-between align-items-center">
                                {props.t(item.name)}
                                {item.name === "Page Impressions Paid" && (
                                  <img className="img-intgration-icon" src={FacebookSocialMediaIcon} />
                                )}
                              </div>
                              <Row className="align-items-center">
                                <Col xs={6}>
                                  <div className="mb-3">
                                    <h4>{formatNumberWithSpaces(page_impressions_paid)}</h4>
                                  </div>
                                </Col>
                              </Row>
                            </CardBody>
                          </Card>
                        }
                        {item.name === 'Post Engagements' &&
                          <Card className="card-h-100">
                            <CardBody>
                              <div className="my-0 text-primary d-flex justify-content-between align-items-center">{props.t(item.name)}
                                {item.name === "Post Engagements" && (
                                  <img className="img-intgration-icon" src={FacebookSocialMediaIcon} />
                                )}
                              </div>
                              <Row className="align-items-center">
                                <Col xs={6}>
                                  <div className="mb-3">
                                    <h4>{formatNumberWithSpaces(post_engagements)}</h4>
                                  </div>
                                </Col>
                              </Row>
                            </CardBody>
                          </Card>
                        }
                        {(item.name === 'Post Impressions' || item.name === 'Post Impressions') &&
                          <Card className="card-h-100">
                            <CardBody>
                              <div className="my-0 text-primary d-flex justify-content-between align-items-center">
                                {props.t(item.name)}
                                {item.name === "Post Impressions" && (
                                  <img className="img-intgration-icon" src={FacebookSocialMediaIcon} />
                                )}
                              </div>
                              <Row className="align-items-center">
                                <Col xs={6}>
                                  <div className="mb-3">
                                    <h4>{formatNumberWithSpaces(post_impressions)}</h4>
                                  </div>
                                </Col>
                              </Row>
                            </CardBody>
                          </Card>
                        }
                      </div>
                    ))}
                  </Row>


                  <Row>
                    <Card>
                      <CardBody style={{ padding: "45px" }}>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                          }}
                        >
                          <h4 className="card-title mb-4">
                            {props.t('Facebook Page posts last 30  days')}
                          </h4>
                          <img className="img-intgration-icon" src={FacebookSocialMediaIcon} />
                        </div>
                        <Space
                          style={{
                            marginBottom: 16,
                          }}
                        >
                          {/* Add any additional buttons or controls specific to Facebook here */}
                          <Button onClick={setAgeSort}>{props.t("Sort Impressions")}</Button>

                          <Button onClick={clearAll}>{props.t("Clear sorters")}</Button>
                          <Dropdown overlay={menu} trigger={['click']}>
                            <Button>{props.t("Select Columns")}</Button>
                          </Dropdown>
                        </Space>
                        <Table
                          style={{ border: "1px solid #e8e8e8" }}
                          columns={filteredColumns}
                          dataSource={facebookPagePost}
                          onChange={handleChange}
                          pagination={{
                            ...paginationOptions,
                            onChange: handleChange,
                            showSizeChanger: true, // Enable the page size selector
                            // showTotal: (total) => `Total ${total} items`, // Optionally show total items
                            // Other pagination settings
                          }}
                         
                        />
                      </CardBody>
                    </Card>
                  </Row>

                </Col>
              </Row>
              <Row>
                <Col xl={6}>
                  <Card className="card-h-100">
                    <CardBody>

                      <div className="d-flex flex-wrap align-items-center mb-4">
                        <h5 className="card-title me-2">{props.t("Social Media Audience ( Gender )")} </h5>
                      </div>
                      <Row className="align-items-center">
                        <div className="col-sm">
                          <div id="wallet-balance" className="apex-charts">
                            <Pie data={dataChart} />
                          </div>
                        </div>
                        <div className="col-sm align-self-center">
                          <div className="mt-4 mt-sm-0">
                            <div>
                              <p className="mb-2"><i className="mdi mdi-circle align-middle font-size-10 me-2 text-success"></i> {props.t("Female")}</p>
                              <h6>{femalePercentage} % = <span className="text-muted font-size-14 fw-normal">{sums.Female}</span></h6>
                            </div>

                            <div className="mt-4 pt-2">
                              <p className="mb-2"><i className="mdi mdi-circle align-middle font-size-10 me-2 text-primary"></i> {props.t("Male")}</p>
                              <h6>{malePercentage} % = <span className="text-muted font-size-14 fw-normal">{sums.Male}</span></h6>
                            </div>

                            <div className="mt-4 pt-2">
                              <p className="mb-2"><i className="mdi mdi-circle align-middle font-size-10 me-2 text-info"></i> {props.t("Unknown")}</p>
                              <h6>{underPercentage} % = <span className="text-muted font-size-14 fw-normal">{sums.Under}</span></h6>
                            </div>
                          </div>
                        </div>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
                <Col xl={6}>
                  <Card className="card-h-100">
                    <CardBody>

                      <div className="d-flex flex-wrap align-items-center mb-4">
                        <h5 className="card-title me-2">{props.t("Social Media Audience ( Age )")} </h5>
                      </div>
                      <Row className="align-items-center">
                        <div className="col-sm">
                          <div id="wallet-balance" className="apex-charts">
                            <Bar
                              data={chartData}
                              options={{
                                title: {
                                  display: true,
                                  text: props.t("Gender and Age Group Sum"),
                                  fontSize: 20,
                                },
                                legend: {
                                  display: true,
                                },
                                scales: {
                                  yAxes: [
                                    {
                                      ticks: {
                                        beginAtZero: true,
                                      },
                                      scaleLabel: {
                                        display: true,
                                        labelString: "Count",
                                      },
                                    },
                                  ],
                                },
                              }}
                            />
                          </div>
                        </div>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
                <hr />
                <div className="d-print-none mt-4">
                  <div className="float-end">
                    <button type="button" onClick={exportToPDF} className="btn btn-primary waves-effect waves-light w-md">
                      <div className="d-flex align-items-center"> {/* Use Flexbox to align icon and text */}
                        <i className="mdi mdi-download d-block font-size-16" style={{ color: 'white' }}></i>
                        <span className="ms-2">{props.t("Download")}</span> {/* Add margin for space between icon and text */}
                      </div>
                    </button>
                  </div>
                </div>
              </Row>
           
        </Container>
        
      </div>

    </Row>

  );
}
FbSocialMediaDashboard.propTypes = {
  t: PropTypes.any
};
export default withRouter(withTranslation()(FbSocialMediaDashboard));
//export default FbSocialMediaDashboard