export const GET_fACEBOOK_CAMPAIGNS_PER_DATE = 'GET_fACEBOOK_CAMPAIGNS_PER_DATE';
export const GET_fACEBOOK_CAMPAIGNS_PER_DATE_SUCCESS = 'GET_fACEBOOK_CAMPAIGNS_PER_DATE_SUCCESS';
export const GET_fACEBOOK_CAMPAIGNS_PER_DATE_FAILURE = 'GET_fACEBOOK_CAMPAIGNS_PER_DATE_FAILURE';
//getfbcamapigns
export const GET_FACEBOOK_CAMPAIGNS = 'GET_FACEBOOK_CAMPAIGNS';
export const GET_FACEBOOK_CAMPAIGNS_SUCCESS = 'GET_FACEBOOK_CAMPAIGNS_SUCCESS';
export const GET_FACEBOOK_CAMPAIGNS_FAILURE = 'GET_FACEBOOK_CAMPAIGNS_FAILURE';

export const GET_FB_CAMPAIGN_METRICS = 'GET_FB_CAMPAIGN_METRICS';
export const GET_FB_CAMPAIGN_METRICS_SUCCESS = 'GET_FB_CAMPAIGN_METRICS_SUCCESS';
export const GET_FB_CAMPAIGN_METRICS_FAILURE = 'GET_FB_CAMPAIGN_METRICS_FAILURE';

export const GET_ADS_FACEBOOK = 'GET_ADS_FACEBOOK';
export const GET_ADS_FACEBOOK_SUCCESS = 'GET_ADS_FACEBOOK_SUCCESS';
export const GET_ADS_FACEBOOK_FAILURE = 'GET_ADS_FACEBOOK_FAILURE';


export const getAdsFacebook = (startDate,endDate) => ({
    type: GET_ADS_FACEBOOK,
    payload: {startDate,endDate},
})
export const getAdsFacebookSuccess = (ads) => ({
    type: GET_ADS_FACEBOOK_SUCCESS,
    payload: {ads},
})
export const getAdsFacebookFailure = (error) => ({
    type: GET_ADS_FACEBOOK_FAILURE,
    payload: {error},
})


export const getFbCampaignMetrics = (startDate,endDate,compare_to) => ({
    type: GET_FB_CAMPAIGN_METRICS,
    payload: {startDate,endDate,compare_to},
})


export const getFbCampaignMetricsSuccess = (dataDashboard) => ({
    type: GET_FB_CAMPAIGN_METRICS_SUCCESS,
    payload: {dataDashboard},
})


export const getFbCampaignMetricsFailure = (error) => ({
    type: GET_FB_CAMPAIGN_METRICS_FAILURE,
    payload: {error},
})

export const getFacebookCampaigns = () => ({
    type: GET_FACEBOOK_CAMPAIGNS,
})

export const getFacebookCampaignsSuccess = (campaigns) => ({
    type: GET_FACEBOOK_CAMPAIGNS_SUCCESS,
    payload: {campaigns},

})

export const getFacebookCampaignsFailure = (error) => ({
    type: GET_FACEBOOK_CAMPAIGNS_FAILURE,
    payload: {error},
})


export const getFacebookCampaignsPerDate = (startDate,endDate) => ({
    type: GET_fACEBOOK_CAMPAIGNS_PER_DATE,
    payload: {startDate,endDate},
})

export const getFacebookCampaignsPerDateSuccess = (metrcisbyCompaign) => ({
    type: GET_fACEBOOK_CAMPAIGNS_PER_DATE_SUCCESS,
    payload: {metrcisbyCompaign},

})
export const getFacebookCampaignsPerDateFailure = (error) => ({
    type: GET_fACEBOOK_CAMPAIGNS_PER_DATE_FAILURE,
    payload: {error},
})
