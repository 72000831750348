import React, { useState, useEffect, useRef } from 'react';
import {
  Row,
  Col,
  CardBody,
  Card,
  Container,
  FormGroup,
  InputGroup,
} from 'reactstrap';
import { Button, Space, Table, Menu, Dropdown, Checkbox } from 'antd';
import ApolloClient from 'apollo-boost';
import urls from '../../routes/apiUrls';
import MetaTags from 'react-meta-tags';
import Breadcrumbs from '../../components/Common/Breadcrumb';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { DateRange } from 'react-date-range';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import secureSection from '../../MangeSecurity/MemberSecurity';
import { getInstaPageData, getInstaPostData, getInstaAudienceData } from '../../store/instagram/actions';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import logo from '../../assets/images/Live_Metrics_Logo.png';
import { Pie, Bar, Doughnut } from 'react-chartjs-2';

const ApolloCliGetInstagram = new ApolloClient({
  uri: urls.instagramSocialMedia,
});

const daysBetween = function (date1, date2) {
  var one_day = 1000 * 60 * 60 * 24;
  var date1_ms = date1.getTime();
  var date2_ms = date2.getTime();
  var difference_ms = date2_ms - date1_ms;
  return Math.round(difference_ms / one_day);
};
function useOutsideAlerter(ref, toggleDate) {
  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        toggleDate(false);
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref]);
}
const datePattern = /(\d{1,2})\/(\d{1,2})\/(\d{4})/;
const InstagramLogo = `${process.env.PUBLIC_URL}/images/integrations_images/Insta1.svg`;

const InstagramDashboard = (props) => {
  secureSection();
  const dispatch = useDispatch();
  const { instaPageData, instaPostData, instaAudienceData } = useSelector((state) => state.Instagram);

  const [tableData, setTableData] = useState([]);
  //start date picker
  const wrapperRef = useRef(null);
  const [toggleDate, setToggleDate] = useState(false);
  useOutsideAlerter(wrapperRef, setToggleDate);
  const [currentFocus, setCurrentFocus] = useState([]);
  const [dateRage, setDateRange] = useState({
    date: [
      window.localStorage.getItem('start')
        ? new Date(window.localStorage.getItem('start'))
        : new Date(
          new Date().toLocaleDateString('en-US', { timeZone: props.timeZone })
        ),
      window.localStorage.getItem('end')
        ? new Date(window.localStorage.getItem('end'))
        : new Date(
          new Date().toLocaleDateString('en-US', { timeZone: props.timeZone })
        ),
    ],
  });
  const [lastPeriod, setLastPeriod] = useState('');
  const [chosedDate, setChosedDate] = useState([
    {
      startDate: window.localStorage.getItem('start')
        ? new Date(window.localStorage.getItem('start'))
        : new Date(
          new Date().toLocaleDateString('en-US', { timeZone: props.timeZone })
        ),
      endDate: window.localStorage.getItem('end')
        ? new Date(window.localStorage.getItem('end'))
        : new Date(
          new Date().toLocaleDateString('en-US', { timeZone: props.timeZone })
        ),
      key: 'selection',
    },
  ]);
  const [activeBtn, setActiveBtn] = useState(
    window.localStorage.getItem('activeBtn')
      ? JSON.parse(window.localStorage.getItem('activeBtn'))
      : {
        today: 1,
        yesterday: 0,
        last7days: 0,
        last30day: 0,
        last60day: 0,
        month: 0,
        other: 0,
      }
  );
  const [comparedTo, setComparedTo] = useState('');
  const mnths = [
    { num: 0, add: 2 },
    { num: 1, add: 0 },
    { num: 2, add: 2 },
    { num: 3, add: 1 },
    { num: 4, add: 2 },
    { num: 5, add: 1 },
    { num: 6, add: 2 },
    { num: 7, add: 2 },
    { num: 8, add: 1 },
    { num: 9, add: 2 },
    { num: 10, add: 1 },
    { num: 11, add: 2 },
  ];
  const getToAddOnMonths = (int) => {
    let res = 0;
    let month = 0;
    if (int === 0) {
      month = int;
    } else {
      month = int - 1;
    }
    mnths.map((x) => {
      if (x.num === month) res = x.add;
    });
    return res;
  };
  useEffect(() => {
    if (chosedDate[0] && chosedDate[0].startDate && chosedDate[0].endDate) {
      const startDate = chosedDate[0].startDate;
      const endDate = chosedDate[0].endDate;
      const newStartDate = new Date(startDate).setDate(
        new Date(startDate).getDate() - daysBetween(startDate, endDate) - 1
      );
      const newEndDate = new Date(endDate).setDate(
        new Date(endDate).getDate() - daysBetween(startDate, endDate) - 1
      );
      comparedTo !== 'year'
        ? setLastPeriod(
          `${'Comparedto'} : ${new Date(newStartDate).toLocaleString('en', {
            day: '2-digit',
            month: 'short',
          })}-${new Date(newEndDate).toLocaleString('en', {
            day: '2-digit',
            month: 'short',
          })} ${new Date(newEndDate).getFullYear()}`
        )
        : setLastPeriod(
          `${'Comparedto'}: ${new Date(startDate).toLocaleString('en', {
            day: '2-digit',
            month: 'short',
          })}-${new Date(endDate).toLocaleString('en', {
            day: '2-digit',
            month: 'short',
          })} ${new Date(endDate).getFullYear() - 1}`
        );
    }
  }, [chosedDate, comparedTo]);
  const buttonHandler = (startDate, endDate) => {
    setDateRange({ date: [startDate, endDate] });
    setChosedDate([
      {
        startDate: startDate,
        endDate: endDate,
        key: 'selection',
      },
    ]);
    setToggleDate(false);
    window.localStorage.setItem('start', startDate);
    window.localStorage.setItem('end', endDate);
  };
  const handleCompareToggle = (compare_to) => {
    setComparedTo(compare_to);
    if (comparedTo === compare_to) {
      setComparedTo('');
    }
  };
  useEffect(() => {
    if (currentFocus[1] === 0) {
      setDateRange({
        date: [
          new Date(
            new Date(
              chosedDate[0].startDate.toLocaleDateString('en-US', {
                timeZone: props.timeZone ? props.timeZone : 'UTC',
              })
            )
          ),
          new Date(
            new Date(
              chosedDate[0].endDate.toLocaleDateString('en-US', {
                timeZone: props.timeZone ? props.timeZone : 'UTC',
              })
            )
          ),
        ],
      });
      setToggleDate(false);
    }
  }, [currentFocus]);
  useEffect(() => {
    window.localStorage.setItem('start', chosedDate[0].startDate);
    window.localStorage.setItem('end', chosedDate[0].endDate);
  }, [chosedDate]);
  // end date picker
  const [instaPagePosts, setinstaPagePosts] = useState([]);
  const [reach, setreach] = useState('');
  const [impressions, setimpressions] = useState('');
  const [follower_count, setfollower_count] = useState('');
  const [profile_views, setprofile_views] = useState('');
  const [website_clicks, setwebsite_clicks] = useState('');
  const [isPeriodChecked, setPeriodChecked] = useState(false);
  const [isYearChecked, setYearChecked] = useState(false);
  const [genderdata, setgenderdata] = useState(null);
  const [fans_gender_age, setFans_gender_age] = useState([]);
  const [image, setImage] = useState(null);

  // GET FACEBOOK SOCIAL MEDIA AUDIENCE
  let newTotals = {};

  useEffect(() => {
    const fetchData = async () => {
      try {
        dispatch(getInstaAudienceData());
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);
  useEffect(() => {
    if (instaAudienceData.length > 0) {
      setFans_gender_age(
        instaAudienceData[0].fans_gender_age
      );
      setImage(
        instaAudienceData[0].profile_picture_url
      );

      // Calculate totals for each gender (F, M, U)
      newTotals =
        instaAudienceData[0].fans_gender_age.reduce(
          (acc, entry) => {
            const gender = entry.dimension_values[0];
            const value = entry.value;

            // Update the corresponding counter based on gender
            acc[gender] += value;

            return acc;
          },
          { F: 0, M: 0, U: 0 }
        );

      // Log the totals
      setgenderdata(newTotals);
    }
  }, [instaAudienceData]);

  let total = 0;
  let femalePercentage;
  let malePercentage;
  let underPercentage;
  // Calculate percentages
  if (
    genderdata?.F !== undefined &&
    genderdata?.M !== undefined &&
    genderdata?.U !== undefined
  ) {
    total = genderdata.F + genderdata.M + genderdata.U;

    femalePercentage = ((genderdata.F / total) * 100).toFixed(2);
    malePercentage = ((genderdata.M / total) * 100).toFixed(2);
    underPercentage = ((genderdata.U / total) * 100).toFixed(2);
  }

  // CREATE THE CHART DATA
  const dataChart = {
    labels: ['Female', 'Male', 'Unkown'],
    datasets: [
      {
        data: [
          genderdata && genderdata.F ? genderdata.F : 0,
          genderdata && genderdata.M ? genderdata.M : 0,
          genderdata && genderdata.U ? genderdata.U : 0,
        ],
        backgroundColor: [
          'rgb(42, 181, 125)',
          'rgb(59, 91, 152)',
          'rgb(75, 166, 239)',
        ],
        hoverBorderColor: '#fff',
      },
    ],
  };
  // CREATE THE AGE GROUP CHART DATA
  const groupedData = {};

  // Check if fans_gender_age is not null and is an array before iterating
  if (fans_gender_age && Array.isArray(fans_gender_age)) {
    fans_gender_age.forEach((entry) => {
      const ageGroup = entry.dimension_values[1]; // Assuming age is at index 1

      // Initialize the group if it doesn't exist
      if (!groupedData[ageGroup]) {
        groupedData[ageGroup] = { F: 0, M: 0, U: 0 };
      }

      // Update the counts for each gender
      const gender = entry.dimension_values[0];
      const value = entry.value;
      groupedData[ageGroup][gender] += value;
    });
  } else {
    console.log('fans_gender_age is null or not an array');
  }

  // Now groupedData contains the total counts for each age group and gender

  const ageGroups = Object.keys(groupedData);
  const chartData = {
    labels: ageGroups,
    datasets: [
      {
        label: props.t('Female'),
        data: ageGroups.map((ageGroup) => groupedData[ageGroup]?.F || 0),
        backgroundColor: 'rgb(42, 181, 125)', // Female
      },
      {
        label: props.t('Male'),
        data: ageGroups.map((ageGroup) => groupedData[ageGroup]?.M || 0),
        backgroundColor: 'rgb(59, 91, 152)', // Male
      },
      {
        label: props.t('Unknown'),
        data: ageGroups.map((ageGroup) => groupedData[ageGroup]?.U || 0),
        backgroundColor: 'rgb(75, 166, 239)', // Unknown
      },
    ],
  };
  // GET ALL PAGE POST DATA
  useEffect(() => {
    dispatch(getInstaPostData());
  }, []);

  // GET PAGE DATA
  useEffect(() => {

    var newStartDate = datePattern.exec(
      chosedDate[0].startDate && chosedDate[0].startDate.toLocaleString('fr-FR')
    );
    var newEndDate = datePattern.exec(
      chosedDate[0].endDate && chosedDate[0].endDate.toLocaleString('fr-FR')
    );
    var startDate =
      newStartDate[3] + '-' + newStartDate[2] + '-' + newStartDate[1];
    var endDate = newEndDate[3] + '-' + newEndDate[2] + '-' + newEndDate[1];
    if (newStartDate && newEndDate) {

      dispatch(getInstaPageData(newStartDate[3] + "-" + newStartDate[2] + "-" + newStartDate[1], newEndDate[3] + "-" + newEndDate[2] + "-" + newEndDate[1], comparedTo));

    }
  }, [dateRage, chosedDate]);
  useEffect(() => {
    if (instaPageData) {
      let total_reach = 0; // Initialize total_reach before the map function
      let total_impressions = 0;
      let total_follower_count = 0;
      let total_profile_views = 0;
      let total_website_clicks = 0;

      instaPageData.forEach((item) => {
        total_reach += item.reach;
        total_impressions += item.impressions;
        total_follower_count += item.follower_count;
        total_profile_views += item.profile_views;
        total_website_clicks += item.website_clicks;
      });
      setreach(total_reach);
      setimpressions(total_impressions);
      setfollower_count(total_follower_count);
      setprofile_views(total_profile_views);
      setwebsite_clicks(total_website_clicks);
    }
  }, [instaPageData]);

  // test ant table
  const [filteredInfo, setFilteredInfo] = useState({});
  const [sortedInfo, setSortedInfo] = useState({});

  const handleChange = (pagination, filters, sorter) => {
    console.log('Various parameters', pagination, filters, sorter);
    setFilteredInfo(filters);
    setSortedInfo(sorter);
  };
  const clearSorters = () => {
    setSortedInfo({});
  };
  const clearAll = () => {
    setFilteredInfo({});
    setSortedInfo({});
    // make the columns  selected
    setSelectedColumns(columns.map((column) => column.key));
  };
  const setAgeSort = () => {
    setSortedInfo({
      order: 'descend',
      columnKey: 'impressions',
    });
  };
  const pageSizeOptions = ['10', '20', '30'];
  const columns = [

    {
      title: 'Post Image',
      dataIndex: 'media_url',
      key: 'media_url',
      align: 'center',
      filters: [
        {
          text: 'Image',
          value: 'IMAGE',
        },
        {
          text: 'Video',
          value: 'VIDEO',
        },
        {
          text: 'Carousel Album',
          value: 'CAROUSEL_ALBUM',
        },
      ],
      filteredValue: filteredInfo.media_url || null,
      onFilter: (value, record) => {
        if (value === 'IMAGE') {
          return record.media_type === 'IMAGE';
        } else if (value === 'VIDEO') {
          return record.media_type === 'VIDEO';
        } else if (value === 'CAROUSEL_ALBUM') {
          return record.media_type === 'CAROUSEL_ALBUM';
        }
        return true; // Return true if no filter is applied
      },
      // sortOrder: sortedInfo.columnKey === 'name' ? sortedInfo.order : null,
      ellipsis: true,
      render: (text, record) => {
        if (!text) {
          return (
            <a href={record.permalink} target="_blank" rel="noopener noreferrer">
              <span>{' There is Non-image '}</span>
            </a>
          );
        } else {
          return (
            <a href={record.permalink} target="_blank" rel="noopener noreferrer">
              <img
                src={text}
                alt={`Full Picture ${record.full_picture}`}
                style={{ maxWidth: '100px', maxHeight: '90px' }}
              />
            </a>
          );
        }
      }
    },
    {
      title: 'Post Interactions',
      dataIndex: 'total_interactions',
      key: 'total_interactions',
      sorter: (a, b) => a.total_interactions - b.total_interactions,
      sortOrder:
        sortedInfo.columnKey === 'total_interactions' ? sortedInfo.order : null,
      ellipsis: true,
      align: 'center',
      render: (text) => <strong>{text}</strong>,
    },
    {
      title: 'Post Saves',
      dataIndex: 'saved',
      align: 'center',
      key: 'saved',
      sorter: (a, b) => a.saved - b.saved,
      sortOrder: sortedInfo.columnKey === 'saved' ? sortedInfo.order : null,
      ellipsis: true,
      render: (text) => <strong>{text}</strong>,
    },
    {
      title: 'Post Impressions',
      dataIndex: 'impressions',
      key: 'impressions',
      align: 'center',
      sorter: (a, b) => a.impressions - b.impressions,
      sortOrder: sortedInfo.columnKey === 'impressions' ? sortedInfo.order : null,
      ellipsis: true,
      render: (text) => <strong>{text || '-'}</strong>, // Render '-' if text is falsy
    },
    {
      title: 'Post Reach',
      dataIndex: 'reach',
      align: 'center',
      key: 'reach',
      onFilter: (value, record) => record.address.includes(value),
      sorter: (a, b) => a.reach - b.reach,
      sortOrder: sortedInfo.columnKey === 'reach' ? sortedInfo.order : null,
      ellipsis: true,
      render: (text) => <strong>{text}</strong>,
    },
    {
      title: 'Post Comments',
      dataIndex: 'comments',
      key: 'comments',
      align: 'center',
      sorter: (a, b) => a.comments - b.comments,
      sortOrder: sortedInfo.columnKey === 'comments' ? sortedInfo.order : null,
      ellipsis: true,
      render: (text) => <strong>{text}</strong>,

    },
    {
      title: ' Post Likes',
      dataIndex: 'likes',
      key: 'likes',
      sorter: (a, b) => a.likes - b.likes,
      sortOrder: sortedInfo.columnKey === 'likes' ? sortedInfo.order : null,
      ellipsis: true,
      align: 'center',
      render: (text) => <strong>{text}</strong>,
    },
    {
      title: ' Post Plays',
      dataIndex: 'plays',
      align: 'center',
      key: 'plays',
      sorter: (a, b) => a.plays - b.plays,
      sortOrder: sortedInfo.columnKey === 'plays' ? sortedInfo.order : null,
      ellipsis: true,
      render: (text) => <strong>{text || '-'}</strong>,
    },
    {
      title: 'Post Shares',
      dataIndex: 'shares',
      align: 'center',
      key: 'shares',
      sorter: (a, b) => a.shares - b.shares,
      sortOrder: sortedInfo.columnKey === 'shares' ? sortedInfo.order : null,
      ellipsis: true,
      render: (text) => <strong>{text}</strong>,
    },
    {
      title: 'Created At',
      dataIndex: 'timestamp',
      align: 'center',
      key: 'timestamp',
      sorter: (a, b) => new Date(a.timestamp) - new Date(b.timestamp),
      ellipsis: true,
      render: (text) => {
        const date = new Date(text);
        const formattedDate = date.toISOString().split('T')[0]; // Extracts date portion
        return <strong>{formattedDate}</strong>;
      },
    },

  ];
  const [selectedColumns, setSelectedColumns] = useState(
    columns.map((column) => column.key)
  );

  const handleMenuClick = (clickedKey) => {
    const index = selectedColumns.indexOf(clickedKey);

    let updatedSelectedColumns = [];

    if (index === -1) {
      // Column was not selected before, add it to the selected columns
      updatedSelectedColumns = [...selectedColumns, clickedKey];
    } else {
      // Column was selected before, remove it from the selected columns
      updatedSelectedColumns = selectedColumns.filter(
        (key) => key !== clickedKey
      );
    }

    setSelectedColumns(updatedSelectedColumns);
  };

  const filteredColumns = columns.filter((column) =>
    selectedColumns.includes(column.key)
  );

  const menu = (
    <Menu>
      {columns.map((column) => (
        <Menu.Item
          key={column.key}
          onClick={() => handleMenuClick(column.key)}
        >
          <Checkbox
            checked={selectedColumns.includes(column.key)}
            style={{ marginRight: '8px' }}
          />
          <span
            style={
              selectedColumns.includes(column.key)
                ? { fontWeight: 'bold' }
                : {}
            }
          >
            {column.title}
          </span>
        </Menu.Item>
      ))}
    </Menu>
  );

  const exportToPDF = async () => {
    try {
      const capture = document.querySelector('.print');
      const canvas = await html2canvas(capture, { useCORS: true, scale: 3 }); // Adjust scale for higher resolution
      const imgData = canvas.toDataURL('image/png', 5.0); // Adjust quality
      const doc = new jsPDF('p', 'mm', 'a4');
      const componentWidth = doc.internal.pageSize.getWidth();
      const componentHeight = doc.internal.pageSize.getHeight();
      const padding = 10; // Adjust this value to set the padding in millimeters
      // Add padding to X and Y coordinates
      doc.addImage(
        imgData,
        'PNG',
        1 + padding,
        padding,
        componentWidth - 2 * padding,
        componentHeight - 2 * padding
      );
      // // Customize the appearance of the PDF
      // doc.setLineWidth(0.5); // Set line width for borders
      // doc.setDrawColor(103, 137, 242); // Set border color to black
      // doc.rect(1, 1, componentWidth - 1, componentHeight - 1, 'S'); // Add a border around the content
      doc.save('Rapport-Instagram-Ads.pdf');
    } catch (error) {
      console.error('Error exporting to PDF:', error);
    }
  };

  const [secCards, setsecCards] = useState([
    {
      id: 1,
      name: 'Reach',
    },
    { id: 2, name: 'Impressions' },
    { id: 3, name: 'New followers' },
    { id: 4, name: 'Profile views' },
    { id: 5, name: 'Website clicks' },
  ]);
  useEffect(() => {
    let isSubscribed = true;
    setsecCards([
      {
        id: 1,
        name: 'Reach',
      },
      { id: 2, name: 'Impressions' },
      { id: 3, name: 'New followers' },
      { id: 4, name: 'Profile views' },
      { id: 5, name: 'Website clicks' },
    ]);
    return () => (isSubscribed = false);
  }, [reach, impressions, follower_count, profile_views, website_clicks]);
  const formatNumberWithSpaces = (value) => {
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
  };
  return (
    <Row>
      <div className="page-content">
        <MetaTags>
          <title>
            {props.t('Instagram Social Media Dashboard')} | Convergen-Metrics -
            E-com SaaS
          </title>
        </MetaTags>
        <Breadcrumbs
          title={props.t("Social Media Dashboard")}
          breadcrumbItem={props.t("Instagram Media Dashboard")}

        />
        <Container fluid className="print">
          <Card className="tamour">
            <CardBody className="">
              <div>
                <div className="invoice-title">
                  <div className="d-flex align-items-start">
                    <div className="flex-grow-1">
                      <div className="">
                        <img src={logo} alt="" height="27" />
                      </div>
                    </div>
                    <div className="flex-shrink-0">
                      <div className="">
                        <h4 className="float-end font-size-16">
                          {props.t('Instagram Social Media Dashboard')}
                        </h4>
                      </div>
                    </div>
                  </div>
                  <div className="d-flex align-items-center justify-content-between">
                    <div className="flex-grow-1">
                      {/* <div className="">
                        <address>
                          <br />
                          <span>
                            {props.t(
                              'Industrial Zone Khéreddine Zone North West,'
                            )}
                          </span>
                          <br />
                          <span>Tunis, Tunisia</span>
                        </address>
                      </div>*/}
                    </div>
                    <div className="flex-grow-1">
                      <img
                        src={image}
                        alt=""
                        height="90"
                        width="90"
                        style={{ borderRadius: '50%', marginRight: '' }}
                      />
                    </div>
                    <div className="flex-shrink-0">
                      <FormGroup className="mt-3">
                        <InputGroup>
                          <div>
                            <div className="divDateContainer">
                              <div ref={wrapperRef}>
                                <button
                                  type="button"
                                  className="btn btn-light"
                                  onClick={() => setToggleDate(!toggleDate)}
                                  id="page-header-search-dropdown"
                                >
                                  {chosedDate[0] !== null
                                    ? [
                                      chosedDate[0].startDate.toLocaleString(
                                        'en',
                                        {
                                          month: 'short',
                                        }
                                      ),
                                      ' ',
                                      chosedDate[0].startDate.getDate(),
                                      ' ',
                                      chosedDate[0].startDate.getFullYear(),
                                      '  -  ',
                                      chosedDate[0].endDate &&
                                      chosedDate[0].endDate.toLocaleString(
                                        'en',
                                        {
                                          month: 'short',
                                        }
                                      ),
                                      ' ',
                                      chosedDate[0].endDate &&
                                      chosedDate[0].endDate.getDate(),
                                      ' ',
                                      chosedDate[0].endDate &&
                                      chosedDate[0].endDate.getFullYear(),
                                    ]
                                    : null}
                                </button>

                                {toggleDate && (
                                  <div
                                    style={{
                                      position: 'relative',
                                      display: 'flex',
                                    }}
                                  >
                                    <div
                                      className="calenderDivStyle"
                                      style={{ right: 0 }}
                                    >
                                      <div className="btnsCalendar">
                                        <DateRange
                                          editableDateInputs={true}
                                          moveRangeOnFirstSelection={false}
                                          onRangeFocusChange={(item) => {
                                            setCurrentFocus(item);
                                          }}
                                          onChange={(item) => {
                                            setChosedDate([item.selection]);
                                            setActiveBtn({
                                              today: 0,
                                              yesterday: 0,
                                              last7days: 0,
                                              last30day: 0,
                                              last60day: 0,
                                              month: 0,
                                              other: 1,
                                            });
                                            window.localStorage.setItem(
                                              'activeBtn',
                                              JSON.stringify({
                                                today: 0,
                                                yesterday: 0,
                                                last7days: 0,
                                                last30day: 0,
                                                last60day: 0,
                                                month: 0,
                                                other: 1,
                                              })
                                            );
                                          }}
                                          maxDate={new Date(new Date().getTime() - 24 * 60 * 60 * 1000)}
                                          disabledDates={[
                                            {
                                              after: new Date(), // Disable dates after today
                                            },
                                          ]}
                                          ranges={chosedDate}
                                          dragSelectionEnabled={true}
                                          rangeColors={['#6689F2']}
                                          months={2}
                                          direction="horizontal"
                                        />
                                      </div>
                                      <div className="calendarDiv">
                                        <div className="btnCalendarContainer">
                                          <button
                                            className={
                                              activeBtn.today
                                                ? 'dateBtns activeBtnDate'
                                                : 'dateBtns'
                                            }
                                            onClick={() => {
                                              buttonHandler(
                                                new Date(
                                                  new Date().toLocaleDateString(
                                                    'en-US',
                                                    {
                                                      timeZone:
                                                        window.localStorage.getItem(
                                                          'timezone'
                                                        ),
                                                    }
                                                  )
                                                ),
                                                new Date(
                                                  new Date().toLocaleDateString(
                                                    'en-US',
                                                    {
                                                      timeZone:
                                                        window.localStorage.getItem(
                                                          'timezone'
                                                        ),
                                                    }
                                                  )
                                                )
                                              );
                                              setActiveBtn({
                                                today: 1,
                                                yesterday: 0,
                                                last7days: 0,
                                                last30day: 0,
                                                last60day: 0,
                                                month: 0,
                                                other: 0,
                                              });
                                              window.localStorage.setItem(
                                                'activeBtn',
                                                JSON.stringify({
                                                  today: 1,
                                                  yesterday: 0,
                                                  last7days: 0,
                                                  last30day: 0,
                                                  last60day: 0,
                                                  month: 0,
                                                  other: 0,
                                                })
                                              );
                                            }}
                                          >
                                            {props.t('compareto')}
                                          </button>
                                          <button
                                            className={
                                              activeBtn.yesterday
                                                ? 'dateBtns activeBtnDate'
                                                : 'dateBtns'
                                            }
                                            onClick={() => {
                                              const timeZone =
                                                window.localStorage.getItem(
                                                  'timezone'
                                                ) || 'UTC'; // set default time zone to UTC
                                              buttonHandler(
                                                new Date(
                                                  new Date(
                                                    new Date().getTime() -
                                                    24 * 60 * 60 * 1000
                                                  ).toLocaleDateString(
                                                    'en-US',
                                                    {
                                                      timeZone: timeZone,
                                                    }
                                                  )
                                                ),

                                                new Date(
                                                  new Date(
                                                    new Date().getTime() -
                                                    24 * 60 * 60 * 1000
                                                  ).toLocaleDateString(
                                                    'en-US',
                                                    {
                                                      timeZone: timeZone,
                                                    }
                                                  )
                                                )
                                              );
                                              setActiveBtn({
                                                today: 0,
                                                yesterday: 1,
                                                last7days: 0,
                                                last30day: 0,
                                                last60day: 0,
                                                month: 0,
                                                other: 0,
                                              });
                                              window.localStorage.setItem(
                                                'activeBtn',
                                                JSON.stringify({
                                                  today: 0,
                                                  yesterday: 1,
                                                  last7days: 0,
                                                  last30day: 0,
                                                  last60day: 0,
                                                  month: 0,
                                                  other: 0,
                                                })
                                              );
                                            }}
                                          >
                                            {props.t('yesterday')}
                                          </button>
                                          <button
                                            className={
                                              activeBtn.last7days
                                                ? 'dateBtns activeBtnDate'
                                                : 'dateBtns'
                                            }
                                            onClick={() => {
                                              const timeZone =
                                                window.localStorage.getItem(
                                                  'timezone'
                                                ) || 'UTC'; // set default time zone to UTC
                                              buttonHandler(
                                                new Date(
                                                  new Date(
                                                    new Date().getTime() -
                                                    6 * 24 * 60 * 60 * 1000
                                                  ).toLocaleDateString(
                                                    'en-US',
                                                    {
                                                      timeZone: timeZone,
                                                    }
                                                  )
                                                ),
                                                new Date(
                                                  new Date().toLocaleDateString(
                                                    'en-US',
                                                    { timeZone: timeZone }
                                                  )
                                                )
                                              );
                                              setActiveBtn({
                                                today: 0,
                                                yesterday: 0,
                                                last7days: 1,
                                                last30day: 0,
                                                last60day: 0,
                                                month: 0,
                                                other: 0,
                                              });
                                              window.localStorage.setItem(
                                                'activeBtn',
                                                JSON.stringify({
                                                  today: 0,
                                                  yesterday: 0,
                                                  last7days: 1,
                                                  last30day: 0,
                                                  last60day: 0,
                                                  month: 0,
                                                  other: 0,
                                                })
                                              );
                                            }}
                                          >
                                            {props.t('last7days')}
                                          </button>
                                          <button
                                            className={
                                              activeBtn.last30day
                                                ? 'dateBtns activeBtnDate'
                                                : 'dateBtns'
                                            }
                                            onClick={() => {
                                              const timeZone =
                                                window.localStorage.getItem(
                                                  'timezone'
                                                ) || 'UTC'; // set default time zone to UTC
                                              buttonHandler(
                                                new Date(
                                                  new Date(
                                                    new Date().getTime() -
                                                    29 * 24 * 60 * 60 * 1000
                                                  ).toLocaleDateString(
                                                    'en-US',
                                                    {
                                                      timeZone: timeZone,
                                                    }
                                                  )
                                                ),
                                                new Date(
                                                  new Date().toLocaleDateString(
                                                    'en-US',
                                                    { timeZone: timeZone }
                                                  )
                                                )
                                              );
                                              setActiveBtn({
                                                today: 0,
                                                yesterday: 0,
                                                last7days: 0,
                                                last30day: 1,
                                                last60day: 0,
                                                month: 0,
                                                other: 0,
                                              });
                                              window.localStorage.setItem(
                                                'activeBtn',
                                                JSON.stringify({
                                                  today: 0,
                                                  yesterday: 0,
                                                  last7days: 0,
                                                  last30day: 1,
                                                  last60day: 0,
                                                  month: 0,
                                                  other: 0,
                                                })
                                              );
                                            }}
                                          >
                                            {props.t('last30days')}
                                          </button>
                                          <button
                                            className={
                                              activeBtn.month
                                                ? 'dateBtns activeBtnDate'
                                                : 'dateBtns'
                                            }
                                            onClick={() => {
                                              const timeZone =
                                                window.localStorage.getItem(
                                                  'timezone'
                                                ) || 'UTC'; // set default time zone to UTC
                                              buttonHandler(
                                                new Date(
                                                  new Date(
                                                    new Date().getFullYear(),
                                                    new Date().getMonth(),

                                                    getToAddOnMonths(
                                                      new Date().getMonth()
                                                    )
                                                  ).toLocaleDateString(
                                                    'en-US',
                                                    {
                                                      timeZone: timeZone,
                                                    }
                                                  )
                                                ),

                                                new Date(
                                                  new Date().toLocaleDateString(
                                                    'en-US',
                                                    { timeZone: timeZone }
                                                  )
                                                )
                                              );
                                              setActiveBtn({
                                                today: 0,
                                                yesterday: 0,
                                                last7days: 0,
                                                last30day: 0,
                                                last60day: 0,
                                                month: 1,
                                                other: 0,
                                              });
                                              window.localStorage.setItem(
                                                'activeBtn',
                                                JSON.stringify({
                                                  today: 0,
                                                  yesterday: 0,
                                                  last7days: 0,
                                                  last30day: 0,
                                                  last60day: 0,
                                                  month: 1,
                                                  other: 0,
                                                })
                                              );
                                            }}
                                          >
                                            {props.t('thismonth')}
                                          </button>
                                          <span className="compareSpan">
                                            <b>{props.t('compareto')}</b>
                                          </span>
                                          <div className="form-check mb-3">
                                            <input
                                              style={{
                                                width: '1em',
                                                height: '1em',
                                                marginTop: '0.25em',
                                                verticalAlign: 'top',
                                                backgroundColor: '#fff',
                                                backgroundRepeat: 'no-repeat',
                                                backgroundPosition: 'center',
                                                border: '#000000',
                                              }}
                                              type="checkbox"
                                              id="formCheck1"
                                              className={
                                                comparedTo === 'period'
                                                  ? 'compareTogglebtnStyleActive'
                                                  : null
                                              }
                                              checked={comparedTo === 'period'}
                                              onClick={() => {
                                                handleCompareToggle('period');
                                                setPeriodChecked(
                                                  !isPeriodChecked
                                                );
                                              }}
                                            />
                                            <label
                                              className="form-check-label"
                                              htmlFor="formCheck1"
                                            >
                                              {props.t('previousperiod')}
                                            </label>
                                          </div>
                                          <div className="form-check mb-3">
                                            <input
                                              style={{
                                                width: '1em',
                                                height: '1em',
                                                marginTop: '0.25em',
                                                verticalAlign: 'top',
                                                backgroundColor: '#fff',
                                                backgroundRepeat: 'no-repeat',
                                                backgroundPosition: 'center',
                                                border: '#000000',
                                              }}
                                              type="checkbox"
                                              id="formCheck1"
                                              className={
                                                comparedTo === 'year'
                                                  ? 'compareTogglebtnStyleActive'
                                                  : null
                                              }
                                              checked={comparedTo === 'year'}
                                              onChange={() => {
                                                handleCompareToggle('year');
                                                setYearChecked(!isYearChecked);
                                              }}
                                            />
                                            <label
                                              className="form-check-label"
                                              htmlFor="formCheck1"
                                            >
                                              {props.t('Previousyear')}
                                            </label>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                )}
                                <br />
                                {comparedTo !== "" && (
                                  <small style={{
                                    display: 'flex',
                                    marginTop: '10px',
                                    backgroundColor: 'rgb(59 91 152 / 7%)',
                                    borderRadius: '5px',
                                    width: 'max-content',
                                    height: '30px',
                                    padding: "8px",
                                    fontWeight: 'bold',
                                  }}>
                                    {lastPeriod}
                                  </small>
                                )}
                              </div>
                            </div>
                          </div>
                        </InputGroup>
                      </FormGroup>
                    </div>
                  </div>
                </div>
              </div>
            </CardBody>
          </Card>

          <Row>
            <Col lg="12">
              <Row className="">
                {secCards.map((item) => (
                  <div className="dashboardMinorCardItem" key={item.id}>
                    {(item.name === 'Reach' || item.name === 'Reach') && (
                      <Card className="card-h-100">
                        <CardBody>
                          <div className="my-0 text-primary d-flex justify-content-between align-items-center">
                            {props.t(item.name)}
                            {item.name === 'Reach' && (
                              <img
                                className="img-intgration-icon"
                                src={InstagramLogo}
                              />
                            )}
                          </div>
                          <Row className="align-items-center">
                            <Col xs={6}>
                              <div className="mb-3">
                                <h4>{formatNumberWithSpaces(reach)}</h4>
                              </div>
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>
                    )}
                    {item.name === 'Impressions' && (
                      <Card className="card-h-100">
                        <CardBody>
                          <div className="my-0 text-primary d-flex justify-content-between align-items-center">
                            {props.t(item.name)}
                            {(item.name === 'Impressions' ||
                              item.name === 'Impressions') && (
                                <img
                                  className="img-intgration-icon"
                                  src={InstagramLogo}
                                />
                              )}
                          </div>
                          <Row className="align-items-center">
                            <Col xs={6}>
                              <div className="mb-3">
                                <h4>{formatNumberWithSpaces(impressions)}</h4>
                              </div>
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>
                    )}
                    {item.name === 'New followers' && (
                      <Card className="card-h-100">
                        <CardBody>
                          <div className="my-0 text-primary d-flex justify-content-between align-items-center">
                            {props.t(item.name)}
                            {(item.name === 'New followers' ||
                              item.name === 'New followers') && (
                                <img
                                  className="img-intgration-icon"
                                  src={InstagramLogo}
                                />
                              )}
                          </div>
                          <Row className="align-items-center">
                            <Col xs={6}>
                              <div className="mb-3">
                                <h4>{formatNumberWithSpaces(follower_count)}</h4>
                              </div>
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>
                    )}
                    {(item.name === 'Profile views' ||
                      item.name === 'Profile views') && (
                        <Card className="card-h-100">
                          <CardBody>
                            <div className="my-0 text-primary d-flex justify-content-between align-items-center">
                              {props.t(item.name)}
                              {item.name === 'Profile views' && (
                                <img
                                  className="img-intgration-icon"
                                  src={InstagramLogo}
                                />
                              )}
                            </div>
                            <Row className="align-items-center">
                              <Col xs={6}>
                                <div className="mb-3">
                                  <h4>{formatNumberWithSpaces(profile_views)}</h4>
                                </div>
                              </Col>
                            </Row>
                          </CardBody>
                        </Card>
                      )}
                    {(item.name === 'Website clicks' ||
                      item.name === 'Website clicks') && (
                        <Card className="card-h-100">
                          <CardBody>
                            <div className="my-0 text-primary d-flex justify-content-between align-items-center">
                              {props.t(item.name)}
                              {item.name === 'Website clicks' && (
                                <img
                                  className="img-intgration-icon"
                                  src={InstagramLogo}
                                />
                              )}
                            </div>
                            <Row className="align-items-center">
                              <Col xs={6}>
                                <div className="mb-3">
                                  <h4>{formatNumberWithSpaces(website_clicks)}</h4>
                                </div>
                              </Col>
                            </Row>
                          </CardBody>
                        </Card>
                      )}
                  </div>
                ))}
              </Row>
            </Col>
          </Row>

          <Row>
            <Card>
              <CardBody style={{ padding: "45px" }}>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}
                >
                  <h4 className="card-title mb-4">
                    {props.t(' Instagram Posts last 30 days ')}
                  </h4>
                  <img className="img-intgration-icon" src={InstagramLogo} />
                </div>
                <Space
                  style={{
                    marginBottom: 16,
                  }}
                >
                  <Button onClick={setAgeSort}>{props.t("Sort Impressions")}</Button>
                  <Button onClick={clearSorters}>{props.t("Clear sorters")}</Button>
                  <Button onClick={clearAll}>{props.t("Clear filters and sorters")}</Button>
                  <Dropdown overlay={menu} trigger={['click']}>
                    <Button>{props.t("Select Columns")}</Button>
                  </Dropdown>
                </Space>
                <Table style={{ border: "1px solid #e8e8e8" }}
                  columns={filteredColumns}
                  dataSource={instaPostData}
                  onChange={handleChange}
                  pagination={{
                    pageSize: 7, // Set the default page size
                    pageSizeOptions: pageSizeOptions, // Provide the options for users to select from
                    showSizeChanger: true, // Enable the page size selector
                    scroll: { y: 700 }, // Configure the vertical scroll if needed
                  }}
                  scroll={{ y: 700 }}
                />
              </CardBody>
            </Card>
          </Row>
          <Row>
            <Col xl={6}>
              <Card className="card-h-100">
                <CardBody>
                  <div className="d-flex flex-wrap align-items-center mb-4">
                    <h5 className="card-title me-2">
                      {props.t('Social Media Audience ( Gender )')}{' '}
                    </h5>
                  </div>
                  <Row className="align-items-center">
                    <div className="col-sm">
                      <div id="wallet-balance" className="apex-charts">
                        <Pie data={dataChart} />
                      </div>
                    </div>
                    <div className="col-sm align-self-center">
                      <div className="mt-4 mt-sm-0">
                        <div>
                          <p className="mb-2">
                            <i className="mdi mdi-circle align-middle font-size-10 me-2 text-success"></i>{' '}
                            {props.t('Female')}
                          </p>
                          <h6>
                            {femalePercentage} % ={' '}
                            <span className="text-muted font-size-14 fw-normal">
                              {genderdata && genderdata.F ? genderdata.F : 0}
                            </span>
                          </h6>
                        </div>

                        <div className="mt-4 pt-2">
                          <p className="mb-2">
                            <i className="mdi mdi-circle align-middle font-size-10 me-2 text-primary"></i>{' '}
                            {props.t('Male')}
                          </p>
                          <h6>
                            {malePercentage} % ={' '}
                            <span className="text-muted font-size-14 fw-normal">
                              {genderdata && genderdata.M ? genderdata.M : 0}
                            </span>
                          </h6>
                        </div>

                        <div className="mt-4 pt-2">
                          <p className="mb-2">
                            <i className="mdi mdi-circle align-middle font-size-10 me-2 text-info"></i>{' '}
                            {props.t('Unknown')}
                          </p>
                          <h6>
                            {underPercentage} % ={' '}
                            <span className="text-muted font-size-14 fw-normal">
                              {genderdata && genderdata.U ? genderdata.U : 0}
                            </span>
                          </h6>
                        </div>
                      </div>
                    </div>
                  </Row>
                </CardBody>
              </Card>
            </Col>
            <Col xl={6}>
              <Card className="card-h-100">
                <CardBody>
                  <div className="d-flex flex-wrap align-items-center mb-4">
                    <h5 className="card-title me-2">
                      {props.t('Social Media Audience ( Age )')}{' '}
                    </h5>
                  </div>
                  <Row className="align-items-center">
                    <div className="col-sm">
                      <div id="wallet-balance" className="apex-charts">
                        <Bar
                          data={chartData}
                          options={{
                            title: {
                              display: true,
                              text: props.t('Gender and Age Group Sum'),
                              fontSize: 20,
                            },
                            legend: {
                              display: true,
                            },
                            scales: {
                              yAxes: [
                                {
                                  ticks: {
                                    beginAtZero: true,
                                  },
                                  scaleLabel: {
                                    display: true,
                                    labelString: 'Count',
                                  },
                                },
                              ],
                            },
                          }}
                        />
                      </div>
                    </div>
                  </Row>

                </CardBody>

              </Card>
            </Col>
            <hr />
            <div className="d-print-none mt-4">
              <div className="float-end">
                <button type="button" onClick={exportToPDF} className="btn btn-primary waves-effect waves-light w-md">
                  <div className="d-flex align-items-center"> {/* Use Flexbox to align icon and text */}
                    <i className="mdi mdi-download d-block font-size-16" style={{ color: 'white' }}></i>
                    <span className="ms-2">{props.t("Download")}</span> {/* Add margin for space between icon and text */}
                  </div>
                </button>
              </div>
            </div>
          </Row>

        </Container>
      </div>
    </Row>
  );
};
InstagramDashboard.propTypes = {
  t: PropTypes.any,
};

export default withRouter(withTranslation()(InstagramDashboard));
