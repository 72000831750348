import React, { useEffect, useState } from "react"
import MetaTags from 'react-meta-tags';

import {
  Table,
  Row,
  Col,
  Card,
  CardBody,
  CardHeader
} from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { withTranslation } from "react-i18next"
import { withRouter } from "react-router-dom"
import ApolloClient from 'apollo-boost';
import { getGABrowsers } from "../../queries/GoogleAnalytics/Queries";
import urls from "../../routes/apiUrls";
const ApploCLI = new ApolloClient({
  uri: urls.analytics,
});
function GoogleAnalytics(props) {
  const [refetch, setRefetch] = useState(false);
  const [dataBrowsers, setDataBrowsers] = useState([]);
  useEffect(() => {
    ApploCLI.query({
      query: getGABrowsers,
      context: {
        headers: {
          Authorization: window.localStorage.getItem("token")
            ? `Bearer ${window.localStorage.getItem("token")}`
            : "",
        },
      },
      errorPolicy: "all",
      fetchPolicy: "network-only",
    }).then((data) => {
      if (data.data.browsers) {
        setDataBrowsers(data.data.browsers[0].data);
      } else {
        setRefetch(!refetch);
      }
    });
  }, []);
  const filteredBrowsers = dataBrowsers.filter((e) =>
    e
  )
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Google Analytics | Live-Metrics - E-com SaaS</title>
        </MetaTags>
        <div className="container-fluid">
          <Breadcrumbs title="Google Analytics" breadcrumbItem="Browsers" pageHeading="Browsers" />
          <Row>
            <Col col={12}>
              <Card>
                <CardHeader>

                </CardHeader>
                <CardBody>
                  <div className="table-responsive">
                    <Table className="table table-striped table-bordered">
                      <thead className="table-light">
                        <tr>
                          <th>{props.t("Browser Name")}</th>
                          <th>{props.t("Browser count")}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {filteredBrowsers.map((element, index) => (
                          <tr key={index}>
                            {/* <th scope="row">{index}</th> */}
                            <td>{element[0]}</td>
                            <td>{element[1]}</td>
                          </tr>
                        ))
                        }
                      </tbody>
                    </Table>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  )
}
export default withRouter(withTranslation()(GoogleAnalytics));
