import React, { useState, useEffect,useRef } from 'react';
import { Nav, CardBody, Row, Col, Card, CardHeader, Alert, Progress } from 'reactstrap';
import { Link } from 'react-router-dom';
import SimpleBar from 'simplebar-react';
import {  Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import MetaTags from 'react-meta-tags';
import Highlighter from 'react-highlight-words';
import { SearchOutlined } from '@ant-design/icons';
import Breadcrumbs from '../../components/Common/Breadcrumb';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import paginationFactory, {
  PaginationProvider, PaginationListStandalone,
  SizePerPageDropdownStandalone
} from 'react-bootstrap-table2-paginator';
import BootstrapTable from 'react-bootstrap-table-next';
import { useDispatch, useSelector } from 'react-redux';
import {
  getKeywordsRequest
} from '../../store/googleAds/actions';
import { withRouter } from "react-router-dom"
import { withTranslation } from "react-i18next"
import PropTypes from "prop-types";
import secureSection from '../../MangeSecurity/MemberSecurity';
import { Button, Space, Table, Menu, Dropdown,Input,Checkbox } from 'antd';
const GoogleAdsIcon = `${process.env.PUBLIC_URL}/images/integrations_images/GoogleADS.svg`;
function Keywordstable(props) {
  secureSection();
 // const { SearchBar } = Search;
  const dispatch = useDispatch();
  const { gaKeywords } = useSelector(state => state.GoogleAds);
  useEffect(() => {
    dispatch(getKeywordsRequest());
  }, []);

  let dataMaparamexTrackingNumbers = [];

  if (gaKeywords?.gaKeywords) { // Add optional chaining here
    dataMaparamexTrackingNumbers = gaKeywords.gaKeywords
      .filter(item => item.cost !== 0 || item.conversions !== 0) // Filter out items with cost and conversions both equal to 0
      .map((item) => {
        return item;
      }).flat();
  }
  // test ant table
  const calculateROAs = (record) => {
  const roasValue = (record.conversions_value / record.cost).toFixed(2);
  return roasValue !== '0.00' ? roasValue : '-';
};

  
  
  const [filteredInfo, setFilteredInfo] = useState({});
  const [sortedInfo, setSortedInfo] = useState({});
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef(null);
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: 'block',
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1677ff' : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: '#ffc069',
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  const handleChange = (pagination, filters, sorter) => {
    console.log('Various parameters', pagination, filters, sorter);
    setFilteredInfo(filters);
    setSortedInfo(sorter);
  };

  const clearAll = () => {
    setFilteredInfo({});
    setSortedInfo({});
    // make the columns  selected
    setSelectedColumns(columns.map((column) => column.key));
  };
  const clearSorters = () => {
    setSortedInfo({});
  };
  const setAgeSort = () => {
    setSortedInfo({
      order: 'descend',
      columnKey: 'impressions',
    });
  };
  const pageSizeOptions = ['10', '20', '30'];
  const columns = [
    {
      title: 'Keyword',
      dataIndex: 'keyword_text',
      key: 'keyword_text',
      onFilter: (value, record) => record.campaign_name.includes(value),
      ...getColumnSearchProps('keyword_text'),
      ellipsis: true,
      align: 'center',
      render: (text) => <strong>{text}</strong>,
    },
    {
      title: 'Campaigns',
      dataIndex: 'campaign_name',
      align: 'center',
      key: 'campaign_name',
      onFilter: (value, record) => record.campaign_name.includes(value),
      ...getColumnSearchProps('campaign_name'),
      ellipsis: true,
      render: (text) => <strong>{text}</strong>,
    },
    {
      title: 'conversions',
      dataIndex: 'conversions',
      key: 'conversions',
      align: 'center',
      sorter: (a, b) => a.conversions - b.conversions,
      sortOrder: sortedInfo.columnKey === 'conversions' ? sortedInfo.order : null,
      ellipsis: true,
      render: (text, record) => (
        <strong>
        {record.conversions && record.conversions
          ? record.conversions.toFixed(2)
          : '-'}
      </strong>
      ),
    },
    {
      title: 'Conversions Value',
      dataIndex: 'conversions_value',
      align: 'center',
      key: 'conversions_value',
      onFilter: (value, record) => record.address.includes(value),
      sorter: (a, b) => a.conversions_value - b.conversions_value,
      sortOrder: sortedInfo.columnKey === 'conversions_value' ? sortedInfo.order : null,
      ellipsis: true,
      render: (text, record) => (
        <strong>
        {record.conversions_value && record.conversions_value
          ? record.conversions_value.toFixed(2)
          : '-'}
      </strong>
      ),
    },
    {
      title: 'Cost',
      dataIndex: 'cost',
      key: 'cost',
      align: 'center',
      sorter: (a, b) => a.cost - b.cost,
      sortOrder: sortedInfo.columnKey === 'cost' ? sortedInfo.order : null,
      ellipsis: true,
      render: (text) => <strong>{text}</strong>,
      
    },
    {
      title: ' Clicks',
      dataIndex: 'clicks',
      key: 'clicks',
      sorter: (a, b) => a.clicks - b.clicks,
      sortOrder: sortedInfo.columnKey === 'clicks' ? sortedInfo.order : null,
      ellipsis: true,
      align: 'center',
      render: (text) => <strong>{text}</strong>,
    },
    {
      title: 'Impressions',
      dataIndex: 'impressions',
      align: 'center',
      key: 'impressions',
      sorter: (a, b) => a.impressions - b.impressions,
      sortOrder: sortedInfo.columnKey === 'impressions' ? sortedInfo.order : null,
      ellipsis: true,
      render: (text) => <strong>{text}</strong>,
    },
    
    {
      title: 'ROAs',
      dataIndex: 'ROAs',
      align: 'center',
      key: 'ROAs',
      sorter: (a, b) => a.ROAs - b.ROAs,
      sortOrder: sortedInfo.columnKey === 'ROAs' ? sortedInfo.order : null,
      ellipsis: true,
      render: (text, record) => (
        <strong>
          {calculateROAs(record)}
        </strong>
      ),
      
    },
    
    
  ];
  const [selectedColumns, setSelectedColumns] = useState(
    columns.map((column) => column.key)
  );

  const handleMenuClick = (clickedKey) => {
    const index = selectedColumns.indexOf(clickedKey);

    let updatedSelectedColumns = [];

    if (index === -1) {
      // Column was not selected before, add it to the selected columns
      updatedSelectedColumns = [...selectedColumns, clickedKey];
    } else {
      // Column was selected before, remove it from the selected columns
      updatedSelectedColumns = selectedColumns.filter(
        (key) => key !== clickedKey
      );
    }

    setSelectedColumns(updatedSelectedColumns);
  };

  const filteredColumns = columns.filter((column) =>
    selectedColumns.includes(column.key)
  );

  const menu = (
    <Menu>
    {columns.map((column) => (
      <Menu.Item
        key={column.key}
        onClick={() => handleMenuClick(column.key)}
      >
        <Checkbox
          checked={selectedColumns.includes(column.key)}
          style={{ marginRight: '8px' }}
        />
        <span
          style={
            selectedColumns.includes(column.key)
              ? { fontWeight: 'bold' }
              : {}
          }
        >
          {column.title}
        </span>
      </Menu.Item>
    ))}
  </Menu>
  );
  return (
    <div className="page-content">
      <MetaTags>
        <title>{props.t("Keywords Data")} | Convergen-Metrics - E-com SaaS</title>
      </MetaTags>
      <div className="container-fluid">
        <Breadcrumbs
          title={props.t("Google Ads")}
          breadcrumbItem={props.t("Keywords Data")}
          pageHeading={props.t("Keywords Data")}
        />

      </div>
      <Row>
        <div className="card">
          <div className="card-header align-items-center d-flex">
            <h4 className="card-title mb-0 flex-grow-1">{props.t("Keywords Perfermonce")} {" "}
              <i class="fa fa-info-circle text-primary" aria-hidden="true" data-tooltip={props.t("This is last 30 days' data")}></i>
            </h4>
            <div className="flex-shrink-0">
              <Nav className="justify-content-end nav-tabs-custom rounded card-header-tabs" role="tablist">
                <img className="img-intgration-icon" src={GoogleAdsIcon} />
              </Nav>
            </div>
          </div>
          <CardBody>
            <Space style={{ marginBottom: 16 }}>
              <Button onClick={setAgeSort}>{props.t('Sort Impressions')}</Button>
              <Button onClick={clearSorters}>{props.t("Clear sorters")}</Button>
              <Button onClick={clearAll}>{props.t('Clear filters and sorters')}</Button>
              <Dropdown overlay={menu} trigger={['click']}>
                <Button>{props.t('Select Columns')}</Button>
              </Dropdown>
            </Space>
            <Table
              style={{ border: '1px solid #e8e8e8' }}
              columns={filteredColumns}
              dataSource={dataMaparamexTrackingNumbers}
              onChange={handleChange}
              pagination={{
                pageSize: 7,
                pageSizeOptions: pageSizeOptions,
                showSizeChanger: true,
                scroll: { y: 700 },
              }}
              scroll={{ y: 700 }}
            />
          </CardBody>
        
        </div>
      </Row>
    </div>
  );
}

Keywordstable.propTypes = {
  t: PropTypes.any,
};

export default withRouter(withTranslation()(Keywordstable));
//export default Keywordstable;
