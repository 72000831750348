import gql from 'graphql-tag';

const GetTrackingNumbers = gql `query{
    GetTrackingNumbers{
        id

    }
}  
` 
const GetTrackingStatus = gql `query{
    getTrackingStatus{
        id
        AccountNumber
        trackingNumber
    }
}  
` 

const getall =  gql `query($limit:Int!,$page:Int!){
    AramexAll(limit:$limit,page:$page){
        type , 
        pages,
        aramex{
            accountNumber,
            account,
            trackingNumber{
               order_id,
               id_customer,
               shipping_number,
               username,
               email,
               updatedtcode,
               updateddiscription
               
            }
        }
        
    }
}
`
const checkSales = gql `query{
    get{
        id,
        type,
        isDeleted,
        active
        UserName,
        Password,
        AccountNumber,
        AccountPin,
        AccountEntity,
        AccountCountryCode
        
    }
}
`
const getAmarexSousAccount = gql `query{
    getAramexShops{
        id,
        AccountNumber,
        AccountPin,
        AccountEntity,
        AccountCountryCode,
        UserName,
        Password,
        active
    }

} 
`
const deleteAramexIntegration = gql `mutation{
    deleteAramexAccount{
        isDeleted

    }
 

}
`
const toggleAramexShop = gql `mutation($AccountNumber: String!){
    toggelAramexAccount(AccountNumber: $AccountNumber){
        active
    }
}
`

const addingAramexAccount = gql `mutation($UserName: String!, $Password: String!, $AccountNumber: String!, $AccountPin: String!, $AccountEntity: String!, $AccountCountryCode: String!){
    addAramexAccount(UserName: $UserName, Password: $Password, AccountNumber: $AccountNumber, AccountPin: $AccountPin, AccountEntity: $AccountEntity, AccountCountryCode: $AccountCountryCode){
        UserName,
        Password,
        AccountNumber,
        AccountPin,
        AccountEntity,
        AccountCountryCode,
    }
}`;
export  {
    addingAramexAccount,
    checkSales,
    getAmarexSousAccount,
    toggleAramexShop,
    deleteAramexIntegration,
    getall,
    GetTrackingNumbers,
    GetTrackingStatus
};