import React, { useEffect } from 'react';
import { CardBody, Col, CardHeader, CardTitle, Card, Row } from 'reactstrap';
import { Pie } from "react-chartjs-2"
import { useDispatch, useSelector } from 'react-redux';
import { fetchOrderStatusRequest } from '../../../store/woocommerce/actions';
import MetaTags from 'react-meta-tags';
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import WooOrdersStatusTable from './WooOrdersStatusTable';
import { withRouter } from "react-router-dom"
import { withTranslation } from "react-i18next"
import PropTypes from "prop-types";
import secureSection from '../../../MangeSecurity/MemberSecurity';
const WooCommercepIcon = `${process.env.PUBLIC_URL}/images/integrations_images/WooCommerce.svg`;
function WooOrdersStatus(props) {
  secureSection();
  const dispatch = useDispatch();
  const { orderStatus } = useSelector((state) => state.woocommerce);
  useEffect(() => {
    const savedOrderStatus = localStorage.getItem('wooorderstatus');
    if (savedOrderStatus) {

      dispatch({ type: 'FETCH_ORDER_STATUS_SUCCESS', payload: JSON.parse(savedOrderStatus) });
    } else {
      dispatch(fetchOrderStatusRequest(15, 1));
    }
  }, [dispatch]);
  const dataMapProductCategoryName = orderStatus?.map((item) => { return item.orderStatus.name });
  const dataMapProductCategoryCount = orderStatus?.map((item) => { return item.orderStatus.total });
  const data = {
    labels: dataMapProductCategoryName,
    datasets: [
      {
        data: dataMapProductCategoryCount,
        backgroundColor: ["#ebeff2", "#FF5733", "#EC1A74", "#F6B43B", " #34c38f", "#3B5B98", "	#000000", "#0E6655", "#76448A", "#EA2427 "],
        hoverBackgroundColor: ["#ebeff2", "#FF5733", "#EC1A74", "#F6B43B", "#34c38f", "#3B5B98", "	#000000", "#0E6655", "#76448A", "#EA2427"],
        hoverBorderColor: "#fff",
      },
    ],
  }
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>{props.t("woo orders status")} | Live-Metrics - E-com SaaS</title>
        </MetaTags>
        <div className="container-fluid">
          <Breadcrumbs title={props.t("WooCommerce")} breadcrumbItem={props.t("Orders Status")} pageHeading={props.t("Orders Status")} />
          <Row >
          <Col xl={6}>
            <Card>
              <CardHeader>
                <CardTitle className="d-flex">
                  <h4 className="card-title mb-0 flex-grow-1"> {props.t("Orders Status")}</h4>
                  <img className="img-intgration-icon" src={WooCommercepIcon} />
                </CardTitle>
              </CardHeader>
              <CardBody>
                <Pie width={474} height={260} data={data || []} />
              </CardBody>
            </Card>
          </Col>
          <Col xl={6}>
            <WooOrdersStatusTable />
            </Col>
            </Row>
        </div>
      </div>
    </React.Fragment>
  );
}
WooOrdersStatus.propTypes = {
  t: PropTypes.any
};
export default withRouter(withTranslation()(WooOrdersStatus));
//export default WooOrdersStatus;