import React from "react"
import { Redirect } from "react-router-dom"
import PageMaintenance from "../pages/Utility/PageMaintenance";
import Error404 from "../pages/Utility/Error404";
import Error500 from "../pages/Utility/Error500";
import Login from "../pages/Authentication/Login"
import Logout from "../pages/Authentication/Logout"
import Register from "../pages/Authentication/Register"
import ForgetPwd from "../pages/Authentication/ForgetPassword"
import Accounts from "../pages/Accounts/Accounts";
import Profile from "../pages/Accounts/Profile";
import Stores from "../pages/Accounts/Stores";
import Members from '../pages/Accounts/Members/Members';
import PrestaShopDashboard from "../pages/PrestaDashbaord";
import AllProducts from "../pages/PrestaDashbaord/Products/AllProducts";
import Advisor from "../pages/Advisor/Advisor";
import AllOrders from "../pages/PrestaDashbaord/Orders/AllOrders";
import Integrationns from "../pages/Integrations"
import Socials from "../pages/Socials/Socials";
import Operations from "../pages/Reports/Operations";
import Strategic from "../pages/Reports/Strategic";
import ProfitandLossTable from "../pages/Reports/ProfitAndLoss";
import NProfitandLossTable from "../pages/Reports/NProfitAndLoss";

import Costs from "../pages/Costs/Costs";
import GoogleDashboard from "../pages/GoogleDashboard";
import WooDashboard from "../pages/WooDashbaord";
import KlaviyoDashboard from "../pages/KlaviyoDashboard";
import GoogleAnalytics from "../pages/GoogleAnalytics";
import GaTrackingPage from "../pages/GoogleAnalytics/TrackingPage/GATrackingPage";
import GaUsers from "../pages/GoogleAnalytics/gaUsers/GAUsers";
import GAAudience from "../pages/GoogleAnalytics/gaAudience";
import GAEventTracking from "../pages/GoogleAnalytics/gaEventTracking";
import GAEventActionTracking from "../pages/GoogleAnalytics/gaEventActionTracking";
import GAAdWords from "../pages/GoogleAnalytics/gaAdWords";
import gaEcommerceAffiliations from "../pages/GoogleAnalytics/gaEcommerceAffiliations";
import GAOperatingSystem from "../pages/GoogleAnalytics/gaPlatformAndDevice/GAOperatingSystem";
import GAMobileDeviceBranding from "../pages/GoogleAnalytics/gaPlatformAndDevice/GAMobileDeviceBranding";
import GADeviceCategoryAndDataSource from "../pages/GoogleAnalytics/gaPlatformAndDevice/GADeviceCategoryAndDataSource";
import OverviewDashboard from "../pages/OverviewDashboard";
import WooCoupons from "../pages/WooDashbaord/WooCoupons/wooCoupons";
import WooTopSellers from "../pages/WooDashbaord/WooTopSellers/WooTopSellers";
import AramexTracking from "../pages/AramexDashboard/index";
import WooOrdersStatus from "../pages/WooDashbaord/WooOrdersStatus/WooOrdersStatus";
import WooProductCategory from "../pages/WooDashbaord/Charts/ProductCategoryChart/ProductCategoryChart";
import Index from "../pages/EmailBuilder/EmailEditor";
import CampaignsDetails from "../pages/Socials/CampaignsDetails";
import GoogleCampaignsDetails from "../pages/GoogleDashboard/googleCampaigns Details/index";
import GoogleadsDetaills from "../pages/GoogleDashboard/googleAds Details/index";
import InvoicePreview from "../pages/GoogleDashboard/Report";
import Adstable from "../pages/GoogleDashboard/adTable";
import Campaignstable from "../pages/GoogleDashboard/campaignTable";
import Keywordstable from "../pages/GoogleDashboard/keywordsTable";
import fbcampaigns from "../pages/Socials/campaignTable";
import InvoiceDetailfacebook from "../pages/Socials/Report";
import FbSocialMediaDashboard from "../pages/facebookSocialMediaDashboard/fbSocialMediaDashboard";
import InstagramDashboard from "../pages/InstagramDashboard/InstagrammDashboard";
import MetaAds from "../pages/Socials/MetaAds";
import Billing from "../pages/Accounts/Billing"; 
import BillingDetails from "../pages/Accounts/BillingDetails";
import TwoStepVerification from "../pages/Authentication/TwoStepVerification";
const userRoutes = [
  //Integrations
  {path:"/integrations", component:Integrationns},
  //Settings 
  { path: "/settings-accounts", component: Accounts },
  { path: "/settings-profile", component: Profile },
  { path: "/settings-stores", component: Stores },
  { path: "/settings-members", component: Members },
  { path: "/billing", component: Billing },
  { path: "/billing-details", component: BillingDetails },
  
  //Ads Dashboard 
  {path: "/ads-dashboard", component:  Socials},
  //PrestaShop Dashboards 
  {path: "/prestashop-dashboard", component: PrestaShopDashboard},
  {path: "/prestashop-all-products", component: AllProducts},
  {path: "/prestashop-all-orders", component: AllOrders},
  //Advisor 
  {path:"/advisor", component: Advisor },
  //WooCommerce Dashboard 
  {path:"/woo_dashboard", component :WooDashboard},
  {path:"/woo_coupons", component:WooCoupons},
  {path:"/woo_top_sellers",component:WooTopSellers},
  {path:"/woo_orders_status",component:WooOrdersStatus},
  {path:"/woo_product_category",component:WooProductCategory},
  //Klaviyo Dashboard 
  {path:"/Klaviyo-Dashboard",component:KlaviyoDashboard},
  {path:"/Aramex-Dashboard",component:AramexTracking},
  //Reports
  {path: "/reports-opertations", component: Operations},
  {path: "/reports-strategic", component: Strategic},
  {path: "/reports-pnl", component: ProfitandLossTable},
  {path: "/reports-Npnl", component: NProfitandLossTable},

  //Google Analytics 
  {path: "/google-analytics", component: GoogleAnalytics},
  {path: "/google-analytics-tracking-page", component: GaTrackingPage},
  {path: "/google-analytics-users", component: GaUsers},
  {path: "/google-analytics-audience", component: GAAudience},
  {path: "/google-analytics-event-tracking", component: GAEventTracking},
  {path: "/google-analytics-event-action-tracking", component: GAEventActionTracking},
  {path: "/google-analytics-ad-words", component: GAAdWords},
  {path: "/google-analytics-ecommerce-affiliation", component: gaEcommerceAffiliations},
  {path: "/google-analytics-operating-system", component: GAOperatingSystem},
  {path: "/google-analytics-mobile-device-branding", component: GAMobileDeviceBranding},
  {path: "/google-analytics-device-category-data-source", component: GADeviceCategoryAndDataSource},
  //Overview Dashboard 
  {path: "/overview-dashboard", component: OverviewDashboard},
  //Costs 
  {path: "/costs", component: Costs},
  // Google Dashboard 
  {path:"/google-dashboard",component: GoogleDashboard},
  {path:"/GoogleCampaignsDetails",component: GoogleCampaignsDetails},
  {path:"/GoogleadsDetaills",component: GoogleadsDetaills},
  { path: "/GoogleReportDetail", component: InvoicePreview},
    // Email Builder 
    {path: "/email-builder", component: Index },
    // facebook ads campaigns 
    {path: "/fb-campaigns", component: fbcampaigns},
    {path: "/fb-campaigns-details", component: CampaignsDetails},
    {path: "/fb-campaigns-invoice", component: InvoiceDetailfacebook},

    // google ads campaigns data 
    {path: "/google-campaigns-details", component: Campaignstable },
    // google ads ads data 
    {path: "/google-ads-details", component: Adstable },
    // google ads keywords data
    {path: "/google-keywords-details", component: Keywordstable},
    // facebook social media dashboard s
    {path: "/fb-social-media-dashboard", component: FbSocialMediaDashboard},
    //
    {path: "/InstagramDashboard", component: InstagramDashboard},
    //
    {path: "/MetaAds", component: MetaAds},
    
    

  // this route should be at the top ( first one to display) of all other routes
  { path: "/", exact: true, component: () => <Redirect to="/integrations" /> }
  

  
]
const authRoutes = [
  //authencation page
  { path: '/logout', component: Logout },
  { path: '/login', component: Login },
  { path: '/forgot-password', component: ForgetPwd },
  { path: '/register', component: Register },
  { path: '/verif/:id/:verifCode', component: TwoStepVerification },

  //Utility page
  { path: '/pages-maintenance', component: PageMaintenance },
  { path: '/pages-404', component: Error404 },
  { path: '/pages-500', component: Error500 },
];
export { userRoutes, authRoutes }
