import React, { useState, useEffect } from 'react';
import { Card, CardBody, Col, Container, Row, Modal, Input, Table, FormGroup } from "reactstrap";
import ApolloClient from 'apollo-boost';
import { Tbody, Th, Thead, Tr } from 'react-super-responsive-table';
import { getFromFacebookSocialMedia, getFacebookSocialMedia, getFacebookSocialMediaAccounts, deletePlatformFacebookSocialMedia, toggleFbSocialMediaAccount } from '../../../queries/FacebookSocialMedia/queries';
import {getFromInstagramSocial, getInstagramSocialMedia, getInstagramocialMediaAccounts, deletePlatformInstagramSocialMedia, toggleInstaSocialMediaAccount} from "../../../queries/instagramSocialMedia/queries"
import urls from '../../../routes/apiUrls';
import InstaragmSocialMediaAccount from './InstagramAccount';
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
const InstagramLogo = `${process.env.PUBLIC_URL}/images/integrations_images/Insta1.svg`;

const ApploCLI = new ApolloClient({
  uri : urls.instagramSocialMedia
})

function InstagramSocialMediaCard(props) {
    const [instaData, setinstaData] = useState([]);
    const [instaDataRecover, setinstaDataRecover] = useState([]);
    const [filterStr, setFilterStr] = useState("");
    const [info, setInfo] = useState(null);
    const [loading, setLoading] = useState(true);
    const [loadingP, setLoadingP] = useState(true);
    const [modal_standard, setmodal_standard] = useState(false);
    const [activeAccount, setActiveAccount] = useState({});
    const [step, setStep] = useState(1);
    const [deleteLoading, setDeleteLoading] = useState(false);
    const [url, setUrl] = useState("");
    const [reachedFinalStep, setReachedFinalStep] = useState(false);

    const integrateFacebookSocialMedia = (facebook_code) => {
      try {
        ApploCLI.mutate({
          mutation: getFromInstagramSocial,
          context: {
            headers: {
              Authorization: window.localStorage.getItem("token")
                ? `Bearer ${window.localStorage.getItem("token")}`
                : "",
            },
          },
          variables: {
            code: facebook_code ? facebook_code : ""
          },
          errorPolicy: "all",
        }).then((data) => {
          console.log(data);
          if (data.data.integration !== null) {
            window.location.replace('/integrations')
            setStep(2);
            setReachedFinalStep(true);
          }
        })
      } catch (error) {
        console.log(error);
      }
    };

  // add instagram social media connection 
  const addConnection = () => {
    const FACEBOOK_APP_GRAPH_VERSION = 'v18.0';
    const FACEBOOK_APP_ID = 1188499042109576;
    const FACEBOOK_APP_REDIRECT = window.location.hostname.includes("app.live-metrics.io") ? `https://${window.location.hostname}/integrations` : `http://${window.location.hostname}:3000/integrations`;
    const FACEBOOK_APP_REDIRECT_STATIC = "https://app.live-metrics.io/integrations"
    const connectUrl = `https://www.facebook.com/${FACEBOOK_APP_GRAPH_VERSION}/dialog/oauth?client_id=${FACEBOOK_APP_ID}&redirect_uri=${FACEBOOK_APP_REDIRECT_STATIC}&scope=ads_read,read_insights,pages_read_engagement,pages_show_list,catalog_management,pages_manage_cta,ads_management,business_management,pages_messaging,pages_manage_metadata,pages_read_user_content,pages_manage_ads,pages_manage_posts,pages_manage_engagement`
    setLoading(true);
    let windowObjectReference = null;
    let previousUrl = null;
    let interval = null;
    const strWindowFeatures = "toolbar=no, menubar=no, width=700, height=700, top=100, left=100";
    if (windowObjectReference === null || windowObjectReference.closed) {
      windowObjectReference = window.open(connectUrl, "", strWindowFeatures);
      var timer = setInterval(function () {
        if (windowObjectReference.closed) {
          setLoading(false);
          clearInterval(timer);
          console.log("windowObjectReference (timer) IF", windowObjectReference)
        }
      }, 1000);

    } else {
      console.log("windowObjectReference ESLE", windowObjectReference);
      var timer = setInterval(function () {
        if (windowObjectReference.closed) {
          setLoading(false);
          clearInterval(timer);
          console.log("windowObjectReference (timer) ELSE", windowObjectReference)
        }
      }, 1000);

    }
    interval = window.setInterval(() => {
      try {
        let temp = windowObjectReference.location;
        windowObjectReference.focus();
        windowObjectReference.onload = function () { }
        if (temp.hostname === "localhost" || temp.hostname === "app.live-metrics.io") {
          window.clearInterval(interval);
          let code = temp.href.split("?")[1].split("&")[0].split("=")[1];
          console.log("code interval", code)
          integrateFacebookSocialMedia(code);
          windowObjectReference.close();
          setLoading(false);
          setStep(2);
          setReachedFinalStep(true);
          window.location.reload();
        }
      } catch (error) {
        console.log("windowObjectReference error", error);
      }
    }, 1000);
    previousUrl = url;
    if (window.top == window.self) {
      console.log("window", window)
    } else {
      console.log("here")
    }
  }

  // get instagram social media pages 
  useEffect(() => {
    let isSubscribed = true
    if (info !== null) {
      ApploCLI.query({
        query: getInstagramocialMediaAccounts,
        context: {
          headers: {
            Authorization: window.localStorage.getItem("token") ? `Bearer ${window.localStorage.getItem("token")}` : ""
          },
        },
        errorPolicy: "all",
        fetchPolicy: "network-only",
      }).then((data) => {
        setLoadingP(false);
        if (true) {
          if (data.data.instagramSocialMediaAccounts) {
            let dataActiveAccount = data.data.instagramSocialMediaAccounts;
            console.log("dataActiveAccountinstagram",dataActiveAccount);
            setinstaData(data.data.instagramSocialMediaAccounts);
            setinstaDataRecover(data.data.instagramSocialMediaAccounts);
            let x = dataActiveAccount.filter((account) => {
              return (account.active === true);
            })
            if (x.length) {
              setActiveAccount(x)
               setStep(3)
            } else {
               setStep(2)
            }
          }
        }
      });
    }
    return () => (isSubscribed = false)
  }, [info])

  // delete connection
  const deleteConnection = () => {
    ApploCLI.mutate({
      mutation: deletePlatformInstagramSocialMedia,
      context: {
        headers: {
          Authorization: window.localStorage.getItem("token") ? `Bearer ${window.localStorage.getItem("token")}` : ""
        }
      },
      errorPolicy: 'all',
    }).then((data) => {
      if (data.data.delete.isDeleted)
        window.location.replace('/integrations');
      setInfo(null);
    })
  };
  useEffect(() => {
    let isSubscribed = true
    ApploCLI.query({
      query: getFacebookSocialMedia,
      context: {
        headers: {
          Authorization: window.localStorage.getItem("token") ? `Bearer ${window.localStorage.getItem("token")}` : ""
        },
      },
      errorPolicy: "all",
      fetchPolicy: "network-only"
    }).then((data) => {
      if (isSubscribed) {
        if (data.data.one) {
          setInfo(data.data.one);
          setLoading(false);
          setStep(2)
        }
        else {
          setStep(1)
        }
      }
    });
    return () => (isSubscribed = false)
  }, [])
  function tog_standard() {
    setmodal_standard(!modal_standard)
    removeBodyCss()
  }
  console.log("********instaDataRecover***********",instaDataRecover);
  const filteredAccounts = instaDataRecover.filter((e) =>
  e.instagram_username && e.instagram_username.toUpperCase().includes(filterStr.toUpperCase())
);
  function removeBodyCss() {
    document.body.classList.add("no_padding")
  }

  console.log("********filteredAccounts***********",filteredAccounts);

  const firstStep = () => {
    return (
      <React.Fragment>
        <div className="">
          <Container fluid>
            <Row>
              <Col>
                <Card className="border text-secondary">
                  <CardBody>
                    <div className="my-0 text-primary d-flex justify-content-between align-items-center">
                      <div className='text-left d-flex align-items-center'>
                        <img className="img-intgration-icon" src={InstagramLogo} />
                        <span className="text-primary h4 mb-0 ml-2">Instagram Social Media</span>
                      </div>
                      <button onClick={()=> addConnection()} className="btn btn-primary">{props.t("Connect")}</button>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    )
  };
  const secondStep = () => {
    return (
      <React.Fragment>
        <div className="">
          <Container fluid>
            <Row>
              <Col>
                <Card className="border text-secondary">
                  <div className="card-header bg-transparent border-secondary">
                    <div className="my-0 text-primary d-flex justify-content-between align-items-center">
                      <h5 className="my-0 text-primary">
                      Instagram Social Media
                      </h5>
                      <img className="img-intgration-icon" src={InstagramLogo} />
                    </div>
                  </div>
                  <FormGroup className="mb-3">
                    <div style={{ textAlign: "center", padding: "20px" }}>
                      {instaDataRecover && instaDataRecover.length > 0 ? (
                        <div>
                          <form className="app-search d-none d-lg-block">
                            <div className="position-relative">
                              <Input

                                size="small"
                                onChange={(e) => setFilterStr(e.target.value)}
                                icon={<img name="search" link />}
                                placeholder="Search..."
                                style={{ float: "right", marginBottom: "20px" }}
                              />
                              <button className="btn btn-primary" type="button"><i
                                className="bx bx-search-alt align-middle"></i></button>
                            </div>
                          </form>

                          <Table
                            id="tech-companies-1"
                            className="table table-striped table-bordered"
                          >
                            <Thead>
                              <Tr>
                                <Th data-priority="3">{props.t("Page Name")}</Th>
                                <Th data-priority="1">{props.t("Active")}</Th>
                              </Tr>
                            </Thead>
                            <Tbody>
                              {filteredAccounts.map((element, index) => (
                                <InstaragmSocialMediaAccount element={element} key={index} info={info} instaData={instaData} />
                              ))}
                            </Tbody>
                          </Table>
                        </div>
                      ) : (
                        <div style={{ display: "grid" }}>
                          <div>
                            <h3>{props.t("No Facebook Data")}</h3>
                            <small>{props.t("Tryagain")}</small>
                          </div>
                        </div>
                      )}
                      {deleteLoading ? (
                        <img style={{ float: 'left', margin: 10 }} name="notched circle loading icon" />
                      ) : (
                        <div className='text-center'>
                          <button type="button" className="btn btn-danger waves-effect waves-light" onClick={() => deleteConnection()}>
                            <i className="bx bx-block font-size-16 align-middle"></i> {props.t("Delete Connection")}
                          </button>
                        </div>
                      )}
                    </div>
                  </FormGroup>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  };
  const thirdStep = () => {
    return (
      <React.Fragment>
        <div className="">
          <Container fluid>
            <Row>
              <Col>
                <Card className="border text-secondary">
                  <div className="card-header bg-transparent border-secondary">
                    <div className="my-0 text-primary d-flex justify-content-between align-items-center"><h5 className="my-0 text-primary">Instagram Social Media </h5><img className="img-intgration-icon" src={InstagramLogo} /></div>
                  </div>
                  <CardBody>
                    <Table id="tech-companies-1" className="table table-striped table-bordered" >
                      <Thead>
                        <Tr>
                          <Th data-priority="3">{props.t("Account Name")}</Th>
                          <Th data-priority="1">{props.t("Active")}</Th>
                        </Tr>
                      </Thead>
                      <Tbody>
                        {activeAccount.map((element, index) => (
                          <InstaragmSocialMediaAccount element={element} key={index} info={info} instaData={instaData} />
                        ))}
                      </Tbody>
                    </Table>
                  </CardBody>
                </Card>
              </Col>
              {deleteLoading ? (
                        <img style={{ float: 'left', margin: 10 }} name="notched circle loading icon" />
                      ) : (
                        <div className='text-center'>
                          <button type="button" className="btn btn-danger waves-effect waves-light" onClick={() => deleteConnection()}>
                            <i className="bx bx-block font-size-16 align-middle"></i> {props.t("Delete Connection")}
                          </button>
                        </div>
                      )}
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  };

  const renderSections = () => {
    switch (step) {
      case 3:
        return thirdStep();
      case 1:
        return firstStep();
      case 2:
        return secondStep();
      default:
        return firstStep();
    }
  };
  return <div>{renderSections()}</div>;
}
InstagramSocialMediaCard.propTypes = {
  t: PropTypes.any
};

export default withRouter(withTranslation()(InstagramSocialMediaCard));
//export default FacebookSocialMediaCard;