import {
    INTEGRATE_GOOGLE_ANALYTICS_FAILURE,
    INTEGRATE_GOOGLE_ANALYTICS_SUCCESS,
    INTEGRATE_GOOGLE_ANALYTICS_REQUEST
} from './actionsTypes'

const initialState = {
    loading: false,
    error: null,
    Step: 1,
    data: [],
    ReachedFinalStep: false
};

const googleAnalyticsReducer = (state = initialState, action) => {

    switch (action.type) {
        case INTEGRATE_GOOGLE_ANALYTICS_REQUEST:
            return {
                ...state,
                loading: true,
                error: null,
            };
        case INTEGRATE_GOOGLE_ANALYTICS_SUCCESS:
            return {
                ...state,
                Step: 2,
                data: action.payload,
                ReachedFinalStep: true,
            };
        case INTEGRATE_GOOGLE_ANALYTICS_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload
            }
        default:
            return state;
    }


}

export default googleAnalyticsReducer;
