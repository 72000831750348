import React, { useState, useEffect } from 'react';
import { integrate, checkSales, Fragment } from '../../../queries/Klaviyo/Queries';
import ApolloClient from "apollo-boost";
import urls from "../../../routes/apiUrls";
import { useDispatch, connect, useSelector } from 'react-redux';
import { integrationklaviyoRequest, deleteIntegrationklaviyoRequest } from '../../../store/Klaviyo/action'
import { Card, CardBody, Col, Container, Row, FormGroup, Input, Modal, Label, Alert, Table, Button, InputGroupText, InputGroup, ModalHeader, ModalBody } from "reactstrap";
import { deleteAlert, successAlert } from '../../Alerts/NotLinkedAccountAlert';
import KlaviyoIcon from "../Klaviyo/Klaviyo.png";
import Swal from 'sweetalert2';
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
// ** Icons Imports
import { Check, Link2, Key } from 'react-feather';





const ApploCLI = new ApolloClient({
  uri: urls.klaviyo,
});
const KlaviyoCard = (props) => {
  const data = [
    {
      icon: <Link2 className='icon' />,
      title: props.t('Allow Access 🤟🏻'),
      subtitle: props.t('Allow LiveMetrics to access to your Klaviyo  account ')
    },
    {
      icon: <Key className='icon' />,
      title: props.t('Add Your Api key  👩🏻‍💻'),
      subtitle: props.t('Just add your api key ')
    },
    {
      icon: <Check className='icon' />,
      title: props.t('Your Account is ready for use 🎉'),
      subtitle: props.t('You  will get 7 days free trial')
    }
  ]
  const dispatch = useDispatch();

  const [modal_standard, setmodal_standard] = useState(false);
  const [apiKey, setApiKey] = useState("");
  const [error, setError] = useState("");
  const [info, setInfo] = useState("");
  const [deleted, setDeleted] = useState(false);
  function tog_standard() {
    setmodal_standard(!modal_standard)
  }
  useEffect(() => {
    let isSubscribed = true
    ApploCLI.query({
      query: checkSales,
      context: {
        headers: {
          Authorization: window.localStorage.getItem("token") ? `Bearer ${window.localStorage.getItem("token")}` : ""
        }
      },
      errorPolicy: "all",
      fetchPolicy: "network-only"
    }).then((data) => {
      if (isSubscribed && data.data.get != null) {
        if (!data.data.get.isDeleted && data.data.get.active) {
          setInfo(data.data.get.active);
          setDeleted(true);
          if (!data.data.get.connectionstate) {
            Swal.fire({
              title: 'oops',
              text: "please check your check your Klaviyo credentials or contact  our support team ",
              icon: 'warning',
              showCancelButton: false,
            }).then(function (e) {

            })
          }
          console.log("data.data.get", data.data.get)
        } else {
          setInfo(data.data.get.isDeleted);
        }
        setApiKey(data.data.get.apiKey)
      }
    }).catch(err => console.log("klaviyo checkSales", err));
    return () => (isSubscribed = false)
  }, [])

  let integration = () => {
    if (apiKey === "") {
      setError("Consumer Key is required!")
      return;
    }
    console.log("Consumer Key is", apiKey)
    setError("");
    dispatch(integrationklaviyoRequest(apiKey))

  }
  let deleteIntegration = () => {
    deleteAlert('Klaviyo').then(function (e) {
      if (e.isConfirmed) {
        dispatch(deleteIntegrationklaviyoRequest())
      }
    })
  }
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col>

              <Card className="border text-secondary">
                <CardBody>
                  <div className="my-0 text-primary d-flex justify-content-between align-items-center">
                    <div className='text-left d-flex align-items-center'>
                      <img className="img-intgration-icon" src={KlaviyoIcon} />
                      <span className="text-primary h4 mb-0 ml-2">Klaviyo</span>
                    </div>

                    <div className="d-flex">
                      <button
                        className={info ? "btn btn-primary mr-2" : "btn btn-primary"}
                        onClick={() => { tog_standard() }}
                        data-toggle="modal"
                        data-target="#myModal"
                      >
                        {info ? (
                          <i className="bx bx-pencil font-size-16 align-middle text-white"></i>
                        ) : (
                          "connect"
                        )}
                      </button>

                      {deleted ? (

                        <button style={{ marginLeft: '10px' }}
                          type="button"
                          className="btn btn-danger waves-effect waves-light "
                          onClick={() => deleteIntegration()}
                        >
                          <i className="bx bx-block font-size-16 align-middle text-white"></i>
                        </button>

                      ) : null}

                    </div>

                    <Modal isOpen={modal_standard} toggle={() => tog_standard()} className='modal-dialog-centered modal-refer-earn modal-lg'>
                      <ModalHeader className='bg-transparent' toggle={() => tog_standard()}></ModalHeader>
                      <ModalBody className='pb-5 px-sm-0'>
                        <div className='text-center'>
                          <img style={{ height: '80px' }} src={KlaviyoIcon} />
                          <h1 className='text-center mb-1'>Klaviyo</h1>
                          <p className='text-center mb-5'>
                          {props.t('Connect your Klaviyo Store within 7 seconds')}
                          </p>
                          <Row className='mb-4'>
                            {data.map((item, index) => {
                              return (
                                <Col xs={12} lg={4} key={index}>
                                  <div className='d-flex justify-content-center mb-1'>
                                    <div className='circle'>
                                      <div className='icon'>
                                        {item.icon}
                                      </div>
                                    </div>
                                  </div>
                                  <div className='text-center'>
                                    <h6 className='fw-bolder mb-1'>{item.title}</h6>
                                    <p>{item.subtitle}</p>
                                  </div>
                                </Col>
                              );
                            })}
                          </Row>
                        </div>
                        <hr />
                        <div className='px-sm-5 mx-50'>
                          <h4 className='fw-bolder mt-5 mb-1'>{props.t('Enter Your Api Key' )}</h4>
                          <Row tag='form' className='g-1' onSubmit={e => e.preventDefault()}>
                            <Col lg='10'>
                              <InputGroup>

                                <Input
                                  type="text"
                                  className="form-control"
                                  id="validationTooltip01"
                                  placeholder={props.t("Api key")}
                                  onChange={e => setApiKey(e.target.value)}
                                  value={apiKey}

                                />
                              </InputGroup>
                            </Col>
                            <Col lg='2' className='d-flex align-items-end'>
                              <Button block color='primary' onClick={() => { integration() }}>
                              {props.t('Connect' )}
                              </Button>
                            </Col>
                          </Row>
                        </div>
                      </ModalBody>
                    </Modal>
                  </div>

                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment >
  )
}
KlaviyoCard.propTypes = {
  t: PropTypes.any
};

export default withRouter(withTranslation()(KlaviyoCard));
//export default KlaviyoCard;
