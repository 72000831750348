import jwt from "jsonwebtoken";

let baseSetting = ``;

if (window.location.hostname.includes("app.live-metrics.tn")) {

} else {

}

let page_path_list = {
  // "/prestashop-dashboard": "Dashboard",
  // "/ads-dashboard":"Ads - Dashboard",
  // "/reports-opertations": "Reports - Operations",
  // "/reports-strategic": "Reports - Strategic",
  // "/reports-pnl": "Reports - Profit & Loss",
  // "/advisor": "Advisor",
  // "/dashboard/products": "Products",
  // "/costs": "Costs",
  // "/integrations": "Integration",
  // "/settings-stores": "Manage Companies",
  // "/settings-members": "Manage Members",
  // "dashboard/plans&billing": "Plans & Billing",

  //social instagram facebook page 

  "/fb-social-media-dashboard": "social",
  "/InstagramDashboard": "social",

  //ads acces facebook and google

  "/google-dashboard":"ads platform",
  "/GoogleReportDetail":"ads platform",
  "/google-campaigns-details":"ads platform",
  "/google-ads-details":"ads platform",
  "/google-keywords-details":"ads platform",
  "/ads-dashboard":"ads platform",
  "/fb-campaigns-invoice":"ads platform",
  "/fb-campaigns":"ads platform",
  "/MetaAds":"ads platform",

  //sales platform
  "/prestashop-dashboard":"prestashop platform",
  "/prestashop-all-products":"prestashop platform",
  "/woo_dashboard":"woocommerce platform",
  "/woo_coupons":"woocommerce platform",
  "/woo_top_sellers":"woocommerce platform",
  "/woo_orders_status":"woocommerce platform",
  //aramex emailing 
  "/Aramex-Dashboard":"Aramex and emailing",
  "/email-builder":"Aramex and emailing",

}

let notEnoughPermission = (dataTokenized) => {
  const firstLink =
    dataTokenized &&
      dataTokenized.sections &&
      dataTokenized.sections.length > 0
      ? dataTokenized.sections[0]
      : "";
  switch (firstLink) {
    // case "Dashboard": window.location.href = `/prestashop-dashboard`; break;
    // case "Ads - Dashboard" : window.location.href = `/ads-dashboard` ; break; 
    // case "Reports - Operations": window.location.href = `/reports-opertations`; break;
    // case "Reports - Strategic": window.location.href = `/reports-strategic`; break;
    // case "Reports - Profit & Loss": window.location.href = `/reports-pnl`; break;
    // case "Advisor": window.location.href = `/advisor`; break;
    // case "Products": window.location.href = `/dashboard/products`; break;
    // case "Costs": window.location.href = `/costs`; break;
    // case "Integration": window.location.href = `/integrations`; break;
    // case "Manage Companies": window.location.href = `/settings-stores`; break;
    // case "Manage Members": window.location.href = `/settings-members`; break;
    // case "Plans & Billing": window.location.href = `/dashboard/plans&billing`; break;
    case "ads platform": window.location.href = `/ads-dashboard`; break;
    case "ads platform": window.location.href = `/google-dashboard`; break;
    case "ads platform": window.location.href = `/GoogleReportDetail`; break;
    case "ads platform": window.location.href = `/google-campaigns-details`; break;
    case "ads platform": window.location.href = `/google-ads-details`; break;
    case "ads platform": window.location.href = `/google-keywords-details`; break;
    case "ads platform": window.location.href = `/fb-campaigns-invoice`; break;
    case "ads platform": window.location.href = `/fb-campaigns`; break;
    case "ads platform": window.location.href = `/MetaAds`; break;
    case "social": window.location.href = `/fb-social-media-dashboard`; break;
    case "social": window.location.href = `/InstagramDashboard`; break;
    case "prestashop platform": window.location.href = `/prestashop-dashboard`; break;
    case "prestashop platform": window.location.href = `/prestashop-all-products`; break;
    case "woocommerce platform": window.location.href = `/woo_dashboard`; break;
    case "woocommerce platform": window.location.href = `/woo_coupons`; break;
    case "woocommerce platform": window.location.href = `/woo_top_sellers`; break;
    case "woocommerce platform": window.location.href = `/woo_orders_status`; break;
    case "Aramex and emailing": window.location.href = `/Aramex-Dashboard`; break;
    case "Aramex and emailing": window.location.href = `/email-builder`; break;

    default : window.location.href = `/pages-404`; break;
  }

}

let secureSection = () => {
  const dataTokenized = jwt.decode(window.localStorage.getItem("token"));
  if (dataTokenized.type && dataTokenized.type === "member") {
    if (!dataTokenized.sections.includes(page_path_list[window.location.pathname])) {
      notEnoughPermission(dataTokenized);
    }
  }
}

export default secureSection;
