import React, { useEffect, useState } from 'react'
import ApolloClient from 'apollo-boost';
import { Modal, FormGroup, Table, Input } from "reactstrap";
import { Tbody, Th, Thead, Tr } from 'react-super-responsive-table';
import { toggleGoogleProfile, getProfileAccounts } from '../../../queries/GoogleAnalytics/Queries';
import { getBrowsers } from '../../../queries/GoogleAnalytics/getBrowsers';
import urls from '../../../routes/apiUrls';
import ProfileAccountList from './ProfileAccountList';
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";

const ApploCLI = new ApolloClient({
    uri: urls.analytics
});
const ApploCLIBrowsers = new ApolloClient({
    uri: urls.gabrowsers,
});
function GoogleAnalyticsProfileAccount(props) {
    const [modal_standard, setmodal_standard] = useState(false);
    const [activeProfile, setActiveProfile] = useState([]);
    const [profileaccounts, setAccounts] = useState([]);
    const [refetch, setRefetch] = useState(false);
    const [filterStr, setFilterStr] = useState("");
    const [isActive, setIsActive] = useState(props.element.active ? true : false);
    const [page, setPage] = useState(1)
    const [pages, setPages] = useState(-1);
    const [loadingState, setLoadingState] = useState(true);
    const [isSearch, setIsSearch] = useState(false);
    const [browsers, setBrowsers] = useState([]);
    const [productsClone, setProductsClone] = useState([]);
    const toggleCampaign = (adAccountId) => {
        ApploCLI.mutate({
            mutation: toggleGoogleProfile,
            variables: {
                adAccount: adAccountId
            },
            context: {
                headers: {
                    Authorization: window.localStorage.getItem("token") ? `Bearer ${window.localStorage.getItem("token")}` : ""
                }
            },
            errorPolicy: 'all',
        }).then((data, err) => {
            if (data.data.toggleprofiles) {
                setIsActive(data.data.toggleprofiles.active);
                window.location.replace('/integrations');
            }
        }).catch((err) => {
            console.error("Error", err);
        })
    }
    useEffect(() => {
        if (!isSearch) {
            let isSubscribed = true
            setLoadingState(false);
            ApploCLIBrowsers.query({
                query: getBrowsers,
                variables: {
                    limit: 10,
                    page: page
                },
                context: {
                    headers: {
                        Authorization: window.localStorage.getItem("token")
                            ? `Bearer ${window.localStorage.getItem("token")}`
                            : "",
                    },
                },
                errorPolicy: "all",
                fetchPolicy: "no-cache",
            }).then((data) => {
                if (isSubscribed) {
                    if (data && data.data && data.data.all && data.data.all.length > 0) {
                        setPages(data.data.all[0].pages)
                        setBrowsers(data.data.all);
                        setProductsClone(data.data.all);
                    }
                    setLoadingState(true);
                }
            });
            return () => (isSubscribed = false)
        }
    }, [page]);
    useEffect(() => {
        ApploCLI.query({
            query: getProfileAccounts,
            context: {
                headers: {
                    Authorization: window.localStorage.getItem("token")
                        ? `Bearer ${window.localStorage.getItem("token")}`
                        : "",
                },
            },
            errorPolicy: "all",
            fetchPolicy: "network-only", 
        }).then((data) => {
            if (data.data.profileaccounts) {
                let active_profile_account = data.data.profileaccounts.filter((x) => { if (x.active === true) return x })
                if (active_profile_account.length > 0) {
                    setActiveProfile(active_profile_account);
                } else {
                    setAccounts(data.data.profileaccounts);
                    setRefetch(!refetch);
                }
            }
        })
    })
    function removeBodyCss() {
        document.body.classList.add("no_padding")
    }
    function tog_standard() {
        setmodal_standard(!modal_standard)
        removeBodyCss()
    }
    const filteredAccounts = profileaccounts.filter((e) =>
        e.name.toUpperCase().includes(filterStr.toUpperCase())
    );
    return (
        <tr className="">
            <td className="">{props.element.account_id}</td>
            <td className="">{props.element.name}</td>
            <td className='text-center'>
                {
                    <button
                        type="button"
                        onClick={() => {
                            tog_standard()
                        }}
                        className="btn btn-primary "
                        data-toggle="modal"
                        data-target="#myModal"
                    >
                        +
                    </button>
                }
                <Modal
                    size='xl'
                    isOpen={modal_standard}
                    toggle={() => {
                        tog_standard()
                    }}
                >
                    <div className="modal-header">
                        <h5 className="modal-title mt-0" id="myModalLabel">
                        {props.t("Google Profile Accounts")}
                        </h5>
                        <button
                            type="button"
                            onClick={() => {
                                setmodal_standard(false)
                            }}
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <FormGroup className="mb-3">
                            <div style={{ textAlign: "center", padding: "20px" }}>
                                {profileaccounts && profileaccounts.length > 0 ? (
                                    <div>
                                        <form className="app-search d-none d-lg-block">
                                            <div className="position-relative">
                                                <Input
                                                    size="small"
                                                    onChange={(e) => setFilterStr(e.target.value)}
                                                    icon={<img name="search" link />}
                                                    placeholder={props.t("Search...")}
                                                    style={{ float: "right", marginBottom: "20px" }}
                                                />
                                                <button className="btn btn-primary" type="button"><i
                                                    className="bx bx-search-alt align-middle"></i></button>
                                            </div>
                                        </form>
                                        <Table
                                            id="tech-companies-1"
                                            className="table table-striped table-bordered"
                                        >
                                            <Thead>
                                                <Tr>
                                                    <Th data-priority="2">{props.t("Profile Id")}</Th>
                                                    <Th data-priority="3">{props.t("Profile Name")}</Th>
                                                    <Th data-priority="4">{props.t("Web Prop Id")}</Th>
                                                    <Th data-priority="5">{props.t("Active")}</Th>
                                                </Tr>
                                            </Thead>
                                            <Tbody>
                                                {filteredAccounts.map((element, index) => (
                                                    <ProfileAccountList element={element} key={index} />
                                                ))}
                                            </Tbody>
                                        </Table>
                                    </div>
                                ) : (
                                    <Table
                                        id="tech-companies-1"
                                        className="table table-striped table-bordered"
                                    >
                                        <Thead>
                                            <Tr>
                                                <Th data-priority="2">{props.t("Profile Id")}</Th>
                                                <Th data-priority="3">{props.t("Profile Name")}</Th>
                                                <Th data-priority="4">{props.t("Web Prop Id")}</Th>
                                                <Th data-priority="5">{props.t("Active")}</Th>
                                            </Tr>
                                        </Thead>
                                        <Tbody>
                                            {activeProfile.map((element, index) => (
                                                <ProfileAccountList element={element} key={index} />
                                            ))}
                                        </Tbody>
                                    </Table>
                                )}
                            </div>
                        </FormGroup>
                    </div>
                    <div className="modal-footer">
                        <button
                            type="button"
                            onClick={() => {
                                tog_standard()
                            }}
                            className="btn btn-secondary "
                            data-dismiss="modal"
                        >
                            {props.t("Close")}
                        </button>
                    </div>
                </Modal>
            </td>
            <td className='text-center'>
                {
                    <div className="form-check form-switch mb-2" dir="ltr">
                        <input type="checkbox" className="form-check-input" checked={isActive} id="customSwitch1" onChange={(e, data) => { toggleCampaign(props.element.id); setIsActive(!isActive) }} />
                        <label className="form-check-label" htmlFor="customSwitch1" checked={isActive}></label>
                    </div>
                }
            </td>
        </tr>
    )
}
GoogleAnalyticsProfileAccount.propTypes = {
    t: PropTypes.any
  };
  
  export default withRouter(withTranslation()(GoogleAnalyticsProfileAccount));
//export default GoogleAnalyticsProfileAccount;