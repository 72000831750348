import React , {useState, useEffect} from "react";
import { Bar } from "react-chartjs-2"
import ApolloClient from 'apollo-boost';
import { getGAAudience } from "../../../queries/GoogleAnalytics/Queries";
import urls from "../../../routes/apiUrls";
const ApploCLI = new ApolloClient({
    uri: urls.analytics,
  });
const UserAgeBarChart = () => {
    const [refetch, setRefetch] = useState(false);
    const [userAge, setUserAge] = useState([]);
    useEffect(() => {
        ApploCLI.query({
          query: getGAAudience,
          context: {
            headers: {
              Authorization: window.localStorage.getItem("token")
                ? `Bearer ${window.localStorage.getItem("token")}`
                : "",
            },
          },
          errorPolicy: "all",
          fetchPolicy: "network-only", //allow me to refecth data
        }).then((data) => {
          if (data.data.gaaudiences) {
            setUserAge(data.data.gaaudiences[0].userAgeBracket);
          } else {
            setRefetch(!refetch);
          }
        });
      }, []);
      const dataMapGAAudienceAge = userAge.map((item,index) => { return item[0]});
      const dataMapGAAudienceAgeCount = userAge.map((item,index) => { return item[1]});
      const data = {
        labels: dataMapGAAudienceAge,
        datasets: [
          {
            label: "Age",
            backgroundColor: "#F6B43B",
            borderColor: "#F6B43B",
            borderWidth: 1,
            data: dataMapGAAudienceAgeCount,
          },
        ],
      }  
      const option = {
        scales: {
          dataset: [
            {
              barPercentage: 0.4,
            },
          ],
        },
      }
  return (
     <Bar width={474} height={300} data={data} options={option} />
  )
}
export default UserAgeBarChart;
