import React, { useState, useEffect } from 'react';
import { TabContent, TabPane, Nav, NavItem, NavLink, CardBody, Progress, Alert } from 'reactstrap';
import { Table, Thead, Tbody, Tr, Th } from "react-super-responsive-table";
import classnames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import SingleProduct from './Products/SingleProduct';
import SimpleBar from "simplebar-react"
import { fetchProductsRequest } from '../../store/prestaShop/actions';
const PrestaShopProducts = ()=> {
  const dispatch = useDispatch();
  const {  products } = useSelector((state) => state.prestashop);
  useEffect(() => {
    const savedProducts = localStorage.getItem('products');
    if(savedProducts!==null){
      dispatch({ type: 'FETCH_PRODUCTS_SUCCESS', payload: JSON.parse(savedProducts) });
    }else {
    dispatch(fetchProductsRequest(50, 1));
    }
  }, [dispatch]);
  const [activeTab, setActiveTab] = useState('1');
  const toggle = tab => {
    if (activeTab !== tab) setActiveTab(tab);
  }
  return (
    <React.Fragment>
      <div className="col-xl-4">
        <div className="card">
          <div className="card-header align-items-center d-flex">
            <h4 className="card-title mb-0 flex-grow-1">Products</h4>
            <div className="flex-shrink-0">
              <Nav className="justify-content-end nav-tabs-custom rounded card-header-tabs" role="tablist">
                <NavItem>
                  <NavLink href="/prestashop-all-products"
                    className={classnames({ active: activeTab === '1' })}
                    onClick={() => { toggle('1'); }}
                  >
                    All
                  </NavLink>
                </NavItem>
              </Nav>
            </div>
          </div>
          <CardBody className="px-0">
            <TabContent activeTab={activeTab}>
              <TabPane tabId="1">
                {products && products.length > 0 ? (
                  <SimpleBar className="table-responsive px-3" style={{ maxHeight: "600px" }}>
                    <Table className="table table-striped table-bordered">
                      <Thead>
                        <Tr>
                          <Th data-priority="1">Product Id</Th>
                          <Th data-priority="1">Name</Th>
                          <Th data-priority="1">Price</Th>
                          <Th data-priority="1">Quantity</Th>
                        </Tr>
                      </Thead>
                      <Tbody>
                        {products.map((element, index) => (
                          <SingleProduct element={element} key={index} />
                        ))}
                      </Tbody>
                    </Table>
                  </SimpleBar>
                ) : (
                  <div className='text-center'>
                    <div className="text-center">
                      <CardBody>
                        <Progress
                          value={80}
                          color="success"
                          style={{ width: '75%' }}
                          animated
                        ></Progress>
                        <Alert color="success" className="mt-3">
                          This might take a few minutes!
                        </Alert>
                      </CardBody>
                    </div>
                  </div>
                )}
              </TabPane>
            </TabContent>
          </CardBody>
        </div>
      </div>
    </React.Fragment>
  );
}
export default PrestaShopProducts;