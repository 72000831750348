import React, {useState, useEffect}from "react"
import MetaTags from 'react-meta-tags';
import {Row} from "reactstrap"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import ApolloClient from 'apollo-boost';
import urls from "../../../routes/apiUrls";
import { getGAAdWordAdGroup } from "../../../queries/GoogleAnalytics/Queries";
import GAAdWordsCard from "./GAAdWordsCard";

const ApploCLI = new ApolloClient({
    uri: urls.analytics,
  });
function GAAdWords() {
    const [adWordsDashboard, setAdWordsDashboard] = useState([]);
    const [loadingState, setLoadingState] = useState(false);
    const [impressions,setImpressions] = useState([]);
    const [clicks,setClicks]= useState([]);
    const [cost, setCost]= useState([]);
    const [cpm, setCpm] = useState([]);
    const [cpc,setCpc]= useState([]);
    const [ctr, setCtr]= useState([]);
    const [roas,setRoas] = useState([]);
    const [rpc,setRpc]= useState([]);
    const [costPerGoalConversion,setCostPerGoalConversion] = useState([]);
    const [costPerTransaction,setCostPerTransaction] = useState([]);
    const [costPerConversion,setCostPerConversion] = useState([]);
    useEffect(() => {
        let isSubscribed = true;
        ApploCLI.query({
          query: getGAAdWordAdGroup,
          context: {
            headers: {
              Authorization: window.localStorage.getItem("token")
                ? `Bearer ${window.localStorage.getItem("token")}`
                : "",
            },
          },
          errorPolicy: "all",
          fetchPolicy: "network-only", //allow me to refecth data
        }).then((data) => {
          if (isSubscribed) {
            if (data.data.gaadgroupwords) {
                setAdWordsDashboard(data.data.gaadgroupwords);
              setLoadingState(true);
            } else {
              setLoadingState(false);
            }
          }
        });
        return () => (isSubscribed = false);
      }, []);

      useEffect(() => {
        let isSubscribed = true;
        var Timpressions = [];
        var Tclicks = [];
        var Tcost = [];
        var Tcpm = [];
        var Tcpc = [];
        var Tctr = [];
        var Troas = [];
        var Trpc = [];
        var TcostPerGoalConversion = [];
        var TcostPerTransaction = [];
        var TcostPerConversion = [];
        if (adWordsDashboard.length !== 0) {
          adWordsDashboard.map((elem) => {
            Timpressions.push(elem.impressions ? (elem.impressions) : 0)
            Tclicks.push(elem.clicks ? (elem.clicks): 0)
            Tcost.push(elem.cost ? (elem.cost) : 0 )
            Tcpm.push(elem.cpm ? (elem.cpm) : 0)
            Tcpc.push(elem.cpc ? (elem.cpc) : 0)
            Tctr.push(elem.ctr ? (elem.ctr) : 0 )
            Troas.push(elem.roas ? (elem.roas): 0)
            Trpc.push(elem.rpc ? (elem.rpc) : 0)
            TcostPerGoalConversion.push(elem.costPerGoalConversion ? (elem.costPerGoalConversion): 0)
            TcostPerTransaction.push(elem.costPerTransaction ? (elem.costPerTransaction): 0)
            TcostPerConversion.push(elem.costPerConversion ? (elem.costPerConversion): 0)
          })
        }
        setImpressions(Timpressions);
        setClicks(Tclicks);
        setCost(Tcost);
        setCpm(Tcpm);
        setCpc(Tcpc);
        setCtr(Tctr);
        setRoas(Troas);
        setRpc(Trpc);
        setCostPerGoalConversion(TcostPerGoalConversion);
        setCostPerTransaction(TcostPerTransaction);
        setCostPerConversion(TcostPerConversion);    
        return () => (isSubscribed = false);
      }, [adWordsDashboard]);
      var op1 = {
        chart: {
          toolbar: {
            followCursor: true,
            show: false,
            tools: {
              download: true,
              selection: true,
              zoom: true,
              zoomin: true,
              zoomout: true,
              pan: true,
              reset: true,
            },
            tooltip: {
              shared: true,
              intersect: true,
              followCursor: true,
              x: {
                show: false,
              },
            },
            autoSelected: "zoom",
          },
          type: "area",
          height: 120,
          sparkline: {
            enabled: true,
          },
        },
        markers: {
          size: 0,
        },
        stroke: {
          curve: "straight",
          width: 2.5,
        },
        fill: {
          opacity: 1,
        },
        series: [{ name: "Impressions", data: impressions }],
        xaxis: {
          crosshairs: {
            width: 1,
          },
        },
        yaxis: {
          show: false,
        },   
        colors: ["#F6B43B", "#EC1A74"],
      };
      var op2 = {
        chart: {
          toolbar: {
            followCursor: true,
            show: false,
            tools: {
              download: true,
              selection: true,
              zoom: true,
              zoomin: true,
              zoomout: true,
              pan: true,
              reset: true,
            },
            tooltip: {
              shared: true,
              intersect: true,
              followCursor: true,
              x: {
                show: false,
              },
            },
            autoSelected: "zoom",
          },
          type: "area",
          height: 120,
          sparkline: {
            enabled: true,
          },
        },
        markers: {
          size: 0,
        },
        stroke: {
          curve: "straight",
          width: 2.5,
        },
        fill: {
          opacity: 1,
        },
        series: [{ name: "Clicks", data: clicks }],
        xaxis: {
          crosshairs: {
            width: 1,
          },
        },
        yaxis: {
          show: false,
        }, 
        colors: ["#F6B43B", "#EC1A74"],
      };
      var op3 = {
        chart: {
          toolbar: {
            followCursor: true,
            show: false,
            tools: {
              download: true,
              selection: true,
              zoom: true,
              zoomin: true,
              zoomout: true,
              pan: true,
              reset: true,
            },
            tooltip: {
              shared: true,
              intersect: true,
              followCursor: true,
              x: {
                show: false,
              },
            },
            autoSelected: "zoom",
          },
          type: "area",
          height: 120,
          sparkline: {
            enabled: true,
          },
        },
        markers: {
          size: 0,
        },
        stroke: {
          curve: "straight",
          width: 2.5,
        },
        fill: {
          opacity: 1,
        },
        series: [{ name: "Cost", data: cost }],
        xaxis: {
          crosshairs: {
            width: 1,
          },
        },
        yaxis: {
          show: false,
        },
    
        colors: ["#F6B43B", "#EC1A74"],
      };
      var op4 = {
        chart: {
          toolbar: {
            followCursor: true,
            show: false,
            tools: {
              download: true,
              selection: true,
              zoom: true,
              zoomin: true,
              zoomout: true,
              pan: true,
              reset: true,
            },
            tooltip: {
              shared: true,
              intersect: true,
              followCursor: true,
              x: {
                show: false,
              },
            },
            autoSelected: "zoom",
          },
          type: "area",
          height: 120,
          sparkline: {
            enabled: true,
          },
        },
        markers: {
          size: 0,
        },
        stroke: {
          curve: "straight",
          width: 2.5,
        },
        fill: {
          opacity: 1,
        },
        series: [{ name: "CPM", data: cpm }],
        xaxis: {
          crosshairs: {
            width: 1,
          },
        },
        yaxis: {
          show: false,
        },   
        colors: ["#F6B43B", "#EC1A74"],
      };
      var op5 = {
        chart: {
          toolbar: {
            followCursor: true,
            show: false,
            tools: {
              download: true,
              selection: true,
              zoom: true,
              zoomin: true,
              zoomout: true,
              pan: true,
              reset: true,
            },
            tooltip: {
              shared: true,
              intersect: true,
              followCursor: true,
              x: {
                show: false,
              },
            },
            autoSelected: "zoom",
          },
          type: "area",
          height: 120,
          sparkline: {
            enabled: true,
          },
        },
        markers: {
          size: 0,
        },
        stroke: {
          curve: "straight",
          width: 2.5,
        },
        fill: {
          opacity: 1,
        },
        series: [{ name: "CPC", data: cpc }],
        xaxis: {
          crosshairs: {
            width: 1,
          },
        },
        yaxis: {
          show: false,
        },  
        colors: ["#F6B43B", "#EC1A74"],
      };
      var op6 = {
        chart: {
          toolbar: {
            followCursor: true,
            show: false,
            tools: {
              download: true,
              selection: true,
              zoom: true,
              zoomin: true,
              zoomout: true,
              pan: true,
              reset: true,
            },
            tooltip: {
              shared: true,
              intersect: true,
              followCursor: true,
              x: {
                show: false,
              },
            },
            autoSelected: "zoom",
          },
          type: "area",
          height: 120,
          sparkline: {
            enabled: true,
          },
        },
        markers: {
          size: 0,
        },
        stroke: {
          curve: "straight",
          width: 2.5,
        },
        fill: {
          opacity: 1,
        },
        series: [{ name: "CTR", data: ctr }],
        xaxis: {
          crosshairs: {
            width: 1,
          },
        },
        yaxis: {
          show: false,
        },    
        colors: ["#F6B43B", "#EC1A74"],
      };
      var op7 = {
        chart: {
          toolbar: {
            followCursor: true,
            show: false,
            tools: {
              download: true,
              selection: true,
              zoom: true,
              zoomin: true,
              zoomout: true,
              pan: true,
              reset: true,
            },
            tooltip: {
              shared: true,
              intersect: true,
              followCursor: true,
              x: {
                show: false,
              },
            },
            autoSelected: "zoom",
          },
          type: "area",
          height: 120,
          sparkline: {
            enabled: true,
          },
        },
        markers: {
          size: 0,
        },
        stroke: {
          curve: "straight",
          width: 2.5,
        },
        fill: {
          opacity: 1,
        },
        series: [{ name: "ROAS", data: roas }],
        xaxis: {
          crosshairs: {
            width: 1,
          },
        },
        yaxis: {
          show: false,
        },   
        colors: ["#F6B43B", "#EC1A74"],
      };
      var op8 = {
        chart: {
          toolbar: {
            followCursor: true,
            show: false,
            tools: {
              download: true,
              selection: true,
              zoom: true,
              zoomin: true,
              zoomout: true,
              pan: true,
              reset: true,
            },
            tooltip: {
              shared: true,
              intersect: true,
              followCursor: true,
              x: {
                show: false,
              },
            },
            autoSelected: "zoom",
          },
          type: "area",
          height: 120,
          sparkline: {
            enabled: true,
          },
        },
        markers: {
          size: 0,
        },
        stroke: {
          curve: "straight",
          width: 2.5,
        },
        fill: {
          opacity: 1,
        },
        series: [{ name: "RPC", data: rpc }],
        xaxis: {
          crosshairs: {
            width: 1,
          },
        },
        yaxis: {
          show: false,
        },   
        colors: ["#F6B43B", "#EC1A74"],
      };
      var op9 = {
        chart: {
          toolbar: {
            followCursor: true,
            show: false,
            tools: {
              download: true,
              selection: true,
              zoom: true,
              zoomin: true,
              zoomout: true,
              pan: true,
              reset: true,
            },
            tooltip: {
              shared: true,
              intersect: true,
              followCursor: true,
              x: {
                show: false,
              },
            },
            autoSelected: "zoom",
          },
          type: "area",
          height: 120,
          sparkline: {
            enabled: true,
          },
        },
        markers: {
          size: 0,
        },
        stroke: {
          curve: "straight",
          width: 2.5,
        },
        fill: {
          opacity: 1,
        },
        series: [{ name: "Cost Per Goal Conversion", data: costPerGoalConversion }],
        xaxis: {
          crosshairs: {
            width: 1,
          },
        },
        yaxis: {
          show: false,
        },  
        colors: ["#F6B43B", "#EC1A74"],
      };
      var op10 = {
        chart: {
          toolbar: {
            followCursor: true,
            show: false,
            tools: {
              download: true,
              selection: true,
              zoom: true,
              zoomin: true,
              zoomout: true,
              pan: true,
              reset: true,
            },
            tooltip: {
              shared: true,
              intersect: true,
              followCursor: true,
              x: {
                show: false,
              },
            },
            autoSelected: "zoom",
          },
          type: "area",
          height: 120,
          sparkline: {
            enabled: true,
          },
        },
        markers: {
          size: 0,
        },
        stroke: {
          curve: "straight",
          width: 2.5,
        },
        fill: {
          opacity: 1,
        },
        series: [{ name: "Cost Per Transaction", data: costPerTransaction }],
        xaxis: {
          crosshairs: {
            width: 1,
          },
        },
        yaxis: {
          show: false,
        },  
        colors: ["#F6B43B", "#EC1A74"],
      };
      var op11 = {
        chart: {
          toolbar: {
            followCursor: true,
            show: false,
            tools: {
              download: true,
              selection: true,
              zoom: true,
              zoomin: true,
              zoomout: true,
              pan: true,
              reset: true,
            },
            tooltip: {
              shared: true,
              intersect: true,
              followCursor: true,
              x: {
                show: false,
              },
            },
            autoSelected: "zoom",
          },
          type: "area",
          height: 120,
          sparkline: {
            enabled: true,
          },
        },
        markers: {
          size: 0,
        },
        stroke: {
          curve: "straight",
          width: 2.5,
        },
        fill: {
          opacity: 1,
        },
        series: [{ name: "Cost Per Conversion", data: costPerConversion }],
        xaxis: {
          crosshairs: {
            width: 1,
          },
        },
        yaxis: {
          show: false,
        },
        colors: ["#F6B43B", "#EC1A74"],
      };
 const [myCards, setMyCards] = useState([
  { id: 1, name: "Impressions", options: op1, series: op1.series },
  { id: 2, name: "Clicks", options: op2, series: op2.series },
  { id: 3, name: "Cost", options: op3, series: op3.series },
  { id: 4, name: "CPM", options: op4, series: op4.series },
  { id: 5, name: "CPC", options: op5, series: op5.series },
  { id: 6, name: "CTR", options: op6, series: op6.series },
  { id: 7, name: "ROAS", options: op7, series: op7.series },
  { id: 8, name: "RPC", options: op8, series: op8.series },
  { id: 9, name: "Cost Per Goal Conversion", options: op9, series: op9.series },
  { id: 10, name: "Cost Per Transaction", options: op10, series: op10.series },
  { id: 11, name: "Cost Per Conversion", options: op11, series: op11.series },
]);
useEffect(() => {
  let isSubscribed = true;
  setMyCards([
    { id: 1, name: "Impressions", options: op1, series: op1.series },
    { id: 2, name: "Clicks", options: op2, series: op2.series },
    { id: 3, name: "Cost", options: op3, series: op3.series },
    { id: 4, name: "CPM", options: op4, series: op4.series },
    { id: 5, name: "CPC", options: op5, series: op5.series },
    { id: 6, name: "CTR", options: op6, series: op6.series },
    { id: 7, name: "ROAS", options: op7, series: op7.series },
    { id: 8, name: "RPC", options: op8, series: op8.series },
    { id: 9, name: "Cost Per Goal Conversion", options: op9, series: op9.series },
    { id: 10, name: "Cost Per Transaction", options: op10, series: op10.series },
    { id: 11, name: "Cost Per Conversion", options: op11, series: op11.series },
  ]);
  return () => (isSubscribed = false);
}, [impressions,clicks,cost,cpc,cpm,ctr,roas,rpc,costPerGoalConversion,costPerTransaction,costPerConversion]);
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Google Analytics | Live-Metrics - E-com SaaS</title>
        </MetaTags>
        <div className="container-fluid">
          <Breadcrumbs title="Google Analytics" breadcrumbItem="Ad Words (Ad Group ) " pageHeading="Ad Words" />
          <Row>
          {
              myCards.map(item => (
                <GAAdWordsCard loading={loadingState} item={item} />
              ))}
         </Row>
        </div>
      </div>
    </React.Fragment>
  )
}
export default GAAdWords;
