import gql from 'graphql-tag';

const checkSales = gql`query{
    get{
        id,
        apiKey,
        type,
        isDeleted,
        active  ,
        connectionstate  
    }
}
`

const getAllMetrics = gql`query($key:String!){
    getmetrics(key:$key){
        apiKey,
        id,
        name,
        results,
        isDeleted
    }
}`
const getAllFlows = gql`query($key:String!){
    getflows(key:$key){
        apiKey,
        id_flow,
        creationDate,
        updateDate,
        archived,
        name,
        status,
        isDeleted
    }
}`



const getAllCompaigns = gql`query($key:String!){
    getcompaigns(key:$key){
        apiKey,
        id_compaign,
        name,
        status,
        send_time,
        message_type,
        campaign_type,
        num_recipients,
        template_id,
        isDeleted
    }
}`

const integrate = gql`mutation($key:String!){
    integration(key:$key) {
        apiKey,
        type,
        active
    }
}`
const deleteIntegration = gql`mutation{
    delete{
        isDeleted
    }
}
`



export { integrate, checkSales, getAllCompaigns, getAllMetrics,deleteIntegration,getAllFlows };